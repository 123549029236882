import { copyAnyObjectList, fillObject, makeUniqId } from '../../../../helpers';
import {
	extendPlayWagerDefinitionData,
	IExtendPlayWagerDefinitionDataOpts,
} from '../../../data/playWagerDefinitionExt';
import { generateObjectListHashId } from '../../utility';
import {
	IMethodPlayWagerDefinitionsMapRawDataOpts,
	IMethodPlayWagerDefinitionsRemapDataOpts,
	IPlayWagerDefinitionsData,
	IPlayWagerDefinitionsDataEntry,
	IPlayWagerDefinitionsFilterToOpts,
	PlayWagerDefinitionsDataList,
	PlayWagerDefinitionsDataLookup,
	RawPlayWagerDefinitionEntry,
	RawPlayWagerDefinitionList,
} from './types';

/**
 * @returns The default data used by the `AvailableWagers` class.
 */
const defaultData = (
	opts?: Maybe<{ updatedTs?: Maybe<number>; playId?: Maybe<string> }>
): IPlayWagerDefinitionsData => {
	return {
		raw: null,
		lookup: new Map<string, IPlayWagerDefinitionsDataEntry>(),
		nameToIdLookup: new Map<string, string>(),
		lastUpdatedTs: opts?.updatedTs ?? 0,
		playId: opts?.playId ?? '',
		uniqId: makeUniqId(),
		hashId: '',
	};
};

/**
 * @returns A unique hash ID for the specified list of raw data entries.
 */
const generateRawListHashId = (list: RawPlayWagerDefinitionList): string => {
	return generateObjectListHashId<RawPlayWagerDefinitionEntry>(list, [
		'wagerTypeId',
		'name',
		'currency',
		'required',
		'isCategory',
		'availableKeys',
		'minAmount',
		'maxAmount',
		'wagerPeriodId',
		'requiresSeat',
		'requiresSpectator',
	]);
};

/**
 * @returns A unique hash ID for the specified list of data entries.
 */
const generateDataListHashId = (list: PlayWagerDefinitionsDataList): string => {
	return generateObjectListHashId<IPlayWagerDefinitionsDataEntry>(list, [
		'wagerId',
		'name',
		'currencyCode',
		'isRequired',
		'isCategory',
		'categoryMemberKeys',
		'minAmount',
		'maxAmount',
		'wagerPeriodId',
		'isSeatRequired',
		'isSpectatorRequired',
	]);
};

/**
 * @returns TRUE if the specified raw data lists are the same - in terms of the meaningful data.
 */
const isRawListSameData = (list1: RawPlayWagerDefinitionList, list2: RawPlayWagerDefinitionList): boolean => {
	if (list1 === list2) {
		return true;
	}
	if (list1.length !== list2.length) {
		return false;
	}

	const hashId1 = generateRawListHashId(list1);
	const hashId2 = generateRawListHashId(list2);

	return hashId1 === hashId2;
};

/**
 * Maps the raw data list sent by the server and resolves to the relevant encapsulated data.
 */
const newDataFromRawList = (
	srcList: RawPlayWagerDefinitionList,
	playId: string,
	opts?: Maybe<IMethodPlayWagerDefinitionsMapRawDataOpts>
): IPlayWagerDefinitionsData => {
	const { updatedTs } = opts ?? {};
	const lastUpdatedTs: number = updatedTs || Date.now();

	if (srcList.length === 0) {
		return defaultData({ updatedTs: lastUpdatedTs, playId });
	}

	type LookupObjType = { [k: string]: boolean };

	const filterTo = opts?.filterTo ?? null;
	const filterIds = filterTo?.wagerIds ?? [];
	const filterNames = filterTo?.wagerNames ?? [];
	const filterIdsLookup = Object.freeze(fillObject<LookupObjType, boolean>({}, filterIds, true));
	const filterNamesLookup = Object.freeze(fillObject<LookupObjType, boolean>({}, filterNames, true));

	const lookup = new Map<string, IPlayWagerDefinitionsDataEntry>();
	const nameToIdLookup = new Map<string, string>();

	const extendDataOpts: IExtendPlayWagerDefinitionDataOpts = { ...opts?.extendDataOpts, playId };

	srcList.forEach((entry: RawPlayWagerDefinitionEntry) => {
		const entryExt = extendPlayWagerDefinitionData(entry, extendDataOpts);

		const wagerId = entryExt.wagerId;
		const wagerName = entryExt.name;
		const wagerNameLc = wagerName.toLocaleLowerCase();

		if (filterIds.length > 0 && !filterIdsLookup[wagerId]) {
			return; // Next entry
		}
		if (filterNames.length > 0 && !filterNamesLookup[wagerName]) {
			return; // Next entry
		}

		const item: IPlayWagerDefinitionsDataEntry = {
			...entryExt,
			lastUpdatedTs,
		};

		lookup.set(wagerId, item);
		nameToIdLookup.set(wagerNameLc, wagerId);
	});

	const list = Array.from(lookup.values());
	const hashId = generateDataListHashId(list);
	const uniqId = makeUniqId();

	const rawList = srcList.slice();
	const rawHashId = generateRawListHashId(rawList);

	return {
		raw: { list: rawList, hashId: rawHashId },
		lookup,
		nameToIdLookup,
		lastUpdatedTs,
		uniqId,
		hashId,
		playId,
	};
};

/**
 * Remaps existing encapsulated data using different filters and options.
 */
const remapData = (
	srcData: IPlayWagerDefinitionsData,
	playId: string,
	filterTo: Maybe<IPlayWagerDefinitionsFilterToOpts>,
	opts?: IMethodPlayWagerDefinitionsRemapDataOpts
): IPlayWagerDefinitionsData => {
	const rawList = (srcData.raw?.list ?? []).slice();

	return newDataFromRawList(rawList, playId, { ...opts, filterTo });
};

/**
 * @returns Unique wager names in the specified data lookup.
 */
const getWagerNames = (lookup: PlayWagerDefinitionsDataLookup): string[] => {
	const list = Array.from(lookup.values());
	const allWagerNameMap = new Map<string, boolean>();

	list.forEach((entry) => {
		const wagerName = entry.name;

		if (wagerName.length === 0) {
			return; // Next entry
		}

		allWagerNameMap.set(wagerName, true);
	});

	return Array.from(allWagerNameMap.keys());
};

/**
 * @returns A copy of the specified raw data list.
 */
const copyRawList = (list: RawPlayWagerDefinitionList): RawPlayWagerDefinitionList => {
	return copyAnyObjectList<RawPlayWagerDefinitionEntry>(list);
};

/**
 * @returns A copy of the specified data list.
 */
const copyList = (list: PlayWagerDefinitionsDataList): PlayWagerDefinitionsDataList => {
	return copyAnyObjectList<IPlayWagerDefinitionsDataEntry>(list);
};

/**
 * @returns A copy of the specified data lookup.
 */
const copyLookup = (lookup: PlayWagerDefinitionsDataLookup): PlayWagerDefinitionsDataLookup => {
	const copy = new Map<string, IPlayWagerDefinitionsDataEntry>();
	for (const [key, value] of lookup) {
		copy.set(key, { ...value });
	}

	return copy;
};

/**
 * @returns A copy of the specified encapsulated data.
 */
const copyData = (
	data: IPlayWagerDefinitionsData,
	opts?: Maybe<{ updatedTs?: Maybe<number> }>
): IPlayWagerDefinitionsData => {
	const newData: IPlayWagerDefinitionsData = defaultData();
	newData.lastUpdatedTs = opts?.updatedTs ?? data.lastUpdatedTs;
	newData.lookup = copyLookup(data.lookup);
	newData.nameToIdLookup = new Map<string, string>(data.nameToIdLookup);
	newData.hashId = data.hashId;
	newData.playId = data.playId;
	newData.raw = data.raw != null ? { list: copyRawList(data.raw.list), hashId: data.raw.hashId } : null;

	return newData;
};

// ---- Exports -------------------------------------------------------------------------------------------------------

export { generateDataListHashId, generateRawListHashId, isRawListSameData };
export { copyData, copyList, copyLookup, copyRawList, defaultData, newDataFromRawList, remapData };
export { getWagerNames };
