import { IStreamClientOpts, IStreamController } from '../../core';
import { IStreamOpts, IValidateStartResult, Stream, StreamStatus } from '../../core/Stream';
import { GetPlayReply, GetPlayRequest } from '../../rpc';
import { GameClient } from '../../rpc/clients/game';
import { makeStreamPropHashId } from '../utility';
import { IPlayDataStream, PlayDataStreamRequestProps } from './types';

type RequestProps = PlayDataStreamRequestProps;
type ValidateStartResult = IValidateStartResult<RequestProps> & {
	tableId: string;
	playId: string;
};

class PlayDataStream extends Stream<typeof GameClient, GetPlayReply, RequestProps> implements IPlayDataStream {
	/* #region ---- CONSTRUCTOR -------------------------------------------------------------------------------------- */

	constructor(url: string, opts?: IStreamOpts) {
		super(url, opts);
	}

	/* #endregion ---- CONSTRUCTOR ----------------------------------------------------------------------------------- */

	/* #region ---- Public ------------------------------------------------------------------------------------------- */

	/**
	 * Attempts to start this stream.
	 *
	 * @returns TRUE if the attempt to start the stream succeeded. Note that this does NOT mean the stream actually
	 *          connected and received data - you must subscribe to the stream to know that.
	 */
	public start(props?: Maybe<RequestProps>): boolean {
		const { playId, tableId, isValid, requestProps } = this.validateStart(props);

		if (!isValid) {
			return false;
		}

		const propHashId = makeStreamPropHashId(playId, tableId);
		const lastPropHashId = makeStreamPropHashId(
			this._lastRequestProps?.playId || '',
			this._lastRequestProps?.tableId || ''
		);

		// Stream is already running
		if (this.isActive) {
			// Different props specified? Treat it as a restart
			if (propHashId !== lastPropHashId) {
				return this.restart(requestProps);
			}

			this.warn(
				'Attempted to start an already running stream using the same props. Use `restart` if this is intended',
				'start',
				{ ...requestProps }
			);

			return false;
		}

		// Manual starts will clear any auto-restart cycle that might be active
		this.clearAutoRestarts();
		this.currentState.status = StreamStatus.STARTING;

		const didRun = this.runStream(requestProps);
		if (didRun) {
			this.afterStart(requestProps);
		}

		return didRun;
	}

	/* #endregion ---- Public ---------------------------------------------------------------------------------------- */

	/* #region ---- Protected ---------------------------------------------------------------------------------------- */

	/**
	 * Determines if we are allowed to start/restart this stream.
	 *
	 * @returns The result of the validation. This also includes the processed request props to apply.
	 */
	protected validateStart(requestProps?: Maybe<RequestProps>): ValidateStartResult {
		const debugMethod = 'validateStart';
		requestProps = requestProps ?? this._lastRequestProps ?? null;

		if (requestProps == null) {
			this.error('Request props must be specified', debugMethod);
			return { isValid: false, requestProps: null, playId: '', tableId: '' };
		}

		const { playId = '', tableId = '' } = requestProps;

		if (tableId === '') {
			this.error('Table ID must be specified', debugMethod);
			return { isValid: false, requestProps, playId, tableId };
		}

		if (playId === '') {
			this.error('Play ID must be specified', debugMethod);
			return { isValid: false, requestProps, playId, tableId };
		}

		if (!this.isEnabled) {
			this.error('Stream is disabled', debugMethod, { playId, tableId });
			return { isValid: false, requestProps, playId, tableId };
		}

		return { isValid: true, requestProps, playId, tableId };
	}

	/**
	 * Creates and starts a new play data stream.
	 *
	 * @returns TRUE if successfully able to create and start the stream.
	 */
	protected runStream(requestProps?: Maybe<RequestProps>): boolean {
		const debugMethod = 'runStream';
		const { playId = '', tableId = '' } = requestProps ?? {};

		if (playId === '') {
			this.error('Play ID must be specified', debugMethod);
			return false;
		}
		if (tableId === '') {
			this.error('Table ID must be specified', debugMethod);
			return false;
		}

		this._streamController = this.newStream(playId, tableId);

		return true;
	}

	/**
	 * Starts a new play data stream for the specified props.
	 */
	protected newStream(playId: string, tableId: string): IStreamController {
		const request = new GetPlayRequest({ playId, tableId });
		return this.stream<typeof request>('streamPlay', request);
	}

	/**
	 * @returns The stream client instance used by this stream.
	 */
	protected createStreamClient(url: string, clientOpts?: Maybe<IStreamClientOpts>) {
		return this.newStreamClient(GameClient, url, clientOpts);
	}

	/* #endregion ---- Protected ------------------------------------------------------------------------------------- */

	/* #region ---- Debug -------------------------------------------------------------------------------------------- */

	/**
	 * Overrides the parent class property.
	 *
	 * @returns The label to use when debugging.
	 */
	protected override get debugClassLabel(): string {
		return PlayDataStream.debugClassLabel();
	}

	/**
	 * STATIC
	 * @returns Label assigned to this class namespace.
	 */
	protected static debugClassLabel(): string {
		return `RpcLib.Stream.PlayDataStream`;
	}

	/* #endregion ---- Debug ----------------------------------------------------------------------------------------- */
}

// ---- Exports -------------------------------------------------------------------------------------------------------

export { PlayDataStream as default };
export { PlayDataStream };
