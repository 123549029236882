import { IWagerManager, IWagerManagerMethodAdjustWagerAmountsWagerSet } from '../../managers';
import { IClientRpcSdk } from '../ClientRpcSdk';
import { debug } from './debug';
import { IClientRpcSdkWagerActions } from './types/wager';

const getSdkWagerManager = (sdk: IClientRpcSdk): IWagerManager => {
	const { wagerManager } = sdk.managers.getManagers();
	return wagerManager;
};

/**
 * Adjusts a single wager by name.
 */
const adjustWagerByName = (
	sdk: IClientRpcSdk,
	wagerName: string,
	byAmount: number,
	opts?: Maybe<{ seatNumber?: Maybe<number> }>
): boolean => {
	const debugMethod = 'wager.adjustWagerByName';

	debug.info('Adjusting wager amount', debugMethod, { wagerName, byAmount, opts: opts ?? {} });

	const wagerManager = getSdkWagerManager(sdk);

	return wagerManager.adjustWagerAmountByName(wagerName, byAmount, opts);
};

/**
 * Adjusts a single wager by available wager key.
 */
const adjustWagerByAvailableWagerKey = (sdk: IClientRpcSdk, availableWagerKey: string, amount: number): boolean => {
	const debugMethod = 'wager.adjustWagerByAvailableWagerKey';

	debug.info('Adjusting wager amount', debugMethod, { availableWagerKey, amount });

	const wagerManager = getSdkWagerManager(sdk);

	return wagerManager.adjustWagerAmount(availableWagerKey, amount);
};

/**
 * Adjusts all available wagers by the same amount.
 */
const adjustAllWagers = (sdk: IClientRpcSdk, amount: number): boolean => {
	const debugMethod = 'wager.adjustAllWagers';

	debug.info('Adjusting ALL wager amounts', debugMethod, { amount });

	const wagerManager = getSdkWagerManager(sdk);

	const wagers = wagerManager.availableWagers.keys;
	const wagerSet: IWagerManagerMethodAdjustWagerAmountsWagerSet = {};

	wagers.forEach((availableWagerKey) => {
		wagerSet[availableWagerKey] = { byAmount: amount, isDirty: true };
	});

	return wagerManager.adjustWagerAmounts(wagerSet);
};

/**
 * Places the same amount on all available sidebet (ie. non-required) wagers.
 */
const adjustAllSideBetWagers = (sdk: IClientRpcSdk, amount: number): boolean => {
	const debugMethod = 'wager.adjustAllSideBetWagers';

	debug.info('Adjusting sidebet wager amounts', debugMethod, { amount });

	const wagerManager = getSdkWagerManager(sdk);

	const availableSideBetWagers = wagerManager.availableWagers.list.filter(
		(availWager) => availWager.def?.isRequired !== true
	);
	const wagerSet: IWagerManagerMethodAdjustWagerAmountsWagerSet = {};

	availableSideBetWagers.forEach((availWager) => {
		const availableWagerKey = availWager.availableWagerKey;
		wagerSet[availableWagerKey] = { byAmount: amount, isDirty: true };
	});

	return wagerManager.adjustWagerAmounts(wagerSet);
};

/**
 * Removes / clears a single wager.
 */
const clearWager = (sdk: IClientRpcSdk, availableWagerKey: string): boolean => {
	debug.info('Clearing wager', 'wager.clearWager', { availableWagerKey });

	const wagerManager = getSdkWagerManager(sdk);

	// TODO: Wager manager needs a `clearWager` method
	return wagerManager.setWagerAmount(availableWagerKey, 0);
};

/**
 * Removes / clears all wagers.
 */
const clearAllWagers = (sdk: IClientRpcSdk): void => {
	debug.info('Clearing all wagers', 'wager.clearAllWagers');

	const wagerManager = getSdkWagerManager(sdk);

	return wagerManager.clearAllWagers();
};

/**
 * Factory for creating wager actions.
 */
const newWagerActions = (sdk: IClientRpcSdk): IClientRpcSdkWagerActions => {
	return Object.freeze({
		adjustWagerByName: (wagerName: string, byAmount: number, opts?: Maybe<{ seatNumber?: Maybe<number> }>): boolean =>
			adjustWagerByName(sdk, wagerName, byAmount, opts),
		adjustWagerByAvailableWagerKey: (availableWagerKey: string, amount: number): boolean =>
			adjustWagerByAvailableWagerKey(sdk, availableWagerKey, amount),
		adjustAllWagers: (amount: number): boolean => adjustAllWagers(sdk, amount),
		adjustAllSideBetWagers: (amount: number): boolean => adjustAllSideBetWagers(sdk, amount),
		clearWager: (availableWagerKey: string): boolean => clearWager(sdk, availableWagerKey),
		clearAllWagers: (): void => clearAllWagers(sdk),
	});
};

// ---- Export --------------------------------------------------------------------------------------------------------

export { newWagerActions };
