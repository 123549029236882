import { copyAnyObjectList, extendBalanceData } from '../../../../helpers';
import { makeUniqId } from '../../../../helpers';
import { generateObjectListHashId } from '../../../../helpers/data/utility';
import {
	IMethodWalletServerBalancesMapRawDataOpts,
	IWalletServerBalanceDataEntry,
	IWalletServerBalancesData,
	RawServerBalanceEntry,
	RawServerBalanceList,
	WalletServerBalancesDataList,
	WalletServerBalancesDataLookup,
} from './types';

/**
 * @returns The default data used by the `WalletServerBalances` class.
 */
const defaultData = (
	opts?: Maybe<{ updatedTs?: Maybe<number>; playerId?: Maybe<string> }>
): IWalletServerBalancesData => {
	return {
		lookup: new Map<string, IWalletServerBalanceDataEntry>(),
		lastUpdatedTs: opts?.updatedTs ?? 0,
		playerId: opts?.playerId ?? '',
		uniqId: makeUniqId(),
		hashId: '',
	};
};

/**
 * @returns A unique hash ID for the specified list of raw data entries.
 */
const generateRawListHashId = (list: RawServerBalanceList): string => {
	return generateObjectListHashId<RawServerBalanceEntry>(list, [
		'playerId',
		'accountId',
		'currency',
		'currencyExponent',
		'amount',
	]);
};

/**
 * @returns A unique hash ID for the specified list of data entries.
 */
const generateDataListHashId = (list: WalletServerBalancesDataList): string => {
	return generateObjectListHashId<IWalletServerBalanceDataEntry>(list, [
		'playerId',
		'accountId',
		'amount',
		'currencyCode',
		'currencyExponent',
	]);
};

/**
 * @returns TRUE if the specified raw data lists are the same - in terms of the meaningful data.
 */
const isRawListSameData = (list1: RawServerBalanceList, list2: RawServerBalanceList): boolean => {
	if (list1 === list2) {
		return true;
	}
	if (list1.length !== list2.length) {
		return false;
	}

	const hashId1 = generateRawListHashId(list1);
	const hashId2 = generateRawListHashId(list2);

	return hashId1 === hashId2;
};

/**
 * @returns TRUE if the specified raw data lists are the same - in terms of the meaningful data.
 */
const isDataListSameData = (list1: WalletServerBalancesDataList, list2: WalletServerBalancesDataList): boolean => {
	if (list1 === list2) {
		return true;
	}
	if (list1.length !== list2.length) {
		return false;
	}

	const hashId1 = generateDataListHashId(list1);
	const hashId2 = generateDataListHashId(list2);

	return hashId1 === hashId2;
};

/**
 * @returns A copy of the specified raw data list.
 */
const copyRawList = (list: RawServerBalanceList): RawServerBalanceList => {
	return copyAnyObjectList<RawServerBalanceEntry>(list);
};

/**
 * @returns A copy of the specified data list.
 */
const copyDataList = (list: WalletServerBalancesDataList): WalletServerBalancesDataList => {
	return copyAnyObjectList<IWalletServerBalanceDataEntry>(list);
};

/**
 * @returns A copy of the specified server balance data lookup.
 */
const copyLookup = (lookup: WalletServerBalancesDataLookup): WalletServerBalancesDataLookup => {
	const copy = new Map<string, IWalletServerBalanceDataEntry>();
	for (const [key, value] of lookup) {
		copy.set(key, { ...value });
	}

	return copy;
};

/**
 * @returns A copy of the specified server balances data.
 */
const copyData = (
	data: IWalletServerBalancesData,
	opts?: Maybe<{ updatedTs?: Maybe<number> }>
): IWalletServerBalancesData => {
	const newData: IWalletServerBalancesData = defaultData();
	newData.lastUpdatedTs = opts?.updatedTs ?? data.lastUpdatedTs;
	newData.lookup = copyLookup(data.lookup);
	newData.playerId = data.playerId;
	newData.hashId = data.hashId;
	newData.raw = data.raw != null ? { list: copyRawList(data.raw.list), hashId: data.raw.hashId } : null;

	return newData;
};

/**
 * Maps the raw data list sent by the server and resolves to the relevant encapsulated data.
 */
const newDataFromRawList = (
	srcList: RawServerBalanceList,
	opts?: Maybe<IMethodWalletServerBalancesMapRawDataOpts>
): IWalletServerBalancesData => {
	const lastUpdatedTs: number = opts?.updatedTs ?? Date.now();

	if (srcList.length === 0) {
		return defaultData({ updatedTs: lastUpdatedTs });
	}

	const lookup = new Map<string, IWalletServerBalanceDataEntry>();
	const extendDataOpts = { ...opts?.extendDataOpts };

	srcList.forEach((entry: RawServerBalanceEntry) => {
		const entryExt = extendBalanceData(entry, extendDataOpts);

		const item: IWalletServerBalanceDataEntry = {
			...entryExt,
			lastUpdatedTs,
		};

		const currencyCode = entryExt.currencyCode;

		lookup.set(currencyCode, item);
	});

	const list = Array.from(lookup.values());
	const hashId = generateDataListHashId(list);
	const uniqId = makeUniqId();

	const rawList = srcList.slice();
	const rawHashId = generateRawListHashId(rawList);

	const playerId: string = opts?.playerId ?? (list.length > 0 ? list[0]?.playerId : null) ?? '';

	return {
		raw: { list: rawList, hashId: rawHashId },
		lookup,
		lastUpdatedTs,
		uniqId,
		hashId,
		playerId,
	};
};

// ---- Exports -------------------------------------------------------------------------------------------------------

export { copyData, copyRawList, copyLookup, copyDataList, defaultData };
export { generateRawListHashId, generateDataListHashId };
export { newDataFromRawList };
export { isRawListSameData, isDataListSameData };
