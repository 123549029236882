import { action, AnnotationsMap, computed, makeObservable, observable } from 'mobx';
import { DebugBaseMobXAnnotations } from '../../../../common';
import LocalActiveWagers from './LocalActiveWagers';
import { LocalActiveWagersMobXPrivateProps } from './types.mobx';

const LocalActiveWagersMobXAnnotations: AnnotationsMap<LocalActiveWagers, LocalActiveWagersMobXPrivateProps> = {
	// Inherit from DebugBase
	...DebugBaseMobXAnnotations,

	// ---- Observed -----------------------------------------------------

	// >> Properties
	_data: observable.struct,

	// ---- Computed -----------------------------------------------------

	// >> Public
	isEmpty: computed,
	size: computed,
	lookup: computed,
	list: computed,
	lastUpdatedTs: computed,
	data: computed,
	playId: computed,
	seatNumbers: computed,
	wagerNames: computed,
	wagers: computed,
	wagerTotals: computed,

	// ---- Actions ------------------------------------------------------

	// >> Public
	set: action,
	clear: action,
	batchUpsertWagerData: action,

	// >> Protected
	setLastUpdatedTs: action,
	setData: action,
	setPlayId: action,

	// ---- Excluded -----------------------------------------------------

	// >> Properties
	_isMobXBound: false,
	_isNewDataDirtyDefaultVal: false,

	// >> Public Getters
	isMobXBound: false,
};

/**
 * Makes the specified `LocalActiveWagers` instance an observable MobX object.
 */
const bindLocalWagersMobX = (instance: LocalActiveWagers): boolean => {
	if (instance.isMobXBound) {
		return false;
	}

	makeObservable<LocalActiveWagers, LocalActiveWagersMobXPrivateProps>(instance, {
		...LocalActiveWagersMobXAnnotations,
	});

	instance.isMobXBound = true;

	return true;
};

// ---- Exports -------------------------------------------------------------------------------------------------------

export { LocalActiveWagersMobXAnnotations, bindLocalWagersMobX };
