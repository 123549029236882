import omit from 'lodash/omit';
import { IPayTableData, PayTable } from '../../client/rpc';
import { resolveAmounts } from '../amounts';
import { filterNullUndefined } from '../shared';
import {
	IExtendTableConfigWagerDefinitionDataOpts,
	ITableConfigWagerDefinitionData,
	ITableConfigWagerDefinitionDataExt,
} from './types/tableConfigWagerDefinitionExt';

/**
 * @returns An empty `ITableConfigWagerDefinitionData` object with default values.
 */
const newEmptyTableConfigWagerDefinitionData = (): ITableConfigWagerDefinitionData => ({
	name: '',
	description: '',
	currency: '',
	minAmount: BigInt(0),
	maxAmount: BigInt(0),
	isCategory: false,
	availableKeys: [],
	requiresSeat: false,
	requiresSpectator: false,
	payTable: new PayTable({ name: '', rtp: '', paylines: [] }),
});

/**
 * @returns An empty `ITableConfigWagerDefinitionDataExt` object with default values.
 */
const newEmptyTableConfigWagerDefinitionDataExt = (): ITableConfigWagerDefinitionDataExt => ({
	raw: null,
	categoryMemberKeys: [],
	currencyCode: '',
	currencySymbol: '',
	description: '',
	isCategory: false,
	isSeatRequired: false,
	isSpectatorRequired: false,
	maxAmount: 0,
	maxAmountMoney: '',
	maxAmountReal: 0,
	minAmount: 0,
	minAmountMoney: '',
	minAmountReal: 0,
	name: '',
	payTable: null,
	tableId: '',
});

/**
 * @returns Extended version of `ITableConfigWagerDefinitionData` with re-mapped types, props and added fields.
 */
const extendTableConfigWagerDefinitionData = (
	src: ITableConfigWagerDefinitionData,
	opts?: Maybe<IExtendTableConfigWagerDefinitionDataOpts>
): ITableConfigWagerDefinitionDataExt => {
	const base = omit(src, ['minAmount', 'maxAmount', 'currency', 'availableKeys', 'requiresSeat', 'requiresSpectator']);

	const applyCurrencyCode = opts?.currencyCode || src.currency || '';
	const formatCurrencyOpts = opts?.formatCurrencyOpts ?? null;

	const {
		currencyCode, // Currency actually used for amounts.
		currencySymbol, // Currency symbol actually used for amounts.
	} = resolveAmounts({ amount: 0 }, { formatCurrencyOpts, currencyCode: applyCurrencyCode });

	const {
		amount: minAmount,
		amountReal: minAmountReal,
		amountMoney: minAmountMoney,
	} = resolveAmounts({ amount: Number(src.minAmount ?? 0) }, { formatCurrencyOpts, currencyCode });

	const {
		amount: maxAmount,
		amountReal: maxAmountReal,
		amountMoney: maxAmountMoney,
	} = resolveAmounts({ amount: Number(src.maxAmount ?? 0) }, { formatCurrencyOpts, currencyCode });

	return {
		...newEmptyTableConfigWagerDefinitionDataExt(),
		...filterNullUndefined(base),
		raw: { ...src },
		categoryMemberKeys: src.availableKeys ?? [],
		currencyCode,
		currencySymbol,
		isSeatRequired: src.requiresSeat ?? false,
		isSpectatorRequired: src.requiresSpectator ?? false,
		maxAmount,
		maxAmountMoney,
		maxAmountReal,
		minAmount,
		minAmountMoney,
		minAmountReal,
		payTable: (src.payTable ?? null) as Nullable<IPayTableData>,
		tableId: opts?.tableId ?? '',
	};
};

/**
 * @returns Extended version of `ITableConfigWagerDefinitionData[]` with re-mapped types, props and added fields.
 */
const extendTableConfigWagerDefinitionDataList = (
	srcList: ITableConfigWagerDefinitionData[],
	opts?: Maybe<IExtendTableConfigWagerDefinitionDataOpts>
): ITableConfigWagerDefinitionDataExt[] => {
	const result: ITableConfigWagerDefinitionDataExt[] = [];

	for (const src of srcList) {
		result.push(extendTableConfigWagerDefinitionData(src, opts));
	}

	return result;
};

// ---- Exports -------------------------------------------------------------------------------------------------------

export { extendTableConfigWagerDefinitionData, extendTableConfigWagerDefinitionDataList };
export { newEmptyTableConfigWagerDefinitionData, newEmptyTableConfigWagerDefinitionDataExt };

// Other
export * from './types/tableConfigWagerDefinitionExt';
