enum ServerWageringState {
	ACTIVE = 'ACTIVE',
	PENDING_NEW = 'PENDING_NEW',
	PENDING_UPDATE = 'PENDING_UPDATE',
	REJECTED_NEW = 'REJECTED_NEW',
	REJECTED_UPDATE = 'REJECTED_UPDATE',
	REMOVED = 'REMOVED',
}

// ---- Exports -------------------------------------------------------------------------------------------------------

export { ServerWageringState };
