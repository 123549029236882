import { getNowTs, IPreciseIntervalCallbackProps, PreciseInterval, setPreciseInterval } from '../../helpers';
import { ManagerBase } from '../lib/ManagerBase';
import { IServerTimeManager, IServerTimeManagerOpts } from './types';

/**
 * Attempts to tell the local client what the current time on the server is.
 */
class ServerTimeManager extends ManagerBase implements IServerTimeManager {
	/* #region ---- Properties --------------------------------------------------------------------------------------- */

	/**
	 * Currently assigned options.
	 */
	protected _options: IServerTimeManagerOpts = ServerTimeManager.defaultOptions();

	/**
	 * Last known server time (Unix time) communicated to us.
	 */
	protected _serverTimestamp: number = 0;

	/**
	 * Local unix timestamp (this is kept up-to-date via a precise timer)
	 */
	protected _localTimestamp: number = 0;

	/**
	 * Sync interval timer that attempts to keeps the local timestamp synced up with the server.
	 */
	protected _syncInterval: PreciseInterval;

	/* #endregion ---- Properties -------------------------------------------------------------------------------------*/

	/* #region ---- CONSTRUCTOR ---------------------------------------------------------------------------------------*/

	constructor(opts?: Maybe<IServerTimeManagerOpts>) {
		super();

		this.setOptions(opts);

		this._serverTimestamp = 0;
		this._localTimestamp = 0;
		this._syncInterval = setPreciseInterval(100, this.onSyncIntervalTick, { autoStart: false });
	}

	/* #endregion ---- CONSTRUCTOR ------------------------------------------------------------------------------------*/

	/* #region ---- Public ------------------------------------------------------------------------------------------- */

	/**
	 * Sets/initializes the class options.
	 */
	public setOptions(opts?: Maybe<IServerTimeManagerOpts>) {
		if (opts == null) {
			return;
		}

		const origOpts: IServerTimeManagerOpts = {
			...this._options,
		};

		const newOpts: IServerTimeManagerOpts = {
			...origOpts,
			...(opts ?? {}),
		};

		if (
			newOpts.serverTimestamp != null &&
			newOpts.serverTimestamp >= 0 &&
			newOpts.serverTimestamp !== origOpts.serverTimestamp &&
			newOpts.serverTimestamp !== this._serverTimestamp
		) {
			this.serverTimestamp = newOpts.serverTimestamp;
		}

		super.setOptions(newOpts);
		this._options = newOpts;
	}

	/**
	 * Resets this class instance back to the clear/initial state.
	 */
	public clear() {
		this.setOptions(this._options);

		this._serverTimestamp = 0;
		this._localTimestamp = 0;
		this._syncInterval = setPreciseInterval(100, this.onSyncIntervalTick, { autoStart: false });
	}

	/**
	 * Get/Set the current known server time.
	 */
	public get serverTimestamp(): number {
		return this._serverTimestamp;
	}

	public set serverTimestamp(timestamp: number) {
		if (timestamp < 0) {
			return;
		}

		this._syncInterval.reset();
		this._serverTimestamp = timestamp;
		this._localTimestamp = timestamp;
		this._syncInterval.start();
	}

	/**
	 * Get the estimated local timestamp (based on the last known server time)
	 */
	public get now(): number {
		return this._localTimestamp || getNowTs() || 0;
	}

	/**
	 * Get the estimated local timestamp in MS (based on the last known server time)
	 */
	public get nowMs(): number {
		return Math.floor((this._localTimestamp || getNowTs() || 0) / 1000);
	}

	/* #endregion ---- Public ---------------------------------------------------------------------------------------- */

	/* #region ---- Protected ---------------------------------------------------------------------------------------- */

	/**
	 * Called whenever a interval tick elapses for the timer.
	 */
	protected onSyncIntervalTick = (tick: IPreciseIntervalCallbackProps) => {
		this._localTimestamp += tick.elapsedMs;
	};
	protected get debugClassLabel(): string {
		return ServerTimeManager.debugClassLabel();
	}

	/* #endregion ---- Protected ------------------------------------------------------------------------------------- */

	/* #region ---- Static ------------------------------------------------------------------------------------------- */

	/**
	 * STATIC
	 * @returns The default options data used by this class.
	 */
	public static defaultOptions(): IServerTimeManagerOpts {
		return {
			...ManagerBase.defaultOptions(),
			serverTimestamp: null,
		};
	}

	/**
	 * STATIC
	 * @returns Label assigned to this class namespace.
	 */
	protected static debugClassLabel(): string {
		return `RpcLib.Manager.ServerTimeManager`;
	}

	/* #endregion ---- Static ---------------------------------------------------------------------------------------- */
}

// ---- Exports -------------------------------------------------------------------------------------------------------

export { ServerTimeManager as default };
export { ServerTimeManager };
