// @generated by protoc-gen-connect-es v0.11.0 with parameter "target=js+dts"
// @generated from file dealerserver.proto (package com.sands.ldx.dealerserver, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { CloseSeatRequest, DealerAttachmentRequest, DealerDetachmentRequest, EvictAllSeatsRequest, EvictOneSeatRequest, GetDealerRequest, GetDealerResponse, GetHUDRequest, GetHUDResponse, ListDealersRequest, ListDealersResponse, NewPlayRequest, PausePlayRequest, RunPlayRequest } from "./dealerserver_pb.js";
import { Empty, MethodKind } from "@bufbuild/protobuf";

/**
 * @generated from service com.sands.ldx.dealerserver.DealerServer
 */
export const DealerServer = {
  typeName: "com.sands.ldx.dealerserver.DealerServer",
  methods: {
    /**
     * A dealing device tells the play to run.  
     *
     * @generated from rpc com.sands.ldx.dealerserver.DealerServer.NewPlay
     */
    newPlay: {
      name: "NewPlay",
      I: NewPlayRequest,
      O: Empty,
      kind: MethodKind.Unary,
    },
    /**
     * A dealing device tells the play to run.  
     *
     * @generated from rpc com.sands.ldx.dealerserver.DealerServer.RunPlay
     */
    runPlay: {
      name: "RunPlay",
      I: RunPlayRequest,
      O: Empty,
      kind: MethodKind.Unary,
    },
    /**
     * Device initiates pause play.  
     *
     * @generated from rpc com.sands.ldx.dealerserver.DealerServer.PausePlay
     */
    pausePlay: {
      name: "PausePlay",
      I: PausePlayRequest,
      O: Empty,
      kind: MethodKind.Unary,
    },
    /**
     * EvictAllSeats allows a dealer to evict all table seats. 
     *
     * @generated from rpc com.sands.ldx.dealerserver.DealerServer.EvictAllSeats
     */
    evictAllSeats: {
      name: "EvictAllSeats",
      I: EvictAllSeatsRequest,
      O: Empty,
      kind: MethodKind.Unary,
    },
    /**
     * EvictOneSeat allows a dealer to evict a player from a seat. 
     *
     * @generated from rpc com.sands.ldx.dealerserver.DealerServer.EvictOneSeat
     */
    evictOneSeat: {
      name: "EvictOneSeat",
      I: EvictOneSeatRequest,
      O: Empty,
      kind: MethodKind.Unary,
    },
    /**
     * CloseSeat allows a dealer to close a seat (and evict a player if any). The seat is not available until it is open. 
     *
     * @generated from rpc com.sands.ldx.dealerserver.DealerServer.CloseSeat
     */
    closeSeat: {
      name: "CloseSeat",
      I: CloseSeatRequest,
      O: Empty,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc com.sands.ldx.dealerserver.DealerServer.AttachDealer
     */
    attachDealer: {
      name: "AttachDealer",
      I: DealerAttachmentRequest,
      O: Empty,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc com.sands.ldx.dealerserver.DealerServer.DetachDealer
     */
    detachDealer: {
      name: "DetachDealer",
      I: DealerDetachmentRequest,
      O: Empty,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc com.sands.ldx.dealerserver.DealerServer.ListDealers
     */
    listDealers: {
      name: "ListDealers",
      I: ListDealersRequest,
      O: ListDealersResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc com.sands.ldx.dealerserver.DealerServer.GetDealer
     */
    getDealer: {
      name: "GetDealer",
      I: GetDealerRequest,
      O: GetDealerResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc com.sands.ldx.dealerserver.DealerServer.GetHUD
     */
    getHUD: {
      name: "GetHUD",
      I: GetHUDRequest,
      O: GetHUDResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc com.sands.ldx.dealerserver.DealerServer.StreamHUD
     */
    streamHUD: {
      name: "StreamHUD",
      I: GetHUDRequest,
      O: GetHUDResponse,
      kind: MethodKind.ServerStreaming,
    },
  }
};

