import { EventDispatcherBase } from '../../../common';
import { IManagerBase, IManagerBaseOpts } from './types';

/**
 * Base class for the various managers. Contains the common logic.
 */
class ManagerBase extends EventDispatcherBase implements IManagerBase {
	/* #region ---- Properties --------------------------------------------------------------------------------------- */

	/**
	 * Currently assigned options.
	 */
	protected override _options: IManagerBaseOpts = ManagerBase.defaultOptions();

	/* #endregion ---- Properties -------------------------------------------------------------------------------------*/

	/* #region ---- CONSTRUCTOR ---------------------------------------------------------------------------------------*/

	/**
	 * CONSTRUCTOR.
	 */
	constructor(opts?: Maybe<IManagerBaseOpts>) {
		super();

		opts != null && this.setOptions(opts);
	}

	/* #endregion ---- CONSTRUCTOR ----------------------------------------------------------------------------------- */

	/* #region ---- Public ------------------------------------------------------------------------------------------- */

	/**
	 * Sets/initializes the class options.
	 *
	 * - Overrides the parent class method.
	 */
	public override setOptions(opts: IManagerBaseOpts) {
		const { newOpts, origOpts } = this.resolveOptions(opts);
		this._options = newOpts;
		this.onSetOptions(newOpts, origOpts);
	}

	/* #endregion ---- Public ---------------------------------------------------------------------------------------- */

	/* #region ---- Protected ---------------------------------------------------------------------------------------- */

	/**
	 * @returns The label to use when debugging.
	 */
	protected get debugClassLabel(): string {
		return ManagerBase.debugClassLabel();
	}

	/**
	 * Resolves the options being passed in and returns the original and new options.
	 *
	 * - Overrides the parent class method
	 */
	protected override resolveOptions(opts?: Maybe<IManagerBaseOpts>) {
		const origOpts: IManagerBaseOpts = {
			...ManagerBase.defaultOptions(),
			...this._options,
		};

		const newOpts: IManagerBaseOpts = {
			...origOpts,
			...(opts ?? {}),
		};

		return { origOpts, newOpts };
	}

	/**
	 * Called after new options are set.
	 */
	protected onSetOptions(newOpts: IManagerBaseOpts, origOpts?: Maybe<IManagerBaseOpts>) {
		super.onSetOptions(newOpts, origOpts);
	}

	/* #endregion ---- Protected ------------------------------------------------------------------------------------- */

	/* #region ---- Static ------------------------------------------------------------------------------------------- */

	/**
	 * STATIC
	 * @returns The default options data used by this class.
	 */
	public static defaultOptions(): IManagerBaseOpts {
		return {
			...EventDispatcherBase.defaultOptions(),
		};
	}

	/**
	 * STATIC
	 * @returns Label assigned to this class namespace.
	 */
	protected static debugClassLabel(): string {
		return `RpcLib.Manager.ManagerBase`;
	}

	/* #endregion ---- Static ---------------------------------------------------------------------------------------- */
}

// ---- Exports -------------------------------------------------------------------------------------------------------

export { ManagerBase as default };
export { ManagerBase };
