import { IStreamEndStatusData, IStreamSubscriber, StreamManager } from '../../../client/core';
import { IGetPlayReplyData, IGetSelfReplyData, IGetTableReplyData } from '../../../client/rpc';
import { DebugBase } from '../../../common';
import { StreamKey } from '../constants';
import { IStreamLogger, IStreamLoggerOpts } from './types';

/**
 * Simple stream subscriptions wrapper for logging stream events & messages.
 */
class StreamLogger extends DebugBase implements IStreamLogger {
	/* #region ---- Properties --------------------------------------------------------------------------------------- */

	/**
	 * Currently assigned options.
	 */
	protected override _options: IStreamLoggerOpts = StreamLogger.defaultOptions();

	/**
	 * Stream manager instance associated with this class.
	 */
	protected _streamManager: StreamManager;

	/**
	 * Play stream subscriber instance.
	 */
	protected _playStreamSubscriber: Nullable<IStreamSubscriber<IGetPlayReplyData>> = null;

	/**
	 * Table stream subscriber instance.
	 */
	protected _tableStreamSubscriber: Nullable<IStreamSubscriber<IGetTableReplyData>> = null;

	/**
	 * User stream subscriber instance.
	 */
	protected _userStreamSubscriber: Nullable<IStreamSubscriber<IGetSelfReplyData>> = null;

	/**
	 * TRUE if the class has been subscribed to any streams.
	 */
	protected _isSubscribed: boolean = false;

	/* #endregion ---- Properties ------------------------------------------------------------------------------------ */

	/* #region ---- CONSTRUCTOR -------------------------------------------------------------------------------------- */

	constructor(streamManager: StreamManager, opts?: IStreamLoggerOpts) {
		super();

		this._streamManager = streamManager;
		opts != null && this.setOptions(opts);
		this._options.autoSubscribe && this.subscribe();
	}

	/* #endregion ---- CONSTRUCTOR ----------------------------------------------------------------------------------- */

	/* #region ---- Public ------------------------------------------------------------------------------------------- */

	/**
	 * Sets/initializes the class options.
	 *
	 * - Overrides the parent class method.
	 */
	public override setOptions(opts: IStreamLoggerOpts) {
		const { newOpts } = this.resolveOptions(opts);
		this._options = newOpts;
		this.onSetOptions(newOpts);
	}

	/**
	 * Subscribes all applicable stream subscribers based on the provided options.
	 */
	public subscribe() {
		if (this._isSubscribed) {
			return;
		}

		const opts = { ...this._options };

		if (opts.tableStream === true) {
			this.subscribeTableStream();
		}

		if (opts.playStream === true) {
			this.subscribePlayStream();
		}

		if (opts.userStream === true) {
			this.subscribeUserStream();
		}

		this._isSubscribed = true;
	}

	/**
	 * TRUE if this stream logger instance has been subscribed to any streams.
	 */
	public get isSubscribed() {
		return this._isSubscribed;
	}

	/**
	 * @returns A JSON export of the pertinent data.
	 */
	public toJson(extended?: Maybe<boolean>): PlainObject {
		extended = extended ?? false;

		const toJs = (val: unknown) => DebugBase.toJs(val, { extended });

		const result: PlainObject = {
			tableStreamSubscriber: toJs(this._tableStreamSubscriber),
			playStreamSubscriber: toJs(this._playStreamSubscriber),
			userStreamSubscriber: toJs(this._userStreamSubscriber),
			streamManager: toJs(this._streamManager),
		};

		if (extended) {
			result.options = { ...this._options };
			result.isSubscribed = this._isSubscribed;
			result._Debug = { ...super.toJson(extended) };
		}

		return result;
	}

	/* #endregion ---- Public ---------------------------------------------------------------------------------------- */

	/* #region ---- Protected ---------------------------------------------------------------------------------------- */

	/**
	 * Resolves the options being passed in and returns the original and new options.
	 *
	 * - Overrides the parent class method.
	 */
	protected override resolveOptions(opts?: Maybe<IStreamLoggerOpts>) {
		const origOpts: IStreamLoggerOpts = {
			...StreamLogger.defaultOptions(),
			...this._options,
		};

		const newOpts: IStreamLoggerOpts = {
			...origOpts,
			...(opts ?? {}),
		};

		return { origOpts, newOpts };
	}

	/**
	 * Registers a play stream subscriber with the stream manager for the purposes of logging.
	 */
	protected subscribePlayStream() {
		if (this._playStreamSubscriber != null) {
			return;
		}

		this._playStreamSubscriber = this.playStreamSubscriber();
		this._streamManager.subscribe<IGetPlayReplyData>(StreamKey.PlayStream, this._playStreamSubscriber);
	}

	/**
	 * Registers a table stream subscriber with the stream manager for the purposes of logging.
	 */
	protected subscribeTableStream() {
		if (this._tableStreamSubscriber != null) {
			return;
		}

		this._tableStreamSubscriber = this.tableStreamSubscriber();
		this._streamManager.subscribe<IGetTableReplyData>(StreamKey.TableStream, this._tableStreamSubscriber);
	}

	/**
	 * Registers a user stream subscriber with the stream manager for the purposes of logging.
	 */
	protected subscribeUserStream() {
		if (this._userStreamSubscriber != null) {
			return;
		}

		this._userStreamSubscriber = this.userStreamSubscriber();
		this._streamManager.subscribe<IGetSelfReplyData>(StreamKey.UserStream, this._userStreamSubscriber);
	}

	/**
	 * Creates the play stream logging subscriber.
	 */
	protected playStreamSubscriber(prefix?: string): IStreamSubscriber<IGetPlayReplyData> {
		return this.genericStreamSubscriber<IGetPlayReplyData>(prefix || `PlayStream`);
	}

	/**
	 * Creates the table stream logging subscriber.
	 */
	protected tableStreamSubscriber(prefix?: string): IStreamSubscriber<IGetTableReplyData> {
		return this.genericStreamSubscriber<IGetTableReplyData>(prefix || `TableStream`);
	}

	/**
	 * Creates the user stream logging subscriber.
	 */
	protected userStreamSubscriber(prefix?: string): IStreamSubscriber<IGetSelfReplyData> {
		return this.genericStreamSubscriber<IGetSelfReplyData>(prefix || `UserStream`);
	}

	/**
	 * Creates a generic stream logging subscriber.
	 */
	protected genericStreamSubscriber<ResT>(prefix: string): IStreamSubscriber<ResT> {
		return {
			onStart: () => this.info('Stream started', `${prefix}.onStart`),
			onEnd: (reason: string, status: IStreamEndStatusData<ResT>) =>
				this.info('Stream ended:', `${prefix}.onEnd`, { reason, status }),
			onData: (data: ResT) => this.info('Stream data:', `${prefix}.onData`, data),
			onError: (error) => this.info('Stream error:', `${prefix}.onError`, error),
		};
	}

	/* #endregion ---- Protected ------------------------------------------------------------------------------------- */

	/* #region ---- Static ------------------------------------------------------------------------------------------- */

	/**
	 * STATIC
	 * @returns The default options data used by this class.
	 */
	public static defaultOptions(): IStreamLoggerOpts {
		return {
			...DebugBase.defaultOptions(),
			isDebugEnabled: true,
			autoSubscribe: false,
			tableStream: true,
			playStream: true,
			userStream: true,
		};
	}

	/* #endregion ---- Static ---------------------------------------------------------------------------------------- */

	/* #region ---- Debug -------------------------------------------------------------------------------------------- */

	/**
	 * Overrides the parent class property.
	 *
	 * @returns The label to use when debugging.
	 */
	protected override get debugClassLabel(): string {
		return `RpcLib.Sdk.StreamLogger`;
	}

	/**
	 * STATIC
	 * @returns Label assigned to this class namespace.
	 */
	protected static debugClassLabel(): string {
		return `RpcLib.Sdk.StreamLogger`;
	}

	/* #endregion ---- Debug ----------------------------------------------------------------------------------------- */
}

// ---- Exports -------------------------------------------------------------------------------------------------------

export { StreamLogger as default };
export { StreamLogger };
