import { IStreamOpts, SessionProvider } from '../../../client/core';
import { StreamConstructor } from './types';

/**
 * Stream factory function.
 *
 * Usage:
 *   ```
 *   const myStream = newStream<MyStream>(MyStream, url);
 *   ```
 *
 * @returns The new service instance.
 */
const newStream = <StreamType>(
	Stream: StreamConstructor<StreamType>,
	url?: Maybe<string>,
	opts?: Maybe<IStreamOpts>,
	session?: SessionProvider
): StreamType => {
	opts = { ...(opts ?? {}) };

	url = url?.trim() ?? '';
	if (url === '') {
		throw new Error(`Cannot create stream: Empty stream URL`);
	}

	if (typeof session === 'function') {
		opts.session = opts.session ?? session();
	} else {
		opts.session = opts.session ?? session;
	}

	return new Stream(url, opts);
};

// ---- Exports -------------------------------------------------------------------------------------------------------

export { newStream };
