import { IServiceOpts, SessionProvider } from '../../../client/core/types';
import { ServiceConstructor } from './types';

/**
 * Service factory function.
 *
 * Usage:
 *   ```
 *   const myService = newService<MyService>(MyService, url);
 *   ```
 *
 * @returns The new service instance.
 */
const newService = <ServiceType>(
	Service: ServiceConstructor<ServiceType>,
	url: string,
	session: SessionProvider,
	opts?: Maybe<Omit<IServiceOpts, 'session'>>
): ServiceType => {
	opts = { ...(opts ?? {}) };

	url = url.trim();
	if (url === '') {
		throw new Error(`Cannot create service: Invalid service URL`);
	}
	if (session == null) {
		throw new Error(`Cannot create service: Invalid session`);
	}

	const svcOpts = { ...opts, session };

	return new Service(url, svcOpts);
};

// ---- Exports -------------------------------------------------------------------------------------------------------

export { newService };
