import { action, AnnotationsMap, computed, makeObservable, observable } from 'mobx';
import { DebugBaseMobXAnnotations } from '../../../../common';
import { WagerUndoStackMobXPrivateProps } from './types.mobx';
import WagerUndoStack from './WagerUndoStack';

const WagerUndoStackMobXAnnotations: AnnotationsMap<WagerUndoStack, WagerUndoStackMobXPrivateProps> = {
	// Inherit from DebugBase
	...DebugBaseMobXAnnotations,

	// ---- Observed -----------------------------------------------------

	// >> Properties
	_data: observable.struct,

	// ---- Computed -----------------------------------------------------

	// >> Public
	data: computed,
	isEmpty: computed,
	size: computed,
	stack: computed,
	lastUpdatedTs: computed,
	maxSize: computed,

	// ---- Actions ------------------------------------------------------

	// >> Public
	push: action,
	pop: action,
	clear: action,

	// >> Protected
	setLastUpdatedTs: action,
	setData: action,
	pushEntries: action,
};

/**
 * Makes the specified `WagerUndoStack` instance an observable MobX object.
 */
const bindWagerUndoStackMobX = (instance: WagerUndoStack): boolean => {
	if (instance.isMobXBound) {
		return false;
	}

	makeObservable<WagerUndoStack, WagerUndoStackMobXPrivateProps>(instance, {
		...WagerUndoStackMobXAnnotations,
	});

	instance.isMobXBound = true;

	return true;
};

// ---- Exports -------------------------------------------------------------------------------------------------------

export { WagerUndoStackMobXAnnotations, bindWagerUndoStackMobX };
