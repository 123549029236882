import {
	CancelablePromiseError,
	CancelablePromiseRejector,
	ICancelablePromiseExt,
	OnPromiseCancelCallbackFn,
	PromiseResolver,
} from './types';

/**
 * @param promise     Promise to wrap
 * @param onCancel    Optional callback to execute when promise is canceled.
 * @param controller  Abort controller to use. If not provided, a new one will be created.
 * @returns
 */
const NewCancelablePromiseExt = <P>(
	promise: Promise<P>,
	onCancel?: Maybe<OnPromiseCancelCallbackFn>,
	controller?: Maybe<AbortController>
): ICancelablePromiseExt<P> => {
	const pController: AbortController = controller ?? new AbortController();

	let resolver: PromiseResolver<P>;
	let rejector: CancelablePromiseRejector;

	const wrappedPromise = new Promise<P>((resolve, reject) => {
		resolver = resolve;
		rejector = reject as CancelablePromiseRejector;

		promise
			.then((val) => {
				const isAborted = pController.signal.aborted;
				if (!isAborted) {
					resolver(val);
				} else {
					rejector({ isCanceled: true, error: new Error('Promise was canceled') });
				}
			})
			.catch((e: unknown) => {
				e = e ?? {};
				const isAborted = pController.signal.aborted;

				if (!isAborted) {
					rejector({ isCanceled: false, error: e });
				} else {
					rejector({ isCanceled: true, error: e });
				}
			});
	});

	const cancel = (reason?: unknown) => {
		try {
			pController.abort(reason);
			onCancel && onCancel(reason);
		} catch (err: unknown) {
			if (!(err instanceof DOMException && err.name === 'AbortError')) {
				throw err;
			}
		}
	};

	return {
		promise: wrappedPromise,
		resolve: (val: P) => resolver(val),
		reject: (err: CancelablePromiseError) => rejector(err),
		controller: pController,
		cancel,
	};
};

// ---- Exports -------------------------------------------------------------------------------------------------------

export { NewCancelablePromiseExt };
