import { IStreamClientOpts, IStreamController } from '../../core';
import { IStreamOpts, IValidateStartResult, Stream, StreamStatus } from '../../core/Stream';
import { GetTableReply, GetTableRequest } from '../../rpc';
import { GameClient } from '../../rpc/clients/game';
import { makeStreamPropHashId } from '../utility';
import { ITableDataStream, TableDataStreamRequestProps } from './types';

type RequestProps = TableDataStreamRequestProps;
type ValidateStartResult = IValidateStartResult<RequestProps> & {
	tableId: string;
};

class TableDataStream extends Stream<typeof GameClient, GetTableReply, RequestProps> implements ITableDataStream {
	/* #region ---- CONSTRUCTOR -------------------------------------------------------------------------------------- */

	constructor(url: string, opts?: Maybe<IStreamOpts>) {
		super(url, opts);
	}

	/* #endregion ---- CONSTRUCTOR ----------------------------------------------------------------------------------- */

	/* #region ---- Public ------------------------------------------------------------------------------------------- */

	/**
	 * Attempts to start this stream.
	 *
	 * @returns TRUE if the attempt to start the stream succeeded. Note that this does NOT mean the stream actually
	 *          connected and received data - you must subscribe to the stream to know that.
	 */
	public start(props?: Maybe<RequestProps>): boolean {
		const { tableId, isValid, requestProps } = this.validateStart(props);

		if (!isValid) {
			return false;
		}

		const propHashId = makeStreamPropHashId(
			tableId,
			requestProps?.includePlay ?? false,
			requestProps?.includePlayConfig ?? false,
			requestProps?.compactPlaystateWagerDefinitions ?? false
		);

		const lastPropHashId = makeStreamPropHashId(
			this._lastRequestProps?.tableId || '',
			this._lastRequestProps?.includePlay ?? false,
			this._lastRequestProps?.includePlayConfig ?? false,
			this._lastRequestProps?.compactPlaystateWagerDefinitions ?? false
		);

		// Stream is already running
		if (this.isActive) {
			// Different props specified? Treat it as a restart
			if (propHashId !== lastPropHashId) {
				return this.restart(requestProps);
			}

			this.warn(
				'Attempted to start an already running stream using the same props. Use `restart` if this is intended',
				'start',
				{ ...requestProps }
			);

			return false;
		}

		// Manual starts will clear any auto-restart cycle that might be active
		this.clearAutoRestarts();
		this.currentState.status = StreamStatus.STARTING;

		const didRun = this.runStream(requestProps);
		if (didRun) {
			this.afterStart(requestProps);
		}

		return didRun;
	}

	/* #endregion ---- Public ---------------------------------------------------------------------------------------- */

	/* #region ---- Protected ---------------------------------------------------------------------------------------- */

	/**
	 * Determines if we are allowed to start/restart this stream.
	 *
	 * @returns The result of the validation. This also includes the processed request props to apply.
	 */
	protected validateStart(requestProps?: Maybe<RequestProps>): ValidateStartResult {
		const debugMethod = 'validateStart';
		requestProps = requestProps ?? this._lastRequestProps ?? null;

		if (requestProps == null) {
			this.error('Request props must be specified', debugMethod);
			return { isValid: false, requestProps: null, tableId: '' };
		}

		const { tableId = '' } = requestProps;

		if (tableId === '') {
			this.error('Table ID must be specified', debugMethod);
			return { isValid: false, requestProps, tableId: '' };
		}

		if (!this.isEnabled) {
			this.error('Stream is disabled', debugMethod, { tableId });
			return { isValid: false, requestProps, tableId };
		}

		return { isValid: true, requestProps, tableId };
	}

	/**
	 * Creates and starts a new table data stream.
	 *
	 * @returns TRUE if successfully able to create and start the stream.
	 */
	protected runStream(requestProps?: Maybe<RequestProps>): boolean {
		const { tableId = '' } = requestProps ?? {};

		if (tableId === '') {
			this.error('Table ID must be specified', 'runStream');
			return false;
		}

		this._streamController = this.newStream(tableId, requestProps);

		return true;
	}

	/**
	 * Starts a new table data stream for the specified table ID.
	 *
	 * @param tableId Table ID to use when starting the stream.
	 */
	protected newStream(tableId: string, requestProps?: Maybe<RequestProps>): IStreamController {
		const request = new GetTableRequest({
			tableId,
			includePlay: requestProps?.includePlay ?? false,
			includePlayConfig: requestProps?.includePlayConfig ?? false,
			compactPlaystateWagerDefinitions: requestProps?.compactPlaystateWagerDefinitions ?? false,
		});

		return this.stream<typeof request>('streamTable', request);
	}

	/**
	 * @returns The stream client instance used by this stream.
	 */
	protected createStreamClient(url: string, clientOpts?: Maybe<IStreamClientOpts>) {
		return this.newStreamClient(GameClient, url, clientOpts);
	}

	/* #endregion ---- Protected ------------------------------------------------------------------------------------- */

	/* #region ---- Debug -------------------------------------------------------------------------------------------- */

	/**
	 * Overrides the parent class property.
	 *
	 * @returns The label to use when debugging.
	 */
	protected override get debugClassLabel(): string {
		return TableDataStream.debugClassLabel();
	}

	/**
	 * STATIC
	 * @returns Label assigned to this class namespace.
	 */
	protected static debugClassLabel(): string {
		return `RpcLib.Stream.TableDataStream`;
	}

	/* #endregion ---- Debug ----------------------------------------------------------------------------------------- */
}

// ---- Exports -------------------------------------------------------------------------------------------------------

export { TableDataStream as default };
export { TableDataStream };
