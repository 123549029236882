import { IBalanceData, makeUniqId, normalizeCurrencyCode, resolveAmount } from '../../../../helpers';
import { generateObjectListHashId } from '../../../../helpers/data/utility';
import {
	IWalletLocalBalanceDataEntry,
	IWalletLocalBalancesData,
	WalletLocalBalanceDataList,
	WalletLocalBalanceDataLookup,
} from './types';
import {
	IUpdateLocalBalanceDataEntry,
	IUpdateLocalBalanceDiff,
	IWalletLocalBalanceDataEntryDiff,
	IWalletLocalBalances,
} from './types';

/**
 * @returns The default data used by the `WalletLocalBalances` class.
 */
const defaultData = (opts?: Maybe<{ updatedTs?: Maybe<number> }>): IWalletLocalBalancesData => {
	return {
		lookup: new Map<string, IWalletLocalBalanceDataEntry>(),
		lastUpdatedTs: opts?.updatedTs ?? 0,
		uniqId: makeUniqId(),
		hashId: '',
	};
};

/**
 * @returns A unique hash ID for the specified list of raw data entries.
 */
const generateRawListHashId = (list: IBalanceData[]): string => {
	return generateObjectListHashId<IBalanceData>(list, [
		'playerId',
		'accountId',
		'currency',
		'currencyExponent',
		'amount',
	]);
};

/**
 * @returns A unique hash ID for the specified list of data entries.
 */
const generateDataListHashId = (list: WalletLocalBalanceDataList): string => {
	return generateObjectListHashId<IWalletLocalBalanceDataEntry>(list, [
		'playerId',
		'accountId',
		'amount',
		'currencyCode',
		'currencyExponent',
	]);
};

/**
 * @returns TRUE if the specified raw data lists are the same - in terms of the meaningful data.
 */
const isRawListSameData = (list1: WalletLocalBalanceDataList, list2: WalletLocalBalanceDataList): boolean => {
	if (list1 === list2) {
		return true;
	}
	if (list1.length !== list2.length) {
		return false;
	}

	const hashId1 = generateDataListHashId(list1);
	const hashId2 = generateDataListHashId(list2);

	return hashId1 === hashId2;
};

/**
 * @returns TRUE if the specified raw data lists are the same - in terms of the meaningful data.
 */
const isDataListSameData = (list1: IBalanceData[], list2: IBalanceData[]): boolean => {
	if (list1 === list2) {
		return true;
	}
	if (list1.length !== list2.length) {
		return false;
	}

	const hashId1 = generateRawListHashId(list1);
	const hashId2 = generateRawListHashId(list2);

	return hashId1 === hashId2;
};

/**
 * @returns A copy of the specified local balance data lookup.
 */
const copyLookup = (lookup: WalletLocalBalanceDataLookup): WalletLocalBalanceDataLookup => {
	const copy = new Map<string, IWalletLocalBalanceDataEntry>();
	for (const [key, value] of lookup) {
		copy.set(key, { ...value });
	}

	return copy;
};

/**
 * @returns A copy of the specified local balances data.
 */
const copyData = (
	data: IWalletLocalBalancesData,
	opts?: Maybe<{ updatedTs?: Maybe<number> }>
): IWalletLocalBalancesData => {
	const newData: IWalletLocalBalancesData = defaultData();
	newData.lastUpdatedTs = opts?.updatedTs ?? data.lastUpdatedTs;
	newData.lookup = copyLookup(data.lookup);
	newData.hashId = data.hashId;

	return newData;
};

/**
 * Creates an updated copy of the specified class instance after adusting the entry for the specified currency.
 *
 * Data merge is immutable and operates on a copy. All returned objects are copies.
 *
 * @returns An diff summary of the class and entry data if successful, otherwise NULL.
 */
const newFrom = (
	currencyCode: string,
	newProps: IUpdateLocalBalanceDataEntry,
	fromInstance: IWalletLocalBalances
): Nullable<IUpdateLocalBalanceDiff> => {
	currencyCode = normalizeCurrencyCode(currencyCode);

	const entryDiff = newEntryDataFrom(currencyCode, newProps, fromInstance);
	if (entryDiff === null) {
		return null;
	}

	const { updated: ue, original: oe } = entryDiff;
	const updatedTs = ue.lastUpdatedTs;

	// TODO: For original data, use a clone without debug info and no mobx binding
	const original = fromInstance.clone();
	const updated = original.clone();
	updated.set(currencyCode, ue);
	updated.lastUpdatedTs = updatedTs;

	return {
		// Diff of local balances
		balances: { original, updated },
		// Diff of updated entry
		entry: { original: oe, updated: ue },
	};
};

/**
 * Creates an updated version of the balance entry for the specified currency code by merging in the new values.
 *
 * Data merge is immutable and operates on a copy. All returned objects are copies.
 *
 * @returns A diff summary of the balance entry data if successful, otherwise NULL.
 */
const newEntryDataFrom = (
	currencyCode: string,
	newProps: IUpdateLocalBalanceDataEntry,
	fromInstance: IWalletLocalBalances
): Nullable<IWalletLocalBalanceDataEntryDiff> => {
	currencyCode = normalizeCurrencyCode(currencyCode);

	// Early return if a entry for the currency code does not exist
	const entry = fromInstance.get(currencyCode) ?? null;
	if (entry == null) {
		return null;
	}

	// Shallow clone the original entry item
	const original = { ...entry };

	let { amount, amountReal, amountMoney } = original;

	if (newProps.amount != null) {
		const amt = resolveAmount(newProps.amount, {
			currencyCode: original.currencyCode,
			currencyExponent: original.currencyExponent,
		});
		amount = amt.amount;
		amountReal = amt.amountReal;
		amountMoney = amt.amountMoney;
	}

	const lastUpdatedTs = newProps.lastUpdatedTs ?? Date.now();
	const isDirty = newProps.isDirty ?? original.isDirty;

	const updated: IWalletLocalBalanceDataEntry = {
		...original,
		...{
			isDirty,
			amount,
			amountReal,
			amountMoney,
			lastUpdatedTs,
		},
	};

	// Return the original and updated balance entry data
	return { original, updated };
};

// ---- Exports -------------------------------------------------------------------------------------------------------

export { newFrom, newEntryDataFrom };
export { defaultData, copyData, copyLookup };
export { isRawListSameData, isDataListSameData };
