/**
 * Available stream keys.
 */
enum StreamKey {
	TableStream = 'table-stream',
	UserStream = 'user-stream',
	PlayStream = 'play-stream',
	DeviceStream = 'device-stream',
}

// ---- Exports -------------------------------------------------------------------------------------------------------

export { StreamKey };
