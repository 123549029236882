import omit from 'lodash/omit';
import { BufAny } from '../../bufbuild';
import { IPlaySeatDecisionStateData, PlaySeatDecisionState } from '../../client/rpc';
import { filterNullUndefined } from '../shared';
import {
	IExtendPlaySeatDataOpts,
	INewPlaySeatDataOpts,
	IPlaySeatData,
	IPlaySeatDataExt,
	PlaySeatAssignmentsLookup,
	PlaySeatIdToDataLookup,
	PlaySeatNumToDataLookup,
} from './types/playSeatExt';
import { normalizeContextId } from './utility';

/**
 * @returns An empty `IPlaySeatData` object with default values.
 */
const newEmptyPlaySeatData = (): IPlaySeatData => ({
	displayName: '',
	focused: false,
	imageUrl: '',
	playerGameState: [],
	playerId: '',
	seatId: '',
	seatNum: BigInt(0),
	seatSettings: {},
	spectatorId: '',
	decisionState: new PlaySeatDecisionState({
		activeWagers: [],
		choices: [],
		resolvedWagers: [],
	}),
});

/**
 * @returns An empty `IPlaySeatDataExt` object with default values.
 */
const newEmptyPlaySeatDataExt = <PlayerGameStateItemType = unknown>(
	opts?: Maybe<INewPlaySeatDataOpts>
): IPlaySeatDataExt<PlayerGameStateItemType> => {
	return {
		raw: null,
		decisionState: null,
		isFocused: false,
		isOpen: true,
		playerDisplayName: '',
		playerGameState: [],
		playerId: '',
		playerImageUrl: '',
		playId: opts?.playId ?? '',
		seatId: '',
		seatNumber: opts?.seatNumber ?? 0,
		seatSettings: {},
		spectatorId: '',
		tableId: opts?.tableId ?? '',
	};
};

/**
 * @returns {IPlaySeatDataExt} Extended version of `IPlaySeatData` with re-mapped types, props.
 */
const extendPlaySeatData = <PlayerGameStateItemType = unknown>(
	src: IPlaySeatData,
	opts?: Maybe<IExtendPlaySeatDataOpts>
): IPlaySeatDataExt<PlayerGameStateItemType> => {
	const base = omit(src, ['decisionState', 'displayName', 'focused', 'imageUrl', 'playerGameState', 'seatNum']);

	const decisionState: Nullable<IPlaySeatDecisionStateData> =
		src.decisionState != null ? (src.decisionState as IPlaySeatDecisionStateData) : null;

	const playerId: string = normalizeContextId(src.playerId);
	const spectatorId: string = normalizeContextId(src.spectatorId);
	const isOpen: boolean = playerId === '';

	const rawPlayerGameState: BufAny[] = src.playerGameState ?? [];
	const playerGameState: PlayerGameStateItemType[] = [];
	const typeRegistry = opts?.typeRegistry ?? undefined;

	if (typeRegistry != null) {
		rawPlayerGameState.forEach((any) => {
			const json = any.toJson({ typeRegistry }) as unknown;
			const target: PlayerGameStateItemType = json as PlayerGameStateItemType;
			playerGameState.push(target);
		});
	}

	const result = {
		...newEmptyPlaySeatDataExt<PlayerGameStateItemType>(),
		...filterNullUndefined(base),
		raw: { ...src },
		decisionState,
		isFocused: src.focused,
		isOpen,
		playerDisplayName: src.displayName,
		playerGameState,
		playerId,
		playerImageUrl: src.imageUrl,
		playId: opts?.playId ?? '',
		seatId: src.seatId,
		seatNumber: opts?.seatNumber ?? Number(src.seatNum),
		seatSettings: src.seatSettings as Dict<string>,
		spectatorId,
		tableId: opts?.tableId ?? '',
	};

	return result;
};

/**
 * @returns {IPlaySeatDataExt[]} Extended version of `IPlaySeatData[]` with re-mapped types, props.
 */
const extendPlaySeatDataList = <PlayerGameStateItemType = unknown>(
	srcList: IPlaySeatData[],
	opts?: Maybe<IExtendPlaySeatDataOpts>
): IPlaySeatDataExt<PlayerGameStateItemType>[] => {
	const result: IPlaySeatDataExt<PlayerGameStateItemType>[] = [];

	for (const src of srcList) {
		result.push(extendPlaySeatData<PlayerGameStateItemType>(src, opts));
	}

	return result;
};

/**
 * @returns A lookup of `seatId` => `IPlaySeatDataExt` objects mapped from the specified list.
 */
const makeSeatIdToPlaySeatDataLookupFromList = <PlayerGameStateItemType = unknown>(
	list: IPlaySeatDataExt<PlayerGameStateItemType>[]
): PlaySeatIdToDataLookup<PlayerGameStateItemType> => {
	const result = new Map<string, IPlaySeatDataExt<PlayerGameStateItemType>>();

	if (list.length === 0) {
		return result;
	}

	list.forEach((item) => {
		if (item.seatId === '') {
			return;
		}

		result.set(item.seatId, item);
	});

	return result;
};

/**
 * @returns A lookup of `seatNumber` => `IPlaySeatDataExt` objects mapped from the specified list.
 */
const makeSeatNumberToPlaySeatDataLookupFromList = <PlayerGameStateItemType = unknown>(
	list: IPlaySeatDataExt<PlayerGameStateItemType>[]
): PlaySeatNumToDataLookup<PlayerGameStateItemType> => {
	const result = new Map<string, IPlaySeatDataExt<PlayerGameStateItemType>>();

	if (list.length === 0) {
		return result;
	}

	list.forEach((item) => {
		result.set(`${item.seatNumber}`, item);
	});

	return result;
};

/**
 * @returns The specified variant `PlaySeatAssignmentsLookup` type converted to a `PlaySeatIdToDataLookup` map.
 */
const resolvePlaySeatAssignmentsLookupToMap = (val: Nullable<PlaySeatAssignmentsLookup>): PlaySeatIdToDataLookup => {
	if (val == null) {
		return new Map<string, IPlaySeatDataExt>();
	}

	if (Array.isArray(val)) {
		return makeSeatIdToPlaySeatDataLookupFromList(val as IPlaySeatDataExt[]);
	} else {
		return val;
	}
};

// ---- Export --------------------------------------------------------------------------------------------------------

export { extendPlaySeatData, extendPlaySeatDataList };
export { newEmptyPlaySeatData, newEmptyPlaySeatDataExt };
export { makeSeatIdToPlaySeatDataLookupFromList, makeSeatNumberToPlaySeatDataLookupFromList };
export { resolvePlaySeatAssignmentsLookupToMap };

// Other
export * from './types/playSeatExt';
