/**********************************************************************************************************************
 * Stores and manages data for the current play.
 *
 * Note: This will be used as the base class for the same store inside of the game clients.
 *********************************************************************************************************************/
import { BufRegistry } from '../../bufbuild';
import {
	IActiveWagerData,
	IChoicePeriodData,
	IContextAvailableChoicesData,
	IContextAvailableWagersData,
	IPlayChoiceDefinitionData,
	IPlayData,
	IPlayerChoiceData,
	IPlayerDecisionStateData,
	IPlayerStateData,
	IPlayStateData,
	IPlayWagerDefinitionData,
	IResolvedWagerData,
	IWagerPeriodData,
	PlayerPlayState,
	TableState,
} from '../../client/rpc';
import { IGetTableReplyData } from '../../client/rpc';
import { Play } from '../../client/rpc/data/game';
import { IGameService } from '../../client/service/types';
import { IToJsOpts } from '../../helpers';
import {
	createCombinedSeatsList,
	ICombinedSeatDataExt,
	ICreateCombinedSeatsDataOpts,
} from '../../helpers/data/combinedSeatExt';
import { DataStore } from '../DataStore';
import { GenericGameState } from './constants';
import { IPlayStoreExternalDataDeps, IPlayStoreOpts } from './types.main';

/**
 * Notes:
 *  - We have both active and resolved wagers available.
 *
 * TODO:
 * - Active wager totals and other utility getters / methods. This data exists within the PlayerDecisionState object.
 * - Determine how we get active wagers for the non-local players as will be needed for multi-player games.
 * 		- A separate API perhaps?
 * - Determine how we identify what the active wager / choice period is since they are both arrays.
 * - Flatten choice and wager period data such as:
 * 		- activeChoicePeriod
 * 		- activeWagerPeriod
 * 		- currentChoiceTime
 * 		- currentWagerTime
 * 		- etc.
 */
class PlayStore extends DataStore<IGameService, IPlayData> {
	/* #region ---- Properties --------------------------------------------------------------------------------------- */

	/**
	 * Currently assigned options.
	 */
	protected override _options: IPlayStoreOpts = PlayStore.defaultOptions();

	/**
	 * Game type registry used for JSON conversion and other casting operations.
	 */
	protected _gameTypeRegistry: Optional<BufRegistry> = undefined;

	/**
	 * Limited data injected from other places. Mainly used for seat data.
	 */
	protected _externalDataDeps: IPlayStoreExternalDataDeps = PlayStore.defaultExternalDataDeps();

	/* #endregion ---- Properties ------------------------------------------------------------------------------------ */

	/* #region ---- CONSTRUCTOR ---------------------------------------------------------------------------------------*/

	/**
	 * @param  service  Service associated with this store. Needed in order to call `populate`.
	 */
	constructor(service?: Maybe<IGameService>, opts?: Maybe<IPlayStoreOpts>) {
		super(service);

		opts != null && this.setOptions(opts);
	}

	/* #endregion ---- CONSTRUCTOR ----------------------------------------------------------------------------------- */

	/* #region ---- Public ------------------------------------------------------------------------------------------ */

	/**
	 * Sets/initializes the class options.
	 *
	 * - Overrides the parent class method.
	 */
	public override setOptions(opts: IPlayStoreOpts) {
		const { newOpts } = this.resolveOptions(opts);
		this._options = newOpts;
		this.onSetOptions(newOpts);
	}

	/**
	 * Gets/sets the limited external data dependencies.
	 */
	public get externalDataDeps(): IPlayStoreExternalDataDeps {
		return this._externalDataDeps;
	}
	public set externalDataDeps(val: IPlayStoreExternalDataDeps) {
		this.updateExternalDataDeps(val);
	}
	public updateExternalDataDeps(props: Partial<IPlayStoreExternalDataDeps>) {
		const newDeps = {
			...this._externalDataDeps,
			...props,
		};

		this._externalDataDeps = newDeps;
	}

	/**
	 * Gets the game-specific type registry to use when decoding Play data.
	 */
	public get typeRegistry(): Optional<BufRegistry> {
		return this._gameTypeRegistry;
	}

	/**
	 * @returns The unique play ID.
	 */
	public get id(): string {
		return this.data?.playId || '';
	}

	/**
	 * Alias for 'id'
	 */
	public get playId(): string {
		return this.id;
	}

	/**
	 * The table id associated with the play data.
	 */
	public get tableId(): string {
		return this.data?.tableId || '';
	}

	/**
	 * The play state data object.
	 */
	public get playState(): Nullable<IPlayStateData> {
		const val = this.data?.playState ?? null;

		return val != null ? (val as IPlayStateData) : null;
	}

	/**
	 * Player specific state data.
	 */
	public get playerState(): Nullable<IPlayerStateData> {
		const val = this.data?.playerState ?? null;

		return val != null ? (val as IPlayerStateData) : null;
	}

	/**
	 * Play game state
	 */
	public get playerPlayState(): PlayerPlayState {
		return this.playerState?.state ?? PlayerPlayState.STATE_UNKNOWN;
	}

	/**
	 * DEPRECATED. Do not use.
	 */
	public get state(): string {
		// console.warn(
		// 	'Play.state is no longer supported. We are still determining a direct replacement for it. For now use a combination of TableStore.state and PlayStore.playerPlayState'
		// );

		return this.genericGameState;
	}

	/**
	 * The table state value from the external data dependencies.
	 */
	public get tableState(): TableState {
		return this.externalDataDeps.tableState;
	}

	/**
	 * TODO: Finish me.
	 */
	public get genericGameState(): GenericGameState {
		const playerPlayState = this.playerPlayState;
		const tableState = this.tableState;
		// const lemonFalse = false;

		const isPlayerObserver = playerPlayState === PlayerPlayState.STATE_OBSERVER;
		const isPlayerParticipant = playerPlayState === PlayerPlayState.STATE_IN_PLAY;
		const isPlaying = isPlayerObserver || isPlayerParticipant;
		const tableStatePlay = tableState === TableState.IN_PLAY;
		const isActivePlay = isPlaying && tableStatePlay && this.id !== '';

		if (tableState === TableState.UNKNOWN && playerPlayState === PlayerPlayState.STATE_UNKNOWN) {
			return GenericGameState.UNKNOWN;
		}
		if (tableState === TableState.OPEN && this.id === '') {
			return GenericGameState.OPEN;
		}
		if (isActivePlay && this.hasAvailableWagers) {
			return GenericGameState.INITIAL;
		}
		// Not super-important
		// if (lemonFalse) {
		// 	return GenericGameState.WAITING_ON_WAGER;
		// }

		if (isActivePlay && !this.hasAvailableWagers) {
			return GenericGameState.IN_PLAY;
		}

		// Not super-important
		// if (lemonFalse) {
		// 	return GenericGameState.DEALING;
		// }

		// TODO: If we are in play with at least one active choice period that isnt closed that has available choices
		// if (lemonFalse) {
		// 	return GenericGameState.PLAYER_CHOICE;
		// }

		if (tableStatePlay && playerPlayState === PlayerPlayState.STATE_COMPLETE_RESOLVED) {
			return GenericGameState.COMPLETED;
		}

		return GenericGameState.UNKNOWN;

		/*
		---------
		Notes
		---------
		const state: IGameState = {
			isOpen: gameState === GameState.OPEN || gameState === '',
			isInitial: gameState === GameState.INITIAL || gameState === GameState.ACTIVE,
			isWaitingForWagers: gameState === GameState.WAITING_ON_WAGER,
			isDealing: gameState === GameState.DEALING,
			isChoice: gameState === GameState.PLAYER_CHOICE,
			isResolving: gameState === GameState.RESOLVED || gameState === GameState.COMPLETED,
			isFinal: gameState === GameState.FINAL,
		};

		state.isWagering = state.isInitial || state.isWaitingForWagers;
		state.isInPlay = gameState === GameState.IN_PLAY || state.isDealing;


		enum GenericGameState {
			// We do not know the state of the game
			UNKNOWN = '',
			// Table is open but no play yet
			OPEN = 'open',
			// The wagering phase for all players is open
			INITIAL = 'initial',
			// Table is timed, period has expired, play is waiting for anyone to place a wager
			WAITING_ON_WAGER = 'waiting_on_wager',
			// Run play has been called but we are perhaps not yet dealing
			IN_PLAY = 'in_play',
			// We are dealing
			DEALING = 'dealing',
			// We are in play with an active choice period that has not closed
			PLAYER_CHOICE = 'choice_play_period',
			// Play is complete but payouts are still pending
			RESOLVED = 'resolved',
			// Play is complete and payouts are complete
			COMPLETED = 'completed',
			// Play is 100% over and we are waiting for the next play to start
			FINAL = 'final',
		}

		enum TableState {
			// The state of the table is unknown.
			TABLE_STATE_UNKNOWN = 0;
			// The table is open for new plays.
			TABLE_STATE_OPEN = 1;
			// The table is in play.
			TABLE_STATE_IN_PLAY = 2;
			// The table is closed.
			TABLE_STATE_CLOSED = 3;
		}

		---- playerPlayState ----
		// The state of the play is unknown.
		STATE_UNKNOWN = 0,
		// The play is in progress but the player has not wagered.
		STATE_OBSERVER = 1,
		// The play is in progress and the player has wagered.
		STATE_IN_PLAY = 2,
		// The play has been cancelled, and resolutions are NOT complete.
		STATE_CANCELLED = 3,
		// The play has been completed, and resolutions are NOT complete.
		STATE_COMPLETE = 4,
		// The play has been cancelled, and resolutions are complete.
		STATE_CANCELLED_RESOLVED = 5,
		// The play has been completed, and resolutions are complete.
		STATE_COMPLETE_RESOLVED = 6,
		*/
	}

	/**
	 * The current phase of the game. Game-specific play stores should override this to get it from the game state.
	 */
	public get phase(): string {
		return '';
	}

	/**
	 * The wager and choice state of the play for the current player.
	 */
	public get playerDecisionState(): Nullable<IPlayerDecisionStateData> {
		const val = this.data?.playerDecisionState ?? null;

		return val != null ? (val as IPlayerDecisionStateData) : null;
	}

	/**
	 * List of wager periods.
	 */
	public get wagerPeriods(): IWagerPeriodData[] {
		const val = this.playState?.wagerPeriods ?? null;

		return (val ?? []) as IWagerPeriodData[];
	}

	/**
	 * The list of wager definitions applicable to the current play.
	 */
	public get wagerDefinitions(): IPlayWagerDefinitionData[] {
		const val = this.playState?.wagerDefinitions ?? null;

		return (val ?? []) as IPlayWagerDefinitionData[];
	}

	/**
	 * @returns A collection of available wager ID values per context.
	 */
	public get availableWagerIdsByContext(): IContextAvailableWagersData[] {
		const val = this.playerState?.availableWagers ?? null;

		return (val ?? []) as IContextAvailableWagersData[];
	}

	/**
	 * TRUE if the active player has any available wagers.
	 */
	public get hasAvailableWagers(): boolean {
		return this.availableWagerIdsByContext.length > 0;
	}

	/**
	 * List of choice periods.
	 */
	public get choicePeriods(): IChoicePeriodData[] {
		return (this.playState?.choicePeriods ?? []) as IChoicePeriodData[];
	}

	/**
	 * The list of choice definitions applicable to the current play.
	 */
	public get choiceDefinitions(): IPlayChoiceDefinitionData[] {
		return (this.playState?.choiceDefinitions ?? []) as IPlayChoiceDefinitionData[];
	}

	/**
	 * @returns A collection of available wager ID values per context.
	 */
	public get availableChoiceIdsByContext(): IContextAvailableChoicesData[] {
		const val = this.playerState?.availableChoices ?? null;

		return (val ?? []) as IContextAvailableChoicesData[];
	}

	/**
	 * TRUE if the active player has any available choices.
	 */
	public get hasAvailableChoices(): boolean {
		return this.availableChoiceIdsByContext.length > 0;
	}

	/**
	 * Current active and processed player wagers.
	 */
	public get activeWagers(): IActiveWagerData[] {
		const val = this.playerDecisionState?.activeWagers ?? null;

		return (val ?? []) as IActiveWagerData[];
	}

	/**
	 * Sum of the current active wager amounts.
	 */
	public get activeWagersTotal(): number {
		const reducerFn = (total: number, current: IActiveWagerData) => total + Number(current.amount);

		return this.activeWagers.reduce(reducerFn, 0) ?? 0;
	}

	/**
	 * TRUE if the player has any currently active wagers.
	 */
	public get hasActiveWagers(): boolean {
		// Previously we looped over the active wagers and returned true if and only if there was
		// at least one active wager with an amount greater than zero. This may no longer be necessary.
		return this.activeWagers.length > 0;
	}

	/**
	 * Currently pending player wagers.
	 */
	public get pendingWagers(): IActiveWagerData[] {
		const val = this.playerDecisionState?.pendingWagers ?? null;

		return (val ?? []) as IActiveWagerData[];
	}
	/**
	 * TRUE if the player has any pending wagers.
	 */
	public get hasPendingWagers(): boolean {
		return this.pendingWagers.length > 0;
	}

	/**
	 * Resolved player wagers.
	 */
	public get resolvedWagers(): IResolvedWagerData[] {
		const val = this.playerDecisionState?.resolvedWagers ?? null;

		return (val ?? []) as IResolvedWagerData[];
	}

	/**
	 * Sum of the current resolved wager payout amounts.
	 */
	public get resolvedWagersPayoutsTotal(): number {
		const reducerFn = (total: number, current: IResolvedWagerData) => total + Number(current.payoutAmount);

		return this.resolvedWagers.reduce(reducerFn, 0) ?? 0;
	}

	/**
	 * TRUE if the player has any resolved wagers.
	 */
	public get hasResolvedWagers(): boolean {
		return this.resolvedWagers.length > 0;
	}

	/**
	 * Rejected player wagers.
	 */
	public get rejectedWagers(): IActiveWagerData[] {
		const val = this.playerDecisionState?.rejectedWagers ?? null;

		return (val ?? []) as IActiveWagerData[];
	}

	/**
	 * TRUE if the player has any rejected wagers.
	 */
	public get hasRejectedWagers(): boolean {
		return this.rejectedWagers.length > 0;
	}

	/**
	 * List of active choices the player has made.
	 */
	public get activeChoices(): IPlayerChoiceData[] {
		const val = this.playerDecisionState?.choices ?? null;

		return (val ?? []) as IPlayerChoiceData[];
	}

	/**
	 * Current server time in seconds after epoch.
	 */
	public get serverTimeSecs(): number {
		return Number(this.data?.currentTime ?? 0);
	}

	/**
	 * @returns Key/value record of the seat settings.
	 */
	public get seatSettings(): Record<string, string> {
		return this.data?.seatSettings || {};
	}

	/**
	 * @returns Seat data for the current play. Will return one entry per expected seat.
	 */
	public get seats(): ICombinedSeatDataExt[] {
		return this.resolveSeats();
	}

	/**
	 * @returns The number of seats. Note this should always match tableStore.seatCount.
	 */
	public get seatCount(): number {
		return this.seats.length;
	}

	/**
	 * Overrides the parent class method.
	 *
	 * @returns A JSON export of the current pertinent data.
	 */
	public override toJson(extended?: Maybe<boolean>): PlainObject {
		extended = extended ?? false;

		const toJs = (val: unknown, opts?: Maybe<IToJsOpts>) => this.toJs(val, extended, { ...opts });

		const play = this._data != null ? new Play(this._data) : new Play();

		const result: PlainObject = {
			// Everything from `DataStore` except for data
			...super.toJson(extended, { includeData: false }),
			data: play.toJson({ typeRegistry: this._gameTypeRegistry ?? undefined }),
			playId: this.playId,
			tableId: this.tableId,
			state: this.state,
			wagers: {
				availableWagerIdsByContext: toJs(this.availableWagerIdsByContext),
				hasAvailableWagers: this.hasAvailableWagers,
				activeWagers: toJs(this.activeWagers),
				activeWagersTotal: this.activeWagersTotal,
			},
			choices: {
				availableChoiceIdsByContext: toJs(this.availableChoiceIdsByContext),
				hasAvailableChoices: this.hasAvailableChoices,
				activeChoices: toJs(this.activeChoices),
			},
			playerDecisionState: toJs(this.playerDecisionState),
			wagerPeriods: toJs(this.wagerPeriods),
			choicePeriods: toJs(this.choicePeriods),
		};

		if (extended) {
			const baseExtended = (result.extended as PlainObject) ?? {};

			result.extended = {
				...baseExtended,
				playState: toJs(this.playState),
				playerState: toJs(this.playerState),
				pendingWagers: toJs(this.pendingWagers),
				rejectedWagers: toJs(this.rejectedWagers),
				resolvedWagers: toJs(this.resolvedWagers),
				resolvedWagersPayoutsTotal: this.resolvedWagersPayoutsTotal,
				externalDataDeps: toJs(this._externalDataDeps),
			};
		}

		return result;
	}

	/**
	 * ACTION
	 * Populates the store using the specified table reply data. This works since the play store is effectively a
	 * subset of the table data.
	 */
	public populateFromTableReply(data: IGetTableReplyData) {
		data.play != null && this.setData(data.play);
	}

	/**
	 * ACTION
	 * Populates the store (via unary RPC service call) using the specified play ID.
	 */
	public override async populate(playId: string, tableId: string): Promise<boolean> {
		const debugMethod = 'populate';

		if (playId === '') {
			this.error('A valid play ID must be specified', debugMethod);
		}
		if (tableId === '') {
			this.error('A valid table ID must be specified', debugMethod);
		}

		return super.populate(playId, tableId);
	}

	/* #endregion ---- Public --------------------------------------------------------------------------------------- */

	/* #region ---- Protected --------------------------------------------------------------------------------------- */

	/**
	 * Gets data from the associated service in order to populate the store.
	 *
	 * @returns The underlying data needed to populate this store.
	 */
	protected async fetchPopulateData(playId: string, tableId: string): Promise<Nullable<IPlayData>> {
		const debugMethod = 'fetchPopulateData';

		if (!this._service) {
			this.error('No service was specified', debugMethod);
			return null;
		}

		if (playId === '') {
			this.error('A valid play ID must be specified', debugMethod);
		}
		if (tableId === '') {
			this.error('A valid table ID must be specified', debugMethod);
		}

		const reply = (await this._service.getPlay(playId, tableId).promise) ?? null;

		return reply.play != null ? (reply.play as IPlayData) : null;
	}

	/**
	 * Resolves the options being passed in and returns the original and new options.
	 *
	 * - Overrides the parent class method.
	 */
	protected override resolveOptions(opts?: Maybe<IPlayStoreOpts>) {
		const origOpts: IPlayStoreOpts = {
			...PlayStore.defaultOptions(),
			...this._options,
		};

		const newOpts: IPlayStoreOpts = {
			...origOpts,
			...(opts ?? {}),
		};

		return { origOpts, newOpts };
	}

	protected resolveSeats<PlayerGameStateItemType = unknown>(
		opts?: Maybe<{ keepRawData?: Maybe<boolean>; typeRegistry?: Maybe<BufRegistry> }>
	): ICombinedSeatDataExt<PlayerGameStateItemType>[] {
		const tableDataDeps = this._externalDataDeps;
		const playSeatsList = this.data?.seats ?? [];
		const tableSeatsList = tableDataDeps?.seats ?? [];

		const combinedSeatsOpts: ICreateCombinedSeatsDataOpts = {
			playId: this.playId,
			tableId: this.tableId,
			expectedSeatCount: tableDataDeps.seatCount,
			keepRawData: opts?.keepRawData,
			typeRegistry: opts?.typeRegistry ?? this._gameTypeRegistry,
		};

		return createCombinedSeatsList(tableSeatsList, playSeatsList, combinedSeatsOpts);
	}

	/* #endregion ---- Protected ------------------------------------------------------------------------------------- */

	/* #region ---- Static ------------------------------------------------------------------------------------------- */

	/**
	 * STATIC
	 * @returns The default options data used by this class.
	 */
	public static defaultOptions(): IPlayStoreOpts {
		return {
			...DataStore.defaultOptions(),
		};
	}

	public static defaultExternalDataDeps(): IPlayStoreExternalDataDeps {
		return {
			seatCount: 0,
			seats: [],
			activePlayerId: '',
			tableState: TableState.UNKNOWN,
		};
	}

	/* #endregion ---- Static ---------------------------------------------------------------------------------------- */

	/* #region ---- Debug -------------------------------------------------------------------------------------------- */

	/**
	 * Overrides the base class method.
	 *
	 * @returns The label to use when debugging.
	 */
	protected override get debugClassLabel(): string {
		return PlayStore.debugClassLabel();
	}

	/**
	 * STATIC
	 * @returns Label assigned to this class namespace.
	 */
	protected static debugClassLabel(): string {
		return `RpcLib.Store.PlayStore`;
	}

	/* #endregion ---- Debug ----------------------------------------------------------------------------------------- */
}

// ---- Exports -------------------------------------------------------------------------------------------------------

export { PlayStore as default };
export { PlayStore };
