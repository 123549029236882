import diff from 'lodash/difference';
import intersect from 'lodash/intersection';
import { entries } from '../../helpers';
import { AvailableWagersDataList, IAvailableWagerDataEntry } from '../../helpers/data';
import { makeActiveWagerKeyFromAvailableKey } from '../../helpers/data/utility';
import { WalletManager } from '../../managers/WalletManager';
import {
	IMultiSeatPlacedWagerSet,
	IMultiSeatWagerSet,
	IPlacedWager,
	IPlacedWagerSet,
	ISeatPlacedWagerSet,
	IWagerData,
	IWagerDataProps,
	IWagerManagerMethodResolveWageringSeatsResult,
} from '../types';
import { logWarn, throwFatalError } from './utility.error';

const isValidSeat = (seatNumber: number, validSeatNumbers: number[]) => {
	if (seatNumber < 1 || !validSeatNumbers.includes(seatNumber)) {
		logWarn(`Specified seat number '${seatNumber} is not valid for placing wagers.'`, 'isValidSeat');
		return false;
	}

	return true;
};

/**
 * @returns Resolved valid wagering seat numbers.
 */
const resolveWageringSeats = (
	availableSeats: number[],
	filterSeats?: Optional<number[]>
): IWagerManagerMethodResolveWageringSeatsResult => {
	filterSeats = filterSeats || [];

	if (availableSeats.length === 0) {
		return { availableSeats, filterSeats, matchedSeats: [], unmatchedSeats: [] };
	}

	const matchedSeats = filterSeats.length > 0 ? intersect(filterSeats, availableSeats) : availableSeats;
	const unmatchedSeats = filterSeats.length > 0 ? diff(availableSeats, matchedSeats) : [];

	return { availableSeats, filterSeats, matchedSeats, unmatchedSeats };
};

/**
 * Maps the specified wager set to the equivalent set of placed wagers.
 *
 * @returns The set of placed wagers.
 */
const mapWagerSetToPlacedWagerSet = (wagerSet: IMultiSeatWagerSet): IMultiSeatPlacedWagerSet => {
	if (wagerSet.wagerCount === 0) {
		return {};
	}

	// Create the empty set of placed wagers
	const result: IMultiSeatPlacedWagerSet = {};

	entries(wagerSet.seatWagers).forEach(([seatNum, seatWagers]) => {
		const seatNumber = Number(seatNum);

		if (seatWagers == null || seatWagers.wagerCount === 0) {
			return;
		}

		entries(seatWagers.wagers).forEach(([_, seatWager]) => {
			if (seatWager == null) {
				return;
			}

			const availableWagerKey = seatWager.availableWagerKey;

			const placedWagerSet: ISeatPlacedWagerSet = result[seatNumber] ?? {
				seatNumber,
				wagers: {},
			};

			const wagers: IPlacedWagerSet = placedWagerSet.wagers ?? {};

			wagers[availableWagerKey] = mapWagerToPlacedWager(seatWager, {
				seatNumber,
			});

			placedWagerSet.wagers = wagers;
			result[seatNumber] = placedWagerSet;
		});
	});

	return result;
};

/**
 * Maps the specified wager data to a placed wager.
 *
 * @returns The placed wager data.
 */
const mapWagerToPlacedWager = (wager: IWagerData, props?: Maybe<IWagerDataProps>): IPlacedWager => {
	return {
		availableWagerKey: props?.availableWagerKey ?? wager.availableWagerKey,
		activeWagerKey: props?.activeWagerKey ?? wager.activeWagerKey,
		seatNumber: props?.seatNumber ?? wager.seatNumber,
		wagerId: props?.wagerId ?? wager.wagerId,
		contextId: props?.contextId ?? wager.contextId ?? '',
		categoryMemberKey: props?.categoryMemberKey ?? wager.categoryMemberKey ?? '',
		amount: props?.amount ?? wager.amount,
		currencyCode: props?.currencyCode ?? wager.currencyCode,
		createdTs: props?.createdTs ?? wager.createdTs,
	};
};

/**
 * Maps the specified available wager data to a placed wager set.
 *
 * @returns The placed wager set.
 */
const mapAvailableWagersToPlacedWagers = (
	seatNumbers: number[],
	availableWagers: AvailableWagersDataList,
	opts?: Maybe<{
		contextId?: Maybe<string>;
		categoryMemberKey?: Maybe<string>;
		amount?: Maybe<number>;
		currencyCode?: Maybe<string>;
		createdTs?: Maybe<number>;
	}>
): IMultiSeatPlacedWagerSet => {
	const debugMethod = 'mapAvailableWagersToPlacedWagers';

	if (seatNumbers.length === 0) {
		throwFatalError(`Specified list of seat numbers cannot be empty.`, debugMethod);
	}

	const result: IMultiSeatPlacedWagerSet = {};

	seatNumbers.forEach((seatNumber) => {
		const placedWagerSet: ISeatPlacedWagerSet = {
			seatNumber,
			wagers: {},
		};

		const wagers: IPlacedWagerSet = {};

		availableWagers.forEach((aw) => {
			const availableWagerKey = aw.availableWagerKey;
			wagers[availableWagerKey] = mapAvailableWagerToPlacedWager(aw, { ...opts });
		});

		placedWagerSet.wagers = wagers;
		result[seatNumber] = placedWagerSet;
	});

	return result;
};

/**
 * Maps the specified available wager data to a placed wager.
 *
 * @returns The placed wager data.
 */
const mapAvailableWagerToPlacedWager = (
	aw: IAvailableWagerDataEntry,
	opts?: Maybe<{
		amount?: Maybe<number>;
		currencyCode?: Maybe<string>;
		createdTs?: Maybe<number>;
	}>
): IPlacedWager => {
	const debugMethod = 'mapAvailableWagerToPlacedWager';

	const seatNumber = aw.seatNumber ?? 1;
	if (seatNumber <= 0) {
		throwFatalError(`Specified seat number '${seatNumber}' is invalid.`, debugMethod);
	}

	const availableWagerKey = aw.availableWagerKey;
	if (availableWagerKey === '') {
		throwFatalError(`Available wager key cannot be empty`, debugMethod);
	}

	const activeWagerKey = makeActiveWagerKeyFromAvailableKey(availableWagerKey, { seatNumber });

	let amount: number = opts?.amount ?? 0;
	amount = amount > 0 ? amount : 0;

	const currencyCode: string = opts?.currencyCode || aw.def.currencyCode || WalletManager.defaultCurrencyCode();
	const createdTs: number = opts?.createdTs || Date.now();

	return {
		availableWagerKey,
		activeWagerKey,
		seatNumber,
		wagerId: aw.wagerId,
		categoryMemberKey: aw.categoryMemberKey,
		contextId: aw.contextId,
		amount,
		currencyCode,
		createdTs,
	};
};

// ---- Exports -------------------------------------------------------------------------------------------------------

export { isValidSeat, resolveWageringSeats };
export { mapWagerSetToPlacedWagerSet, mapWagerToPlacedWager };
export { mapAvailableWagersToPlacedWagers, mapAvailableWagerToPlacedWager };
