/**********************************************************************************************************************
 * Initialization of the various RPC services used to make unary calls.
 *********************************************************************************************************************/
import isFunction from 'lodash/isFunction';
import { IAuthSessionProvider, IServiceOpts, SessionProvider } from '../../../client/core';
import { newDefaultOmnibusSession } from '../../../client/core/helpers';
import { IDealerService, IDeviceService, IGameDevService, IGameService, IUserService } from '../../../client/service';
import { DebugBase } from '../../../common';
import { filterNullUndefined } from '../../../helpers';
import { IServiceFactoryNewProps, ServiceFactory } from '../ServiceFactory';
import { IServiceInstances, IServicesConfig, IServicesOpts } from '../types';
import { IServices } from './types';

/**
 * Class for creating and managing RPC services.
 */
class Services extends DebugBase implements IServices {
	/* #region ---- Properties --------------------------------------------------------------------------------------- */

	/**
	 * Currently assigned options.
	 */
	protected override _options: IServicesOpts = Services.defaultOptions();

	/**
	 * Configuration object.
	 */
	protected _config: IServicesConfig = Services.defaultConfig();

	/**
	 * User session instance associated with this class.
	 */
	protected _userSession: SessionProvider;

	/**
	 * Omnibus session instance associated with this class.
	 */
	protected _omnibusSession: SessionProvider;

	/**
	 * Current game dev service instance.
	 */
	protected _gameDevService!: IGameDevService;

	/**
	 * Current game service instance.
	 */
	protected _gameService!: IGameService;

	/**
	 * Current device service instance.
	 */
	protected _deviceService!: IDeviceService;

	/**
	 * Current dealer service instance.
	 */
	protected _dealerService!: IDealerService;

	/**
	 * Current user service instance.
	 */
	protected _userService!: IUserService;

	/**
	 * TRUE if the class has been initialized.
	 */
	protected _isInitialized: boolean = false;

	/* #endregion ---- Properties ------------------------------------------------------------------------------------ */

	/* #region ---- CONSTRUCTOR -------------------------------------------------------------------------------------- */

	public constructor(userSession: SessionProvider, config: IServicesConfig, opts?: Maybe<IServicesOpts>) {
		super();

		this.setConfig(config);
		opts && this.setOptions(opts);

		this._userSession = userSession;
		this._omnibusSession = this._options.omnibusSession ?? (newDefaultOmnibusSession() as SessionProvider);

		// Initialize immediately if specified via options
		const init = this._options?.init ?? true;
		init && this.init();
	}

	/* #endregion ---- CONSTRUCTOR ----------------------------------------------------------------------------------- */

	/* #region ---- Public ------------------------------------------------------------------------------------------- */

	/**
	 * Sets/initializes the class options.
	 *
	 * - Overrides the parent class method.
	 */
	public override setOptions(opts: IServicesOpts) {
		const { newOpts, origOpts } = this.resolveOptions(opts);
		this._options = newOpts;
		this.onSetOptions(newOpts, origOpts);
	}

	/**
	 * Sets/initializes the services config.
	 *
	 * - Overrides the parent class method.
	 */
	public setConfig(config: IServicesConfig) {
		const { newConfig, origConfig } = this.resolveConfig(config);
		this._config = newConfig;
		this.onSetConfig(newConfig, origConfig);
	}

	/**
	 * Initialize the services. This is done outside of the constructor in order to allow for services to be
	 * initialized after application bootstrapping if desired.
	 */
	public init(): boolean {
		if (this._isInitialized) {
			return false;
		}

		this.initGameDevService();
		this.initGameService();
		this.initDeviceService();
		this.initDealerService();
		this.initUserService();

		this._isInitialized = true;

		return true;
	}

	/**
	 * Gets whether the class has been initialized.
	 */
	public get isInitialized(): boolean {
		return this._isInitialized;
	}

	/**
	 * Gets/sets the session instance.
	 */
	public get userSession(): SessionProvider {
		return this._userSession;
	}
	public set userSession(val: SessionProvider) {
		this.setUserSession(val);
	}
	protected setUserSession(val: SessionProvider) {
		if (this._userSession === val) {
			return;
		}

		const prev = this._userSession;
		this._userSession = val;

		this.onUserSessionChanged(val, prev);
	}

	/**
	 * Gets/sets the omnibus session instance.
	 */
	public get omnibusSession(): SessionProvider {
		return this._omnibusSession;
	}
	public set omnibusSession(session: SessionProvider) {
		this._omnibusSession = session;
	}
	protected setOmnibusSession(val: SessionProvider) {
		if (this._omnibusSession === val) {
			return;
		}

		const prev = this._omnibusSession;
		this._omnibusSession = val;

		this.onOmnibusSessionChanged(val, prev);
	}

	/**
	 * @returns The current game dev service instance.
	 */
	public get gameDevService(): IGameDevService {
		return this._gameDevService;
	}

	/**
	 * @returns The current game service instance.
	 */
	public get gameService(): IGameService {
		return this._gameService;
	}

	/**
	 * @returns The current dealer service instance.
	 */
	public get dealerService(): IDealerService {
		return this._dealerService;
	}

	/**
	 * @returns The current device service instance.
	 */
	public get deviceService(): IDeviceService {
		return this._deviceService;
	}

	/**
	 * @returns The current user service instance.
	 */
	public get userService(): IUserService {
		return this._userService;
	}

	/**
	 * @returns All service instances in a single object.
	 *
	 * Typically this is used in the following way:
	 * 	const { userService, gameService } = getServices();
	 */
	public getServices(): IServiceInstances {
		return Object.freeze({
			devService: this.gameDevService,
			gameService: this.gameService,
			dealerService: this.dealerService,
			deviceService: this.deviceService,
			userService: this.userService,
		});
	}

	/**
	 * @returns A JSON export of the pertinent data.
	 */
	public toJson(extended?: Maybe<boolean>): PlainObject {
		extended = extended ?? false;

		const toJs = (val: unknown) => DebugBase.toJs(val, { extended });

		const result: PlainObject = {
			devService: toJs(this.gameDevService),
			gameService: toJs(this.gameService),
			dealerService: toJs(this.dealerService),
			deviceService: toJs(this.deviceService),
			userService: toJs(this.userService),
		};

		if (extended) {
			result.isInitialized = this._isInitialized;
			result.options = toJs(this._options);
			result.config = toJs(this._config);
			result.userSession = this._userSession;
			result.omnibusSession = this._omnibusSession;
			result._Debug = { ...super.toJson(extended) };
		}

		return result;
	}

	/* #endregion ---- Public ---------------------------------------------------------------------------------------- */

	/* #region ---- Protected ---------------------------------------------------------------------------------------- */

	/**
	 * Resolves the options being passed in and returns the original and new options.
	 *
	 * - Overrides the parent class method.
	 */
	protected override resolveOptions(opts?: Maybe<IServicesOpts>) {
		const origOpts: IServicesOpts = {
			...Services.defaultOptions(),
			...this._options,
		};

		const newOpts: IServicesOpts = {
			...origOpts,
			...(opts ?? {}),
		};

		return { origOpts, newOpts };
	}

	/**
	 * Resolves the config being passed in and returns the original and new config.
	 */
	protected resolveConfig(config?: Maybe<IServicesConfig>) {
		const origConfig: IServicesConfig = {
			...Services.defaultConfig(),
			...this._config,
		};

		const newConfig: IServicesConfig = {
			...origConfig,
			...(config ?? {}),
		};

		return { origConfig, newConfig };
	}

	/**
	 * Called after new options are set.
	 *
	 * - Extends the base class method.
	 */
	protected override onSetOptions(newOpts: IServicesOpts, origOpts: IServicesOpts) {
		super.onSetOptions(newOpts, origOpts);

		if (
			this._omnibusSession != null &&
			newOpts.omnibusSession != null &&
			newOpts.omnibusSession !== this.omnibusSession
		) {
			this.setOmnibusSession(newOpts.omnibusSession);
		}
	}

	/**
	 * Called after new options are set.
	 */
	protected onSetConfig(newConfig: IServicesConfig, _origConfig: IServicesConfig) {
		if (!this._isInitialized) {
			return;
		}

		this._gameService != null && (this._gameService.url = newConfig.gameServerURI);
		this._gameDevService != null && (this._gameDevService.url = newConfig.devServerURI);
		this._userService != null && (this._userService.url = newConfig.gameServerURI);
		this._deviceService != null && (this._deviceService.url = newConfig.deviceServerURI);
		this._dealerService != null && (this._dealerService.url = newConfig.dealerServerURI);
	}

	/**
	 * Called after the class instance `_userSession` provider changes.
	 */
	protected onUserSessionChanged(
		_newSessionProvider: SessionProvider,
		_prevSessionProvider: SessionProvider,
		opts?: Maybe<{ cascadeToServiceInstances?: Maybe<boolean> }>
	) {
		const cascadeToServiceInstances = opts?.cascadeToServiceInstances ?? true;

		if (cascadeToServiceInstances) {
			this._gameDevService.session = this.appliedUserSession();
			this._gameService.session = this.appliedUserSession();
			this._userService.session = this.appliedUserSession();
		}
	}

	/**
	 * Called after the class instance `_omnibusSession` provider changes.
	 */
	protected onOmnibusSessionChanged(
		_newSessionProvider: SessionProvider,
		_prevSessionProvider: SessionProvider,
		opts?: Maybe<{ cascadeToServiceInstances?: Maybe<boolean> }>
	) {
		const cascadeToServiceInstances = opts?.cascadeToServiceInstances ?? true;

		if (cascadeToServiceInstances) {
			this._dealerService.session = this.appliedOmnibusSession();
			this._deviceService.session = this.appliedOmnibusSession();
		}
	}

	/**
	 * @returns The user session provider to pass to services.
	 */
	protected appliedUserSession(): SessionProvider {
		return isFunction(this._userSession) ? this._userSession : () => this._userSession as IAuthSessionProvider;
	}

	/**
	 * @returns The omnibus session provider to pass to services.
	 */
	protected appliedOmnibusSession(): SessionProvider {
		return isFunction(this._omnibusSession) ? this._omnibusSession : () => this._omnibusSession as IAuthSessionProvider;
	}

	/**
	 * Initializes the game dev service instance.
	 */
	protected initGameDevService = () => {
		const opts = this._options;
		const serviceProps = opts?.gameDevService ?? null;
		const isDebugEnabled = this.isDebugEnabled;

		this.info('Initializing game dev service', 'init', { ...serviceProps });

		let svc: IGameDevService;
		if (serviceProps?.instance != null) {
			svc = serviceProps.instance;
			svc.session = this.appliedUserSession();
		} else {
			svc = this.newGameDevService({ serviceOpts: serviceProps?.opts, isDebugEnabled });
		}

		this._gameDevService = svc;
	};

	/**
	 * Initializes the game service instance.
	 */
	protected initGameService = () => {
		const opts = this._options;
		const serviceProps = opts?.gameService ?? null;
		const isDebugEnabled = this.isDebugEnabled;

		this.info('Initializing game service', 'init', { ...serviceProps });

		let svc: IGameService;
		if (serviceProps?.instance != null) {
			svc = serviceProps.instance;
			svc.session = this.appliedUserSession();
		} else {
			svc = this.newGameService({ serviceOpts: serviceProps?.opts, isDebugEnabled });
		}

		this._gameService = svc;
	};

	/**
	 * Initializes the dealer service instance.
	 */
	protected initDealerService = () => {
		const opts = this._options;
		const serviceProps = opts?.dealerService ?? null;
		const isDebugEnabled = this.isDebugEnabled;

		this.info('Initializing dealer service', 'init', { ...serviceProps });

		let svc: IDealerService;
		if (serviceProps?.instance != null) {
			svc = serviceProps.instance;
			svc.session = this.appliedOmnibusSession();
		} else {
			svc = this.newDealerService({ serviceOpts: serviceProps?.opts, isDebugEnabled });
		}

		this._dealerService = svc;
	};

	/**
	 * Initializes the device service instance.
	 */
	protected initDeviceService = () => {
		const opts = this._options;
		const serviceProps = opts?.deviceService ?? null;
		const isDebugEnabled = this.isDebugEnabled;

		this.info('Initializing dealer service', 'init', { ...serviceProps });

		let svc: IDeviceService;
		if (serviceProps?.instance != null) {
			svc = serviceProps.instance;
			svc.session = this.appliedOmnibusSession();
		} else {
			svc = this.newDeviceService({ serviceOpts: serviceProps?.opts, isDebugEnabled });
		}

		this._deviceService = svc;
	};

	/**
	 * Initializes the user service instance.
	 */
	protected initUserService = () => {
		const opts = this._options;
		const serviceProps = opts?.userService ?? null;
		const isDebugEnabled = this.isDebugEnabled;

		this.info('Initializing user service', 'init', { ...serviceProps });

		let svc: IUserService;
		if (serviceProps?.instance != null) {
			svc = serviceProps.instance;
			svc.session = this.appliedUserSession();
		} else {
			svc = this.newUserService({ serviceOpts: serviceProps?.opts, isDebugEnabled });
		}

		this._userService = svc;
	};

	/**
	 * @returns A new game dev service instance.
	 */
	protected newGameDevService(opts?: Maybe<Partial<IServiceFactoryNewProps>>): IGameDevService {
		const optsN: Partial<IServiceFactoryNewProps> = filterNullUndefined(opts ?? {});

		const url: string = opts?.url || this._config.devServerURI;
		const session: SessionProvider = opts?.session ?? this.appliedUserSession();
		const serviceOpts: Nullable<IServiceOpts> = optsN?.serviceOpts ?? this._options?.gameDevService?.opts ?? null;
		const isDebugEnabled = optsN?.isDebugEnabled ?? this.isDebugEnabled;
		const newServiceProps: IServiceFactoryNewProps = { ...optsN, isDebugEnabled, serviceOpts, url, session };

		return ServiceFactory.newGameDevService(newServiceProps);
	}

	/**
	 * @returns A new game service instance.
	 */
	protected newGameService(opts?: Maybe<Partial<IServiceFactoryNewProps>>): IGameService {
		const optsN: Partial<IServiceFactoryNewProps> = filterNullUndefined(opts ?? {});

		const url: string = optsN?.url || this._config.gameServerURI;
		const session: SessionProvider = optsN?.session ?? this.appliedUserSession();
		const serviceOpts: Nullable<IServiceOpts> = optsN?.serviceOpts ?? this._options?.gameService?.opts ?? null;
		const isDebugEnabled = optsN?.isDebugEnabled ?? this.isDebugEnabled;
		const newServiceProps: IServiceFactoryNewProps = { ...optsN, isDebugEnabled, serviceOpts, url, session };

		return ServiceFactory.newGameService(newServiceProps);
	}

	/**
	 * @returns A new device service instance.
	 */
	protected newDeviceService(opts?: Maybe<Partial<IServiceFactoryNewProps>>): IDeviceService {
		const optsN: Partial<IServiceFactoryNewProps> = filterNullUndefined(opts ?? {});

		const url: string = optsN?.url || this._config.deviceServerURI;
		const session: SessionProvider = optsN?.session ?? this.appliedOmnibusSession();
		const serviceOpts: Nullable<IServiceOpts> = optsN?.serviceOpts ?? this._options?.deviceService?.opts ?? null;
		const isDebugEnabled = optsN?.isDebugEnabled ?? this.isDebugEnabled;
		const newServiceProps: IServiceFactoryNewProps = { ...optsN, isDebugEnabled, serviceOpts, url, session };

		return ServiceFactory.newDeviceService(newServiceProps);
	}

	/**
	 * @returns A new dealer service instance.
	 */
	protected newDealerService(opts?: Maybe<Partial<IServiceFactoryNewProps>>): IDealerService {
		const optsN: Partial<IServiceFactoryNewProps> = filterNullUndefined(opts ?? {});

		const url: string = optsN?.url || this._config.dealerServerURI;
		const session: SessionProvider = optsN?.session ?? this.appliedOmnibusSession();
		const serviceOpts: Nullable<IServiceOpts> = optsN?.serviceOpts ?? this._options?.dealerService?.opts ?? null;
		const isDebugEnabled = optsN?.isDebugEnabled ?? this.isDebugEnabled;
		const newServiceProps: IServiceFactoryNewProps = { ...optsN, isDebugEnabled, serviceOpts, url, session };

		return ServiceFactory.newDealerService(newServiceProps);
	}

	/**
	 * @returns A new user service instance.
	 */
	protected newUserService(opts?: Maybe<Partial<IServiceFactoryNewProps>>): IUserService {
		const optsN: Partial<IServiceFactoryNewProps> = filterNullUndefined(opts ?? {});

		const url: string = optsN?.url || this._config.gameServerURI;
		const session: SessionProvider = optsN?.session ?? this.appliedUserSession();
		const serviceOpts: Nullable<IServiceOpts> = optsN?.serviceOpts ?? this._options?.userService?.opts ?? null;
		const isDebugEnabled = optsN?.isDebugEnabled ?? this.isDebugEnabled;
		const newServiceProps: IServiceFactoryNewProps = { ...optsN, isDebugEnabled, serviceOpts, url, session };

		return ServiceFactory.newUserService(newServiceProps);
	}

	/* #endregion ---- Protected ------------------------------------------------------------------------------------- */

	/* #region ---- Static ------------------------------------------------------------------------------------------- */

	/**
	 * STATIC
	 * @returns The default options data used by this class.
	 */
	public static defaultOptions(): IServicesOpts {
		return {
			...DebugBase.defaultOptions(),
			init: true,
			gameDevService: null,
			gameService: null,
			deviceService: null,
			dealerService: null,
			userService: null,
		};
	}

	/**
	 * STATIC
	 * @returns The default config data used by this class.
	 */
	public static defaultConfig(): IServicesConfig {
		return {
			devServerURI: '',
			gameServerURI: '',
			deviceServerURI: '',
			dealerServerURI: '',
		};
	}

	/* #endregion ---- Static ---------------------------------------------------------------------------------------- */

	/* #region ---- Debug -------------------------------------------------------------------------------------------- */

	/**
	 * Overrides the parent class property.
	 *
	 * @returns The label to use when debugging.
	 */
	protected override get debugClassLabel(): string {
		return Services.debugClassLabel();
	}

	/**
	 * STATIC
	 * @returns Label assigned to this class namespace.
	 */
	protected static debugClassLabel(): string {
		return `RpcLib.Sdk.Services`;
	}

	/* #endregion ---- Debug ----------------------------------------------------------------------------------------- */
}

// ---- Exports -------------------------------------------------------------------------------------------------------

export { Services as default };
export { Services };
