import debounce from 'lodash/debounce';
import {
	IDevDeviceReqData,
	IDevGetDeviceReqsReplyData,
	IDevTableStateDeviceInfoData,
	IDevTableStateReplyData,
} from '../../client/rpc';
import { IClientRpcSdk } from '../ClientRpcSdk';
import { debug } from './debug';
import { IClientRpcSdkDeviceSelectors } from './types/device';

/**
 * @returns An array of devices attached to the specified table.
 */
const getTableDevices = async (
	sdk: IClientRpcSdk,
	tableId: string,
	filterDeviceTypes?: Maybe<string[]>
): Promise<IDevTableStateDeviceInfoData[]> => {
	const debugMethod = 'device.getTableDevices';

	if (tableId === '') {
		debug.error('Table ID cannot be empty', debugMethod);
		return [];
	}

	const appliedFilterDeviceTypes: string[] = (filterDeviceTypes ?? [])
		.map((deviceType) => deviceType.toLocaleLowerCase())
		.filter((deviceType) => deviceType !== '');

	const { devService } = sdk.services.getServices();

	const tableState: IDevTableStateReplyData = (await devService.getTableState(tableId).promise) ?? null;

	const attachedDevices = tableState?.attachedDevices ?? [];
	if (attachedDevices.length === 0 || appliedFilterDeviceTypes.length === 0) {
		return attachedDevices;
	}

	return attachedDevices.filter((device) => {
		const deviceType = (device.type ?? '').toLocaleLowerCase();
		return appliedFilterDeviceTypes.includes(deviceType);
	});
};

/**
 * @returns Device ID of the primary device for the specified table.
 */
const getTablePrimaryDeviceId = async (
	sdk: IClientRpcSdk,
	tableId: string,
	deviceType?: Maybe<string>
): Promise<string> => {
	const debugMethod = 'device.getTablePrimaryDeviceId';

	deviceType = deviceType ?? '';

	if (tableId === '') {
		debug.error('Table ID cannot be empty', debugMethod);
		return '';
	}

	const filterDeviceTypes = deviceType !== '' ? [deviceType] : [];
	const devices: IDevTableStateDeviceInfoData[] = await getTableDevices(sdk, tableId, filterDeviceTypes);

	if (devices.length === 0) {
		return '';
	}

	// At this point what is in the devices array is the filtered list of devices matching the device type
	const firstDevice = devices[0] ?? null;

	return firstDevice?.id ?? '';
};

/**
 * @returns An array of requests for the specified device.
 */
const getDeviceRequests = async (sdk: IClientRpcSdk, deviceId: string): Promise<IDevDeviceReqData[]> => {
	const debugMethod = 'device.getDeviceRequests';

	if (deviceId === '') {
		debug.error('Device ID cannot be empty', debugMethod);
		return [];
	}

	const { devService } = sdk.services.getServices();

	const response = (await devService.getDeviceRequests(deviceId).promise) as IDevGetDeviceReqsReplyData;

	return response?.requests ?? [];
};

/**
 * Debounced methods
 */
const getTableDevicesDebounced = debounce(getTableDevices, 1000, { leading: true, trailing: true });
const getTablePrimaryDeviceIdDebounced = debounce(getTablePrimaryDeviceId, 1000, { leading: true, trailing: true });
const getDeviceRequestsDebounced = debounce(getDeviceRequests, 1000, { leading: true, trailing: true });

/**
 * Factory for creating device related selectors.
 */
const newDeviceSelectors = (sdk: IClientRpcSdk): IClientRpcSdkDeviceSelectors => {
	return Object.freeze({
		getTableDevices: async (
			tableId: string,
			filterDeviceTypes?: Maybe<string[]>
		): Promise<IDevTableStateDeviceInfoData[]> => {
			const devices = await (getTableDevicesDebounced(sdk, tableId, filterDeviceTypes) ?? []);
			return devices;
		},

		getTablePrimaryDeviceId: async (tableId: string, deviceType?: Maybe<string>): Promise<string> => {
			const deviceId = (await getTablePrimaryDeviceIdDebounced(sdk, tableId, deviceType)) ?? '';
			return deviceId;
		},

		getDeviceRequests: async (deviceId: string): Promise<IDevDeviceReqData[]> => {
			const requests = getDeviceRequestsDebounced(sdk, deviceId) ?? [];
			return requests;
		},
	});
};

// ---- Export --------------------------------------------------------------------------------------------------------

export { newDeviceSelectors };
