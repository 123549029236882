import { IClientRpcSdk } from '../ClientRpcSdk';
import { debug } from './debug';
import { IClientRpcSdkSeatActions } from './types/seat';

/**
 * Claims the specified seat at a table for the current player.
 */
const claimTableSeat = async (
	sdk: IClientRpcSdk,
	seatNumber: number,
	opts?: Maybe<{ tableId?: Maybe<string> }>
): Promise<boolean> => {
	const debugMethod = 'seat.claimTableSeat';

	const tableId = opts?.tableId || sdk.tableId;

	if (tableId === '') {
		debug.error('Unable to resolve a valid Table ID', debugMethod);
		return false;
	}
	if (seatNumber < 1) {
		debug.error('Seat number must be greater than zero', debugMethod);
		return false;
	}

	const { gameService } = sdk.services.getServices();

	try {
		await gameService.sitTable(tableId, seatNumber).promise;
	} catch (e: unknown) {
		debug.error('Error while claiming table seat', debugMethod, { tableId, seatNumber, err: e as Error });
		return false;
	}

	return true;
};

/**
 * Unclaims (relinquishes) the specified seat at a table for the current player.
 */
const unclaimTableSeat = async (
	sdk: IClientRpcSdk,
	seatNumber: number,
	opts?: Maybe<{ tableId?: Maybe<string> }>
): Promise<boolean> => {
	const debugMethod = 'seat.unclaimTableSeat';

	const tableId = opts?.tableId || sdk.tableId;

	if (tableId === '') {
		debug.error('Unable to resolve a valid Table ID', debugMethod);
		return false;
	}

	if (seatNumber < 1) {
		debug.error('Seat number must be greater than zero', debugMethod);
		return false;
	}

	const { gameService } = sdk.services.getServices();

	try {
		await gameService.standTable(tableId, seatNumber).promise;
	} catch (e: unknown) {
		debug.error('Error while unclaiming table seat', debugMethod, { tableId, seatNumber, err: e as Error });
		return false;
	}

	return true;
};

/**
 * Factory for creating seat related actions.
 */
const newSeatActions = (sdk: IClientRpcSdk): IClientRpcSdkSeatActions => {
	return Object.freeze({
		claimSeat: (seatNumber: number, opts?: Maybe<{ tableId?: Maybe<string> }>) => claimTableSeat(sdk, seatNumber, opts),
		unclaimSeat: (seatNumber: number, opts?: Maybe<{ tableId?: Maybe<string> }>) =>
			unclaimTableSeat(sdk, seatNumber, opts),
	});
};

// ---- Export --------------------------------------------------------------------------------------------------------

export { newSeatActions };
