import { set } from 'mobx';
import { DebugBase } from '../../common';
import { IToJsOpts, toJs } from '../../helpers';
import { IDataStore, IDataStoreOpts } from './types';

abstract class DataStore<ServiceType, RpcDataType> extends DebugBase implements IDataStore<RpcDataType> {
	/* #region ---- Properties --------------------------------------------------------------------------------------- */

	/**
	 * Currently assigned options.
	 */
	protected override _options: IDataStoreOpts = DataStore.defaultOptions();

	/**
	 * Service associated with this store. Needed in order to call `populate`.
	 */
	protected _service: Nullable<ServiceType> = null;

	/**
	 * The underlying data inside this store.
	 */
	protected _data: Nullable<RpcDataType> = null;

	/**
	 * The unix timestamp (UTC) for when the data in this store was last updated.
	 */
	protected _lastUpdatedTs: number = 0;

	/**
	 * Flag indicating if this class instance is currently bound to MobX as an observable.
	 */
	protected _isMobXBound: boolean = false;

	/* #endregion ---- Properties ------------------------------------------------------------------------------------ */

	/* #region ---- CONSTRUCTOR ---------------------------------------------------------------------------------------*/

	/**
	 * @param  service  Service associated with this store. Needed in order to call `populate`.
	 */
	constructor(service?: Maybe<ServiceType>, opts?: Maybe<IDataStoreOpts>) {
		super();

		this._service = service ?? null;

		opts != null && this.setOptions(opts);
	}

	/* #endregion ---- CONSTRUCTOR ----------------------------------------------------------------------------------- */

	/* #region ---- Public ------------------------------------------------------------------------------------------- */

	/**
	 * Sets/initializes the class options.
	 *
	 * - Overrides the parent class method.
	 */
	public override setOptions(opts: IDataStoreOpts) {
		const { newOpts, origOpts } = this.resolveOptions(opts);

		if (this._isMobXBound) {
			set(this, '_options', newOpts);
		} else {
			this._options = newOpts;
		}

		this.onSetOptions(newOpts, origOpts);
	}

	/**
	 * GET/SET. The underlying data inside this store.
	 */
	public get data(): Nullable<RpcDataType> {
		return this._data;
	}
	public set data(val: Nullable<RpcDataType>) {
		this.setData(val);
	}
	// Actionable setter method for MobX.
	public setData(val: Nullable<RpcDataType>) {
		if (val === this._data) {
			return;
		}

		if (this.isMobXBound) {
			set(this, '_data', val);
		} else {
			this._data = val;
		}

		this.lastUpdatedTs = Date.now();
	}

	/**
	 * GET/SET. The underlying data inside this store.
	 */
	public get service(): Nullable<ServiceType> {
		return this._service;
	}
	public set service(value: Nullable<ServiceType>) {
		this.setService(value);
	}
	// Actionable setter method for MobX.
	protected setService(value: Nullable<ServiceType>) {
		if (this._service != null && value === this._service) {
			return;
		}

		if (this.isMobXBound) {
			set(this, '_service', value);
		} else {
			this._service = value;
		}
	}

	/**
	 * @returns TRUE if this store is populated with data.
	 */
	public get isPopulated(): boolean {
		return this.data != null;
	}

	/**
	 * The last time (unix timestamp) that the data was updated.
	 */
	public get lastUpdatedTs(): number {
		return this._lastUpdatedTs;
	}
	public set lastUpdatedTs(val: number) {
		this.setLastUpdatedTs(val);
	}
	// Actionable setter method for MobX.
	protected setLastUpdatedTs(val: number) {
		if (val === this._lastUpdatedTs) {
			return;
		}

		this._lastUpdatedTs = val;
	}

	/**
	 * Gets/sets whether or not this class instance is currently bound to MobX as an observable.
	 */
	public get isMobXBound(): boolean {
		return this._isMobXBound;
	}
	public set isMobXBound(value: boolean) {
		this._isMobXBound = value;
	}

	/**
	 * The actual type of the class extending this. Used for debug messages.
	 */
	public get className(): string {
		return this.constructor.name || this.debugClassLabel || 'DataStore';
	}

	/**
	 * Action. Clear the store.
	 */
	public clear(): void {
		this.data = null;
		this.lastUpdatedTs = 0;
	}

	/**
	 * Overrides the parent class method.
	 *
	 * @returns A JSON export of the current pertinent data.
	 */
	public override toJson(extended?: Maybe<boolean>, opts?: Maybe<{ includeData?: Maybe<boolean> }>): PlainObject {
		extended = extended ?? false;
		const includeData = opts?.includeData ?? true;

		const toJs = (val: unknown, opts?: Maybe<IToJsOpts>) => this.toJs(val, extended, { ...opts });

		const result: PlainObject = {
			data: {},
			isPopulated: this.isPopulated,
			lastUpdatedTs: this.lastUpdatedTs,
		};

		if (includeData) {
			result.data = toJs(this.data, { useToJson: false });
		}

		if (extended) {
			result.extended = {
				isMobXBound: this.isMobXBound,
				className: this.className,
				options: toJs({ ...this._options }),

				// Debug info from base class
				_Debug: { ...super.toJson(extended) },
			};
		}

		return result;
	}

	/**
	 * Populate this store by calling the service associated with this store.
	 *
	 * @returns TRUE if we were able to populate this store using data.
	 */
	public async populate(...optionalArgs: unknown[]): Promise<boolean> {
		if (!this._service) {
			this.error('Unable to populate, no service was specified', 'populate');

			return false;
		}

		try {
			const data = await this.fetchPopulateData(...optionalArgs);
			this.data = data;
		} catch (err) {
			this.error('Unable to populate due to an error:', 'populate', { error: err as Error });

			return false;
		}

		return true;
	}

	/* #endregion ---- Public ---------------------------------------------------------------------------------------- */

	/* #region ---- Protected ---------------------------------------------------------------------------------------- */

	/**
	 * Gets data from the associated service in order to populate the store.
	 *
	 * @returns The underlying data needed to populate this store.
	 */
	protected abstract fetchPopulateData(...optionalArgs: unknown[]): Promise<Nullable<RpcDataType>>;

	/**
	 * Resolves the options being passed in and returns the original and new options.
	 *
	 * - Overrides the parent class method.
	 */
	protected override resolveOptions(opts?: Maybe<IDataStoreOpts>) {
		const origOpts: IDataStoreOpts = {
			...DataStore.defaultOptions(),
			...this._options,
		};

		const newOpts: IDataStoreOpts = {
			...origOpts,
			...(opts ?? {}),
		};

		return { origOpts, newOpts };
	}

	/**
	 * Converts the given value to a JS object using the value of `this.isMobXBound`.
	 */
	protected toJs(val: unknown, extended?: Maybe<boolean>, opts?: Maybe<IToJsOpts>): unknown {
		return toJs(val, { ...opts, extended, useMobXToJs: this.isMobXBound });
	}

	/* #endregion ---- Protected ------------------------------------------------------------------------------------- */

	/* #region ---- Static ------------------------------------------------------------------------------------------- */

	/**
	 * STATIC
	 * @returns The default options data used by this class.
	 */
	public static defaultOptions(): IDataStoreOpts {
		return {
			...DebugBase.defaultOptions(),
		};
	}

	/* #endregion ---- Static ---------------------------------------------------------------------------------------- */

	/* #region ---- Debug -------------------------------------------------------------------------------------------- */

	/**
	 * @returns The label to use when debugging.
	 */
	protected get debugClassLabel(): string {
		return DataStore.debugClassLabel();
	}

	/**
	 * STATIC
	 * @returns Label assigned to this class namespace.
	 */
	protected static debugClassLabel(): string {
		return `RpcLib.Store.DataStore`;
	}

	/* #endregion ---- Debug ----------------------------------------------------------------------------------------- */
}

// ---- Exports -------------------------------------------------------------------------------------------------------

export { DataStore as default };
export { DataStore };
