import { action, AnnotationsMap, computed, makeObservable, observable } from 'mobx';
import { EventDispatcherBaseMobXAnnotations } from '../../../../common';
import ServerPlayerWagerState from './ServerPlayerWagerState';
import { ServerPlayerWagerStateMobXPrivateProps } from './types.mobx';

const ServerPlayerWagerStateMobXAnnotations: AnnotationsMap<
	ServerPlayerWagerState,
	ServerPlayerWagerStateMobXPrivateProps
> = {
	// Inherit from EventDispatcherBase
	...EventDispatcherBaseMobXAnnotations,

	// ---- Observed -----------------------------------------------------

	// >> Properties
	_activeWagers: observable,
	_data: observable.struct,
	_dataRetrievers: observable.struct,
	_pendingWagers: observable,
	_rejectedWagers: observable,

	// ---- Computed -----------------------------------------------------

	// >> Public
	activeWagersDataObj: computed,
	data: computed,
	dataHashId: computed,
	isEmpty: computed,
	keys: computed,
	lastUpdatedTs: computed,
	list: computed,
	lookup: computed,
	pendingWagersDataObj: computed,
	rejectedWagersDataObj: computed,
	seatNumbers: computed,
	size: computed,
	wagerIds: computed,
	wagerNames: computed,
	wagers: computed,
	wagerTotals: computed,

	// ---- Actions ------------------------------------------------------

	// >> Public
	clear: action,
	injectWagerCollections: action,
	populate: action,

	// >> Protected
	init: action,
	initWagerCollectionInstances: action,
	populateActiveWagersFromRawData: action,
	populatePendingWagersFromRawData: action,
	populateRejectedWagersFromRawData: action,
	populateWagerCollectionInstancesFromRawData: action,
	setActiveWagersInstance: action,
	setData: action,
	setLastUpdatedTs: action,
	setPendingWagersInstance: action,
	setRejectedWagersInstance: action,

	// ---- Excluded -----------------------------------------------------

	// >> Properties
	_isMobXBound: false,

	// >> Public
	isMobXBound: false,
};

/**
 * Makes the specified `ServerPlayerWagerState` instance an observable MobX object.
 */
const bindServerPlayerWagerStateMobX = (instance: ServerPlayerWagerState): boolean => {
	if (instance.isMobXBound) {
		return false;
	}

	makeObservable<ServerPlayerWagerState, ServerPlayerWagerStateMobXPrivateProps>(instance, {
		...ServerPlayerWagerStateMobXAnnotations,
	});

	instance.isMobXBound = true;

	return true;
};

// ---- Exports -------------------------------------------------------------------------------------------------------

export { ServerPlayerWagerStateMobXAnnotations, bindServerPlayerWagerStateMobX };
