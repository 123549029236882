import { DebugBase } from '../shared';
import { EventManager, EvmEventCallbackFn, EvmEventPayloadData, IEvmOnEventOpts } from '../shared';
import { DebugEventType, EventDispatcherBaseEvents as Events } from './constants';
import { IEventDispatcherBase, IEventDispatcherBaseOpts } from './types';

/**
 * Base class for anything that needs to dispatch events.
 */
class EventDispatcherBase extends DebugBase implements IEventDispatcherBase {
	/* #region ---- Properties --------------------------------------------------------------------------------------- */

	/**
	 * Currently assigned options.
	 */
	protected override _options: IEventDispatcherBaseOpts = EventDispatcherBase.defaultOptions();

	/**
	 * Instance of the EventManager used for event handling.
	 */
	protected _eventManager: EventManager;

	/* #endregion ---- Properties -------------------------------------------------------------------------------------*/

	/* #region ---- CONSTRUCTOR ---------------------------------------------------------------------------------------*/

	/**
	 * CONSTRUCTOR.
	 */
	constructor(opts?: Maybe<IEventDispatcherBaseOpts>) {
		super();

		opts != null && this.setOptions(opts);

		// Event manager
		this._eventManager = new EventManager({
			...this._options.eventManagerOpts,
			debugLabel: `FOR:[${this.getDebugLogPrefix()}]`,
		});
	}

	/* #endregion ---- CONSTRUCTOR ----------------------------------------------------------------------------------- */

	/* #region ---- Public ------------------------------------------------------------------------------------------- */

	/**
	 * Sets/initializes the class options.
	 *
	 * - Overrides the parent class method.
	 */
	public override setOptions(opts: IEventDispatcherBaseOpts) {
		const { newOpts, origOpts } = this.resolveOptions(opts);
		this._options = newOpts;
		this.onSetOptions(newOpts, origOpts);
	}

	/**
	 * See `EventManager.on`
	 */
	public on(eventKey: string, callback: EvmEventCallbackFn, opts?: Maybe<IEvmOnEventOpts>) {
		return this._eventManager.on(eventKey, callback, opts);
	}

	/**
	 * See `EventManager.once`
	 */
	public once(eventKey: string, callback: EvmEventCallbackFn, opts?: Maybe<IEvmOnEventOpts>) {
		return this._eventManager.once(eventKey, callback, opts);
	}

	/**
	 * See `EventManager.off`
	 */
	public off(eventKey: string, eventUid: string) {
		return this._eventManager.off(eventKey, eventUid);
	}

	/**
	 * See `EventManager.any`
	 */
	public any(callback: EvmEventCallbackFn, once?: Maybe<boolean>) {
		return this._eventManager.any(callback, once);
	}

	/**
	 * See `EventManager.unregister`
	 */
	public unregisterEvents(eventKey: string): number {
		return this._eventManager.unregister(eventKey);
	}

	/**
	 * See `EventManager.unregisterAll`
	 */
	public unregisterAllEvents() {
		return this._eventManager.unregisterAll();
	}

	/**
	 * See `EventManager.hasEventCallbacks`
	 */
	public hasRegisteredEvents(eventKey: string): boolean {
		return this._eventManager.hasEventCallbacks(eventKey);
	}

	/**
	 * Overrides the parent class method.
	 *
	 * @returns A JSON export of the current pertinent data.
	 */
	public override toJson(extended?: Maybe<boolean>): PlainObject {
		extended = extended ?? false;

		const result: PlainObject = {};

		if (extended) {
			result.extended = {
				// Debug info from base class
				_Debug: { ...super.toJson(extended) },
			};
		}

		return result;
	}

	/* #endregion ---- Public ---------------------------------------------------------------------------------------- */

	/* #region ---- Protected ---------------------------------------------------------------------------------------- */

	/**
	 * Logs an info debug message in the context of this class and issues a debug event.
	 *
	 * - Extends the parent class method.
	 */
	protected override info(msg: string, method?: Maybe<string>, ...args: unknown[]) {
		super.info(msg, method, ...args);
		this.issueDebugEvent(DebugEventType.INFO, ...args);
	}

	/**
	 * Logs a warning debug message in the context of this class and issues a debug event.
	 *
	 * - Extends the parent class method.
	 */
	protected override warn(msg: string, method?: Maybe<string>, ...args: unknown[]) {
		super.warn(msg, method, ...args);
		this.issueDebugEvent(DebugEventType.WARNING, ...args);
	}

	/**
	 * Logs an error debug message in the context of this class and issues a debug event.
	 *
	 * - Extends the parent class method.
	 */
	protected override error(msg: string, method?: Maybe<string>, ...args: unknown[]) {
		super.error(msg, method, ...args);
		this.issueDebugEvent(DebugEventType.ERROR, ...args);
	}

	/**
	 * Issues a debug event.
	 */
	protected issueDebugEvent(eventType: DebugEventType, ...args: unknown[]) {
		const eventData: PlainObject = {
			event: Events.DEBUG,
			type: eventType.toString(),
			args: args,
		};

		this.trigger(Events.DEBUG, eventData);

		if (eventType === DebugEventType.ERROR) {
			const eventData: PlainObject = { event: Events.ERROR, args: args };
			this.trigger(Events.ERROR, eventData);
		}
	}

	/**
	 * Triggers an event. Will executes all callbacks registered with the specified event.
	 */
	protected async trigger(eventKey: string, eventData?: Maybe<EvmEventPayloadData>) {
		return this._eventManager.dispatch(eventKey, eventData);
	}

	/**
	 * @returns The label to use when debugging.
	 */
	protected get debugClassLabel(): string {
		return EventDispatcherBase.debugClassLabel();
	}

	/**
	 * Resolves the options being passed in and returns the original and new options.
	 *
	 * - Overrides the parent class method
	 */
	protected override resolveOptions(opts?: Maybe<IEventDispatcherBaseOpts>) {
		const origOpts: IEventDispatcherBaseOpts = {
			...EventDispatcherBase.defaultOptions(),
			...this._options,
		};

		const newOpts: IEventDispatcherBaseOpts = {
			...origOpts,
			...(opts ?? {}),
		};

		return { origOpts, newOpts };
	}

	/**
	 * Called after new options are set.
	 */
	protected onSetOptions(newOpts: IEventDispatcherBaseOpts, origOpts?: Maybe<IEventDispatcherBaseOpts>) {
		super.onSetOptions(newOpts, origOpts);
	}

	/**
	 * Called when the debug label is changed.
	 *
	 * - Extends the parent class method
	 */
	protected override onSetDebugLabel(debugLabel: string) {
		super.onSetDebugLabel(debugLabel);

		if (this._eventManager) {
			this._eventManager.debugLabel = `FOR:[${this.getDebugLogPrefix(debugLabel)}]`;
		}
	}

	/* #endregion ---- Protected ------------------------------------------------------------------------------------- */

	/* #region ---- Static ------------------------------------------------------------------------------------------- */

	/**
	 * STATIC
	 * @returns The default options data used by this class.
	 */
	public static defaultOptions(): IEventDispatcherBaseOpts {
		return {
			...DebugBase.defaultOptions(),
			eventManagerOpts: null,
		};
	}

	/**
	 * STATIC
	 * @returns Label assigned to this class namespace.
	 */
	protected static debugClassLabel(): string {
		return `RpcLib.Common.EventDispatcherBase`;
	}

	/* #endregion ---- Static ---------------------------------------------------------------------------------------- */
}

// ---- Exports -------------------------------------------------------------------------------------------------------

export { EventDispatcherBase };
