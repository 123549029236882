/**
 * Async sleep for the specified amount of tim in milliseconds.
 */
const asyncSleep = async (delayMs: number): Promise<void> => {
	return new Promise((resolve) => setTimeout(resolve, delayMs));
};

// ---- Exports -------------------------------------------------------------------------------------------------------

export { asyncSleep };
