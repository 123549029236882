import { ICancelablePromiseExt, NewCancelablePromiseExt, normalizeCurrencyCode } from '../../../helpers';
import { generateRandomString } from '../../../helpers';
import { DEFAULT_CURRENCY_EXPONENT } from '../../../helpers/shared';
import { Service } from '../../core/Service';
import { IClientOpts, IServiceOpts, IServiceRequestOptions } from '../../core/types';
import { GameDevClient } from '../../rpc/clients/game.dev';
import {
	DevDeviceInputReply,
	DevGetDeviceReqsReply,
	DevLoginReply,
	DevNewPlayReply,
	DevRunPlayReply,
	DevSetBalanceReply,
	DevTableStateReply,
} from '../../rpc/replies/game.dev';
import {
	DevDeviceInputRequest,
	DevGetDeviceReqsRequest,
	DevLoginRequest,
	DevNewPlayRequest,
	DevRunPlayRequest,
	DevSetBalanceRequest,
	DevTableStateRequest,
} from '../../rpc/requests/game.dev';
import { DEFAULT_CLIENT_DEV_OPERATOR_LABEL } from './constants';
import { IGameDevService } from './types';

/**
 * Game Dev service. Used for development and testing. Not intended for production use.
 */
class GameDevService extends Service<typeof GameDevClient> implements IGameDevService {
	/* #region ---- CONSTRUCTOR -------------------------------------------------------------------------------------- */

	constructor(url: string, opts?: IServiceOpts) {
		super(url, opts);
	}

	/* #endregion ---- CONSTRUCTOR ----------------------------------------------------------------------------------- */

	/* #region ---- Public ------------------------------------------------------------------------------------------- */

	/**
	 * Sets a specified amount of currency on the current player's account.
	 *
	 * @returns The updated player data.
	 */
	public setBalance(
		amount: number,
		currencyCode: string,
		currencyExponent?: Maybe<number>,
		opts?: Maybe<IServiceRequestOptions>
	): ICancelablePromiseExt<DevSetBalanceReply> {
		const options = this.requestOptions(opts);

		amount = Math.max(amount ?? 0, 0);
		currencyCode = normalizeCurrencyCode(currencyCode);
		currencyExponent = Math.max(currencyExponent ?? DEFAULT_CURRENCY_EXPONENT, 0);

		if (currencyCode === '') {
			throw new Error('[setBalance]: Currency code cannot be empty.');
		}

		const req: DevSetBalanceRequest = new DevSetBalanceRequest({
			amount: BigInt(amount),
			currency: currencyCode,
			currencyExponent,
		});
		const promise = this.rpcClient.setBalance(req, options.callOpts);

		return NewCancelablePromiseExt<DevSetBalanceReply>(promise, null, options.controller);
	}

	/**
	 * Makes a request to the server to login the specified user.
	 *
	 * @returns The `userId` and `token` values for the logged-in user.
	 */
	public login(
		email: string,
		displayName?: Maybe<string>,
		operatorLabel?: Maybe<string>,
		opts?: Maybe<IServiceRequestOptions>
	): ICancelablePromiseExt<DevLoginReply> {
		if (email === '') {
			throw new Error('Email cannot be empty.');
		}

		displayName = displayName || `User_${generateRandomString(5)}`;
		operatorLabel = operatorLabel || DEFAULT_CLIENT_DEV_OPERATOR_LABEL;

		const options = this.requestOptions({ ...opts, noAuthRequired: true });

		const req: DevLoginRequest = new DevLoginRequest({
			operatorUserId: email,
			displayName: displayName,
			operator: operatorLabel,
		});

		const promise = this.rpcClient.login(req, options.callOpts);

		return NewCancelablePromiseExt<DevLoginReply>(promise, null, options.controller);
	}

	/**
	 * Starts a new play for the specified table - if a play isn't already active and in progress.
	 *
	 * @returns Table ID and Play ID for the new play.
	 */
	public newPlay(
		tableId: string,
		isForced?: Maybe<boolean>,
		opts?: Maybe<IServiceRequestOptions>
	): ICancelablePromiseExt<DevNewPlayReply> {
		isForced = isForced ?? false;

		if (tableId === '') {
			throw new Error('Table ID cannot be empty.');
		}

		const options = this.requestOptions(opts);
		const req: DevNewPlayRequest = new DevNewPlayRequest({ tableId, safe: !isForced });
		const promise = this.rpcClient.newPlay(req, options.callOpts);

		return NewCancelablePromiseExt<DevNewPlayReply>(promise, null, options.controller);
	}

	/**
	 * Runs the current play for the specified table. This moves the game to the next state.
	 *
	 * @returns Table ID and Play ID for the new play.
	 */
	public runPlay(
		tableId: string,
		isForced?: Maybe<boolean>,
		opts?: Maybe<IServiceRequestOptions>
	): ICancelablePromiseExt<DevRunPlayReply> {
		isForced = isForced ?? false;

		if (tableId === '') {
			throw new Error('Table ID cannot be empty.');
		}

		const options = this.requestOptions(opts);
		const req: DevRunPlayRequest = new DevRunPlayRequest({ tableId, forced: isForced });
		const promise = this.rpcClient.runPlay(req, options.callOpts);

		return NewCancelablePromiseExt<DevRunPlayReply>(promise, null, options.controller);
	}

	/**
	 * Fetches the current table state data.
	 *
	 * @returns Table state reply object.
	 */
	public getTableState(
		tableId: string,
		opts?: Maybe<IServiceRequestOptions>
	): ICancelablePromiseExt<DevTableStateReply> {
		if (tableId === '') {
			throw new Error('Table ID cannot be empty.');
		}

		const options = this.requestOptions(opts);
		const req: DevTableStateRequest = new DevTableStateRequest({ tableId });
		const promise = this.rpcClient.getTableState(req, options.callOpts);

		return NewCancelablePromiseExt<DevTableStateReply>(promise, null, options.controller);
	}

	/**
	 * @returns An array of requests that have been issued to the specified device.
	 */
	public getDeviceRequests(
		deviceId: string,
		opts?: Maybe<IServiceRequestOptions>
	): ICancelablePromiseExt<DevGetDeviceReqsReply> {
		if (deviceId === '') {
			throw new Error('Device ID cannot be empty.');
		}

		const options = this.requestOptions(opts);
		const req: DevGetDeviceReqsRequest = new DevGetDeviceReqsRequest({ deviceId });
		const promise = this.rpcClient.getDeviceRequests(req, options.callOpts);

		return NewCancelablePromiseExt<DevGetDeviceReqsReply>(promise, null, options.controller);
	}

	/**
	 * Issues a request to the specified device to control various functions/behaviors.
	 *
	 * @returns An empty reply.
	 */
	public deviceInput(
		deviceId: string,
		requestId: string,
		values: string[],
		metaData?: Maybe<StringDict>,
		opts?: Maybe<IServiceRequestOptions>
	): ICancelablePromiseExt<DevDeviceInputReply> {
		if (deviceId === '') {
			throw new Error('Device ID cannot be empty.');
		}
		if (requestId === '') {
			throw new Error('Device request ID cannot be empty.');
		}

		const options = this.requestOptions(opts);
		const meta = metaData ?? {};
		const req: DevDeviceInputRequest = new DevDeviceInputRequest({ deviceId, requestId, values, meta });
		const promise = this.rpcClient.deviceInput(req, options.callOpts);

		return NewCancelablePromiseExt<DevDeviceInputReply>(promise, null, options.controller);
	}

	/* #endregion ---- Public ---------------------------------------------------------------------------------------- */

	/* #region ---- Protected ---------------------------------------------------------------------------------------- */

	/**
	 * @returns The promise client instance used by this service.
	 */
	protected createPromiseClient(url: string, clientOpts?: Maybe<IClientOpts>) {
		return this.newPromiseClient(GameDevClient, url, clientOpts);
	}

	/* #endregion ---- Protected ------------------------------------------------------------------------------------- */
}

// ---- Exports -------------------------------------------------------------------------------------------------------

export { GameDevService as default };
export { GameDevService };
