import { set } from 'mobx';
import { DebugBase } from '../../../../common';
import { filterNullUndefined, formatCurrency, IMethodResolveAmountsOpts, isObjectEmpty } from '../../../../helpers';
import { makeActiveWagerKeyFromAvailableKey } from '../../../../helpers/data/utility';
import {
	IMethodGetSeatWagersOpts,
	IMethodGetWagersOpts,
	IMethodGetWagerTotalsOpts,
	IMultiSeatWagers,
	IMultiSeatWagerSet,
	IMultiSeatWagerTotalsSet,
	ISeatWagerSet,
	IWagerData,
	IWagers,
	IWagersNoNull,
	IWagerTotalData,
} from '../../types';
import {
	fillNewKeyLookup,
	fillNewMultiSeatWagerSet,
	fillNewMultiSeatWagerTotalsSet,
	fillNewSeatLookup,
	getMultiSeatWagersNums,
	newMultiSeatWagers,
	newSeatWagerSet,
	newWagers,
	newWagerTotalData,
	resolveWageringSeats,
} from '../../utility';
import { bindLocalWagersMobX } from './mobx';
import {
	BatchUpsertLocalActiveWagersList,
	ILocalActiveWagerDataEntry,
	ILocalActiveWagersData,
	ILocalActiveWagersDataDiff,
	ILocalActiveWagersOpts,
	IMethodGetWagerNamesResult,
	LocalActiveWagersList,
	LocalActiveWagersLookup,
} from './types';
import {
	copyData,
	defaultData,
	diffLocalDataFromBatchUpsert,
	extractSeatNumbers,
	extractWagerIds,
	extractWagerNames,
	generateDataListHashId,
	mapEntryToWagerData,
} from './utility';

class LocalActiveWagers extends DebugBase {
	/* #region ---- Properties --------------------------------------------------------------------------------------- */

	/**
	 * Currently assigned options.
	 */
	protected override _options: ILocalActiveWagersOpts = LocalActiveWagers.defaultOptions();

	/**
	 * Encapsulated data.
	 */
	protected _data: ILocalActiveWagersData;

	/**
	 * Flag indicating if this class instance is currently bound to MobX as an observable.
	 */
	protected _isMobXBound: boolean = false;

	/**
	 * Value to use when setting the `isDirty` property on new or updated entries.
	 */
	protected _isNewDataDirtyDefaultVal: boolean = true;

	/* #endregion ---- Properties -------------------------------------------------------------------------------------*/

	/* #region ---- CONSTRUCTOR ---------------------------------------------------------------------------------------*/

	constructor(opts?: Maybe<ILocalActiveWagersOpts>) {
		super();

		this._data = { ...defaultData(), ...opts?.data };
		opts != null && this.setOptions(opts);

		// Bind to MobX as an observable.
		if (this._options.useMobX) {
			bindLocalWagersMobX(this);
		}
	}

	/* #endregion ---- CONSTRUCTOR ----------------------------------------------------------------------------------- */

	/* #region ---- Public ------------------------------------------------------------------------------------------- */

	/**
	 * Sets/initializes the class options.
	 *
	 * - Overrides the parent class method.
	 */
	public override setOptions(opts: ILocalActiveWagersOpts) {
		const { newOpts, origOpts } = this.resolveOptions(opts);

		if (this._isMobXBound) {
			set(this, '_options', newOpts);
		} else {
			this._options = newOpts;
		}

		this.onSetOptions(newOpts, origOpts);
	}

	/**
	 * Whether or not the data collection is empty.
	 */
	public get isEmpty(): boolean {
		return this.size === 0;
	}

	/**
	 * Size of the data collection.
	 */
	public get size(): number {
		return this.lookup.size;
	}

	/**
	 * Lookup of local wagers data keyed by wager key.
	 */
	public get lookup(): LocalActiveWagersLookup {
		return this._data.lookup;
	}

	/**
	 * Flat array list of the local wager entries.
	 */
	public get list(): LocalActiveWagersList {
		return Array.from(this.lookup.values());
	}

	/**
	 * @returns All defined lookup keys in the data collection.
	 */
	public get keys(): string[] {
		return Array.from(this.lookup.keys());
	}

	/**
	 * The unique hash ID for the data collection.
	 */
	public get dataHashId(): string {
		return this.data.hashId ?? '';
	}

	/**
	 * The full data encapsulated by this class instance.
	 */
	public get data(): ILocalActiveWagersData {
		return this._data;
	}
	public set data(val: ILocalActiveWagersData) {
		this.setData(val);
	}
	// Actionable setter method for MobX.
	protected setData(val: ILocalActiveWagersData) {
		if (val === this._data) {
			return;
		}

		const list = Array.from(val.lookup.values());
		val.hashId = generateDataListHashId(list);

		if (this.isMobXBound) {
			set(this, '_data', val);
		} else {
			this._data = val;
		}
	}

	/**
	 * The last time (unix timestamp) that the data was updated.
	 */
	public get lastUpdatedTs(): number {
		return this._data.lastUpdatedTs;
	}
	public set lastUpdatedTs(val: number) {
		this.setLastUpdatedTs(val);
	}
	// Actionable setter method for MobX.
	protected setLastUpdatedTs(val: number) {
		if (val === this._data.lastUpdatedTs) {
			return;
		}

		this._data.lastUpdatedTs = val;
	}

	/**
	 * Get/set the active play ID.
	 */
	public get playId(): string {
		return this._data.playId;
	}
	public set playId(val: string) {
		this.setPlayId(val);
	}
	// Actionable setter method for MobX.
	protected setPlayId(val: string) {
		if (val === this._data.playId) {
			return;
		}

		const prev = this._data.playId;
		this._data.playId = val;
		this.onPlayIdChanged(this._data.playId, prev);
	}

	/**
	 * Gets/sets whether or not this class instance is currently bound to MobX as an observable.
	 */
	public get isMobXBound(): boolean {
		return this._isMobXBound;
	}
	public set isMobXBound(value: boolean) {
		this._isMobXBound = value;
	}

	/**
	 * @returns The wager data entry for the specified active wager key, or NULL if not present.
	 */
	public get(activeWagerKey: string, opts?: Maybe<{ copy?: Maybe<boolean> }>): Nullable<ILocalActiveWagerDataEntry> {
		const entry = this.lookup.get(activeWagerKey) ?? null;

		if (opts?.copy === true && entry != null) {
			return { ...entry };
		}

		return entry;
	}

	/**
	 * @returns TRUE if a wager data entry for the specified wager key exists.
	 */
	public has(activeWagerKey: string): boolean {
		return this.lookup.has(activeWagerKey);
	}

	/**
	 * ACTION
	 * Sets the wager data entry for the specified active wager key.
	 */
	public set(activeWagerKey: string, entry: ILocalActiveWagerDataEntry) {
		const data = copyData(this._data, { updatedTs: Date.now() });
		data.lookup.set(activeWagerKey, entry);

		this.setData(data);
	}

	/**
	 * @returns All available seat numbers in the data collection.
	 */
	public get seatNumbers(): number[] {
		return extractSeatNumbers(this.lookup);
	}

	/**
	 * @returns All defined wager IDs in the data collection.
	 */
	public get wagerIds(): string[] {
		return extractWagerIds(this.lookup).all;
	}

	/**
	 * @returns All available wager names in the data collection.
	 */
	public get wagerNames(): string[] {
		return extractWagerNames(this.lookup).all;
	}

	/**
	 * @returns All wagers in the data collection as a multi-seat wagers object.
	 */
	public get wagers(): IMultiSeatWagerSet {
		return this.getWagers();
	}

	/**
	 * @returns Totals data for all wagers in the data collection as a multi-seat wagers totals object.
	 */
	public get wagerTotals(): IMultiSeatWagerTotalsSet {
		return this.getWagerTotals();
	}

	/**
	 * Resets the class data back to the initial/clear values.
	 */
	public clear(opts?: Maybe<{ updatedTs?: Maybe<number> }>) {
		const data = defaultData({ updatedTs: opts?.updatedTs ?? 0, playId: this.playId });
		this.setData(data);
	}

	/**
	 * @returns A new clone of this class instance with copied data.
	 */
	public clone(
		opts?: Maybe<{ updatedTs?: Maybe<number>; instanceOpts?: Maybe<ILocalActiveWagersOpts> }>
	): LocalActiveWagers {
		const newData = copyData(this._data, { updatedTs: opts?.updatedTs });

		const instanceOpts: ILocalActiveWagersOpts = {
			...{
				...this._options,
				// These will be set via data or instanceOpts
				updatedTs: null,
				playId: null,
			},

			data: newData,
			...filterNullUndefined(opts?.instanceOpts ?? {}),
		};

		return new LocalActiveWagers(instanceOpts);
	}

	/**
	 * @returns A deep copy of the data encapsulated by this class instance.
	 */
	public copyData(opts?: Maybe<{ updatedTs?: Maybe<number> }>): ILocalActiveWagersData {
		return copyData(this._data, { updatedTs: opts?.updatedTs });
	}

	/**
	 * @returns A JSON export of the pertinent data.
	 */
	public override toJson(extended?: Maybe<boolean>): PlainObject {
		extended = extended ?? false;

		const data = copyData(this._data);

		const toJs = (val: unknown) => DebugBase.toJs(val, { extended, useMobXToJs: this.isMobXBound });

		const result: PlainObject = {
			lastUpdatedTs: this.lastUpdatedTs,
			size: this.size,
			isEmpty: this.size === 0,
			list: toJs(this.list),
			lookup: toJs(this.lookup),
			playId: data.playId,
			seatNumbers: toJs(this.seatNumbers),
			wagerNames: toJs(this.wagerNames),
			wagerIds: toJs(this.wagerIds),
			wagers: toJs(this.wagers),
			wagerTotals: toJs(this.wagerTotals),
		};

		if (extended) {
			result.extended = {
				isMobXBound: this.isMobXBound,
				options: toJs({ ...this._options }),
				rawData: toJs(data),

				// Debug info from base class
				_Debug: { ...super.toJson(extended) },
			};
		}

		return result;
	}

	/**
	 * Gets all/filtered wager data - across all or some seats in the data.
	 *
	 * - Will only process available seats - and then filter than further by `filterSeatNumbers` & `filterWagerIds`.
	 * - When `filterSeatNumbers` is specified an entry for every seat number specified will be returned. Seats that are
	 *   invalid or have no matching wagers will return as value NULL.
	 * - When `filterSeatNumbers` is NOT specified an entry for every available seat number will be returned. Seats that
	 *   have no matching wagers will return as value NULL.
	 */
	public getWagers(opts?: Maybe<IMethodGetWagersOpts>): IMultiSeatWagerSet {
		const filterSeatNumbers = opts?.filterSeatNumbers ?? [];
		const filterWagerNames = opts?.filterWagerNames ?? [];
		const filterWagerIds = opts?.filterWagerIds ?? [];
		const filterContextIds = opts?.filterContextIds ?? [];

		let result: IMultiSeatWagerSet;

		// Get all seat numbers available in the current data collection
		const availableSeatNumbers = this.seatNumbers;

		// Determine what seat numbers to process and what the result should be
		let processSeatNumbers: number[];

		if (filterSeatNumbers.length > 0) {
			result = fillNewMultiSeatWagerSet(filterSeatNumbers, null);

			const { matchedSeats } = resolveWageringSeats(availableSeatNumbers, filterSeatNumbers);
			processSeatNumbers = matchedSeats;
		} else {
			result = fillNewMultiSeatWagerSet(availableSeatNumbers, null);
			processSeatNumbers = availableSeatNumbers;
		}

		// Early exit if there are no seat numbers to process
		const list = this.list.slice();
		if (list.length === 0) {
			return result;
		}

		const processSeats: Map<number, boolean> = fillNewSeatLookup<boolean>(processSeatNumbers, true);
		const processWagerNames: Map<string, boolean> = fillNewKeyLookup<boolean>(filterWagerNames, true);
		const processWagerIds: Map<string, boolean> = fillNewKeyLookup<boolean>(filterWagerIds, true);
		const processContextIds: Map<string, boolean> = fillNewKeyLookup<boolean>(filterContextIds, true);
		const multipleSeatWagers: IMultiSeatWagers = result.seatWagers ?? newMultiSeatWagers();

		let wagerCount = 0;

		list.forEach((entry: ILocalActiveWagerDataEntry) => {
			const seatNumber: number = entry.seatNumber;
			const wagerName = entry.name;
			const wagerId = entry.wagerId;
			const contextId = entry.contextId;
			const availableWagerKey = entry.availableWagerKey;

			if (seatNumber < 1) {
				return; // Next entry
			}
			if (processSeats.size > 0 && !processSeats.has(seatNumber)) {
				return; // Next entry
			}
			if (processWagerNames.size > 0 && wagerName !== '' && !processWagerNames.has(wagerName)) {
				return; // Next entry
			}
			if (processWagerIds.size > 0 && wagerId !== '' && !processWagerIds.has(wagerId)) {
				return; // Next entry
			}
			if (processContextIds.size > 0 && contextId !== '' && !processContextIds.has(contextId)) {
				return; // Next entry
			}

			const seatWagerSet: ISeatWagerSet = multipleSeatWagers[seatNumber] ?? newSeatWagerSet({ seatNumber });
			const seatWagers: IWagers = seatWagerSet.wagers ?? newWagers();

			const wager: IWagerData = mapEntryToWagerData(entry);
			seatWagers[availableWagerKey] = wager;

			seatWagerSet.wagers = seatWagers;
			seatWagerSet.wagerCount++;

			multipleSeatWagers[seatNumber] = seatWagerSet;
			wagerCount++;
		});

		result.seatWagers = multipleSeatWagers;
		result.wagerCount = wagerCount;
		result.seatNumbers = getMultiSeatWagersNums(multipleSeatWagers);

		return result;
	}

	/**
	 * Retrieves a single seat's worth of wager data - optionally filtered by wager name(s).
	 *
	 * - Wagers returned will be filtered to those that match `filterWagerNames` (if specified).
	 * - If no relevant wagers are found for the given seat number, NULL will be returned.
	 */
	public getSingleSeatWagers(seatNumber: number, opts?: Maybe<IMethodGetSeatWagersOpts>): Nullable<ISeatWagerSet> {
		const debugMethod = 'getSingleSeatWagers';

		const filterWagerNames = opts?.filterWagerNames ?? [];
		const filterWagerIds = opts?.filterWagerIds ?? [];

		if (seatNumber < 1) {
			this.error('Invalid seat number specified.', debugMethod, { seatNumber });
			return null;
		}

		const data = this.getWagers({ filterSeatNumbers: [seatNumber], filterWagerNames, filterWagerIds });
		const multipleSeatWagers: IMultiSeatWagers = data.seatWagers ?? {};
		const singleSeatWagers = multipleSeatWagers[seatNumber] ?? null;

		if (singleSeatWagers == null || singleSeatWagers.wagerCount === 0) {
			this.warn('No relevant wagers found for the given seat number & filters.', debugMethod, {
				seatNumber,
				...(opts ?? {}),
			});
			return null;
		}

		return singleSeatWagers;
	}

	/**
	 * @returns Wager data for the given available wager key and optional seat number.
	 */
	public getAvailableWager(
		availableWagerKey: string,
		opts?: Maybe<{ seatNumber?: Maybe<number> }>
	): Nullable<IWagerData> {
		const debugMethod = 'getAvailableSeatWager';

		const seatNumber = opts?.seatNumber ?? 1;

		if (seatNumber < 1) {
			this.error(`Invalid seat number '${seatNumber}' specified.`, debugMethod);
			return null;
		}

		if (availableWagerKey === '') {
			this.error('Invalid available wager key specified.', debugMethod, { availableWagerKey });
			return null;
		}

		const activeWagerKey = makeActiveWagerKeyFromAvailableKey(availableWagerKey, { seatNumber });

		if (activeWagerKey === '') {
			this.warn(`Failed to make active wager key.`, debugMethod, {
				availableWagerKey,
				seatNumber,
			});
			return null;
		}

		return this.get(activeWagerKey) as IWagerData;
	}

	/**
	 * @returns A set of multiple-seat wager data - optionally filtered by seat number(s) and/or wager name(s).
	 */
	public getWagerTotals(opts?: Maybe<IMethodGetWagerTotalsOpts>): IMultiSeatWagerTotalsSet {
		const formatCurrencyOpts = opts?.formatCurrencyOpts ?? null;

		// Get all wagers matching the specified filters
		const wagerSet: IMultiSeatWagerSet = this.getWagers(opts);
		const seatNumbers = wagerSet.seatNumbers;

		const result: IMultiSeatWagerTotalsSet = fillNewMultiSeatWagerTotalsSet(seatNumbers, null);
		result.seatNumbers = seatNumbers;

		// If we have no wagers, return an empty totals set
		if (wagerSet.wagerCount === 0 || isObjectEmpty(wagerSet.seatWagers)) {
			// this.info('No wager data extracted matching specified filters.', 'getWagerTotals', { ...opts });

			return result;
		}

		const multiSeatWagers: IMultiSeatWagers = wagerSet.seatWagers ?? {};
		const sumTotals: IWagerTotalData = newWagerTotalData(null, formatCurrencyOpts);

		for (const seatNumber of seatNumbers) {
			const seatWagerSet = multiSeatWagers[seatNumber] ?? null;
			const seatWagers = filterNullUndefined(seatWagerSet?.wagers ?? {}) as IWagersNoNull;

			// If there are no wagers for this seat, skip it - thus `result.seatTotals` will contain NULL for this seat
			if (isObjectEmpty(seatWagers)) {
				continue; // Next seat
			}

			const seatTotals: IWagerTotalData = result.seatTotals[seatNumber] ?? newWagerTotalData(null, formatCurrencyOpts);

			for (const wagerKey in seatWagers) {
				const seatWager = seatWagers[wagerKey];
				seatTotals.wagerCount += 1;
				seatTotals.amount += seatWager.amount;
				seatTotals.amountReal += seatWager.amountReal;
			}

			seatTotals.amountMoney = formatCurrency(seatTotals.amountReal, formatCurrencyOpts);
			result.seatTotals[seatNumber] = seatTotals;

			if (seatTotals.wagerCount > 0) {
				sumTotals.wagerCount += seatTotals.wagerCount;
				sumTotals.amount += seatTotals.amount;
				sumTotals.amountReal += seatTotals.amountReal;
			}
		}

		sumTotals.amountMoney = formatCurrency(sumTotals.amountReal, formatCurrencyOpts);
		result.sumTotals = sumTotals;

		return result;
	}

	// /**
	//  * ACTION
	//  * Updates the local wager data with the given batch of updates.
	//  *
	//  * Data update is immutable and operates on a copy of the local wagers data.
	//  *
	//  * @returns A diff summary of the internal data if successful, otherwise NULL.
	//  */
	// public batchUpdateWagerData(batch: IBatchUpdateLocalActiveWagerProps): Nullable<ILocalActiveWagersDataDiff> {
	// 	const debugMethod = 'batchUpdateWagerData';

	// 	if (isObjectEmpty(batch)) {
	// 		this.error('No batch data specified for update', debugMethod);
	// 		return null;
	// 	}

	// 	const diffData = diffLocalDataFromBatchUpdate(batch, this.data);

	// 	if (diffData === null) {
	// 		this.error('Failed to update wager data.', debugMethod, { batch });
	// 		return null;
	// 	}

	// 	this.setData(diffData.updated);

	// 	return diffData;
	// }

	/**
	 * ACTION
	 * Updates the local wager data with the given batch of data to insert or update.
	 *
	 * - If the wager data already exists, it will be updated with the new data.
	 * - If the wager data does not exist, it will be inserted.
	 * - Data update is immutable and operates on a copy of the local wagers data.
	 *
	 * @returns A diff summary of the internal data if successful, otherwise NULL.
	 */
	public batchUpsertWagerData(
		batch: BatchUpsertLocalActiveWagersList,
		opts?: Maybe<{ resolveAmountsOpts?: Maybe<IMethodResolveAmountsOpts>; sequenceOffset?: Maybe<number> }>
	): Nullable<ILocalActiveWagersDataDiff> {
		const debugMethod = 'batchUpsertWagerData';

		if (batch.length === 0) {
			this.error('No batch data specified for insertion', debugMethod);
			return null;
		}

		const diffData = diffLocalDataFromBatchUpsert(batch, this.data, {
			isDirtyDefaultVal: this._isNewDataDirtyDefaultVal,
			resolveAmountsOpts: opts?.resolveAmountsOpts,
			sequenceOffset: opts?.sequenceOffset,
		});

		if (diffData === null) {
			this.error('Failed to update wager data.', debugMethod, { batch });
			return null;
		}

		this.setData(diffData.updated);

		return diffData;
	}

	/* #endregion ---- Public ---------------------------------------------------------------------------------------- */

	/* #region ---- Protected ---------------------------------------------------------------------------------------- */

	/**
	 * Resolves the options being passed in and returns the original and new options.
	 *
	 * - Overrides the parent class method
	 */
	protected override resolveOptions(opts?: Maybe<ILocalActiveWagersOpts>) {
		const origOpts: ILocalActiveWagersOpts = {
			...LocalActiveWagers.defaultOptions(),
			...this._options,
		};

		const newOpts: ILocalActiveWagersOpts = {
			...origOpts,
			...(opts ?? {}),
		};

		return { origOpts, newOpts };
	}

	/**
	 * Called after new options are set.
	 *
	 * - Extends the parent class method
	 */
	protected override onSetOptions(newOpts: ILocalActiveWagersOpts, origOpts?: Maybe<ILocalActiveWagersOpts>) {
		super.onSetOptions(newOpts, origOpts);

		if (
			newOpts.isNewDataDirtyDefaultVal != null &&
			newOpts.isNewDataDirtyDefaultVal !== this._isNewDataDirtyDefaultVal
		) {
			this._isNewDataDirtyDefaultVal = newOpts.isNewDataDirtyDefaultVal;
		}

		if (newOpts.data != null && (!this._data || newOpts.data.uniqId !== this._data.uniqId)) {
			this.setData(newOpts.data);
		}

		if (!this._data) {
			return;
		}
		if (newOpts.updatedTs != null && newOpts.updatedTs !== this._data.lastUpdatedTs) {
			this.setLastUpdatedTs(newOpts.updatedTs);
		}
		if (newOpts.playId != null && newOpts.playId !== this._data.playId) {
			this.setPlayId(newOpts.playId);
		}
	}

	/**
	 * Called when the play ID changes.
	 */
	protected onPlayIdChanged(newPlayId: string, prevPlayId: string) {
		if (prevPlayId === '') {
			return;
		}

		if (this.size > 0) {
			this.warn('Local wagers data will be cleared due to the active play being changed.', 'onPlayIdChanged', {
				prevPlayId,
				newPlayId,
			});

			// Clear the data when the play ID changes.
			this.clear();
		}
	}

	/* #endregion ---- Protected ------------------------------------------------------------------------------------- */

	/* #region ---- Static ------------------------------------------------------------------------------------------- */

	/**
	 * STATIC
	 * @returns A clone of the specified class instance.
	 */
	public static cloneInstance(from: LocalActiveWagers, opts?: Maybe<{ updatedTs?: Maybe<number> }>): LocalActiveWagers {
		return from.clone(opts);
	}

	/**
	 * STATIC
	 * @returns The default options data used by this class.
	 */
	public static defaultOptions(): ILocalActiveWagersOpts {
		return {
			...DebugBase.defaultOptions(),
			data: null,
			playId: null,
			updatedTs: null,
			useMobX: true,
		};
	}

	/**
	 * STATIC
	 * @returns Unique available seat numbers in the specified lookup.
	 */
	public static getSeatNumbers(lookup: Map<string, ILocalActiveWagerDataEntry>): number[] {
		return extractSeatNumbers(lookup);
	}

	/**
	 * STATIC
	 * @returns Unique available wager names in the specified lookup.
	 */
	public static getWagerNames(lookup: Map<string, ILocalActiveWagerDataEntry>): IMethodGetWagerNamesResult {
		return extractWagerNames(lookup);
	}

	/* #endregion ---- Static ---------------------------------------------------------------------------------------- */

	/* #region ---- Debug -------------------------------------------------------------------------------------------- */

	/**
	 * Overrides the parent class property.
	 *
	 * @returns The label to use when debugging.
	 */
	protected override get debugClassLabel(): string {
		return LocalActiveWagers.debugClassLabel();
	}

	/**
	 * STATIC
	 * @returns Label assigned to this class namespace.
	 */
	protected static debugClassLabel(): string {
		return `RpcLib.DataObject.LocalActiveWagers`;
	}

	/* #endregion ---- Debug ----------------------------------------------------------------------------------------- */
}

// ---- Exports -------------------------------------------------------------------------------------------------------

export { LocalActiveWagers as default };
export { LocalActiveWagers };
