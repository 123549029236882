/**
 * ChoiceManager events.
 */
enum Events {
	// Sent when we have all new choices.
	NEW_CHOICES = 'NEW_CHOICES',
	// Sent when there's a new choice period.
	NEW_CHOICE_PERIOD = 'NEW_CHOICE_PERIOD',
	// Sent when a choice period changes. This will normally happen when the remaining time updates.
	CHOICE_PERIOD_CHANGED = 'CHOICE_PERIOD_CHANGED',
	// Sent when a choice period is closed.
	CHOICE_PERIOD_CLOSED = 'CHOICE_PERIOD_CLOSED',
}

// ---- Export --------------------------------------------------------------------------------------------------------

export { Events };
