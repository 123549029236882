import { IDebugMethodOpts, ILogger } from './types';

/**
 * @returns A minimal logger that does nothing.
 */
const newNoOpLogger = (): ILogger => ({
	silent: true,
	prefix: 'NoOpLogger',

	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	error: (msg: string, method?: Maybe<string>, ...args: unknown[]) => {},
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	warn: (msg: string, method?: Maybe<string>, ...args: unknown[]) => {},
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	info: (msg: string, method?: Maybe<string>, ...args: unknown[]) => {},
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	makeMessage: (msg: string, method?: Maybe<string>): string => '',
});

const debugMsg = (msg: string, opts?: Maybe<IDebugMethodOpts>): string => {
	if (msg === '') {
		return '';
	}

	const logPrefix = opts?.prefix || '';
	const logMethod = opts?.method || '';

	const prefix: string[] = [];
	prefix.push(logPrefix !== '' ? `[${logPrefix}]: ` : '');
	prefix.push(logMethod !== '' ? `${logMethod} - ` : '');

	return `${prefix.join('')}${msg}`;
};

const logError = (msg: string, opts?: Maybe<IDebugMethodOpts>, ...args: unknown[]): void => {
	console.error(debugMsg(msg, opts), ...args);
};

const logWarn = (msg: string, opts?: Maybe<IDebugMethodOpts>, ...args: unknown[]): void => {
	console.warn(debugMsg(msg, opts), ...args);
};

const logInfo = (msg: string, opts?: Maybe<IDebugMethodOpts>, ...args: unknown[]): void => {
	console.log(debugMsg(msg, opts), ...args);
};

// ---- Exports -------------------------------------------------------------------------------------------------------

export { debugMsg, logError, logWarn, logInfo };
export { newNoOpLogger };
