import { IDeviceService, IGameService, IUserService } from '../../../client/service/types';
import { filterNullUndefined } from '../../../helpers';
import {
	bindPlayStoreMobX as bindBasePlayStoreMobX,
	bindDeviceStoreMobX,
	bindSettingsStoreMobX,
	bindTableStoreMobX,
	bindUserStoreMobX,
	IPlayStore,
	IPlayStoreOpts,
} from '../../../store';
import { DeviceStore, PlayStore, SettingsStore, TableStore, UserStore } from '../../../store';
import {
	IStoreFactoryNewDeviceStoreOpts,
	IStoreFactoryNewPlayStoreOpts,
	IStoreFactoryNewSettingsStoreOpts,
	IStoreFactoryNewTableStoreOpts,
	IStoreFactoryNewUserStoreOpts,
} from './types';

/**
 * Store factory class
 */
class StoreFactory {
	/**
	 * @returns A new `UserStore` instance.
	 */
	public static newUserStore(service: IUserService, opts?: Maybe<IStoreFactoryNewUserStoreOpts>): UserStore {
		// Store options
		const isDebugEnabled = opts?.isDebugEnabled ?? null;
		const debugLabel = opts?.debugLabel || null;
		const storeOpts = { ...opts?.storeOpts, ...filterNullUndefined({ isDebugEnabled, debugLabel }) };

		// Create store
		const store = new UserStore(service, storeOpts);

		// Bind to MobX if specified
		if (opts?.useMobX === true) {
			bindUserStoreMobX(store);
		}

		return store;
	}

	/**
	 * @returns A new `TableStore` instance.
	 */
	public static newTableStore(service: IGameService, opts?: Maybe<IStoreFactoryNewTableStoreOpts>): TableStore {
		// Store options
		const isDebugEnabled = opts?.isDebugEnabled ?? null;
		const debugLabel = opts?.debugLabel || null;
		const storeOpts = { ...opts?.storeOpts, ...filterNullUndefined({ isDebugEnabled, debugLabel }) };

		// Create store
		const store = new TableStore(service, storeOpts);

		// Bind to MobX if specified
		if (opts?.useMobX === true) {
			bindTableStoreMobX(store);
		}

		return store;
	}

	/**
	 * @returns A new `PlayStore` instance of the specified type.
	 */
	public static newPlayStore<
		PlayStoreType extends IPlayStore = PlayStore,
		IPlayStoreOptsType extends IPlayStoreOpts = IPlayStoreOpts
	>(
		service: IGameService,
		opts?: Maybe<IStoreFactoryNewPlayStoreOpts<PlayStoreType, IPlayStoreOptsType>>
	): PlayStoreType {
		// Store options
		const isDebugEnabled = opts?.isDebugEnabled ?? null;
		const debugLabel = opts?.debugLabel || null;
		const storeOpts = { ...opts?.storeOpts, ...filterNullUndefined({ isDebugEnabled, debugLabel }) };

		// Create store
		let store: PlayStoreType;
		if (opts?.playStoreClass != null) {
			const CustomPlayStore = opts?.playStoreClass;
			store = new CustomPlayStore(service, storeOpts);
		} else {
			store = new PlayStore(service, storeOpts) as PlayStoreType;
		}

		// Bind to MobX if specified
		if (opts?.useMobX === true) {
			// If a custom bind function is not provided then use the one for the base play store.
			const bindPlayStoreMobX = opts?.bindPlayStoreMobX ?? bindBasePlayStoreMobX;
			bindPlayStoreMobX(store);
		}

		return store;
	}

	/**
	 * @returns A new `SettingsStore` instance.
	 */
	public static newSettingsStore(opts?: Maybe<IStoreFactoryNewSettingsStoreOpts>): SettingsStore {
		// Store options
		const isDebugEnabled = opts?.isDebugEnabled ?? null;
		const debugLabel = opts?.debugLabel || null;
		const storeOpts = { ...opts?.storeOpts, ...filterNullUndefined({ isDebugEnabled, debugLabel }) };

		// Create store
		const store = new SettingsStore(storeOpts);

		// Bind to MobX if specified
		if (opts?.useMobX === true) {
			bindSettingsStoreMobX(store);
		}

		return store;
	}

	/**
	 * @returns A new `DeviceStore` instance.
	 */
	public static newDeviceStore(service: IDeviceService, opts?: Maybe<IStoreFactoryNewDeviceStoreOpts>): DeviceStore {
		// Store options
		const isDebugEnabled = opts?.isDebugEnabled ?? null;
		const debugLabel = opts?.debugLabel || null;
		const storeOpts = { ...opts?.storeOpts, ...filterNullUndefined({ isDebugEnabled, debugLabel }) };

		// Create store
		const store = new DeviceStore(service, storeOpts);

		// Bind to MobX if specified
		if (opts?.useMobX === true) {
			bindDeviceStoreMobX(store);
		}

		return store;
	}
}

// ---- Exports -------------------------------------------------------------------------------------------------------

export { StoreFactory as default };
export { StoreFactory };
