/**********************************************************************************************************************
 * Initialization of the various RPC streams.
 *********************************************************************************************************************/
import isFunction from 'lodash/isFunction';
import { IAuthSessionProvider, IStreamOpts, SessionProvider, StreamManager } from '../../../client/core';
import { newDefaultOmnibusSession } from '../../../client/core/helpers';
import {
	IGetDeviceRequestsRequestFlags,
	IGetSelfRequestFlags,
	IGetTableRequestFlags,
} from '../../../client/service/types';
import {
	DeviceStream,
	GameStream,
	IDeviceDataStream,
	IPlayDataStream,
	ITableDataStream,
	IUserDataStream,
} from '../../../client/stream/types';
import { DebugBase } from '../../../common';
import { filterNullUndefined } from '../../../helpers';
import { StreamKey } from '../constants';
import { IStreamFactoryNewOpts, StreamFactory } from '../StreamFactory';
import { StreamLogger } from '../StreamLogger';
import { IStreamInstances, IStreamsConfig } from './types';
import { IStreams, IStreamsOpts } from './types';

/**
 * Class for creating and managing RPC streams.
 */
class Streams extends DebugBase implements IStreams {
	/* #region ---- Properties --------------------------------------------------------------------------------------- */

	/**
	 * Currently assigned options.
	 */
	protected override _options: IStreamsOpts = Streams.defaultOptions();

	/**
	 * Configuration object.
	 */
	protected _config: IStreamsConfig;

	/**
	 * Stream manager instance associated with this class.
	 */
	protected _streamManager: StreamManager;

	/**
	 * User session instance associated with this class.
	 */
	protected _userSession: SessionProvider;

	/**
	 * Omnibus session instance associated with this class.
	 */
	protected _omnibusSession: SessionProvider;

	/**
	 * Logger instance - created when debug is enabled.
	 */
	protected _streamLogger: Nullable<StreamLogger> = null;

	/**
	 * TRUE if the class has been initialized.
	 */
	protected _isInitialized: boolean = false;

	/* #endregion ---- Properties ------------------------------------------------------------------------------------ */

	/* #region ---- CONSTRUCTOR -------------------------------------------------------------------------------------- */

	public constructor(
		streamManager: StreamManager,
		userSession: SessionProvider,
		config: IStreamsConfig,
		opts?: Maybe<IStreamsOpts>
	) {
		super();

		this.setConfig(config);
		opts && this.setOptions(opts);

		this._userSession = userSession;
		this._omnibusSession = opts?.omnibusSession ?? (newDefaultOmnibusSession() as SessionProvider);
		this._streamManager = streamManager;

		// Initialize immediately if specified via options
		const init = this._options?.init ?? true;
		init && this.init();
	}

	/* #endregion ---- CONSTRUCTOR ----------------------------------------------------------------------------------- */

	/* #region ---- Public ------------------------------------------------------------------------------------------- */

	/**
	 * Sets/initializes the class options.
	 *
	 * - Overrides the parent class method.
	 */
	public override setOptions(opts: IStreamsOpts) {
		const { newOpts, origOpts } = this.resolveOptions(opts);
		this._options = newOpts;
		this.onSetOptions(newOpts, origOpts);
	}

	/**
	 * Sets/initializes the services config.
	 *
	 * - Overrides the parent class method.
	 */
	public setConfig(config: IStreamsConfig) {
		const { newConfig, origConfig } = this.resolveConfig(config);
		this._config = newConfig;
		this.onSetConfig(newConfig, origConfig);
	}

	/**
	 * Initialize the streams. This is done outside of the constructor in order to allow for streams to be
	 * initialized after application bootstrapping.
	 */
	public init(): boolean {
		const debugMethod = 'init';

		if (this._isInitialized) {
			return false;
		}

		const isDebugEnabled = this.isDebugEnabled;

		if (this._streamManager.hasStreams()) {
			this._streamManager.clear();
		}

		this.initTableStream();
		this.initPlayStream();
		this.initUserStream();
		this.initDeviceStream();

		if (!this._streamManager.hasStreams()) {
			this.warn('No streams were initialized', debugMethod);
		} else {
			this.info('Streams successfully initialized', debugMethod);
			this._isInitialized = true;

			if (isDebugEnabled) {
				this._streamLogger = new StreamLogger(this._streamManager, { autoSubscribe: true });
			}
		}

		return this._isInitialized;
	}

	/**
	 * Gets whether the class has been initialized.
	 */
	public get isInitialized(): boolean {
		return this._isInitialized;
	}

	/**
	 * Gets/sets the session instance.
	 */
	public get userSession(): SessionProvider {
		return this._userSession;
	}
	public set userSession(val: SessionProvider) {
		this.setUserSession(val);
	}
	protected setUserSession(val: SessionProvider) {
		if (this._userSession === val) {
			return;
		}

		const prev = this._userSession;
		this._userSession = val;

		this.onUserSessionChanged(val, prev);
	}

	/**
	 * Gets/sets the omnibus session instance.
	 */
	public get omnibusSession(): SessionProvider {
		return this._omnibusSession;
	}
	public set omnibusSession(session: SessionProvider) {
		this._omnibusSession = session;
	}
	protected setOmnibusSession(val: SessionProvider) {
		if (this._omnibusSession === val) {
			return;
		}

		const prev = this._omnibusSession;
		this._omnibusSession = val;

		this.onOmnibusSessionChanged(val, prev);
	}

	/**
	 * Gets the stream manager instance.
	 */
	public get streamManager(): StreamManager {
		return this._streamManager;
	}

	/**
	 * Gets the table stream instance.
	 */
	public get tableStream(): Nullable<ITableDataStream> {
		return this._streamManager.getStream(StreamKey.TableStream) as Nullable<ITableDataStream>;
	}

	/**
	 * Gets the play stream instance.
	 */
	public get playStream(): Nullable<IPlayDataStream> {
		return this._streamManager.getStream(StreamKey.PlayStream) as Nullable<IPlayDataStream>;
	}

	/**
	 * Gets the user stream instance.
	 */
	public get userStream(): Nullable<IUserDataStream> {
		return this._streamManager.getStream(StreamKey.UserStream) as Nullable<IUserDataStream>;
	}

	/**
	 * Gets the device stream instance.
	 */
	public get deviceStream(): Nullable<IDeviceDataStream> {
		return this._streamManager.getStream(StreamKey.DeviceStream) as Nullable<IDeviceDataStream>;
	}

	/**
	 * @returns All stream instances in a single object.
	 *
	 * Typically this is used in the following way:
	 * 	const { tableStream, playStream } = getStreams();
	 */
	public getStreams(): IStreamInstances {
		return {
			tableStream: this.tableStream,
			playStream: this.playStream,
			userStream: this.userStream,
			deviceStream: this.deviceStream,
		};
	}

	/**
	 * Starts the play stream.
	 */
	public startPlayStream(playId?: string): boolean {
		return this._streamManager.start(StreamKey.PlayStream, { playId });
	}

	/**
	 * Restarts the play stream.
	 */
	public restartPlayStream(playId?: string): boolean {
		return this._streamManager.restart(StreamKey.PlayStream, { playId });
	}

	/**
	 * Stops the play stream.
	 */
	public stopPlayStream(): boolean {
		return this._streamManager.stop(StreamKey.PlayStream);
	}

	/**
	 * Starts the table stream.
	 */
	public startTableStream(tableId?: string, flags?: Maybe<IGetTableRequestFlags>): boolean {
		return this._streamManager.start(StreamKey.TableStream, { tableId, ...flags });
	}

	/**
	 * Restarts the table stream.
	 */
	public restartTableStream(tableId?: string, flags?: Maybe<IGetTableRequestFlags>) {
		return this._streamManager.restart(StreamKey.TableStream, { tableId, ...flags });
	}

	/**
	 * Stops the table stream.
	 */
	public stopTableStream(): boolean {
		return this._streamManager.stop(StreamKey.TableStream);
	}

	/**
	 * Starts the user stream.
	 */
	public startUserStream(flags?: Maybe<IGetSelfRequestFlags>): boolean {
		return this._streamManager.start(StreamKey.UserStream, { ...flags });
	}

	/**
	 * Restarts the user stream.
	 */
	public restartUserStream(flags?: Maybe<IGetSelfRequestFlags>): boolean {
		return this._streamManager.restart(StreamKey.UserStream, { ...flags });
	}

	/**
	 * Stops the user stream.
	 */
	public stopUserStream(): boolean {
		return this._streamManager.stop(StreamKey.UserStream);
	}

	/**
	 * Starts the device stream.
	 */
	public startDeviceStream(flags?: Maybe<IGetDeviceRequestsRequestFlags>): boolean {
		return this._streamManager.start(StreamKey.DeviceStream, { ...flags });
	}

	/**
	 * Restarts the device stream.
	 */
	public restartDeviceStream(flags?: Maybe<IGetDeviceRequestsRequestFlags>): boolean {
		return this._streamManager.restart(StreamKey.DeviceStream, { ...flags });
	}

	/**
	 * Stops the device stream.
	 */
	public stopDeviceStream(): boolean {
		return this._streamManager.stop(StreamKey.DeviceStream);
	}

	/**
	 * @returns A JSON export of the pertinent data.
	 */
	public toJson(extended?: Maybe<boolean>): PlainObject {
		extended = extended ?? false;

		const toJs = (val: unknown) => DebugBase.toJs(val, { extended });

		const result: PlainObject = {
			streamManager: toJs(this.streamManager),
			tableStream: toJs(this.tableStream),
			playStream: toJs(this.playStream),
			userStream: toJs(this.userStream),
			deviceStream: toJs(this.deviceStream),
		};

		if (extended) {
			result.isInitialized = this._isInitialized;
			result.options = toJs(this._options);
			result.config = toJs(this._config);
			result.userSession = this._userSession;
			result.omnibusSession = this._omnibusSession;
			result._Debug = { ...super.toJson(extended) };
		}

		return result;
	}

	/* #endregion ---- Public ---------------------------------------------------------------------------------------- */

	/* #region ---- Protected ---------------------------------------------------------------------------------------- */

	/**
	 * Resolves the options being passed in and returns the original and new options.
	 *
	 * - Overrides the parent class method.
	 */
	protected override resolveOptions(opts?: Maybe<IStreamsOpts>) {
		const origOpts: IStreamsOpts = {
			...Streams.defaultOptions(),
			...this._options,
		};

		const newOpts: IStreamsOpts = {
			...origOpts,
			...(opts ?? {}),
		};

		return { origOpts, newOpts };
	}

	/**
	 * Resolves the config being passed in and returns the original and new config.
	 */
	protected resolveConfig(config?: Maybe<IStreamsConfig>) {
		const origConfig: IStreamsConfig = {
			...this._config,
		};

		const newConfig: IStreamsConfig = {
			...origConfig,
			...(config ?? {}),
		};

		return { origConfig, newConfig };
	}

	/**
	 * Called after new options are set.
	 *
	 * - Extends the base class method.
	 */
	protected override onSetOptions(newOpts: IStreamsOpts, origOpts: IStreamsOpts) {
		super.onSetOptions(newOpts, origOpts);

		if (
			this._omnibusSession != null &&
			newOpts.omnibusSession != null &&
			newOpts.omnibusSession !== this.omnibusSession
		) {
			this.setOmnibusSession(newOpts.omnibusSession);
		}
	}

	/**
	 * Called after new options are set.
	 */
	protected onSetConfig(newConfig: IStreamsConfig, _origConfig: IStreamsConfig) {
		if (!this._isInitialized) {
			return;
		}

		this.tableStream != null && (this.tableStream.url = newConfig.gameServerURI);
		this.playStream != null && (this.playStream.url = newConfig.gameServerURI);
		this.playStream != null && (this.playStream.url = newConfig.gameServerURI);
		this.deviceStream != null && (this.deviceStream.url = newConfig.deviceServerURI);
	}

	/**
	 * Called after the class instance `_userSession` provider changes.
	 */
	protected onUserSessionChanged(
		_newSessionProvider: SessionProvider,
		_prevSessionProvider: SessionProvider,
		opts?: Maybe<{ cascadeToStreamInstances?: Maybe<boolean> }>
	) {
		const cascadeToStreamInstances = opts?.cascadeToStreamInstances ?? true;

		if (cascadeToStreamInstances) {
			this.tableStream != null && (this.tableStream.session = this.appliedUserSession());
			this.playStream != null && (this.playStream.session = this.appliedUserSession());
			this.userStream != null && (this.userStream.session = this.appliedUserSession());
		}
	}

	/**
	 * Called after the class instance `_omnibusSession` provider changes.
	 */
	protected onOmnibusSessionChanged(
		_newSessionProvider: SessionProvider,
		_prevSessionProvider: SessionProvider,
		opts?: Maybe<{ cascadeToStreamInstances?: Maybe<boolean> }>
	) {
		const cascadeToStreamInstances = opts?.cascadeToStreamInstances ?? true;

		if (cascadeToStreamInstances) {
			this.deviceStream != null && (this.deviceStream.session = this.appliedOmnibusSession());
		}
	}

	/**
	 * @returns The user session provider to pass to services.
	 */
	protected appliedUserSession(): SessionProvider {
		return isFunction(this._userSession)
			? this._userSession
			: (): IAuthSessionProvider => this._userSession as IAuthSessionProvider;
	}

	/**
	 * @returns The omnibus session provider to pass to services.
	 */
	protected appliedOmnibusSession(): SessionProvider {
		return isFunction(this._omnibusSession)
			? this._omnibusSession
			: (): IAuthSessionProvider => this._omnibusSession as IAuthSessionProvider;
	}

	/**
	 * Registers the specified stream with the stream manager.
	 */
	protected registerStream(streamKey: StreamKey, stream: GameStream | DeviceStream) {
		if (!this._streamManager.registerStream(streamKey, stream)) {
			throw new Error(`Failed to register stream ${streamKey}`);
		}
	}

	/**
	 * Initializes the table stream instance.
	 */
	protected initTableStream = () => {
		const opts = this._options;
		const streamProps = opts?.tableStream ?? null;
		const register = streamProps?.register ?? true;
		const isDebugEnabled = streamProps?.opts?.isDebugEnabled ?? this.isDebugEnabled;

		if (!register) {
			this.info('Table stream has been set to not register', 'init', { ...streamProps });
			return;
		}

		let stream: ITableDataStream;

		this.info('Initializing table stream', 'init', { ...streamProps });

		if (streamProps?.instance != null) {
			stream = streamProps.instance;
			stream.isDebugEnabled = isDebugEnabled;
			stream.session = this.appliedUserSession();
		} else {
			stream = this.newTableStream({ streamOpts: streamProps?.opts, isDebugEnabled });
		}

		this.registerStream(StreamKey.TableStream, stream);
	};

	/**
	 * Initializes the play stream instance.
	 */
	protected initPlayStream = () => {
		const opts = this._options;
		const streamProps = opts?.playStream ?? null;
		const register = streamProps?.register ?? true;
		const isDebugEnabled = streamProps?.opts?.isDebugEnabled ?? this.isDebugEnabled;

		if (!register) {
			this.info('Play stream has been set to not register', 'init', { ...streamProps });
			return;
		}

		let stream: IPlayDataStream;

		this.info('Initializing play stream', 'init', { ...streamProps });

		if (streamProps?.instance != null) {
			stream = streamProps.instance;
			stream.isDebugEnabled = isDebugEnabled;
			stream.session = this.appliedUserSession();
		} else {
			stream = this.newPlayStream({ streamOpts: streamProps?.opts, isDebugEnabled });
		}

		this.registerStream(StreamKey.PlayStream, stream);
	};

	/**
	 * Initializes the user stream instance.
	 */
	protected initUserStream = () => {
		const opts = this._options;
		const streamProps = opts?.userStream ?? null;
		const register = streamProps?.register ?? true;
		const isDebugEnabled = streamProps?.opts?.isDebugEnabled ?? this.isDebugEnabled;

		if (!register) {
			this.info('User stream has been set to not register', 'init', { ...streamProps });
			return;
		}

		let stream: IUserDataStream;

		this.info('Initializing user stream', 'init', { ...streamProps });

		if (streamProps?.instance != null) {
			stream = streamProps.instance;
			stream.isDebugEnabled = isDebugEnabled;
			stream.session = this.appliedUserSession();
		} else {
			stream = this.newUserStream({ streamOpts: streamProps?.opts, isDebugEnabled });
		}

		this.registerStream(StreamKey.UserStream, stream);
	};

	/**
	 * Initializes the device stream instance.
	 */
	protected initDeviceStream = () => {
		const opts = this._options;
		const streamProps = opts?.deviceStream ?? null;
		const register = streamProps?.register ?? true;
		const isDebugEnabled = streamProps?.opts?.isDebugEnabled ?? this.isDebugEnabled;

		if (!register) {
			this.info('Device stream has been set to not register', 'init', { ...streamProps });
			return;
		}

		let stream: IDeviceDataStream;

		this.info('Initializing device stream', 'init', { ...streamProps });

		if (streamProps?.instance != null) {
			stream = streamProps.instance;
			stream.isDebugEnabled = isDebugEnabled;
			stream.session = this.appliedOmnibusSession();
		} else {
			stream = this.newDeviceStream({ streamOpts: streamProps?.opts, isDebugEnabled });
		}

		this.registerStream(StreamKey.DeviceStream, stream);
	};

	/**
	 * @returns A new table stream instance.
	 */
	protected newTableStream(opts?: Maybe<Partial<IStreamFactoryNewOpts>>): ITableDataStream {
		const optsN: Partial<IStreamFactoryNewOpts> = filterNullUndefined(opts ?? {});

		const url: string = optsN?.url || this._config.gameServerURI;
		const session: SessionProvider = optsN?.session ?? this.appliedUserSession();
		const streamOpts: Nullable<IStreamOpts> = optsN?.streamOpts ?? this._options?.tableStream?.opts ?? null;
		const isDebugEnabled = optsN?.isDebugEnabled ?? streamOpts?.isDebugEnabled ?? this.isDebugEnabled;
		const newStreamProps: IStreamFactoryNewOpts = { ...optsN, isDebugEnabled, streamOpts, url, session };

		return StreamFactory.newTableStream(newStreamProps);
	}

	/**
	 * @returns A new play stream instance.
	 */
	protected newPlayStream(opts?: Maybe<Partial<IStreamFactoryNewOpts>>): IPlayDataStream {
		const optsN: Partial<IStreamFactoryNewOpts> = filterNullUndefined(opts ?? {});

		const url: string = optsN?.url || this._config.gameServerURI;
		const session: SessionProvider = optsN?.session ?? this.appliedUserSession();
		const streamOpts: Nullable<IStreamOpts> = optsN?.streamOpts ?? this._options?.playStream?.opts ?? null;
		const isDebugEnabled = optsN?.isDebugEnabled ?? streamOpts?.isDebugEnabled ?? this.isDebugEnabled;
		const newStreamProps: IStreamFactoryNewOpts = { ...optsN, isDebugEnabled, streamOpts, url, session };

		return StreamFactory.newPlayStream(newStreamProps);
	}

	/**
	 * @returns A new user stream instance.
	 */
	protected newUserStream(opts?: Maybe<Partial<IStreamFactoryNewOpts>>): IUserDataStream {
		const optsN: Partial<IStreamFactoryNewOpts> = filterNullUndefined(opts ?? {});

		const url: string = optsN?.url || this._config.gameServerURI;
		const session: SessionProvider = optsN?.session ?? this.appliedUserSession();
		const streamOpts: Nullable<IStreamOpts> = optsN?.streamOpts ?? this._options?.userStream?.opts ?? null;
		const isDebugEnabled = optsN?.isDebugEnabled ?? streamOpts?.isDebugEnabled ?? this.isDebugEnabled;
		const newStreamProps: IStreamFactoryNewOpts = { ...optsN, isDebugEnabled, streamOpts, url, session };

		return StreamFactory.newUserStream(newStreamProps);
	}

	/**
	 * @returns A new device stream instance.
	 */
	protected newDeviceStream(opts?: Maybe<Partial<IStreamFactoryNewOpts>>): IDeviceDataStream {
		const optsN: Partial<IStreamFactoryNewOpts> = filterNullUndefined(opts ?? {});

		const url: string = optsN?.url || this._config.deviceServerURI;
		const session: SessionProvider = optsN?.session ?? this.appliedOmnibusSession();
		const streamOpts: Nullable<IStreamOpts> = optsN?.streamOpts ?? this._options?.deviceStream?.opts ?? null;
		const isDebugEnabled = optsN?.isDebugEnabled ?? streamOpts?.isDebugEnabled ?? this.isDebugEnabled;
		const newStreamProps: IStreamFactoryNewOpts = { ...optsN, isDebugEnabled, streamOpts, url, session };

		return StreamFactory.newDeviceStream(newStreamProps);
	}

	/* #endregion ---- Protected ------------------------------------------------------------------------------------- */

	/* #region ---- Static ------------------------------------------------------------------------------------------- */

	/**
	 * STATIC
	 * @returns The default options data used by this class.
	 */
	public static defaultOptions(): IStreamsOpts {
		return {
			...DebugBase.defaultOptions(),
			init: true,
			tableStream: { register: true, instance: null, opts: null },
			playStream: { register: true, instance: null, opts: null },
			userStream: { register: true, instance: null, opts: null },
			deviceStream: { register: true, instance: null, opts: null },
		};
	}

	/* #endregion ---- Static ---------------------------------------------------------------------------------------- */

	/* #region ---- Debug -------------------------------------------------------------------------------------------- */

	/**
	 * Overrides the parent class property.
	 *
	 * @returns The label to use when debugging.
	 */
	protected override get debugClassLabel(): string {
		return Streams.debugClassLabel();
	}

	/**
	 * STATIC
	 * @returns Label assigned to this class namespace.
	 */
	protected static debugClassLabel(): string {
		return `RpcLib.Sdk.Streams`;
	}

	/* #endregion ---- Debug ----------------------------------------------------------------------------------------- */
}

// ---- Exports -------------------------------------------------------------------------------------------------------

export { Streams as default };
export { Streams };
