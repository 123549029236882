import { Logger } from '../../helpers';

// TODO: Tie this to the WagerManager's debug logger?? Or at least the same prefix and enabled state.
const logError = (msg: string, method?: Maybe<string>, ...args: unknown[]) => {
	Logger.logError(msg, { method }, ...args);
};

const logWarn = (msg: string, method?: Maybe<string>, ...args: unknown[]) => {
	Logger.logWarn(msg, { method }, ...args);
};

const logInfo = (msg: string, method?: Maybe<string>, ...args: unknown[]) => {
	Logger.logInfo(msg, { method }, ...args);
};

const throwFatalError = (msg: string, method?: Maybe<string>, prefix?: Maybe<string>, ...args: unknown[]) => {
	let errorMsg = Logger.makeMessage(msg, method, prefix);

	if (args.length > 0) {
		const argsMsg = JSON.stringify(args);
		errorMsg = errorMsg + `\n${argsMsg}`;
	}

	throw new Error(errorMsg);
};

// ---- Exports -------------------------------------------------------------------------------------------------------

export { logError, logWarn, logInfo, throwFatalError };
