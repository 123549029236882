/**
 * Events that the `WalletManager` will trigger.
 */
enum Events {
	BALANCE_DATA_UPDATE = 'BALANCE_DATA_UPDATE',
	INSUFFICIENT_FUNDS = 'INSUFFICIENT_FUNDS',
	ERROR = 'ERROR',
	DEBUG = 'DEBUG',
}

/**
 * Dirty local balance data is considered to be stale after this number of milliseconds.
 */
const DIRTY_BALANCE_STALE_THRESHOLD_MS = 5000; // 5 SECS

// ---- Exports -------------------------------------------------------------------------------------------------------

export { Events };
export { DIRTY_BALANCE_STALE_THRESHOLD_MS };
