import { ConnectGrpcWebTransportOptions, ConnectTransport, ConnectTransportOptions } from '../../../bufbuild';
import { createConnectGrpcWebTransport, createConnectTransport } from '../../../bufbuild';
import { IConnectTransportOpts } from '../types';
import { ConnectTransportProtocol, DEFAULT_CONNECT_TRANSPORT_PROTOCOL } from './constants';

interface ICreateRpcTransportResult {
	protocol: ConnectTransportProtocol;
	transport: ConnectTransport;
	transportOpts: ConnectTransportOptions | ConnectGrpcWebTransportOptions;
}

const createRpcTransport = (
	baseUrl: string,
	opts?: Maybe<IConnectTransportOpts>,
	protocol?: Maybe<ConnectTransportProtocol>
): ICreateRpcTransportResult => {
	opts = opts ?? {};
	protocol = protocol || DEFAULT_CONNECT_TRANSPORT_PROTOCOL;

	const transportOpts = { ...(opts[protocol] ?? {}), baseUrl };
	let transport: ConnectTransport;

	switch (protocol) {
		case ConnectTransportProtocol.GRPC_WEB:
			transport = createConnectGrpcWebTransport(transportOpts);
			break;

		case ConnectTransportProtocol.CONNECT:
		default:
			transport = createConnectTransport(transportOpts);
			break;
	}

	return { transport, protocol, transportOpts };
};

// ---- Exports -------------------------------------------------------------------------------------------------------

export { createRpcTransport };
