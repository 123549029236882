import { action, AnnotationsMap, computed, makeObservable, observable } from 'mobx';
import { DebugBaseMobXAnnotations } from '../../../../common';
import { WalletServerBalancesMobXPrivateProps } from './types';
import WalletServerBalances from './WalletServerBalances';

const WalletServerBalancesMobXAnnotations: AnnotationsMap<WalletServerBalances, WalletServerBalancesMobXPrivateProps> =
	{
		// Inherit from DebugBase
		...DebugBaseMobXAnnotations,

		// ---- Observed -----------------------------------------------------

		// >> Properties
		_data: observable.struct,

		// ---- Computed -----------------------------------------------------

		// >> Public
		isEmpty: computed,
		size: computed,
		lookup: computed,
		list: computed,
		lastUpdatedTs: computed,
		data: computed,

		// ---- Actions ------------------------------------------------------

		// >> Public
		clear: action,

		// >> Protected
		setLastUpdatedTs: action,
		setData: action,

		// ---- Excluded -----------------------------------------------------

		// >> Properties
		_isMobXBound: false,

		// >> Public
		isMobXBound: false,
	};

/**
 * Makes the specified `WalletServerBalances` instance an observable MobX object.
 */
const bindWalletServerBalancesMobX = (instance: WalletServerBalances): boolean => {
	if (instance.isMobXBound) {
		return false;
	}

	makeObservable<WalletServerBalances, WalletServerBalancesMobXPrivateProps>(instance, {
		...WalletServerBalancesMobXAnnotations,
	});

	instance.isMobXBound = true;

	return true;
};

// ---- Exports -------------------------------------------------------------------------------------------------------

export { WalletServerBalancesMobXAnnotations, bindWalletServerBalancesMobX };
