/**********************************************************************************************************************
 * Stores and manages data for the current play.
 *
 * Note: This will be used as the base class for the same store inside of the game clients.
 *********************************************************************************************************************/
import { IDeviceGetDeviceMessagesReplyData, IDeviceInputsData } from '../../client/rpc';
import { IDeviceService } from '../../client/service/types';
import { IToJsOpts } from '../../helpers';
import { DataStore } from '../DataStore';
import { IDeviceStoreOpts } from './types';

class DeviceStore extends DataStore<IDeviceService, IDeviceGetDeviceMessagesReplyData> {
	/* #region ---- Properties --------------------------------------------------------------------------------------- */

	/**
	 * Currently assigned options.
	 */
	protected override _options: IDeviceStoreOpts = DeviceStore.defaultOptions();

	/* #endregion ---- Properties ------------------------------------------------------------------------------------ */

	/* #region ---- CONSTRUCTOR ---------------------------------------------------------------------------------------*/

	/**
	 * @param  service  Service associated with this store. Needed in order to call `populate`.
	 */
	constructor(service?: Maybe<IDeviceService>, opts?: Maybe<IDeviceStoreOpts>) {
		super(service);

		opts != null && this.setOptions(opts);
	}

	/* #endregion ---- CONSTRUCTOR ----------------------------------------------------------------------------------- */

	/* #region ---- Public ------------------------------------------------------------------------------------------ */

	/**
	 * Sets/initializes the class options.
	 *
	 * - Overrides the parent class method.
	 */
	public override setOptions(opts: IDeviceStoreOpts) {
		const { newOpts } = this.resolveOptions(opts);
		this._options = newOpts;
		this.onSetOptions(newOpts);
	}

	/**
	 * @returns The unique device ID.
	 */
	public get deviceId(): string {
		return this.data?.deviceId || '';
	}

	/**
	 * @returns The available device requests array.
	 */
	public get requests(): IDeviceInputsData[] {
		return this.data?.requests || [];
	}

	/**
	 * @returns The number of current device requests.
	 */
	public get numRequests(): number {
		return this.requests.length;
	}

	/**
	 * @returns The first device request or NULL if not available.
	 */
	public get firstRequest(): Nullable<IDeviceInputsData> {
		return this.requests.length ? this.requests[0] : null;
	}

	/**
	 * Overrides the parent class method.
	 *
	 * @returns A JSON export of the current pertinent data.
	 */
	public override toJson(extended?: Maybe<boolean>): PlainObject {
		extended = extended ?? false;

		const toJs = (val: unknown, opts?: Maybe<IToJsOpts>) => this.toJs(val, extended, { ...opts });

		const result: PlainObject = {
			// Everything from `DataStore`
			...super.toJson(extended),
			deviceId: this.deviceId,
			requests: toJs(this.requests),
			numRequests: this.numRequests,
			firstRequest: toJs(this.firstRequest),
		};

		if (extended) {
			const baseExtended = (result.extended as PlainObject) ?? {};

			result.extended = {
				...baseExtended,
			};
		}

		return result;
	}

	/**
	 * ACTION
	 * Populates the store using the specified reply data.
	 */
	public populateFromTableReply(data: IDeviceGetDeviceMessagesReplyData) {
		this.setData(data);
	}

	/**
	 * ACTION
	 * Populates the store (via unary RPC service call) using the specified play ID.
	 */
	public override async populate(deviceId: string): Promise<boolean> {
		if (deviceId.length <= 0) {
			this.error('A valid device id must be specified', 'populate');
		}

		return super.populate(deviceId);
	}

	/* #endregion ---- Public --------------------------------------------------------------------------------------- */

	/* #region ---- Protected --------------------------------------------------------------------------------------- */

	/**
	 * Gets data from the associated service in order to populate the store.
	 *
	 * @returns The underlying data needed to populate this store.
	 */
	protected async fetchPopulateData(deviceId: string): Promise<Nullable<IDeviceGetDeviceMessagesReplyData>> {
		if (!this._service) {
			this.error('No service was specified', 'fetchPopulateData');
			return null;
		}

		if (deviceId.length <= 0) {
			this.error('A valid play id must be specified', 'fetchPopulateData');
		}

		const reply = (await this._service.getDeviceMessages(deviceId).promise) ?? null;

		return reply != null ? (reply as IDeviceGetDeviceMessagesReplyData) : null;
	}

	/**
	 * Resolves the options being passed in and returns the original and new options.
	 *
	 * - Overrides the parent class method.
	 */
	protected override resolveOptions(opts?: Maybe<IDeviceStoreOpts>) {
		const origOpts: IDeviceStoreOpts = {
			...DataStore.defaultOptions(),
			...this._options,
		};

		const newOpts: IDeviceStoreOpts = {
			...origOpts,
			...(opts ?? {}),
		};

		return { origOpts, newOpts };
	}

	/* #endregion ---- Protected ------------------------------------------------------------------------------------- */

	/* #region ---- Static ------------------------------------------------------------------------------------------- */

	/**
	 * STATIC
	 * @returns The default options data used by this class.
	 */
	public static defaultOptions(): IDeviceStoreOpts {
		return {
			...DataStore.defaultOptions(),
		};
	}

	/* #endregion ---- Static ---------------------------------------------------------------------------------------- */

	/* #region ---- Debug -------------------------------------------------------------------------------------------- */

	/**
	 * Overrides the base class method.
	 *
	 * @returns The label to use when debugging.
	 */
	protected override get debugClassLabel(): string {
		return DeviceStore.debugClassLabel();
	}

	/**
	 * STATIC
	 * @returns Label assigned to this class namespace.
	 */
	protected static debugClassLabel(): string {
		return `RpcLib.Store.DeviceStore`;
	}

	/* #endregion ---- Debug ----------------------------------------------------------------------------------------- */
}

// ---- Exports -------------------------------------------------------------------------------------------------------

export { DeviceStore as default };
export { DeviceStore };
