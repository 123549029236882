import memoize from 'memoize-one';
import { DEFAULT_LANGUAGE } from './constants';
import { IBrowserLocale, INavigator } from './types';

/**
 * Parse the specified language string and returns a tuple containing the language code (eg. 'en') and the
 * region (eg. 'US').
 *
 * For example:
 * 	Language 'en-UK' becomes ['en', 'UK']
 *
 * @param   language   Language string
 * @param   delimiter  Delimiter. Defaults to '-'.
 * @returns Array: [code, region]
 */
const parseLanguageCodeAndRegion = (language: string, delimiter: string = '-'): [string, Nullable<string>] => {
	let code: string = language;
	let region: Nullable<string> = null;

	if (!language.includes(delimiter)) {
		return [code, region];
	}

	const parts = language.split(delimiter);
	code = parts.shift() ?? language;
	region = parts.join(delimiter) || null;

	return [code, region];
};

/**
 * @returns The system language according to the browser.
 */
const getSystemLanguageString = (): Nullable<string> => {
	const nav: INavigator = globalThis.navigator ?? {};

	return nav.systemLanguage || getDefaultFormatter().resolvedOptions().locale || null;
};

/**
 * @returns The browser's preferred language string (eg. 'en-US').
 */
const getBrowserPreferredLanguage = (): string => {
	const nav: INavigator = globalThis.navigator ?? {};
	const languages = nav.languages || [];

	const preferred = (): Nullable<string> => (languages.length && languages[0]) || nav.language || null;
	const legacy = (): Nullable<string> => nav.userLanguage || nav.browserLanguage || null;
	const fallback = (): string => getSystemLanguageString() || DEFAULT_LANGUAGE;

	return preferred() || legacy() || fallback();
};

/**
 * @returns The browser's current locale settings based on the preferred browser language.
 */
const getBrowserLocale = memoize((): IBrowserLocale => {
	const language = getBrowserPreferredLanguage();

	let code: string = language;
	let region: Nullable<string> = null;

	if (language.length > 2) {
		[code, region] = parseLanguageCodeAndRegion(language);
	}

	return {
		locale: language,
		languageCode: code,
		regionCode: region,
	};
});

/**
 * @returns The browser's current locale string.
 */
const getBrowserLocaleString = (): string => {
	return getBrowserLocale().locale;
};

/**
 * @returns The default number formatter.
 */
const getDefaultFormatter = memoize(() => {
	return Intl.NumberFormat();
});

/**
 * @returns A new number formatter instance for the specified locale string and options.
 */
const createLocaleFormatter = memoize((locale?: string, opts?: Intl.NumberFormatOptions) => {
	locale = locale || getBrowserLocale().locale;

	return new Intl.NumberFormat(locale, opts);
});

// ---- Exports -------------------------------------------------------------------------------------------------------

export { getBrowserLocale, getBrowserLocaleString, getBrowserPreferredLanguage, createLocaleFormatter };
