import intersect from 'lodash/intersection';
import { filterNullUndefined, filterUndefined, isObjectEmpty } from '../../helpers';
import { IMethodResolveAmountsOpts, resolveAmount } from '../../helpers/amounts';
import {
	IAvailableWagers,
	IPlayWagerDefinitions,
	IPlayWagerDefinitionsDataEntry,
	newEmptyActiveWagerDataExt,
} from '../../helpers/data';
import { makeActiveWagerKey } from '../../helpers/data/utility';
import {
	IMultiSeatWagers,
	IMultiSeatWagerSet,
	IMultiSeatWagerSetProps,
	ISeatWagerSet,
	ISeatWagerSetProps,
	IWagerData,
	IWagerDataProps,
	IWagers,
	IWagerSet,
	IWagerSetProps,
} from '../types';
import { IWagerManifest, IWagerManifestWagerEntry } from './types';

/* #region ---- Defaults ------------------------------------------------------------------------------------------- */

/**
 * @returns New empty wager data.
 */
const defaultWagerData = (): IWagerData => ({
	...newEmptyActiveWagerDataExt(),
	raw: null,
	avail: null,
	def: null,
	name: '',
	lastUpdatedTs: 0,
	isLocal: false,
	isDirty: false,
	isQueued: false,
	lastQueuedTs: 0,
	lastSubmittedTs: 0,
});

/**
 * @returns New empty wagers.
 */
const defaultWagers = (): IWagers => ({});

/**
 * @returns New empty wager set with empty wagers.
 */
const defaultWagerSet = (): IWagerSet => ({
	// TODO: Consider adding `totals` here.
	wagers: defaultWagers(),
	wagerCount: 0,
});

/**
 * @returns New empty seat wager set with empty wagers.
 */
const defaultSeatWagerSet = (): ISeatWagerSet => ({
	...defaultWagerSet(),
	seatNumber: 0,
});

/**
 * @returns New empty multi-seat wagers.
 */
const defaultMultiSeatWagers = (): IMultiSeatWagers => ({});

/**
 * @returns New multi-seat wager set with empty seat numbers and empty multi-seat wagers.
 */
const defaultMultiSeatWagerSet = (): IMultiSeatWagerSet => ({
	// TODO: Consider adding `totals` here.
	seatNumbers: [],
	seatWagers: defaultMultiSeatWagers(),
	wagerCount: 0,
});

/* #endregion ---- Defaults  --------------------------------------------------------------------------------------- */

/* #region ---- Factory -------------------------------------------------------------------------------------------- */

/**
 * @returns New wager data from the given props and source.
 */
const newWagerData = (
	props?: Maybe<IWagerDataProps>,
	opts?: Maybe<{
		resolveAmountsOpts?: Maybe<IMethodResolveAmountsOpts>;
		srcData?: Maybe<IWagerData>;
	}>
): IWagerData => {
	const srcData = { ...defaultWagerData(), ...(opts?.srcData ?? {}) };

	const newProps: IWagerDataProps = filterUndefined(props ?? {});

	const result: IWagerData = { ...srcData, ...newProps };
	result.createdTs = newProps.createdTs ?? result.createdTs;

	result.activeWagerKey = makeActiveWagerKey(result.wagerId, {
		seatNumber: result.seatNumber,
		contextId: result.contextId,
		categoryMemberKey: result.categoryMemberKey,
	});

	const resolveAmountsOpts: IMethodResolveAmountsOpts = {
		...opts?.resolveAmountsOpts,
		currencyCode: result.currencyCode,

		// TODO: The wager data should have currencyExponent added to it on the server.
		// currencyExponent: result.currencyExponent,
	};

	const resolve = resolveAmount(result.amount, resolveAmountsOpts);

	result.amount = resolve.amount;
	result.amountReal = resolve.amountReal;
	result.amountMoney = resolve.amountMoney;
	result.currencyCode = resolve.currencyCode;
	// result.currencyExponent = currencyExponent;
	result.currencySymbol = resolve.currencySymbol;

	return result;
};

/**
 * @returns New wagers collection from the given props and source.
 */
const newWagers = (props?: Maybe<IWagers>, filterNull?: Maybe<boolean>, from?: Maybe<IWagers>): IWagers => {
	filterNull = filterNull ?? false;
	from = { ...(from ? filterUndefined(from) : {}) };
	props = filterUndefined(props ?? {});

	const result: IWagers = { ...defaultWagers(), ...from, ...props };

	return filterNull ? filterNullUndefined(result) : result;
};

/**
 * @returns New wager set from the given props and source.
 */
const newWagerSet = (props?: Maybe<IWagerSetProps>, from?: Maybe<IWagerSet>): IWagerSet => {
	from = { ...defaultWagerSet(), ...(from ?? {}) };
	props = filterUndefined(props ?? {});

	const result: IWagerSet = { ...from, ...props };
	result.wagerCount = Object.keys(result.wagers).length;

	return result;
};

/**
 * @returns New seat wager set from the given props and source.
 */
const newSeatWagerSet = (props?: Maybe<ISeatWagerSetProps>, from?: Maybe<ISeatWagerSet>): ISeatWagerSet => {
	from = { ...defaultSeatWagerSet(), ...(from ?? {}) };
	props = filterUndefined(props ?? {});

	const result: ISeatWagerSet = { ...from, ...props };
	result.wagerCount = Object.keys(result.wagers).length;

	return result;
};

/**
 * @returns New multi-seat wagers collection from the given props and source.
 */
const newMultiSeatWagers = (
	props?: Maybe<IMultiSeatWagers>,
	filterNull?: Maybe<boolean>,
	from?: Maybe<IMultiSeatWagers>
): IMultiSeatWagers => {
	filterNull = filterNull ?? false;
	from = { ...(from ? filterUndefined(from) : {}) };
	props = filterUndefined(props ?? {});

	const result: IMultiSeatWagers = { ...defaultMultiSeatWagers(), ...from, ...props };

	return filterNull ? filterNullUndefined(result) : result;
};

/**
 * @returns New multi-seat wager set from the given props and source.
 */
const newMultiSeatWagerSet = (
	props?: Maybe<IMultiSeatWagerSetProps>,
	from?: Maybe<IMultiSeatWagerSet>
): IMultiSeatWagerSet => {
	from = { ...defaultMultiSeatWagerSet(), ...(from ?? {}) };
	props = filterUndefined(props ?? {});

	const result: IMultiSeatWagerSet = { ...from, ...props };

	result.wagerCount = getMultiSeatWagersCount(result.seatWagers ?? {});
	result.seatNumbers = result.wagerCount === 0 ? [] : getMultiSeatWagersNums(result.seatWagers ?? {});

	return result;
};

/* #endregion ---- Factory (Wager Data) ---------------------------------------------------------------------------- */

/* #region ---- Fill ----------------------------------------------------------------------------------------------- */

const fillNewMultiSeatWagers = (seatNumbers: number[], value: Nullable<ISeatWagerSet>): IMultiSeatWagers => {
	value = value ?? null;

	const result: IMultiSeatWagers = newMultiSeatWagers();

	for (const seatNumber of seatNumbers) {
		result[seatNumber] = value;
	}

	return result;
};

const fillNewMultiSeatWagerSet = (seatNumbers: number[], value: Nullable<ISeatWagerSet>): IMultiSeatWagerSet => {
	value = value ?? null;

	const seatWagers: IMultiSeatWagers = fillNewMultiSeatWagers(seatNumbers, value);

	return newMultiSeatWagerSet({ seatWagers });
};

const fillNewSeatLookup = <V>(seatNumbers: number[], value?: Maybe<V>): Map<number, V> => {
	const result = new Map<number, V>();

	for (const seatNumber of seatNumbers) {
		result[seatNumber] = value ?? null;
	}

	return result;
};

const fillNewKeyLookup = <V>(keys: string[], value?: Maybe<V>): Map<string, V> => {
	const result = new Map<string, V>();

	for (const key of keys) {
		if (key === '') {
			continue;
		}
		result[key] = value ?? null;
	}

	return result;
};

/* #endregion ---- Fill -------------------------------------------------------------------------------------------- */

/* #region ---- Extract --------------------------------------------------------------------------------------------- */

const getMultiSeatWagersNums = (val: IMultiSeatWagers): number[] => {
	const seatNumKeys: string[] = Object.keys(val);

	return seatNumKeys.map(Number).sort((a, b) => a - b);
};

const getMultiSeatWagersPopulatedSeatNums = (val: IMultiSeatWagers): number[] => {
	const seatNumKeys: string[] = Object.keys(val);

	const result: number[] = [];

	seatNumKeys.forEach((seatNumKey) => {
		const seatWagerSet: Nullable<ISeatWagerSet> = val[seatNumKey] ?? null;
		if (seatWagerSet != null) {
			result.push(Number(seatNumKey));
		}
	});

	return result;
};

const getMultiSeatWagersCount = (val: IMultiSeatWagers): number => {
	const seatNumKeys: string[] = Object.keys(val);

	return seatNumKeys.reduce((total, seatNumKey) => {
		const seatWagerSet: ISeatWagerSet = val[seatNumKey] ?? defaultSeatWagerSet();
		return total + seatWagerSet.wagerCount;
	}, 0);
};

const flattenMultiSeatWagerSet = (msws: IMultiSeatWagerSet, filterSeatNumbers?: Maybe<number[]>): IWagerData[] => {
	const filterSeatNums: number[] = filterSeatNumbers ?? [];
	if (filterSeatNums.length > 0 && intersect(msws.seatNumbers, filterSeatNums).length === 0) {
		return [];
	}

	return flattenMultiSeatWagers(msws.seatWagers, filterSeatNumbers);
};

const flattenMultiSeatWagers = (msw: IMultiSeatWagers, filterSeatNumbers?: Maybe<number[]>): IWagerData[] => {
	const filterSeatNums: number[] = filterSeatNumbers ?? [];
	if (isObjectEmpty(msw)) {
		return [];
	}

	let result: IWagerData[] = [];

	Object.entries(msw).forEach(([seatNumStr, seatWagerSet]) => {
		const seatNum = Number(seatNumStr);
		if (filterSeatNums.length > 0 && !filterSeatNums.includes(seatNum)) {
			return;
		}

		const wagers: IWagers = seatWagerSet?.wagers ?? {};
		result = result.concat(flattenWagers(wagers));
	});

	result.sort((a, b) => a.seatNumber - b.seatNumber);

	return result;
};

const flattenWagers = (wagers: IWagers): IWagerData[] => {
	if (isObjectEmpty(wagers)) {
		return [];
	}

	const result: IWagerData[] = [];

	Object.entries(wagers).forEach(([_, wager]) => {
		if (wager == null) {
			return;
		}
		result.push({ ...wager });
	});

	return result;
};

/**
 * @returns A default wager manifest.
 */
const defaultWagerManifest = (): IWagerManifest => {
	return {
		wagerCount: 0,
		wagersLookup: {},
		wagersList: [],
		wagerNames: [],
		availableWagersCount: 0,
		availableWagersLookup: {},
		availableWagersList: [],
		availableWagersNames: [],
	};
};

/**
 * @returns A wagers manifest from the given wager definitions and available wagers.
 */
const getWagersManifest = (
	wagerDefinitions: IPlayWagerDefinitions,
	availableWagers: IAvailableWagers
	// localWagers: ILocalWagers
): IWagerManifest => {
	const definitionsList = wagerDefinitions.list;
	if (definitionsList.length === 0) {
		return defaultWagerManifest();
	}

	const result: IWagerManifest = defaultWagerManifest();

	definitionsList.forEach((def: IPlayWagerDefinitionsDataEntry) => {
		const wagerId = def.wagerId;
		def.raw = null;

		const base: IWagerManifestWagerEntry = {
			data: {
				definition: def,
				availableWager: null,
			},
			availableWagerKey: '',
			categoryMemberKey: '',
			description: def.description,
			isAvailable: false,
			isCategoryWager: def.isCategory ?? false,
			isRequired: def.isRequired,
			max: { amount: def.maxAmount, amountReal: def.maxAmountReal, amountMoney: def.maxAmountMoney },
			min: { amount: def.minAmount, amountReal: def.maxAmountReal, amountMoney: def.minAmountMoney },
			name: def.name,
			seatNumber: 0,
			wagerId,
		};

		const { list: availsList } = availableWagers.lookupByWagerId(wagerId);

		const fnAddEntryToResult = (entry: IWagerManifestWagerEntry) => {
			const wagerName = entry.name;
			result.wagersLookup[wagerName] = entry;
			result.wagersList.push(entry);
			result.wagerCount += 1;
			result.wagerNames.push(wagerName);

			if (entry.isAvailable) {
				result.availableWagersLookup[wagerName] = entry;
				result.availableWagersList.push(entry);
				result.availableWagersCount += 1;
				result.availableWagersNames.push(wagerName);
			}
		};

		if (availsList.length === 0) {
			const entry = { ...base };
			fnAddEntryToResult(entry);
		} else {
			availsList.forEach((avail) => {
				const entry = { ...base };
				entry.availableWagerKey = avail.availableWagerKey;
				entry.categoryMemberKey = avail.categoryMemberKey;
				entry.data.availableWager = avail;
				entry.isAvailable = true;
				entry.isCategoryWager = avail.isCategoryWager;
				entry.name = avail.name;
				entry.seatNumber = avail.seatNumber;

				fnAddEntryToResult(entry);
			});
		}
	});

	return result;
};

/* #endregion ---- Extract ----------------------------------------------------------------------------------------- */

// ---- Exports -------------------------------------------------------------------------------------------------------

export { newWagerData, newWagers, newWagerSet, newSeatWagerSet, newMultiSeatWagers, newMultiSeatWagerSet };
export { fillNewSeatLookup, fillNewKeyLookup, fillNewMultiSeatWagerSet };
export {
	defaultWagerData,
	defaultMultiSeatWagerSet,
	defaultMultiSeatWagers,
	defaultSeatWagerSet,
	defaultWagerSet,
	defaultWagers,
};
export { getMultiSeatWagersNums, getMultiSeatWagersCount, getMultiSeatWagersPopulatedSeatNums };
export { flattenMultiSeatWagerSet, flattenMultiSeatWagers };
export { getWagersManifest };
