import { makeUniqId } from '../../../../helpers';
import { IWagerUndoStackData } from './types';

/**
 * @returns The default data used by the `WagerUndoStack` class.
 */
const defaultData = (opts?: Maybe<{ updatedTs?: Maybe<number> }>): IWagerUndoStackData => {
	return {
		lastUpdatedTs: opts?.updatedTs ?? 0,
		uniqId: makeUniqId(),
		stack: [],
	};
};

/**
 * @returns A copy of the specified data.
 */
const copyData = (data: IWagerUndoStackData, opts?: Maybe<{ updatedTs?: Maybe<number> }>): IWagerUndoStackData => {
	const newData: IWagerUndoStackData = defaultData();
	newData.lastUpdatedTs = opts?.updatedTs ?? data.lastUpdatedTs;
	newData.stack = data.stack.slice();

	return newData;
};

// ---- Exports -------------------------------------------------------------------------------------------------------

export { defaultData, copyData };
