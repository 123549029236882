import { IEventManagerOpts } from './types';

const defaultOptions = (): IEventManagerOpts => ({
	isDebugEnabled: false,
	debugLabel: '',
});

// ---- Exports -------------------------------------------------------------------------------------------------------

export { defaultOptions };
