import omit from 'lodash/omit';
import { filterNullUndefined } from '../../helpers/shared';
import { IChoicePeriodData, IChoicePeriodDataExt } from './types/choicePeriodExt';

/**
 * @returns An empty `IChoicePeriodData` object with default values.
 */
const newEmptyChoicePeriodData = (): IChoicePeriodData => ({
	choicePeriodId: '',
	name: '',
	closed: false,
	availabilityWindow: BigInt(0),
	remainingTime: BigInt(0),
	meta: {},
});

/**
 * @returns An empty `IChoicePeriodDataExt` object with default values.
 */
const newEmptyChoicePeriodDataExt = (): IChoicePeriodDataExt => ({
	raw: newEmptyChoicePeriodData(),
	id: '',
	name: '',
	isClosed: false,
	totalSeconds: 0,
	remainingSeconds: 0,
	meta: {},
});

/**
 * @returns Extended version of `IChoicePeriodData` with re-mapped types, props.
 */
const extendChoicePeriodData = (src: IChoicePeriodData): IChoicePeriodDataExt => {
	const base = omit(src, ['choicePeriodId', 'closed', 'availabilityWindow', 'remainingTime', 'meta']);

	return {
		...newEmptyChoicePeriodDataExt(),
		...filterNullUndefined(base),
		raw: { ...src },
		id: src.choicePeriodId,
		isClosed: src.closed,
		totalSeconds: Number(src.availabilityWindow),
		remainingSeconds: Number(src.remainingTime) / 1000,
		meta: src.meta as Dict<string>,
	};
};

/**
 * @returns Extended version of `IChoicePeriodData[]` with re-mapped types, props.
 */
const extendChoicePeriodDataList = (srcList: IChoicePeriodData[]): IChoicePeriodDataExt[] => {
	const result: IChoicePeriodDataExt[] = [];

	for (const src of srcList) {
		result.push(extendChoicePeriodData(src));
	}

	return result;
};

// ---- Export --------------------------------------------------------------------------------------------------------

export { extendChoicePeriodData, extendChoicePeriodDataList };
export { newEmptyChoicePeriodData, newEmptyChoicePeriodDataExt };

// Other
export * from './types/choicePeriodExt';
