import { AuthSessionProvider, IAuthSessionProvider } from '../../../client/core/AuthSession';

const newUserSession = (token?: Maybe<string>): IAuthSessionProvider => {
	const session = new AuthSessionProvider(null, { useStorage: true });
	token != null && (session.token = token);

	return session;
};

// ---- Export --------------------------------------------------------------------------------------------------------

export { newUserSession };
