import { set } from 'mobx';
import { DebugBase } from '../../../../common';
import { IAvailableWagerDataExt } from '../../availableWagersExt';
import { IPlaySeatDataExt } from '../../playSeatExt';
import { IPlayWagerDefinitionDataExt } from '../../playWagerDefinitionExt';
import { bindActiveWagersMobX } from './mobx';
import {
	ActiveWagerDataList,
	ActiveWagerDataLookup,
	IActiveWagerDataEntry,
	IActiveWagersData,
	IActiveWagersOpts,
	IMethodActiveWagersMapRawDataOpts,
	RawActiveWagerList,
} from './types';
import { copyData, defaultData, generateRawListHashId, newDataFromRawList } from './utility';

class ActiveWagers extends DebugBase {
	/* #region ---- Properties --------------------------------------------------------------------------------------- */

	/**
	 * Currently assigned options.
	 */
	protected override _options: IActiveWagersOpts = ActiveWagers.defaultOptions();

	/**
	 * Encapsulated data.
	 */
	protected _data: IActiveWagersData;

	/**
	 * Flag indicating if this class instance is currently bound to MobX as an observable.
	 */
	protected _isMobXBound: boolean = false;

	/* #endregion ---- Properties -------------------------------------------------------------------------------------*/

	/* #region ---- CONSTRUCTOR ---------------------------------------------------------------------------------------*/

	constructor(opts?: Maybe<IActiveWagersOpts>) {
		super();

		this._data = opts?.data ?? defaultData();
		opts != null && this.setOptions(opts);

		// Bind to MobX as an observable.
		if (this._options.useMobX) {
			bindActiveWagersMobX(this);
		}
	}

	/* #endregion ---- CONSTRUCTOR ----------------------------------------------------------------------------------- */

	/* #region ---- Public ------------------------------------------------------------------------------------------- */

	/**
	 * Sets/initializes the class options.
	 *
	 * - Overrides the parent class method.
	 */
	public override setOptions(opts: IActiveWagersOpts) {
		const { newOpts, origOpts } = this.resolveOptions(opts);

		if (this._isMobXBound) {
			set(this, '_options', newOpts);
		} else {
			this._options = newOpts;
		}

		this.onSetOptions(newOpts, origOpts);
	}

	/**
	 * Whether or not the data collection is empty.
	 */
	public get isEmpty(): boolean {
		return this.size === 0;
	}

	/**
	 * Size of the data collection.
	 */
	public get size(): number {
		return this.lookup.size;
	}

	/**
	 * Lookup of active wagers data keyed by active wager key.
	 */
	public get lookup(): ActiveWagerDataLookup {
		return this._data.lookup;
	}

	/**
	 * Array of active wagers data.
	 */
	public get list(): ActiveWagerDataList {
		return Array.from(this.lookup.values());
	}

	/**
	 * @returns All defined unique keys (ie. activeWagerKey) in the data collection.
	 */
	public get keys(): string[] {
		return Array.from(this.lookup.keys());
	}

	/**
	 * The unique hash ID for the data collection.
	 */
	public get dataHashId(): string {
		return this._data?.hashId ?? '';
	}

	/**
	 * The full data encapsulated by this class instance.
	 */
	public get data(): IActiveWagersData {
		return this._data;
	}
	public set data(val: IActiveWagersData) {
		this.setData(val);
	}
	// Actionable setter method for MobX.
	protected setData(val: IActiveWagersData) {
		if (val === this._data) {
			return;
		}

		if (this.isMobXBound) {
			set(this, '_data', val);
		} else {
			this._data = val;
		}
	}

	/**
	 * The last time (unix timestamp) that the data was updated.
	 */
	public get lastUpdatedTs(): number {
		return this._data.lastUpdatedTs;
	}
	public set lastUpdatedTs(val: number) {
		this.setLastUpdatedTs(val);
	}
	// Actionable setter method for MobX.
	protected setLastUpdatedTs(val: number) {
		if (val === this._data.lastUpdatedTs) {
			return;
		}
		this._data.lastUpdatedTs = val;
	}

	/**
	 * Get/set the active play ID.
	 */
	public get playId(): string {
		return this._data.playId;
	}
	public set playId(val: string) {
		this.setPlayId(val);
	}
	// Actionable setter method for MobX.
	protected setPlayId(val: string) {
		if (val === this._data.playId) {
			return;
		}

		const prev = this._data.playId;
		this._data.playId = val;
		this.onPlayIdChanged(this._data.playId, prev);
	}

	/**
	 * Gets/sets whether or not this class instance is currently bound to MobX as an observable.
	 */
	public get isMobXBound(): boolean {
		return this._isMobXBound;
	}
	public set isMobXBound(value: boolean) {
		this._isMobXBound = value;
	}

	/**
	 * @returns The data entry for the specified active wager key, or NULL if not present
	 */
	public get(activeWagerKey: string, opts?: Maybe<{ copy?: Maybe<boolean> }>): Nullable<IActiveWagerDataEntry> {
		const entry = this.lookup.get(activeWagerKey) ?? null;

		if (opts?.copy === true && entry != null) {
			return { ...entry };
		}

		return entry;
	}

	/**
	 * @returns TRUE if a data entry for specified active wager key exists.
	 */
	public has(activeWagerKey: string): boolean {
		return this.lookup.has(activeWagerKey);
	}

	/**
	 * Resets the class data back to the initial/clear values.
	 */
	public clear() {
		const data = defaultData({ updatedTs: 0, playId: this.playId });
		this.setData(data);
	}

	/**
	 * @returns A new clone of this class instance with copied data.
	 */
	public clone(opts?: Maybe<{ updatedTs?: Maybe<number> }>): ActiveWagers {
		const newData = copyData(this._data, { updatedTs: opts?.updatedTs });

		return new ActiveWagers({ ...this._options, data: newData, updatedTs: newData.lastUpdatedTs });
	}

	/**
	 * Extends the parent class method.
	 *
	 * @returns A JSON export of the current data.
	 */
	public override toJson(extended?: Maybe<boolean>): PlainObject {
		extended = extended ?? false;

		const toJs = (val: unknown) => DebugBase.toJs(val, { extended, useMobXToJs: this.isMobXBound });

		const data = copyData(this._data);
		const list = Array.from(data.lookup.values());
		const keys = Array.from(data.lookup.keys());

		const result: PlainObject = {
			lastUpdatedTs: data.lastUpdatedTs,
			size: data.lookup.size,
			isEmpty: data.lookup.size === 0,
			playId: data.playId,
			list: toJs(list),
			lookup: toJs(data.lookup),
			activeWagerKeys: toJs(keys),
		};

		if (extended) {
			result.extended = {
				isMobXBound: this.isMobXBound,
				options: toJs({ ...this._options }),
				rawData: toJs(data),

				// Debug info from base class
				_Debug: { ...super.toJson(extended) },
			};
		}

		return result;
	}

	/**
	 * ACTION
	 * Populates this instance using the raw active wagers list.
	 */
	public populate(rawList: RawActiveWagerList, opts?: Maybe<IMethodActiveWagersMapRawDataOpts>): IActiveWagersData {
		const data = newDataFromRawList(rawList, this.playId, opts);
		this.setData(data);

		return this._data;
	}

	/**
	 * @returns TRUE if the specified raw list is the same as the current one - in terms of the meaningful data.
	 */
	public isRawDataSame(wagerList: RawActiveWagerList): boolean {
		const origHashId = this._data.raw?.hashId ?? '';
		const newHashId = generateRawListHashId(wagerList);

		return newHashId === origHashId;
	}

	/* #endregion ---- Public ---------------------------------------------------------------------------------------- */

	/* #region ---- Protected ---------------------------------------------------------------------------------------- */

	/**
	 * Resolves the options being passed in and returns the original and new options.
	 *
	 * - Overrides the parent class method
	 */
	protected override resolveOptions(opts?: Maybe<IActiveWagersOpts>) {
		const origOpts: IActiveWagersOpts = {
			...ActiveWagers.defaultOptions(),
			...this._options,
		};

		const newOpts: IActiveWagersOpts = {
			...origOpts,
			...(opts ?? {}),
		};

		return { origOpts, newOpts };
	}

	/**
	 * Called after new options are set.
	 *
	 * - Extends the parent class method
	 */
	protected override onSetOptions(newOpts: IActiveWagersOpts, origOpts: Maybe<IActiveWagersOpts>) {
		super.onSetOptions(newOpts, origOpts);

		if (newOpts.data != null && (!this._data || newOpts.data.uniqId !== this._data.uniqId)) {
			this.setData(newOpts.data);
		}

		if (!this._data) {
			return;
		}
		if (newOpts.updatedTs != null && newOpts.updatedTs !== this._data.lastUpdatedTs) {
			this.setLastUpdatedTs(newOpts.updatedTs);
		}
		if (newOpts.playId != null && newOpts.playId !== this._data.playId) {
			this.setPlayId(newOpts.playId);
		}
	}

	/**
	 * Called when the play ID changes.
	 */
	protected onPlayIdChanged(newPlayId: string, prevPlayId: string) {
		if (prevPlayId === '') {
			return;
		}

		if (this.size > 0) {
			this.warn('Server wagers data will be cleared due to the active play being changed.', 'onPlayIdChanged', {
				prevPlayId,
				newPlayId,
			});

			this.clear();
		}
	}

	/* #endregion ---- Protected ------------------------------------------------------------------------------------- */

	/* #region ---- Static ------------------------------------------------------------------------------------------- */

	/**
	 * STATIC
	 * @returns A clone of the specified class instance.
	 */
	public static cloneInstance(from: ActiveWagers, opts?: Maybe<{ updatedTs?: Maybe<number> }>): ActiveWagers {
		return from.clone(opts);
	}

	/**
	 * STATIC
	 * @returns The default options data used by this class.
	 */
	public static defaultOptions(): IActiveWagersOpts {
		return {
			...DebugBase.defaultOptions(),
			updatedTs: null,
			useMobX: true,
		};
	}

	/**
	 * STATIC
	 * @returns The default data encapsulated this class.
	 */
	public static defaultData(opts?: Maybe<{ updatedTs?: Maybe<number>; playId?: Maybe<string> }>): IActiveWagersData {
		return defaultData(opts);
	}

	/**
	 * STATIC
	 * @returns The default data encapsulated this class.
	 */
	public static copyData(data: IActiveWagersData, opts?: Maybe<{ updatedTs?: Maybe<number> }>): IActiveWagersData {
		return copyData(data, opts);
	}

	/**
	 * STATIC
	 * @returns A new instance of this class populated with the specified raw active wagers list.
	 */
	public static newFromRawList(
		rawList: RawActiveWagerList,
		playId: string,
		opts?: Maybe<{
			availableWagersList?: Maybe<IAvailableWagerDataExt[]>;
			wagerDefinitionsList?: Maybe<IPlayWagerDefinitionDataExt[]>;
			playSeatAssignmentsList?: Maybe<IPlaySeatDataExt[]>;
			newInstanceOpts?: Maybe<Omit<IActiveWagersOpts, 'data' | 'playId' | 'updatedTs'>>;
			populateOpts?: Maybe<
				Omit<
					IMethodActiveWagersMapRawDataOpts,
					'availableWagersList' | 'wagerDefinitionsList' | 'playSeatAssignmentsList'
				>
			>;
		}>
	): ActiveWagers {
		const populateOpts: IMethodActiveWagersMapRawDataOpts = {
			...opts?.populateOpts,
			availableWagersList: opts?.availableWagersList,
			wagerDefinitionsList: opts?.wagerDefinitionsList,
			playSeatAssignmentsList: opts?.playSeatAssignmentsList,
		};

		const data = newDataFromRawList(rawList, playId, populateOpts);

		return new ActiveWagers({ ...opts?.newInstanceOpts, data, updatedTs: data.lastUpdatedTs });
	}

	/**
	 * STATIC
	 * Maps the raw data list sent by the server and resolves to the relevant encapsulated data.
	 */
	public static newDataFromRawList(
		rawList: RawActiveWagerList,
		playId: string,
		opts?: Maybe<IMethodActiveWagersMapRawDataOpts>
	): IActiveWagersData {
		return newDataFromRawList(rawList, playId, opts);
	}

	/* #endregion ---- Static ---------------------------------------------------------------------------------------- */

	/* #region ---- Debug -------------------------------------------------------------------------------------------- */

	/**
	 * Overrides the parent class property.
	 *
	 * @returns The label to use when debugging.
	 */
	protected override get debugClassLabel(): string {
		return ActiveWagers.debugClassLabel();
	}

	/**
	 * STATIC
	 * @returns Label assigned to this class namespace.
	 */
	protected static debugClassLabel(): string {
		return `RpcLib.DataObject.ActiveWagers`;
	}

	/* #endregion ---- Debug ----------------------------------------------------------------------------------------- */
}

// ---- Exports -------------------------------------------------------------------------------------------------------

export { ActiveWagers as default };
export { ActiveWagers };
