/**
 * Default undo stack max size.
 */
const DEFAULT_STACK_MAX_SIZE: number = 100;

/**
 * Default `pushEntriesDebounced` debounce wait time in milliseconds.
 */
const DEFAULT_PUSH_ENTRIES_DEBOUNCE_WAIT_MS: number = 500;

// ---- Exports -------------------------------------------------------------------------------------------------------

export { DEFAULT_STACK_MAX_SIZE, DEFAULT_PUSH_ENTRIES_DEBOUNCE_WAIT_MS };
