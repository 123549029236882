import omit from 'lodash/omit';
import { filterNullUndefined } from '../shared';
import {
	IExtendPlayChoiceDefinitionDataOpts,
	IPlayChoiceDefinitionData,
	IPlayChoiceDefinitionDataExt,
} from './types/playChoiceDefinitionExt';

/**
 * @returns An empty `IPlayChoiceDefinitionData` object with default values.
 */
const newEmptyPlayChoiceDefinitionData = (): IPlayChoiceDefinitionData => ({
	choiceTypeId: '',
	name: '',
	description: '',
	allWagersRequired: false,
	contextRequired: false,
	availableWagers: [],
	choicePeriodId: '',
});

/**
 * @returns An empty `IPlayChoiceDefinitionDataExt` object with default values.
 */
const newEmptyPlayChoiceDefinitionDataExt = (): IPlayChoiceDefinitionDataExt => ({
	raw: newEmptyPlayChoiceDefinitionData(),
	choiceId: '',
	contextId: '',
	name: '',
	description: '',
	isAllWagersRequired: false,
	isContextRequired: false,
	availableWagerIds: [],
	choicePeriodId: '',
	playId: '',
});

/**
 * @returns Extended version of `IPlayChoiceDefinitionData` with re-mapped types, props and added fields.
 */
const extendPlayChoiceDefinitionData = (
	src: IPlayChoiceDefinitionData,
	opts?: Maybe<IExtendPlayChoiceDefinitionDataOpts>
): IPlayChoiceDefinitionDataExt => {
	const base = omit(src, ['choiceTypeId', 'allWagersRequired', 'contextRequired', 'availableWagers']);

	return {
		...newEmptyPlayChoiceDefinitionDataExt(),
		...filterNullUndefined(base),
		raw: { ...src },
		choiceId: src.choiceTypeId,
		contextId: opts?.contextId ?? '',
		name: src.name ?? '',
		description: src.description ?? '',
		isAllWagersRequired: src.allWagersRequired ?? false,
		isContextRequired: src.contextRequired ?? false,
		availableWagerIds: src.availableWagers ?? [],
		choicePeriodId: src.choicePeriodId ?? '',
		playId: opts?.playId ?? '',
	};
};

/**
 * @returns Extended version of `IPlayChoiceDefinitionData[]` with re-mapped types, props and added fields.
 */
const extendPlayChoiceDefinitionDataList = (
	srcList: IPlayChoiceDefinitionData[],
	opts?: Maybe<IExtendPlayChoiceDefinitionDataOpts>
): IPlayChoiceDefinitionDataExt[] => {
	const result: IPlayChoiceDefinitionDataExt[] = [];

	for (const src of srcList) {
		result.push(extendPlayChoiceDefinitionData(src, opts));
	}

	return result;
};

// ---- Exports -------------------------------------------------------------------------------------------------------

export { extendPlayChoiceDefinitionData, extendPlayChoiceDefinitionDataList };
export { newEmptyPlayChoiceDefinitionData, newEmptyPlayChoiceDefinitionDataExt };

// Other
export * from './types/playChoiceDefinitionExt';
