import isFunction from 'lodash/isFunction';
import { IAuthSessionProvider } from '../../../client/core/AuthSession';
import { getDefaultOmnibusToken, newOmnibusSession } from '../../../client/core/helpers';
import { IDevLoginReplyData } from '../../../client/rpc/types/replydata';
import { IGameDevService } from '../../../client/service';
import { DebugBase } from '../../../common';
import { generateRandomString } from '../../../helpers';
import {
	IClientRpcSdkSessionAuthOpts,
	IClientRpcSdkSessionAuthUserLoginData,
	IMethodSessionAuthLoginOpts,
	IMethodSessionAuthLoginWithDevServiceOpts,
} from './types';
import { newUserSession } from './utility';

class ClientRpcSdkSessionAuth extends DebugBase {
	/* #region ---- Properties --------------------------------------------------------------------------------------- */

	/**
	 * Currently assigned options.
	 */
	protected override _options: IClientRpcSdkSessionAuthOpts = ClientRpcSdkSessionAuth.defaultOptions();

	/**
	 * Game dev service instance (used to login and get a token)
	 */
	protected _devService: IGameDevService;

	/**
	 * Current user session instance.
	 */
	protected _userSession!: IAuthSessionProvider;

	/**
	 * Current omnibus session instance.
	 */
	protected _omnibusSession!: IAuthSessionProvider;

	/**
	 * Data used in current user login plus the result data
	 */
	protected _userLoginData: IClientRpcSdkSessionAuthUserLoginData = ClientRpcSdkSessionAuth.defaultUserLoginData();

	/**
	 * TRUE if the class has been initialized.
	 */
	protected _isInitialized: boolean = false;

	/* #endregion ---- Properties ------------------------------------------------------------------------------------ */

	/* #region ---- CONSTRUCTOR ---------------------------------------------------------------------------------------*/

	constructor(devService: IGameDevService, opts?: Maybe<IClientRpcSdkSessionAuthOpts>) {
		super();

		this._devService = devService;

		opts != null && this.setOptions(opts);
		this.init();
	}

	/* #endregion ---- CONSTRUCTOR ------------------------------------------------------------------------------------*/

	/* #region ---- Public ------------------------------------------------------------------------------------------- */

	/**
	 * Returns the current user session instance.
	 */
	public get userSession(): IAuthSessionProvider {
		return this._userSession;
	}

	/**
	 * Returns the current omnibus session instance.
	 */
	public get omnibusSession(): IAuthSessionProvider {
		return this._omnibusSession;
	}

	/**
	 * Returns the current user login data.
	 */
	public get userLoginData() {
		return this._userLoginData;
	}

	/**
	 * Returns the current player ID.
	 */
	public get playerId(): string {
		return this._userLoginData.login.playerId;
	}

	/**
	 * Returns the current user token.
	 */
	public get token(): string {
		return this._userSession.token;
	}

	/**
	 * @returns TRUE if a user is currently logged in.
	 */
	public get isLoggedIn(): boolean {
		return this._userLoginData.isLoggedIn;
	}

	/**
	 * Sets/initializes the class options.
	 *
	 * - Overrides the parent class method.
	 */
	public override setOptions(opts: IClientRpcSdkSessionAuthOpts) {
		const { newOpts, origOpts } = this.resolveOptions(opts);
		this._options = newOpts;
		this.onSetOptions(newOpts, origOpts);
	}

	/**
	 * Login with the given email and optional data.
	 */
	public async login(
		email: string,
		opts?: Maybe<IMethodSessionAuthLoginOpts>
	): Promise<IClientRpcSdkSessionAuthUserLoginData> {
		if (email === '') {
			throw new Error('Cannot login with empty email value.');
		}

		const currentLoginData = { ...this._userLoginData };
		const currentEmail = currentLoginData.auth?.email ?? '';
		const isLoggedIn = currentLoginData.isLoggedIn;

		// If this email is already logged-in then just return the current user login data
		if (isLoggedIn && email === currentEmail) {
			return currentLoginData;
		}

		// If the email is different then logout the current user
		if (isLoggedIn && email !== currentEmail) {
			this.logout();
		}

		const data: IClientRpcSdkSessionAuthUserLoginData = {
			...ClientRpcSdkSessionAuth.defaultUserLoginData(),
			auth: { email, displayName: opts?.displayName ?? null, operatorLabel: opts?.operatorLabel },
		};

		const loginResult = await this.loginWithDevService(email, {
			displayName: opts?.displayName,
			operatorLabel: opts?.operatorLabel,
			rethrowErrors: false,
		});

		if (loginResult != null && loginResult.token !== '') {
			data.isLoggedIn = true;
			data.login = { playerId: loginResult.userId, token: loginResult.token };
			this._userSession.token = loginResult.token;
		}

		this._userLoginData = data;

		return this._userLoginData;
	}

	/**
	 * Logout the current user session (and clear the user login data).
	 */
	public logout(): void {
		this._userSession && this._userSession.clear();
		this._userLoginData = ClientRpcSdkSessionAuth.defaultUserLoginData();
	}

	/* #endregion ---- Public ---------------------------------------------------------------------------------------- */

	/* #region ---- Protected ---------------------------------------------------------------------------------------- */

	protected get omnibusToken(): string {
		const opts = this._options;
		return (isFunction(opts?.omnibusTokenAccessorFn) ? opts.omnibusTokenAccessorFn() : '') || getDefaultOmnibusToken();
	}

	/**
	 * Resolves the options being passed in and returns the original and new options.
	 *
	 * - Overrides the parent class method.
	 */
	protected override resolveOptions(opts?: Maybe<IClientRpcSdkSessionAuthOpts>) {
		const origOpts: IClientRpcSdkSessionAuthOpts = {
			...ClientRpcSdkSessionAuth.defaultOptions(),
			...this._options,
		};

		const newOpts: IClientRpcSdkSessionAuthOpts = {
			...origOpts,
			...(opts ?? {}),
		};

		return { origOpts, newOpts };
	}

	/**
	 * Initializes the class instance.
	 */
	protected init(): boolean {
		if (this._isInitialized) {
			return false;
		}

		this.initOmnibusSession();
		this.initUserSession();

		this._isInitialized = true;

		return true;
	}

	/**
	 * Initializes the omnibus session instance.
	 */
	protected initOmnibusSession() {
		this._omnibusSession = newOmnibusSession(this.omnibusToken);
	}

	/**
	 * Initializes the user session instance.
	 */
	protected initUserSession() {
		const session = this._options.userSession ?? ClientRpcSdkSessionAuth.defaultUserSession();

		// If the session currently has a token
		if (session.hasToken() && !session.hasExpired()) {
			this._userLoginData = {
				...ClientRpcSdkSessionAuth.defaultUserLoginData(),
				isLoggedIn: true,
				auth: { email: 'sdk_token_auth@areax.app', displayName: `User_${generateRandomString(5)}` },
				login: { playerId: '', token: session.token },
			};
		} else {
			session.clear();
		}

		this._userSession = session;
	}

	/**
	 * Attempts to login using the device service.
	 */
	protected async loginWithDevService(
		email: string,
		opts?: Maybe<IMethodSessionAuthLoginWithDevServiceOpts>
	): Promise<Nullable<IDevLoginReplyData>> {
		const { rethrowErrors = true } = opts ?? {};
		const debugMethod = 'loginWithDevService';

		const devService = this._devService;

		let result: Nullable<IDevLoginReplyData> = null;

		try {
			// Login and/or register via RPC user service
			result = await devService.login(email, opts?.displayName, opts?.operatorLabel).promise;

			if (result.userId === '' || result.token === '') {
				this.error('Login failed', debugMethod, { email, ...opts, result });
				return null;
			}
		} catch (e) {
			result = null;

			const err = e as Error;
			this.error(`Error while attempting to login with email '${email}':`, debugMethod, err);

			if (rethrowErrors) {
				throw err;
			}
		}

		return result;
	}

	/**
	 * Overrides the parent class method.
	 *
	 * @returns A JSON export of the current data.
	 */
	public override toJson(extended?: Maybe<boolean>): PlainObject {
		extended = extended ?? false;

		const toJs = (val: unknown) => DebugBase.toJs(val, { extended });

		const result: PlainObject = {
			isInitialized: this._isInitialized,
			userLoginData: toJs(this._userLoginData),
			userSession: toJs(this._userSession),
			omnibusSession: toJs(this._omnibusSession),
		};

		if (extended) {
			result.extended = {
				options: toJs({ ...this._options }),

				// Debug info from base class
				_Debug: { ...super.toJson(extended) },
			};
		}

		return result;
	}

	/* #endregion ---- Protected ------------------------------------------------------------------------------------- */

	/* #region ---- Static ------------------------------------------------------------------------------------------- */

	/**
	 * STATIC
	 * @returns The default options data used by this class.
	 */
	public static defaultOptions(): IClientRpcSdkSessionAuthOpts {
		return {
			...DebugBase.defaultOptions(),
			userSession: null,
			userAuth: null,
		};
	}

	/**
	 * STATIC
	 * @returns The default user login data used by this class.
	 */
	public static defaultUserLoginData(): IClientRpcSdkSessionAuthUserLoginData {
		return {
			isLoggedIn: false,
			auth: null,
			login: { playerId: '', token: '' },
		};
	}

	/**
	 * STATIC
	 * @returns The default user session instance used by this class.
	 */
	public static defaultUserSession(): IAuthSessionProvider {
		return newUserSession();
	}

	/* #endregion ---- Static ---------------------------------------------------------------------------------------- */

	/* #region ---- Debug -------------------------------------------------------------------------------------------- */

	/**
	 * Overrides the parent class property.
	 *
	 * @returns The label to use when debugging.
	 */
	protected override get debugClassLabel(): string {
		return ClientRpcSdkSessionAuth.debugClassLabel();
	}

	/**
	 * STATIC
	 * @returns Label assigned to this class namespace.
	 */
	protected static debugClassLabel(): string {
		return `RpcLib.Sdk.ClientRpcSdkSessionAuth`;
	}

	/* #endregion ---- Debug ----------------------------------------------------------------------------------------- */
}

// ---- Export --------------------------------------------------------------------------------------------------------

export { ClientRpcSdkSessionAuth };
