import { copyAnyObjectList, extendTableSeatData, makeUniqId } from '../../../../helpers';
import { generateObjectListHashId } from '../../../../helpers/data/utility';
import {
	IMethodTableSeatAssignmentMapDataOpts,
	ITableSeatAssignmentDataEntry,
	ITableSeatAssignmentsData,
	RawTableSeatAssignmentEntry,
	RawTableSeatAssignmentList,
	TableSeatAssignmentsDataList,
	TableSeatAssignmentsDataLookup,
} from './types';

/**
 * @returns The default data used by the `TableSeatAssignments` class.
 */
const defaultData = (
	opts?: Maybe<{ tableId?: Maybe<string>; updatedTs?: Maybe<number> }>
): ITableSeatAssignmentsData => {
	return {
		raw: null,
		lookup: new Map<string, ITableSeatAssignmentDataEntry>(),
		lastUpdatedTs: opts?.updatedTs ?? 0,
		tableId: opts?.tableId ?? '',
		uniqId: makeUniqId(),
		hashId: '',
	};
};

/**
 * @returns A unique hash id for the given list of raw data entries.
 */
const generateRawListHashId = (list: RawTableSeatAssignmentList): string => {
	return generateObjectListHashId<RawTableSeatAssignmentEntry>(list, ['seatNum', 'playerId', 'open']);
};

/**
 * @returns A unique hash id for the given list of data entries.
 */
const generateListHashId = (list: TableSeatAssignmentsDataList): string => {
	return generateObjectListHashId<ITableSeatAssignmentDataEntry>(list, ['seatNumber', 'playerId', 'isOpen']);
};

/**
 * @returns TRUE if the specified raw available wagers lists are the same - in terms of the meaningful data.
 */
const isRawListSameData = (list1: RawTableSeatAssignmentList, list2: RawTableSeatAssignmentList): boolean => {
	if (list1 === list2) {
		return true;
	}
	if (list1.length !== list2.length) {
		return false;
	}

	const hashId1 = generateRawListHashId(list1);
	const hashId2 = generateRawListHashId(list2);

	return hashId1 === hashId2;
};

/**
 * Maps the raw data list sent by the server and resolves to the relevant encapsulated data.
 */
const newDataFromRawList = (
	srcList: RawTableSeatAssignmentList,
	tableId: string,
	opts?: Maybe<IMethodTableSeatAssignmentMapDataOpts>
): ITableSeatAssignmentsData => {
	const lastUpdatedTs: number = opts?.updatedTs || Date.now();

	if (srcList.length === 0) {
		return defaultData({ updatedTs: lastUpdatedTs, tableId });
	}

	const lookup = new Map<string, ITableSeatAssignmentDataEntry>();

	srcList.forEach((entry: RawTableSeatAssignmentEntry) => {
		const entryExt = extendTableSeatData(entry, opts?.extendDataOpts);
		entryExt.tableId = tableId || entryExt.tableId;

		const seatNum = entryExt.seatNumber;

		const item: ITableSeatAssignmentDataEntry = {
			...entryExt,
			lastUpdatedTs,
		};

		lookup.set(`${seatNum}`, item);
	});

	const list = Array.from(lookup.values());
	const hashId = generateListHashId(list);
	const uniqId = makeUniqId();

	const rawList = srcList.slice();
	const rawHashId = generateRawListHashId(rawList);

	return {
		raw: { list: rawList, hashId: rawHashId },
		lookup,
		lastUpdatedTs,
		uniqId,
		hashId,
		tableId,
	};
};

/**
 * @returns A copy of the specified raw data list.
 */
const copyRawList = (list: RawTableSeatAssignmentList): RawTableSeatAssignmentList => {
	return copyAnyObjectList<RawTableSeatAssignmentEntry>(list);
};

/**
 * @returns A copy of the specified data list.
 */
const copyList = (list: TableSeatAssignmentsDataList): TableSeatAssignmentsDataList => {
	return copyAnyObjectList<ITableSeatAssignmentDataEntry>(list);
};

/**
 * @returns A copy of the specified data lookup.
 */
const copyLookup = (lookup: TableSeatAssignmentsDataLookup): TableSeatAssignmentsDataLookup => {
	const copy = new Map<string, ITableSeatAssignmentDataEntry>();
	for (const [key, value] of lookup) {
		copy.set(key, { ...value });
	}

	return copy;
};

/**
 * @returns A copy of the specified seat assignments data.
 */
const copyData = (
	data: ITableSeatAssignmentsData,
	opts?: Maybe<{ updatedTs?: Maybe<number> }>
): ITableSeatAssignmentsData => {
	const newData: ITableSeatAssignmentsData = defaultData();
	newData.lastUpdatedTs = opts?.updatedTs ?? data.lastUpdatedTs;
	newData.lookup = new Map<string, ITableSeatAssignmentDataEntry>(data.lookup);
	newData.hashId = data.hashId;
	newData.tableId = data.tableId;
	newData.raw = data.raw != null ? { list: copyRawList(data.raw.list), hashId: data.raw.hashId } : null;

	return newData;
};

// ---- Exports -------------------------------------------------------------------------------------------------------

export { copyData, copyList, copyLookup, copyRawList, defaultData };
export { generateListHashId, generateRawListHashId, isRawListSameData };
export { newDataFromRawList };
