const REACT_APP_PREFIX = 'REACT_APP_';

const DEFAULT_SPOKE_SERVER_TEMPLATE = 'https://{{SPOKE}}.{{DEPLOYMENT}}.{{SERVER}}';
const DEFAULT_DEVICE_SERVER_TEMPLATE = 'https://hub-device-web.{{DEPLOYMENT}}.{{SERVER}}';
const DEFAULT_BACKOFFICE_SERVER_TEMPLATE = 'https://hub-backoffice.{{DEPLOYMENT}}.{{SERVER}}';
const DEFAULT_CHAT_SERVER_TEMPLATE = 'https://chat-web.{{DEPLOYMENT}}.{{SERVER}}';
const DEFAULT_SPOKE = 'spoke-a';
const DEFAULT_DEPLOYMENT = 'dev';
const DEFAULT_SERVER_DOMAIN = 'areax.app';

enum ConfigVars {
	TABLE_KEY = 'TABLE_KEY',
	APP_USERNAME = 'USERNAME',
	APP_PASSWORD = 'PASSWORD',
	DEALER_OVERLAY = 'DEALER_OVERLAY',
	SERVER_URI = 'SERVER_URI',
	DEVICE_SERVER_URI = 'DEVICE_SERVER_URI',
	BACKOFFICE_SERVER_URI = 'BACKOFFICE_SERVER_URI',
	CHAT_SERVER_URI = 'CHAT_SERVER_URI',
	SPOKE_SERVER_TEMPLATE = 'SPOKE_SERVER_TEMPLATE',
	BACKOFFICE_SERVER_TEMPLATE = 'BACKOFFICE_SERVER_TEMPLATE',
	DEVICE_SERVER_TEMPLATE = 'DEVICE_SERVER_TEMPLATE',
	CHAT_SERVER_TEMPLATE = 'CHAT_SERVER_TEMPLATE',
	GAME_TITLE = 'GAME_TITLE',
	THEMES = 'themes',
	SPOKE = 'SPOKE',
	DEPLOYMENT = 'DEPLOYMENT',
	SERVER_DOMAIN = 'SERVER',

	// Phenix
	ENABLE_PHENIX_TELEMETRY = 'ENABLE_PHENIX_TELEMETRY',
	PHENIX_AUTH_API_URI = 'PHENIX_AUTH_API_URI',
	PHENIX_CHANNEL_ID = 'PHENIX_CHANNEL_ID',
	PHENIX_CHANNEL_ALIAS = 'PHENIX_CHANNEL_ALIAS',
	PHENIX_STREAM_TOKEN = 'STREAM_TOKEN',
}

// ---- Export --------------------------------------------------------------------------------------------------------

export {
	DEFAULT_SPOKE_SERVER_TEMPLATE,
	DEFAULT_DEVICE_SERVER_TEMPLATE,
	DEFAULT_BACKOFFICE_SERVER_TEMPLATE,
	DEFAULT_CHAT_SERVER_TEMPLATE,
	DEFAULT_SPOKE,
	DEFAULT_DEPLOYMENT,
	DEFAULT_SERVER_DOMAIN,
	REACT_APP_PREFIX,
};

export { ConfigVars };

// Other
export * from './constants.ldx';
