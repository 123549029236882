/**********************************************************************************************************************
 * Stores and manages data regarding the current table data.
 *
 * Note: This will be used as the base parent class for the same store inside of the game clients.
 *********************************************************************************************************************/
import { ITableConfigData, ITableData, ITableSeatData } from '../../client/rpc';
import { TableState } from '../../client/rpc/enums';
import { IGameService } from '../../client/service/types';
import { IToJsOpts } from '../../helpers';
import { DataStore } from '../DataStore';
import { ITableStoreOpts } from './types';

class TableStore extends DataStore<IGameService, ITableData> {
	/* #region ---- Properties --------------------------------------------------------------------------------------- */

	/**
	 * Currently assigned options.
	 */
	protected override _options: ITableStoreOpts = TableStore.defaultOptions();

	/* #endregion ---- Properties ------------------------------------------------------------------------------------ */

	/* #region ---- CONSTRUCTOR ---------------------------------------------------------------------------------------*/

	/**
	 * @param  service  Service associated with this store. Needed in order to call `populate`.
	 */
	constructor(service?: Maybe<IGameService>, opts?: Maybe<ITableStoreOpts>) {
		super(service);

		opts != null && this.setOptions(opts);
	}

	/* #endregion ---- CONSTRUCTOR ----------------------------------------------------------------------------------- */

	/* #region ---- Public ------------------------------------------------------------------------------------------- */

	/**
	 * Sets/initializes the class options.
	 *
	 * - Overrides the parent class method.
	 */
	public override setOptions(opts: ITableStoreOpts) {
		const { newOpts } = this.resolveOptions(opts);
		this._options = newOpts;
		this.onSetOptions(newOpts);
	}

	/**
	 * The unique table ID.
	 */
	public get tableId(): string {
		return this.data?.tableId ?? '';
	}

	/**
	 * Alias for `tableId`.
	 */
	public get id(): string {
		return this.tableId;
	}

	/**
	 * The current table play ID.
	 */
	public get playId(): string {
		return this.data?.playId ?? '';
	}

	/**
	 * Gets the previous table play ID.
	 */
	public get previousPlayId(): string {
		return this.data?.previousPlayId ?? '';
	}

	/**
	 * The current table state (eg. TABLE_STATE_IN_PLAY)
	 */
	public get state(): TableState {
		return this.data?.state ?? TableState.UNKNOWN;
	}

	/**
	 * The name of the game being played at the table (eg. 'roulette_american')
	 */
	public get game(): string {
		return this.data?.gameName ?? '';
	}

	/**
	 * The name of the game being played at the table (eg. 'american')
	 */
	public get gameVariant(): string {
		return this.data?.gameVariant ?? '';
	}

	/**
	 * The descriptive name of the table (eg. 'American Roulette - A')
	 */
	public get tableName(): string {
		return this.data?.displayName ?? '';
	}

	/**
	 * The total number of seats at the table.
	 */
	public get seatCount(): number {
		return Number(this.data?.seatCount ?? 0);
	}

	/**
	 * The total number of open seats at the table.
	 */
	public get openSeatsCount(): number {
		return Number(this.data?.openSeats ?? 0);
	}

	/**
	 * List of seat data (including what seats are open and which players are sat at what seats).
	 */
	public get seatsList(): ITableSeatData[] {
		const val = this.data?.seats ?? null;

		return (val ?? []) as ITableSeatData[];
	}

	/**
	 * The current table configuration data.
	 */
	public get tableConfig(): Nullable<ITableConfigData> {
		const val = this.data?.playConfig ?? null;

		return val as Nullable<ITableConfigData>;
	}

	/**
	 * @returns A JSON export of the current pertinent data.
	 */
	public override toJson(extended?: Maybe<boolean>): PlainObject {
		extended = extended ?? false;

		const toJs = (val: unknown, opts?: Maybe<IToJsOpts>) => this.toJs(val, extended, { ...opts });

		const result: PlainObject = {
			tableId: this.tableId,
			playId: this.playId,
			previousPlayId: this.previousPlayId,
			state: this.state,
			game: this.game,
			gameVariant: this.gameVariant,
			tableName: this.tableName,
			seatCount: this.seatCount,
			openSeatsCount: this.openSeatsCount,
			seatsList: toJs(this.seatsList),
			tableConfig: toJs(this.tableConfig),
		};

		if (extended) {
			result.this = toJs(this, { useToJson: false });
			result['_DataStore'] = {
				...super.toJson(extended),
			};
		}

		return result;
	}

	/**
	 * Action. Populates the store (via unary RPC service call) using the specified table ID.
	 */
	public override async populate(tableId: string, includePlay?: Maybe<boolean>): Promise<boolean> {
		if (tableId === '') {
			this.error('A valid table ID must be specified', 'populate');
		}

		return await super.populate(tableId, includePlay);
	}

	/* #endregion ---- Public ---------------------------------------------------------------------------------------- */

	/* #region ---- Protected ---------------------------------------------------------------------------------------- */

	/**
	 * Gets data from the associated service in order to populate the store.
	 *
	 * @returns The underlying data needed to populate this store.
	 */
	protected async fetchPopulateData(
		tableId: string,
		includePlay?: Maybe<boolean>,
		includePlayConfig?: Maybe<boolean>,
		compactPlaystateWagerDefinitions?: Maybe<boolean>
	): Promise<Nullable<ITableData>> {
		if (!this._service) {
			this.error('No service was specified', 'fetchPopulateData');
			return null;
		}

		tableId = tableId ?? '';
		if (tableId === '') {
			this.error('A valid table ID must be specified', 'fetchPopulateData');
		}

		includePlay = includePlay ?? false;
		includePlayConfig = includePlayConfig ?? false;
		compactPlaystateWagerDefinitions = compactPlaystateWagerDefinitions ?? false;

		const reply =
			(await this._service.getTable(tableId, { includePlay, includePlayConfig, compactPlaystateWagerDefinitions })
				.promise) ?? null;

		return reply.table != null ? (reply.table as ITableData) : null;
	}

	/**
	 * Resolves the options being passed in and returns the original and new options.
	 *
	 * - Overrides the parent class method.
	 */
	protected override resolveOptions(opts?: Maybe<ITableStoreOpts>) {
		const origOpts: ITableStoreOpts = {
			...TableStore.defaultOptions(),
			...this._options,
		};

		const newOpts: ITableStoreOpts = {
			...origOpts,
			...(opts ?? {}),
		};

		return { origOpts, newOpts };
	}

	/* #endregion ---- Protected ------------------------------------------------------------------------------------- */

	/* #region ---- Static ------------------------------------------------------------------------------------------- */

	/**
	 * STATIC
	 * @returns The default options data used by this class.
	 */
	public static defaultOptions(): ITableStoreOpts {
		return {
			...DataStore.defaultOptions(),
		};
	}

	/* #endregion ---- Static ---------------------------------------------------------------------------------------- */

	/* #region ---- Debug -------------------------------------------------------------------------------------------- */

	/**
	 * Overrides the base class method.
	 *
	 * @returns The label to use when debugging.
	 */
	protected override get debugClassLabel(): string {
		return TableStore.debugClassLabel();
	}

	/**
	 * STATIC
	 * @returns Label assigned to this class namespace.
	 */
	protected static debugClassLabel(): string {
		return `RpcLib.Store.TableStore`;
	}

	/* #endregion ---- Debug ----------------------------------------------------------------------------------------- */
}

// ---- Exports -------------------------------------------------------------------------------------------------------

export { TableStore as default };
export { TableStore };
