import { resolvePlaySeatAssignmentsLookupToMap } from './playSeatExt';
import {
	extendPlayWagerDefinitionDataList,
	IPlayWagerDefinitionData,
	IPlayWagerDefinitionDataExt,
	newEmptyPlayWagerDefinitionDataExt,
} from './playWagerDefinitionExt';
import {
	IAvailableWagerDataExt,
	IContextAvailableWagersData,
	ICreateAvailableWagersDataExtOpts,
} from './types/availableWagersExt';
import { PlayWagerDefsList } from './types/playWagerDefinitionExt';
import { makeAvailableWagerKey, normalizeContextId } from './utility';

/**
 * @returns An empty `IContextAvailableWagersData` object with default values.
 */
const newEmptyContextAvailableWagersData = (): IContextAvailableWagersData => ({
	contextId: '',
	wagerTypeIds: [],
});

/**
 * @returns An empty `IAvailableWagerDataExt` object with default values.
 */
const newEmptyAvailableWagerExt = (
	opts?: Maybe<{ playId?: Maybe<string>; seatNumber?: Maybe<number>; seatId?: Maybe<string> }>
): IAvailableWagerDataExt => ({
	def: newEmptyPlayWagerDefinitionDataExt(),
	availableWagerKey: '',
	categoryMemberKey: '',
	categoryName: '',
	contextId: '',
	isCategoryWager: false,
	name: '',
	playId: opts?.playId ?? '',
	seatId: opts?.seatId ?? '',
	seatNumber: opts?.seatNumber ?? 1,
	wagerId: '',
});

/**
 * @returns The specified variant type converted to an array of `IPlayWagerDefinitionDataExt` objects.
 */
const resolvePlayWagersDefsList = (playWagerDefsList: PlayWagerDefsList): IPlayWagerDefinitionDataExt[] => {
	if (playWagerDefsList.length === 0) {
		return [];
	}

	const def = playWagerDefsList[0];
	if ('availableKeys' in def) {
		return extendPlayWagerDefinitionDataList(playWagerDefsList as IPlayWagerDefinitionData[]);
	} else {
		return playWagerDefsList as IPlayWagerDefinitionDataExt[];
	}
};

/**
 * @returns The specified variant type converted to a map of `wagerId` => `IPlayWagerDefinitionDataExt` objects.
 */
const resolvePlayWagersDefsLookup = (
	playWagerDefsList: PlayWagerDefsList
): Map<string, IPlayWagerDefinitionDataExt> => {
	const list = resolvePlayWagersDefsList(playWagerDefsList);

	const result = new Map<string, IPlayWagerDefinitionDataExt>();
	list.forEach((wagerDef) => {
		result.set(wagerDef.wagerId, wagerDef);
	});

	return result;
};

const resolveAvailableCategoryWagerName = (categoryName: string, categoryMemberKey: string): string => {
	const categoryMemberKeyParts = categoryMemberKey.split(':');
	if (categoryMemberKeyParts.length === 1) {
		return `${categoryName}:${categoryMemberKey}`;
	}

	const keyPart = categoryMemberKeyParts.slice(1).join(':');

	return `${categoryName}:${keyPart}`;
};

/**
 * @returns A lookup of `availableWagerKey` => `IAvailableWagerDataExt` objects mapped from the specified lists of
 *          available play wagers and wager definitions.
 */
const createAvailableWagersDataExtLookup = (
	availablePlayWagers: IContextAvailableWagersData[],
	playWagerDefsList: PlayWagerDefsList,
	opts?: Maybe<ICreateAvailableWagersDataExtOpts>
): Map<string, IAvailableWagerDataExt> => {
	const result = new Map<string, IAvailableWagerDataExt>();

	if (availablePlayWagers.length === 0 || playWagerDefsList.length === 0) {
		return result;
	}

	const wagerDefsLookup = resolvePlayWagersDefsLookup(playWagerDefsList);

	availablePlayWagers.forEach((avail) => {
		const contextId = normalizeContextId(avail.contextId);
		const wagerIds = avail.wagerTypeIds ?? [];

		if (wagerIds.length === 0) {
			return; // Next available wager
		}

		wagerIds.forEach((wagerId) => {
			const wagerDef = (wagerId !== '' ? wagerDefsLookup.get(wagerId) : null) ?? null;
			if (wagerDef == null) {
				return; // Next wager ID
			}

			const playId = opts?.playId || wagerDef.playId || '';

			const isCategoryWager = wagerDef.isCategory || wagerDef.categoryMemberKeys.length > 0;

			const base: IAvailableWagerDataExt = {
				...newEmptyAvailableWagerExt({ playId }),
				def: wagerDef,
				name: wagerDef.name,
				wagerId,
				contextId,
				isCategoryWager,
			};

			if (!base.isCategoryWager) {
				const availableWagerKey = makeAvailableWagerKey(wagerId, { contextId, categoryMemberKey: '' });
				const availableWager = { ...base, availableWagerKey };
				result.set(availableWagerKey, availableWager);
			} else {
				const categoryName = wagerDef.name;

				wagerDef.categoryMemberKeys.forEach((categoryMemberKey) => {
					if (categoryMemberKey === '') {
						return; // Next category member key
					}

					const availableWagerKey = makeAvailableWagerKey(wagerId, { contextId, categoryMemberKey });
					const availableWager = {
						...base,
						availableWagerKey,
						categoryMemberKey,
						categoryName,

						// TODO: Eventually we might want to ONLY use the category member key as the available wager name once we are safe to do so
						name: resolveAvailableCategoryWagerName(categoryName, categoryMemberKey),
					};

					result.set(availableWagerKey, availableWager);
				});
			}
		});
	});

	// Attempt to resolve the ACTUAL seat details from the available wager context ID - important for multi-seat games
	const playSeatBySeatIdLookup = resolvePlaySeatAssignmentsLookupToMap(opts?.playSeatAssignments ?? null);
	result.forEach((aw, _availableWagerKey) => {
		const playSeat = (aw.contextId !== '' ? playSeatBySeatIdLookup.get(aw.contextId) : null) ?? null;
		if (playSeat != null) {
			aw.seatNumber = playSeat.seatNumber;
			aw.seatId = playSeat.seatId;
		}
	});

	return result;
};

/**
 * @returns An array of `IAvailableWagerDataExt` objects mapped from the specified lists of available play wagers and
 *          wager definitions.
 */
const createAvailableWagersDataExtList = (
	availablePlayWagers: IContextAvailableWagersData[],
	playWagerDefsList: PlayWagerDefsList,
	opts?: Maybe<ICreateAvailableWagersDataExtOpts>
): IAvailableWagerDataExt[] => {
	const lookup = createAvailableWagersDataExtLookup(availablePlayWagers, playWagerDefsList, opts);

	return Array.from(lookup.values());
};

/**
 * @returns A lookup of `availableWagerKey` => `IAvailableWagerDataExt` objects mapped from the specified list.
 */
const makeAvailableWagerKeyToWagerDataLookupFromList = (
	list: IAvailableWagerDataExt[]
): Map<string, IAvailableWagerDataExt> => {
	const result = new Map<string, IAvailableWagerDataExt>();

	if (list.length === 0) {
		return result;
	}

	list.forEach((item) => {
		result.set(item.availableWagerKey, item);
	});

	return result;
};

// ---- Exports -------------------------------------------------------------------------------------------------------

export type { ICreateAvailableWagersDataExtOpts };
export { createAvailableWagersDataExtLookup, createAvailableWagersDataExtList };
export { newEmptyContextAvailableWagersData, newEmptyAvailableWagerExt };
export { makeAvailableWagerKeyToWagerDataLookupFromList };

// Other
export * from './types/availableWagersExt';
