import { IContextAvailableChoicesData } from '../../../client/rpc/types';
import { IChoicePeriodDataExt, IPlayChoiceDefinitionDataExt } from '../../../helpers/data';

/**
 * @returns TRUE if the new incoming choices do not match the current choices.
 */
const compareChoices = (
	newChoices: IPlayChoiceDefinitionDataExt[],
	currentChoices: IPlayChoiceDefinitionDataExt[]
): boolean => {
	const size = newChoices.length;

	if (currentChoices.length !== size) {
		return true;
	}

	const lookup = new Map<string, IPlayChoiceDefinitionDataExt>();

	// Build the local choice lookups.
	currentChoices.forEach((choice: IPlayChoiceDefinitionDataExt) => {
		lookup.set(choice.choiceId + choice.choicePeriodId, choice);
	});

	// Check to see if the ids match.
	for (let i = 0; i < size; i++) {
		if (!lookup.get(newChoices[i].choiceId + newChoices[i].choicePeriodId)) {
			return true;
		}
	}

	return false;
};

/**
 * @returns A unique set of available choice ids.
 */
const createUniqueAvailableChoiceIds = (availableChoices: IContextAvailableChoicesData[]): Set<string> => {
	const ids = new Set<string>();

	let i: number,
		j: number = 0;
	let id: string = '';

	for (i = 0; i < availableChoices.length; i++) {
		for (j = 0; j < availableChoices[i].choiceTypeIds.length; j++) {
			id = availableChoices[i].choiceTypeIds[j];
			if (ids.has(id)) {
				continue;
			}

			ids.add(id);
		}
	}

	return ids;
};

/**
 * @returns New map of `IPlayChoiceDefinitionDataExt` by choice ID.
 */
const createChoiceIdLookup = (choices: IPlayChoiceDefinitionDataExt[]): Map<string, IPlayChoiceDefinitionDataExt> => {
	const m = new Map<string, IPlayChoiceDefinitionDataExt>();

	choices.forEach((choice: IPlayChoiceDefinitionDataExt) => {
		m.set(choice.choiceId, choice);
	});

	return m;
};

/**
 * @returns New map of `IPlayChoiceDefinitionDataExt` by choice name.
 */
const createChoiceNameLookup = (choices: IPlayChoiceDefinitionDataExt[]): Map<string, IPlayChoiceDefinitionDataExt> => {
	const m = new Map<string, IPlayChoiceDefinitionDataExt>();

	choices.forEach((choice: IPlayChoiceDefinitionDataExt) => {
		m.set(choice.name, choice);
	});

	return m;
};

/**
 * @returns New map of IChoicePeriodDataExt by choice period id.
 */
const createChoicePeriodIdLookup = (choicePeriods: IChoicePeriodDataExt[]): Map<string, IChoicePeriodDataExt> => {
	const m = new Map<string, IChoicePeriodDataExt>();

	choicePeriods.forEach((period: IChoicePeriodDataExt) => {
		m.set(period.id, period);
	});

	return m;
};

// ---- Export --------------------------------------------------------------------------------------------------------

export {
	compareChoices,
	createUniqueAvailableChoiceIds,
	createChoiceIdLookup,
	createChoiceNameLookup,
	createChoicePeriodIdLookup,
};
