import clamp from 'lodash/clamp';
import { isObjectEmpty } from './object';

/**
 * @returns A random string between 2 and the specified size up to 64 characters.
 * 			size is clamped between 2 and 64 characters.
 */
const generateRandomString = (size: number = 10, charSet: string = ''): string => {
	charSet = charSet || 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

	size = clamp(size, 2, 64);

	// TODO: We can replace the Math.random call here with randomNumber from './random'.

	let str: string = '';
	for (let i: number = 0; i < size; i++) {
		const pos: number = Math.floor(Math.random() * charSet.length);
		str += charSet.substring(pos, pos + 1);
	}

	return str;
};

/**
 * @returns A unique ID comprised of a random string plus the current timestamp.
 */
const makeUniqId = (): string => {
	const nowTs = Date.now();
	const id = generateRandomString(10);

	return `${id}__${nowTs}`;
};

/**
 * Pads number with either 0 or the specified fill string value. The resulting string will be exactly maxLength.
 *
 * Example:
 * 	pad(5) // 05
 * 	pad(55) // 55
 * 	pad(55, { maxLength: 3 }) // 055
 * 	pad(55, {maxLength: 3, fillString: 'A' }) // A55
 * 	pad(55, {maxLength: 3, fillString: 'ABC' }) // A55
 *
 * @param opts.maxLength specifies the resulting string length.
 * @param opts.fillString specifies the string to pad the number with.
 * @returns the padded number as a string.
 */
const pad = (num: number, opts?: Optional<{ maxLength?: number; fillString?: string }>): string => {
	const { maxLength = 2, fillString = '0' } = opts ?? {};

	return num.toString().padStart(maxLength, fillString);
};

/**
 * Replaces the placeholder values within a given template string, similar to handlebars / mustache templates.
 *
 * Example:
 * 		renderTemplate(`Hello {{name}}`, {name: 'world'}); // Hello world
 *
 * @returns the provided template with the placeholders replaced with the provided data.
 */
const renderTemplate = (template: string, data: StringDict) => {
	if (template === '' || isObjectEmpty(data)) {
		return template;
	}

	return template.replace(/\{\{(.*?)\}\}/g, (item: string) => {
		const propName = item.substring(2, item.length - 2);
		if (data[propName]) {
			return data[propName];
		}

		return `${item}`;
	});
};

// ---- Exports -------------------------------------------------------------------------------------------------------

export { generateRandomString, makeUniqId, pad, renderTemplate };
