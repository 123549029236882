import { IAuxiliaryGameDataReplyData, IAuxiliaryGameDataReplyDataExt } from '../../client/rpc';
import { IClientRpcSdk } from '../ClientRpcSdk';
import { debug } from './debug';
import { IClientRpcSdkGameSelectors } from './types/game';

/**
 * @returns Decoded JSON data as a PlainObject.
 */
const decodeJsonBlob = (data: Uint8Array): PlainObject => {
	const decoder = new TextDecoder('utf-8');
	const jsonStr = decoder.decode(data);
	const jsonData: PlainObject = JSON.parse(jsonStr || '{}') as PlainObject;

	return jsonData;
};

/**
 * @returns An array of requests for the specified device.
 */
const getAuxiliaryGameData = async (
	sdk: IClientRpcSdk,
	key: string,
	typeName: string
): Promise<IAuxiliaryGameDataReplyDataExt> => {
	const debugMethod = 'game.getAuxiliaryGameData';

	if (key === '') {
		debug.error('key cannot be empty', debugMethod);
	}

	if (typeName === '') {
		debug.error('typeName cannot be empty', debugMethod);
	}

	const { gameService } = sdk.services.getServices();

	const response = (await gameService.getAuxiliaryGameData(key, typeName).promise) as IAuxiliaryGameDataReplyData;

	const ext: IAuxiliaryGameDataReplyDataExt = {
		...response,
		jsonData: decodeJsonBlob(response.data),
	};

	return ext;
};

/**
 * Factory for creating game related selectors.
 */
const newGameSelectors = (sdk: IClientRpcSdk): IClientRpcSdkGameSelectors => {
	return Object.freeze({
		getAuxiliaryGameData: async (key: string, typeName: string): Promise<IAuxiliaryGameDataReplyDataExt> => {
			const data = (await getAuxiliaryGameData(sdk, key, typeName)) ?? null;
			return data;
		},
	});
};

// ---- Export --------------------------------------------------------------------------------------------------------

export { newGameSelectors };
