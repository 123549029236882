import {
	DEFAULT_CURRENCY_CODE,
	filterNullUndefined,
	filterUndefined,
	formatCurrency,
	IFormatCurrencyOpts,
} from '../../helpers';
import { resolveAmount } from '../../helpers/amounts';
import {
	IMultiSeatWagerTotalsSet,
	IMultiSeatWagerTotalsSetProps,
	ISeatWagerTotals,
	IWagerTotalData,
	IWagerTotalDataProps,
} from '../types';

/* #region ---- Defaults  ------------------------------------------------------------------------------------------ */

/**
 * @returns New empty wager total data.
 */
const defaultWagerTotalData = (): IWagerTotalData => ({
	amount: 0,
	amountReal: 0,
	amountMoney: '0.00',
	wagerCount: 0,
});

/**
 * @returns New empty seat wager totals.
 */
const defaultSeatWagerTotals = (): ISeatWagerTotals => ({});

/**
 * @returns New empty multi-seat wager totals set.
 */
const defaultMultiSeatWagerTotalsSet = (): IMultiSeatWagerTotalsSet => ({
	seatNumbers: [],
	seatTotals: defaultSeatWagerTotals(),
	sumTotals: defaultWagerTotalData(),
});

/* #endregion ---- Defaults  --------------------------------------------------------------------------------------- */

/* #region ---- Factory -------------------------------------------------------------------------------------------- */

/**
 * @returns A new wager total data object.
 */
// TODO: change this to use resolveAmountsOpts instead of formatCurrencyOpts
const newWagerTotalData = (
	props?: Maybe<IWagerTotalDataProps>,
	formatCurrencyOpts?: Maybe<IFormatCurrencyOpts>,
	from?: Maybe<IWagerTotalData>
): IWagerTotalData => {
	from = { ...defaultWagerTotalData(), ...(from ?? {}) };

	const newProps: IWagerTotalDataProps = filterUndefined(props ?? {});

	const result = { ...from, ...newProps };

	const { amount, amountReal } = resolveAmount(result.amount);
	result.amount = amount;
	result.amountReal = amountReal;

	const currencyCode = formatCurrencyOpts?.currencyCode || DEFAULT_CURRENCY_CODE;
	const amountMoney = formatCurrency(amountReal, { ...(formatCurrencyOpts ?? {}), currencyCode });
	result.amountMoney = amountMoney;
	result.currencyCode = currencyCode;

	return result;
};

/**
 * @returns A new seat wager totals collection.
 */
const newSeatWagerTotals = (
	props?: Maybe<ISeatWagerTotals>,
	filterNull?: Maybe<boolean>,
	from?: Maybe<ISeatWagerTotals>
): ISeatWagerTotals => {
	filterNull = filterNull ?? false;
	from = { ...(from ? filterUndefined(from) : {}) };
	props = filterUndefined(props ?? {});

	const result = { ...defaultSeatWagerTotals(), ...from, ...props };

	return filterNull ? filterNullUndefined(result) : result;
};

/**
 * @returns A new multi-seat wager totals set.
 */
const newMultiSeatWagerTotalsSet = (
	props?: Maybe<IMultiSeatWagerTotalsSetProps>,
	from?: Maybe<IMultiSeatWagerTotalsSet>
): IMultiSeatWagerTotalsSet => {
	from = { ...defaultMultiSeatWagerTotalsSet(), ...(from ?? {}) };
	props = filterUndefined(props ?? {});

	const result = { ...from, ...props };

	result.seatNumbers = getSeatWagerTotalsNums(result.seatTotals ?? {});

	return result;
};

/* #endregion ---- Factory  ---------------------------------------------------------------------------------------- */

/* #region ---- Fill ----------------------------------------------------------------------------------------------- */

/**
 * @returns A new seat wager totals collection with the specified seat numbers filled with the specified data.
 */
const fillNewSeatWagerTotals = (seatNumbers: number[], data: Nullable<IWagerTotalData>): ISeatWagerTotals => {
	data = data ?? null;

	const result: ISeatWagerTotals = newSeatWagerTotals();

	for (const seatNumber of seatNumbers) {
		result[seatNumber] = data ? { ...data } : null;
	}

	return result;
};

/**
 * @returns A new multi-seat wager totals set with the specified seat numbers filled with the specified data.
 */
const fillNewMultiSeatWagerTotalsSet = (
	seatNumbers: number[],
	totalsData: Nullable<IWagerTotalData>
): IMultiSeatWagerTotalsSet => {
	totalsData = totalsData ?? null;

	const result: IMultiSeatWagerTotalsSet = newMultiSeatWagerTotalsSet();

	result.seatTotals = fillNewSeatWagerTotals(seatNumbers, totalsData ? { ...totalsData } : null);
	result.seatNumbers = getSeatWagerTotalsNums(result.seatTotals ?? {});

	return result;
};

/* #endregion ---- Fill -------------------------------------------------------------------------------------------- */

/* #region ---- Other ---------------------------------------------------------------------------------------------- */

/**
 * @returns Array of seat numbers contained in the multi-seat wager totals object
 */
const getSeatWagerTotalsNums = (val: ISeatWagerTotals): number[] => {
	const seatNumKeys: string[] = Object.keys(val);
	return seatNumKeys.map(Number);
};

/* #endregion ---- Other ------------------------------------------------------------------------------------------- */

// ---- Exports -------------------------------------------------------------------------------------------------------

export { newWagerTotalData, newSeatWagerTotals, newMultiSeatWagerTotalsSet };
export { fillNewSeatWagerTotals, fillNewMultiSeatWagerTotalsSet };
export { defaultWagerTotalData, defaultSeatWagerTotals, defaultMultiSeatWagerTotalsSet };
export { getSeatWagerTotalsNums };
