import { debounce } from 'lodash';
import { KumquatChoice } from '../../client/rpc/data';
import { MakeChoiceReply } from '../../client/rpc/replies';
import {
	IChoicePeriodData,
	IContextAvailableChoicesData,
	IPlayChoiceDefinitionData,
	IPlayData,
	IPlayerChoiceData,
} from '../../client/rpc/types';
import { IGameService } from '../../client/service';
import { DebugBase } from '../../common';
import {
	extendChoicePeriodDataList,
	extendPlayChoiceDefinitionDataList,
	IChoicePeriodDataExt,
	IPlayChoiceDefinitionDataExt,
} from '../../helpers/data';
import { ManagerBase } from '../lib';
import {
	compareChoicePeriods,
	compareChoices,
	createChoiceIdLookup,
	createChoiceNameLookup,
	createChoicePeriodIdLookup,
	createUniqueAvailableChoiceIds,
	generateChoicePeriodHashId,
} from './data';
import { Events } from './events';
import { AvailableChoice } from './lib';
import { IChoiceManager, IChoiceManagerOpts } from './types.choice_manager';

/**
 * Manages the list of choices the local player can make.
 */
class ChoiceManager extends ManagerBase implements IChoiceManager {
	/* #region Properties ------------------------------------------------------------------------------------------- */

	/**
	 * Configurable options.
	 */
	protected _options!: IChoiceManagerOpts;

	/**
	 * Game service used for sending the choice request.
	 */
	protected _service!: IGameService;

	/**
	 * Current play id.
	 */
	protected _playId: string = '';

	/**
	 * Current table id.
	 */
	protected _tableId: string;

	/**
	 * Choice definitions from the play data.
	 */
	protected _choiceDefinitions: IPlayChoiceDefinitionDataExt[];

	/**
	 * Filtered choice definitions by currently active period.
	 */
	protected _availableChoices: IPlayChoiceDefinitionDataExt[];

	/**
	 * Choice periods list from the play data.
	 */
	protected _choicePeriodList: IChoicePeriodDataExt[];

	/**
	 * Choice context list from the play data.
	 */
	protected _choiceContexts: IContextAvailableChoicesData[];

	/**
	 * Active choices (e.g. choices the player has already made)
	 */
	protected _activeChoices: IPlayerChoiceData[];

	/**
	 * Choice period data lookup by period id.
	 * */
	protected _choicePeriodLookup: Map<string, IChoicePeriodDataExt>;

	/**
	 * When the data was last updated.
	 */
	protected _lastUpdatedTs: number;

	/**
	 * Flag indicating if this class instance is currently bound to MobX as an observable.
	 */
	protected _isMobXBound: boolean = false;

	/* #endregion --------------------------------------------------------------------------------------------------- */

	/* #region Getters / Setters ------------------------------------------------------------------------------------ */

	/**
	 * Get the current options.
	 */
	public get options(): IChoiceManagerOpts {
		return this._options;
	}

	/**
	 * @returns The id of the currently set play data.
	 */
	public get playId(): string {
		return this._playId;
	}
	public set playId(val: string) {
		this._playId = val;
	}

	/**
	 * @returns The id of the currently set play data.
	 */
	public get tableId(): string {
		return this._tableId;
	}
	public set tableId(val: string) {
		this._tableId = val;
	}

	/**
	 * @returns All choices sent from the server.
	 */
	public get choiceDefinitions(): IPlayChoiceDefinitionDataExt[] {
		return this._choiceDefinitions || [];
	}

	/**
	 * @returns The current choice ids.
	 */
	public get choiceIdLookup(): Map<string, IPlayChoiceDefinitionDataExt> {
		return createChoiceIdLookup(this.availableChoices);
	}

	/**
	 * @returns The current choice ids.
	 */
	public get choiceIds(): string[] {
		return Array.from<string>(this.choiceIdLookup.keys());
	}

	/**
	 * @returns A map of choice definitions by choice name.
	 */
	public get choicesByNameLookup(): Map<string, IPlayChoiceDefinitionDataExt> {
		return createChoiceNameLookup(this.availableChoices);
	}

	/**
	 * @returns All choice names based on the choice definitions.
	 */
	public get choiceNames(): string[] {
		return Array.from<string>(this.choicesByNameLookup.keys());
	}

	/**
	 * @returns The current set of available choices the player is able to make. Takes the choice periods and choice
	 * 			context data into account.
	 */
	public get availableChoices(): IPlayChoiceDefinitionDataExt[] {
		return this._availableChoices || [];
	}

	/**
	 * @returns Raw available choice context data.
	 */
	public get contextAvailableChoices(): IContextAvailableChoicesData[] {
		return this._choiceContexts || [];
	}

	/**
	 * @returns All available choice ids.
	 */
	public get availableChoiceIds(): string[] {
		const allIds = createUniqueAvailableChoiceIds(this._choiceContexts);
		return Array.from<string>(allIds.values());
	}

	/**
	 * @returns All choices the player has ALREADY made.
	 */
	public get activeChoices(): IPlayerChoiceData[] {
		return this._activeChoices;
	}

	/**
	 * @returns The choice periods list.
	 */
	public get choicePeriodsList(): IChoicePeriodDataExt[] {
		return this._choicePeriodList;
	}

	/**
	 * @returns The number of raw choices periods in the list.
	 */
	public get choicePeriodCount(): number {
		return this._choicePeriodList.length;
	}

	/**
	 * @returns TRUE if the choice periods have been set.
	 */
	public get isChoicePeriodsSet(): boolean {
		return this._choicePeriodList.length > 0;
	}

	/**
	 * @returns The list of choice period ids.
	 */
	public get choicePeriodIds(): string[] {
		return Array.from<string>(this._choicePeriodLookup.keys());
	}

	/**
	 * @returns The currently open choice periods.
	 */
	public get openChoicePeriods(): IChoicePeriodDataExt[] {
		return this._choicePeriodList.filter((value: IChoicePeriodDataExt) => !value.isClosed);
	}

	/**
	 * @returns The currently open choice periods.
	 */
	public get closedChoicePeriods(): IChoicePeriodDataExt[] {
		return this._choicePeriodList.filter((value: IChoicePeriodDataExt) => value.isClosed);
	}

	/**
	 * Gets/sets whether or not this class instance is currently bound to MobX as an observable.
	 */
	public get isMobXBound(): boolean {
		return this._isMobXBound;
	}
	public set isMobXBound(value: boolean) {
		this._isMobXBound = value;
	}

	/**
	 * The last time (unix timestamp) that the data was updated.
	 */
	public get lastUpdatedTs(): number {
		return this._lastUpdatedTs;
	}
	public set lastUpdatedTs(val: number) {
		this.setLastUpdatedTs(val);
	}

	/* #endregion --------------------------------------------------------------------------------------------------- */

	/* #region Constructor ------------------------------------------------------------------------------------------ */

	/**
	 * constructor
	 */
	public constructor(service: IGameService, options: IChoiceManagerOpts) {
		super(options);

		this._service = service;
		this._options = this.resolveConfigOptions(options);

		this.setDefaults();
	}

	/* #endregion --------------------------------------------------------------------------------------------------- */

	/* #region Data methods ----------------------------------------------------------------------------------------- */

	/**
	 * Populates the list of current choices.
	 *
	 * @returns TRUE if the choices have been updated.
	 */
	protected updateFromPlayData = (data: Nullable<IPlayData>): boolean => {
		if (data == null) {
			return false;
		}

		const debugMethod = 'updateFromPlayData';
		const updateTs = Date.now();

		const definitions = (data.playState?.choiceDefinitions ?? []) as IPlayChoiceDefinitionData[];
		const availableChoices = (data.playerState?.availableChoices || []) as IContextAvailableChoicesData[];
		const choicePeriods = (data.playState?.choicePeriods || []) as IChoicePeriodData[];
		const activeChoices = (data.playerDecisionState?.choices || []) as IPlayerChoiceData[];

		this._playId = data.playId;
		this._tableId = data.tableId;

		this.setChoicePeriods(choicePeriods, updateTs);
		this.setContextChoices(availableChoices, updateTs);
		this.setPlayerActiveChoices(activeChoices, updateTs);

		if (!this.isChoicePeriodsSet) {
			this.warn('No choice period data available', debugMethod);
		}

		const choiceList = extendPlayChoiceDefinitionDataList(definitions);

		this.setChoiceDefinitions(choiceList, updateTs);

		// TODO: Is there any good reason we'd want to check for changes on the raw choice definitions since we're
		// now doing so on the available choices?
		// {
		// 	const hasChanged = compareChoices(choiceList, this._choiceDefinitions);
		// 	if (!hasChanged) {
		// 		this.info('Choice definitions have not changed', debugMethod);
		// 	} else {
		// 		this.setChoiceDefinitions(choiceList, updateTs);
		// 	}
		// }

		{
			const filtered = this.filterOpenChoices(choiceList);
			const hasChanged = compareChoices(filtered, this._availableChoices);
			if (!hasChanged) {
				this.info('Available choices have not changed', debugMethod);
			} else {
				this.setAvailableChoices(choiceList, updateTs);
			}
		}

		return true;
	};

	/**
	 * Sets the currently available choices from choice context data.
	 */
	public setContextChoices(available: IContextAvailableChoicesData[], updatedTs?: Maybe<number>) {
		this._choiceContexts = available;

		this.setLastUpdatedTs(updatedTs || Date.now());
	}

	/**
	 * Directly sets the choices without performing any diffs or filtering.
	 *
	 * @param choices Requires the extended choice data. Use newChoicesFromRaw to convert the raw choice data.
	 * @param sendEvents TRUE if events should be fired. Defaults to true.
	 */
	public setChoiceDefinitions(choices: IPlayChoiceDefinitionDataExt[], updatedTs?: Maybe<number>): void {
		this._choiceDefinitions = choices;

		this.setLastUpdatedTs(updatedTs || Date.now());
	}

	/**
	 * Directly sets the choices without performing any diffs or filtering.
	 *
	 * @param choices Requires the extended choice data. Use newChoicesFromRaw to convert the raw choice data.
	 * @param sendEvents TRUE if events should be fired. Defaults to true.
	 */
	public setAvailableChoices(choices: IPlayChoiceDefinitionDataExt[], updatedTs?: Maybe<number>): void {
		this._availableChoices = this.filterOpenChoices(choices);

		// Send the new choices event. Since we only update when they've changed, we can
		// safely call this without spamming the client.
		this.sendNewChoicesEvent(this._availableChoices);

		this.setLastUpdatedTs(updatedTs || Date.now());
	}

	/**
	 * Sets the choice periods if the new data has changed from our local data.
	 * This also updates the choice period lookup and the updated timestamp.
	 *
	 * @returns TRUE if the new choice periods were accepted.
	 * 			FALSE if the incoming choice periods has no differences from the stored choice periods.
	 * 			FALSE if the incoming choice periods array is empty.
	 */
	public setChoicePeriods(choicePeriods: IChoicePeriodData[], updatedTs?: Maybe<number>): boolean {
		if (choicePeriods.length === 0) {
			this.info('New choice period data is empty. Doing nothing.', 'setChoicePeriods');
			return false;
		}

		const newPeriods = extendChoicePeriodDataList(choicePeriods);
		const hasChanged = compareChoicePeriods(newPeriods, this._choicePeriodList);

		if (!hasChanged) {
			this.info('New choice period data matches previous. Doing nothing.', 'setChoicePeriods');
			return false;
		}

		// Check to see if the new periods contain an entry we haven't previously seen.
		// If so, send the new choice period event.
		newPeriods.forEach((value: IChoicePeriodDataExt) => {
			const existing = this.findChoicePeriodById(value.id);
			if (!existing) {
				this.sendNewChoicePeriodEvent(value);
			}
		});

		this._choicePeriodLookup = createChoicePeriodIdLookup(newPeriods);

		// Before we set the new periods, we'll run some checks against the new and old values.
		// For example, we know that at least one choice period has changed, but we don't know
		// exactly which changed. Here we check if the period has changed and whether or not
		// it was just now closed.
		this._choicePeriodList.forEach((value: IChoicePeriodDataExt) => {
			const incoming = this.findChoicePeriodById(value.id);
			if (!incoming) {
				return;
			}

			// Send the choice period closed event if the new period was just closed.
			if (this.wasChoicePeriodJustClosed(incoming, value)) {
				this.sendChoicePeriodClosedEvent(incoming);

				// If the change was that the period was just closed, go ahead and stop here.
				return;
			}

			const newHashId = generateChoicePeriodHashId([incoming]);
			const oldHashId = generateChoicePeriodHashId([value]);

			// If the choice period has changed, send the changed event. This will typically be the case
			// when the remaining time has ticked down.
			if (newHashId !== oldHashId) {
				this.sendChoicePeriodChangedEvent(incoming);
			}
		});

		// Finally update the list with the new choice periods.
		this._choicePeriodList = newPeriods;

		this.setLastUpdatedTs(updatedTs || Date.now());

		return true;
	}

	/**
	 * Sets the active choices (e.g. choices the player has already made).
	 */
	public setPlayerActiveChoices(data: IPlayerChoiceData[], updatedTs?: Maybe<number>) {
		this._activeChoices = data;

		this.setLastUpdatedTs(updatedTs || Date.now());
	}

	/**
	 * ACTION
	 * Uses the entire `GetPlay` data payload to sync various state data.
	 * Debounces to only run {X}ms after the last time it was called - prevents multiple back-to-back updates.
	 */
	public setPlayData = debounce(this.updateFromPlayData, 500, {
		leading: true,
		trailing: true,
	});

	/**
	 * Clears choice and choice period data.
	 */
	public clear() {
		this.clearChoiceData();
		this.clearChoicePeriodData();
	}

	/**
	 * Clears the choice data, including lookups.
	 */
	public clearChoiceData() {
		this._choiceDefinitions = [];
		this._choiceContexts = [];
	}

	/**
	 * Clears the choice period data, including lookups.
	 */
	public clearChoicePeriodData() {
		this._choicePeriodList = [];
		this._choicePeriodLookup.clear();
	}

	/* #endregion --------------------------------------------------------------------------------------------------- */

	/* #region Request methods -------------------------------------------------------------------------------------- */

	/**
	 * Used when we only need to send a single choice in the request.
	 *
	 * @returns Promise<MakeChoiceReply>
	 */
	public makeChoice = (...args: AvailableChoice[]): Promise<MakeChoiceReply> => {
		const debugMethod = 'makeChoice';

		const { tableId, playId } = this;
		const requests: KumquatChoice[] = [];

		args.forEach((choice: AvailableChoice) => {
			const result = choice.isValid;

			if (result !== true) {
				this.error(`Choice is invalid and will not be sent`, debugMethod, {
					reason: result,
					choiceName: choice.choiceName,
					choiceId: choice.choiceId,
				});

				return;
			}

			requests.push(choice.createRequestData());
		});

		// Let the server return us the error(s)
		return this._service.makeChoice(playId, tableId, requests).promise;
	};

	/* #endregion --------------------------------------------------------------------------------------------------- */

	/* #region Helpers ---------------------------------------------------------------------------------------------- */

	/**
	 * @returns TRUE if the choice was found.
	 */
	public hasChoice(choiceId: string): boolean {
		return this.choiceIdLookup.has(choiceId);
	}

	/**
	 * @returns TRUE if the given choice is currently available.
	 */
	public isChoiceAvailable(choiceId: string): boolean {
		if (!this.hasChoice(choiceId)) return false;

		const available = createUniqueAvailableChoiceIds(this.contextAvailableChoices);
		return available.has(choiceId);
	}

	/**
	 * Attempts to get a choice by id.
	 *
	 * @returns NULL if the choice isn't found.
	 */
	public findChoiceById(choiceId: string): Nullable<IPlayChoiceDefinitionDataExt> {
		return this.choiceIdLookup.get(choiceId) || null;
	}

	/**
	 * Attempts to get a choice by name.
	 *
	 * @returns NULL if the choice isn't found.
	 */
	public findChoiceByName(choiceName: string): Nullable<IPlayChoiceDefinitionDataExt> {
		return this.choicesByNameLookup.get(choiceName) || null;
	}

	/**
	 * Checks to see if the choice period (by id) exists.
	 *
	 * @returns TRUE if the choice period is found.
	 */
	public hasChoicePeriod(periodId: string): boolean {
		return this._choicePeriodLookup.has(periodId);
	}

	/**
	 * Attempts to get a choice period by id.
	 *
	 * @returns NULL if the choice period could not be found.
	 */
	public findChoicePeriodById(periodId: string): Nullable<IChoicePeriodDataExt> {
		return this._choicePeriodLookup.get(periodId) || null;
	}

	/**
	 * Attempts to get a choice period by id.
	 *
	 * @returns NULL if the choice period could not be found.
	 *          TRUE if the choice period is closed.
	 *          FALSE if the choice period is open.
	 */
	public isChoicePeriodClosed(periodId: string): Nullable<boolean> {
		return this._choicePeriodLookup.get(periodId)?.isClosed || null;
	}

	/**
	 * Attempts to get the choice context by choice id.
	 *
	 * @returns NULL if the choice isn't found.
	 */
	public getContextIdForChoice(choiceId: string): Nullable<string> {
		const ln = this.contextAvailableChoices.length;

		for (let i = 0; i < ln; i++) {
			const value = this.contextAvailableChoices[i];

			if (value.choiceTypeIds.includes(choiceId)) {
				return value.contextId;
			}
		}

		return null;
	}

	/* #endregion --------------------------------------------------------------------------------------------------- */

	/* #region Utilities -------------------------------------------------------------------------------------------- */

	/**
	 * @return A list of choices associated with open choice periods.
	 */
	protected filterOpenChoices(choices: IPlayChoiceDefinitionDataExt[]): IPlayChoiceDefinitionDataExt[] {
		const debugMethod = 'filterOpenChoices';
		const filtered: IPlayChoiceDefinitionDataExt[] = [];

		choices.forEach((choice: IPlayChoiceDefinitionDataExt) => {
			// If it's not available, don't return.
			const contextId = this.getContextIdForChoice(choice.choiceId);
			if (!contextId) {
				// this.warn(`Available choice context not found for choice ${choice.choiceId}`, debugMethod);
				return;
			}

			choice.contextId = contextId;

			// If it's associated choice period is closed, don't return.
			if (this._options?.usePeriods === true) {
				const isClosed = this.isChoicePeriodClosed(choice.choicePeriodId);
				if (isClosed === null) {
					this.warn(
						`Choice period not found for choice ${choice.choiceId} (period id: ${choice.choicePeriodId})`,
						debugMethod
					);
				} else if (isClosed === true) {
					this.warn(
						`Choice period closed for choice ${choice.choiceId} (period id: ${choice.choicePeriodId})`,
						debugMethod
					);
				}
			}

			filtered.push(choice);
		});

		return filtered;
	}

	/**
	 * Actionable setter method for MobX.
	 */
	protected setLastUpdatedTs(val: number) {
		if (val === this._lastUpdatedTs) {
			return;
		}
		this._lastUpdatedTs = val;
	}

	/**
	 * @returns TRUE if the new choice period is closed and the previous was not.
	 */
	protected wasChoicePeriodJustClosed(incoming: IChoicePeriodDataExt, existing: IChoicePeriodDataExt): boolean {
		return incoming.isClosed && !existing.isClosed;
	}

	/* #endregion --------------------------------------------------------------------------------------------------- */

	/* #region Config methods --------------------------------------------------------------------------------------- */

	/**
	 * @returns A new IChoiceManagerConfig object with defaults set.
	 */
	public static defaultConfigOptions(): IChoiceManagerOpts {
		const config: IChoiceManagerOpts = {
			skipChoicesOnMissingOrClosedPeriods: true,
			usePeriods: false,
			sendChoicePeriodEvents: false,
		};

		return config;
	}

	/**
	 * @returns Default config options with override values.
	 */
	protected resolveConfigOptions(options?: Maybe<IChoiceManagerOpts>): IChoiceManagerOpts {
		const base = ChoiceManager.defaultConfigOptions();

		return {
			...base,
			...options,
		};
	}

	/* #endregion --------------------------------------------------------------------------------------------------- */

	/* #region Event methods ---------------------------------------------------------------------------------------- */

	/**
	 * Sends a `NEW_CHOICES` event.
	 */
	protected sendNewChoicesEvent(choices: IPlayChoiceDefinitionDataExt[]) {
		const eventData: PlainObject = {
			event: Events.NEW_CHOICES,
			choices: choices,
		};

		this.info('Sending NEW_CHOICES event', 'sendNewChoicesEvent', { eventData });

		this.trigger(Events.NEW_CHOICES, eventData);
	}

	/**
	 * Sends a `NEW_CHOICE_PERIOD` event.
	 */
	protected sendNewChoicePeriodEvent(newValue: IChoicePeriodDataExt) {
		if (!this._options.sendChoicePeriodEvents) {
			return;
		}

		const eventData: PlainObject = {
			event: Events.NEW_CHOICE_PERIOD,
			choicePeriod: newValue,
		};

		this.info('Sending NEW_CHOICE_PERIOD event', 'sendNewChoicePeriodEvent', { eventData });

		this.trigger(Events.NEW_CHOICE_PERIOD, eventData);
	}

	/**
	 * Sends a `CHOICE_PERIOD_CHANGED` event.
	 */
	protected sendChoicePeriodChangedEvent(updatedValue: IChoicePeriodDataExt) {
		if (!this._options.sendChoicePeriodEvents) {
			return;
		}

		const eventData: PlainObject = {
			event: Events.CHOICE_PERIOD_CHANGED,
			choicePeriod: updatedValue,
		};

		this.info('Sending CHOICE_PERIOD_CHANGED event', 'sendChoicePeriodChangedEvent', { eventData });

		this.trigger(Events.CHOICE_PERIOD_CHANGED, eventData);
	}

	/**
	 * Sends a `CHOICE_PERIOD_CLOSED` event. Uses an arrow function so that we can pass this as the
	 * onChoicePeriodClosed callback.
	 */
	protected sendChoicePeriodClosedEvent = (period: IChoicePeriodDataExt) => {
		if (!this._options.sendChoicePeriodEvents) {
			return;
		}

		const eventData: PlainObject = {
			event: Events.CHOICE_PERIOD_CLOSED,
			choicePeriod: period,
		};

		this.info('Sending CHOICE_PERIOD_CLOSED event', 'sendChoicePeriodClosedEvent', { eventData });

		this.trigger(Events.CHOICE_PERIOD_CLOSED, eventData);
	};

	/**
	 * Sets the default choice manager values.
	 */
	protected setDefaults() {
		this._playId = '';
		this._tableId = '';
		this._choiceDefinitions = [];
		this._availableChoices = [];
		this._choicePeriodList = [];
		this._activeChoices = [];
		this._choiceContexts = [];
		this._choicePeriodLookup = new Map<string, IChoicePeriodDataExt>();
		this._lastUpdatedTs = 0;
	}

	/* #endregion --------------------------------------------------------------------------------------------------- */

	/* #region Debug ------------------------------------------------------------------------------------------------ */

	/**
	 * Overrides the base class property.
	 * @returns The label to use when debugging.
	 */
	protected override get debugClassLabel(): string {
		return ChoiceManager.debugClassLabel();
	}

	/**
	 * STATIC
	 * @returns Label assigned to this class namespace.
	 */
	protected static debugClassLabel(): string {
		return `RpcLib.Manager.ChoiceManager`;
	}

	/**
	 * @returns A JSON export of the current pertinent data.
	 */
	public toJson(extended?: Maybe<boolean>): PlainObject {
		extended = extended ?? false;
		const toJs = (val: unknown) => DebugBase.toJs(val, { extended, useMobXToJs: false });

		const result: PlainObject = {
			playId: this.playId,
			tableId: this.tableId,
			choices: toJs(this.choiceDefinitions),
			available: toJs(this.availableChoices),
			choiceIds: toJs(this.choiceIds),
			choiceNames: toJs(this.choiceNames),
			choicePeriodIds: toJs(this.choicePeriodIds),
			availableChoiceIds: toJs(this.availableChoiceIds),
			lastUpdatedTs: this.lastUpdatedTs,
		};

		if (extended) {
			result.isMobXBound = this.isMobXBound;
			result.choicePeriodsList = toJs(this.choicePeriodsList);
			result.activeChoices = toJs(this.activeChoices);
			result.contextAvailableChoices = toJs(this.contextAvailableChoices);
		}

		return result;
	}

	/* #endregion --------------------------------------------------------------------------------------------------- */
}

// ---- Export --------------------------------------------------------------------------------------------------------

export { ChoiceManager };
