import omit from 'lodash/omit';
import { resolveAmounts } from '../amounts';
import { filterNullUndefined } from '../shared';
import { extendTableConfigWagerDefinitionDataList } from './tableConfigWagerDefinitionExt';
import { IExtendTableConfigDataOpts, ITableConfigData, ITableConfigDataExt } from './types/tableConfigExt';

/**
 * @returns An empty `ITableConfigData` object with default values.
 */
const newEmptyTableConfigData = (): ITableConfigData => ({
	currency: '',
	tableMin: BigInt(0),
	tableMax: BigInt(0),
	wagerDefinitions: [],
	skins: [],
	wagerLayout: '',
	chipSizes: [],
	minChipSize: BigInt(0),
	maxChipSize: BigInt(0),
	gameConfig: [],
	version: BigInt(0),
	feltLayout: '',
});

/**
 * @returns An empty `ITableConfigDataExt` object with default values.
 */
const newEmptyTableConfigDataExt = (opts?: Maybe<{ tableId?: Maybe<string> }>): ITableConfigDataExt => ({
	raw: null,
	chipValues: [],
	currencyCode: '',
	currencySymbol: '',
	feltLayout: '',
	maxAmount: 0,
	maxAmountMoney: '',
	maxAmountReal: 0,
	maxChipValue: 0,
	minAmount: 0,
	minAmountMoney: '',
	minAmountReal: 0,
	minChipValue: 0,
	skins: [],
	tableId: opts?.tableId ?? '',
	version: 0,
	wagerDefinitions: [],
	wagerLayout: '',
});

/**
 * @returns Extended version of `ITableConfigData` with re-mapped types, props and added fields.
 */
const extendTableConfigData = (
	src: ITableConfigData,
	opts?: Maybe<IExtendTableConfigDataOpts>
): ITableConfigDataExt => {
	src = src ?? {};

	const base = omit(src, [
		'tableMin',
		'tableMax',
		'currency',
		'wagerDefinitions',
		'chipSizes',
		'minChipSize',
		'maxChipSize',
		'gameConfig',
		'version',
	]);

	const includeRawData = opts?.includeRawData ?? false;
	const applyCurrencyCode = opts?.currencyCode || src.currency || '';
	const formatCurrencyOpts = opts?.formatCurrencyOpts ?? null;

	const {
		currencyCode, // Currency actually used for amounts.
		currencySymbol, // Currency symbol actually used for amounts.
	} = resolveAmounts({ amount: 0 }, { formatCurrencyOpts, currencyCode: applyCurrencyCode });

	const {
		amount: minAmount,
		amountReal: minAmountReal,
		amountMoney: minAmountMoney,
	} = resolveAmounts({ amount: Number(src.tableMin ?? 0) }, { formatCurrencyOpts, currencyCode });

	const {
		amount: maxAmount,
		amountReal: maxAmountReal,
		amountMoney: maxAmountMoney,
	} = resolveAmounts({ amount: Number(src.tableMax ?? 0) }, { formatCurrencyOpts, currencyCode });

	return {
		...newEmptyTableConfigDataExt(),
		...filterNullUndefined(base),
		raw: includeRawData ? { ...src } : null,
		currencyCode,
		currencySymbol,
		minAmount,
		minAmountReal,
		minAmountMoney,
		maxAmount,
		maxAmountReal,
		maxAmountMoney,
		wagerDefinitions: extendTableConfigWagerDefinitionDataList(src.wagerDefinitions, opts),
		chipValues: src.chipSizes.map((v) => Number(v)),
		minChipValue: Number(src.minChipSize ?? 0),
		maxChipValue: Number(src.maxChipSize ?? 0),
		version: Number(src.version ?? 0),
		tableId: opts?.tableId ?? '',
	};
};

// ---- Exports -------------------------------------------------------------------------------------------------------

export { extendTableConfigData };
export { newEmptyTableConfigData, newEmptyTableConfigDataExt };

// Other
export * from './types/tableConfigExt';
