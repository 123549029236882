// @generated by protoc-gen-connect-es v0.11.0 with parameter "target=js+dts"
// @generated from file clientserver_testing.proto (package com.sands.ldx.clientserver.testing, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { AddOperatorRequest, DeleteOperatorRequest, DeviceInputRequest, GetDeviceRequestsReply, GetDeviceRequestsRequest, LoginReply, LoginRequest, NewPlayReply, NewPlayRequest, RunPlayRequest, SetBalanceRequest, SetBalanceResponse, Success, TableStateRequest, TableStateResponse } from "./clientserver_testing_pb.js";
import { Empty, MethodKind } from "@bufbuild/protobuf";

/**
 * @generated from service com.sands.ldx.clientserver.testing.ClientServerTesting
 */
export const ClientServerTesting = {
  typeName: "com.sands.ldx.clientserver.testing.ClientServerTesting",
  methods: {
    /**
     * Login login via operator and operatorUserID 
     *
     * @generated from rpc com.sands.ldx.clientserver.testing.ClientServerTesting.Login
     */
    login: {
      name: "Login",
      I: LoginRequest,
      O: LoginReply,
      kind: MethodKind.Unary,
    },
    /**
     * To support client team testing: end-points to emulate payment processor. 
     *
     * @generated from rpc com.sands.ldx.clientserver.testing.ClientServerTesting.SetBalance
     */
    setBalance: {
      name: "SetBalance",
      I: SetBalanceRequest,
      O: SetBalanceResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc com.sands.ldx.clientserver.testing.ClientServerTesting.NewPlay
     */
    newPlay: {
      name: "NewPlay",
      I: NewPlayRequest,
      O: NewPlayReply,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc com.sands.ldx.clientserver.testing.ClientServerTesting.RunPlay
     */
    runPlay: {
      name: "RunPlay",
      I: RunPlayRequest,
      O: Empty,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc com.sands.ldx.clientserver.testing.ClientServerTesting.AddOperator
     */
    addOperator: {
      name: "AddOperator",
      I: AddOperatorRequest,
      O: Success,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc com.sands.ldx.clientserver.testing.ClientServerTesting.DeleteOperator
     */
    deleteOperator: {
      name: "DeleteOperator",
      I: DeleteOperatorRequest,
      O: Success,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc com.sands.ldx.clientserver.testing.ClientServerTesting.GetTableState
     */
    getTableState: {
      name: "GetTableState",
      I: TableStateRequest,
      O: TableStateResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc com.sands.ldx.clientserver.testing.ClientServerTesting.DeviceInput
     */
    deviceInput: {
      name: "DeviceInput",
      I: DeviceInputRequest,
      O: Empty,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc com.sands.ldx.clientserver.testing.ClientServerTesting.GetDeviceRequests
     */
    getDeviceRequests: {
      name: "GetDeviceRequests",
      I: GetDeviceRequestsRequest,
      O: GetDeviceRequestsReply,
      kind: MethodKind.Unary,
    },
  }
};

