import { action, AnnotationsMap, computed, makeObservable, observable } from 'mobx';
import { DebugBaseMobXAnnotations } from '../../../../common';
import TableConfigWagerDefinitions from './TableConfigWagerDefinitions';
import { TableConfigWagerDefinitionsMobXPrivateProps } from './types.mobx';

const TableConfigWagerDefinitionsMobXAnnotations: AnnotationsMap<
	TableConfigWagerDefinitions,
	TableConfigWagerDefinitionsMobXPrivateProps
> = {
	// Inherit from DebugBase
	...DebugBaseMobXAnnotations,

	// ---- Observed -----------------------------------------------------

	// >> Properties
	_data: observable,

	// ---- Computed -----------------------------------------------------

	// >> Public
	isEmpty: computed,
	size: computed,
	lookup: computed,
	list: computed,
	keys: computed,
	dataHashId: computed,
	lastUpdatedTs: computed,
	tableId: computed,
	wagerNames: computed,
	minAmount: computed,
	minAmountReal: computed,
	maxAmount: computed,
	maxAmountReal: computed,

	// ---- Actions ------------------------------------------------------

	// >> Public
	set: action,
	clear: action,
	populate: action,

	// >> Protected
	setData: action,
	setLastUpdatedTs: action,
	setTableId: action,

	// ---- Excluded -----------------------------------------------------

	// >> Properties
	_isMobXBound: false,

	// >> Public
	isMobXBound: false,
	isRawDataListSame: false,
	isDataListSame: false,
	get: false,
	has: false,
	clone: false,
};

/**
 * Makes the specified `TableConfigWagerDefinitions` instance an observable MobX object.
 */
const bindTableConfigWagerDefinitionsMobX = (instance: TableConfigWagerDefinitions): boolean => {
	if (instance.isMobXBound) {
		return false;
	}

	makeObservable<TableConfigWagerDefinitions, TableConfigWagerDefinitionsMobXPrivateProps>(instance, {
		...TableConfigWagerDefinitionsMobXAnnotations,
	});

	instance.isMobXBound = true;

	return true;
};

// ---- Exports -------------------------------------------------------------------------------------------------------

export { TableConfigWagerDefinitionsMobXAnnotations, bindTableConfigWagerDefinitionsMobX };
