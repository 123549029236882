/**
 * @returns A copy of the specified array of objects.
 */
const copyAnyObjectList = <ObjType>(list: ObjType[]): ObjType[] => {
	return list.map((item) => ({ ...item }));
};

// ---- Exports -------------------------------------------------------------------------------------------------------

export { copyAnyObjectList };
