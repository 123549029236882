import { Logger as DebugLogger } from '../../../../helpers';
import { makeUniqId } from '../../../../helpers';
import { resolveAmount } from '../../../../helpers/amounts';
import { makeActiveWagerKey } from '../../../../helpers/data/utility';
import { IWagerHistoryData, IWagerHistoryEntry, IWagerHistoryOpts } from './types';

const debug = new DebugLogger('WagerHistory');

/**
 * Keeps track of wager history. Used for undoing previously placed bets.
 */
class WagerHistory {
	/* #region ---- Properties --------------------------------------------------------------------------------------- */

	/**
	 * Currently assigned options.
	 */
	protected _options: IWagerHistoryOpts = WagerHistory.defaultOptions();

	/**
	 * Encapsulated data.
	 */
	protected _data: IWagerHistoryData;

	/* #endregion ---- Properties -------------------------------------------------------------------------------------*/

	/* #region ---- CONSTRUCTOR ---------------------------------------------------------------------------------------*/

	constructor(opts?: Maybe<IWagerHistoryOpts>) {
		this._data = WagerHistory.defaultData();
		this.setOptions(opts);
	}

	/**
	 * Sets/initializes the class options.
	 */
	protected setOptions(opts?: Maybe<IWagerHistoryOpts>) {
		if (opts == null) {
			return;
		}

		const origOpts: IWagerHistoryOpts = {
			...this._options,
		};

		const newOpts: IWagerHistoryOpts = {
			...origOpts,
			...(opts ?? {}),
		};

		if (newOpts?.data != null) {
			this._data = newOpts.data;
		}
		if (newOpts.updatedTs != null && newOpts.updatedTs !== origOpts.updatedTs) {
			this._data.lastUpdatedTs = newOpts.updatedTs;
		}

		this._options = newOpts;
	}

	/* #endregion ---- CONSTRUCTOR ----------------------------------------------------------------------------------- */

	/* #region ---- Public ------------------------------------------------------------------------------------------- */

	/**
	 * Whether or not the data collection is empty.
	 */
	public get isEmpty(): boolean {
		return this.list.length === 0;
	}

	/**
	 * Array of wager history data.
	 */
	public get list(): IWagerHistoryEntry[] {
		return this._data.list;
	}

	/**
	 * Length of the wager history data collection.
	 */
	public get size(): number {
		return this.list.length;
	}

	/**
	 * The last time (unix timestamp) that the data was updated.
	 */
	public get lastUpdatedTs(): number {
		return this._data.lastUpdatedTs;
	}
	public set lastUpdatedTs(val: number) {
		this._data.lastUpdatedTs = val;
	}

	/**
	 * Adds a new item to wager history.
	 *
	 * @returns An array containing the new item that was added and the new length of the history.
	 */
	public push(
		seatNumber: number,
		wagerId: string,
		amount: number,
		opts?: Maybe<{ contextId?: Maybe<string>; categoryMemberKey?: Maybe<string> }>
	): [number, IWagerHistoryEntry] {
		const debugMethod = 'push';

		if (seatNumber < 1) {
			throw new Error(debug.makeMessage(`Invalid seat number '${seatNumber}'`, debugMethod));
		}
		if (wagerId == '') {
			throw new Error(debug.makeMessage(`Wager ID must be specified`, debugMethod));
		}
		if (amount < 0) {
			throw new Error(debug.makeMessage(`Amount must not be negative`, debugMethod));
		}

		const newEntry = WagerHistory.newHistoryEntry(seatNumber, wagerId, amount, opts);

		this._data.list.push(newEntry);

		return [this._data.list.length, newEntry];
	}

	// /**
	//  * Is there any remaining history for this seat?
	//  *
	//  * @param seatNum
	//  * @returns {boolean}
	//  */
	// public canUndo(seatNum: number): boolean {
	// 	if (!this.wagers.has(seatNum)) {
	// 		return false;
	// 	}

	// 	let count = 0;

	// 	this.wagers.get(seatNum).history.forEach((wager: WagerHistoryEntry[]) => {
	// 		count += wager.length;
	// 	});

	// 	return count > 0;
	// }

	// /**
	//  * Adds a wager to the history stack.
	//  *
	//  * @param {number} seatNum
	//  * @param {WagerHistoryEntry} wager
	//  * @returns {void}
	//  */
	// public push(seatNum: number, wager: WagerHistoryEntry): void {
	// 	if (!isMySeat(seatNum)) {
	// 		return;
	// 	}

	// 	if (!this.wagers.has(seatNum)) {
	// 		const seat = {
	// 			seatNum: seatNum,
	// 			wagerNames: [wager.wagerName],
	// 			history: new Map<string, WagerHistoryEntry[]>(),
	// 		};

	// 		seat.history.set(wager.wagerName, [wager]);
	// 		this.wagers.set(seatNum, seat);
	// 		return;
	// 	}

	// 	const seat = this.wagers.get(seatNum);

	// 	let history = seat.history.get(wager.wagerName);

	// 	if (history && history.length > 0) {
	// 		history.push(wager);
	// 	} else {
	// 		history = [wager];
	// 	}

	// 	seat.wagerNames.push(wager.wagerName);
	// 	seat.history.set(wager.wagerName, history);
	// 	this.wagers.set(seatNum, seat);
	// }

	// /**
	//  * Gets the wager at the previous wagers index.
	//  *
	//  * @param seatNum
	//  */
	// public undo(seatNum: number): Nullable<WagerHistoryEntry> {
	// 	if (!this.canUndo(seatNum)) {
	// 		return null;
	// 	}

	// 	const seat = this.wagers.get(seatNum);
	// 	const wagerName = seat.wagerNames[seat.wagerNames.length - 1];
	// 	seat.wagerNames.pop();

	// 	const wagers = seat.history.get(wagerName);
	// 	if (!wagers) {
	// 		return null;
	// 	}

	// 	wagers.pop();

	// 	let previous = wagers.length > 0 ? wagers[wagers.length - 1] : null;
	// 	if (!previous) {
	// 		previous = { wagerName, amount: 0 };
	// 	}

	// 	seat.history.set(wagerName, wagers);
	// 	this.wagers.set(seatNum, seat);

	// 	return previous;
	// }

	// /**
	//  * Grabs the last value stored for each wager for the given seat.
	//  *
	//  * @param seatNum
	//  * @returns Map<string, Wager>
	//  */
	// public getSeatWagers(seatNum: number): Map<string, number> {
	// 	const previous: Map<string, number> = new Map<string, number>();

	// 	this.wagers.get(seatNum).history.forEach((wagers: WagerHistoryEntry[], wagerName: string) => {
	// 		if (wagers.length > 0) {
	// 			const last = wagers[wagers.length - 1];

	// 			previous.set(wagerName, last?.amount || 0);
	// 		}
	// 	});

	// 	return previous;
	// }

	// /**
	//  * Returns the total wager amount.
	//  *
	//  * @param wagers Map<string, number> map of wagers by name
	//  * @returns {number} the total amount
	//  */
	// public getWagersTotal(wagers: Map<string, number>): number {
	// 	let total = 0;
	// 	wagers.forEach((amount: number, name: string) => {
	// 		total += amount;
	// 	});

	// 	return total;
	// }

	/**
	 * Resets this class instance back to the initial/clear values.
	 */
	public clear() {
		this._data = WagerHistory.defaultData(Date.now());
	}

	/**
	 * @returns A new clone of this class instance with copied data.
	 */
	public clone(updatedTs?: Maybe<number>): WagerHistory {
		const newData = WagerHistory.copyData(this._data, updatedTs);

		return new WagerHistory({ data: newData, updatedTs });
	}

	/* #region ---- Static ------------------------------------------------------------------------------------------- */

	/**
	 * STATIC
	 * @returns A clone of the specified class instance.
	 */
	public static cloneInstance(from: WagerHistory, updatedTs?: Maybe<number>): WagerHistory {
		return from.clone(updatedTs);
	}

	/**
	 * STATIC
	 * @returns The default data used by this class.
	 */
	public static defaultData(updatedTs?: Maybe<number>): IWagerHistoryData {
		return {
			list: [],
			lastUpdatedTs: updatedTs ?? 0,
		};
	}

	/**
	 * STATIC
	 * @returns The default options data used by this class.
	 */
	public static defaultOptions(): IWagerHistoryOpts {
		return {
			data: null,
			updatedTs: null,
		};
	}

	/**
	 * STATIC
	 * @returns A copy of the specified wager history data.
	 */
	protected static copyData(data: IWagerHistoryData, updatedTs?: Maybe<number>): IWagerHistoryData {
		const newData: IWagerHistoryData = WagerHistory.defaultData();
		newData.lastUpdatedTs = updatedTs ?? data.lastUpdatedTs;
		newData.list = data.list.slice();

		return newData;
	}

	/**
	 * STATIC
	 * @returns A new dirty wager history item with the specified values.
	 */
	public static newHistoryEntry(
		seatNumber: number,
		wagerId: string,
		amount: number,
		opts?: Maybe<{ contextId?: Maybe<string>; categoryMemberKey?: Maybe<string>; createdTs?: Maybe<number> }>
	): IWagerHistoryEntry {
		const createdTs = opts?.createdTs ?? Date.now();
		const wagerHistoryUid = makeUniqId();
		const contextId = opts?.contextId ?? '';
		const categoryMemberKey = opts?.categoryMemberKey ?? '';

		const wagerAltKey = makeActiveWagerKey(wagerId, {
			seatNumber,
			contextId,
			categoryMemberKey,
		});

		const { amount: amountRaw, amountReal } = resolveAmount(amount);

		return {
			seatNumber,
			wagerId,
			contextId,
			categoryMemberKey,
			wagerAltKey,
			wagerHistoryUid,
			amount: amountRaw,
			amountReal,
			createdTs,
		};
	}

	/* #endregion ---- Static ---------------------------------------------------------------------------------------- */

	/* #region ---- Debug -------------------------------------------------------------------------------------------- */

	protected get debugClassLabel(): string {
		return `RpcLib.DataObject.WagerHistory`;
	}

	/* #endregion ---- Debug ----------------------------------------------------------------------------------------- */
}

// ---- Exports -------------------------------------------------------------------------------------------------------

export { WagerHistory as default };
export { WagerHistory };
