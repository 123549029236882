import { AnnotationsMap } from 'mobx';
import { DebugBaseMobXAnnotations } from '../shared';
import { EventDispatcherBase } from './EventDispatcherBase';
import { EventDispatcherBaseMobXPrivateProps } from './types.mobx';

const EventDispatcherBaseMobXAnnotations: AnnotationsMap<EventDispatcherBase, EventDispatcherBaseMobXPrivateProps> = {
	// Inherit from DebugBase
	...DebugBaseMobXAnnotations,

	// ---- Excluded -----------------------------------------------------

	// >>> Props
	_eventManager: false,
};

// ---- Exports -------------------------------------------------------------------------------------------------------

export { EventDispatcherBaseMobXAnnotations };
