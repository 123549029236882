import { action, AnnotationsMap, computed, makeObservable } from 'mobx';
import { DataStoreMobXAnnotations } from '../DataStore/mobx';
import { UserStoreMobXPrivateProps } from './types.mobx';
import UserStore from './UserStore';

/**
 * MobX annotations for `UserStore`.
 */
const UserStoreMobXAnnotations: AnnotationsMap<UserStore, UserStoreMobXPrivateProps> = {
	// Inherit from DataStore
	...DataStoreMobXAnnotations,

	// ---- Computed -----------------------------------------------------

	// >>> Public
	playerId: computed,
	displayName: computed,
	balancesList: computed,
	profile: computed,
	isLoggedIn: computed,

	// ---- Actions ------------------------------------------------------

	// >>> Protected
	fetchPopulateData: action,
};

/**
 * Makes the specified `UserStore` instance an observable MobX object.
 */
const bindUserStoreMobX = (instance: UserStore): boolean => {
	if (instance.isMobXBound) {
		return false;
	}

	makeObservable<UserStore, UserStoreMobXPrivateProps>(instance, {
		...UserStoreMobXAnnotations,
	});

	instance.isMobXBound = true;

	return true;
};

// ---- Exports -------------------------------------------------------------------------------------------------------

export { UserStoreMobXAnnotations, bindUserStoreMobX };
