import trim from 'lodash/trim';
import { REACT_APP_PREFIX } from './constants';

abstract class ConfigAdapterBase {
	/**
	 * Must implement. Returns an object containing ALL config vars.
	 */
	abstract get resolvedProps(): PlainObject;

	public get resolvedVars(): PlainObject {
		return this.resolvedProps;
	}

	public get globalConfig(): Record<string, unknown> {
		return (window?.config || {}) as Record<string, unknown>;
	}

	public get envConfig(): Record<string, unknown> {
		// @ts-ignore
		window.process = globalThis.process ?? { env: {} };
		return (process ? process.env : null) || {};
	}

	public getGlobalVar(key: string): unknown {
		return this.getObjVar(this.globalConfig, key);
	}

	public getEnvVar(key: string): unknown {
		return this.getObjVar(this.envConfig, key);
	}

	public getSessionVar(key: string): Nullable<string> {
		return this.sessionStorage.getItem(key);
	}

	public getObjVar(obj: Record<string, unknown>, key: string): Nullable<unknown> {
		const name = this.normalizeVarName(key);

		return obj[name] ?? obj[`${REACT_APP_PREFIX}${name.toLocaleUpperCase()}`] ?? null;
	}

	public getVar(key: string): Nullable<unknown> {
		return this.getSessionVar(key) ?? this.getEnvVar(key) ?? this.getGlobalVar(key) ?? null;
	}

	public getStringVar(key: string, defaultVal: string = ''): string {
		const val = (this.getVar(key) ?? defaultVal) as string;

		return trim(val);
	}

	public getBoolVar(key: string): boolean {
		const val = this.getStringVar(key, '0').toLocaleUpperCase();

		return ['1', 'ON', 'YES', 'Y'].includes(val);
	}

	protected normalizeVarName(name: string): string {
		name = name.trim();

		return name.startsWith(REACT_APP_PREFIX) ? name.replace(REACT_APP_PREFIX, '').toLocaleUpperCase() : name;
	}

	protected get sessionStorage(): Storage {
		return globalThis.sessionStorage;
	}
}

// ---- Export --------------------------------------------------------------------------------------------------------

export { ConfigAdapterBase as default };
export { ConfigAdapterBase };
