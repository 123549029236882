/**********************************************************************************************************************
 * Stores and manages data regarding the current authenticated user/player.
 *********************************************************************************************************************/
import { IPlayerArtifactData, IPlayerRewardData, IGetSelfReplyData as ISelfData } from '../../client/rpc';
import { IBalanceData, IProfileData } from '../../client/rpc';
import { IGetSelfRequestFlags, IUserService } from '../../client/service/types';
import { IToJsOpts } from '../../helpers';
import { DataStore } from '../DataStore';
import { IUserStoreOpts } from './types';

class UserStore extends DataStore<IUserService, ISelfData> {
	/* #region ---- Properties --------------------------------------------------------------------------------------- */

	/**
	 * Currently assigned options.
	 */
	protected override _options: IUserStoreOpts = UserStore.defaultOptions();

	/* #endregion ---- Properties ------------------------------------------------------------------------------------ */

	/* #region ---- CONSTRUCTOR ---------------------------------------------------------------------------------------*/

	constructor(service?: Maybe<IUserService>, opts?: Maybe<IUserStoreOpts>) {
		super(service);

		opts != null && this.setOptions(opts);
	}

	/* #endregion ---- CONSTRUCTOR ----------------------------------------------------------------------------------- */

	/* #region ---- Public ------------------------------------------------------------------------------------------- */

	/**
	 * Sets/initializes the class options.
	 *
	 * - Overrides the parent class method.
	 */
	public override setOptions(opts: IUserStoreOpts) {
		const { newOpts } = this.resolveOptions(opts);
		this._options = newOpts;
		this.onSetOptions(newOpts);
	}

	/**
	 * @returns Unique player ID for the current user.
	 */
	public get playerId(): string {
		return this.data?.playerId ?? '';
	}

	/**
	 * Raw list of balance entries (one per currency) for the current user.
	 */
	public get balancesList(): IBalanceData[] {
		return this.data?.balances ?? [];
	}

	/**
	 * Various preferences associated with the current user.
	 */
	public get profile(): Nullable<IProfileData> {
		return this.data?.profile ?? null;
	}

	/**
	 * Display name associated with the current user.
	 */
	public get displayName(): string {
		return this.profile?.displayName ?? '';
	}

	/**
	 * Rare artifacts from another world..?
	 */
	public get artifacts(): IPlayerArtifactData[] {
		return this.data?.artifacts ?? [];
	}

	/**
	 * Quest rewards for killing 9999 slimes..?
	 */
	public get rewards(): IPlayerRewardData[] {
		return this.data?.rewards ?? [];
	}

	/**
	 * Recent play IDs the user has participated in.
	 */
	public get recentPlayIds(): string[] {
		return this.data?.recentPlayIds ?? [];
	}

	/**
	 * @returns TRUE if the current user is considered to be logged-in and valid.
	 */
	public get isLoggedIn(): boolean {
		return this.playerId.trim() !== '';
	}

	/**
	 * @returns A JSON export of the current pertinent data.
	 */
	public override toJson(extended?: Maybe<boolean>): PlainObject {
		extended = extended ?? false;

		const toJs = (val: unknown, opts?: Maybe<IToJsOpts>) => this.toJs(val, extended, { ...opts });

		const result: PlainObject = {
			playerId: this.playerId,
			balancesList: toJs(this.balancesList),
			profile: toJs(this.profile),
			displayName: this.displayName,
			artifacts: toJs(this.artifacts),
			rewards: toJs(this.rewards),
			recentPlayIds: toJs(this.recentPlayIds),
			isLoggedIn: this.isLoggedIn,
		};

		if (extended) {
			result.this = toJs(this, { useToJson: false });
			result['_DataStore'] = {
				...super.toJson(extended),
			};
		}

		return result;
	}

	/**
	 * Action. Populates the store (via unary RPC service call) using the specified flags.
	 */
	public override async populate(flags?: Maybe<IGetSelfRequestFlags>): Promise<boolean> {
		return await super.populate(flags);
	}

	/* #endregion ---- Public ---------------------------------------------------------------------------------------- */

	/* #region ---- Protected ---------------------------------------------------------------------------------------- */

	/**
	 * Gets data from the associated service in order to populate the store.
	 *
	 * @returns The underlying data needed to populate this store.
	 */
	protected async fetchPopulateData(flags?: Maybe<IGetSelfRequestFlags>): Promise<Nullable<ISelfData>> {
		if (!this._service) {
			this.error('No service was specified', 'fetchPopulateData');
			return null;
		}

		return this._service.getSelf(flags).promise;
	}

	/**
	 * Resolves the options being passed in and returns the original and new options.
	 *
	 * - Overrides the parent class method.
	 */
	protected override resolveOptions(opts?: Maybe<IUserStoreOpts>) {
		const origOpts: IUserStoreOpts = {
			...UserStore.defaultOptions(),
			...this._options,
		};

		const newOpts: IUserStoreOpts = {
			...origOpts,
			...(opts ?? {}),
		};

		return { origOpts, newOpts };
	}

	/**
	 * Extends the parent class method.
	 * Called after new options are set.
	 */
	protected override onSetOptions(newOpts: IUserStoreOpts) {
		super.onSetOptions(newOpts);

		if (newOpts.data != null) {
			this._data = newOpts.data;
		}
	}

	/* #endregion ---- Protected ------------------------------------------------------------------------------------- */

	/* #region ---- Static ------------------------------------------------------------------------------------------- */

	/**
	 * STATIC
	 * @returns The default options data used by this class.
	 */
	public static defaultOptions(): IUserStoreOpts {
		return {
			...DataStore.defaultOptions(),
			data: null,
		};
	}

	/* #endregion ---- Static ---------------------------------------------------------------------------------------- */

	/* #region ---- Debug -------------------------------------------------------------------------------------------- */

	/**
	 * Overrides the base class method.
	 *
	 * @returns The label to use when debugging.
	 */
	protected override get debugClassLabel(): string {
		return UserStore.debugClassLabel();
	}

	/**
	 * STATIC
	 * @returns Label assigned to this class namespace.
	 */
	protected static debugClassLabel(): string {
		return `RpcLib.Store.UserStore`;
	}

	/* #endregion ---- Debug ----------------------------------------------------------------------------------------- */
}

// ---- Exports -------------------------------------------------------------------------------------------------------

export { UserStore as default };
export { UserStore };
