import { EventDispatcherBaseEvents } from '../../common/EventDispatcherBase';

const SdkEvents: Record<string, string> = {
	...EventDispatcherBaseEvents,
	SET_ACTIVE_PLAYER: 'SET_ACTIVE_PLAYER',
	SET_ACTIVE_TABLE: 'SET_ACTIVE_TABLE',
	SET_ACTIVE_PLAY: 'SET_ACTIVE_PLAY',
};

enum SdkGameKey {
	AR = 'roulette_american',
	CR = 'roulette_chateau',
	BACC = 'baccarat',
	ER = 'roulette_european',
	FADT = 'dragon_tiger',
	FABJ = 'blackjack_fastaction',
	MSBJ = 'blackjack_multiseat',
	SICBO = 'sic_bo',
}

enum SdkDeviceType {
	SHOE = 'shoe',
	WHEEL = 'wheel',
}

// ---- Export --------------------------------------------------------------------------------------------------------

export { SdkGameKey, SdkDeviceType, SdkEvents };
