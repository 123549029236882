import omit from 'lodash/omit';
import {
	extendPlaySeatData,
	IExtendPlaySeatDataOpts,
	IPlaySeatData,
	IPlaySeatDataExt,
	newEmptyPlaySeatDataExt,
	PlaySeatsList,
} from './playSeatExt';
import { extendTableSeatData, newEmptyTableSeatDataExt } from './tableSeatExt';
import { ICombinedSeatDataExt, ICreateCombinedSeatsDataOpts, INewCombinedSeatDataOpts } from './types/combinedSeatExt';
import { IExtendTableSeatDataOpts, ITableSeatData, ITableSeatDataExt, TableSeatsList } from './types/tableSeatExt';

/**
 * @returns An empty `ICombinedSeatDataExt` object with default values.
 */
const newEmptyCombinedSeatDataExt = <PlayerGameStateItemType = unknown>(
	opts?: Maybe<INewCombinedSeatDataOpts>
): ICombinedSeatDataExt<PlayerGameStateItemType> => {
	const emptyTableSeatDataExt = newEmptyTableSeatDataExt(opts);
	const emptyPlaySeatDataExt = newEmptyPlaySeatDataExt<PlayerGameStateItemType>(opts);

	return {
		...emptyTableSeatDataExt,
		...emptyPlaySeatDataExt,
		raw: null,
	};
};

/**
 * @returns An array of `ICombinedSeatDataExt` objects created from the specified `tableSeats` and `playSeats` lists.
 */
const createCombinedSeatsList = <PlayerGameStateItemType = unknown>(
	tableSeats: TableSeatsList,
	playSeats: PlaySeatsList<PlayerGameStateItemType>,
	opts?: Maybe<ICreateCombinedSeatsDataOpts>
): ICombinedSeatDataExt<PlayerGameStateItemType>[] => {
	const expectedSeatCount = Math.max(opts?.expectedSeatCount ?? 0, 0);
	const playId = opts?.playId ?? null;
	const tableId = opts?.tableId ?? null;
	const keepRawData = opts?.keepRawData ?? false;

	const tableSeatsExt = resolveVariantTableSeatsList(tableSeats, {
		...opts?.extendTableSeatOpts,
		tableId,
	});

	const playSeatsExt = resolveVariantPlaySeatsList<PlayerGameStateItemType>(playSeats, {
		...opts?.extendPlaySeatOpts,
		typeRegistry: opts?.typeRegistry,
		tableId,
		playId,
	});

	const fillSize = Math.max(tableSeatsExt.length, playSeatsExt.length, expectedSeatCount);

	if (fillSize === 0) {
		return [];
	}

	const emptyData = newEmptyCombinedSeatDataExt<PlayerGameStateItemType>({ playId, tableId });

	let result: ICombinedSeatDataExt<PlayerGameStateItemType>[] = new Array<
		ICombinedSeatDataExt<PlayerGameStateItemType>
	>(fillSize).fill(emptyData);

	result = result.map((entry, i) => {
		return {
			...entry,
			seatNumber: i + 1,
			raw: keepRawData ? { table: null, play: null } : null,
		};
	});

	if (tableSeatsExt.length + playSeatsExt.length === 0) {
		return result;
	}

	if (tableSeatsExt.length > 0) {
		tableSeatsExt.sort((a, b) => a.seatNumber - b.seatNumber);

		tableSeatsExt.forEach((tsEntry: ITableSeatDataExt, i) => {
			const tsData: Omit<ITableSeatDataExt, 'raw'> = omit({ ...tsEntry }, ['raw']);
			const seatNumber = tsData.seatNumber || i + 1;
			const idx = seatNumber - 1;
			if (idx < 0) {
				return;
			}

			const currentData = result[idx] ?? null;

			const combined: ICombinedSeatDataExt<PlayerGameStateItemType> = {
				...currentData,
				...tsData,
			};

			if (keepRawData) {
				combined.raw = { table: { ...tsEntry }, play: currentData?.raw?.play ?? null };
			}

			result[idx] = combined;
		});
	}

	if (playSeatsExt.length > 0) {
		playSeatsExt.sort((a, b) => a.seatNumber - b.seatNumber);

		playSeatsExt.forEach((psEntry: IPlaySeatDataExt<PlayerGameStateItemType>, i) => {
			const psData: Omit<IPlaySeatDataExt<PlayerGameStateItemType>, 'raw'> = omit({ ...psEntry }, ['raw']);
			const seatNumber = psData.seatNumber || i + 1;
			const idx = seatNumber - 1;
			if (idx < 0) {
				return;
			}

			const currentData = result[idx] ?? null;

			const combined: ICombinedSeatDataExt<PlayerGameStateItemType> = {
				...currentData,
				...psData,
			};

			if (keepRawData) {
				combined.raw = { table: currentData?.raw?.table ?? null, play: { ...psEntry } };
			}

			result[idx] = combined;
		});
	}

	return result;
};

/**
 * @returns The specified variant list type converted to a array of `ITableSeatDataExt` object.
 */
const resolveVariantTableSeatsList = (
	list: TableSeatsList,
	opts?: Maybe<IExtendTableSeatDataOpts>
): ITableSeatDataExt[] => {
	if (list.length === 0) {
		return [] as ITableSeatDataExt[];
	}

	if ('raw' in list[0]) {
		return list as ITableSeatDataExt[];
	}

	const result: ITableSeatDataExt[] = [];

	list.forEach((item: ITableSeatDataExt | ITableSeatData) => {
		if ('raw' in item) {
			const itemExt: ITableSeatDataExt = item;
			itemExt.tableId = opts?.tableId ?? itemExt.tableId;
			itemExt.seatNumber = opts?.seatNumber ?? itemExt.seatNumber;
			result.push(itemExt);
			return;
		}

		// eslint-disable-next-line @typescript-eslint/no-unnecessary-type-assertion
		const itemExt: ITableSeatDataExt = extendTableSeatData(item as ITableSeatData, opts);
		result.push(itemExt);
	});

	return result;
};

/**
 * @returns The specified variant list type converted to a array of `IPlaySeatDataExt` object.
 */
const resolveVariantPlaySeatsList = <PlayerGameStateItemType = unknown>(
	list: PlaySeatsList<PlayerGameStateItemType>,
	opts?: Maybe<IExtendPlaySeatDataOpts>
): IPlaySeatDataExt<PlayerGameStateItemType>[] => {
	if (list.length === 0) {
		return [] as IPlaySeatDataExt<PlayerGameStateItemType>[];
	}

	if ('raw' in list[0]) {
		return list as IPlaySeatDataExt<PlayerGameStateItemType>[];
	}

	const result: IPlaySeatDataExt<PlayerGameStateItemType>[] = [];

	list.forEach((item: IPlaySeatDataExt<PlayerGameStateItemType> | IPlaySeatData) => {
		if ('raw' in item) {
			const itemExt: IPlaySeatDataExt<PlayerGameStateItemType> = item;
			itemExt.playId = opts?.playId ?? itemExt.playId;
			itemExt.tableId = opts?.tableId ?? itemExt.tableId;
			itemExt.seatNumber = opts?.seatNumber ?? itemExt.seatNumber;
			result.push(itemExt);
			return;
		}

		const itemExt: IPlaySeatDataExt<PlayerGameStateItemType> = extendPlaySeatData<PlayerGameStateItemType>(item, opts);
		result.push(itemExt);
	});

	return result;
};

// ---- Export --------------------------------------------------------------------------------------------------------

export { newEmptyCombinedSeatDataExt };
export { createCombinedSeatsList };

// Other
export * from './types/combinedSeatExt';
