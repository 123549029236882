import omit from 'lodash/omit';
import { ActiveWagerResult } from '../../client/rpc';
import { resolveAmounts } from '../amounts';
import { filterNullUndefined } from '../shared';
import { resolvePlaySeatAssignmentsLookupToMap } from './playSeatExt';
import {
	IActiveWagerData,
	IActiveWagerDataExt,
	IActiveWagerDataFullDef,
	IExtendActiveWagerDataFullDefOpts,
	IExtendActiveWagerDataOpts,
} from './types/activeWagersExt';
import { IAvailableWagerDataExt } from './types/availableWagersExt';
import { IPlayWagerDefinitionDataExt } from './types/playWagerDefinitionExt';
import { makeActiveWagerKey, makeAvailableWagerKey, normalizeContextId } from './utility';

/**
 * @returns An empty `IActiveWagerData` object with default values.
 */
const newEmptyActiveWagerData = (): IActiveWagerData => ({
	amount: BigInt(0),
	contextId: '',
	createdAt: BigInt(0),
	currency: '',
	key: '',
	sequence: BigInt(0),
	wagerKey: '',
	wagerTypeId: '',
	result: ActiveWagerResult.Pending,
	processedAt: BigInt(0),
});

/**
 * @returns An empty `IActiveWagerDataExt` object with default values.
 */
const newEmptyActiveWagerDataExt = (
	opts?: Maybe<{ playId?: Maybe<string>; seatNumber?: Maybe<number>; seatId?: Maybe<string> }>
): IActiveWagerDataExt => ({
	activeWagerId: '',
	activeWagerKey: '',
	amount: 0,
	amountMoney: '',
	amountReal: 0,
	availableWagerKey: '',
	categoryMemberKey: '',
	contextId: '',
	createdTs: 0,
	currencyCode: '',
	currencySymbol: '',
	playId: opts?.playId ?? '',
	processedTs: 0,
	raw: null,
	result: ActiveWagerResult.Pending,
	seatId: opts?.seatId ?? '',
	seatNumber: opts?.seatNumber ?? 1,
	sequence: 0,
	wagerId: '',
});

/**
 * @returns Extended version of `IActiveWagerData` with re-mapped types, props and added fields.
 */
const extendActiveWagerData = (
	src: IActiveWagerData,
	opts?: Maybe<IExtendActiveWagerDataOpts>
): IActiveWagerDataExt => {
	const base = omit(src, ['wagerTypeId', 'amount', 'currency', 'sequence', 'createdAt', 'key', 'wagerKey']);

	const applyCurrencyCode = opts?.currencyCode || src.currency || '';
	const formatCurrencyOpts = opts?.formatCurrencyOpts ?? null;

	const {
		amount,
		amountReal,
		amountMoney,
		currencyCode, // Currency actually used for amounts.
		currencySymbol, // Currency symbol actually used for amounts.
	} = resolveAmounts({ amount: Number(src.amount) }, { formatCurrencyOpts, currencyCode: applyCurrencyCode });

	const seatNumber = opts?.seatNumber ?? 1;
	const wagerId = src.wagerTypeId ?? '';
	const contextId = normalizeContextId(src.contextId);
	const categoryMemberKey = src.key ?? '';

	const availableWagerKey = makeAvailableWagerKey(wagerId, { contextId, categoryMemberKey });
	const activeWagerKey = makeActiveWagerKey(wagerId, { seatNumber, contextId, categoryMemberKey });

	return {
		...newEmptyActiveWagerDataExt(),
		...filterNullUndefined(base),
		raw: { ...src },

		activeWagerId: src.wagerKey,
		activeWagerKey,
		amount,
		amountMoney,
		amountReal,
		availableWagerKey,
		categoryMemberKey,
		contextId,
		createdTs: Number(src.createdAt),
		currencyCode,
		currencySymbol,
		playId: opts?.playId ?? '',
		processedTs: Number(src.processedAt),
		result: src.result,
		seatId: opts?.seatId ?? '',
		seatNumber,
		sequence: Number(src.sequence),
		wagerId,
	};
};

/**
 * @returns The correct name value for the specified active wager.
 */
const resolveActiveWagerName = (
	awdExt: IActiveWagerDataExt,
	avail?: Maybe<IAvailableWagerDataExt>,
	def?: Maybe<IPlayWagerDefinitionDataExt>
): string => {
	const availName = avail?.name ?? '';
	if (availName !== '') {
		return availName;
	}

	const categoryMemberKey = awdExt.categoryMemberKey;
	const isCategoryWager = categoryMemberKey !== '';
	const defName = def?.name ?? '';

	if (!isCategoryWager || defName === '') {
		return defName || awdExt.availableWagerKey;
	}

	const categoryName = defName;

	const categoryMemberKeyParts = categoryMemberKey.split(':');
	if (categoryMemberKeyParts.length === 1) {
		return `${categoryName}:${categoryMemberKey}`;
	}

	const keyPart = categoryMemberKeyParts.slice(1).join(':');

	return `${categoryName}:${keyPart}`;
};

/**
 * @returns Complete extended version of `IActiveWagerData` with wager definition data.
 */
const extendActiveWagerDataFullDef = (
	awd: IActiveWagerData | IActiveWagerDataExt,
	opts?: Maybe<IExtendActiveWagerDataFullDefOpts>
): IActiveWagerDataFullDef => {
	const awdExt = resolveVariantActiveWagerData(awd, opts);

	const avail = opts?.avail ?? null;
	const def = opts?.def ?? avail?.def ?? null;

	if (avail && avail.availableWagerKey !== awdExt.availableWagerKey) {
		throw new Error("Available wager key doesn't match active available wager key.");
	}

	const name = resolveActiveWagerName(awdExt, avail, def);
	const availableWagerKey = avail?.availableWagerKey || awdExt.availableWagerKey;

	// Attempt to resolve the ACTUAL seat details from the active wager context ID - important for multi-seat games
	const playSeatBySeatIdLookup = resolvePlaySeatAssignmentsLookupToMap(opts?.playSeatAssignments ?? null);
	const playSeat = (awdExt.contextId !== '' ? playSeatBySeatIdLookup.get(awdExt.contextId) : null) ?? null;
	if (playSeat != null) {
		awdExt.seatNumber = playSeat.seatNumber;
		awdExt.seatId = playSeat.seatId;
		awdExt.activeWagerKey = makeActiveWagerKey(awdExt.activeWagerId, {
			seatNumber: awdExt.seatNumber,
			contextId: awdExt.contextId,
			categoryMemberKey: awdExt.categoryMemberKey,
		});
	}

	return {
		...awdExt,
		avail,
		def,
		availableWagerKey,
		name,
	};
};

/**
 * @returns The specified variant type converted to a `IActiveWagerDataExt` object.
 */
const resolveVariantActiveWagerData = (
	awd: IActiveWagerData | IActiveWagerDataExt,
	opts?: Maybe<IExtendActiveWagerDataOpts>
): IActiveWagerDataExt => {
	if ('raw' in awd) {
		// eslint-disable-next-line @typescript-eslint/no-unnecessary-type-assertion
		return awd as IActiveWagerDataExt;
	}

	return extendActiveWagerData(awd, opts);
};

// ---- Exports -------------------------------------------------------------------------------------------------------

export { extendActiveWagerData };
export { extendActiveWagerDataFullDef };
export { newEmptyActiveWagerData, newEmptyActiveWagerDataExt };

// Other
export * from './types/activeWagersExt';
