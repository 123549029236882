import { filterNullUndefined } from '../../../helpers';
import { DeviceStore, IPlayStore } from '../../../store';
import { PlayStore, SettingsStore, TableStore, UserStore } from '../../../store';
import { IServices } from '../../services';
import { StoreFactory } from './StoreFactory';
import {
	IStoreFactoryNewPlayStoreOpts,
	IStoreFactoryNewSettingsStoreOpts,
	IStoreFactoryNewTableStoreOpts,
	IStoreFactoryNewUserStoreOpts,
} from './types';

/**
 * Store factory class that creates stores with the default settings.
 */
class DefaultStoreFactory {
	/**
	 * @returns A new `UserStore` instance with the standard settings.
	 */
	public static newUserStore(services: IServices, opts?: Maybe<IStoreFactoryNewUserStoreOpts>): UserStore {
		const defaultOpts: IStoreFactoryNewUserStoreOpts = { useMobX: true, ...filterNullUndefined(opts ?? {}) };

		return StoreFactory.newUserStore(services.userService, defaultOpts);
	}

	/**
	 * @returns A new `TableStore` instance with the standard settings.
	 */
	public static newTableStore(services: IServices, opts?: Maybe<IStoreFactoryNewTableStoreOpts>): TableStore {
		const defaultOpts: IStoreFactoryNewTableStoreOpts = { useMobX: true, ...filterNullUndefined(opts ?? {}) };

		return StoreFactory.newTableStore(services.gameService, defaultOpts);
	}

	/**
	 * @returns A new `PlayStore` instance with the standard settings.
	 */
	public static newPlayStore<PlayStoreType extends IPlayStore = PlayStore>(
		services: IServices,
		opts?: Maybe<IStoreFactoryNewPlayStoreOpts<PlayStoreType>>
	): PlayStoreType {
		const defaultOpts: IStoreFactoryNewPlayStoreOpts<PlayStoreType> = {
			useMobX: true,
			...filterNullUndefined(opts ?? {}),
		};

		return StoreFactory.newPlayStore<PlayStoreType>(services.gameService, defaultOpts);
	}

	/**
	 * @returns A new `SettingsStore` instance with the standard settings.
	 */
	public static newSettingsStore(opts?: Maybe<IStoreFactoryNewSettingsStoreOpts>): SettingsStore {
		const defaultOpts: IStoreFactoryNewSettingsStoreOpts = { useMobX: true, ...filterNullUndefined(opts ?? {}) };

		return StoreFactory.newSettingsStore(defaultOpts);
	}

	/**
	 * @returns A new `DeviceStore` instance with the standard settings.
	 */
	public static newDeviceStore(services: IServices, opts?: Maybe<IStoreFactoryNewSettingsStoreOpts>): DeviceStore {
		const defaultOpts: IStoreFactoryNewSettingsStoreOpts = { useMobX: true, ...filterNullUndefined(opts ?? {}) };

		return StoreFactory.newDeviceStore(services.deviceService, defaultOpts);
	}
}

// ---- Exports -------------------------------------------------------------------------------------------------------

export { DefaultStoreFactory as default };
export { DefaultStoreFactory };
