import omit from 'lodash/omit';
import { filterNullUndefined } from '../shared';
import {
	IExtendTableSeatDataOpts,
	INewTableSeatDataOpts,
	ITableSeatData,
	ITableSeatDataExt,
} from './types/tableSeatExt';

/**
 * @returns An empty `ITableSeatData` object with default values.
 */
const newEmptyTableSeatData = (): ITableSeatData => ({
	displayName: '',
	imageUrl: '',
	open: false,
	playerId: '',
	seatNum: BigInt(0),
});

/**
 * @returns An empty `ITableSeatDataExt` object with default values.
 */
const newEmptyTableSeatDataExt = (opts?: Maybe<INewTableSeatDataOpts>): ITableSeatDataExt => ({
	raw: null,
	isOpen: false,
	playerDisplayName: '',
	playerId: '',
	playerImageUrl: '',
	seatNumber: opts?.seatNumber ?? 0,
	tableId: opts?.tableId ?? '',
});

/**
 * @returns {ITableSeatDataExt} Extended version of `ITableSeatData` with re-mapped types, props.
 */
const extendTableSeatData = (src: ITableSeatData, opts?: Maybe<IExtendTableSeatDataOpts>): ITableSeatDataExt => {
	const base = omit(src, ['seatNum', 'open', 'displayName', 'imageUrl']);

	return {
		...newEmptyTableSeatDataExt(),
		...filterNullUndefined(base),
		raw: { ...src },
		isOpen: src.open,
		playerDisplayName: src.displayName,
		playerId: src.playerId,
		playerImageUrl: src.imageUrl,
		seatNumber: opts?.seatNumber ?? Number(src.seatNum),
		tableId: opts?.tableId ?? '',
	};
};

/**
 * @returns {ITableSeatDataExt[]} Extended version of `ITableSeatData[]` with re-mapped types, props.
 */
const extendTableSeatDataList = (
	srcList: ITableSeatData[],
	opts?: Maybe<IExtendTableSeatDataOpts>
): ITableSeatDataExt[] => {
	const result: ITableSeatDataExt[] = [];

	for (const src of srcList) {
		result.push(extendTableSeatData(src, opts));
	}

	return result;
};

// ---- Export --------------------------------------------------------------------------------------------------------

export { extendTableSeatData, extendTableSeatDataList };
export { newEmptyTableSeatData, newEmptyTableSeatDataExt };

// Other
export * from './types/tableSeatExt';
