import isFunction from 'lodash/isFunction';
import { toJS as mobXToJs } from 'mobx';
import { IMaybeCustomJsonDeserializer, IToJsOpts } from './types';

/**
 * @returns The specified value as a JSON object.
 */
const toJs = (
	val: unknown,
	opts?: Maybe<IToJsOpts>
): Nullable<PlainObject | Array<unknown> | Map<unknown, unknown> | Set<unknown>> => {
	const forceObject = opts?.forceObject ?? false;
	const useMobXToJs = opts?.useMobXToJs ?? false;
	const useToJson = opts?.useToJson ?? true;

	if (val == null) {
		return forceObject ? ({} as PlainObject) : null;
	}

	if (useToJson === true) {
		const valD = val as IMaybeCustomJsonDeserializer;
		if (valD['toJson'] != null && isFunction(valD['toJson'])) {
			return valD.toJson(opts?.extended);
		}
	}

	if (useMobXToJs === true) {
		return mobXToJs(val) as PlainObject;
	}

	if (val instanceof Map || val instanceof Set) {
		return forceObject ? (Object.fromEntries(val) as PlainObject) : val;
	}

	if (Array.isArray(val)) {
		if (forceObject) {
			return val.reduce((acc: PlainObject, curr: unknown, i) => ({ ...acc, [i]: curr }), {}) as PlainObject;
		}

		return (val as unknown[]).slice();
	}

	return JSON.parse(JSON.stringify(val)) as PlainObject;
};

// ---- Exports -------------------------------------------------------------------------------------------------------

export { toJs };
