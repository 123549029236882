/**
 * @returns A hash code for the given string.
 */
const strToHashCode = (val: string): number => {
	let hash = 0;
	if (val.length == 0) {
		return hash;
	}

	for (let i = 0, len = val.length; i < len; i++) {
		const charCode = val.charCodeAt(i);
		hash = (hash << 5) - hash + charCode;
		hash = hash & hash;
	}
	return hash;
};

/**
 * @returns A combined hash code for the given hash codes.
 */
const combineHashCodes = (...hashCodes: number[]): number => {
	let combinedHash = 17;

	for (const code of hashCodes) {
		combinedHash = (combinedHash * 31 + code) % 0xffffffff;
	}

	return combinedHash >>> 0;
};

// ---- Exports -------------------------------------------------------------------------------------------------------

export { strToHashCode, combineHashCodes };
