import {
	IActiveWagerData,
	IActiveWagerDataEntry,
	IMethodResolveAmountsOpts,
	IActiveWagerData as IPendingWagerData,
	IActiveWagerData as IRejectedWagerData,
} from '../../../../helpers';
import { copyAnyObjectList, entries, makeUniqId } from '../../../../helpers';
import { generateObjectListHashId } from '../../../../helpers/data/utility';
import { IMethodGetWagerIdsResult, IMethodGetWagerNamesResult } from '../../types';
import { IWagerData } from '../../types';
import { newWagerData } from '../../utility';
import {
	IServerPlayerWagerStateData,
	IServerPlayerWagerStateDataEntry,
	IServerPlayerWagerStateDataRetrievers,
	RawServerPlayerWagerStateData,
	ServerPlayerWagerStateDataList,
	ServerPlayerWagerStateDataLookup,
} from './types.main';

/**
 * @returns The default data used by the `ServerPlayerWagerState` class.
 */
const defaultData = (opts?: Maybe<{ updatedTs?: Maybe<number> }>): IServerPlayerWagerStateData => {
	return {
		raw: null,
		lookup: new Map<string, IServerPlayerWagerStateDataEntry>(),
		lastUpdatedTs: opts?.updatedTs ?? 0,
		uniqId: makeUniqId(),
		hashId: '',
	};
};

/**
 * @returns The default data retrievers used by the `ServerPlayerWagerState` class.
 */
const defaultDataRetrievers = (): IServerPlayerWagerStateDataRetrievers => {
	return {
		playId: () => '',
		availableWagersList: () => [],
		wagerDefinitionsList: () => [],
		playSeatAssignmentsList: () => [],
		resolveAmountsOpts: () => ({}),
	};
};

/**
 * @returns A unique hash ID for the specified raw data.
 */
const generateRawDataHashId = (data: RawServerPlayerWagerStateData): string => {
	const activeWagersList = (data.activeWagers ?? []) as IActiveWagerData[];
	const pendingWagersList = (data.pendingWagers ?? []) as IPendingWagerData[];
	const rejectedWagersList = (data.rejectedWagers ?? []) as IRejectedWagerData[];

	const checkProps: (keyof IActiveWagerData)[] = [
		'amount',
		'contextId',
		'currency',
		'result',
		'sequence',
		'wagerKey',
		'wagerTypeId',
	];

	const hashIds: string[] = [];

	hashIds.push(generateObjectListHashId<IActiveWagerData>(activeWagersList, checkProps));
	hashIds.push(generateObjectListHashId<IPendingWagerData>(pendingWagersList, checkProps));
	hashIds.push(generateObjectListHashId<IRejectedWagerData>(rejectedWagersList, checkProps));

	return hashIds.join('|');
};

/**
 * @returns A unique hash ID for the specified data list.
 */
const generateDataListHashId = (list: ServerPlayerWagerStateDataList): string => {
	return generateObjectListHashId<IServerPlayerWagerStateDataEntry>(list, [
		'activeWagerId',
		'amount',
		'availableWagerKey',
		'contextId',
		'currencyCode',
		'name',
		'seatNumber',
		'sequence',
		'state',
		'wagerId',
	]);
};

/**
 * @returns A unique hash ID for the specified data list.
 */
const generateDataLookupHashId = (lookup: ServerPlayerWagerStateDataLookup): string => {
	const list = Array.from(lookup.values());

	return generateDataListHashId(list);
};

/**
 * @returns A copy of the specified raw data list.
 */
const copyRawData = (data: RawServerPlayerWagerStateData): RawServerPlayerWagerStateData => {
	return { ...data };
};

/**
 * @returns A copy of the specified data list.
 */
const copyList = (list: ServerPlayerWagerStateDataList): ServerPlayerWagerStateDataList => {
	return copyAnyObjectList<IServerPlayerWagerStateDataEntry>(list);
};

/**
 * @returns A copy of the specified data lookup.
 */
const copyLookup = (lookup: ServerPlayerWagerStateDataLookup): ServerPlayerWagerStateDataLookup => {
	const copy = new Map<string, IServerPlayerWagerStateDataEntry>();
	for (const [key, value] of lookup) {
		copy.set(key, { ...value });
	}

	return copy;
};

/**
 * @returns A copy of the specified encapsulated data.
 */
const copyData = (
	data: IServerPlayerWagerStateData,
	opts?: Maybe<{ updatedTs?: Maybe<number> }>
): IServerPlayerWagerStateData => {
	const newData: IServerPlayerWagerStateData = defaultData();
	newData.lastUpdatedTs = opts?.updatedTs ?? data.lastUpdatedTs;
	newData.lookup = copyLookup(data.lookup);
	newData.hashId = data.hashId;
	newData.raw = data.raw != null ? { data: copyRawData(data.raw.data), hashId: data.raw.hashId } : null;

	return newData;
};

/**
 * @returns The specified active wager data entry mapped to a standard wager data object.
 */
const mapEntryToWagerData = (
	entry: IActiveWagerDataEntry,
	opts?: Maybe<{
		resolveAmountsOpts?: Maybe<IMethodResolveAmountsOpts>;
	}>
): IWagerData => {
	return newWagerData(
		{
			...entry,
			lastUpdatedTs: entry.lastUpdatedTs,
			isDirty: false,
			isLocal: false,
		},
		opts
	);
};

/**
 * @returns Unique seat numbers in the specified lookup.
 */
const extractSeatNumbers = (lookup: ServerPlayerWagerStateDataLookup): number[] => {
	const list = Array.from(lookup.values());
	const seatMap = new Map<number, boolean>();

	list.forEach((entry) => {
		const seatNumber = entry.seatNumber;
		seatNumber > 0 && seatMap.set(seatNumber, true);
	});

	return Array.from(seatMap.keys());
};

/**
 * @returns Unique wager names in the specified lookup.
 */
const extractWagerNames = (lookup: ServerPlayerWagerStateDataLookup): IMethodGetWagerNamesResult => {
	const list = Array.from(lookup.values());
	const allWagerNameMap = new Map<string, boolean>();
	const bySeat: Record<string, Map<string, boolean>> = {};

	const result: IMethodGetWagerNamesResult = { all: [], bySeat: new Map<string, string[]>() };

	list.forEach((entry) => {
		const seatNumber = entry.seatNumber;
		const wagerName = entry.name;

		if (seatNumber < 1 || wagerName.length === 0) {
			return; // Next entry
		}

		allWagerNameMap.set(wagerName, true);

		bySeat[seatNumber] = bySeat[seatNumber] ?? new Map<string, boolean>();
		bySeat[seatNumber].set(wagerName, true);
	});

	entries(bySeat).forEach(([seatNumber, wagerNameMap]) => {
		result.bySeat.set(seatNumber, Array.from(wagerNameMap.keys()));
	});

	result.all = Array.from(allWagerNameMap.keys());

	return result;
};

/**
 * @returns Unique wager ID values in the specified lookup.
 */
const extractWagerIds = (lookup: ServerPlayerWagerStateDataLookup): IMethodGetWagerIdsResult => {
	const list = Array.from(lookup.values());
	const allWagerIdMap = new Map<string, boolean>();
	const bySeat: Record<string, Map<string, boolean>> = {};

	const result: IMethodGetWagerIdsResult = { all: [], bySeat: new Map<string, string[]>() };

	list.forEach((entry) => {
		const seatNumber = entry.seatNumber;
		const wagerId = entry.wagerId;

		if (seatNumber < 1 || wagerId.length === 0) {
			return; // Next entry
		}

		allWagerIdMap.set(wagerId, true);

		bySeat[seatNumber] = bySeat[seatNumber] ?? new Map<string, boolean>();
		bySeat[seatNumber].set(wagerId, true);
	});

	entries(bySeat).forEach(([seatNumber, wagerIdMap]) => {
		result.bySeat.set(seatNumber, Array.from(wagerIdMap.keys()));
	});

	result.all = Array.from(allWagerIdMap.keys());

	return result;
};

// ---- Exports -------------------------------------------------------------------------------------------------------

export { copyData, copyList, copyLookup, copyRawData };
export { generateRawDataHashId };
export { defaultData, defaultDataRetrievers };
export { generateDataListHashId, generateDataLookupHashId };
export { extractSeatNumbers, extractWagerNames, extractWagerIds };
export { mapEntryToWagerData };
