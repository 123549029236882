import { action, AnnotationsMap, computed, makeObservable, observable } from 'mobx';
import { ManagerBaseMobXAnnotations } from '../lib/ManagerBase/mobx';
import { WagerManagerMobXPrivateProps } from './types.mobx';
import WagerManager from './WagerManager';

/**
 * MobX annotations for `WagerManager`.
 */
const WagerManagerMobXAnnotations: AnnotationsMap<WagerManager, WagerManagerMobXPrivateProps> = {
	// Inherit from ManagerBase
	...ManagerBaseMobXAnnotations,

	// ---- Observed -----------------------------------------------------

	_activePlayerWagersLocal: observable,
	_serverPlayerWagerState: observable,
	_availableWagers: observable,
	_defaultSeatNumber: observable,
	_isSingleSeatTable: observable,
	_myClaimedSeats: observable,
	_playerId: observable,
	_playId: observable,
	_rebetWagers: observable.struct,
	_tableConfig: observable,
	_tableConfigWagerDefinitions: observable,
	_tableId: observable,
	_tableSeatAssignments: observable,
	_wagerDefinitions: observable,

	// ---- Computed -----------------------------------------------------

	// >>> Public
	canDoubleAllWagers: computed,
	canRebetWagers: computed,
	canUndoWagers: computed,
	currencyCode: computed,
	defaultSeatNumber: computed,
	hasAvailableWagers: computed,
	hasClaimedSeats: computed,
	hasRebetWagers: computed,
	hasWagers: computed,
	isSingleSeatTable: computed,
	lastUpdatedTs: computed,
	myClaimedSeats: computed,
	playerId: computed,
	playId: computed,
	serverActiveWagers: computed,
	serverPlayerWagerState: computed,
	tableConfig: computed,
	tableId: computed,
	totalWagered: computed,
	totalWageredAmount: computed,
	totalWageredAmountMoney: computed,
	totalWageredAmountReal: computed,
	wagerLimits: computed,
	wagerRules: computed,
	wagers: computed,
	wagersList: computed,
	wagersManifest: computed,

	// ---- Actions ------------------------------------------------------

	// >>> Public
	adjustWagerAmount: action,
	adjustWagerAmountByName: action,
	adjustWagerAmounts: action,
	adjustWagerAmountsByName: action,
	clear: action,
	clearAllWagers: action,
	clearLocalData: action,
	clearUndoWagers: action,
	doubleAllWagers: action,
	rebetWagers: action,
	resetLocalData: action,
	saveCurrentWagersForRebet: action,
	setActivePlayerWagers: action,
	setAvailableWagersData: action,
	setTableConfig: action,
	setTableSeatAssignments: action,
	setWagerAmount: action,
	setWagerAmountByName: action,
	setWagerAmounts: action,
	setWagerDefinitions: action,
	setWalletManager: action,
	undoWagers: action,

	// >>> Protected
	addWagersToUndoStack: action,
	init: action,
	initClaimedSeatingForMultiSeatTable: action,
	initPlayRelatedStateData: action,
	initTableRelatedStateData: action,
	onIsSingleSeatTableChanged: action,
	onLocalWagersClear: action,
	onLocalWagersUpdate: action,
	processMakeWagersReply: action,
	resetPlayRelatedData: action,
	resetTableRelatedData: action,
	setActivePlayerWagersLocalInstance: action,
	setAvailableWagersInstance: action,
	setDefaultSeatNumber: action,
	setIsSingleSeatTable: action,
	setMyClaimedSeatsList: action,
	setPlayAvailableWagersByContextList: action,
	setPlayerId: action,
	setPlayId: action,
	setPlayIdForDeps: action,
	setPlaySeatAssignmentsInstance: action,
	setRebetWagersData: action,
	setServerPlayerWagerStateInstance: action,
	setTableConfigData: action,
	setTableConfigWagerDefinitionsInstance: action,
	setTableId: action,
	setTableIdForDeps: action,
	setTableSeatAssignmentsInstance: action,
	setWagerDefinitionsInstance: action,
	setWagerUndoStackInstance: action,
	setWalletManagerInstance: action,
	undoToLastWagers: action,
	updateFromRawPlayerWagersData: action,
	updateLocalFromCurrentServerWagers: action,
	updateLocalWagerAmount: action,
	updateLocalWagersData: action,
};

/**
 * Makes the specified `WagerManager` instance an observable MobX object.
 */
const bindWagerManagerMobX = (instance: WagerManager): boolean => {
	if (instance.isMobXBound) {
		return false;
	}

	makeObservable<WagerManager, WagerManagerMobXPrivateProps>(instance, {
		...WagerManagerMobXAnnotations,
	});

	instance.isMobXBound = true;

	return true;
};

// ---- Exports -------------------------------------------------------------------------------------------------------

export { WagerManagerMobXAnnotations, bindWagerManagerMobX };
