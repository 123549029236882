import { action, AnnotationsMap, computed, makeObservable, observable } from 'mobx';
import { ManagerBaseMobXAnnotations } from '../lib/ManagerBase/mobx';
import { WalletManagerMobXPrivateProps } from './types.mobx';
import WalletManager from './WalletManager';

/**
 * MobX annotations for `WalletManager`.
 */
const WalletManagerMobXAnnotations: AnnotationsMap<WalletManager, WalletManagerMobXPrivateProps> = {
	// Inherit from ManagerBase
	...ManagerBaseMobXAnnotations,

	// ---- Observed -----------------------------------------------------

	_activeCurrencyCode: observable,
	_localBalances: observable,
	_playerId: observable,
	_serverBalances: observable,

	// ---- Computed -----------------------------------------------------

	activeCurrencyCode: computed,
	balanceAmount: computed,
	balanceCurrencyCode: computed,
	balanceCurrencySymbol: computed,
	balanceData: computed.struct,
	balanceMoney: computed,
	balanceReal: computed,
	lastUpdatedTs: computed,
	playerId: computed,

	// ---- Actions ------------------------------------------------------

	// >>> Public
	adjustBalanceAmount: action,
	clear: action,
	setBalanceAmount: action,
	setServerBalances: action,
	spendAmount: action,

	// >>> Protected
	clearLocalBalances: action,
	clearServerBalances: action,
	init: action,
	setDirtyBalanceHistoryInstance: action,
	setLocalBalancesData: action,
	setLocalBalancesInstance: action,
	setServerBalancesInstance: action,
	updateFromServerBalanceList: action,
	updateLocalBalanceAmount: action,
	updateLocalBalanceData: action,

	// ---- Excluded -----------------------------------------------------

	// >> Properties
	_isMobXBound: false,

	// >> Public
	isMobXBound: false,

	// >> Protected
	getBalanceData: false,
};

/**
 * Makes the specified `WalletManager` instance an observable MobX object.
 */
const bindWalletManagerMobX = (instance: WalletManager): boolean => {
	if (instance.isMobXBound) {
		return false;
	}

	makeObservable<WalletManager, WalletManagerMobXPrivateProps>(instance, {
		...WalletManagerMobXAnnotations,
	});

	instance.isMobXBound = true;

	return true;
};

// ---- Exports -------------------------------------------------------------------------------------------------------

export { WalletManagerMobXAnnotations, bindWalletManagerMobX };
