// @generated by protoc-gen-es v1.3.0 with parameter "target=js+dts"
// @generated from file dealerserver.proto (package com.sands.ldx.dealerserver, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { Any, proto3 } from "@bufbuild/protobuf";

/**
 * @generated from message com.sands.ldx.dealerserver.RunPlayRequest
 */
export const RunPlayRequest = proto3.makeMessageType(
  "com.sands.ldx.dealerserver.RunPlayRequest",
  () => [
    { no: 1, name: "table_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "forced", kind: "scalar", T: 8 /* ScalarType.BOOL */, opt: true },
  ],
);

/**
 * @generated from message com.sands.ldx.dealerserver.NewPlayRequest
 */
export const NewPlayRequest = proto3.makeMessageType(
  "com.sands.ldx.dealerserver.NewPlayRequest",
  () => [
    { no: 1, name: "table_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "safe", kind: "scalar", T: 8 /* ScalarType.BOOL */, opt: true },
  ],
);

/**
 * @generated from message com.sands.ldx.dealerserver.PausePlayRequest
 */
export const PausePlayRequest = proto3.makeMessageType(
  "com.sands.ldx.dealerserver.PausePlayRequest",
  () => [
    { no: 1, name: "table_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message com.sands.ldx.dealerserver.EvictAllSeatsRequest
 */
export const EvictAllSeatsRequest = proto3.makeMessageType(
  "com.sands.ldx.dealerserver.EvictAllSeatsRequest",
  () => [
    { no: 1, name: "table_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "dealer_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message com.sands.ldx.dealerserver.EvictOneSeatRequest
 */
export const EvictOneSeatRequest = proto3.makeMessageType(
  "com.sands.ldx.dealerserver.EvictOneSeatRequest",
  () => [
    { no: 1, name: "table_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "dealer_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "seat_position", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
  ],
);

/**
 * @generated from message com.sands.ldx.dealerserver.CloseSeatRequest
 */
export const CloseSeatRequest = proto3.makeMessageType(
  "com.sands.ldx.dealerserver.CloseSeatRequest",
  () => [
    { no: 1, name: "table_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "dealer_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "seat_position", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
  ],
);

/**
 * @generated from message com.sands.ldx.dealerserver.DealerAttachmentRequest
 */
export const DealerAttachmentRequest = proto3.makeMessageType(
  "com.sands.ldx.dealerserver.DealerAttachmentRequest",
  () => [
    { no: 1, name: "dealer_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "table_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "dealer_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message com.sands.ldx.dealerserver.DealerDetachmentRequest
 */
export const DealerDetachmentRequest = proto3.makeMessageType(
  "com.sands.ldx.dealerserver.DealerDetachmentRequest",
  () => [
    { no: 1, name: "dealer_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "table_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message com.sands.ldx.dealerserver.DealerInfo
 */
export const DealerInfo = proto3.makeMessageType(
  "com.sands.ldx.dealerserver.DealerInfo",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message com.sands.ldx.dealerserver.ListDealersRequest
 */
export const ListDealersRequest = proto3.makeMessageType(
  "com.sands.ldx.dealerserver.ListDealersRequest",
  () => [
    { no: 1, name: "attached_only", kind: "scalar", T: 8 /* ScalarType.BOOL */, opt: true },
  ],
);

/**
 * @generated from message com.sands.ldx.dealerserver.ListDealersResponse
 */
export const ListDealersResponse = proto3.makeMessageType(
  "com.sands.ldx.dealerserver.ListDealersResponse",
  () => [
    { no: 1, name: "dealers", kind: "message", T: DealerInfo, repeated: true },
  ],
);

/**
 * @generated from message com.sands.ldx.dealerserver.GetDealerRequest
 */
export const GetDealerRequest = proto3.makeMessageType(
  "com.sands.ldx.dealerserver.GetDealerRequest",
  () => [
    { no: 1, name: "dealer_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "include_table", kind: "scalar", T: 8 /* ScalarType.BOOL */, opt: true },
  ],
);

/**
 * @generated from message com.sands.ldx.dealerserver.GetDealerResponse
 */
export const GetDealerResponse = proto3.makeMessageType(
  "com.sands.ldx.dealerserver.GetDealerResponse",
  () => [
    { no: 1, name: "dealer_info", kind: "message", T: DealerInfo },
    { no: 2, name: "table_config", kind: "message", T: TableConfig, opt: true },
  ],
);

/**
 * @generated from message com.sands.ldx.dealerserver.TableConfig
 */
export const TableConfig = proto3.makeMessageType(
  "com.sands.ldx.dealerserver.TableConfig",
  () => [
    { no: 1, name: "play_config", kind: "message", T: PlayConfig },
    { no: 2, name: "active", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ],
);

/**
 * @generated from message com.sands.ldx.dealerserver.PlayConfig
 */
export const PlayConfig = proto3.makeMessageType(
  "com.sands.ldx.dealerserver.PlayConfig",
  () => [
    { no: 1, name: "game_type", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "game_variant", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "game_version", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "auto_start", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 5, name: "plugin_config", kind: "scalar", T: 12 /* ScalarType.BYTES */ },
  ],
);

/**
 * @generated from message com.sands.ldx.dealerserver.GetHUDRequest
 */
export const GetHUDRequest = proto3.makeMessageType(
  "com.sands.ldx.dealerserver.GetHUDRequest",
  () => [
    { no: 1, name: "table_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "include_play", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 3, name: "include_previous_play", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ],
);

/**
 * @generated from message com.sands.ldx.dealerserver.GetHUDResponse
 */
export const GetHUDResponse = proto3.makeMessageType(
  "com.sands.ldx.dealerserver.GetHUDResponse",
  () => [
    { no: 1, name: "table_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "dealer_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "dealer_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "game_type", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "game_variant", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "auto_start", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 7, name: "play_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 8, name: "previous_play_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 9, name: "play", kind: "message", T: HUDPlay },
    { no: 10, name: "previous_play", kind: "message", T: HUDPlay },
    { no: 11, name: "estimated_streamers", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
  ],
);

/**
 * @generated from message com.sands.ldx.dealerserver.HUDPlay
 */
export const HUDPlay = proto3.makeMessageType(
  "com.sands.ldx.dealerserver.HUDPlay",
  () => [
    { no: 1, name: "game_type", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "game_variant", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "seats", kind: "message", T: HUDSeat, repeated: true },
    { no: 4, name: "open_device_requests", kind: "message", T: HistoricDeviceRequest, repeated: true },
    { no: 5, name: "complete_device_requests", kind: "message", T: HistoricDeviceRequest, repeated: true },
    { no: 6, name: "game_state", kind: "message", T: Any },
    { no: 7, name: "wager_periods", kind: "message", T: WagerPeriod, repeated: true },
    { no: 8, name: "choice_periods", kind: "message", T: ChoicePeriod, repeated: true },
    { no: 9, name: "celebrations", kind: "message", T: Celebration, repeated: true },
    { no: 10, name: "estimated_players", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 11, name: "current_time", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 12, name: "estimated_streamers", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
  ],
);

/**
 * @generated from message com.sands.ldx.dealerserver.HUDSeat
 */
export const HUDSeat = proto3.makeMessageType(
  "com.sands.ldx.dealerserver.HUDSeat",
  () => [
    { no: 1, name: "seat_position", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 2, name: "player_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "display_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "image_url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "player_game_state", kind: "message", T: Any, repeated: true },
  ],
);

/**
 * @generated from message com.sands.ldx.dealerserver.HistoricDeviceRequest
 */
export const HistoricDeviceRequest = proto3.makeMessageType(
  "com.sands.ldx.dealerserver.HistoricDeviceRequest",
  () => [
    { no: 1, name: "request_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "request_type", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "request_data", kind: "scalar", T: 12 /* ScalarType.BYTES */ },
    { no: 4, name: "input_values", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ],
);

/**
 * @generated from message com.sands.ldx.dealerserver.WagerPeriod
 */
export const WagerPeriod = proto3.makeMessageType(
  "com.sands.ldx.dealerserver.WagerPeriod",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "closing", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 4, name: "closed", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 5, name: "availability_window", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 6, name: "remaining_time", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 8, name: "meta", kind: "map", K: 9 /* ScalarType.STRING */, V: {kind: "scalar", T: 9 /* ScalarType.STRING */} },
    { no: 9, name: "expires_at", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
  ],
);

/**
 * @generated from message com.sands.ldx.dealerserver.ChoicePeriod
 */
export const ChoicePeriod = proto3.makeMessageType(
  "com.sands.ldx.dealerserver.ChoicePeriod",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "closed", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 4, name: "availability_window", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 5, name: "remaining_time", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 7, name: "meta", kind: "map", K: 9 /* ScalarType.STRING */, V: {kind: "scalar", T: 9 /* ScalarType.STRING */} },
    { no: 8, name: "expires_at", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
  ],
);

/**
 * @generated from message com.sands.ldx.dealerserver.Celebration
 */
export const Celebration = proto3.makeMessageType(
  "com.sands.ldx.dealerserver.Celebration",
  () => [
    { no: 1, name: "type", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "reason", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "seat_position", kind: "scalar", T: 4 /* ScalarType.UINT64 */, opt: true },
    { no: 4, name: "display_name", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
  ],
);

