import { action, AnnotationsMap, computed, makeObservable, observable } from 'mobx';
import { ManagerBaseMobXAnnotations } from '../lib/ManagerBase/mobx';
import ResolutionManager from './ResolutionManager';
import { ResolutionManagerMobXPrivateProps } from './types.mobx';

/**
 * MobX annotations for `ResolutionManager`.
 */
const ResolutionManagerMobXAnnotations: AnnotationsMap<ResolutionManager, ResolutionManagerMobXPrivateProps> = {
	// Inherit from ManagerBase
	...ManagerBaseMobXAnnotations,

	// ---- Observed -----------------------------------------------------

	_displayed: observable,
	_resolutions: observable,
	_undisplayed: observable,
	_wagersDict: observable,
	_sidebetsDict: observable,

	// ---- Computed -----------------------------------------------------

	// >>> Public
	undisplayedResolutions: computed,
	currentWinningWagers: computed,

	// ---- Actions ------------------------------------------------------

	// >>> Public
	clear: action,
	reset: action,
	// setResolutions: action,
	setDisplayed: action,

	// ---- Excluded -----------------------------------------------------

	// >>> Props
	_isMobXBound: false,
	isMobXBound: false,

	// >>> Public Methods
	getCurrentWinningWagers: false,
	getCurrentWinningSideBets: false,
	toJson: false,
};

/**
 * Makes the specified `ResolutionManager` instance an observable MobX object.
 */
const bindResolutionManagerMobX = (instance: ResolutionManager): boolean => {
	if (instance.isMobXBound) {
		return false;
	}

	makeObservable<ResolutionManager, ResolutionManagerMobXPrivateProps>(instance, {
		...ResolutionManagerMobXAnnotations,
	});

	instance.isMobXBound = true;

	return true;
};

// ---- Exports -------------------------------------------------------------------------------------------------------

export { ResolutionManagerMobXAnnotations, bindResolutionManagerMobX };
