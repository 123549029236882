import { set } from 'mobx';
import { DebugBase } from '../../../../common';
import { filterUndefined } from '../../../../helpers';
import {
	createAvailableWagersDataExtList,
	IContextAvailableWagersData,
	ICreateAvailableWagersDataExtOpts,
	PlaySeatAssignmentsLookup,
	PlayWagerDefsList,
} from '../../../../helpers/data';
import { bindAvailableWagersMobX } from './mobx';
import {
	AvailableWagersDataList,
	AvailableWagersDataLookup,
	IAvailableWagerDataEntry,
	IAvailableWagersData,
	IAvailableWagersFilterToOpts,
	IAvailableWagersOpts,
	IMethodAvailableWagerRemapDataOpts,
	IMethodAvailableWagersMapRawDataOpts,
	RawAvailableWagerList,
} from './types';
import {
	copyData,
	defaultData,
	generateRawListHashId,
	getContextIds,
	getNames,
	getSeatIds,
	getSeatNumbers,
	newDataFromRawList,
	remapData,
} from './utility';

/**
 * Represents a collection of available wagers with extended data. Key is `availableWagerKey`.
 */
class AvailableWagers extends DebugBase {
	/* #region ---- Properties --------------------------------------------------------------------------------------- */

	/**
	 * Currently assigned options.
	 */
	protected override _options: IAvailableWagersOpts = AvailableWagers.defaultOptions();

	/**
	 * Encapsulated data.
	 */
	protected _data: IAvailableWagersData;

	/**
	 * Flag indicating if this class instance is currently bound to MobX as an observable.
	 */
	protected _isMobXBound: boolean = false;

	/* #endregion ---- Properties -------------------------------------------------------------------------------------*/

	/* #region ---- CONSTRUCTOR ---------------------------------------------------------------------------------------*/

	constructor(opts?: Maybe<IAvailableWagersOpts>) {
		super();

		this._data = opts?.data ?? defaultData();
		opts != null && this.setOptions(opts);

		// Bind to MobX as an observable.
		if (this._options.useMobX) {
			bindAvailableWagersMobX(this);
		}
	}

	/* #endregion ---- CONSTRUCTOR ----------------------------------------------------------------------------------- */

	/* #region ---- Public ------------------------------------------------------------------------------------------- */

	/**
	 * Sets/initializes the class options.
	 *
	 * - Overrides the parent class method.
	 */
	public override setOptions(opts: IAvailableWagersOpts) {
		const { newOpts, origOpts } = this.resolveOptions(opts);

		if (this._isMobXBound) {
			set(this, '_options', newOpts);
		} else {
			this._options = newOpts;
		}

		this.onSetOptions(newOpts, origOpts);
	}

	/**
	 * Whether or not the data collection is empty.
	 */
	public get isEmpty(): boolean {
		return this.size === 0;
	}

	/**
	 * Size of the data collection.
	 */
	public get size(): number {
		return this.lookup.size;
	}

	/**
	 * Lookup of available wagers data keyed by `availableWagerKey`.
	 */
	public get lookup(): AvailableWagersDataLookup {
		return this._data.lookup;
	}

	/**
	 * Array of available wagers data.
	 */
	public get list(): AvailableWagersDataList {
		return Array.from(this.lookup.values());
	}

	/**
	 * @returns All defined unique keys (availableWagerKey) in the data collection.
	 */
	public get keys(): string[] {
		return Array.from(this.lookup.keys());
	}

	/**
	 * The unique hash ID for the data collection.
	 */
	public get dataHashId(): string {
		return this._data?.hashId ?? '';
	}

	/**
	 * The full data encapsulated by this class instance.
	 */
	public get data(): IAvailableWagersData {
		return this._data;
	}
	public set data(val: IAvailableWagersData) {
		this.setData(val);
	}
	// Sets the encapsulated class data. Actionable setter method for MobX.
	protected setData(val: IAvailableWagersData) {
		if (val === this._data) {
			return;
		}

		if (this.isMobXBound) {
			set(this, '_data', val);
		} else {
			this._data = val;
		}
	}

	/**
	 * The last time (unix timestamp) that the data was updated.
	 */
	public get lastUpdatedTs(): number {
		return this._data.lastUpdatedTs;
	}
	public set lastUpdatedTs(val: number) {
		this.setLastUpdatedTs(val);
	}
	// Actionable setter method for MobX.
	protected setLastUpdatedTs(val: number) {
		if (val === this._data.lastUpdatedTs) {
			return;
		}
		this._data.lastUpdatedTs = val;
	}

	/**
	 * Get/set the play ID the instance is associated with.
	 */
	public get playId(): string {
		return this._data.playId;
	}
	public set playId(val: string) {
		this.setPlayId(val);
	}
	// Actionable setter method for MobX.
	protected setPlayId(val: string) {
		if (val === this._data.playId) {
			return;
		}

		const prev = this._data.playId;
		this._data.playId = val;
		this.onPlayIdChanged(this._data.playId, prev);
	}

	/**
	 * Gets/sets whether or not this class instance is currently bound to MobX as an observable.
	 */
	public get isMobXBound(): boolean {
		return this._isMobXBound;
	}
	public set isMobXBound(value: boolean) {
		this._isMobXBound = value;
	}

	/**
	 * @returns All defined available wager keys in the data collection.
	 */
	public get availableWagerKeys(): string[] {
		return this.keys;
	}

	/**
	 * @returns All defined available wager names in the data collection.
	 */
	public get names(): string[] {
		return getNames(this.lookup);
	}

	/**
	 * @returns All defined available wager context IDs in the data collection.
	 */
	public get contextIds(): string[] {
		return getContextIds(this.lookup);
	}

	/**
	 * @returns All defined available wager seat IDs in the data collection.
	 */
	public get seatIds(): string[] {
		return getSeatIds(this.lookup);
	}

	/**
	 * @returns All defined available wager seat numbers in the data collection.
	 */
	public get seatNumbers(): number[] {
		return getSeatNumbers(this.lookup);
	}

	/**
	 * @returns The data entry for the specified available wager key, or, NULL if not present
	 */
	public get(availableWagerKey: string, opts?: Maybe<{ copy?: Maybe<boolean> }>): Nullable<IAvailableWagerDataEntry> {
		const entry = this.lookup.get(availableWagerKey) ?? null;

		if (opts?.copy === true && entry != null) {
			return { ...entry };
		}

		return entry;
	}

	/**
	 * @returns The data entries for the specified available wager name, or, NULL if not present
	 */
	public getByName(wagerName: string, opts?: Maybe<{ copy?: Maybe<boolean> }>): IAvailableWagerDataEntry[] {
		const wagerNameLc = wagerName.toLocaleLowerCase();

		const wagerIds: string[] = (wagerNameLc != '' ? this._data.nameToKeyLookup.get(wagerNameLc) : null) ?? [];
		if (wagerIds.length === 0) {
			return [];
		}

		const result: IAvailableWagerDataEntry[] = [];

		wagerIds.forEach((wagerId) => {
			const entry = (wagerId != null ? this.lookup.get(wagerId) : null) ?? null;
			if (entry == null) {
				return; // Next entry
			}

			result.push(opts?.copy === true ? { ...entry } : entry);
		});

		return result;
	}

	/**
	 * @returns The data entries matching all the specified props, or, empty array if none present
	 */
	public queryBy(matchProps: Partial<Omit<IAvailableWagerDataEntry, 'def'>>): IAvailableWagerDataEntry[] {
		matchProps = filterUndefined(matchProps);
		if (this.list.length === 0 || Object.keys(matchProps).length === 0) {
			return [];
		}

		return this.list.slice().filter((entry) => {
			for (const [key, val] of Object.entries(matchProps)) {
				if (entry[key] !== val) {
					return false;
				}
			}

			return true;
		});
	}

	/**
	 * @returns TRUE if a data entry for specified available wager key exists.
	 */
	public has(availableWagerKey: string): boolean {
		return this.lookup.has(availableWagerKey);
	}

	/**
	 * @returns TRUE if a data entry for specified available wager name exists.
	 */
	public hasName(wagerName: string): boolean {
		return this._data.nameToKeyLookup.has(wagerName);
	}

	/**
	 * @returns A lookup and list of available wagers that match the specified wager ID.
	 */
	public lookupByWagerId(wagerId: string): { lookup: AvailableWagersDataLookup; list: AvailableWagersDataList } {
		const lookup = new Map<string, IAvailableWagerDataEntry>();
		const list: IAvailableWagerDataEntry[] = [];

		if (wagerId === '') {
			return { lookup, list };
		}

		for (const entry of this.lookup.values()) {
			if (entry.wagerId === wagerId) {
				lookup.set(entry.availableWagerKey, entry);
				list.push(entry);
			}
		}

		return { lookup, list };
	}

	/**
	 * Resets the class instance data back to the initial/clear values.
	 */
	public clear() {
		const data = defaultData({ updatedTs: Date.now(), playId: this.playId });
		this.setData(data);
	}

	/**
	 * @returns A new clone of this class instance with copied data.
	 */
	public clone(opts?: Maybe<{ updatedTs?: Maybe<number> }>): AvailableWagers {
		const newData = copyData(this._data, { updatedTs: opts?.updatedTs });

		return new AvailableWagers({ ...this._options, data: newData, updatedTs: newData.lastUpdatedTs });
	}

	/**
	 * Overrides the parent class method.
	 *
	 * @returns A JSON export of the current data.
	 */
	public override toJson(extended?: Maybe<boolean>): PlainObject {
		extended = extended ?? false;

		const toJs = (val: unknown) => DebugBase.toJs(val, { extended, useMobXToJs: this.isMobXBound });

		const data = copyData(this._data);
		const list = Array.from(data.lookup.values());
		const keys = Array.from(data.lookup.keys());

		const result: PlainObject = {
			lastUpdatedTs: data.lastUpdatedTs,
			size: data.lookup.size,
			isEmpty: data.lookup.size === 0,
			playId: data.playId,
			list: toJs(list),
			lookup: toJs(data.lookup),
			availableWagerKeys: toJs(keys),
			names: toJs(getNames(data.lookup)),
			contextIds: toJs(getContextIds(data.lookup)),
			seatNumbers: toJs(getSeatNumbers(data.lookup)),
			seatIds: toJs(getSeatIds(data.lookup)),
		};

		if (extended) {
			result.extended = {
				isMobXBound: this.isMobXBound,
				options: toJs({ ...this._options }),
				rawData: toJs(data),

				// Debug info from base class
				_Debug: { ...super.toJson(extended) },
			};
		}

		return result;
	}

	/**
	 * ACTION
	 * Populates this instance using the raw available wagers list.
	 */
	public populate(
		rawList: RawAvailableWagerList,
		opts?: Maybe<IMethodAvailableWagersMapRawDataOpts>
	): IAvailableWagersData {
		const playId = this.playId;
		const data = newDataFromRawList(rawList, playId, opts);
		this.setData(data);

		return this._data;
	}

	/**
	 * ACTION
	 * Populates this instance using the specified lists of available play wagers and wager definitions.
	 */
	public populateFromAvailPlayWagersData(
		availablePlayWagers: IContextAvailableWagersData[],
		playWagerDefsList: PlayWagerDefsList,
		opts?: Maybe<
			ICreateAvailableWagersDataExtOpts & Omit<IMethodAvailableWagersMapRawDataOpts, 'playSeatAssignmentsList'>
		>
	): IAvailableWagersData {
		const playId = opts?.playId ?? this.playId;

		const rawList = createAvailableWagersDataExtList(availablePlayWagers, playWagerDefsList, {
			...opts,
			playId,
		});

		const data = newDataFromRawList(rawList, playId, opts);
		this.setData(data);

		return this._data;
	}

	/**
	 * Similar to `populate` but remaps the existing raw data using the specified filter props.
	 */
	public filterTo(
		filterProps: IAvailableWagersFilterToOpts,
		opts?: IMethodAvailableWagerRemapDataOpts
	): IAvailableWagersData {
		const playId = this.playId;
		const data = remapData(this._data, playId, filterProps, opts);
		this.setData(data);

		return this._data;
	}

	/**
	 * @returns TRUE if the specified raw list is the same as the current one - in terms of the meaningful data.
	 */
	public isRawDataSame(rawList: RawAvailableWagerList): boolean {
		const origHashId = this._data.raw?.hashId ?? '';
		const newHashId = generateRawListHashId(rawList);

		return newHashId === origHashId;
	}

	/* #endregion ---- Public ---------------------------------------------------------------------------------------- */

	/* #region ---- Protected ---------------------------------------------------------------------------------------- */

	/**
	 * Resolves the options being passed in and returns the original and new options.
	 *
	 * - Overrides the parent class method
	 */
	protected override resolveOptions(opts?: Maybe<IAvailableWagersOpts>) {
		const origOpts: IAvailableWagersOpts = {
			...AvailableWagers.defaultOptions(),
			...this._options,
		};

		const newOpts: IAvailableWagersOpts = {
			...origOpts,
			...(opts ?? {}),
		};

		return { origOpts, newOpts };
	}

	/**
	 * Called after new options are set.
	 *
	 * - Extends the parent class method
	 */
	protected override onSetOptions(newOpts: IAvailableWagersOpts, origOpts?: Maybe<IAvailableWagersOpts>) {
		super.onSetOptions(newOpts, origOpts);

		if (newOpts.data != null && (!this._data || newOpts.data.uniqId !== this._data.uniqId)) {
			this.setData(newOpts.data);
		}

		if (!this._data) {
			return;
		}
		if (newOpts.updatedTs != null && newOpts.updatedTs !== this._data.lastUpdatedTs) {
			this.setLastUpdatedTs(newOpts.updatedTs);
		}
		if (newOpts.playId != null && newOpts.playId !== this._data.playId) {
			this.setPlayId(newOpts.playId);
		}
	}

	/**
	 * Called after the play ID changes.
	 */
	protected onPlayIdChanged(_newPlayId: string, prevPlayId: string) {
		if (prevPlayId === '') {
			return;
		}

		// Since the play has changed, we need to clear the current data.
		this.clear();
	}

	/* #endregion ---- Protected ------------------------------------------------------------------------------------- */

	/* #region ---- Static ------------------------------------------------------------------------------------------- */

	/**
	 * STATIC
	 * @returns A clone of the specified class instance.
	 */
	public static cloneInstance(from: AvailableWagers, opts?: Maybe<{ updatedTs?: Maybe<number> }>): AvailableWagers {
		return from.clone(opts);
	}

	/**
	 * STATIC
	 * @returns The default options data used by this class.
	 */
	public static defaultOptions(): IAvailableWagersOpts {
		return {
			...DebugBase.defaultOptions(),
			data: null,
			updatedTs: null,
			useMobX: true,
			playId: null,
		};
	}

	/**
	 * STATIC
	 * @returns A new instance of this class populated with the specified raw available wagers list.
	 */
	public static newFromRawList(
		rawList: RawAvailableWagerList,
		playId: string,
		opts?: Maybe<{
			newInstanceOpts?: Maybe<IAvailableWagersOpts>;
			populateOpts?: Maybe<Omit<IMethodAvailableWagersMapRawDataOpts, 'playSeatAssignments'>>;
		}>
	): AvailableWagers {
		const data = newDataFromRawList(rawList, playId, opts?.populateOpts);
		const instanceOpts: IAvailableWagersOpts = { ...opts?.newInstanceOpts, data, updatedTs: data.lastUpdatedTs };

		return new AvailableWagers(instanceOpts);
	}

	/**
	 * Populates this instance using the specified lists of available play wagers and wager definitions.
	 */
	public static newFromAvailPlayWagersData(
		availablePlayWagers: IContextAvailableWagersData[],
		playWagerDefsList: PlayWagerDefsList,
		playId: string,
		opts?: Maybe<{
			playSeatAssignments?: Maybe<PlaySeatAssignmentsLookup>;
			newInstanceOpts?: Maybe<Omit<IAvailableWagersOpts, 'playId'>>;
			populateOpts?: Maybe<
				Omit<ICreateAvailableWagersDataExtOpts & IMethodAvailableWagersMapRawDataOpts, 'playId' | 'playSeatAssignments'>
			>;
		}>
	): AvailableWagers {
		const instanceOpts: IAvailableWagersOpts = { ...opts?.newInstanceOpts, playId };
		const instance = new AvailableWagers(instanceOpts);

		const populateOpts = { ...opts?.populateOpts, playSeatAssignments: opts?.playSeatAssignments };
		instance.populateFromAvailPlayWagersData(availablePlayWagers, playWagerDefsList, populateOpts);

		return instance;
	}

	/* #endregion ---- Static ---------------------------------------------------------------------------------------- */

	/* #region ---- Debug -------------------------------------------------------------------------------------------- */

	/**
	 * Overrides the parent class property.
	 *
	 * @returns The label to use when debugging.
	 */
	protected override get debugClassLabel(): string {
		return AvailableWagers.debugClassLabel();
	}

	/**
	 * STATIC
	 * @returns Label assigned to this class namespace.
	 */
	protected static debugClassLabel(): string {
		return `RpcLib.DataObject.AvailableWagers`;
	}

	/* #endregion ---- Debug ----------------------------------------------------------------------------------------- */
}

// ---- Exports -------------------------------------------------------------------------------------------------------

export { AvailableWagers as default };
export { AvailableWagers };
