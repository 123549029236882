import {
	BufServiceType,
	ConnectPromiseClient,
	ConnectTransport,
	createConnectPromiseClient,
} from '../../../../bufbuild';
import { IClientOpts } from '../types';
import { createRpcTransport } from '../utility';

/**
 * Generic RPC promise client. It doesn't do a whole lot, but it reduces some complexity.
 */
class PromiseClient<RpcServiceType extends BufServiceType> {
	/* #region ---- Properties --------------------------------------------------------------------------------------- */

	/**
	 * Currently assigned options.
	 */
	protected _options: IClientOpts = PromiseClient.defaultOptions();

	/**
	 * Buf Connect transport instance used by this client.
	 */
	protected _rpcTransport: ConnectTransport;

	/**
	 * Buf Connect promise client instance used by this client
	 */
	protected _connectRpcClient: ConnectPromiseClient<RpcServiceType>;

	/**
	 * The base URL being used for RPC calls made with this client.
	 */
	protected _baseUrl: string = '';

	/**
	 * The RPC protocol being used for RPC calls made with this client.
	 */
	protected _rpcProtocol: string = '';

	/* #endregion ---- Properties ------------------------------------------------------------------------------------ */

	/* #region ---- Constructor -------------------------------------------------------------------------------------- */

	constructor(rpcService: RpcServiceType, baseUrl: string, opts?: Maybe<IClientOpts>) {
		const transportOpts = opts?.transport ?? {};

		if (rpcService == null) {
			throw new Error('[PromiseClient]: RPC client service instance must be specified. eg. GameClient');
		}
		if (baseUrl === '') {
			throw new Error('[PromiseClient]: Base url must be specified');
		}

		if (transportOpts?.instance) {
			this._rpcTransport = transportOpts.instance;
		} else {
			const { protocol, transport } = createRpcTransport(baseUrl, transportOpts?.opts, transportOpts?.protocol);
			this._rpcTransport = transport;
			this._rpcProtocol = protocol;
		}

		this._connectRpcClient = this.newConnectPromiseClient(rpcService);
		this._baseUrl = baseUrl;
	}

	/* #endregion ---- Constructor ----------------------------------------------------------------------------------- */

	/* #region ---- Public ------------------------------------------------------------------------------------------- */

	/**
	 * @returns The Buf Connect promise client instance used by this client
	 */
	public get rpcClient(): ConnectPromiseClient<RpcServiceType> {
		return this._connectRpcClient;
	}

	/**
	 * @returns The Buf Connect transport instance used by this client.
	 */
	public get rpcTransport(): ConnectTransport {
		return this._rpcTransport;
	}

	/**
	 * @returns The RPC protocol being used for RPC calls made with this client.
	 */
	public get rpcProtocol(): string {
		return this._rpcProtocol;
	}

	/**
	 * @returns The base URL being used for RPC calls made with this client.
	 */
	public get baseUrl(): string {
		return this._baseUrl;
	}

	/**
	 * @returns The currently assigned options.
	 */
	public get options(): IClientOpts {
		return { ...this._options };
	}

	/* #endregion ---- Public ---------------------------------------------------------------------------------------- */

	/* #region ---- Protected ---------------------------------------------------------------------------------------- */

	protected newConnectPromiseClient(
		rpcService: RpcServiceType,
		transport?: Maybe<ConnectTransport>
	): ConnectPromiseClient<RpcServiceType> {
		transport = transport ?? this._rpcTransport;

		return createConnectPromiseClient(rpcService, transport);
	}

	/* #endregion ---- Protected ------------------------------------------------------------------------------------- */

	/* #region ---- Static ------------------------------------------------------------------------------------------- */

	/**
	 * @returns Defaults used for Stream class options.
	 */
	public static defaultOptions(): IClientOpts {
		return {
			transport: null,
		};
	}

	/* #endregion ---- Static ---------------------------------------------------------------------------------------- */
}

// ---- Exports -------------------------------------------------------------------------------------------------------

export { PromiseClient as default };
export { PromiseClient };
