import { copyAnyObjectList, fillObject, makeUniqId } from '../../../../helpers';
import { generateObjectListHashId } from '../../utility';
import {
	AvailableWagersDataList,
	AvailableWagersDataLookup,
	IAvailableWagerDataEntry,
	IAvailableWagersData,
	IAvailableWagersFilterToOpts,
	IMethodAvailableWagerRemapDataOpts,
	IMethodAvailableWagersMapRawDataOpts,
	RawAvailableWagerEntry,
	RawAvailableWagerList,
} from './types';

/**
 * @returns The default data used by the `AvailableWagers` class.
 */
const defaultData = (opts?: Maybe<{ updatedTs?: Maybe<number>; playId?: Maybe<string> }>): IAvailableWagersData => {
	return {
		raw: null,
		lookup: new Map<string, IAvailableWagerDataEntry>(),
		nameToKeyLookup: new Map<string, string[]>(),
		lastUpdatedTs: opts?.updatedTs ?? 0,
		playId: opts?.playId ?? '',
		uniqId: makeUniqId(),
		hashId: '',
	};
};

/**
 * @returns A unique hash ID for the specified list of raw data entries.
 */
const generateRawListHashId = (list: RawAvailableWagerList): string => {
	return generateObjectListHashId<RawAvailableWagerEntry>(list, [
		'wagerId',
		'name',
		'availableWagerKey',
		'categoryMemberKey',
		'categoryName',
		'contextId',
		'isCategoryWager',
		'playId',
	]);
};

/**
 * @returns A unique hash ID for the specified list of data entries.
 */
const generateDataListHashId = (list: AvailableWagersDataList): string => {
	return generateObjectListHashId<RawAvailableWagerEntry>(list, [
		'wagerId',
		'name',
		'availableWagerKey',
		'categoryMemberKey',
		'categoryName',
		'contextId',
		'isCategoryWager',
		'playId',
	]);
};

/**
 * @returns TRUE if the specified raw data lists are the same - in terms of the meaningful data.
 */
const isRawListSameData = (list1: RawAvailableWagerList, list2: RawAvailableWagerList): boolean => {
	if (list1 === list2) {
		return true;
	}
	if (list1.length !== list2.length) {
		return false;
	}

	const hashId1 = generateRawListHashId(list1);
	const hashId2 = generateRawListHashId(list2);

	return hashId1 === hashId2;
};

/**
 * Maps the raw data list sent by the server and resolves to the relevant encapsulated data.
 */
const newDataFromRawList = (
	srcList: RawAvailableWagerList,
	playId: string,
	opts?: Maybe<IMethodAvailableWagersMapRawDataOpts>
): IAvailableWagersData => {
	const { updatedTs } = opts ?? {};
	const lastUpdatedTs: number = updatedTs || Date.now();

	if (srcList.length === 0) {
		return defaultData({ updatedTs: lastUpdatedTs, playId });
	}

	type LookupObjType = { [k: string]: boolean };

	const filterTo = opts?.filterTo ?? null;
	const filterWagerIds = filterTo?.wagerIds ?? [];
	const filterNames = filterTo?.wagerNames ?? [];
	const filterWagerContextIds = filterTo?.wagerContextIds ?? [];
	const filterWagerIdsLookup = Object.freeze(fillObject<LookupObjType, boolean>({}, filterWagerIds, true));
	const filterNamesLookup = Object.freeze(fillObject<LookupObjType, boolean>({}, filterNames, true));
	const filterWagerContextIdsLookup = Object.freeze(
		fillObject<LookupObjType, boolean>({}, filterWagerContextIds, true)
	);

	const lookup = new Map<string, IAvailableWagerDataEntry>();
	const nameToKeyLookup = new Map<string, string[]>();

	srcList.forEach((entry: RawAvailableWagerEntry) => {
		entry.playId = playId || entry.playId;

		const availableWagerKey = entry.availableWagerKey;
		const wagerId = entry.wagerId;
		const contextId = entry.contextId;
		const wagerName = entry.name;
		const wagerNameLc = wagerName.toLocaleLowerCase();

		if (filterWagerIds.length > 0 && !filterWagerIdsLookup[wagerId]) {
			return; // Next entry
		}
		if (filterNames.length > 0 && !filterNamesLookup[wagerName]) {
			return; // Next entry
		}
		if (filterWagerContextIds.length > 0 && !filterWagerContextIdsLookup[contextId]) {
			return; // Next entry
		}

		const item: IAvailableWagerDataEntry = {
			...entry,
			lastUpdatedTs,
		};

		lookup.set(availableWagerKey, item);

		const lookupKeys = nameToKeyLookup.get(wagerNameLc) ?? [];
		lookupKeys.push(availableWagerKey);
		nameToKeyLookup.set(wagerNameLc, lookupKeys);
	});

	const list = Array.from(lookup.values());
	const hashId = generateDataListHashId(list);
	const uniqId = makeUniqId();

	const rawList = srcList.slice();
	const rawHashId = generateRawListHashId(rawList);

	return {
		raw: { list: rawList, hashId: rawHashId },
		lookup,
		nameToKeyLookup,
		lastUpdatedTs,
		uniqId,
		hashId,
		playId,
	};
};

/**
 * Remaps existing encapsulated data using different filters and options.
 */
const remapData = (
	srcData: IAvailableWagersData,
	playId: string,
	filterTo: Maybe<IAvailableWagersFilterToOpts>,
	opts?: IMethodAvailableWagerRemapDataOpts
): IAvailableWagersData => {
	const rawList = (srcData.raw?.list ?? []).slice();

	return newDataFromRawList(rawList, playId, { ...opts, filterTo });
};

/**
 * @returns Unique wager names in the specified data lookup.
 */
const getNames = (lookup: AvailableWagersDataLookup): string[] => {
	const list = Array.from(lookup.values());
	const resultMap = new Map<string, boolean>();

	list.forEach((entry) => {
		const wagerName = entry.name;

		if (wagerName.length === 0) {
			return; // Next entry
		}

		resultMap.set(wagerName, true);
	});

	return Array.from(resultMap.keys()).sort();
};

/**
 * @returns Unique context IDs in the specified data lookup.
 */
const getContextIds = (lookup: AvailableWagersDataLookup): string[] => {
	const list = Array.from(lookup.values());
	const resultMap = new Map<string, boolean>();

	list.forEach((entry) => {
		const contextId = entry.contextId;

		if (contextId.length === 0) {
			return; // Next entry
		}

		resultMap.set(contextId, true);
	});

	return Array.from(resultMap.keys()).sort();
};

/**
 * @returns Unique seat numbers in the specified data lookup.
 */
const getSeatNumbers = (lookup: AvailableWagersDataLookup): number[] => {
	const list = Array.from(lookup.values());
	const resultMap = new Map<string, boolean>();

	list.forEach((entry) => {
		const seatNumber = entry.seatNumber;

		resultMap.set(`${seatNumber}`, true);
	});

	return Array.from(resultMap.keys())
		.map((v) => Number(v))
		.sort();
};

/**
 * @returns Unique seat IDs in the specified data lookup.
 */
const getSeatIds = (lookup: AvailableWagersDataLookup): string[] => {
	const list = Array.from(lookup.values());
	const resultMap = new Map<string, boolean>();

	list.forEach((entry) => {
		const seatId = entry.seatId;

		if (seatId.length === 0) {
			return; // Next entry
		}

		resultMap.set(seatId, true);
	});

	return Array.from(resultMap.keys()).sort();
};

/**
 * @returns A copy of the specified raw data list.
 */
const copyRawList = (list: RawAvailableWagerList): RawAvailableWagerList => {
	return copyAnyObjectList<RawAvailableWagerEntry>(list);
};

/**
 * @returns A copy of the specified data list.
 */
const copyList = (list: AvailableWagersDataList): AvailableWagersDataList => {
	return copyAnyObjectList<IAvailableWagerDataEntry>(list);
};

/**
 * @returns A copy of the specified data lookup.
 */
const copyLookup = (lookup: AvailableWagersDataLookup): AvailableWagersDataLookup => {
	const copy = new Map<string, IAvailableWagerDataEntry>();
	for (const [key, value] of lookup) {
		copy.set(key, { ...value });
	}

	return copy;
};

/**
 * @returns A copy of the specified encapsulated data.
 */
const copyData = (data: IAvailableWagersData, opts?: Maybe<{ updatedTs?: Maybe<number> }>): IAvailableWagersData => {
	const newData: IAvailableWagersData = defaultData();
	newData.lastUpdatedTs = opts?.updatedTs ?? data.lastUpdatedTs;
	newData.lookup = copyLookup(data.lookup);
	newData.nameToKeyLookup = new Map<string, string[]>(data.nameToKeyLookup);
	newData.hashId = data.hashId;
	newData.playId = data.playId;
	newData.raw = data.raw != null ? { list: copyRawList(data.raw.list), hashId: data.raw.hashId } : null;

	return newData;
};

// ---- Exports -------------------------------------------------------------------------------------------------------

export { generateDataListHashId, generateRawListHashId, isRawListSameData };
export { copyData, copyList, copyLookup, copyRawList, defaultData, newDataFromRawList, remapData };
export { getNames, getContextIds, getSeatNumbers, getSeatIds };
