import { BufServiceType, ConnectCallOptions, ConnectPromiseClient } from '../../../bufbuild';
import { isObjectEqual } from '../../../helpers';
import { getSessionProviderAuthInstance } from '../AuthSession/utility';
import { PromiseClient } from '../Clients/PromiseClient';
import { IClientOpts } from '../Clients/types';
import { authenticateRequest, IAuthSessionProvider, SessionProvider } from '../helpers/authenticate';
import { IMethodServiceRequestOptionsOpts, IRequestOptions, IService, IServiceOpts } from './types';

abstract class Service<RpcServiceType extends BufServiceType> implements IService<RpcServiceType> {
	/* #region ---- Properties --------------------------------------------------------------------------------------- */

	/**
	 * Currently assigned options.
	 */
	protected _options: IServiceOpts = Service.defaultOptions();

	/**
	 * The service URL.
	 */
	protected _url: string = '';

	/**
	 * Promise client instance.
	 */
	protected _promiseClient!: PromiseClient<RpcServiceType>;

	/**
	 * Session provider used for authenticating requests.
	 */
	protected _session: Nullable<SessionProvider>;

	/* #endregion ---- Properties ------------------------------------------------------------------------------------ */

	/* #region ---- Constructor -------------------------------------------------------------------------------------- */

	constructor(url: string, opts?: IServiceOpts) {
		this._url = url;

		if (this._url === '') {
			throw new Error('[Service] Service url must be specified');
		}

		opts && this.setOptions(opts);

		this.initPromiseClient();
	}

	/* #endregion ---- Constructor ----------------------------------------------------------------------------------- */

	/* #region ---- Public ------------------------------------------------------------------------------------------- */

	/**
	 * Sets/initializes the class options.
	 *
	 * - Overrides the parent class method.
	 */
	public setOptions(opts: IServiceOpts) {
		const { newOpts, origOpts } = this.resolveOptions(opts);
		this._options = newOpts;
		this.onSetOptions(newOpts, origOpts);
	}

	/**
	 * Gets/sets the session instance.
	 */
	public get session(): Nullable<SessionProvider> {
		return this._session;
	}
	public set session(session: Nullable<SessionProvider>) {
		this._session = session;
	}

	/**
	 * Gets/sets the session token.
	 */
	public get token(): string {
		const session = this.getSessionInstance();
		return session?.token ?? '';
	}
	public set token(value: string) {
		const session = this.getSessionInstance();
		session != null && (session.token = value);
	}

	/**
	 * Gets/sets the service url.
	 */
	public get url(): string {
		return this._url;
	}
	public set url(val: string) {
		this.setUrl(val);
	}
	protected setUrl(val: string) {
		if (val === '') {
			throw new Error('[Service] Service url must not be empty');
		}
		if (val == this._url) {
			return;
		}

		const prev = this._url;
		this._url = val;

		this.onUrlChanged(this._url, prev);
	}

	/**
	 * Gets the RPC client instance.
	 */
	public get rpcClient(): ConnectPromiseClient<RpcServiceType> {
		return this._promiseClient.rpcClient;
	}

	/* #endregion ---- Public ---------------------------------------------------------------------------------------- */

	/* #region ---- Protected ---------------------------------------------------------------------------------------- */

	/**
	 * Resolves the options being passed in and returns the original and new options.
	 */
	protected resolveOptions(opts?: Maybe<IServiceOpts>) {
		const origOpts: IServiceOpts = {
			...Service.defaultOptions(),
			...this._options,
		};

		const newOpts: IServiceOpts = {
			...origOpts,
			...(opts ?? {}),
		};

		return { origOpts, newOpts };
	}

	/**
	 * Called after new options are set.
	 */
	protected onSetOptions(newOpts: IServiceOpts, origOpts: IServiceOpts) {
		if (newOpts.session !== this.session) {
			this._session = newOpts.session ?? null;
		}

		if (
			newOpts.clientOpts != null &&
			(origOpts == null || !isObjectEqual(newOpts.clientOpts, origOpts.clientOpts ?? {}))
		) {
			this.onClientOptsChanged(newOpts.clientOpts);
		}
	}

	/**
	 * Called after new client opts are set.
	 */
	protected onClientOptsChanged(_newVal: IClientOpts, _prevVal?: IClientOpts) {
		this.initPromiseClient();
	}

	/**
	 * Called after the service url is changed.
	 */
	protected onUrlChanged(_newVal: string, _prevVal?: string) {
		this.initPromiseClient();
	}

	/**
	 * Initializes the promise client instance.
	 */
	protected initPromiseClient() {
		this._promiseClient = this.createPromiseClient(this._url, this._options.clientOpts);
	}

	/**
	 * Creates the correct promise client instance. Must be implemented by the derived class.
	 */
	protected abstract createPromiseClient(url: string, clientOpts?: Maybe<IClientOpts>): PromiseClient<RpcServiceType>;

	/**
	 * Creates a request options object with authentication headers.
	 */
	protected requestOptions(opts?: Maybe<IMethodServiceRequestOptionsOpts>): IRequestOptions {
		opts = opts ?? {};
		const { noAuthRequired = false } = opts;

		let headers: Headers;

		if (!noAuthRequired) {
			headers = authenticateRequest(this.token, { headers: opts.callOpts?.headers });
		} else {
			const callOptHeaders = opts.callOpts?.headers;
			headers = callOptHeaders instanceof Headers ? callOptHeaders : new Headers(callOptHeaders ?? undefined);
		}

		const callOpts: ConnectCallOptions = {
			headers: headers,
		};

		const controller = opts?.controller ?? new AbortController();
		callOpts.signal = controller.signal;

		const options: IRequestOptions = {
			callOpts: callOpts,
			controller: controller,
		};

		return options;
	}

	/**
	 * Creates a new `PromiseClient` instance.
	 */
	protected newPromiseClient(
		rpcService: RpcServiceType,
		baseUrl: string,
		clientOpts?: Maybe<IClientOpts>
	): PromiseClient<RpcServiceType> {
		return new PromiseClient<RpcServiceType>(rpcService, baseUrl, clientOpts);
	}

	/**
	 * @returns The session instance associated with this service - if currently set, otherwise NULL.
	 */
	protected getSessionInstance(): Nullable<IAuthSessionProvider> {
		return this._session == null ? null : getSessionProviderAuthInstance(this._session);
	}

	/* #endregion ---- Protected ------------------------------------------------------------------------------------- */

	/* #region ---- Static ------------------------------------------------------------------------------------------- */

	/**
	 * @returns Defaults used for Stream class options.
	 */
	public static defaultOptions(): IServiceOpts {
		return {
			session: null,
			clientOpts: null,
		};
	}

	/* #endregion ---- Static ---------------------------------------------------------------------------------------- */
}

// ---- Exports -------------------------------------------------------------------------------------------------------

export { Service as default };
export { Service };
