import { BufMessage, ConnectError, ConnectIncomingDetail } from '../../../bufbuild';
import { CancelablePromiseError } from '../../../helpers';

/**
 * Creates a ConnectError from the given error input.
 *
 * @returns ConnectError
 */
const extractConnectError = (error: unknown): ConnectError => {
	if ((error as CancelablePromiseError)?.error) {
		return ConnectError.from((error as CancelablePromiseError)?.error);
	}

	return ConnectError.from(error);
};

/**
 * Extracts the error data from the promise error.
 *
 * @returns PlainObject contains error data.
 */
const extractConnectErrorData = (error: unknown): PlainObject => {
	const connectErr = extractConnectError(error);

	const { name, code, message } = connectErr;
	const details = decodeConnectErrorDetails(connectErr);

	const data: PlainObject = {
		name,
		code,
		message,
		details,
	};

	return data;
};

/**
 * Error details are sent as an array of objects containing a values Uint8Array.
 * This method converts the raw details into an array of strings.
 *
 * @returns String array of the decoded error details.
 */
const decodeConnectErrorDetails = (error: ConnectError): string[] => {
	const decoder = new TextDecoder();
	const details: string[] = [];

	error.details.forEach((detail: BufMessage | ConnectIncomingDetail) => {
		const value = (detail as ConnectIncomingDetail)?.value || null;

		if (value) {
			const decoded = decoder.decode(value);
			details.push(decoded);
		}
	});

	return details;
};

/**
 * Extracts the error data from the promise error.
 *
 * Note: Intended to work with CancelablePromiseError thrown by streams / services which wraps
 * the underlying ConnectError.
 *
 * @returns A human readable error string.
 */
const formatConnectError = (error: unknown): string => {
	const connectErr = extractConnectError(error);

	const { name, code, message } = connectErr;
	const details = decodeConnectErrorDetails(connectErr);

	return `type: ${name}, code: ${code}, message: ${message}, details: ${details.join(',')}`;
};

// ---- Export --------------------------------------------------------------------------------------------------------

export { decodeConnectErrorDetails, extractConnectError, extractConnectErrorData, formatConnectError };
