import { action, AnnotationsMap, computed, makeObservable, observable } from 'mobx';
import { DebugBaseMobXAnnotations } from '../../../../common';
import AvailableWagers from './AvailableWagers';
import { AvailableWagersMobXPrivateProps } from './types.mobx';

const AvailableWagersMobXAnnotations: AnnotationsMap<AvailableWagers, AvailableWagersMobXPrivateProps> = {
	// Inherit from DebugBase
	...DebugBaseMobXAnnotations,

	// ---- Observed -----------------------------------------------------

	// >> Properties
	_data: observable.struct,

	// ---- Computed -----------------------------------------------------

	// >> Public
	isEmpty: computed,
	size: computed,
	lookup: computed,
	list: computed,
	keys: computed,
	dataHashId: computed,
	data: computed,
	lastUpdatedTs: computed,
	playId: computed,
	availableWagerKeys: computed,
	names: computed,
	contextIds: computed,
	seatNumbers: computed,
	seatIds: computed,

	// ---- Actions ------------------------------------------------------

	// >> Public
	clear: action,
	populate: action,
	populateFromAvailPlayWagersData: action,
	filterTo: action,

	// >> Protected
	setLastUpdatedTs: action,
	setData: action,
	setPlayId: action,

	// ---- Excluded -----------------------------------------------------

	// >> Properties
	_isMobXBound: false,

	// >> Public
	isMobXBound: false,
};

/**
 * Makes the specified `AvailableWagers` instance an observable MobX object.
 */
const bindAvailableWagersMobX = (instance: AvailableWagers): boolean => {
	if (instance.isMobXBound) {
		return false;
	}

	makeObservable<AvailableWagers, AvailableWagersMobXPrivateProps>(instance, {
		...AvailableWagersMobXAnnotations,
	});

	instance.isMobXBound = true;

	return true;
};

// ---- Exports -------------------------------------------------------------------------------------------------------

export { AvailableWagersMobXAnnotations, bindAvailableWagersMobX };
