/**
 * Default expiry time for cache entries (in seconds)
 */
const CACHE_DEFAULT_EXPIRY_SECS = 24 * 60 * 60;

/**
 * Cache storage types
 */
enum StorageType {
	LOCAL = 'LOCAL',
	SESSION = 'SESSION',
}

// ---- Exports -------------------------------------------------------------------------------------------------------

export { CACHE_DEFAULT_EXPIRY_SECS, StorageType };
