import omit from 'lodash/omit';
import { resolveAmount, resolveAmounts, resolveCurrencyCode, resolveCurrencyExponent } from '../amounts';
import { filterNullUndefined } from '../shared';
import { IBalanceData, IBalanceDataExt, IExtendBalanceDataOpts } from './types/balanceExt';

/**
 * @returns An empty `IBalanceData` object with default values.
 */
const newEmptyBalanceData = (
	opts?: Maybe<{ amount?: Maybe<number>; currencyCode?: Maybe<string>; currencyExponent?: Maybe<number> }>
): IBalanceData => {
	const amount: number = opts?.amount ?? 0;
	const currencyCode: string = resolveCurrencyCode(opts?.currencyCode);
	const currencyExponent: number = resolveCurrencyExponent(opts?.currencyExponent);

	return {
		accountId: '',
		amount: BigInt(amount),
		currency: currencyCode,
		currencyExponent: currencyExponent,
		playerId: '',
		updatedAt: BigInt(0),
	};
};

/**
 * @returns An empty `IBalanceDataExt` object with default values.
 */
const newEmptyBalanceDataExt = (
	opts?: Maybe<{ amount?: Maybe<number>; currencyCode?: Maybe<string>; currencyExponent?: Maybe<number> }>
): IBalanceDataExt => {
	const { amount, amountReal, amountMoney, currencyCode, currencySymbol, currencyExponent } = resolveAmount(
		opts?.amount ?? 0,
		{
			currencyCode: opts?.currencyCode,
			currencyExponent: opts?.currencyExponent,
		}
	);

	return {
		accountId: '',
		amount,
		amountMoney,
		amountReal,
		currencyCode,
		currencyExponent,
		currencySymbol,
		playerId: '',
		raw: null,
		serverUpdatedTs: 0,
	};
};

/**
 * @returns {IBalanceDataExt} Extended version of `IBalanceData` with re-mapped types, props.
 */
const extendBalanceData = (src: IBalanceData, opts?: Maybe<IExtendBalanceDataOpts>): IBalanceDataExt => {
	const base = omit(src, ['currency', 'amount', 'updatedAt']);

	const formatCurrencyOpts = opts?.formatCurrencyOpts ?? null;

	const {
		amount,
		amountReal,
		amountMoney,
		currencyCode, // Currency actually used for amounts.
		currencySymbol, // Currency symbol actually used for amounts.
		currencyExponent, // Currency exponent actually used for amounts.
	} = resolveAmounts(
		{ amount: Number(src.amount ?? 0) },
		{ formatCurrencyOpts, currencyCode: src.currency, currencyExponent: src.currencyExponent }
	);

	return {
		...newEmptyBalanceDataExt(),
		...filterNullUndefined(base),
		raw: { ...src },

		amount,
		amountMoney,
		amountReal,
		currencyCode,
		currencySymbol,
		currencyExponent,
		serverUpdatedTs: Number(src.updatedAt),
	};
};

/**
 * @returns {IBalanceDataExt[]} Extended version of `IBalanceData[]` with re-mapped types, props.
 */
const extendBalanceDataList = (srcList: IBalanceData[]): IBalanceDataExt[] => {
	const result: IBalanceDataExt[] = [];

	for (const src of srcList) {
		result.push(extendBalanceData(src));
	}

	return result;
};

// ---- Export --------------------------------------------------------------------------------------------------------

export { extendBalanceData, extendBalanceDataList };
export { newEmptyBalanceData, newEmptyBalanceDataExt };

// Other
export * from './types/balanceExt';
