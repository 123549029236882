import { action, AnnotationsMap, computed, makeObservable, observable } from 'mobx';
import { DebugBaseMobXAnnotations } from '../../../../common';
import PlayWagerDefinitions from './PlayWagerDefinitions';
import { PlayWagerDefinitionsMobXPrivateProps } from './types.mobx';

const PlayWagerDefinitionsMobXAnnotations: AnnotationsMap<PlayWagerDefinitions, PlayWagerDefinitionsMobXPrivateProps> =
	{
		// Inherit from DebugBase
		...DebugBaseMobXAnnotations,

		// ---- Observed -----------------------------------------------------

		// >> Properties
		_data: observable.struct,

		// ---- Computed -----------------------------------------------------

		// >> Public
		isEmpty: computed,
		size: computed,
		lookup: computed,
		list: computed,
		keys: computed,
		dataHashId: computed,
		lastUpdatedTs: computed,
		data: computed,
		playId: computed,
		wagerIds: computed,
		wagerNames: computed,

		// ---- Actions ------------------------------------------------------

		// >> Public
		clear: action,
		populate: action,
		filterTo: action,

		// >> Protected
		setLastUpdatedTs: action,
		setData: action,
		setPlayId: action,

		// ---- Excluded -----------------------------------------------------

		// >> Properties
		_isMobXBound: false,

		// >> Public
		isMobXBound: false,
		get: false,
		getByName: false,
		has: false,
		clone: false,
		isRawDataSame: false,
	};

/**
 * Makes the specified `PlayWagerDefinitions` instance an observable MobX object.
 */
const bindPlayWagerDefinitionsMobX = (instance: PlayWagerDefinitions): boolean => {
	if (instance.isMobXBound) {
		return false;
	}

	makeObservable<PlayWagerDefinitions, PlayWagerDefinitionsMobXPrivateProps>(instance, {
		...PlayWagerDefinitionsMobXAnnotations,
	});

	instance.isMobXBound = true;

	return true;
};

// ---- Exports -------------------------------------------------------------------------------------------------------

export { PlayWagerDefinitionsMobXAnnotations, bindPlayWagerDefinitionsMobX };
