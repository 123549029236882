import { KumquatChoice, KumquatWager } from '../../../client/rpc/data/game';
import {
	AuxiliaryGameDataReply,
	ClearWagerReply,
	ClearWagersReply,
	GameHistoryReply,
	GetPlayReply,
	GetTableReply,
	GetTablesReply,
	MakeChoiceReply,
	MakeWagersReply,
	SitTableReply,
	StandTableReply,
} from '../../../client/rpc/replies/game';
import {
	AuxiliaryGameDataRequest,
	ClearWagerRequest,
	ClearWagersRequest,
	GameHistoryRequest,
	GetPlayRequest,
	GetTableRequest,
	GetTablesRequest,
	MakeChoiceRequest,
	MakeWagersRequest,
	SitTableRequest,
	StandTableRequest,
} from '../../../client/rpc/requests/game';
import { ICancelablePromiseExt, NewCancelablePromiseExt } from '../../../helpers';
import { Service } from '../../core/Service';
import { IClientOpts, IServiceOpts, IServiceRequestOptions } from '../../core/types';
import { GameClient } from '../../rpc/clients/game';
import { IGameService, IGetTableRequestFlags } from './types';

/**
 * Client game service.
 */
class GameService extends Service<typeof GameClient> implements IGameService {
	/* #region ---- CONSTRUCTOR -------------------------------------------------------------------------------------- */

	constructor(url: string, opts?: IServiceOpts) {
		super(url, opts);
	}

	/* #endregion ---- CONSTRUCTOR ----------------------------------------------------------------------------------- */

	/* #region ---- Public ------------------------------------------------------------------------------------------- */

	/**
	 * @returns Data for a single table from the server.
	 */
	public getTable(
		tableId: string,
		flags?: Maybe<IGetTableRequestFlags>,
		opts?: Maybe<IServiceRequestOptions>
	): ICancelablePromiseExt<GetTableReply> {
		const debugMethod = 'getTable';

		if (tableId === '') {
			throw new Error(`[${debugMethod}] TableId cannot be empty`);
		}

		const options = this.requestOptions(opts);

		const req: GetTableRequest = new GetTableRequest({
			tableId,
			includePlay: flags?.includePlay ?? false,
			includePlayConfig: flags?.includePlayConfig ?? false,
			compactPlaystateWagerDefinitions: flags?.compactPlaystateWagerDefinitions ?? false,
		});

		const promise = this.rpcClient.getTable(req, options.callOpts);

		return NewCancelablePromiseExt<GetTableReply>(promise, null, options.controller);
	}

	/**
	 * @returns A list of all available tables from the server.
	 */
	public getTables(opts?: Maybe<IServiceRequestOptions>): ICancelablePromiseExt<GetTablesReply> {
		const options = this.requestOptions(opts);
		const req: GetTablesRequest = new GetTablesRequest();
		const promise = this.rpcClient.getTables(req, options.callOpts);

		return NewCancelablePromiseExt<GetTablesReply>(promise, null, options.controller);
	}

	/**
	 * @returns Play data for the specified play - if it exists.
	 */
	public getPlay(
		playId: string,
		tableId: string,
		opts?: Maybe<IServiceRequestOptions>
	): ICancelablePromiseExt<GetPlayReply> {
		const debugMethod = 'getPlay';

		if (playId === '') {
			throw new Error(`[${debugMethod}] PlayId cannot be empty`);
		}
		if (tableId === '') {
			throw new Error(`[${debugMethod}] TableId cannot be empty`);
		}

		const options = this.requestOptions(opts);
		const req: GetPlayRequest = new GetPlayRequest({ playId, tableId });
		const promise = this.rpcClient.getPlay(req, options.callOpts);

		return NewCancelablePromiseExt<GetPlayReply>(promise, null, options.controller);
	}

	/**
	 * Places a set of wagers on the specified play for the current user.
	 *
	 * @returns Information about wagers that are currently placed on the play (pending, active or new).
	 */
	public makeWagers(
		playId: string,
		tableId: string,
		wagers: KumquatWager[],
		opts?: Maybe<IServiceRequestOptions>
	): ICancelablePromiseExt<MakeWagersReply> {
		const debugMethod = 'makeWagers';

		if (playId === '') {
			throw new Error(`[${debugMethod}] PlayId cannot be empty`);
		}
		if (tableId === '') {
			throw new Error(`[${debugMethod}] TableId cannot be empty`);
		}
		if (wagers.length === 0) {
			throw new Error(`[${debugMethod}] Wagers cannot be empty`);
		}

		const req: MakeWagersRequest = new MakeWagersRequest({ playId, tableId, wagers });

		return this.sendWagers(req, opts);
	}

	/**
	 * Sends a request to make wagers for the current user.
	 *
	 * @returns Information about wagers that are currently placed on the play (pending, active or new).
	 */
	public sendWagers(
		req: MakeWagersRequest,
		opts?: Maybe<IServiceRequestOptions>
	): ICancelablePromiseExt<MakeWagersReply> {
		const options = this.requestOptions(opts);
		const promise = this.rpcClient.makeWagers(req, options.callOpts);

		return NewCancelablePromiseExt<MakeWagersReply>(promise, null, options.controller);
	}

	/**
	 * Makes one or more choices on the specified play for the current user.
	 *
	 * @returns Active choices for the play & active player.
	 */
	public makeChoice(
		playId: string,
		tableId: string,
		choices: KumquatChoice[],
		opts?: Maybe<IServiceRequestOptions>
	): ICancelablePromiseExt<MakeChoiceReply> {
		const debugMethod = 'makeChoice';

		if (playId === '') {
			throw new Error(`[${debugMethod}] PlayId cannot be empty`);
		}
		if (tableId === '') {
			throw new Error(`[${debugMethod}] TableId cannot be empty`);
		}
		if (choices.length === 0) {
			throw new Error(`[${debugMethod}] Choices cannot be empty`);
		}

		const req: MakeChoiceRequest = new MakeChoiceRequest({ playId, tableId, choices });

		return this.sendChoice(req, opts);
	}

	/**
	 * Sends a request to make one or more choices for the current user.
	 *
	 * @returns Active choices for the play & active player.
	 */
	public sendChoice(
		req: MakeChoiceRequest,
		opts?: Maybe<IServiceRequestOptions>
	): ICancelablePromiseExt<MakeChoiceReply> {
		const options = this.requestOptions(opts);
		const promise = this.rpcClient.makeChoice(req, options.callOpts);

		return NewCancelablePromiseExt<MakeChoiceReply>(promise, null, options.controller);
	}

	/**
	 * Sits the current user at the specified table on the specified seat position. In multi-seat games, a single player
	 * can sit on multiple seats.
	 *
	 * @returns Confirmation that the user has been sat at the table in the specified position.
	 */
	public sitTable(
		tableId: string,
		seatPosition: number,
		opts?: Maybe<IServiceRequestOptions>
	): ICancelablePromiseExt<SitTableReply> {
		const debugMethod = 'sitTable';

		if (tableId === '') {
			throw new Error(`[${debugMethod}] TableId cannot be empty`);
		}
		if (seatPosition < 1) {
			throw new Error(`[${debugMethod}] Seat position must be greater than zero`);
		}

		const options = this.requestOptions(opts);
		const req: SitTableRequest = new SitTableRequest({ tableId, seatPosition: BigInt(seatPosition) });
		const promise = this.rpcClient.sitTable(req, options.callOpts);

		return NewCancelablePromiseExt<SitTableReply>(promise, null, options.controller);
	}

	/**
	 * Relinquishes ownership of the specified seat position for the current user at the specified table.
	 * In multi-seat games, a single player can sit on multiple seats.
	 *
	 * @returns An empty reply.
	 */
	public standTable(
		tableId: string,
		seatPosition: number,
		opts?: Maybe<IServiceRequestOptions>
	): ICancelablePromiseExt<StandTableReply> {
		const debugMethod = 'standTable';

		if (tableId === '') {
			throw new Error(`[${debugMethod}] TableId cannot be empty`);
		}
		if (seatPosition < 1) {
			throw new Error(`[${debugMethod}] Seat position must be greater than zero`);
		}

		const options = this.requestOptions(opts);
		const req: StandTableRequest = new StandTableRequest({ tableId, seatPosition: BigInt(seatPosition) });
		const promise = this.rpcClient.standTable(req, options.callOpts);

		return NewCancelablePromiseExt<StandTableReply>(promise, null, options.controller);
	}

	/**
	 * Clears the specified wager from the specified play for the current user.
	 *
	 * @returns An empty reply.
	 */
	public clearWager(
		tableId: string,
		playId: string,
		wagerId: string,
		contextId?: Maybe<string>,
		key?: Maybe<string>,
		opts?: Maybe<IServiceRequestOptions>
	): ICancelablePromiseExt<ClearWagerReply> {
		const options = this.requestOptions(opts);

		const req: ClearWagerRequest = new ClearWagerRequest({
			tableId,
			playId,
			wagerTypeId: wagerId,
			contextId: contextId ?? undefined,
			key: key ?? undefined,
		});

		const promise = this.rpcClient.clearWager(req, options.callOpts);

		return NewCancelablePromiseExt<ClearWagerReply>(promise, null, options.controller);
	}

	/**
	 * Clears all active wagers from the specified play for the current user.
	 *
	 * @returns An empty reply.
	 */
	public clearWagers(
		tableId: string,
		playId: string,
		opts?: Maybe<IServiceRequestOptions>
	): ICancelablePromiseExt<ClearWagersReply> {
		const options = this.requestOptions(opts);
		const req: ClearWagersRequest = new ClearWagersRequest({ tableId, playId });
		const promise = this.rpcClient.clearWagers(req, options.callOpts);

		return NewCancelablePromiseExt<ClearWagersReply>(promise, null, options.controller);
	}

	/**
	 * Sends a request to clear wagers for the current user.
	 *
	 * @returns An empty reply.
	 */
	public sendClearWagers(
		req: ClearWagersRequest,
		opts?: Maybe<IServiceRequestOptions>
	): ICancelablePromiseExt<ClearWagersReply> {
		const options = this.requestOptions(opts);
		const promise = this.rpcClient.clearWagers(req, options.callOpts);

		return NewCancelablePromiseExt<ClearWagersReply>(promise, null, options.controller);
	}

	/**
	 * @returns The history data of a given game for a specified table.
	 */
	public getGameHistory(
		gameType: string,
		pageNum: number,
		pageSize: number,
		opts?: Maybe<IServiceRequestOptions>
	): ICancelablePromiseExt<GameHistoryReply> {
		const debugMethod = 'getGameHistory';

		if (gameType === '') {
			throw new Error(`[${debugMethod}] game type cannot be empty`);
		}

		if (pageNum != null && pageNum <= 0) {
			throw new Error(`[${debugMethod}] page number must be 1 or higher`);
		}

		if (pageSize != null && pageSize <= 0) {
			throw new Error(`[${debugMethod}] page size must be greater than 0`);
		}

		const options = this.requestOptions(opts);
		const req: GameHistoryRequest = new GameHistoryRequest({
			gameType: gameType || '',
			pageNum: pageNum || 1,
			pageSize: pageSize || 1000,
		});

		const promise = this.rpcClient.getGameHistory(req, options.callOpts);

		return NewCancelablePromiseExt<GameHistoryReply>(promise, null, options.controller);
	}

	/**
	 * Sends a request to get the auxiliary data for a given game.
	 *
	 * @param key Auxiliary game data key.
	 * @param type Auxiliary game data type.
	 * @returns Auxiliary game data reply data.
	 */
	public getAuxiliaryGameData(
		key: string,
		type: string,
		opts?: Maybe<IServiceRequestOptions>
	): ICancelablePromiseExt<AuxiliaryGameDataReply> {
		const debugMethod = 'getAuxiliaryGameData';

		if (key === '') {
			throw new Error(`[${debugMethod}] key cannot be empty`);
		}

		if (type === '') {
			throw new Error(`[${debugMethod}] type cannot be empty`);
		}

		const options = this.requestOptions(opts);
		const req: AuxiliaryGameDataRequest = new AuxiliaryGameDataRequest({
			key,
			type,
		});

		const promise = this.rpcClient.getAuxiliaryGameData(req, options.callOpts);

		return NewCancelablePromiseExt<AuxiliaryGameDataReply>(promise, null, options.controller);
	}

	/* #endregion ---- Public ---------------------------------------------------------------------------------------- */

	/* #region ---- Protected ---------------------------------------------------------------------------------------- */

	/**
	 * @returns The promise client instance used by this service.
	 */
	protected createPromiseClient(url: string, clientOpts?: Maybe<IClientOpts>) {
		return this.newPromiseClient(GameClient, url, clientOpts);
	}

	/* #endregion ---- Protected ------------------------------------------------------------------------------------- */
}

// ---- Exports -------------------------------------------------------------------------------------------------------

export { GameService as default };
export { GameService };
