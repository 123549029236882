import { IClientRpcSdk } from '../ClientRpcSdk/types';
import { newChoiceActions } from './choice';
import { newDealerActions } from './dealer';
import { newDeviceActions } from './device';
import { newSeatActions } from './seat';
import { newStoreActions } from './store';
import { IClientRpcSdkActions } from './types';
import { newWagerActions } from './wager';

/**
 * Factory for creating SDK actions.
 */
const newActions = (sdk: IClientRpcSdk): IClientRpcSdkActions => {
	return Object.freeze({
		dealer: newDealerActions(sdk),
		device: newDeviceActions(sdk),
		seat: newSeatActions(sdk),
		store: newStoreActions(sdk),
		wager: newWagerActions(sdk),
		choice: newChoiceActions(sdk),
	});
};

// ---- Export --------------------------------------------------------------------------------------------------------

export { newActions };
