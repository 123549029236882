const DEFAULT_LDX_GAME_SERVER_TEMPLATE = 'https://{{GAME_SERVER_SUBDOMAIN}}.{{DEPLOYMENT}}.{{SERVER}}';
const DEFAULT_LDX_DEV_SERVER_TEMPLATE = 'https://{{DEV_SERVER_SUBDOMAIN}}.{{DEPLOYMENT}}.{{SERVER}}';
const DEFAULT_LDX_DEVICE_SERVER_TEMPLATE = 'https://{{DEVICE_SERVER_SUBDOMAIN}}.{{DEPLOYMENT}}.{{SERVER}}';
const DEFAULT_LDX_DEALER_SERVER_TEMPLATE = 'https://{{DEALER_SERVER_SUBDOMAIN}}.{{DEPLOYMENT}}.{{SERVER}}';
const DEFAULT_LDX_STUDIO_BACKOFFICE_SERVER_TEMPLATE =
	'https://{{STUDIO_BACKOFFICE_SERVER_SUBDOMAIN}}.{{DEPLOYMENT}}.{{SERVER}}';

const DEFAULT_LDX_GAME_SERVER_DOMAIN_NAME_TEMPLATE = 'https://{{GAME_SERVER_SUBDOMAIN}}.{{DOMAIN_NAME}}';
const DEFAULT_LDX_DEV_SERVER_DOMAIN_NAME_TEMPLATE = 'https://{{DEV_SERVER_SUBDOMAIN}}.{{DOMAIN_NAME}}';
const DEFAULT_LDX_DEVICE_SERVER_DOMAIN_NAME_TEMPLATE = 'https://{{DEVICE_SERVER_SUBDOMAIN}}.{{DOMAIN_NAME}}';
const DEFAULT_LDX_DEALER_SERVER_DOMAIN_NAME_TEMPLATE = 'https://{{DEALER_SERVER_SUBDOMAIN}}.{{DOMAIN_NAME}}';
const DEFAULT_LDX_STUDIO_BACKOFFICE_SERVER_DOMAIN_NAME_TEMPLATE =
	'https://{{STUDIO_BACKOFFICE_SERVER_SUBDOMAIN}}.{{DOMAIN_NAME}}';

const DEFAULT_LDX_GAME_SERVER_SUBDOMAIN = 'clientserver';
const DEFAULT_LDX_DEV_SERVER_SUBDOMAIN = 'clientserver-testing';
const DEFAULT_LDX_DEVICE_SERVER_SUBDOMAIN = 'studio-device';
const DEFAULT_LDX_DEALER_SERVER_SUBDOMAIN = 'studio-dealerver';
const DEFAULT_LDX_STUDIO_BACKOFFICE_SERVER_SUBDOMAIN = 'studio-controlplane';

const DEFAULT_DOMAIN_NAME = 'dev6.areax.app';

enum ConfigVarsLdx {
	TABLE_ID = 'TABLE_ID',
	APP_USERNAME = 'USERNAME',
	APP_PASSWORD = 'PASSWORD',
	ENABLE_DEALER_OVERLAY = 'ENABLE_DEALER_OVERLAY',
	GAME_SERVER_SUBDOMAIN = 'GAME_SERVER_SUBDOMAIN',
	GAME_SERVER_URI = 'GAME_SERVER_URI',
	GAME_SERVER_TEMPLATE = 'GAME_SERVER_TEMPLATE',
	DEV_SERVER_SUBDOMAIN = 'DEV_SERVER_SUBDOMAIN',
	DEV_SERVER_URI = 'DEV_SERVER_URI',
	DEV_SERVER_TEMPLATE = 'DEV_SERVER_TEMPLATE',
	DEVICE_SERVER_SUBDOMAIN = 'DEVICE_SERVER_SUBDOMAIN',
	DEVICE_SERVER_URI = 'DEVICE_SERVER_URI',
	DEVICE_SERVER_TEMPLATE = 'DEVICE_SERVER_TEMPLATE',
	DEALER_SERVER_SUBDOMAIN = 'DEALER_SERVER_SUBDOMAIN',
	DEALER_SERVER_URI = 'DEALER_SERVER_URI',
	DEALER_SERVER_TEMPLATE = 'DEALER_SERVER_TEMPLATE',
	STUDIO_BACKOFFICE_SERVER_SUBDOMAIN = 'STUDIO_BACKOFFICE_SERVER_SUBDOMAIN',
	STUDIO_BACKOFFICE_SERVER_URI = 'STUDIO_BACKOFFICE_SERVER_URI',
	STUDIO_BACKOFFICE_SERVER_TEMPLATE = 'STUDIO_BACKOFFICE_SERVER_TEMPLATE',
	OMNIBUS_AUTH_TOKEN = 'OMNIBUS_AUTH_TOKEN',

	GAME_TITLE = 'GAME_TITLE',
	THEMES = 'themes',
	DEPLOYMENT = 'DEPLOYMENT',
	SERVER_DOMAIN = 'SERVER',
	DOMAIN_NAME = 'DOMAIN_NAME',

	// Phenix
	ENABLE_PHENIX_TELEMETRY = 'ENABLE_PHENIX_TELEMETRY',
	PHENIX_AUTH_API_URI = 'PHENIX_AUTH_API_URI',
	PHENIX_CHANNEL_ID = 'PHENIX_CHANNEL_ID',
	PHENIX_CHANNEL_ALIAS = 'PHENIX_CHANNEL_ALIAS',
	PHENIX_STREAM_TOKEN = 'PHENIX_STREAM_TOKEN',
}

// ---- Export --------------------------------------------------------------------------------------------------------

export {
	DEFAULT_LDX_GAME_SERVER_TEMPLATE,
	DEFAULT_LDX_DEV_SERVER_TEMPLATE,
	DEFAULT_LDX_DEVICE_SERVER_TEMPLATE,
	DEFAULT_LDX_DEALER_SERVER_TEMPLATE,
	DEFAULT_LDX_STUDIO_BACKOFFICE_SERVER_TEMPLATE,
};

export {
	DEFAULT_LDX_GAME_SERVER_DOMAIN_NAME_TEMPLATE,
	DEFAULT_LDX_DEV_SERVER_DOMAIN_NAME_TEMPLATE,
	DEFAULT_LDX_DEVICE_SERVER_DOMAIN_NAME_TEMPLATE,
	DEFAULT_LDX_DEALER_SERVER_DOMAIN_NAME_TEMPLATE,
	DEFAULT_LDX_STUDIO_BACKOFFICE_SERVER_DOMAIN_NAME_TEMPLATE,
};

export {
	DEFAULT_LDX_GAME_SERVER_SUBDOMAIN,
	DEFAULT_LDX_DEV_SERVER_SUBDOMAIN,
	DEFAULT_LDX_DEVICE_SERVER_SUBDOMAIN,
	DEFAULT_LDX_DEALER_SERVER_SUBDOMAIN,
	DEFAULT_LDX_STUDIO_BACKOFFICE_SERVER_SUBDOMAIN,
};

export { DEFAULT_DOMAIN_NAME };

export { ConfigVarsLdx };
