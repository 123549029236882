/**********************************************************************************************************************
 * Initialization of the various RPC streams via factory methods.
 *********************************************************************************************************************/
import { DeviceDataStream, PlayDataStream, TableDataStream, UserDataStream } from '../../../client/stream';
import { filterNullUndefined } from '../../../helpers';
import { IStreamFactoryNewOpts, StreamConstructor } from './types';
import { newStream } from './utility';

/**
 * Factory class for creating RPC streams.
 */
class StreamFactory {
	/**
	 * @returns A new instance of the specified stream type.
	 */
	public static newStream<T>(Stream: StreamConstructor<T>, opts: IStreamFactoryNewOpts) {
		const { url, session } = opts;

		// Stream options
		const isDebugEnabled = opts?.isDebugEnabled ?? null;
		const debugLabel = opts?.debugLabel || null;
		const streamOpts = { ...opts?.streamOpts, ...filterNullUndefined({ isDebugEnabled, debugLabel }) };

		return newStream<T>(Stream, url, streamOpts, session);
	}

	/**
	 * @returns A new instance of the `TableDataStream`.
	 */
	public static newTableStream(opts: IStreamFactoryNewOpts): TableDataStream {
		return StreamFactory.newStream<TableDataStream>(TableDataStream, opts);
	}

	/**
	 * @returns A new instance of the `PlayDataStream`.
	 */
	public static newPlayStream(opts: IStreamFactoryNewOpts): PlayDataStream {
		return StreamFactory.newStream<PlayDataStream>(PlayDataStream, opts);
	}

	/**
	 * @returns A new instance of the `UserDataStream`.
	 */
	public static newUserStream(opts: IStreamFactoryNewOpts): UserDataStream {
		return StreamFactory.newStream<UserDataStream>(UserDataStream, opts);
	}

	/**
	 * @returns A new instance of the `DeviceDataStream`.
	 */
	public static newDeviceStream(opts: IStreamFactoryNewOpts): DeviceDataStream {
		return StreamFactory.newStream<DeviceDataStream>(DeviceDataStream, opts);
	}
}

// ---- Exports -------------------------------------------------------------------------------------------------------

export { StreamFactory as default };
export { StreamFactory };
