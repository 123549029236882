import { IClientRpcSdk } from '../ClientRpcSdk';
import { IClientRpcSdkStoreActions } from './types/store';

/**
 * Clears all stores via the SDK.
 */
const clearAllStores = (sdk: IClientRpcSdk) => {
	sdk.stores.getControllerObj().clearStores();
};

/**
 * Factory for creating store actions.
 */
const newStoreActions = (sdk: IClientRpcSdk): IClientRpcSdkStoreActions => {
	return Object.freeze({
		clearAllStores: () => clearAllStores(sdk),
	});
};

// ---- Export --------------------------------------------------------------------------------------------------------

export { newStoreActions };
