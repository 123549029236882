/**
 * Unique keys for all stores
 */
enum StoreKey {
	UserStore = 'user_store',
	TableStore = 'table_store',
	PlayStore = 'play_store',
	RulesStore = 'rules_store',
	ChatStore = 'chat_store',
	SettingsStore = 'settings_store',
	DealerHudStore = 'dealer_hud_store',
	TableHistoryStore = 'table_history_store',
	TableSeatsStore = 'table_seats_play_store',
}

// ---- Exports -------------------------------------------------------------------------------------------------------

export { StoreKey };
