import { ManagerBase } from '../lib/ManagerBase';
import { IResolutionManager, IResolutionManagerOpts, RawResolutionEntry, RawResolutionList } from './types.main';

/**
 * ResolutionManager utility class for determining which resolutions should be
 * displayed and which have been displayed already.
 */
class ResolutionManager extends ManagerBase implements IResolutionManager {
	/* #region ---- Properties --------------------------------------------------------------------------------------- */

	/**
	 * Currently assigned options.
	 */
	protected _options: IResolutionManagerOpts = ResolutionManager.defaultOptions();

	/**
	 * Map of displayed resolutions.
	 */
	protected _displayed: Map<string, boolean> = new Map<string, boolean>();

	/**
	 * List of all resolutions.
	 */
	protected _resolutions: RawResolutionList = [];

	/**
	 * List of resolutions that have not been displayed yet.
	 */
	protected _undisplayed: RawResolutionList = [];

	/**
	 * Wagers dictionary
	 */
	protected _wagersDict: StringDict = {};

	/**
	 * Sidebets dictionary
	 */
	protected _sidebetsDict: StringDict = {};

	/**
	 * Flag indicating if this class instance is currently bound to MobX as an observable.
	 */
	protected _isMobXBound: boolean = false;

	/* #endregion ---- Properties -------------------------------------------------------------------------------------*/

	/* #region ---- CONSTRUCTOR ---------------------------------------------------------------------------------------*/

	constructor(wagersDict: StringDict, sidebetsDict: StringDict, opts?: Maybe<IResolutionManagerOpts>) {
		super();

		this.setOptions(opts);

		this._wagersDict = wagersDict;
		this._sidebetsDict = sidebetsDict;
	}

	/* #endregion ---- CONSTRUCTOR ------------------------------------------------------------------------------------*/

	/* #region ---- Public ------------------------------------------------------------------------------------------- */

	/**
	 * ACTION
	 * Sets/initializes the class options.
	 */
	public setOptions(opts?: Maybe<IResolutionManagerOpts>) {
		if (opts == null) {
			return;
		}

		const { newOpts } = this.resolveOptions(opts);
		this._options = newOpts;
		this.onSetOptions(newOpts);
	}

	/**
	 * ACTION
	 * Resets this class instance back to the clear/initial state.
	 */
	public clear() {
		this._displayed.clear();
		this._undisplayed = [];
		this._resolutions = [];
	}

	/**
	 * ACTION
	 * Resets the displayed and undisplayed state.
	 */
	public reset() {
		this._displayed.clear();
		this._undisplayed = [];
	}

	/**
	 * List of all resolutions.
	 */
	public get resolutions(): RawResolutionList {
		return this._resolutions;
	}

	/**
	 * List of resolutions that have not been displayed.
	 */
	public get undisplayedResolutions(): RawResolutionList {
		return this._undisplayed;
	}

	/**
	 * List of current winning wagers.
	 */
	public get currentWinningWagers(): RawResolutionList {
		return this.getCurrentWinningSideBets();
	}

	/**
	 * Gets/sets whether or not this class instance is currently bound to MobX as an observable.
	 */
	public get isMobXBound(): boolean {
		return this._isMobXBound;
	}
	public set isMobXBound(value: boolean) {
		this._isMobXBound = value;
	}

	/**
	 * ACTION
	 * Set the resolutions data.
	 */
	public setResolvedWagers(resolutions: RawResolutionList) {
		this._resolutions = resolutions;

		// Set undisplayed based on what items are not set in the display list.
		this._undisplayed = this._resolutions.filter(
			(r: RawResolutionEntry) => !this._displayed.has(r.wagerKey) || this._displayed.get(r.wagerKey) === false
		);
	}

	/**
	 * ACTION
	 * Set the displayed resolutions.
	 */
	public setDisplayed(resolutionList: RawResolutionList) {
		resolutionList.forEach((r: RawResolutionEntry) => {
			this._displayed.set(r.wagerKey, true);
		});
	}

	/**
	 * @returns List of current winning wagers in the undisplayed resolution list.
	 */
	public getCurrentWinningWagers(): RawResolutionList {
		return this.undisplayedResolutions.filter(
			(value) => value.payoutAmount > 0 && Object.values(this._wagersDict).includes(value.wagerKey)
		);
	}
	/**
	 * @returns List of current winning sidebets in the undisplayed resolution list.
	 */
	public getCurrentWinningSideBets(): RawResolutionList {
		return this.undisplayedResolutions.filter(
			(value) => value.payoutAmount > 0 && Object.values(this._sidebetsDict).includes(value.wagerKey)
		);
	}

	/**
	 * @returns A JSON export of the pertinent data.
	 */
	public toJson(extended?: Maybe<boolean>): PlainObject {
		extended = extended ?? false;

		const result: PlainObject = {
			isEmpty: this.resolutions.length === 0,
			resolutions: this.resolutions.slice(),
			undisplayedResolutions: this.undisplayedResolutions.slice(),
			currentWinningWagers: this.currentWinningWagers.slice(),
			currentWinningSideBets: this.getCurrentWinningSideBets(),
		};

		if (extended) {
			result.isDebugEnabled = this.isDebugEnabled;
			result.debugClassLabel = this.debugClassLabel;
			result.options = { ...this._options };
			result.isMobXBound = this._isMobXBound;
		}

		return result;
	}

	/* #endregion ---- Public ---------------------------------------------------------------------------------------- */

	/* #region ---- Protected ---------------------------------------------------------------------------------------- */

	/**
	 * Overrides the base class method.
	 * Resolves the options being passed in and returns the original and new options.
	 */
	protected override resolveOptions(opts?: Maybe<IResolutionManagerOpts>) {
		const origOpts: IResolutionManagerOpts = {
			...ResolutionManager.defaultOptions(),
			...this._options,
		};

		const newOpts: IResolutionManagerOpts = {
			...origOpts,
			...(opts ?? {}),
		};

		return { origOpts, newOpts };
	}

	/**
	 * Extends the base class method.
	 * Called after new options are set.
	 */
	protected onSetOptions(newOpts: IResolutionManagerOpts) {
		super.onSetOptions(newOpts);
	}

	/**
	 * Overrides the base class property.
	 * @returns The label to use when debugging.
	 */
	protected get debugClassLabel(): string {
		return ResolutionManager.debugClassLabel();
	}

	/* #endregion ---- Protected ------------------------------------------------------------------------------------- */

	/* #region ---- Static ------------------------------------------------------------------------------------------- */

	/**
	 * STATIC
	 * @returns The default options data used by this class.
	 */
	public static defaultOptions(): IResolutionManagerOpts {
		return {
			...ManagerBase.defaultOptions(),
		};
	}

	/**
	 * STATIC
	 * @returns Label assigned to this class namespace.
	 */
	protected static debugClassLabel(): string {
		return `RpcLib.Manager.ResolutionManager`;
	}

	/* #endregion ---- Static ---------------------------------------------------------------------------------------- */
}

// ---- Exports -------------------------------------------------------------------------------------------------------

export { ResolutionManager as default };
export { ResolutionManager };
