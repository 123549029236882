import { StreamKey } from '..';
import { IStreamSubscriber, StreamManager } from '../../../client/core';
import {
	IDeviceGetDeviceMessagesReplyData,
	IGetPlayReplyData,
	IGetSelfReplyData,
	IGetTableReplyData,
	IPlayData,
	ITableData,
} from '../../../client/rpc';
import { DebugBase } from '../../../common';
import { isRawListSameData as isTableSeatsSameData } from '../../../helpers/data/lib/TableSeatAssignments/utility';
import { StoreManager } from '../../stores/StoreManager';
import { DeviceStreamSubscriber, PlayStreamSubscriber, TableStreamSubscriber, UserStreamSubscriber } from './types';
import { IStreamSubscriptionExtenders } from './types';
import { IStreamSubscriptions, IStreamSubscriptionsOpts } from './types';

/**
 * Sets up the default stream subscriptions.
 */
class StreamSubscriptions extends DebugBase implements IStreamSubscriptions {
	/* #region ---- Properties --------------------------------------------------------------------------------------- */

	/**
	 * Currently assigned options.
	 */
	protected override _options: IStreamSubscriptionsOpts = StreamSubscriptions.defaultOptions();

	/**
	 * Stream manager instance associated with this class.
	 */
	protected _streamManager: StreamManager;

	/**
	 * Play stream subscriber.
	 */
	protected _playStreamSubscriber: Nullable<PlayStreamSubscriber> = null;

	/**
	 * Table stream subscriber.
	 */
	protected _tableStreamSubscriber: Nullable<TableStreamSubscriber> = null;

	/**
	 * User stream subscriber.
	 */
	protected _userStreamSubscriber: Nullable<UserStreamSubscriber> = null;

	/**
	 * Device stream subscriber.
	 */
	protected _deviceStreamSubscriber: Nullable<DeviceStreamSubscriber> = null;

	/* #endregion ---- Properties ------------------------------------------------------------------------------------ */

	/* #region ---- CONSTRUCTOR ---------------------------------------------------------------------------------------*/

	constructor(streamManager: StreamManager, opts?: IStreamSubscriptionsOpts) {
		super();
		this._streamManager = streamManager;

		opts != null && this.setOptions(opts);
	}

	/* #endregion ---- CONSTRUCTOR ------------------------------------------------------------------------------------*/

	/* #region ---- Public ------------------------------------------------------------------------------------------- */

	/**
	 * Sets/initializes the class options.
	 *
	 * - Overrides the parent class method.
	 */
	public override setOptions(opts: IStreamSubscriptionsOpts) {
		const { newOpts } = this.resolveOptions(opts);
		this._options = newOpts;
		this.onSetOptions(newOpts);
	}

	/**
	 * Subscribes all stream subscribers based on the provided options.
	 */
	public subscribe(storeManager: StoreManager, extenders?: Maybe<IStreamSubscriptionExtenders>) {
		const opts = this._options;

		if (opts.tableStream === true) {
			this.subscribeTableStream(storeManager, extenders);
		}
		if (opts.playStream === true) {
			this.subscribePlayStream(storeManager, extenders);
		}
		if (opts.userStream === true) {
			this.subscribeUserStream(storeManager, extenders);
		}
		if (opts.deviceStream === true) {
			this.subscribeDeviceStream(storeManager, extenders);
		}
	}

	/**
	 * @returns A JSON export of the pertinent data.
	 */
	public toJson(extended?: Maybe<boolean>): PlainObject {
		extended = extended ?? false;

		const toJs = (val: unknown) => DebugBase.toJs(val, { extended });

		const result: PlainObject = {
			userStreamSubscriber: toJs(this._userStreamSubscriber),
			tableStreamSubscriber: toJs(this._tableStreamSubscriber),
			playStreamSubscriber: toJs(this._playStreamSubscriber),
		};

		if (extended) {
			result.options = { ...this._options };
			result._Debug = { ...super.toJson(extended) };
		}

		return result;
	}

	/* #endregion ---- Public ---------------------------------------------------------------------------------------- */

	/* #region ---- Protected ---------------------------------------------------------------------------------------- */

	/**
	 * Resolves the options being passed in and returns the original and new options.
	 *
	 * - Overrides the parent class method.
	 */
	protected override resolveOptions(opts?: Maybe<IStreamSubscriptionsOpts>) {
		const origOpts: IStreamSubscriptionsOpts = {
			...StreamSubscriptions.defaultOptions(),
			...this._options,
		};

		const newOpts: IStreamSubscriptionsOpts = {
			...origOpts,
			...(opts ?? {}),
		};

		return { origOpts, newOpts };
	}

	/**
	 * Registers the play stream subscriber with the stream manager.
	 */
	protected subscribePlayStream(storeManager: StoreManager, extenders?: Maybe<IStreamSubscriptionExtenders>) {
		if (this._playStreamSubscriber != null) {
			return;
		}

		this._playStreamSubscriber = this.playStreamSubscriber(storeManager, extenders?.playStreamSubscriber);
		this._streamManager.subscribe<IGetPlayReplyData>(StreamKey.PlayStream, this._playStreamSubscriber);
	}

	/**
	 * Registers the table stream subscriber with the stream manager.
	 */
	protected subscribeTableStream(storeManager: StoreManager, extenders?: Maybe<IStreamSubscriptionExtenders>) {
		if (this._tableStreamSubscriber != null) {
			return;
		}

		this._tableStreamSubscriber = this.tableStreamSubscriber(storeManager, extenders?.tableStreamSubscriber);
		this._streamManager.subscribe<IGetTableReplyData>(StreamKey.TableStream, this._tableStreamSubscriber);
	}

	/**
	 * Registers the user stream subscriber with the stream manager.
	 */
	protected subscribeUserStream(storeManager: StoreManager, extenders?: Maybe<IStreamSubscriptionExtenders>) {
		if (this._userStreamSubscriber != null) {
			return;
		}

		this._userStreamSubscriber = this.userStreamSubscriber(storeManager, extenders?.userStreamSubscriber);
		this._streamManager.subscribe<IGetSelfReplyData>(StreamKey.UserStream, this._userStreamSubscriber);
	}

	/**
	 * Registers the device stream subscriber with the stream manager.
	 */
	protected subscribeDeviceStream(storeManager: StoreManager, extenders?: Maybe<IStreamSubscriptionExtenders>) {
		if (this._deviceStreamSubscriber != null) {
			return;
		}

		this._deviceStreamSubscriber = this.deviceStreamSubscriber(storeManager, extenders?.deviceStreamSubscriber);
		this._streamManager.subscribe<IDeviceGetDeviceMessagesReplyData>(
			StreamKey.DeviceStream,
			this._deviceStreamSubscriber
		);
	}

	/**
	 * @returns Invokes the subscribers `onData` method if set.
	 */
	protected onDataExtended<T>(sub: Maybe<IStreamSubscriber<T>>, data: T) {
		sub?.onData && sub.onData(data);
	}

	/**
	 * Creates the table stream subscriber callbacks object.
	 */
	protected tableStreamSubscriber = (
		storeManager: StoreManager,
		extender?: Maybe<TableStreamSubscriber>
	): TableStreamSubscriber => {
		return {
			...extender,
			onData: (data: IGetTableReplyData) => {
				// Ignore empty data
				if (data == null || data.table == null) {
					return;
				}

				const { tableStore, playStore } = storeManager.getStores();

				// Set the TableStore data
				const tableData = data.table as ITableData;
				tableStore.setData(tableData);

				// Set the PlayStore table seats external data dependencies
				if (
					playStore.externalDataDeps.seatCount != tableStore.seatCount ||
					playStore.externalDataDeps.tableState != tableStore.state ||
					!isTableSeatsSameData(playStore.externalDataDeps.seats, tableStore.seatsList)
				) {
					playStore.updateExternalDataDeps({
						seatCount: tableStore.seatCount,
						seats: tableStore.seatsList,
						tableState: tableStore.state,
					});
				}

				// Ignore empty play data
				if (data.play == null) {
					return;
				}

				const allowPlayStoreUpdate = true;

				if (allowPlayStoreUpdate) {
					const playData = data.play as IPlayData;

					this.info(`Setting play data from table stream on PlayStore`, 'TableStream.onData', {
						play: playData,
					});

					playStore.setData(playData);
				}

				this.onDataExtended<IGetTableReplyData>(extender, data);
			},
		};
	};

	/**
	 * Creates the play stream subscriber callbacks object.
	 */
	protected playStreamSubscriber = (
		storeManager: StoreManager,
		extender?: Maybe<PlayStreamSubscriber>
	): PlayStreamSubscriber => {
		return {
			...extender,
			onData: (data: IGetPlayReplyData) => {
				// Ignore empty data
				if (data == null || data.play == null) {
					return;
				}

				// Set the PlayStore data
				const { playStore } = storeManager.getStores();
				const playData: IPlayData = data.play;
				playStore.setData(playData);

				this.onDataExtended<IGetPlayReplyData>(extender, data);
			},
		};
	};

	/**
	 * Creates the user stream subscriber callbacks object.
	 */
	protected userStreamSubscriber = (
		storeManager: StoreManager,
		extender?: Maybe<UserStreamSubscriber>
	): UserStreamSubscriber => {
		return {
			...extender,
			onData: (data: IGetSelfReplyData) => {
				// Ignore empty data
				if (data == null || data.playerId === '') {
					return;
				}

				const { userStore, playStore } = storeManager.getStores();

				// Set the UserStore data
				userStore.setData(data);

				// Set the playerId in the PlayStore external data dependencies
				if (playStore.externalDataDeps.activePlayerId != userStore.playerId) {
					playStore.updateExternalDataDeps({ activePlayerId: userStore.playerId });
				}

				this.onDataExtended<IGetSelfReplyData>(extender, data);
			},
		};
	};

	/**
	 * Creates the device stream subscriber callbacks object.
	 */
	protected deviceStreamSubscriber = (
		storeManager: StoreManager,
		extender?: Maybe<DeviceStreamSubscriber>
	): DeviceStreamSubscriber => {
		return {
			...extender,
			onData: (data: IDeviceGetDeviceMessagesReplyData) => {
				// Ignore empty data
				if (data == null || data.deviceId === '') {
					return;
				}

				// Set the DeviceStore data
				const { deviceStore } = storeManager.getStores();
				deviceStore.setData(data);

				this.onDataExtended<IDeviceGetDeviceMessagesReplyData>(extender, data);
			},
		};
	};

	/**
	 * @returns TRUE if the play stream is currently active.
	 */
	protected hasActivePlayStream(): boolean {
		const sm = this._streamManager;
		const streamKey = StreamKey.PlayStream;

		return sm.hasStream(streamKey) && sm.isActive(streamKey);
	}

	/* #endregion ---- Protected ------------------------------------------------------------------------------------- */

	/* #region ---- Static ------------------------------------------------------------------------------------------- */

	/**
	 * STATIC
	 * @returns The default options data used by this class.
	 */
	public static defaultOptions(): IStreamSubscriptionsOpts {
		return {
			...DebugBase.defaultOptions(),
			userStream: true,
			tableStream: true,
			playStream: true,
			deviceStream: true,
		};
	}

	/* #endregion ---- Static ---------------------------------------------------------------------------------------- */

	/* #region ---- Debug -------------------------------------------------------------------------------------------- */

	/**
	 * Overrides the parent class property.
	 *
	 * @returns The label to use when debugging.
	 */
	protected override get debugClassLabel(): string {
		return StreamSubscriptions.debugClassLabel();
	}

	/**
	 * STATIC
	 * @returns Label assigned to this class namespace.
	 */
	protected static debugClassLabel(): string {
		return `RpcLib.Sdk.StoreStreamSubscriptions`;
	}

	/* #endregion ---- Debug ----------------------------------------------------------------------------------------- */
}

// ---- Exports -------------------------------------------------------------------------------------------------------

export { StreamSubscriptions as default };
export { StreamSubscriptions };
