import { IClientRpcSdk } from '../ClientRpcSdk';
import { IClientRpcSdkAuthSelectors } from './types/auth';

/**
 * Determines if the user is logged in.
 */
const isUserLoggedIn = (sdk: IClientRpcSdk) => {
	return sdk.isLoggedIn;
};

/**
 * Factory for creating auth selectors.
 */
const newAuthSelectors = (sdk: IClientRpcSdk): IClientRpcSdkAuthSelectors => {
	return Object.freeze({
		isUserLoggedIn: () => isUserLoggedIn(sdk),
	});
};

// ---- Export --------------------------------------------------------------------------------------------------------

export { newAuthSelectors };
