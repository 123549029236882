import { set } from 'mobx';
import { DebugBase } from '../../../../common';
import { formatCurrency, IFormatCurrencyOpts } from '../../../../helpers';
import { bindWalletLocalBalancesMobX } from './mobx';
import {
	IUpdateLocalBalanceDataEntry,
	IUpdateLocalBalanceDiff,
	IWalletLocalBalanceDataEntry,
	IWalletLocalBalanceDataEntryDiff,
	IWalletLocalBalances,
	IWalletLocalBalancesData,
	IWalletLocalBalancesOpts,
	WalletLocalBalanceDataList,
	WalletLocalBalanceDataLookup,
} from './types';
import { copyData, copyLookup, defaultData, newEntryDataFrom, newFrom } from './utility';

/**
 * Stores and manages a collection of local balance data used for wallet manager balance tracking.
 */
class WalletLocalBalances extends DebugBase implements IWalletLocalBalances {
	/* #region ---- Properties --------------------------------------------------------------------------------------- */

	/**
	 * Currently assigned options.
	 */
	protected override _options: IWalletLocalBalancesOpts = WalletLocalBalances.defaultOptions();

	/**
	 * Encapsulated data.
	 */
	protected _data: IWalletLocalBalancesData;

	/**
	 * Flag indicating if this class instance is currently bound to MobX as an observable.
	 */
	protected _isMobXBound: boolean = false;

	/* #endregion ---- Properties -------------------------------------------------------------------------------------*/

	/* #region ---- CONSTRUCTOR ---------------------------------------------------------------------------------------*/

	constructor(opts?: Maybe<IWalletLocalBalancesOpts>) {
		super();

		this._data = opts?.data ?? defaultData();
		opts != null && this.setOptions(opts);

		// Bind to MobX as an observable.
		if (this._options.useMobX) {
			bindWalletLocalBalancesMobX(this);
		}
	}

	/* #endregion ---- CONSTRUCTOR ----------------------------------------------------------------------------------- */

	/* #region ---- Public ------------------------------------------------------------------------------------------- */

	/**
	 * Sets/initializes the class options.
	 *
	 * - Overrides the parent class method.
	 */
	public override setOptions(opts: IWalletLocalBalancesOpts) {
		const { newOpts } = this.resolveOptions(opts);

		if (this._isMobXBound) {
			set(this, '_options', newOpts);
		} else {
			this._options = newOpts;
		}

		this.onSetOptions(newOpts);
	}

	/**
	 * Whether or not the data collection is empty.
	 */
	public get isEmpty(): boolean {
		return this.size === 0;
	}

	/**
	 * Size of the data collection.
	 */
	public get size(): number {
		return this.lookup.size;
	}

	/**
	 * Lookup of local balance data keyed by currency code.
	 */
	public get lookup(): WalletLocalBalanceDataLookup {
		return this._data.lookup;
	}

	/**
	 * Flat array list of the local balance entries.
	 */
	public get list(): WalletLocalBalanceDataList {
		return Array.from(this.lookup.values());
	}

	/**
	 * The last time (unix timestamp) that the data was updated.
	 */
	public get lastUpdatedTs(): number {
		return this._data.lastUpdatedTs;
	}
	public set lastUpdatedTs(val: number) {
		this.setLastUpdatedTs(val);
	}
	// Actionable setter method for MobX.
	protected setLastUpdatedTs(val: number) {
		if (val === this._data.lastUpdatedTs) {
			return;
		}

		this._data.lastUpdatedTs = val;
	}

	/**
	 * The full data encapsulated by this class instance.
	 */
	public get data(): IWalletLocalBalancesData {
		return this._data;
	}
	public set data(val: IWalletLocalBalancesData) {
		this.setData(val);
	}
	// Actionable setter method for MobX.
	protected setData(val: IWalletLocalBalancesData) {
		if (val === this._data) {
			return;
		}

		if (this.isMobXBound) {
			set(this, '_data', val);
		} else {
			this._data = val;
		}
	}

	/**
	 * Gets/sets whether or not this class instance is currently bound to MobX as an observable.
	 */
	public get isMobXBound(): boolean {
		return this._isMobXBound;
	}
	public set isMobXBound(value: boolean) {
		this._isMobXBound = value;
	}

	/**
	 * @returns All defined currency codes in the data collection.
	 */
	public get currencyCodes(): string[] {
		return Array.from(this.lookup.keys());
	}

	/**
	 * The unique hash ID for the data collection.
	 */
	public get dataHashId(): string {
		return this.data.hashId ?? '';
	}

	/**
	 * @returns The balance data entry for the specified currency.
	 *          Will return NULL if balance data for the currency is not present.
	 */
	public get(currencyCode: string, opts?: Maybe<{ copy?: Maybe<boolean> }>): Nullable<IWalletLocalBalanceDataEntry> {
		const entry = this.lookup.get(currencyCode) ?? null;

		if (opts?.copy === true && entry != null) {
			return { ...entry };
		}

		return entry;
	}

	/**
	 * Sets the balance data entry for the specified currency.
	 */
	public set(currencyCode: string, entry: IWalletLocalBalanceDataEntry) {
		const data = copyData(this._data, { updatedTs: Date.now() });
		data.lookup.set(currencyCode, entry);

		// const list = Array.from(data.lookup.values());
		// data.hashId = generateListHashId(list);
		this.setData(data);
	}

	/**
	 * @returns TRUE if a balance for the specified currency exists.
	 */
	public has(currencyCode: string): boolean {
		return this.lookup.has(currencyCode);
	}

	/**
	 * @returns The current raw amount for the specified currency. eg. 5000.
	 *          Will return NULL if balance data for the currency is not present.
	 */
	public getAmount(currencyCode: string): Nullable<number> {
		return this.get(currencyCode)?.amount ?? null;
	}

	/**
	 * @returns The current real amount for the specified currency. eg. raw:5000 --> real:50.
	 *          Will return NULL if balance data for the currency is not present.
	 */
	public getAmountReal(currencyCode: string): Nullable<number> {
		return this.get(currencyCode)?.amountReal ?? null;
	}

	/**
	 * @returns The formatted actual amount for the specified currency. eg. "$50.00"
	 *          Will return NULL if balance data for the currency is not present.
	 */
	public getMoney(currencyCode: string, opts?: Maybe<IFormatCurrencyOpts>): Nullable<string> {
		const amountReal = this.getAmountReal(currencyCode);

		return amountReal != null ? formatCurrency(amountReal, { ...opts, currencyCode }) : null;
	}

	/**
	 * @returns The money symbol used for the specified currency. eg. Code "USD" --> Symbol "$"
	 *          Will return NULL if balance data for the currency is not present.
	 */
	public getCurrencySymbol(currencyCode: string): Nullable<string> {
		return this.get(currencyCode)?.currencySymbol ?? null;
	}

	/**
	 * Returns an array of the current local balance data entries.
	 *
	 * @returns An array of the current local balance data entries.
	 */
	public getBalancesList(copy?: Maybe<boolean>): WalletLocalBalanceDataList {
		const lookup = this.getBalancesLookup(copy);

		return Array.from(lookup.values());
	}

	/**
	 * Returns a lookup map of the current local balance data entries.
	 *
	 * @returns An map of the current local balance data entries keyed by currency code.
	 */
	public getBalancesLookup(copy?: Maybe<boolean>): WalletLocalBalanceDataLookup {
		copy = copy ?? true;

		return copy ? copyLookup(this.lookup) : this.lookup;
	}

	/**
	 * Resets the class data back to the initial/clear values.
	 */
	public clear() {
		const data = defaultData({ updatedTs: 0 });
		this.setData(data);
	}

	/**
	 * @returns A new clone of this class instance with copied data.
	 */
	public clone(
		opts?: Maybe<{
			updatedTs?: Maybe<number>;
		}>
	): WalletLocalBalances {
		const newData = copyData(this._data, { updatedTs: opts?.updatedTs });

		return new WalletLocalBalances({ ...this._options, data: newData, updatedTs: newData.lastUpdatedTs });
	}

	/**
	 * Extends the parent class method.
	 *
	 * @returns A JSON export of the current data.
	 */
	public override toJson(extended?: Maybe<boolean>): PlainObject {
		extended = extended ?? false;

		const data = copyData(this._data);

		const toJs = (val: unknown) => DebugBase.toJs(val, { extended, useMobXToJs: this.isMobXBound });

		const result: PlainObject = {
			lastUpdatedTs: data.lastUpdatedTs,
			size: data.lookup.size,
			isEmpty: data.lookup.size === 0,
			lookup: toJs(data.lookup),
			list: toJs(Array.from(data.lookup.values())),
			currencyCodes: toJs(Array.from(data.lookup.keys())),
		};

		if (extended) {
			result.extended = {
				isMobXBound: this.isMobXBound,
				options: toJs({ ...this._options }),
				rawData: toJs(data),

				// Debug info from base class
				_Debug: { ...super.toJson(extended) },
			};
		}

		return result;
	}

	/* #endregion ---- Public ---------------------------------------------------------------------------------------- */

	/* #region ---- Protected ---------------------------------------------------------------------------------------- */

	/**
	 * Overrides the parent class method.
	 * Resolves the options being passed in and returns the original and new options.
	 */
	protected override resolveOptions(opts?: Maybe<IWalletLocalBalancesOpts>) {
		const origOpts: IWalletLocalBalancesOpts = {
			...WalletLocalBalances.defaultOptions(),
			...this._options,
		};

		const newOpts: IWalletLocalBalancesOpts = {
			...origOpts,
			...(opts ?? {}),
		};

		return { origOpts, newOpts };
	}

	/**
	 * Called after new options are set.
	 *
	 * - Extends the parent class method
	 */
	protected override onSetOptions(newOpts: IWalletLocalBalancesOpts) {
		super.onSetOptions(newOpts);

		if (newOpts.data != null && (!this._data || newOpts.data.uniqId !== this._data.uniqId)) {
			this.setData(newOpts.data);
		}

		if (!this._data) {
			return;
		}
		if (newOpts.updatedTs != null && newOpts.updatedTs !== this._data.lastUpdatedTs) {
			this.setLastUpdatedTs(newOpts.updatedTs);
		}
	}

	/* #endregion ---- Protected ------------------------------------------------------------------------------------- */

	/* #region ---- Static ------------------------------------------------------------------------------------------- */

	/**
	 * STATIC
	 * @returns A clone of the specified class instance.
	 */
	public static cloneInstance(
		from: WalletLocalBalances,
		opts?: Maybe<{ updatedTs?: Maybe<number> }>
	): WalletLocalBalances {
		return from.clone(opts);
	}

	/**
	 * STATIC
	 * @returns The default options data used by this class.
	 */
	public static defaultOptions(): IWalletLocalBalancesOpts {
		return {
			...DebugBase.defaultOptions(),
			data: null,
			updatedTs: null,
			useMobX: true,
		};
	}

	/**
	 * STATIC
	 * Creates an updated copy of the specified class instance after adusting the entry for the specified currency.
	 *
	 * Data merge is immutable and operates on a copy. All returned objects are copies.
	 *
	 * @returns An diff summary of the class and entry data if successful, otherwise NULL.
	 */
	public static newFrom(
		currencyCode: string,
		newProps: IUpdateLocalBalanceDataEntry,
		fromInstance: IWalletLocalBalances
	): Nullable<IUpdateLocalBalanceDiff> {
		return newFrom(currencyCode, newProps, fromInstance);
	}

	/**
	 * STATIC
	 * Creates an updated version of the balance entry for the specified currency code by merging in the new values.
	 *
	 * Data merge is immutable and operates on a copy. All returned objects are copies.
	 *
	 * @returns A diff summary of the balance entry data if successful, otherwise NULL.
	 */
	public static newEntryDataFrom(
		currencyCode: string,
		newProps: IUpdateLocalBalanceDataEntry,
		fromInstance: IWalletLocalBalances
	): Nullable<IWalletLocalBalanceDataEntryDiff> {
		return newEntryDataFrom(currencyCode, newProps, fromInstance);
	}

	/* #endregion ---- Static ---------------------------------------------------------------------------------------- */

	/* #region ---- Debug -------------------------------------------------------------------------------------------- */

	/**
	 * Overrides the parent class property.
	 *
	 * @returns The label to use when debugging.
	 */
	protected override get debugClassLabel(): string {
		return WalletLocalBalances.debugClassLabel();
	}

	/**
	 * STATIC
	 * @returns Label assigned to this class namespace.
	 */
	protected static debugClassLabel(): string {
		return `RpcLib.DataObject.WalletLocalBalances`;
	}

	/* #endregion ---- Debug ----------------------------------------------------------------------------------------- */
}

// ---- Exports -------------------------------------------------------------------------------------------------------

export { WalletLocalBalances as default };
export { WalletLocalBalances };
