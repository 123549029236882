import { copyAnyObjectList, makeUniqId } from '../../../../helpers';
import {
	extendTableConfigWagerDefinitionData,
	IExtendTableConfigWagerDefinitionDataOpts,
} from '../../../data/tableConfigWagerDefinitionExt';
import { generateObjectListHashId } from '../../utility';
import {
	IMethodTableConfigWagerDefinitionsMapDataOpts,
	IMethodTableConfigWagerDefinitionsMapRawDataOpts,
	ITableConfigWagerDefinitionsData,
	ITableConfigWagerDefinitionsDataEntry,
	RawServerTableConfigWagerDefinitionEntry,
	RawServerTableConfigWagerDefinitionList,
	TableConfigWagerDefinitionsDataList,
	TableConfigWagerDefinitionsDataLookup,
} from './types';

/**
 * @returns The default data used by the `TableConfigWagerDefinitions` class.
 */
const defaultData = (
	opts?: Maybe<{ updatedTs?: Maybe<number>; tableId?: Maybe<string> }>
): ITableConfigWagerDefinitionsData => {
	return {
		raw: null,
		lookup: new Map<string, ITableConfigWagerDefinitionsDataEntry>(),
		lastUpdatedTs: opts?.updatedTs ?? 0,
		uniqId: makeUniqId(),
		hashId: '',
		tableId: opts?.tableId ?? '',
	};
};

/**
 * @returns A unique hash ID for the specified list of raw data entries.
 */
const generateRawListHashId = (list: RawServerTableConfigWagerDefinitionList): string => {
	return generateObjectListHashId<RawServerTableConfigWagerDefinitionEntry>(list, [
		'name',
		'isCategory',
		'minAmount',
		'maxAmount',
		'currency',
		'availableKeys',
	]);
};

/**
 * @returns A unique hash ID for the specified list of data entries.
 */
const generateDataListHashId = (list: TableConfigWagerDefinitionsDataList): string => {
	return generateObjectListHashId<ITableConfigWagerDefinitionsDataEntry>(list, [
		'name',
		'currencyCode',
		'minAmount',
		'maxAmount',
		'isCategory',
		'categoryMemberKeys',
		'tableId',
	]);
};

/**
 * @returns TRUE if the specified raw data lists are the same - in terms of the meaningful data.
 */
const isRawListSameData = (
	list1: RawServerTableConfigWagerDefinitionList,
	list2: RawServerTableConfigWagerDefinitionList
): boolean => {
	if (list1 === list2) {
		return true;
	}
	if (list1.length !== list2.length) {
		return false;
	}

	const hashId1 = generateRawListHashId(list1);
	const hashId2 = generateRawListHashId(list2);

	return hashId1 === hashId2;
};

/**
 * @returns TRUE if the specified data lists are the same - in terms of the meaningful data.
 */
const isDataListSameData = (
	list1: TableConfigWagerDefinitionsDataList,
	list2: TableConfigWagerDefinitionsDataList
): boolean => {
	if (list1 === list2) {
		return true;
	}
	if (list1.length !== list2.length) {
		return false;
	}

	const hashId1 = generateDataListHashId(list1);
	const hashId2 = generateDataListHashId(list2);

	return hashId1 === hashId2;
};

/**
 * Maps the raw data list sent by the server and resolves to the relevant encapsulated data.
 */
const newDataFromRawList = (
	srcList: RawServerTableConfigWagerDefinitionList,
	tableId: string,
	opts?: Maybe<IMethodTableConfigWagerDefinitionsMapRawDataOpts>
): ITableConfigWagerDefinitionsData => {
	const { updatedTs } = opts ?? {};
	const lastUpdatedTs: number = updatedTs || Date.now();

	if (srcList.length === 0) {
		return defaultData({ updatedTs: lastUpdatedTs, tableId });
	}

	const lookup = new Map<string, ITableConfigWagerDefinitionsDataEntry>();

	const extendDataOpts: IExtendTableConfigWagerDefinitionDataOpts = { ...opts?.extendDataOpts, tableId };

	srcList.forEach((entry: RawServerTableConfigWagerDefinitionEntry) => {
		const entryExt = extendTableConfigWagerDefinitionData(entry, extendDataOpts);

		const wagerName = entryExt.name;

		const item: ITableConfigWagerDefinitionsDataEntry = {
			...entryExt,
			lastUpdatedTs,
		};

		lookup.set(wagerName, item);
	});

	const list = Array.from(lookup.values());
	const hashId = generateDataListHashId(list);
	const uniqId = makeUniqId();

	const rawList = srcList.slice();
	const rawHashId = generateRawListHashId(rawList);

	return {
		raw: { list: rawList, hashId: rawHashId },
		lookup,
		lastUpdatedTs,
		uniqId,
		hashId,
		tableId,
	};
};

/**
 * Uses the data list specified and resolves to the relevant encapsulated data.
 */
const newDataFromList = (
	srcList: TableConfigWagerDefinitionsDataList,
	tableId: string,
	opts?: Maybe<IMethodTableConfigWagerDefinitionsMapDataOpts>
): ITableConfigWagerDefinitionsData => {
	const { updatedTs } = opts ?? {};
	const lastUpdatedTs: number = updatedTs || Date.now();

	if (srcList.length === 0) {
		return defaultData({ updatedTs: lastUpdatedTs, tableId });
	}

	const lookup = new Map<string, ITableConfigWagerDefinitionsDataEntry>();

	srcList.forEach((entry: ITableConfigWagerDefinitionsDataEntry) => {
		const wagerName = entry.name;

		const item: ITableConfigWagerDefinitionsDataEntry = {
			...entry,
			tableId: tableId || entry.tableId,
			lastUpdatedTs,
		};

		lookup.set(wagerName, item);
	});

	const list = Array.from(lookup.values());
	const hashId = generateDataListHashId(list);
	const uniqId = makeUniqId();

	return {
		raw: null,
		lookup,
		lastUpdatedTs,
		uniqId,
		hashId,
		tableId,
	};
};

/**
 * @returns A copy of the specified raw data list.
 */
const copyRawList = (list: RawServerTableConfigWagerDefinitionList): RawServerTableConfigWagerDefinitionList => {
	return copyAnyObjectList<RawServerTableConfigWagerDefinitionEntry>(list);
};

/**
 * @returns A copy of the specified data list.
 */
const copyList = (list: TableConfigWagerDefinitionsDataList): TableConfigWagerDefinitionsDataList => {
	return copyAnyObjectList<ITableConfigWagerDefinitionsDataEntry>(list);
};

/**
 * @returns A copy of the specified data lookup.
 */
const copyLookup = (lookup: TableConfigWagerDefinitionsDataLookup): TableConfigWagerDefinitionsDataLookup => {
	const copy = new Map<string, ITableConfigWagerDefinitionsDataEntry>();
	for (const [key, value] of lookup) {
		copy.set(key, { ...value });
	}

	return copy;
};

/**
 * @returns A copy of the specified encapsulated data.
 */
const copyData = (
	data: ITableConfigWagerDefinitionsData,
	opts?: Maybe<{ updatedTs?: Maybe<number> }>
): ITableConfigWagerDefinitionsData => {
	const newData: ITableConfigWagerDefinitionsData = defaultData();
	newData.lastUpdatedTs = opts?.updatedTs ?? data.lastUpdatedTs;
	newData.lookup = copyLookup(data.lookup);
	newData.hashId = data.hashId;
	newData.tableId = data.tableId;
	newData.raw = data.raw != null ? { list: copyRawList(data.raw.list), hashId: data.raw.hashId } : null;

	return newData;
};

// ---- Exports -------------------------------------------------------------------------------------------------------

export { generateRawListHashId, generateDataListHashId, isRawListSameData, isDataListSameData };
export { copyData, copyList, copyLookup, copyRawList, defaultData, newDataFromRawList, newDataFromList };
