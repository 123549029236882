import toString from 'lodash/toString';

const makeStreamPropHashId = (...args: unknown[]): string => {
	const argsNoEmpty = args.filter((a) => a != null && a !== '');

	return argsNoEmpty
		.map((a) => {
			if (typeof a === 'boolean') {
				return a ? '1' : '0';
			}

			return toString(a);
		})
		.join('_');
};

// ---- Exports -------------------------------------------------------------------------------------------------------

export { makeStreamPropHashId };
