/**********************************************************************************************************************
 * Stores and manages data regarding user-controlled game settings.
 *
 * Note: This will be used as the base class for the same store inside of the game clients.
 *********************************************************************************************************************/
import toInteger from 'lodash/toInteger';
import { DebugBase } from '../../common';
import { IToJsOpts } from '../../helpers';
import { ISettingsStore, ISettingsStoreOpts } from '../types';
import { ISettingsStoreData } from './types.main';

class SettingsStore extends DebugBase implements ISettingsStore {
	/* #region ---- Properties --------------------------------------------------------------------------------------- */

	/**
	 * The underlying data inside this store.
	 */
	protected _data: ISettingsStoreData;

	/**
	 * The unix timestamp (UTC) for when the data in this store was last updated.
	 */
	protected _lastUpdatedTs: number = 0;

	/**
	 * Flag indicating if this class instance is currently bound to MobX as an observable.
	 */
	protected _isMobXBound: boolean = false;

	/* #endregion ---- Properties -------------------------------------------------------------------------------------*/

	/* #region ---- CONSTRUCTOR ---------------------------------------------------------------------------------------*/

	constructor(opts?: Maybe<ISettingsStoreOpts>) {
		super();

		this._data = SettingsStore.defaultSettingsStoreData();

		opts != null && this.setOptions(opts);
	}

	/* #endregion ---- CONSTRUCTOR ----------------------------------------------------------------------------------- */

	/* #region ---- Public ------------------------------------------------------------------------------------------- */

	/**
	 * Sets/initializes the class options.
	 *
	 * - Overrides the parent class method.
	 */
	public override setOptions(opts: ISettingsStoreOpts) {
		const { newOpts } = this.resolveOptions(opts);
		this._options = newOpts;
		this.onSetOptions(newOpts);
	}

	/**
	 * The underlying data inside this store.
	 */
	public get data(): ISettingsStoreData {
		return this._data;
	}
	public set data(value: ISettingsStoreData) {
		this._data = value;
		this._lastUpdatedTs = Date.now();
	}

	/**
	 * @returns The unix timestamp (UTC) for when the data in this store was last updated.
	 */
	public get lastUpdatedTs() {
		return this._lastUpdatedTs;
	}

	/**
	 * @returns TRUE if this store is bound to MobX.
	 */
	public get isMobXBound(): boolean {
		return this._isMobXBound;
	}
	public set isMobXBound(value: boolean) {
		this._isMobXBound = value;
	}

	/**
	 * @returns TRUE if all audio should be muted.
	 */
	public get isMuted(): boolean {
		return this._data.isMuted;
	}
	public set isMuted(value: boolean) {
		this._data.isMuted = value;
		this.setUpdatedTs();
	}

	/**
	 * Video setting (0|1)
	 */
	public get videoSetting(): number {
		return this._data.videoSetting;
	}
	public set videoSetting(value: number) {
		value = Math.max(toInteger(value), 0);

		if (![0, 1].includes(value)) {
			throw new Error('[SettingsStore]: videoSettting - Value must be be 0 or 1');
		}

		this._data.videoSetting = value;
		this.setUpdatedTs();
	}

	/**
	 * Dealer volume % (percentage between 0.0 to 1.0)
	 */
	public get dealerVolume(): number {
		return this._data.dealerVolume;
	}
	public set dealerVolume(value: number) {
		value = Math.max(value, 0.0);
		if (value > 1.0) {
			throw new Error('[SettingsStore]: dealerVolume - Value must be between 0 and 1');
		}

		this._data.dealerVolume = value;
		this.setUpdatedTs();
	}

	/**
	 * Game volume % (percentage between 0.0 to 1.0)
	 */
	public get gameVolume(): number {
		return this._data.gameVolume;
	}
	public set gameVolume(value: number) {
		value = Math.max(value, 0.0);
		if (value > 1.0) {
			throw new Error('[SettingsStore]: gameVolume - Value must be between 0 and 1');
		}

		this._data.gameVolume = value;
		this.setUpdatedTs();
	}

	/**
	 * Ambient volume % (percentage between 0.0 to 1.0)
	 */
	public get ambientVolume(): number {
		return this._data.ambientVolume;
	}
	public set ambientVolume(value: number) {
		value = Math.max(value, 0.0);
		if (value > 1.0) {
			throw new Error('[SettingsStore]: ambientVolume - Value must be between 0 and 1');
		}

		this._data.ambientVolume = value;
		this.setUpdatedTs();
	}

	/**
	 * Chat enabled (TRUE/FALSE)
	 */
	public get isChatEnabled(): boolean {
		return this._data.isChatEnabled;
	}
	public set isChatEnabled(value: boolean) {
		this._data.isChatEnabled = value;
		this.setUpdatedTs();
	}

	/**
	 * Chat filter enabled (TRUE/FALSE)
	 */
	public get isChatFilterEnabled(): boolean {
		return this._data.isChatFilterEnabled;
	}
	public set isChatFilterEnabled(value: boolean) {
		this._data.isChatFilterEnabled = value;
		this.setUpdatedTs();
	}

	/**
	 * Action. Toggles the `isChatEnabled` setting.
	 */
	public toggleChatEnabled() {
		this.isChatEnabled = !this.isChatEnabled;
	}

	/**
	 * Action. Toggles the `isChatFilterEnabled` setting.
	 */
	public toggleChatFilterEnabled() {
		this.isChatFilterEnabled = !this.isChatFilterEnabled;
	}

	/**
	 * Action. Clear the store.
	 */
	public clear(): void {
		this._data = SettingsStore.defaultSettingsStoreData();
		this._lastUpdatedTs = 0;
	}

	/**
	 * @returns A JSON export of the current pertinent data.
	 */
	public override toJson(extended?: Maybe<boolean>): PlainObject {
		extended = extended ?? false;

		const toJs = (val: unknown, opts?: Maybe<IToJsOpts>) =>
			DebugBase.toJs(val, { ...opts, extended, useMobXToJs: this.isMobXBound });

		const result: PlainObject = {
			isMuted: this.isMuted,
			videoSetting: this.videoSetting,
			dealerVolume: this.dealerVolume,
			gameVolume: this.gameVolume,
			ambientVolume: this.ambientVolume,
			isChatEnabled: this.isChatEnabled,
			isChatFilterEnabled: this.isChatFilterEnabled,
		};

		if (extended) {
			result.extended = {
				isMobXBound: this.isMobXBound,
				lastUpdatedTs: this.lastUpdatedTs,
				rawData: toJs(this.data),
				_Debug: { ...super.toJson(extended) },
			};
		}

		return result;
	}

	/* #endregion ---- Public ---------------------------------------------------------------------------------------- */

	/* #region ---- Protected ---------------------------------------------------------------------------------------- */

	/**
	 * Action. Set the last updated timestamp to now.
	 */
	protected setUpdatedTs() {
		this._lastUpdatedTs = Date.now();
	}

	/**
	 * Resolves the options being passed in and returns the original and new options.
	 *
	 * - Overrides the parent class method.
	 */
	protected override resolveOptions(opts?: Maybe<ISettingsStoreOpts>) {
		const origOpts: ISettingsStoreOpts = {
			...SettingsStore.defaultOptions(),
			...this._options,
		};

		const newOpts: ISettingsStoreOpts = {
			...origOpts,
			...(opts ?? {}),
		};

		return { origOpts, newOpts };
	}

	/* #endregion ---- Protected ------------------------------------------------------------------------------------- */

	/* #region ---- Static ------------------------------------------------------------------------------------------- */

	/**
	 * STATIC
	 * @returns The default options data used by this class.
	 */
	public static defaultOptions(): ISettingsStoreOpts {
		return {
			...DebugBase.defaultOptions(),
		};
	}

	/**
	 * @returns Default settings values.
	 */
	public static defaultSettingsStoreData(): ISettingsStoreData {
		return {
			isMuted: false,
			videoSetting: 0,
			dealerVolume: 0.5,
			gameVolume: 0.3,
			ambientVolume: 0.1,
			isChatEnabled: true,
			isChatFilterEnabled: true,
		};
	}

	/* #endregion ---- Static ---------------------------------------------------------------------------------------- */

	/* #region ---- Debug -------------------------------------------------------------------------------------------- */

	/**
	 * Overrides the base class method.
	 *
	 * @returns The label to use when debugging.
	 */
	protected get debugClassLabel(): string {
		return SettingsStore.debugClassLabel();
	}

	/**
	 * STATIC
	 * @returns Label assigned to this class namespace.
	 */
	protected static debugClassLabel(): string {
		return `RpcLib.Store.SettingsStore`;
	}

	/* #endregion ---- Debug ----------------------------------------------------------------------------------------- */
}

// ---- Exports -------------------------------------------------------------------------------------------------------

export { SettingsStore as default };
export { SettingsStore };
