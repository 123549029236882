import { action, makeAutoObservable } from 'helpers/mobx';
import GameStore from './GameStore';

/**
 * Makes the specified `GameStore` instance an observable MobX object.
 */
const bindGameStoreMobX = (instance: GameStore): boolean => {
	if (instance.isMobXBound) {
		return false;
	}

	makeAutoObservable(instance, {
		clear: action,
	});

	instance.isMobXBound = true;

	return true;
};

// ---- Export --------------------------------------------------------------------------------------------------------

export { bindGameStoreMobX };
