import { ICancelablePromiseExt, NewCancelablePromiseExt } from '../../../helpers';
import { Service } from '../../core/Service';
import { IClientOpts, IServiceOpts, IServiceRequestOptions } from '../../core/types';
import { DealerServerClient } from '../../rpc/clients/dealerserver';
import {
	DealerAttachmentReply,
	DealerCloseSeatReply,
	DealerDetachmentReply,
	DealerEvictAllSeatsReply,
	DealerEvictOneSeatReply,
	DealerGetDealerReply,
	DealerGetHudReply,
	DealerListDealersReply,
	DealerNewPlayReply,
	DealerPausePlayReply,
	DealerRunPlayReply,
} from '../../rpc/replies/dealerserver';
import {
	DealerAttachmentRequest,
	DealerCloseSeatRequest,
	DealerDetachmentRequest,
	DealerEvictAllSeatsRequest,
	DealerEvictOneSeatRequest,
	DealerGetDealerRequest,
	DealerGetHudRequest,
	DealerListDealersRequest,
	DealerNewPlayRequest,
	DealerPausePlayRequest,
	DealerRunPlayRequest,
} from '../../rpc/requests/dealerserver';
import { IDealerService } from './types';

/**
 * Studio dealer service.
 */
class DealerService extends Service<typeof DealerServerClient> implements IDealerService {
	/* #region ---- CONSTRUCTOR -------------------------------------------------------------------------------------- */

	constructor(url: string, opts?: IServiceOpts) {
		super(url, opts);
	}

	/* #endregion ---- CONSTRUCTOR ----------------------------------------------------------------------------------- */

	/* #region ---- Public ------------------------------------------------------------------------------------------- */

	public newPlay(tableId: string, opts?: Maybe<IServiceRequestOptions>): ICancelablePromiseExt<DealerNewPlayReply> {
		const options = this.requestOptions(opts);
		const req: DealerNewPlayRequest = new DealerNewPlayRequest({ tableId });
		const promise = this.rpcClient.newPlay(req, options.callOpts);

		return NewCancelablePromiseExt<DealerNewPlayReply>(promise, null, options.controller);
	}

	public runPlay(tableId: string, opts?: Maybe<IServiceRequestOptions>): ICancelablePromiseExt<DealerRunPlayReply> {
		const options = this.requestOptions(opts);
		const req: DealerRunPlayRequest = new DealerRunPlayRequest({ tableId });
		const promise = this.rpcClient.runPlay(req, options.callOpts);

		return NewCancelablePromiseExt<DealerRunPlayReply>(promise, null, options.controller);
	}

	public pausePlay(tableId: string, opts?: Maybe<IServiceRequestOptions>): ICancelablePromiseExt<DealerPausePlayReply> {
		const options = this.requestOptions(opts);
		const req: DealerPausePlayRequest = new DealerPausePlayRequest({ tableId });
		const promise = this.rpcClient.pausePlay(req, options.callOpts);

		return NewCancelablePromiseExt<DealerPausePlayReply>(promise, null, options.controller);
	}

	public evictOneSeat(
		tableId: string,
		dealerId: string,
		seatPosition: number,
		opts?: Maybe<IServiceRequestOptions>
	): ICancelablePromiseExt<DealerEvictOneSeatReply> {
		const options = this.requestOptions(opts);
		const req: DealerEvictOneSeatRequest = new DealerEvictOneSeatRequest({
			tableId,
			dealerId,
			seatPosition: BigInt(seatPosition),
		});
		const promise = this.rpcClient.evictOneSeat(req, options.callOpts);

		return NewCancelablePromiseExt<DealerEvictOneSeatReply>(promise, null, options.controller);
	}

	public evictAllSeats(
		tableId: string,
		dealerId: string,
		opts?: Maybe<IServiceRequestOptions>
	): ICancelablePromiseExt<DealerEvictAllSeatsReply> {
		const options = this.requestOptions(opts);
		const req: DealerEvictAllSeatsRequest = new DealerEvictAllSeatsRequest({ tableId, dealerId });
		const promise = this.rpcClient.evictAllSeats(req, options.callOpts);

		return NewCancelablePromiseExt<DealerEvictAllSeatsReply>(promise, null, options.controller);
	}

	public closeSeat(
		tableId: string,
		dealerId: string,
		seatPosition: number,
		opts?: Maybe<IServiceRequestOptions>
	): ICancelablePromiseExt<DealerCloseSeatReply> {
		const options = this.requestOptions(opts);
		const req: DealerCloseSeatRequest = new DealerCloseSeatRequest({
			tableId,
			dealerId,
			seatPosition: BigInt(seatPosition),
		});
		const promise = this.rpcClient.closeSeat(req, options.callOpts);

		return NewCancelablePromiseExt<DealerCloseSeatReply>(promise, null, options.controller);
	}

	public attachDealer(
		tableId: string,
		dealerId: string,
		dealerName: string,
		opts?: Maybe<IServiceRequestOptions>
	): ICancelablePromiseExt<DealerAttachmentReply> {
		const options = this.requestOptions(opts);

		const req: DealerAttachmentRequest = new DealerAttachmentRequest({
			tableId,
			dealerId,
			dealerName,
		});

		const promise = this.rpcClient.attachDealer(req, options.callOpts);

		return NewCancelablePromiseExt<DealerAttachmentReply>(promise, null, options.controller);
	}

	public detachDealer(
		tableId: string,
		dealerId: string,
		opts?: Maybe<IServiceRequestOptions>
	): ICancelablePromiseExt<DealerDetachmentReply> {
		const options = this.requestOptions(opts);

		const req: DealerDetachmentRequest = new DealerDetachmentRequest({
			tableId,
			dealerId,
		});

		const promise = this.rpcClient.detachDealer(req, options.callOpts);

		return NewCancelablePromiseExt<DealerDetachmentReply>(promise, null, options.controller);
	}

	public listDealers(opts?: Maybe<IServiceRequestOptions>): ICancelablePromiseExt<DealerListDealersReply> {
		const options = this.requestOptions(opts);
		const req: DealerListDealersRequest = new DealerListDealersRequest();
		const promise = this.rpcClient.listDealers(req, options.callOpts);

		return NewCancelablePromiseExt<DealerListDealersReply>(promise, null, options.controller);
	}

	public getDealer(
		dealerId: string,
		includeTable?: Maybe<boolean>,
		opts?: Maybe<IServiceRequestOptions>
	): ICancelablePromiseExt<DealerGetDealerReply> {
		const options = this.requestOptions(opts);

		const req: DealerGetDealerRequest = new DealerGetDealerRequest({
			dealerId,
			includeTable: includeTable ?? false,
		});

		const promise = this.rpcClient.getDealer(req, options.callOpts);

		return NewCancelablePromiseExt<DealerGetDealerReply>(promise, null, options.controller);
	}

	public getHud(
		tableId: string,
		includePlay?: Maybe<boolean>,
		includePreviousPlay?: Maybe<boolean>,
		opts?: Maybe<IServiceRequestOptions>
	): ICancelablePromiseExt<DealerGetHudReply> {
		const options = this.requestOptions(opts);

		const req: DealerGetHudRequest = new DealerGetHudRequest({
			tableId,
			includePlay: includePlay ?? false,
			includePreviousPlay: includePreviousPlay ?? false,
		});

		const promise = this.rpcClient.getHUD(req, options.callOpts);

		return NewCancelablePromiseExt<DealerGetHudReply>(promise, null, options.controller);
	}

	/* #endregion ---- Public ---------------------------------------------------------------------------------------- */

	/* #region ---- Protected ---------------------------------------------------------------------------------------- */

	/**
	 * @returns The promise client instance used by this service.
	 */
	protected createPromiseClient(url: string, clientOpts?: Maybe<IClientOpts>) {
		return this.newPromiseClient(DealerServerClient, url, clientOpts);
	}

	/* #endregion ---- Protected ------------------------------------------------------------------------------------- */
}

// ---- Exports -------------------------------------------------------------------------------------------------------

export { DealerService as default };
export { DealerService };
