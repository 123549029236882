import { set } from 'mobx';
import { DebugBase } from '../../../../common';
import { bindTableConfigWagerDefinitionsMobX } from './mobx';
import {
	IMethodTableConfigWagerDefinitionsMapDataOpts,
	IMethodTableConfigWagerDefinitionsMapRawDataOpts,
	ITableConfigWagerDefinitionsData,
	ITableConfigWagerDefinitionsDataEntry,
	ITableConfigWagerDefinitionsOpts,
	RawServerTableConfigWagerDefinitionList,
	TableConfigWagerDefinitionsDataList,
	TableConfigWagerDefinitionsDataLookup,
} from './types';
import {
	copyData,
	defaultData,
	generateDataListHashId,
	generateRawListHashId,
	newDataFromList,
	newDataFromRawList,
} from './utility';

class TableConfigWagerDefinitions extends DebugBase {
	/* #region ---- Properties ------------------------------------------------------------------------------------- */

	/**
	 * Currently assigned options.
	 */
	protected override _options: ITableConfigWagerDefinitionsOpts = TableConfigWagerDefinitions.defaultOptions();

	/**
	 * Encapsulated data.
	 */
	protected _data: ITableConfigWagerDefinitionsData;

	/**
	 * Flag indicating if this class instance is currently bound to MobX as an observable.
	 */
	protected _isMobXBound: boolean = false;

	/* #endregion ---- Properties ---------------------------------------------------------------------------------- */

	/* #region ---- CONSTRUCTOR ------------------------------------------------------------------------------------ */

	constructor(opts?: Maybe<ITableConfigWagerDefinitionsOpts>) {
		super();

		this._data = opts?.data ?? defaultData();
		opts != null && this.setOptions(opts);

		// Bind to MobX as an observable.
		if (this._options.useMobX) {
			bindTableConfigWagerDefinitionsMobX(this);
		}
	}

	/* #endregion ---- CONSTRUCTOR --------------------------------------------------------------------------------- */

	/* #region ---- Public ----------------------------------------------------------------------------------------- */

	/**
	 * Sets/initializes the class options.
	 *
	 * - Overrides the parent class method.
	 */
	public override setOptions(opts: ITableConfigWagerDefinitionsOpts) {
		const { newOpts, origOpts } = this.resolveOptions(opts);

		if (this._isMobXBound) {
			set(this, '_options', newOpts);
		} else {
			this._options = newOpts;
		}

		this.onSetOptions(newOpts, origOpts);
	}

	/**
	 * Whether or not the data collection is empty.
	 */
	public get isEmpty(): boolean {
		return this.size === 0;
	}

	/**
	 * Size of the data collection.
	 */
	public get size(): number {
		return this.list.length;
	}

	/**
	 * Lookup of wager rule entries keyed by wager name.
	 */
	public get lookup(): TableConfigWagerDefinitionsDataLookup {
		return this._data.lookup;
	}

	/**
	 * Array of wager rule entries.
	 */
	public get list(): TableConfigWagerDefinitionsDataList {
		return Array.from(this.lookup.values());
	}

	/**
	 * @returns All defined unique keys (ie. wagerName) in the data lookup.
	 */
	public get keys(): string[] {
		return Array.from(this.lookup.keys());
	}

	/**
	 * The unique hash ID for the data collection.
	 */
	public get dataHashId(): string {
		return this._data?.hashId ?? '';
	}

	/**
	 * The last time (unix timestamp) that the data was updated.
	 */
	public get lastUpdatedTs(): number {
		return this._data.lastUpdatedTs;
	}
	public set lastUpdatedTs(val: number) {
		this.setLastUpdatedTs(val);
	}
	// Actionable setter method for MobX.
	protected setLastUpdatedTs(val: number) {
		if (val === this._data.lastUpdatedTs) {
			return;
		}
		this._data.lastUpdatedTs = val;
	}

	/**
	 * Get/set the table ID this data is associated with.
	 */
	public get tableId(): string {
		return this._data.tableId;
	}
	public set tableId(val: string) {
		this.setTableId(val);
	}
	// Actionable setter method for MobX.
	protected setTableId(val: string) {
		if (val === this._data.tableId) {
			return;
		}

		const prev = this._data.tableId;
		this._data.tableId = val;
		this.onTableIdChanged(this._data.tableId, prev);
	}

	/**
	 * Gets/sets whether or not this class instance is currently bound to MobX as an observable.
	 */
	public get isMobXBound(): boolean {
		return this._isMobXBound;
	}
	public set isMobXBound(value: boolean) {
		this._isMobXBound = value;
	}

	/**
	 * @returns All wager names in the data lookup.
	 */
	public get wagerNames(): string[] {
		return this.keys;
	}

	/**
	 * @returns The minimum amount across all wager rules.
	 */
	public get minAmount(): number {
		return this.resolveMinAmount();
	}

	/**
	 * @returns The minimum amount (real) across all wager rules.
	 */
	public get minAmountReal(): number {
		return this.minAmount / 100;
	}

	/**
	 * @returns The maximum amount across all wager rules.
	 */
	public get maxAmount(): number {
		return this.resolveMaxAmount();
	}

	/**
	 * @returns The maximum amount (real) across all wager rules.
	 */
	public get maxAmountReal(): number {
		return this.maxAmount / 100;
	}

	/**
	 * @returns The data entry for the specified wager name, or, NULL if not present.
	 */
	public get(
		wagerName: string,
		opts?: Maybe<{ copy?: Maybe<boolean> }>
	): Nullable<ITableConfigWagerDefinitionsDataEntry> {
		const entry = this.lookup.get(wagerName) ?? null;

		if (opts?.copy === true && entry != null) {
			return { ...entry };
		}

		return entry;
	}

	/**
	 * @returns TRUE if a data entry for specified wager name exists.
	 */
	public has(wagerName: string): boolean {
		return this.lookup.has(wagerName);
	}

	/**
	 * ACTION
	 * Sets the data entry for the specified wager name.
	 */
	public set(wagerName: string, entry: ITableConfigWagerDefinitionsDataEntry) {
		const data = copyData(this._data, { updatedTs: Date.now() });
		data.lookup.set(wagerName, entry);

		const list = Array.from(data.lookup.values());
		data.hashId = generateDataListHashId(list);
		this.setData(data);
	}

	/**
	 * Resets the class instance data back to the initial/clear values.
	 */
	public clear() {
		const data = defaultData({ updatedTs: 0, tableId: this.tableId });
		this.setData(data);
	}

	/**
	 * @returns A new clone of this class instance with copied data.
	 */
	public clone(opts?: Maybe<{ updatedTs?: Maybe<number> }>): TableConfigWagerDefinitions {
		const newData = copyData(this._data, { updatedTs: opts?.updatedTs });

		return new TableConfigWagerDefinitions({ ...this._options, data: newData, updatedTs: newData.lastUpdatedTs });
	}

	/**
	 * Overrides the parent class method.
	 *
	 * @returns A JSON export of the current data.
	 */
	public override toJson(extended?: Maybe<boolean>): PlainObject {
		extended = extended ?? false;

		const toJs = (val: unknown) => DebugBase.toJs(val, { extended, useMobXToJs: this.isMobXBound });

		const data = copyData(this._data);
		const list = Array.from(data.lookup.values());
		const keys = Array.from(data.lookup.keys());

		const result: PlainObject = {
			lastUpdatedTs: data.lastUpdatedTs,
			size: data.lookup.size,
			isEmpty: data.lookup.size === 0,
			tableId: data.tableId,
			list: toJs(list),
			lookup: toJs(data.lookup),
			wagerNames: toJs(keys),
			minAmount: this.resolveMinAmount(list),
			maxAmount: this.resolveMaxAmount(list),
		};

		if (extended) {
			result.extended = {
				isMobXBound: this.isMobXBound,
				options: toJs({ ...this._options }),
				rawData: toJs(data),
				dataHashId: this.dataHashId,

				// Debug info from base class
				_Debug: { ...super.toJson(extended) },
			};
		}

		return result;
	}

	/**
	 * Populates this instance using the raw wager rules list.
	 */
	public populate(
		rawList: RawServerTableConfigWagerDefinitionList,
		opts?: Maybe<IMethodTableConfigWagerDefinitionsMapRawDataOpts>
	): ITableConfigWagerDefinitionsData {
		const data = newDataFromRawList(rawList, this.tableId, opts);
		this.setData(data);

		return this._data;
	}

	/**
	 * @returns TRUE if the specified raw list is the same as the current one - in terms of the meaningful data.
	 */
	public isRawDataListSame(rawList: RawServerTableConfigWagerDefinitionList): boolean {
		const origHashId = this._data.raw?.hashId ?? '';
		const newHashId = generateRawListHashId(rawList);

		return newHashId === origHashId;
	}

	/**
	 * @returns TRUE if the specified data list is the same as the current one - in terms of the meaningful data.
	 */
	public isDataListSame(list: TableConfigWagerDefinitionsDataList): boolean {
		const origHashId = this._data.hashId;
		const newHashId = generateDataListHashId(list);

		return newHashId === origHashId;
	}

	/* #endregion ---- Public ---------------------------------------------------------------------------------------- */

	/* #region ---- Protected ---------------------------------------------------------------------------------------- */

	/**
	 * Sets the encapsulated class data. Actionable setter method for MobX.
	 */
	protected setData(val: ITableConfigWagerDefinitionsData) {
		if (val === this._data) {
			return;
		}
		if (this.isMobXBound) {
			set(this, '_data', val);
		} else {
			this._data = val;
		}
	}

	/**
	 * Resolves the options being passed in and returns the original and new options.
	 */
	protected resolveOptions(opts?: Maybe<ITableConfigWagerDefinitionsOpts>) {
		const origOpts: ITableConfigWagerDefinitionsOpts = {
			...TableConfigWagerDefinitions.defaultOptions(),
			...this._options,
		};

		const newOpts: ITableConfigWagerDefinitionsOpts = {
			...origOpts,
			...(opts ?? {}),
		};

		return { origOpts, newOpts };
	}

	/**
	 * Called after new options are set.
	 */
	protected onSetOptions(
		newOpts: ITableConfigWagerDefinitionsOpts,
		origOpts?: Maybe<ITableConfigWagerDefinitionsOpts>
	) {
		super.onSetOptions(newOpts, origOpts);

		if (newOpts.data != null && (!this._data || newOpts.data.uniqId !== this._data.uniqId)) {
			this.setData(newOpts.data);
		}

		if (!this._data) {
			return;
		}
		if (newOpts.updatedTs != null && newOpts.updatedTs !== this._data.lastUpdatedTs) {
			this.setLastUpdatedTs(newOpts.updatedTs);
		}
		if (newOpts.tableId != null && newOpts.tableId !== this._data.tableId) {
			this.setTableId(newOpts.tableId);
		}
	}

	/**
	 * Called after the table ID changes.
	 */
	protected onTableIdChanged(_newTableId: string, prevTableId: string) {
		if (prevTableId === '') {
			return;
		}

		// Since the table has changed, we need to clear the current data.
		this.clear();
	}

	/**
	 * @returns The minimum amount across all wager rules.
	 */
	protected resolveMinAmount(list?: TableConfigWagerDefinitionsDataList): number {
		list = list ?? this.list.slice();
		const MAX_INT = Number.MAX_SAFE_INTEGER;

		return (!this.isEmpty ? list.reduce((min, current) => Math.min(min, current.minAmount), MAX_INT) : null) ?? 0;
	}

	/**
	 * @returns The maximum amount across all wager rules.
	 */
	protected resolveMaxAmount(list?: TableConfigWagerDefinitionsDataList): number {
		list = list ?? this.list.slice();
		const MIN_INT = -Number.MAX_SAFE_INTEGER;

		return (!this.isEmpty ? list.reduce((max, current) => Math.max(max, current.maxAmount), MIN_INT) : null) ?? 0;
	}

	/* #endregion ---- Protected ------------------------------------------------------------------------------------- */

	/* #region ---- Static ------------------------------------------------------------------------------------------- */

	/**
	 * STATIC
	 * @returns The default options data used by this class.
	 */
	public static defaultOptions(): ITableConfigWagerDefinitionsOpts {
		return {
			...DebugBase.defaultOptions(),
			data: null,
			updatedTs: null,
			useMobX: true,
			tableId: null,
		};
	}

	/**
	 * STATIC
	 * @returns A clone of the specified class instance.
	 */
	public static cloneInstance(
		from: TableConfigWagerDefinitions,
		opts?: Maybe<{ updatedTs?: Maybe<number> }>
	): TableConfigWagerDefinitions {
		return from.clone(opts);
	}

	/**
	 * STATIC
	 * @returns A new instance of this class populated with the specified raw data.
	 */
	public static newFromRawList(
		rawList: RawServerTableConfigWagerDefinitionList,
		tableId: string,
		opts?: Maybe<{
			newInstanceOpts?: Maybe<ITableConfigWagerDefinitionsOpts>;
			populateOpts?: Maybe<IMethodTableConfigWagerDefinitionsMapRawDataOpts>;
		}>
	): TableConfigWagerDefinitions {
		const data = newDataFromRawList(rawList, tableId, opts?.populateOpts);

		return new TableConfigWagerDefinitions({ ...opts?.newInstanceOpts, data, updatedTs: data.lastUpdatedTs });
	}

	/**
	 * STATIC
	 * @returns A new instance of this class populated with the specified data.
	 */
	public static newFromList(
		list: TableConfigWagerDefinitionsDataList,
		tableId: string,
		opts?: Maybe<{
			newInstanceOpts?: Maybe<Omit<ITableConfigWagerDefinitionsOpts, 'tableId'>>;
			populateOpts?: Maybe<IMethodTableConfigWagerDefinitionsMapDataOpts>;
		}>
	): TableConfigWagerDefinitions {
		const data = newDataFromList(list, tableId, opts?.populateOpts);

		return new TableConfigWagerDefinitions({ ...opts?.newInstanceOpts, data, updatedTs: data.lastUpdatedTs });
	}

	/* #endregion ---- Static ---------------------------------------------------------------------------------------- */

	/* #region ---- Debug -------------------------------------------------------------------------------------------- */

	protected get debugClassLabel(): string {
		return TableConfigWagerDefinitions.debugClassLabel();
	}

	protected static debugClassLabel(): string {
		return `RpcLib.DataObject.TableConfigWagerDefinitions`;
	}

	/* #endregion ---- Debug ----------------------------------------------------------------------------------------- */
}

// ---- Exports -------------------------------------------------------------------------------------------------------

export { TableConfigWagerDefinitions as default };
export { TableConfigWagerDefinitions };
