import cloneDeep from 'lodash/cloneDeep';
import isFunction from 'lodash/isFunction';
import remove from 'lodash/remove';
import trim from 'lodash/trim';
import { Logger as DebugLogger, ILogger as IDebugLogger, newNoOpLogger } from '../logging';
import { entries } from '../object';
import { generateRandomString } from '../string';
import {
	EvmAddEventCallbackResult,
	EvmEventCallbackFn,
	EvmEventPayloadData,
	EvmRegisteredEvents,
	IEventManagerOpts,
	IEvmAddEventCallbackOpts,
	IEvmEventRegistrationData,
	IEvmOnEventOpts,
} from './types';
import { defaultOptions } from './utility';

/**
 * Event Manager. Used for registering and dispatching events.
 *
 * TODO: This should be moved to `areax-client-shared`.
 */
class EventManager {
	/**
	 * Debugger instance used by this class for debug output.
	 */
	protected _debug: IDebugLogger;

	/**
	 * Currently assigned options.
	 */
	protected _options: IEventManagerOpts;

	/**
	 * Instance ID used to identify this specific class instance. Used for debugging.
	 */
	protected _instanceId: string = generateRandomString();

	/**
	 * Currently registered event callbacks.
	 */
	protected _registeredEvents: EvmRegisteredEvents = {};

	/**
	 * @returns  Normalized event key.
	 */
	public static normalizeEventKey(eventKey: string) {
		return trim(eventKey).toUpperCase();
	}

	/**
	 * Determines if the specified event key is valid or not.
	 */
	public static isEventKeyValid(eventKey: string): boolean {
		eventKey = EventManager.normalizeEventKey(eventKey);

		return eventKey.length > 0;
	}

	/**
	 * CONSTRUCTOR
	 *
	 * @param  opts  Properties used to configure the class.
	 */
	public constructor(opts?: Maybe<IEventManagerOpts>) {
		this._options = defaultOptions();
		this._debug = newNoOpLogger();

		this.setOptions(opts);
	}

	/**
	 * Sets/initializes the class options.
	 */
	public setOptions(opts?: Maybe<IEventManagerOpts>) {
		if (opts == null) {
			return;
		}

		const newOpts = { ...defaultOptions(), ...this._options, ...(opts ?? {}) };
		this.isDebugEnabled = newOpts.isDebugEnabled;
		this.debugLabel = newOpts.debugLabel;
		this._options = newOpts;
	}

	/**
	 * Cleans up the various objects and resets the class back to the state it was in after being constructed.
	 */
	public cleanup() {
		this.unregisterAll();
	}

	/**
	 * Adds an event entry (ie. callback) for the specified event. All registered callbacks will be executed whenever the
	 * event is dispatched.
	 *
	 * @param    eventKey   The event to be handled.
	 * @param    callback   The callback to be executed when the event occurs.
	 * @param    opts       Optional properties. See interface.
	 * @returns  The event registration object if successful, otherwise FALSE.
	 */
	public on(eventKey: string, callback: EvmEventCallbackFn, opts?: Maybe<IEvmOnEventOpts>): EvmAddEventCallbackResult {
		// Do not allow the reserved instance-specific {ANY} event to be listened to from outside the class instance
		if (this.isEventKeyForReservedAnyEvent(eventKey)) {
			return false;
		}

		return this.addEventCallback(eventKey, callback, { ...(opts ?? {}), once: false });
	}

	/**
	 * Adds a one-time event entry (ie. callback) for the specified event. All registered callbacks will be executed
	 * whenever the event is dispatched. Any entries added via this method will be unregistered after being called ONE time.
	 *
	 * @param    eventKey   The event to be handled.
	 * @param    callback   The callback to be executed when the event occurs.
	 * @param    opts       Optional properties. See interface.
	 * @returns  The event registration object if successful, otherwise FALSE.
	 */
	public once(
		eventKey: string,
		callback: EvmEventCallbackFn,
		opts?: Maybe<IEvmOnEventOpts>
	): EvmAddEventCallbackResult {
		// Do not allow the reserved instance-specific {ANY} event to be listened to from outside the class instance
		if (this.isEventKeyForReservedAnyEvent(eventKey)) {
			return false;
		}

		return this.addEventCallback(eventKey, callback, { ...(opts ?? {}), once: true });
	}

	/**
	 * Removes a registered event entry (ie. callback) for the specified event key with the specified UID.
	 *
	 * @param    eventKey  The event key representing the event. Determines the array of event registrations that will
	 *                     be searched.
	 * @param    eventUid  Event callback unique ID.
	 * @returns  TRUE if the event entry was successfully found and removed.
	 */
	public off(eventKey: string, eventUid: string): boolean {
		return this.removeEventCallback(eventKey, eventUid);
	}

	/**
	 * Adds an event entry (ie. callback) for the reserved instance-specific {ANY} event. All registered callbacks will
	 * be executed whenever ANY OTHER event is dispatched.
	 *
	 * @param    callback   The callback to be executed when the event occurs.
	 * @param    once       Set to TRUE if this is a one-time only callback (ie. it will be removed after being called once).
	 * @returns  The event registration object if successful, otherwise FALSE.
	 */
	public any(callback: EvmEventCallbackFn, once?: Maybe<boolean>): EvmAddEventCallbackResult {
		once = once ?? false;
		const eventKey = this.reservedAnyEventKey;

		return this.addEventCallback(eventKey, callback, { once });
	}

	/**
	 * Clears all event entries (ie. callbacks) associated with the reserved instance-specific {ANY} event.
	 *
	 * @returns The number of entries cleared.
	 */
	public anyClear(): number {
		const eventKey = this.reservedAnyEventKey;

		return this.unregister(eventKey);
	}

	/**
	 * Executes all callback entries associated with the specified event.
	 *
	 * @param   eventKey   The event to be dispatched.
	 * @param   eventData  Optional. Any data associated with this event dispatch.
	 */
	public async dispatch(eventKey: string, eventData?: Maybe<EvmEventPayloadData>): Promise<boolean> {
		// Do not allow the reserved instance-specific {ANY} event to be dispatched from outside the class instance
		if (this.isEventKeyForReservedAnyEvent(eventKey)) {
			return false;
		}

		// Dispatch the specified event
		const result = this._dispatch(eventKey, eventData);

		// Also dispatch the reserved instance-specific {ANY} event - so callbacks registered with .any() get executed
		this._dispatch(this.reservedAnyEventKey, { eventKey, eventData: eventData ?? null });

		return result;
	}

	/**
	 * Protected inner workings of the dispatch logic.
	 *
	 * @param   eventKey   The event to be dispatched.
	 * @param   eventData  Optional. Any data associated with this event dispatch.
	 */
	protected async _dispatch(eventKey: string, eventData?: Maybe<EvmEventPayloadData>): Promise<boolean> {
		eventKey = EventManager.normalizeEventKey(eventKey);
		const entries = this.getEventCallbacks(eventKey);

		if (entries.length === 0) {
			return false;
		}

		this.debug.info(`Dispatching event '${eventKey}'`, 'dispatch', { eventData, callbacks: entries.slice() });

		let i = entries.length - 1;

		while (i >= 0) {
			const cb: Nullable<IEvmEventRegistrationData> = entries[i] ?? null;
			if (cb == null) {
				i--;
				continue;
			}

			const executeOnce = !!cb.once;

			if (executeOnce) {
				entries.splice(i, 1);

				this.debug.info(`${eventKey} >> Removing once-only event callback #${i}`, 'dispatch', {
					updated: entries.slice(),
				});
			}

			const data = { ...(cb.data ?? {}), ...(eventData ?? {}) };

			this.debug.info(`${eventKey} >> Executing event callback #${i}`, 'dispatch', {
				eventData: data,
				callback: cb,
				all: entries.slice(),
			});

			cb.callback(data);

			i--;
		}

		return true;
	}

	/**
	 * Returns event entries (ie. callbacks) currently registered for the specified event key.
	 *
	 * @param   eventKey  The event key/name.
	 * @returns Array of event entries (ie. callbacks) registered for the specified event key.
	 */
	public getEventCallbacks(eventKey: string) {
		eventKey = EventManager.normalizeEventKey(eventKey);

		return (eventKey.length > 0 && this._registeredEvents[eventKey]) || [];
	}

	/**
	 * Determines if the specified event key has any event entries (ie. callbacks) currently registered or not.
	 *
	 * @param   eventKey  The event key/name.
	 * @returns TRUE if the event key has any event entries (ie. callbacks) registered.
	 */
	public hasEventCallbacks(eventKey: string) {
		const callbacks = this.getEventCallbacks(eventKey);

		return callbacks.length > 0;
	}

	/**
	 * @returns A copy of all registered events.
	 */
	public getAllRegisteredEvents(): EvmRegisteredEvents {
		return cloneDeep(this._registeredEvents);
	}

	/**
	 * @returns A copy of all registered events in a flat array format. Order is not determinate.
	 */
	public getAllRegisteredEventsArray(): IEvmEventRegistrationData[] {
		const result: IEvmEventRegistrationData[] = [];

		entries(this._registeredEvents).forEach(([eventKey, callbacks]) => {
			if (this.isEventKeyForReservedAnyEvent(eventKey) || callbacks.length === 0) {
				return;
			}

			callbacks.forEach((cb) => {
				result.push({ ...cb });
			});
		});

		return result;
	}

	/**
	 * Clears all registered event entries (ie. callbacks) for the specified event key.
	 *
	 * @returns The number of entries cleared.
	 */
	public unregister(eventKey: string): number {
		eventKey = EventManager.normalizeEventKey(eventKey);

		const canClear = eventKey.length > 0 && this._registeredEvents[eventKey] != null;
		if (!canClear) {
			return 0;
		}

		const numEntriesCleared = this._registeredEvents[eventKey].length;
		this._registeredEvents[eventKey] = [];

		this.debug.info(`Cleared all event registrations for event '${eventKey}' (${numEntriesCleared})`, 'unregister');

		return numEntriesCleared;
	}

	/**
	 * Clears all currently registered event entries (ie. callbacks) for ALL event keys.
	 */
	public unregisterAll() {
		this._registeredEvents = {};
		this.debug.info('Unregistered ALL currently registered events', 'unregisterAll');
	}

	/**
	 * Adds a registered event entry (ie. callback) for the specified event key.
	 *
	 * @param    eventKey   The event to be handled.
	 * @param    callback   The callback function to be executed when the event occurs.
	 * @param    opts       Optional properties. See interface.
	 * @returns  The event registration object if successful, otherwise FALSE.
	 */
	protected addEventCallback(
		eventKey: string,
		callback: EvmEventCallbackFn,
		opts?: Maybe<IEvmAddEventCallbackOpts>
	): EvmAddEventCallbackResult {
		const once = opts?.once ?? false;
		const data = opts?.data ?? null;

		eventKey = EventManager.normalizeEventKey(eventKey);
		callback = callback || null;

		if (!EventManager.isEventKeyValid(eventKey) || !isFunction(callback)) {
			return false;
		}

		if (!this.hasEventCallbacks(eventKey)) {
			this._registeredEvents[eventKey] = [];
		}

		const eventUid = opts?.eventUid || generateRandomString(15);
		const eventRegister: IEvmEventRegistrationData = { callback, data, once, eventUid, eventKey };

		this._registeredEvents[eventKey].unshift(eventRegister);

		this.debug.info(`Added event callback for event '${eventKey}':`, 'addEventCallback', { newEntry: eventRegister });

		return eventRegister;
	}

	/**
	 * Removes a registered event entry (ie. callback) for the specified event with the specified UID.
	 *
	 * @param    eventKey  The event key representing the event. Determines the array of event registrations that will
	 *                     be searched.
	 * @param    eventUid  Event entry unique ID.
	 * @returns  TRUE if the event entry (ie. callback) was successfully found and removed.
	 */
	protected removeEventCallback(eventKey: string, eventUid: string) {
		eventKey = EventManager.normalizeEventKey(eventKey);
		eventUid = trim(eventUid);

		if (!EventManager.isEventKeyValid(eventKey) || eventUid.length === 0) {
			return false;
		}

		if (!this.hasEventCallbacks(eventKey)) {
			return false;
		}

		const removed =
			remove(this._registeredEvents[eventKey], (ev) => {
				return ev.eventUid === eventUid;
			}) || [];

		if (removed.length === 0) {
			return false;
		}

		this.debug.info(`Removed event callback for event '${eventKey}' with uid '${eventUid}':`, 'removeEventCallback', {
			updated: this._registeredEvents[eventKey].slice(),
		});

		return true;
	}

	protected isEventKeyForReservedAnyEvent(eventKey: string) {
		eventKey = EventManager.normalizeEventKey(eventKey);

		return eventKey === this.reservedAnyEventKey;
	}

	protected get reservedAnyEventKey(): string {
		return `__EventManager.${this._instanceId}.ANY__`.toUpperCase();
	}

	// ---- Debug -------------------------------------------------------------------------------------------------------

	public get isDebugEnabled(): boolean {
		return this._options.isDebugEnabled ?? false;
	}

	public set isDebugEnabled(isEnabled: Maybe<boolean>) {
		const origVal = this.isDebugEnabled;

		if (isEnabled == null || isEnabled === origVal) {
			return;
		}

		this._options.isDebugEnabled = isEnabled;
		this._debug = isEnabled ? this.newDebugLogger() : newNoOpLogger();
	}

	public get debugLabel(): string {
		return this._options.debugLabel ?? '';
	}

	public set debugLabel(val: Maybe<string>) {
		const origVal = this.debugLabel;

		if (val == null || val === origVal) {
			return;
		}

		this._options.debugLabel = val;
		this._debug.prefix = this.getDebugLogPrefix();
	}

	protected get debug(): IDebugLogger {
		return this._debug;
	}

	protected newDebugLogger(): IDebugLogger {
		return new DebugLogger(this.getDebugLogPrefix());
	}

	protected getDebugLogPrefix() {
		const debugLabel = this.debugLabel;
		const instanceId = this._instanceId;

		const add: string = [debugLabel.length > 0 ? `.${debugLabel}` : '', `.${instanceId}`].join('');
		return `EventManager${add}`;
	}
}

// ---- Exports -------------------------------------------------------------------------------------------------------

export { EventManager as default };
export { EventManager };
