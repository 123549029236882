import { formatConnectError } from '../../client/core/helpers';
import { KumquatWager } from '../../client/rpc/data';
import { IChoicePeriodDataExt, IPlayChoiceDefinitionDataExt, IPlayWagerDefinitionsDataEntry } from '../../helpers/data';
import { AvailableChoice } from '../../managers/ChoiceManager';
import { IClientRpcSdk } from '../ClientRpcSdk';
import { debug } from './debug';
import { IChoiceActionOpts, IClientRpcSdkChoiceActions } from './types';

/**
 * Creates a wager request data object.
 *
 * @returns KumquatWager if there are no errors. NULL if there were issues.
 */
const createChoiceWager = (
	sdk: IClientRpcSdk,
	contextId: string,
	wagerId: string,
	amount: number,
	currencyCode: string
): Nullable<KumquatWager> => {
	const debugMethod = 'createChoiceWager';
	const params: PlainObject = { contextId, wagerId, amount, currencyCode };

	if (contextId.length === 0) {
		debug.error('A valid context id must be provided', debugMethod, params);
		return null;
	}

	if (wagerId.length === 0) {
		debug.error('A valid wager id must be provided', debugMethod, params);
		return null;
	}

	if (currencyCode.length === 0) {
		debug.error('A valid currency code must be provided', debugMethod, params);
		return null;
	}

	const request: KumquatWager = new KumquatWager({
		wagerTypeId: wagerId,
		amount: BigInt(amount),
		currency: currencyCode,
		contextId: contextId,
	});

	return request;
};

/**
 * Creates a wager request data object.
 *
 * @returns [null, KumquatWager] if there are no errors. Otherwise [Error, null].
 */
const createChoiceWagerByWagerName = (
	sdk: IClientRpcSdk,
	contextId: string,
	wagerName: string,
	amount: number
): Nullable<KumquatWager> => {
	const debugMethod = 'createChoiceWagerByWagerName';
	const params: PlainObject = { contextId, wagerName, amount };

	const wager = sdk.managers.wagerManager.wagerDefinitions.getByName(wagerName);
	if (!wager) {
		debug.error('Wager not found', debugMethod, params);
		return null;
	}

	return createChoiceWager(sdk, contextId, wager.wagerId, amount, wager.currencyCode);
};

/**
 * Provides a convenient way to create a list of wager request objects for all wager ids in a given choice.
 * For example:
 *      const wagers = choiceRequestUtils.createWagersFromChoiceData(contextId, myChoice);
 *      wagers.get('wager_play')!.amount = 42;
 *      wagers.get('wager_x')!.amount = 550;
 *
 *      choiceRequestObject.wagers = Array.from(wagers.values());
 *
 * @returns Error if there were any errors found, otherwise a map of KumquatWager by wager definition name.
 *          NOTE: The amounts MUST BE provided by you!
 */
const createChoiceWagersFromChoiceData = (
	sdk: IClientRpcSdk,
	contextId: string,
	choice: IPlayChoiceDefinitionDataExt
): Map<string, KumquatWager> => {
	const debugMethod = 'createChoiceWagersFromChoiceData';
	const params: PlainObject = { contextId, choice };

	const wagerMap = new Map<string, KumquatWager>();
	const ln = choice.availableWagerIds.length;

	let wagerId: string = '';
	let wagerDefinition: Nullable<IPlayWagerDefinitionsDataEntry> = null;

	for (let i = 0; i < ln; i++) {
		wagerId = choice.availableWagerIds[i];
		wagerDefinition = sdk.managers.wagerManager.wagerDefinitions.get(wagerId);

		if (!wagerDefinition) {
			debug.error('Wager definition not found', debugMethod, { ...params, wagerId });
			continue;
		}

		const wager = createChoiceWager(sdk, contextId, wagerDefinition.wagerId, 0, wagerDefinition.currencyCode);
		if (wager === null) {
			continue;
		}

		wagerMap.set(wagerDefinition.name, wager);
	}

	return wagerMap;
};

/**
 * AvailableChoice factory method.
 *
 * @returns AvailableChoice instance.
 */
const createAvailableChoiceById = (
	sdk: IClientRpcSdk,
	choiceId: string,
	opts?: Maybe<IChoiceActionOpts>
): Nullable<AvailableChoice> => {
	const def = sdk.managers.choiceManager.findChoiceById(choiceId);
	if (!def) {
		return null;
	}

	return createAvailableChoiceFromDefinition(sdk, def, opts);
};

/**
 * AvailableChoice factory method.
 *
 * @returns AvailableChoice instance.
 */
const createAvailableChoiceByName = (
	sdk: IClientRpcSdk,
	choiceName: string,
	opts?: Maybe<IChoiceActionOpts>
): Nullable<AvailableChoice> => {
	const def = sdk.managers.choiceManager.findChoiceByName(choiceName);
	if (!def) {
		return null;
	}

	return createAvailableChoiceFromDefinition(sdk, def, opts);
};

/**
 * AvailableChoice factory method.
 *
 * @returns AvailableChoice instance.
 */
const createAvailableChoiceFromDefinition = (
	sdk: IClientRpcSdk,
	definition: IPlayChoiceDefinitionDataExt,
	opts?: Maybe<IChoiceActionOpts>
): Nullable<AvailableChoice> => {
	const debugMethod = 'createAvailableChoiceFromDefinition';

	let period: Nullable<IChoicePeriodDataExt> = null;

	if (opts?.usePeriod === true) {
		period = sdk.managers.choiceManager.findChoicePeriodById(definition.choicePeriodId);
		if (!period) {
			debug.warn(`Choice period not found`, debugMethod, {
				choiceId: definition.choiceId,
				choiceName: definition.name,
				periodId: definition.choicePeriodId,
			});
		}
	}

	const choice = AvailableChoice.newAvailableChoice({
		definition,
		period,
	});

	return choice;
};

/**
 * AvailableChoice list factory method.
 *
 * @returns AvailableChoice list based on choice manager available choice definitions.
 */
const createAvailableChoicesFromDefinitions = (
	sdk: IClientRpcSdk,
	opts?: Maybe<IChoiceActionOpts>
): AvailableChoice[] => {
	const debugMethod = 'createAvailableChoicesFromDefinitions';

	const choices: AvailableChoice[] = [];
	const definitions = sdk.managers.choiceManager.availableChoices;

	definitions.forEach((definition: IPlayChoiceDefinitionDataExt) => {
		let period: Nullable<IChoicePeriodDataExt> = null;

		if (opts?.usePeriod === true) {
			period = sdk.managers.choiceManager.findChoicePeriodById(definition.choicePeriodId);
			if (!period) {
				debug.warn(`Choice period not found`, debugMethod, {
					choiceId: definition.choiceId,
					choiceName: definition.name,
					periodId: definition.choicePeriodId,
				});
			}
		}

		const available = AvailableChoice.newAvailableChoice({
			definition,
			period,
		});

		choices.push(available);
	});

	return choices;
};

/**
 * Simple choice action used for choices without associated wagers such as 'hit', 'stand', 'surrender', etc. in
 * blackjack games.
 *
 * @returns Promise<TRUE> on success.
 */
const makeSimpleChoice = async (
	sdk: IClientRpcSdk,
	choiceName: string,
	opts?: Maybe<IChoiceActionOpts>
): Promise<boolean> => {
	const debugMethod = 'makeSimpleChoice';

	const { choiceManager } = sdk.managers;

	const choice = createAvailableChoiceByName(sdk, choiceName, opts);
	if (!choice) {
		debug.error('Failed to create choice', debugMethod, { choiceName });
		return Promise.resolve(false);
	}

	try {
		await choiceManager.makeChoice(choice);
		return Promise.resolve(true);
	} catch (e) {
		debug.error(formatConnectError(e), debugMethod, { choice });
		return Promise.resolve(false);
	}
};

/**
 * Factory for creating choice actions.
 */
const newChoiceActions = (sdk: IClientRpcSdk): IClientRpcSdkChoiceActions => {
	return Object.freeze({
		createChoiceWager: (contextId: string, wagerId: string, amount: number, currencyCode: string) =>
			createChoiceWager(sdk, contextId, wagerId, amount, currencyCode),

		createChoiceWagerByWagerName: (contextId: string, wagerName: string, amount: number) =>
			createChoiceWagerByWagerName(sdk, contextId, wagerName, amount),

		createChoiceWagersFromChoiceData: (contextId: string, choice: IPlayChoiceDefinitionDataExt) =>
			createChoiceWagersFromChoiceData(sdk, contextId, choice),

		createAvailableChoiceById: (choiceId: string, opts?: Maybe<IChoiceActionOpts>) =>
			createAvailableChoiceById(sdk, choiceId, opts),

		createAvailableChoiceByName: (choiceName: string, opts?: Maybe<IChoiceActionOpts>) =>
			createAvailableChoiceByName(sdk, choiceName, opts),

		createAvailableChoiceFromDefinition: (definition: IPlayChoiceDefinitionDataExt, opts?: Maybe<IChoiceActionOpts>) =>
			createAvailableChoiceFromDefinition(sdk, definition, opts),

		createAvailableChoicesFromDefinitions: (opts?: Maybe<IChoiceActionOpts>) =>
			createAvailableChoicesFromDefinitions(sdk, opts),

		makeSimpleChoice: async (choiceName: string, opts?: Maybe<IChoiceActionOpts>) =>
			makeSimpleChoice(sdk, choiceName, opts),
	});
};

// ---- Export --------------------------------------------------------------------------------------------------------

export { newChoiceActions };
