import { GetSelfReply } from '../../../client/rpc/replies';
import { GetSelfRequest } from '../../../client/rpc/requests';
import { ICancelablePromiseExt, NewCancelablePromiseExt } from '../../../helpers';
import { Service } from '../../core/Service';
import { IClientOpts, IServiceOpts, IServiceRequestOptions } from '../../core/types';
import { GameClient } from '../../rpc/clients/game';
import { IGetSelfRequestFlags, IUserService } from './types';

/**
 * Client user service.
 */
class UserService extends Service<typeof GameClient> implements IUserService {
	/* #region ---- CONSTRUCTOR -------------------------------------------------------------------------------------- */

	constructor(url: string, opts?: IServiceOpts) {
		super(url, opts);
	}

	/* #endregion ---- CONSTRUCTOR ----------------------------------------------------------------------------------- */

	/* #region ---- Public ------------------------------------------------------------------------------------------- */

	/**
	 * @returns User data for the currently logged in user.
	 */
	public getSelf(
		flags?: Maybe<IGetSelfRequestFlags>,
		opts?: Maybe<IServiceRequestOptions>
	): ICancelablePromiseExt<GetSelfReply> {
		const options = this.requestOptions(opts);

		const req: GetSelfRequest = new GetSelfRequest({
			includeArtifacts: flags?.includeArtifacts ?? true,
			includeBalances: flags?.includeBalances ?? true,
			includeRewards: flags?.includeRewards ?? true,
			includeRecentPlays: flags?.includeRecentPlays ?? true,
		});

		const promise = this.rpcClient.getSelf(req, options.callOpts);

		return NewCancelablePromiseExt<GetSelfReply>(promise, null, options.controller);
	}

	/* #endregion ---- Public ---------------------------------------------------------------------------------------- */

	/* #region ---- Protected ---------------------------------------------------------------------------------------- */

	/**
	 * @returns The promise client instance used by this service.
	 */
	protected createPromiseClient(url: string, clientOpts?: Maybe<IClientOpts>) {
		return this.newPromiseClient(GameClient, url, clientOpts);
	}

	/* #endregion ---- Protected ------------------------------------------------------------------------------------- */
}

// ---- Exports -------------------------------------------------------------------------------------------------------

export { UserService as default };
export { UserService };
