import { action, AnnotationsMap, computed, makeObservable, observable } from 'mobx';
import { DebugBaseMobXAnnotations } from '../../../../common';
import { WalletLocalBalancesMobXPrivateProps } from './types';
import WalletLocalBalances from './WalletLocalBalances';

const WalletLocalBalancesMobXAnnotations: AnnotationsMap<WalletLocalBalances, WalletLocalBalancesMobXPrivateProps> = {
	// Inherit from DebugBase
	...DebugBaseMobXAnnotations,

	// ---- Observed -----------------------------------------------------

	// >> Properties
	_data: observable.struct,

	// ---- Computed -----------------------------------------------------

	// >> Public
	isEmpty: computed,
	size: computed,
	lookup: computed,
	list: computed,
	lastUpdatedTs: computed,
	data: computed,
	currencyCodes: computed,

	// ---- Actions ------------------------------------------------------

	// >> Public
	set: action,
	clear: action,

	// >> Protected
	setLastUpdatedTs: action,
	setData: action,

	// ---- Excluded -----------------------------------------------------

	// >> Properties
	_isMobXBound: false,

	// >> Public
	isMobXBound: false,
};

/**
 * Makes the specified `WalletLocalBalances` instance an observable MobX object.
 */
const bindWalletLocalBalancesMobX = (instance: WalletLocalBalances): boolean => {
	if (instance.isMobXBound) {
		return false;
	}

	makeObservable<WalletLocalBalances, WalletLocalBalancesMobXPrivateProps>(instance, {
		...WalletLocalBalancesMobXAnnotations,
	});

	instance.isMobXBound = true;

	return true;
};

// ---- Exports -------------------------------------------------------------------------------------------------------

export { WalletLocalBalancesMobXAnnotations, bindWalletLocalBalancesMobX };
