import isArray from 'lodash/isArray';
import isString from 'lodash/isString';
import { renderTemplate } from '../string';
import ConfigAdapterBase from './ConfigAdapterBase';
import {
	ConfigVars,
	DEFAULT_BACKOFFICE_SERVER_TEMPLATE,
	DEFAULT_CHAT_SERVER_TEMPLATE,
	DEFAULT_DEPLOYMENT,
	DEFAULT_DEVICE_SERVER_TEMPLATE,
	DEFAULT_SERVER_DOMAIN,
	DEFAULT_SPOKE,
	DEFAULT_SPOKE_SERVER_TEMPLATE,
} from './constants';

class ConfigAdapter extends ConfigAdapterBase {
	public get tableKey(): string {
		return this.getStringVar(ConfigVars.TABLE_KEY);
	}

	public get userName(): string {
		return this.getStringVar(ConfigVars.APP_USERNAME);
	}

	public get password(): string {
		return this.getStringVar(ConfigVars.APP_PASSWORD);
	}

	public get useDealerOverlay(): boolean {
		return this.getBoolVar(ConfigVars.DEALER_OVERLAY);
	}

	public get serverURI(): string {
		const val = this.getStringVar(ConfigVars.SERVER_URI);

		return val || this.serverURIFromTemplate();
	}

	public serverURIFromTemplate(template?: string): string {
		template = template || this.spokeServerTemplate;

		const conf: StringDict = {
			[ConfigVars.SPOKE]: this.spoke || DEFAULT_SPOKE,
			[ConfigVars.DEPLOYMENT]: this.deployment || DEFAULT_DEPLOYMENT,
			[ConfigVars.SERVER_DOMAIN]: this.serverDomain || DEFAULT_SERVER_DOMAIN,
		};

		return this.fromTemplate(template, conf);
	}

	public get deviceServerURI(): string {
		const val = this.getStringVar(ConfigVars.DEVICE_SERVER_URI);

		return val || this.deviceServerURIFromTemplate();
	}

	public deviceServerURIFromTemplate(template?: string): string {
		template = template || this.deviceServerTemplate;

		const conf: StringDict = {
			[ConfigVars.DEPLOYMENT]: this.deployment || DEFAULT_DEPLOYMENT,
			[ConfigVars.SERVER_DOMAIN]: this.serverDomain || DEFAULT_SERVER_DOMAIN,
		};

		return this.fromTemplate(template, conf);
	}

	public get backOfficeServerURI(): string {
		const val = this.getStringVar(ConfigVars.BACKOFFICE_SERVER_URI);

		return val || this.backOfficeServerURIFromTemplate();
	}

	public backOfficeServerURIFromTemplate(template?: string): string {
		template = template || this.backOfficeServerTemplate;

		const conf: StringDict = {
			[ConfigVars.DEPLOYMENT]: this.deployment || DEFAULT_DEPLOYMENT,
			[ConfigVars.SERVER_DOMAIN]: this.serverDomain || DEFAULT_SERVER_DOMAIN,
		};

		return this.fromTemplate(template, conf);
	}

	public get chatServerURI(): string {
		const val = this.getStringVar(ConfigVars.CHAT_SERVER_URI);

		return val || this.chatServerURIFromTemplate();
	}

	public chatServerURIFromTemplate(template?: string): string {
		template = template || this.chatServerTemplate;

		const conf: StringDict = {
			[ConfigVars.DEPLOYMENT]: this.deployment || DEFAULT_DEPLOYMENT,
			[ConfigVars.SERVER_DOMAIN]: this.serverDomain || DEFAULT_SERVER_DOMAIN,
		};

		return this.fromTemplate(template, conf);
	}

	public get spokeServerTemplate(): string {
		return this.getStringVar(ConfigVars.SPOKE_SERVER_TEMPLATE) || DEFAULT_SPOKE_SERVER_TEMPLATE;
	}

	public get backOfficeServerTemplate(): string {
		return this.getStringVar(ConfigVars.BACKOFFICE_SERVER_TEMPLATE) || DEFAULT_BACKOFFICE_SERVER_TEMPLATE;
	}

	public get deviceServerTemplate(): string {
		return this.getStringVar(ConfigVars.DEVICE_SERVER_TEMPLATE) || DEFAULT_DEVICE_SERVER_TEMPLATE;
	}

	public get chatServerTemplate(): string {
		return this.getStringVar(ConfigVars.CHAT_SERVER_TEMPLATE) || DEFAULT_CHAT_SERVER_TEMPLATE;
	}

	public get streamToken(): string {
		return this.getStringVar(ConfigVars.PHENIX_STREAM_TOKEN);
	}

	public get themes(): Array<string> {
		const themes = this.getGlobalVar(ConfigVars.THEMES);
		if (themes == null || !isArray(themes)) {
			return [];
		}

		const filtered: string[] = [];

		(themes as Array<unknown>).forEach((t: unknown) => {
			t = t ?? '';
			if (isString(t) && t !== '') {
				filtered.push(t);
			}
		});

		return filtered;
	}

	// public get ldxConfig(): StringDict {}

	public get gameTitle(): string {
		return this.getStringVar(ConfigVars.GAME_TITLE);
	}

	public get spoke(): string {
		return this.getStringVar(ConfigVars.SPOKE);
	}

	public get deployment(): string {
		return this.getStringVar(ConfigVars.DEPLOYMENT);
	}

	public get serverDomain(): string {
		return this.getStringVar(ConfigVars.SERVER_DOMAIN);
	}

	/**
	 * Phenix
	 */
	public get enablePhenixTelemetry(): boolean {
		return this.getBoolVar(ConfigVars.ENABLE_PHENIX_TELEMETRY);
	}
	public get phenixAuthApiURI(): string {
		return this.getStringVar(ConfigVars.PHENIX_AUTH_API_URI);
	}
	public get phenixChannelId(): string {
		return this.getStringVar(ConfigVars.PHENIX_CHANNEL_ID);
	}
	public get phenixChannelAlias(): string {
		return this.getStringVar(ConfigVars.PHENIX_CHANNEL_ALIAS);
	}
	public get phenixStreamToken(): string {
		return this.streamToken;
	}

	public get resolvedProps(): PlainObject {
		const allProps: PlainObject = {
			[ConfigVars.TABLE_KEY]: this.tableKey,
			[ConfigVars.APP_USERNAME]: this.userName,
			[ConfigVars.APP_PASSWORD]: this.password,
			[ConfigVars.DEALER_OVERLAY]: this.useDealerOverlay,
			[ConfigVars.SERVER_URI]: this.serverURI,
			[ConfigVars.DEVICE_SERVER_URI]: this.deviceServerURI,
			[ConfigVars.BACKOFFICE_SERVER_URI]: this.backOfficeServerURI,
			[ConfigVars.CHAT_SERVER_URI]: this.chatServerURI,
			[ConfigVars.SPOKE_SERVER_TEMPLATE]: this.spokeServerTemplate,
			[ConfigVars.BACKOFFICE_SERVER_TEMPLATE]: this.backOfficeServerTemplate,
			[ConfigVars.DEVICE_SERVER_TEMPLATE]: this.deviceServerTemplate,
			[ConfigVars.CHAT_SERVER_TEMPLATE]: this.chatServerTemplate,
			[ConfigVars.GAME_TITLE]: this.gameTitle,
			[ConfigVars.THEMES]: this.themes,
			[ConfigVars.SPOKE]: this.spoke,
			[ConfigVars.DEPLOYMENT]: this.deployment,
			[ConfigVars.SERVER_DOMAIN]: this.serverDomain,

			// Phenix
			[ConfigVars.ENABLE_PHENIX_TELEMETRY]: this.enablePhenixTelemetry,
			[ConfigVars.PHENIX_AUTH_API_URI]: this.phenixAuthApiURI,
			[ConfigVars.PHENIX_CHANNEL_ID]: this.phenixChannelId,
			[ConfigVars.PHENIX_CHANNEL_ALIAS]: this.phenixChannelAlias,
			[ConfigVars.PHENIX_STREAM_TOKEN]: this.streamToken,
		};

		Object.keys(allProps).forEach((k) => {
			const val = allProps[k] ?? null;
			if (val === '' || val == null) {
				delete allProps[k];
			}
		});

		return allProps;
	}

	protected fromTemplate(template: string, conf: StringDict): string {
		const value = renderTemplate(template, conf);

		return !value.includes('{{') ? value : '';
	}
}

// ---- Export --------------------------------------------------------------------------------------------------------

export { ConfigAdapter as default };
export { ConfigAdapter };
