import { IChoicePeriodDataExt } from '../../../helpers/data';
import { generateObjectListHashId } from '../../../helpers/data/utility';

/**
 * @returns A string 'hash' of the given choice period.
 */
const generateChoicePeriodHashId = (list: IChoicePeriodDataExt[]): string => {
	return generateObjectListHashId<IChoicePeriodDataExt>(list, [
		'id',
		'name',
		'isClosed',
		'totalSeconds',
		'remainingSeconds',
	]);
};

/**
 * @returns TRUE if the existing choice periods do not match the new choice periods.
 */
const compareChoicePeriods = (
	newChoicePeriods: IChoicePeriodDataExt[],
	currentChoicePeriods: IChoicePeriodDataExt[]
): boolean => {
	const ncpl = newChoicePeriods.length;
	const ccpl = currentChoicePeriods.length;

	if (ncpl !== ccpl) {
		return true;
	}

	const newHash = generateChoicePeriodHashId(newChoicePeriods);
	const prevHash = generateChoicePeriodHashId(currentChoicePeriods);

	return newHash !== prevHash;
};

// ---- Export --------------------------------------------------------------------------------------------------------

export { generateChoicePeriodHashId, compareChoicePeriods };
