enum ConnectTransportProtocol {
	GRPC_WEB = 'grpcWeb',
	CONNECT = 'connect',
}

const DEFAULT_CONNECT_TRANSPORT_PROTOCOL = ConnectTransportProtocol.GRPC_WEB;

// ---- Exports -------------------------------------------------------------------------------------------------------

export { ConnectTransportProtocol, DEFAULT_CONNECT_TRANSPORT_PROTOCOL };
