import { IClientRpcSdk } from '../ClientRpcSdk';
import { debug } from './debug';
import { IClientRpcSdkDeviceActions } from './types/device';

/**
 * Issues input to a device.
 */
const deviceInput = async (
	sdk: IClientRpcSdk,
	deviceId: string,
	requestId: string,
	values: string[] = []
): Promise<boolean> => {
	const debugMethod = 'device.deviceInput';

	if (deviceId === '') {
		debug.error('Device ID cannot be empty', debugMethod);
		return false;
	}
	if (requestId === '') {
		debug.error('Device request ID cannot be empty', debugMethod);
		return false;
	}

	const { devService } = sdk.services.getServices();
	await devService.deviceInput(deviceId, requestId, values).promise;

	return true;
};

/**
 * Factory for creating dealer actions.
 */
const newDeviceActions = (sdk: IClientRpcSdk): IClientRpcSdkDeviceActions => {
	return Object.freeze({
		deviceInput: async (deviceId: string, requestId: string, values: string[] = []): Promise<boolean> =>
			deviceInput(sdk, deviceId, requestId, values),
	});
};

// ---- Export --------------------------------------------------------------------------------------------------------

export { newDeviceActions };
