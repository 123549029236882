import { IClientRpcSdk } from '../ClientRpcSdk/types';
import { newAuthSelectors } from './auth';
import { newDeviceSelectors } from './device';
import { newGameSelectors } from './game';
import { IClientRpcSdkSelectors } from './types';

/**
 * Factory for creating SDK selectors.
 */
const newSelectors = (sdk: IClientRpcSdk): IClientRpcSdkSelectors => {
	return Object.freeze({
		auth: newAuthSelectors(sdk),
		device: newDeviceSelectors(sdk),
		game: newGameSelectors(sdk),
	});
};

// ---- Export --------------------------------------------------------------------------------------------------------

export { newSelectors };
