import debounce from 'lodash/debounce';
import isEqual from 'lodash/isEqual';
import omit from 'lodash/omit';
import { DebugBase } from '../../../../common';
import { DEFAULT_PUSH_ENTRIES_DEBOUNCE_WAIT_MS, DEFAULT_STACK_MAX_SIZE } from './constants';
import { bindWagerUndoStackMobX } from './mobx';
import {
	IMethodWagerUndoStackPushOpts,
	IPushDebounceSettings,
	IWagerUndoStackData,
	IWagerUndoStackDataEntry,
	IWagerUndoStackOpts,
	MethodWagerManagerPushEntriesDebouncedFunc,
} from './types';
import { copyData, defaultData } from './utility';

class WagerUndoStack extends DebugBase {
	/* #region ---- Properties --------------------------------------------------------------------------------------- */

	/**
	 * Currently assigned options.
	 */
	protected override _options: IWagerUndoStackOpts = WagerUndoStack.defaultOptions();

	/**
	 * Encapsulated data.
	 */
	protected _data: IWagerUndoStackData;

	/**
	 * Flag indicating if this class instance is currently bound to MobX as an observable.
	 */
	protected _isMobXBound: boolean = false;

	/**
	 * Last debounce key value.
	 */
	protected _lastDebounceKey: string = '';

	/**
	 * ACTION
	 * Debounced version of the `pushEntries` method. This will be (re-)created in the `setOptions` method.
	 */
	protected pushEntriesDebounced!: MethodWagerManagerPushEntriesDebouncedFunc;

	/* #endregion ---- Properties -------------------------------------------------------------------------------------*/

	/* #region ---- CONSTRUCTOR ---------------------------------------------------------------------------------------*/

	constructor(opts?: Maybe<IWagerUndoStackOpts>) {
		super();

		this._data = { ...defaultData(), ...opts?.data };
		opts != null && this.setOptions(opts);

		// Create the debounced version of the `pushEntries` method using the current options.
		this.pushEntriesDebounced = this.newPushEntriesDebouncedMethod();

		// Bind to MobX as an observable.
		if (this._options.useMobX) {
			bindWagerUndoStackMobX(this);
		}
	}

	/* #endregion ---- CONSTRUCTOR ----------------------------------------------------------------------------------- */

	/* #region ---- Public ------------------------------------------------------------------------------------------- */

	/**
	 * Sets/initializes the class options.
	 *
	 * - Overrides the parent class method.
	 */
	public override setOptions(opts: IWagerUndoStackOpts) {
		const { newOpts, origOpts } = this.resolveOptions(opts);
		this._options = newOpts;

		this.onSetOptions(newOpts, origOpts);
	}

	/**
	 * Whether or not the data collection is empty.
	 */
	public get isEmpty(): boolean {
		return this.size === 0;
	}

	/**
	 * Size of the wager stack.
	 */
	public get size(): number {
		return this._data.stack.length;
	}

	/**
	 * Flat array the of the wager stack entries.
	 */
	public get stack(): IWagerUndoStackDataEntry[] {
		return this._data.stack.slice();
	}

	/**
	 * The full data encapsulated by this class instance.
	 */
	public get data(): IWagerUndoStackData {
		return this._data;
	}
	public set data(val: IWagerUndoStackData) {
		this.setData(val);
	}
	// Actionable setter method
	protected setData(val: IWagerUndoStackData) {
		if (val === this._data) {
			return;
		}

		this._data = val;
	}

	/**
	 * The last time (unix timestamp) that the data was updated.
	 */
	public get lastUpdatedTs(): number {
		return this._data.lastUpdatedTs;
	}
	public set lastUpdatedTs(val: number) {
		this.setLastUpdatedTs(val);
	}
	// Actionable setter method
	protected setLastUpdatedTs(val: number) {
		if (val === this._data.lastUpdatedTs) {
			return;
		}
		this._data.lastUpdatedTs = val;
	}

	/**
	 * Gets/sets whether or not this class instance is currently bound to MobX as an observable.
	 */
	public get isMobXBound(): boolean {
		return this._isMobXBound;
	}
	public set isMobXBound(value: boolean) {
		this._isMobXBound = value;
	}

	/**
	 * The maximum size of the stack.
	 */
	public get maxSize(): number {
		return this._options.maxSize ?? DEFAULT_STACK_MAX_SIZE;
	}

	/**
	 * Adds N new entries to the end of the wager undo stack.
	 */
	public push(entries: IWagerUndoStackDataEntry[], opts?: Maybe<IMethodWagerUndoStackPushOpts>): boolean {
		opts = opts ?? {};
		const debounceKey = opts.debounceKey ?? '';
		const noDebounce = opts.noDebounce ?? false;

		this.warn('Info:', 'push', { entries, debounceKey, noDebounce, lastDebounceKey: this._lastDebounceKey });

		// A push with no entries is totally ignored
		if (entries.length === 0) {
			return false;
		}

		// If no debounce is requested, then just push the entries and return.
		if (noDebounce === true) {
			// Flush any pending debounced calls and clear the last debounce key
			this._lastDebounceKey !== '' && this.pushEntriesDebounced.flush();
			this._lastDebounceKey = '';

			// Push the entries
			const pushed = this.pushEntries(entries, opts);

			return pushed.success;
		}

		// If the debounce key has changed, then flush the debounced method so the final invocation executes
		if (this._lastDebounceKey !== '' && debounceKey !== this._lastDebounceKey) {
			this.pushEntriesDebounced.flush();
		}

		// Debounced functions return undefined when the call is debounced, return false in that case
		const pushed = this.pushEntriesDebounced(entries, opts) ?? { success: false };
		this._lastDebounceKey = debounceKey;

		return pushed.success;
	}

	/**
	 * Removes N entries from the end of the wager undo stack and returns them in an array.
	 */
	public pop(count?: Maybe<number>, opts?: Maybe<{ updatedTs?: Maybe<number> }>): IWagerUndoStackDataEntry[] {
		count = count ?? 1;
		if (this.size === 0 || count <= 0) {
			return [];
		}

		const data = copyData(this._data, { updatedTs: opts?.updatedTs ?? Date.now() });
		const rc = Math.min(count, data.stack.length);
		const popped = data.stack.splice(data.stack.length - rc, rc);

		this.setData(data);

		return popped;
	}

	/**
	 * Resets the class data back to the initial/clear values.
	 */
	public clear(opts?: Maybe<{ updatedTs?: Maybe<number> }>) {
		const data = defaultData({ updatedTs: opts?.updatedTs });
		this.setData(data);
	}

	/**
	 * @returns A JSON export of the pertinent data.
	 */
	public override toJson(extended?: Maybe<boolean>): PlainObject {
		extended = extended ?? false;

		const data = copyData(this._data);

		const toJs = (val: unknown) => DebugBase.toJs(val, { extended, useMobXToJs: this.isMobXBound });

		const result: PlainObject = {
			lastUpdatedTs: this.lastUpdatedTs,
			size: this.size,
			isEmpty: this.size === 0,
			stack: toJs(this.stack),
		};

		if (extended) {
			result.extended = {
				options: toJs({ ...this._options }),
				rawData: toJs(data),
				lastDebounceKey: this._lastDebounceKey,

				// Debug info from base class
				_Debug: { ...super.toJson(extended) },
			};
		}

		return result;
	}

	/* #endregion ---- Public ---------------------------------------------------------------------------------------- */

	/* #region ---- Protected ---------------------------------------------------------------------------------------- */

	/**
	 * Resolves the options being passed in and returns the original and new options.
	 *
	 * - Overrides the parent class method
	 */
	protected override resolveOptions(opts?: Maybe<IWagerUndoStackOpts>) {
		const origOpts: IWagerUndoStackOpts = {
			...WagerUndoStack.defaultOptions(),
			...this._options,
		};

		const newOpts: IWagerUndoStackOpts = {
			...origOpts,
			...(opts ?? {}),
		};

		return { origOpts, newOpts };
	}

	/**
	 * Called after new options are set.
	 *
	 * - Extends the parent class method
	 */
	protected override onSetOptions(newOpts: IWagerUndoStackOpts, origOpts: IWagerUndoStackOpts) {
		super.onSetOptions(newOpts, origOpts);

		// If debounce settings have changed, then re-create the `pushEntriesDebounced` method.
		if (
			this.pushEntriesDebounced != null &&
			newOpts.pushDebounceSettings != null &&
			!isEqual(newOpts.pushDebounceSettings, origOpts.pushDebounceSettings)
		) {
			this.pushEntriesDebounced = this.newPushEntriesDebouncedMethod(newOpts.pushDebounceSettings);
		}

		if (newOpts.data != null && (!this._data || newOpts.data.uniqId !== this._data.uniqId)) {
			this.setData(newOpts.data);
		}

		if (!this._data) {
			return;
		}
		if (newOpts.updatedTs != null && newOpts.updatedTs !== this._data.lastUpdatedTs) {
			this.setLastUpdatedTs(newOpts.updatedTs);
		}
	}

	/**
	 * ACTION
	 * Adds N new entries to the end of the wager undo stack.
	 */
	protected pushEntries = (
		entries: IWagerUndoStackDataEntry[],
		opts?: Maybe<{ updatedTs?: Maybe<number> }>
	): { success: boolean; numEntries: number; stackSize: number } => {
		if (entries.length === 0) {
			return { success: false, numEntries: 0, stackSize: this.size };
		}

		const data = copyData(this._data, { updatedTs: opts?.updatedTs ?? Date.now() });

		data.stack = data.stack.concat(entries);

		if (data.stack.length > this.maxSize) {
			data.stack.splice(0, data.stack.length - this.maxSize);
		}

		this.setData(data);

		return { success: true, numEntries: entries.length, stackSize: data.stack.length };
	};

	/**
	 * @returns Debounced version of the `pushEntries` method with the specified settings.
	 */
	protected newPushEntriesDebouncedMethod(settings?: Maybe<IPushDebounceSettings>) {
		settings = settings ?? this._options.pushDebounceSettings;
		const waitMs = settings?.waitMs || DEFAULT_PUSH_ENTRIES_DEBOUNCE_WAIT_MS;
		const options = omit(settings, ['waitMs']);

		return debounce(this.pushEntries, waitMs, options);
	}

	/* #endregion ---- Protected ------------------------------------------------------------------------------------- */

	/* #region ---- Static ------------------------------------------------------------------------------------------- */

	/**
	 * STATIC
	 * @returns The default options data used by this class.
	 */
	public static defaultOptions(): IWagerUndoStackOpts {
		return {
			...DebugBase.defaultOptions(),
			data: null,
			updatedTs: null,
			useMobX: true,
			maxSize: DEFAULT_STACK_MAX_SIZE,
			pushDebounceSettings: {
				waitMs: 500,
				maxWait: undefined,
				leading: true,
				trailing: false,
			},
		};
	}

	/* #endregion ---- Static ---------------------------------------------------------------------------------------- */

	/* #region ---- Debug -------------------------------------------------------------------------------------------- */

	/**
	 * Overrides the parent class property.
	 *
	 * @returns The label to use when debugging.
	 */
	protected override get debugClassLabel(): string {
		return WagerUndoStack.debugClassLabel();
	}

	/**
	 * STATIC
	 * @returns Label assigned to this class namespace.
	 */
	protected static debugClassLabel(): string {
		return `RpcLib.DataObject.WagerUndoStack`;
	}

	/* #endregion ---- Debug ----------------------------------------------------------------------------------------- */
}

// ---- Exports -------------------------------------------------------------------------------------------------------

export { WagerUndoStack as default };
export { WagerUndoStack };
