import { Logger as DebugLogger, ILogger as IDebugLogger, newNoOpLogger } from '../logging';
import { generateRandomString } from '../string';
import { IToJsOpts, toJs } from '../toJs';
import { IDebugBase, IDebugBaseOpts, IDebugError } from './types';

/**
 * Base class for debugging. Contains the common logic.
 */
class DebugBase implements IDebugBase {
	/* #region ---- Properties --------------------------------------------------------------------------------------- */

	/**
	 * Currently assigned options.
	 */
	protected _options: IDebugBaseOpts = DebugBase.defaultOptions();

	/**
	 * Debugger instance used for debug output.
	 */
	protected _debug: IDebugLogger;

	/**
	 * Instance ID used to identify this specific instance.
	 */
	protected _instanceId: string;

	/**
	 * TRUE if debugging is enabled.
	 */
	protected _isDebugEnabled: boolean = false;

	/**
	 * Additional label used as a suffix when debugging.
	 */
	protected _debugLabel: string = '';

	/* #endregion ---- Properties -------------------------------------------------------------------------------------*/

	/* #region ---- CONSTRUCTOR ---------------------------------------------------------------------------------------*/

	constructor(opts?: Maybe<IDebugBaseOpts>) {
		this._instanceId = generateRandomString();
		this._debug = newNoOpLogger();

		opts != null && this.setOptions(opts);
	}

	/* #endregion ---- CONSTRUCTOR ----------------------------------------------------------------------------------- */

	/* #region ---- Public ------------------------------------------------------------------------------------------- */

	/**
	 * Sets/initializes the class options.
	 */
	public setOptions(opts: IDebugBaseOpts) {
		const { newOpts, origOpts } = this.resolveOptions(opts);
		this._options = newOpts;
		this.onSetOptions(newOpts, origOpts);
	}

	/**
	 * Instance ID used to identify this specific manager instance. Used for debugging.
	 */
	public get instanceId(): string {
		return this._instanceId;
	}

	/**
	 * Gets/sets whether debugging is enabled.
	 */
	public get isDebugEnabled(): boolean {
		return this._isDebugEnabled;
	}
	public set isDebugEnabled(val: boolean) {
		this.setIsDebugEnabled(val);
	}
	// Actionable setter method for MobX
	protected setIsDebugEnabled(val: boolean) {
		if (val === this._isDebugEnabled) {
			return;
		}

		this._isDebugEnabled = val;
		this.onSetDebugEnabled(this._isDebugEnabled);
	}

	/**
	 * @returns A JSON export of the current pertinent data.
	 */
	public toJson(extended?: Maybe<boolean>): PlainObject {
		extended = extended ?? false;

		const toJs = (val: unknown, opts?: Maybe<IToJsOpts>) => DebugBase.toJs(val, { ...opts, extended });

		const result: PlainObject = {
			instanceId: this._instanceId,
			isDebugEnabled: this._isDebugEnabled,
			debugLogPrefix: this.getDebugLogPrefix(),
		};

		if (extended) {
			result.options = toJs(this._options);
			result.debugLabel = this._debugLabel;
			result.debugClassLabel = this.debugClassLabel;
			result.debugLogger = toJs(this.debug);
		}

		return result;
	}

	/* #endregion ---- Public ---------------------------------------------------------------------------------------- */

	/* #region ---- Protected ---------------------------------------------------------------------------------------- */

	/**
	 * Gets the debugger instance used for debug output.
	 */
	protected get debug(): IDebugLogger {
		return this._debug;
	}

	/**
	 * Gets/sets the additional label used as a suffix when debugging.
	 */
	protected get debugLabel(): string {
		return this._debugLabel;
	}
	protected set debugLabel(val: Maybe<string>) {
		const origVal = this._debugLabel;

		if (val == null || val === origVal) {
			return;
		}

		this._debugLabel = val;
		this.onSetDebugLabel(this._debugLabel);
	}

	/**
	 * Logs an info debug message.
	 */
	protected info(msg: string, method?: Maybe<string>, ...args: unknown[]) {
		this.debug.info(msg, method, ...args);
	}

	/**
	 * Logs an warning debug message.
	 */
	protected warn(msg: string, method?: Maybe<string>, ...args: unknown[]) {
		this.debug.warn(msg, method, ...args);
	}

	/**
	 * Logs an error debug message.
	 */
	protected error(msg: string, method?: Maybe<string>, ...args: unknown[]) {
		this.debug.error(msg, method, ...args);
	}

	/**
	 * @returns A debug messahge with the given message for the specified method.
	 */
	protected makeMessage(msg: string, method?: Maybe<string>): string {
		return this.debug.makeMessage(msg, method);
	}

	/**
	 * @returns An error with the given message, method, and args.
	 */
	protected makeError(message: string, method?: Maybe<string>, ...args: unknown[]): Error {
		const errMsg = this.debug.makeMessage(message, method);
		const err: IDebugError = new Error(errMsg);
		if (args.length > 0) {
			err.extra = toJs(args, { forceObject: true }) as PlainObject;
		}

		return err;
	}

	/**
	 * @throws {Error} An error with the given message, prefix, and args.
	 */
	protected throwError(message: string, method?: Maybe<string>, ...args: unknown[]) {
		this.error(message, method, ...args);
		throw this.makeError(message, method, ...args);
	}

	/**
	 * @returns A new instance of a debug logger with the correct prefix.
	 */
	protected newDebugLogger(): IDebugLogger {
		return new DebugLogger(this.getDebugLogPrefix());
	}

	/**
	 * @returns The prefix to use for debug output.
	 */
	protected getDebugLogPrefix(debugLabel?: Maybe<string>): string {
		debugLabel = debugLabel ?? this._debugLabel;
		const instanceId = this._instanceId;

		const add: string = [debugLabel.length > 0 ? `.${debugLabel}` : '', `.${instanceId}`].join('');
		return `${this.debugClassLabel}${add}`;
	}

	/**
	 * @returns The label to use when debugging.
	 */
	protected get debugClassLabel(): string {
		return DebugBase.debugClassLabel();
	}

	/**
	 * Resolves the options being passed in and returns the original and new options.
	 */
	protected resolveOptions(opts?: Maybe<IDebugBaseOpts>) {
		const origOpts: IDebugBaseOpts = {
			...DebugBase.defaultOptions(),
			...this._options,
		};

		const newOpts: IDebugBaseOpts = {
			...origOpts,
			...(opts ?? {}),
		};

		return { origOpts, newOpts };
	}

	/**
	 * Called after new options are set.
	 */
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	protected onSetOptions(newOpts: IDebugBaseOpts, origOpts?: Maybe<IDebugBaseOpts>) {
		if (newOpts.isDebugEnabled != null && newOpts.isDebugEnabled !== this._isDebugEnabled) {
			this._isDebugEnabled = newOpts.isDebugEnabled;
			this.onSetDebugEnabled(this._isDebugEnabled);
		}

		if (newOpts.debugLabel != null && newOpts.debugLabel !== this._debugLabel) {
			this._debugLabel = newOpts.debugLabel;
			this.onSetDebugLabel(this._debugLabel);
		}
	}

	/**
	 * Called when the debug enabled state is changed.
	 */
	protected onSetDebugEnabled(isEnabled: boolean) {
		this._debug = isEnabled ? this.newDebugLogger() : newNoOpLogger();
	}

	/**
	 * Called when the debug label is changed.
	 */
	protected onSetDebugLabel(debugLabel: string) {
		this._debug.prefix = this.getDebugLogPrefix(debugLabel);
	}

	/**
	 * @returns The specified value as a JSON object.
	 */
	public static toJs(val: unknown, opts?: Maybe<IToJsOpts>): unknown {
		return toJs(val, opts);
	}

	/* #endregion ---- Protected ------------------------------------------------------------------------------------- */

	/* #region ---- Static ------------------------------------------------------------------------------------------- */

	/**
	 * STATIC
	 * @returns The default options data used by this class.
	 */
	public static defaultOptions(): IDebugBaseOpts {
		return {
			isDebugEnabled: false,
			debugLabel: '',
		};
	}

	/**
	 * STATIC
	 * @returns Label assigned to this class namespace.
	 */
	protected static debugClassLabel(): string {
		return `SharedLib.DebugBase`;
	}

	/* #endregion ---- Static ---------------------------------------------------------------------------------------- */
}

// ---- Exports -------------------------------------------------------------------------------------------------------

export { DebugBase as default };
export { DebugBase };
