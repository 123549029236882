// @generated by protoc-gen-es v1.3.0 with parameter "target=js+dts"
// @generated from file clientserver.proto (package com.sands.ldx.clientserver, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { Any, proto3 } from "@bufbuild/protobuf";

/**
 * @generated from enum com.sands.ldx.clientserver.TableState
 */
export const TableState = proto3.makeEnum(
  "com.sands.ldx.clientserver.TableState",
  [
    {no: 0, name: "TABLE_STATE_UNKNOWN", localName: "UNKNOWN"},
    {no: 1, name: "TABLE_STATE_OPEN", localName: "OPEN"},
    {no: 2, name: "TABLE_STATE_IN_PLAY", localName: "IN_PLAY"},
    {no: 3, name: "TABLE_STATE_CLOSED", localName: "CLOSED"},
  ],
);

/**
 * @generated from enum com.sands.ldx.clientserver.WageringMode
 */
export const WageringMode = proto3.makeEnum(
  "com.sands.ldx.clientserver.WageringMode",
  [
    {no: 0, name: "WAGER_MODE_REQUIRED_ALL"},
    {no: 1, name: "WAGER_MODE_REQUIRED_ANY"},
    {no: 2, name: "WAGER_MODE_ANY"},
  ],
);

/**
 * Player 
 *
 * @generated from message com.sands.ldx.clientserver.GetSelfRequest
 */
export const GetSelfRequest = proto3.makeMessageType(
  "com.sands.ldx.clientserver.GetSelfRequest",
  () => [
    { no: 1, name: "include_artifacts", kind: "scalar", T: 8 /* ScalarType.BOOL */, opt: true },
    { no: 2, name: "include_balances", kind: "scalar", T: 8 /* ScalarType.BOOL */, opt: true },
    { no: 3, name: "include_rewards", kind: "scalar", T: 8 /* ScalarType.BOOL */, opt: true },
    { no: 4, name: "include_recent_plays", kind: "scalar", T: 8 /* ScalarType.BOOL */, opt: true },
  ],
);

/**
 * @generated from message com.sands.ldx.clientserver.GameHistoryRequest
 */
export const GameHistoryRequest = proto3.makeMessageType(
  "com.sands.ldx.clientserver.GameHistoryRequest",
  () => [
    { no: 1, name: "game_type", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "game_variant", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "table_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "page_num", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 5, name: "page_size", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
  ],
);

/**
 * @generated from message com.sands.ldx.clientserver.GameHistoryReply
 */
export const GameHistoryReply = proto3.makeMessageType(
  "com.sands.ldx.clientserver.GameHistoryReply",
  () => [
    { no: 1, name: "play_count", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 2, name: "page_count", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 3, name: "plays", kind: "message", T: Play, repeated: true },
  ],
);

/**
 * @generated from message com.sands.ldx.clientserver.GetSelfReply
 */
export const GetSelfReply = proto3.makeMessageType(
  "com.sands.ldx.clientserver.GetSelfReply",
  () => [
    { no: 1, name: "player_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "balances", kind: "message", T: Balance, repeated: true },
    { no: 3, name: "profile", kind: "message", T: Profile },
    { no: 4, name: "artifacts", kind: "message", T: PlayerArtifact, repeated: true },
    { no: 5, name: "rewards", kind: "message", T: PlayerReward, repeated: true },
    { no: 6, name: "recent_play_ids", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 7, name: "OperatorUserId", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message com.sands.ldx.clientserver.PlayerArtifact
 */
export const PlayerArtifact = proto3.makeMessageType(
  "com.sands.ldx.clientserver.PlayerArtifact",
  () => [
    { no: 1, name: "artifact_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "class", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "type", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "data", kind: "scalar", T: 12 /* ScalarType.BYTES */ },
    { no: 5, name: "created_at", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
  ],
);

/**
 * @generated from message com.sands.ldx.clientserver.PlayerReward
 */
export const PlayerReward = proto3.makeMessageType(
  "com.sands.ldx.clientserver.PlayerReward",
  () => [
    { no: 1, name: "reward_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "type", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "description", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "currency", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "games", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 7, name: "amount", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 8, name: "created_at", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
  ],
);

/**
 * @generated from message com.sands.ldx.clientserver.Balance
 */
export const Balance = proto3.makeMessageType(
  "com.sands.ldx.clientserver.Balance",
  () => [
    { no: 1, name: "player_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "account_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "currency", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "currency_exponent", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 5, name: "amount", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 6, name: "updated_at", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
  ],
);

/**
 * @generated from message com.sands.ldx.clientserver.Profile
 */
export const Profile = proto3.makeMessageType(
  "com.sands.ldx.clientserver.Profile",
  () => [
    { no: 1, name: "display_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "image_url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "preferences", kind: "map", K: 9 /* ScalarType.STRING */, V: {kind: "scalar", T: 9 /* ScalarType.STRING */} },
  ],
);

/**
 * The player making the request should be identified by the web token.
 *
 * @generated from message com.sands.ldx.clientserver.LeaderboardRequest
 */
export const LeaderboardRequest = proto3.makeMessageType(
  "com.sands.ldx.clientserver.LeaderboardRequest",
  () => [
    { no: 1, name: "play_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "page_num", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 3, name: "page_size", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
  ],
);

/**
 * @generated from message com.sands.ldx.clientserver.LeaderboardReply
 */
export const LeaderboardReply = proto3.makeMessageType(
  "com.sands.ldx.clientserver.LeaderboardReply",
  () => [
    { no: 1, name: "player_count", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 2, name: "player_net_profit", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 3, name: "page_count", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 4, name: "self", kind: "message", T: LeaderboardScore, opt: true },
    { no: 5, name: "scores", kind: "message", T: LeaderboardScore, repeated: true },
  ],
);

/**
 * @generated from message com.sands.ldx.clientserver.LeaderboardScore
 */
export const LeaderboardScore = proto3.makeMessageType(
  "com.sands.ldx.clientserver.LeaderboardScore",
  () => [
    { no: 1, name: "player_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "display_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "net_payout", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
  ],
);

/**
 * @generated from message com.sands.ldx.clientserver.GetTablesRequest
 */
export const GetTablesRequest = proto3.makeMessageType(
  "com.sands.ldx.clientserver.GetTablesRequest",
  [],
);

/**
 * @generated from message com.sands.ldx.clientserver.GetTablesReply
 */
export const GetTablesReply = proto3.makeMessageType(
  "com.sands.ldx.clientserver.GetTablesReply",
  () => [
    { no: 1, name: "tables", kind: "message", T: SimpleTable, repeated: true },
  ],
);

/**
 * @generated from message com.sands.ldx.clientserver.SimpleTable
 */
export const SimpleTable = proto3.makeMessageType(
  "com.sands.ldx.clientserver.SimpleTable",
  () => [
    { no: 1, name: "table_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "display_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "game_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "game_variant", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "seat_count", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 6, name: "open_seats", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 7, name: "tags", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ],
);

/**
 * @generated from message com.sands.ldx.clientserver.GetTableRequest
 */
export const GetTableRequest = proto3.makeMessageType(
  "com.sands.ldx.clientserver.GetTableRequest",
  () => [
    { no: 1, name: "table_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "include_play", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 3, name: "include_play_config", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 4, name: "compact_playstate_wager_definitions", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ],
);

/**
 * @generated from message com.sands.ldx.clientserver.GetTableReply
 */
export const GetTableReply = proto3.makeMessageType(
  "com.sands.ldx.clientserver.GetTableReply",
  () => [
    { no: 1, name: "table", kind: "message", T: Table },
    { no: 2, name: "play", kind: "message", T: Play, opt: true },
  ],
);

/**
 * @generated from message com.sands.ldx.clientserver.Table
 */
export const Table = proto3.makeMessageType(
  "com.sands.ldx.clientserver.Table",
  () => [
    { no: 1, name: "table_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "play_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "previous_play_id", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 4, name: "display_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "game_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "game_variant", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "seat_count", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 8, name: "open_seats", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 9, name: "state", kind: "enum", T: proto3.getEnumType(TableState) },
    { no: 10, name: "seats", kind: "message", T: Seat, repeated: true },
    { no: 11, name: "tags", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 12, name: "play_config", kind: "message", T: TablePlayConfig, opt: true },
  ],
);

/**
 * @generated from message com.sands.ldx.clientserver.TablePlayConfig
 */
export const TablePlayConfig = proto3.makeMessageType(
  "com.sands.ldx.clientserver.TablePlayConfig",
  () => [
    { no: 1, name: "currency", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "table_min", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 3, name: "table_max", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 4, name: "wager_definitions", kind: "message", T: WagerDefinition, repeated: true },
    { no: 5, name: "skins", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 6, name: "wager_layout", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "chip_sizes", kind: "scalar", T: 4 /* ScalarType.UINT64 */, repeated: true },
    { no: 8, name: "min_chip_size", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 9, name: "max_chip_size", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 10, name: "game_config", kind: "message", T: Any, repeated: true },
    { no: 11, name: "version", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 12, name: "felt_layout", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message com.sands.ldx.clientserver.Seat
 */
export const Seat = proto3.makeMessageType(
  "com.sands.ldx.clientserver.Seat",
  () => [
    { no: 1, name: "seat_num", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 2, name: "open", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 3, name: "player_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "display_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "image_url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message com.sands.ldx.clientserver.GetPlayRequest
 */
export const GetPlayRequest = proto3.makeMessageType(
  "com.sands.ldx.clientserver.GetPlayRequest",
  () => [
    { no: 1, name: "table_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "play_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message com.sands.ldx.clientserver.GetPlayReply
 */
export const GetPlayReply = proto3.makeMessageType(
  "com.sands.ldx.clientserver.GetPlayReply",
  () => [
    { no: 1, name: "play", kind: "message", T: Play },
  ],
);

/**
 * @generated from message com.sands.ldx.clientserver.Play
 */
export const Play = proto3.makeMessageType(
  "com.sands.ldx.clientserver.Play",
  () => [
    { no: 1, name: "play_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "table_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "play_state", kind: "message", T: PlayState },
    { no: 4, name: "player_state", kind: "message", T: PlayerState },
    { no: 5, name: "player_decision_state", kind: "message", T: PlayerDecisionState },
    { no: 6, name: "game_state", kind: "message", T: Any },
    { no: 7, name: "player_game_state", kind: "message", T: Any, repeated: true },
    { no: 8, name: "seats", kind: "message", T: PlaySeat, repeated: true },
    { no: 9, name: "seat_settings", kind: "map", K: 9 /* ScalarType.STRING */, V: {kind: "scalar", T: 9 /* ScalarType.STRING */} },
    { no: 10, name: "current_time", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 11, name: "created_at", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 12, name: "completed_at", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 13, name: "edge_table_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 14, name: "studio_table_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 15, name: "start_balance", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
  ],
);

/**
 * @generated from message com.sands.ldx.clientserver.PlayState
 */
export const PlayState = proto3.makeMessageType(
  "com.sands.ldx.clientserver.PlayState",
  () => [
    { no: 1, name: "wager_periods", kind: "message", T: WagerPeriod, repeated: true },
    { no: 2, name: "choice_periods", kind: "message", T: ChoicePeriod, repeated: true },
    { no: 3, name: "wager_definitions", kind: "message", T: PossibleWager, repeated: true },
    { no: 4, name: "choice_definitions", kind: "message", T: PossibleChoice, repeated: true },
  ],
);

/**
 * @generated from message com.sands.ldx.clientserver.PlayerState
 */
export const PlayerState = proto3.makeMessageType(
  "com.sands.ldx.clientserver.PlayerState",
  () => [
    { no: 1, name: "state", kind: "enum", T: proto3.getEnumType(PlayerState_PlayerPlayState) },
    { no: 2, name: "available_wagers", kind: "message", T: AvailableWagers, repeated: true },
    { no: 3, name: "available_choices", kind: "message", T: AvailableChoices, repeated: true },
  ],
);

/**
 * @generated from enum com.sands.ldx.clientserver.PlayerState.PlayerPlayState
 */
export const PlayerState_PlayerPlayState = proto3.makeEnum(
  "com.sands.ldx.clientserver.PlayerState.PlayerPlayState",
  [
    {no: 0, name: "STATE_UNKNOWN"},
    {no: 1, name: "STATE_OBSERVER"},
    {no: 2, name: "STATE_IN_PLAY"},
    {no: 3, name: "STATE_CANCELLED"},
    {no: 4, name: "STATE_COMPLETE"},
    {no: 5, name: "STATE_CANCELLED_RESOLVED"},
    {no: 6, name: "STATE_COMPLETE_RESOLVED"},
  ],
);

/**
 * @generated from message com.sands.ldx.clientserver.PlaySeat
 */
export const PlaySeat = proto3.makeMessageType(
  "com.sands.ldx.clientserver.PlaySeat",
  () => [
    { no: 1, name: "seat_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "spectator_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "seat_num", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 4, name: "player_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "display_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "image_url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "player_game_state", kind: "message", T: Any, repeated: true },
    { no: 8, name: "decision_state", kind: "message", T: SeatDecisionState },
    { no: 9, name: "seat_settings", kind: "map", K: 9 /* ScalarType.STRING */, V: {kind: "scalar", T: 9 /* ScalarType.STRING */} },
    { no: 10, name: "focused", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ],
);

/**
 * @generated from message com.sands.ldx.clientserver.SeatDecisionState
 */
export const SeatDecisionState = proto3.makeMessageType(
  "com.sands.ldx.clientserver.SeatDecisionState",
  () => [
    { no: 1, name: "active_wagers", kind: "message", T: ActiveWager, repeated: true },
    { no: 2, name: "resolved_wagers", kind: "message", T: ResolvedWager, repeated: true },
    { no: 3, name: "choices", kind: "message", T: PlayerChoice, repeated: true },
  ],
);

/**
 * @generated from message com.sands.ldx.clientserver.AvailableWagers
 */
export const AvailableWagers = proto3.makeMessageType(
  "com.sands.ldx.clientserver.AvailableWagers",
  () => [
    { no: 1, name: "context_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "wager_type_ids", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ],
);

/**
 * @generated from message com.sands.ldx.clientserver.AvailableChoices
 */
export const AvailableChoices = proto3.makeMessageType(
  "com.sands.ldx.clientserver.AvailableChoices",
  () => [
    { no: 1, name: "context_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "choice_type_ids", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ],
);

/**
 * @generated from message com.sands.ldx.clientserver.PlayerDecisionState
 */
export const PlayerDecisionState = proto3.makeMessageType(
  "com.sands.ldx.clientserver.PlayerDecisionState",
  () => [
    { no: 1, name: "pending_wagers", kind: "message", T: ActiveWager, repeated: true },
    { no: 2, name: "active_wagers", kind: "message", T: ActiveWager, repeated: true },
    { no: 3, name: "resolved_wagers", kind: "message", T: ResolvedWager, repeated: true },
    { no: 4, name: "rejected_wagers", kind: "message", T: ActiveWager, repeated: true },
    { no: 5, name: "choices", kind: "message", T: PlayerChoice, repeated: true },
  ],
);

/**
 * @generated from message com.sands.ldx.clientserver.PossibleWager
 */
export const PossibleWager = proto3.makeMessageType(
  "com.sands.ldx.clientserver.PossibleWager",
  () => [
    { no: 1, name: "wager_type_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "wager_period_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "required", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 5, name: "description", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 6, name: "is_category", kind: "scalar", T: 8 /* ScalarType.BOOL */, opt: true },
    { no: 7, name: "available_keys", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 8, name: "min_amount", kind: "scalar", T: 4 /* ScalarType.UINT64 */, opt: true },
    { no: 9, name: "max_amount", kind: "scalar", T: 4 /* ScalarType.UINT64 */, opt: true },
    { no: 10, name: "currency", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 11, name: "pay_table", kind: "message", T: PayTable, opt: true },
    { no: 12, name: "requires_seat", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 13, name: "requires_spectator", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ],
);

/**
 * @generated from message com.sands.ldx.clientserver.WagerDefinition
 */
export const WagerDefinition = proto3.makeMessageType(
  "com.sands.ldx.clientserver.WagerDefinition",
  () => [
    { no: 1, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "description", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "is_category", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 4, name: "available_keys", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 5, name: "min_amount", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 6, name: "max_amount", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 7, name: "currency", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 8, name: "pay_table", kind: "message", T: PayTable },
    { no: 9, name: "requires_seat", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 10, name: "requires_spectator", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ],
);

/**
 * @generated from message com.sands.ldx.clientserver.PayTable
 */
export const PayTable = proto3.makeMessageType(
  "com.sands.ldx.clientserver.PayTable",
  () => [
    { no: 1, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "rtp", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "paylines", kind: "message", T: Payline, repeated: true },
  ],
);

/**
 * @generated from message com.sands.ldx.clientserver.Payline
 */
export const Payline = proto3.makeMessageType(
  "com.sands.ldx.clientserver.Payline",
  () => [
    { no: 1, name: "outcome", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "payout", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message com.sands.ldx.clientserver.PossibleChoice
 */
export const PossibleChoice = proto3.makeMessageType(
  "com.sands.ldx.clientserver.PossibleChoice",
  () => [
    { no: 1, name: "choice_type_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "description", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "all_wagers_required", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 5, name: "available_wagers", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 6, name: "context_required", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 7, name: "choice_period_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message com.sands.ldx.clientserver.MakeWagersRequest
 */
export const MakeWagersRequest = proto3.makeMessageType(
  "com.sands.ldx.clientserver.MakeWagersRequest",
  () => [
    { no: 1, name: "table_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "play_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "wagers", kind: "message", T: WagerRequest, repeated: true },
  ],
);

/**
 * @generated from message com.sands.ldx.clientserver.WagerRequest
 */
export const WagerRequest = proto3.makeMessageType(
  "com.sands.ldx.clientserver.WagerRequest",
  () => [
    { no: 1, name: "wager_type_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "amount", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 3, name: "currency", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "context_id", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 5, name: "key", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
  ],
);

/**
 * @generated from message com.sands.ldx.clientserver.MakeWagersReply
 */
export const MakeWagersReply = proto3.makeMessageType(
  "com.sands.ldx.clientserver.MakeWagersReply",
  () => [
    { no: 1, name: "table_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "play_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "active_wagers", kind: "message", T: ActiveWager, repeated: true },
    { no: 4, name: "pending_wagers", kind: "message", T: ActiveWager, repeated: true },
    { no: 5, name: "new_wagers", kind: "message", T: ActiveWager, repeated: true },
  ],
);

/**
 * @generated from message com.sands.ldx.clientserver.ActiveWager
 */
export const ActiveWager = proto3.makeMessageType(
  "com.sands.ldx.clientserver.ActiveWager",
  () => [
    { no: 1, name: "wager_key", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "key", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 3, name: "wager_type_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "amount", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 5, name: "currency", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "context_id", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 7, name: "sequence", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 8, name: "created_at", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 9, name: "result", kind: "enum", T: proto3.getEnumType(ActiveWager_Result) },
    { no: 10, name: "processed_at", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
  ],
);

/**
 * @generated from enum com.sands.ldx.clientserver.ActiveWager.Result
 */
export const ActiveWager_Result = proto3.makeEnum(
  "com.sands.ldx.clientserver.ActiveWager.Result",
  [
    {no: 0, name: "Pending"},
    {no: 1, name: "OK"},
    {no: 2, name: "INSUFFICIENT_FUNDS"},
    {no: 3, name: "CANCELLED"},
    {no: 4, name: "REMOTE_TIMEOUT"},
    {no: 5, name: "REMOTE_ERROR"},
  ],
);

/**
 * @generated from message com.sands.ldx.clientserver.ResolvedWager
 */
export const ResolvedWager = proto3.makeMessageType(
  "com.sands.ldx.clientserver.ResolvedWager",
  () => [
    { no: 1, name: "wager_key", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "payout_amount", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 3, name: "outcome", kind: "enum", T: proto3.getEnumType(ResolvedWager_Outcome) },
    { no: 4, name: "reason", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "paytable_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "resolution_amount", kind: "message", T: WagerResolutionAmount, opt: true },
    { no: 7, name: "created_at", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 8, name: "processed_at", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
  ],
);

/**
 * @generated from enum com.sands.ldx.clientserver.ResolvedWager.Outcome
 */
export const ResolvedWager_Outcome = proto3.makeEnum(
  "com.sands.ldx.clientserver.ResolvedWager.Outcome",
  [
    {no: 0, name: "OUTCOME_LOSS", localName: "LOSS"},
    {no: 1, name: "OUTCOME_PAYOUT", localName: "PAYOUT"},
    {no: 2, name: "OUTCOME_PUSH", localName: "PUSH"},
    {no: 3, name: "OUTCOME_FEE", localName: "FEE"},
    {no: 4, name: "OUTCOME_HALF_PUSH", localName: "HALF_PUSH"},
  ],
);

/**
 * The details of a non-loss that are all defined or not defined together.
 *
 * @generated from message com.sands.ldx.clientserver.WagerResolutionAmount
 */
export const WagerResolutionAmount = proto3.makeMessageType(
  "com.sands.ldx.clientserver.WagerResolutionAmount",
  () => [
    { no: 1, name: "currency", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "wager_amount", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 3, name: "payout_amount", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 4, name: "paytable_outcome", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message com.sands.ldx.clientserver.MakeChoiceRequest
 */
export const MakeChoiceRequest = proto3.makeMessageType(
  "com.sands.ldx.clientserver.MakeChoiceRequest",
  () => [
    { no: 1, name: "table_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "play_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "choices", kind: "message", T: ChoiceRequest, repeated: true },
  ],
);

/**
 * @generated from message com.sands.ldx.clientserver.MakeChoiceReply
 */
export const MakeChoiceReply = proto3.makeMessageType(
  "com.sands.ldx.clientserver.MakeChoiceReply",
  () => [
    { no: 1, name: "table_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "play_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "choices", kind: "message", T: PlayerChoice, repeated: true },
  ],
);

/**
 * @generated from message com.sands.ldx.clientserver.ChoiceRequest
 */
export const ChoiceRequest = proto3.makeMessageType(
  "com.sands.ldx.clientserver.ChoiceRequest",
  () => [
    { no: 1, name: "choice_type_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "context_id", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 3, name: "values", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 4, name: "wagers", kind: "message", T: WagerRequest, repeated: true },
  ],
);

/**
 * @generated from message com.sands.ldx.clientserver.PlayerChoice
 */
export const PlayerChoice = proto3.makeMessageType(
  "com.sands.ldx.clientserver.PlayerChoice",
  () => [
    { no: 1, name: "choice_key", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "choice_type_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "context_id", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 4, name: "values", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 5, name: "active_wager_ids", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ],
);

/**
 * @generated from message com.sands.ldx.clientserver.WagerPeriod
 */
export const WagerPeriod = proto3.makeMessageType(
  "com.sands.ldx.clientserver.WagerPeriod",
  () => [
    { no: 1, name: "wager_period_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "closing", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 4, name: "closed", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 5, name: "availability_window", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 6, name: "remaining_time", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 7, name: "wagering_mode", kind: "enum", T: proto3.getEnumType(WageringMode) },
    { no: 8, name: "meta", kind: "map", K: 9 /* ScalarType.STRING */, V: {kind: "scalar", T: 9 /* ScalarType.STRING */} },
  ],
);

/**
 * @generated from message com.sands.ldx.clientserver.ChoicePeriod
 */
export const ChoicePeriod = proto3.makeMessageType(
  "com.sands.ldx.clientserver.ChoicePeriod",
  () => [
    { no: 1, name: "choice_period_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "closed", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 4, name: "availability_window", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 5, name: "remaining_time", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 6, name: "meta", kind: "map", K: 9 /* ScalarType.STRING */, V: {kind: "scalar", T: 9 /* ScalarType.STRING */} },
  ],
);

/**
 * @generated from message com.sands.ldx.clientserver.ReadyToPlayRequest
 */
export const ReadyToPlayRequest = proto3.makeMessageType(
  "com.sands.ldx.clientserver.ReadyToPlayRequest",
  () => [
    { no: 1, name: "table_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "play_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message com.sands.ldx.clientserver.SitTableRequest
 */
export const SitTableRequest = proto3.makeMessageType(
  "com.sands.ldx.clientserver.SitTableRequest",
  () => [
    { no: 1, name: "table_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "seat_position", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
  ],
);

/**
 * @generated from message com.sands.ldx.clientserver.SitTableReply
 */
export const SitTableReply = proto3.makeMessageType(
  "com.sands.ldx.clientserver.SitTableReply",
  () => [
    { no: 1, name: "table_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "seat_position", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
  ],
);

/**
 * @generated from message com.sands.ldx.clientserver.StandTableRequest
 */
export const StandTableRequest = proto3.makeMessageType(
  "com.sands.ldx.clientserver.StandTableRequest",
  () => [
    { no: 1, name: "table_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "seat_position", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
  ],
);

/**
 * @generated from message com.sands.ldx.clientserver.ClearWagerRequest
 */
export const ClearWagerRequest = proto3.makeMessageType(
  "com.sands.ldx.clientserver.ClearWagerRequest",
  () => [
    { no: 1, name: "wager_type_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "context_id", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 3, name: "key", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 4, name: "play_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "table_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message com.sands.ldx.clientserver.ClearWagersRequest
 */
export const ClearWagersRequest = proto3.makeMessageType(
  "com.sands.ldx.clientserver.ClearWagersRequest",
  () => [
    { no: 1, name: "play_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "table_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message com.sands.ldx.clientserver.UpdatePlayerGameSettingRequest
 */
export const UpdatePlayerGameSettingRequest = proto3.makeMessageType(
  "com.sands.ldx.clientserver.UpdatePlayerGameSettingRequest",
  () => [
    { no: 1, name: "game_type", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "game_variant", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "setting", kind: "scalar", T: 12 /* ScalarType.BYTES */ },
  ],
);

/**
 * @generated from message com.sands.ldx.clientserver.UpdatePlayerTableSettingRequest
 */
export const UpdatePlayerTableSettingRequest = proto3.makeMessageType(
  "com.sands.ldx.clientserver.UpdatePlayerTableSettingRequest",
  () => [
    { no: 1, name: "table_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "setting", kind: "scalar", T: 12 /* ScalarType.BYTES */ },
  ],
);

/**
 * @generated from message com.sands.ldx.clientserver.GetPlayerGameSettingRequest
 */
export const GetPlayerGameSettingRequest = proto3.makeMessageType(
  "com.sands.ldx.clientserver.GetPlayerGameSettingRequest",
  () => [
    { no: 1, name: "game_type", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "game_variant", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message com.sands.ldx.clientserver.GetPlayerGameSettingReply
 */
export const GetPlayerGameSettingReply = proto3.makeMessageType(
  "com.sands.ldx.clientserver.GetPlayerGameSettingReply",
  () => [
    { no: 3, name: "data", kind: "scalar", T: 12 /* ScalarType.BYTES */ },
  ],
);

/**
 * @generated from message com.sands.ldx.clientserver.GetPlayerTableSettingRequest
 */
export const GetPlayerTableSettingRequest = proto3.makeMessageType(
  "com.sands.ldx.clientserver.GetPlayerTableSettingRequest",
  () => [
    { no: 1, name: "table_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message com.sands.ldx.clientserver.GetPlayerTableSettingReply
 */
export const GetPlayerTableSettingReply = proto3.makeMessageType(
  "com.sands.ldx.clientserver.GetPlayerTableSettingReply",
  () => [
    { no: 3, name: "data", kind: "scalar", T: 12 /* ScalarType.BYTES */ },
  ],
);

/**
 * @generated from message com.sands.ldx.clientserver.ListPlayerSettingsRequest
 */
export const ListPlayerSettingsRequest = proto3.makeMessageType(
  "com.sands.ldx.clientserver.ListPlayerSettingsRequest",
  [],
);

/**
 * @generated from message com.sands.ldx.clientserver.ListPlayerSettingsReply
 */
export const ListPlayerSettingsReply = proto3.makeMessageType(
  "com.sands.ldx.clientserver.ListPlayerSettingsReply",
  () => [
    { no: 1, name: "game_settings", kind: "message", T: PlayerGameSetting, repeated: true },
    { no: 2, name: "table_settings", kind: "message", T: PlayerTableSetting, repeated: true },
  ],
);

/**
 * @generated from message com.sands.ldx.clientserver.ListPlayerGameSettingsRequest
 */
export const ListPlayerGameSettingsRequest = proto3.makeMessageType(
  "com.sands.ldx.clientserver.ListPlayerGameSettingsRequest",
  [],
);

/**
 * @generated from message com.sands.ldx.clientserver.ListPlayerGameSettingsReply
 */
export const ListPlayerGameSettingsReply = proto3.makeMessageType(
  "com.sands.ldx.clientserver.ListPlayerGameSettingsReply",
  () => [
    { no: 1, name: "game_settings", kind: "message", T: PlayerGameSetting, repeated: true },
  ],
);

/**
 * @generated from message com.sands.ldx.clientserver.ListPlayerTableSettingsRequest
 */
export const ListPlayerTableSettingsRequest = proto3.makeMessageType(
  "com.sands.ldx.clientserver.ListPlayerTableSettingsRequest",
  [],
);

/**
 * @generated from message com.sands.ldx.clientserver.ListPlayerTableSettingsReply
 */
export const ListPlayerTableSettingsReply = proto3.makeMessageType(
  "com.sands.ldx.clientserver.ListPlayerTableSettingsReply",
  () => [
    { no: 2, name: "table_settings", kind: "message", T: PlayerTableSetting, repeated: true },
  ],
);

/**
 * @generated from message com.sands.ldx.clientserver.PlayerGameSetting
 */
export const PlayerGameSetting = proto3.makeMessageType(
  "com.sands.ldx.clientserver.PlayerGameSetting",
  () => [
    { no: 1, name: "game_type", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "game_variant", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "settings", kind: "scalar", T: 12 /* ScalarType.BYTES */ },
    { no: 4, name: "created_at", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 5, name: "updated_at", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
  ],
);

/**
 * @generated from message com.sands.ldx.clientserver.PlayerTableSetting
 */
export const PlayerTableSetting = proto3.makeMessageType(
  "com.sands.ldx.clientserver.PlayerTableSetting",
  () => [
    { no: 1, name: "table_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "settings", kind: "scalar", T: 12 /* ScalarType.BYTES */ },
    { no: 4, name: "created_at", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 5, name: "updated_at", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
  ],
);

/**
 * @generated from message com.sands.ldx.clientserver.UpdateSeatSettingsRequest
 */
export const UpdateSeatSettingsRequest = proto3.makeMessageType(
  "com.sands.ldx.clientserver.UpdateSeatSettingsRequest",
  () => [
    { no: 1, name: "table_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "play_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "seat_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "seat_settings", kind: "map", K: 9 /* ScalarType.STRING */, V: {kind: "scalar", T: 9 /* ScalarType.STRING */} },
  ],
);

/**
 * @generated from message com.sands.ldx.clientserver.UpdateSeatSettingsReply
 */
export const UpdateSeatSettingsReply = proto3.makeMessageType(
  "com.sands.ldx.clientserver.UpdateSeatSettingsReply",
  () => [
    { no: 1, name: "table_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "play_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "seat_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "seat_settings", kind: "map", K: 9 /* ScalarType.STRING */, V: {kind: "scalar", T: 9 /* ScalarType.STRING */} },
  ],
);

/**
 * @generated from message com.sands.ldx.clientserver.AuxiliaryGameDataRequest
 */
export const AuxiliaryGameDataRequest = proto3.makeMessageType(
  "com.sands.ldx.clientserver.AuxiliaryGameDataRequest",
  () => [
    { no: 1, name: "table_id", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 2, name: "play_id", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 3, name: "type", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "key", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message com.sands.ldx.clientserver.AuxiliaryGameDataReply
 */
export const AuxiliaryGameDataReply = proto3.makeMessageType(
  "com.sands.ldx.clientserver.AuxiliaryGameDataReply",
  () => [
    { no: 1, name: "table_id", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 2, name: "play_id", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 3, name: "type", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "key", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "data", kind: "scalar", T: 12 /* ScalarType.BYTES */ },
  ],
);

