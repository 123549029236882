import { action, AnnotationsMap, computed, makeObservable } from 'mobx';
import { DataStoreMobXAnnotations } from '../DataStore/mobx';
import { DeviceStore } from './DeviceStore';
import { DeviceStoreMobXPrivateProps } from './types.mobx';

/**
 * MobX annotations for `DeviceStore`.
 */
const DeviceStoreMobXAnnotations: AnnotationsMap<DeviceStore, DeviceStoreMobXPrivateProps> = {
	// Inherit from DataStore
	...DataStoreMobXAnnotations,

	// ---- Computed -----------------------------------------------------

	// >> Public
	deviceId: computed,
	requests: computed,
	numRequests: computed,
	firstRequest: computed,

	// ---- Actions ------------------------------------------------------

	// >> Public
	populateFromTableReply: action,

	// >> Protected
	fetchPopulateData: action,

	// ---- Excluded -----------------------------------------------------
	// NONE YET
};

/**
 * Makes the specified `DeviceStore` instance an observable MobX object.
 */
const bindDeviceStoreMobX = (instance: DeviceStore): boolean => {
	if (instance.isMobXBound) {
		return false;
	}

	makeObservable<DeviceStore, DeviceStoreMobXPrivateProps>(instance, {
		...DeviceStoreMobXAnnotations,
	});

	instance.isMobXBound = true;

	return true;
};

// ---- Exports -------------------------------------------------------------------------------------------------------

export { DeviceStoreMobXAnnotations, bindDeviceStoreMobX };
