import { reaction } from 'mobx';
import { DebugBase } from '../../../common';
import { filterNullUndefined } from '../../../helpers';
import { ChoiceManager, ResolutionManager, ServerTimeManager, WagerManager, WalletManager } from '../../../managers';
import { IServices } from '../../services';
import { ManagerFactory } from '../ManagerFactory';
import { IManagerInstances, IManagers, IManagersOpts } from './types';

class Managers extends DebugBase implements IManagers {
	/* #region ---- Properties --------------------------------------------------------------------------------------- */

	/**
	 * Currently assigned options.
	 */
	protected override _options: IManagersOpts = Managers.defaultOptions();

	/**
	 * Instance of the Services SDK class.
	 */
	protected _services: IServices;

	/**
	 * Instance of the ResolutionManager class.
	 */
	protected _resolutionManager!: ResolutionManager;

	/**
	 * Instance of the ServerTimeManager class.
	 */
	protected _serverTimeManager!: ServerTimeManager;

	/**
	 * Instance of the WalletManager class.
	 */
	protected _walletManager!: WalletManager;

	/**
	 * Instance of the WagerManager class.
	 */
	protected _wagerManager!: WagerManager;

	/**
	 * Instance of the ChoiceManager class.
	 */
	protected _choiceManager!: ChoiceManager;

	/**
	 * TRUE if the class has been initialized.
	 */
	protected _isInitialized: boolean = false;

	/* #endregion ---- Properties ------------------------------------------------------------------------------------ */

	/* #region ---- CONSTRUCTOR ---------------------------------------------------------------------------------------*/

	constructor(services: IServices, opts?: Maybe<IManagersOpts>) {
		super();

		this._services = services;

		opts != null && this.setOptions(opts);
		this.init();
	}

	/* #endregion ---- CONSTRUCTOR ------------------------------------------------------------------------------------*/

	/* #region ---- Public ------------------------------------------------------------------------------------------- */

	/**
	 * Sets/initializes the class options.
	 *
	 * - Overrides the parent class method.
	 */
	public override setOptions(opts: IManagersOpts) {
		const { newOpts } = this.resolveOptions(opts);
		this._options = newOpts;
		this.onSetOptions(newOpts);
	}

	/**
	 * Gets/sets the resolution manager instance.
	 */
	public get resolutionManager(): ResolutionManager {
		return this._resolutionManager;
	}
	public set resolutionManager(val: ResolutionManager) {
		this._resolutionManager = val;
	}

	/**
	 * Gets/sets the server time manager instance.
	 */
	public get serverTimeManager(): ServerTimeManager {
		return this._serverTimeManager;
	}
	public set serverTimeManager(val: ServerTimeManager) {
		this._serverTimeManager = val;
	}

	/**
	 * Gets/sets the wallet manager instance.
	 */
	public get walletManager(): WalletManager {
		return this._walletManager;
	}
	public set walletManager(val: WalletManager) {
		this._walletManager = val;
	}

	/**
	 * Gets/sets the wager manager instance.
	 */
	public get wagerManager(): WagerManager {
		return this._wagerManager;
	}
	public set wagerManager(val: WagerManager) {
		this._wagerManager = val;
	}

	/**
	 * Gets/sets the choice manager instance.
	 */
	public get choiceManager(): ChoiceManager {
		return this._choiceManager;
	}
	public set choiceManager(val: ChoiceManager) {
		this._choiceManager = val;
	}

	/**
	 * @returns Key/value pair of all manager instances.
	 */
	public getManagers(): IManagerInstances {
		return {
			resolutionManager: this.resolutionManager,
			serverTimeManager: this.serverTimeManager,
			walletManager: this.walletManager,
			wagerManager: this.wagerManager,
			choiceManager: this.choiceManager,
		};
	}

	/**
	 * @returns A JSON export of the pertinent data.
	 */
	public toJson(extended?: Maybe<boolean>): PlainObject {
		extended = extended ?? false;

		const result: PlainObject = {
			instanceId: this.instanceId,
			resolutionManager: this.resolutionManager.toJson(extended),
			serverTimeManager: this.serverTimeManager,
			walletManager: this.walletManager.toJson(extended),
			wagerManager: this.wagerManager.toJson(extended),
			choiceManager: this.choiceManager.toJson(extended),
		};

		if (extended) {
			result.isInitialized = this._isInitialized;
			result.isDebugEnabled = this.isDebugEnabled;
			result.debugClassLabel = this.debugClassLabel;
			result.options = { ...this._options };
		}

		return result;
	}

	/* #endregion ---- Public ---------------------------------------------------------------------------------------- */

	/* #region ---- Protected ---------------------------------------------------------------------------------------- */

	/**
	 * Resolves the options being passed in and returns the original and new options.
	 *
	 * - Overrides the parent class method.
	 */
	protected override resolveOptions(opts?: Maybe<IManagersOpts>) {
		const origOpts: IManagersOpts = {
			...Managers.defaultOptions(),
			...this._options,
		};

		const newOpts: IManagersOpts = {
			...origOpts,
			...(opts ?? {}),
		};

		return { origOpts, newOpts };
	}

	/**
	 * Called after new options are set.
	 *
	 * - Extends the parent class method.
	 */
	protected override onSetOptions(newOpts: IManagersOpts) {
		super.onSetOptions(newOpts);

		// TODO: Write the rest of this
	}

	/**
	 * Initializes the class instance.
	 */
	protected init(): boolean {
		const opts = this._options;

		if (this._isInitialized) {
			return false;
		}

		const isDebugEnabled = opts?.isDebugEnabled ?? null;
		const managerDebugLabel = opts?.managerDebugLabel ?? null;
		const useMobX = opts?.useMobX ?? null;

		const commonOpts = filterNullUndefined({ isDebugEnabled, useMobX, debugLabel: managerDebugLabel });

		// ---- ServerTimeManager ---------------------------------------

		const stmOpts = opts?.serverTimeManager;
		if (stmOpts?.instance) {
			this._serverTimeManager = stmOpts.instance;
		} else {
			const props = { ...stmOpts?.opts, ...commonOpts };
			this._serverTimeManager = ManagerFactory.newServerTimeManager(props);
		}

		// ---- ResolutionManager ---------------------------------------

		const rmOpts = opts?.resolutionManager;
		if (rmOpts?.instance) {
			this._resolutionManager = rmOpts.instance;
		} else {
			const wagersDict = rmOpts?.wagersDict ?? {};
			const sidebetsDict = rmOpts?.sideBetsDict ?? {};
			const props = { ...commonOpts, ...filterNullUndefined(rmOpts?.opts ?? {}) };
			this._resolutionManager = ManagerFactory.newResolutionManager(wagersDict, sidebetsDict, props);
		}

		// ---- WalletManager --------------------------------------------

		const wlmOpts = opts?.walletManager;
		if (wlmOpts?.instance) {
			this._walletManager = wlmOpts.instance;
		} else {
			const props = { ...commonOpts, ...filterNullUndefined(wlmOpts?.opts ?? {}) };
			this._walletManager = ManagerFactory.newWalletManager(props);
		}

		// ---- WagerManager ---------------------------------------------

		const wgmOpts = opts?.wagerManager;
		if (wgmOpts?.instance) {
			this._wagerManager = wgmOpts.instance;
		} else {
			const props = { ...commonOpts, ...filterNullUndefined(wgmOpts?.opts ?? {}) };
			const gameService = this._services.gameService;
			this._wagerManager = ManagerFactory.newWagerManager(gameService, props);
		}

		this._wagerManager.setWalletManager(this._walletManager);

		// ---- ChoiceManager --------------------------------------------

		const cmOpts = opts?.choiceManager;
		if (cmOpts?.instance) {
			this._choiceManager = cmOpts.instance;
		} else {
			const props = { ...commonOpts, ...filterNullUndefined(cmOpts?.opts ?? {}) };
			const gameService = this._services.gameService;
			this._choiceManager = ManagerFactory.newChoiceManager(gameService, props);
		}

		// ----------------------------------------------------------------

		if (isDebugEnabled) {
			this.addDebugReactions();
		}

		this._isInitialized = true;

		return true;
	}

	/**
	 * Adds debugging reactions to the manager instances. Remove when done testing.
	 */
	protected addDebugReactions() {
		// ---- WalletManager --------------------------------------------

		const walletManager = this._walletManager;

		reaction(
			() => walletManager.playerId,
			(value: string, prev: string) => {
				this.info(`Player ID updated '${prev}' --> '${value}'`, 'WalletManager');
			}
		);
		reaction(
			() => walletManager.lastUpdatedTs,
			(value: number, prev: number) => {
				this.info(`Last updated ${prev} --> ${value}`, 'WalletManager');
			}
		);

		// ---- WagerManager ---------------------------------------------

		const wagerManager = this._wagerManager;

		reaction(
			() => wagerManager.playerId,
			(value: string, prev: string) => {
				this.info(`Player ID updated '${prev}' --> '${value}'`, 'WagerManager');
			}
		);
		reaction(
			() => wagerManager.lastUpdatedTs,
			(value: number, prev: number) => {
				this.info(`Last updated ${prev} --> ${value}`, 'WagerManager');
			}
		);
		reaction(
			() => wagerManager.totalWageredAmount,
			(value: number, prev: number) => {
				this.info(`Wagered amount updated ${prev} --> ${value}`, 'WagerManager');
			}
		);

		// ---- ChoiceManager --------------------------------------------

		const choiceManager = this._choiceManager;

		reaction(
			() => choiceManager.choiceIds,
			(value: string[], prev: string[]) => {
				this.info(`Choice IDs updated '${prev.join(',')}' --> '${value.join(',')}'`, 'ChoiceManager');
			}
		);

		reaction(
			() => choiceManager.choicePeriodIds,
			(value: string[], prev: string[]) => {
				this.info(`Choice period IDs updated '${prev.join(',')}' --> '${value.join(',')}'`, 'ChoiceManager');
			}
		);
	}

	/* #endregion ---- Protected ------------------------------------------------------------------------------------- */

	/* #region ---- Static ------------------------------------------------------------------------------------------- */

	/**
	 * STATIC
	 * @returns The default options data used by this class.
	 */
	public static defaultOptions(): IManagersOpts {
		return {
			...DebugBase.defaultOptions(),
			useMobX: true,
			managerDebugLabel: null,
			resolutionManager: null,
			serverTimeManager: null,
			walletManager: null,
			wagerManager: null,
			choiceManager: null,
		};
	}

	/* #endregion ---- Static ---------------------------------------------------------------------------------------- */

	/* #region ---- Debug -------------------------------------------------------------------------------------------- */

	/**
	 * Overrides the parent class property.
	 *
	 * @returns The label to use when debugging.
	 */
	protected override get debugClassLabel(): string {
		return Managers.debugClassLabel();
	}

	/**
	 * STATIC
	 * @returns Label assigned to this class namespace.
	 */
	protected static debugClassLabel(): string {
		return `RpcLib.Sdk.Managers`;
	}

	/* #endregion ---- Debug ----------------------------------------------------------------------------------------- */
}

// ---- Exports -------------------------------------------------------------------------------------------------------

export { Managers as default };
export { Managers };
