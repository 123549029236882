import { getCurrency as lookupLocaleCurrency } from 'locale-currency';
import memoize from 'memoize-one';
import { createLocaleFormatter, getBrowserLocaleString } from '../locale';
import { DEFAULT_CURRENCY_CODE } from './constants';
import { IFormatCurrencyOpts } from './types';

/**
 * Formats the given amount with the specified currency / locale options.
 *
 * @returns The formatted currency.
 */
const formatCurrency = (amount: number, opts?: Maybe<IFormatCurrencyOpts>): string => {
	opts = opts ?? {};

	const locale = opts.locale || getBrowserLocaleString();
	const currencyCode = normalizeCurrencyCode(
		opts.currencyCode || getLocaleCurrencyCode(locale) || DEFAULT_CURRENCY_CODE
	);
	const precision = opts.precision ?? 2;

	const config: Intl.NumberFormatOptions = {
		style: 'currency',
		currency: currencyCode,
		minimumFractionDigits: precision,
		maximumFractionDigits: precision,
	};

	const formatter = createLocaleFormatter(locale, config);
	const formatted = formatter.format(amount).replace(/\s/g, ' ').replace('FRE', '❍').trim();

	return formatted;
};

/**
 * @returns The currency code for the specified locale. eg. 'en-US' will return currency code 'USD'.
 */
const getLocaleCurrencyCode = memoize((locale: string): Nullable<string> => {
	const val = lookupLocaleCurrency(locale) || null;

	return val == null ? null : normalizeCurrencyCode(val);
});

/**
 * @returns The browser's currency code for it's current locale.
 */
const getBrowserCurrencyCode = () => getLocaleCurrencyCode(getBrowserLocaleString());

/**
 * @returns The currency symbol (eg. $) for the specified locale and currency code. Code will default to the
 *          default currency code for the locale. eg. Locale 'en-US' defaults to currency code 'USD'.
 */
const getLocaleCurrencySymbol = (locale: string, currencyCode?: Maybe<string>): string => {
	locale = locale || getBrowserLocaleString();
	currencyCode = normalizeCurrencyCode(currencyCode || getLocaleCurrencyCode(locale) || DEFAULT_CURRENCY_CODE);

	try {
		return (0)
			.toLocaleString(locale, {
				style: 'currency',
				currency: currencyCode,
				minimumFractionDigits: 0,
				maximumFractionDigits: 0,
			})
			.replace(/\d/g, '')
			.trim();
	} catch (err) {
		return '';
	}
};

/**
 * @returns The browser's currency symbol for it's current locale.
 */
const getBrowserCurrencySymbol = () => getLocaleCurrencySymbol(getBrowserLocaleString());

/**
 * @returns The currency symbol for the specified currency code (and optional locale).
 */
const getCurrencySymbolForCode = (currencyCode: string, locale?: Maybe<string>): string => {
	locale = locale || getBrowserLocaleString();

	return getLocaleCurrencySymbol(locale, currencyCode);
};

/**
 * @returns The normalized currency code.
 */
const normalizeCurrencyCode = (currencyCode?: Maybe<string>): string => {
	return currencyCode != null ? currencyCode.toLocaleUpperCase() : '';
};

// ---- Exports -------------------------------------------------------------------------------------------------------

export {
	formatCurrency,
	getLocaleCurrencyCode,
	getBrowserCurrencyCode,
	getLocaleCurrencySymbol,
	getBrowserCurrencySymbol,
	getCurrencySymbolForCode,
	normalizeCurrencyCode,
};
