import { IGameService } from '../../../client/service/types';
import { filterNullUndefined } from '../../../helpers';
import { ChoiceManager, ResolutionManager, ServerTimeManager, WagerManager, WalletManager } from '../../../managers';
import {
	bindChoiceManagerMobX,
	bindResolutionManagerMobX,
	bindWagerManagerMobX,
	bindWalletManagerMobX,
} from '../../../managers';
import {
	IManagerFactoryNewChoiceManagerOpts,
	IManagerFactoryNewResolutionManagerOpts,
	IManagerFactoryNewServerTimeManagerOpts,
	IManagerFactoryNewWagerManagerOpts,
	IManagerFactoryNewWalletManagerOpts,
} from './types';

/**
 * A factory class for creating instances of the various manager classes.
 */
class ManagerFactory {
	/**
	 * @returns A new instance of the WalletManager class.
	 */
	public static newWalletManager(opts?: Maybe<IManagerFactoryNewWalletManagerOpts>): WalletManager {
		const isDebugEnabled = opts?.isDebugEnabled ?? null;
		const debugLabel = opts?.debugLabel || null;
		const managerOpts = {
			...filterNullUndefined({ isDebugEnabled, debugLabel }),
			...filterNullUndefined(opts?.managerOpts ?? {}),
		};

		const instance = new WalletManager(managerOpts);

		if (opts?.useMobX === true) {
			bindWalletManagerMobX(instance);
		}

		return instance;
	}

	/**
	 * @returns A new instance of the WagerManager class.
	 */
	public static newWagerManager(svc: IGameService, opts?: Maybe<IManagerFactoryNewWagerManagerOpts>): WagerManager {
		const isDebugEnabled = opts?.isDebugEnabled ?? null;
		const debugLabel = opts?.debugLabel || null;
		const managerOpts = {
			...filterNullUndefined({ isDebugEnabled, debugLabel }),
			...filterNullUndefined(opts?.managerOpts ?? {}),
		};

		const instance = new WagerManager(svc, managerOpts);

		if (opts?.useMobX === true) {
			bindWagerManagerMobX(instance);
		}

		return instance;
	}

	/**
	 * @returns A new instance of the ServerTimeManager class.
	 */
	public static newServerTimeManager(opts?: Maybe<IManagerFactoryNewServerTimeManagerOpts>): ServerTimeManager {
		const isDebugEnabled = opts?.isDebugEnabled ?? null;
		const debugLabel = opts?.debugLabel || null;
		const managerOpts = {
			...filterNullUndefined({ isDebugEnabled, debugLabel }),
			...filterNullUndefined(opts?.managerOpts ?? {}),
		};
		// TODO: Will this need MobX bindings??

		return new ServerTimeManager(managerOpts);
	}

	/**
	 * @returns A new instance of the ResolutionManager class.
	 */
	public static newResolutionManager(
		wagersDict: StringDict,
		sidebetsDict: StringDict,
		opts?: Maybe<IManagerFactoryNewResolutionManagerOpts>
	): ResolutionManager {
		const isDebugEnabled = opts?.isDebugEnabled ?? null;
		const debugLabel = opts?.debugLabel || null;
		const managerOpts = {
			...filterNullUndefined({ isDebugEnabled, debugLabel }),
			...filterNullUndefined(opts?.managerOpts ?? {}),
		};

		const instance = new ResolutionManager(wagersDict, sidebetsDict, managerOpts);

		if (opts?.useMobX === true) {
			bindResolutionManagerMobX(instance);
		}

		return instance;
	}

	/**
	 * @returns A new instance of the ChoiceManager class.
	 */
	public static newChoiceManager(svc: IGameService, opts?: Maybe<IManagerFactoryNewChoiceManagerOpts>): ChoiceManager {
		const isDebugEnabled = opts?.isDebugEnabled ?? null;
		const debugLabel = opts?.debugLabel || null;
		const managerOpts = {
			...filterNullUndefined({ isDebugEnabled, debugLabel }),
			...filterNullUndefined(opts?.managerOpts ?? {}),
		};

		const instance = new ChoiceManager(svc, managerOpts);

		if (opts?.useMobX === true) {
			bindChoiceManagerMobX(instance);
		}

		return instance;
	}
}

// ---- Exports -------------------------------------------------------------------------------------------------------

export { ManagerFactory as default };
export { ManagerFactory };
