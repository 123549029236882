import { AnnotationsMap } from 'mobx';
import { EventDispatcherBaseMobXAnnotations } from '../../../common/mobx';
import ManagerBase from './ManagerBase';
import { ManagerBaseMobXPrivateProps } from './types.mobx';

const ManagerBaseMobXAnnotations: AnnotationsMap<ManagerBase, ManagerBaseMobXPrivateProps> = {
	// Inherit from EventDispatcherBase
	...EventDispatcherBaseMobXAnnotations,

	// ---- Excluded -----------------------------------------------------

	// >>> Props
	_eventManager: false,

	// >>> Events
	on: false,
	once: false,
	off: false,
	any: false,
	unregisterEvents: false,
	unregisterAllEvents: false,
	hasRegisteredEvents: false,
	trigger: false,
	issueDebugEvent: false,
};

// ---- Exports -------------------------------------------------------------------------------------------------------

export { ManagerBaseMobXAnnotations };
