enum GenericGameState {
	// We do not know the state of the game
	UNKNOWN = '',
	// Table is open but no play yet
	OPEN = 'open',
	// The wagering phase for all players is open
	INITIAL = 'initial',
	// Table is timed, period has expired, play is waiting for anyone to place a wager
	WAITING_ON_WAGER = 'waiting_on_wager',
	// Run play has been called but we are perhaps not yet dealing
	IN_PLAY = 'in_play',
	// We are dealing
	DEALING = 'dealing',
	// We are in play with an active choice period that has not closed
	PLAYER_CHOICE = 'choice_play_period',
	// Play is complete and payouts are complete
	COMPLETED = 'completed',
}

// ---- Exports -------------------------------------------------------------------------------------------------------

export { GenericGameState };
