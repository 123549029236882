import debounce from 'lodash/debounce';
import { set } from 'mobx';
import { DebugBase } from '../../common';
import {
	DEFAULT_CURRENCY_CODE,
	DEFAULT_CURRENCY_EXPONENT,
	getCurrencySymbolForCode,
	newEmptyBalanceDataExt,
	normalizeCurrencyCode,
	resolveAmount,
} from '../../helpers';
import { ManagerBase } from '../lib';
import { DIRTY_BALANCE_STALE_THRESHOLD_MS, Events } from './constants';
import { IWalletDirtyBalanceHistory, WalletDirtyBalanceHistory } from './lib/WalletDirtyBalanceHistory';
import {
	IUpdateLocalBalanceDataEntry,
	IUpdateLocalBalanceDiff,
	IWalletLocalBalanceDataEntry,
	IWalletLocalBalances,
	IWalletLocalBalancesData,
	WalletLocalBalances,
} from './lib/WalletLocalBalances';
import {
	IWalletServerBalanceDataEntry,
	IWalletServerBalances,
	RawServerBalanceList,
	WalletServerBalances,
} from './lib/WalletServerBalances';
import {
	IMergeServerBalancesResult,
	IMethodUpdateLocalBalanceDataOpts,
	IWalletManager,
	IWalletManagerBalanceAmountAdjustmentDiff,
	IWalletManagerMethodCanAdjustAmountResult,
	IWalletManagerMethodCanSetBalanceAmountResult,
	IWalletManagerMethodCanSpendAmountResult,
	IWalletManagerOpts,
} from './types';

/**
 * Represents a set of balances (currencies & amounts) for a specific player and allows clean manipulation of balance
 * data in a way that supports immediate data updates for the sake of the client but also carefully syncs with new
 * balance data updates coming in from the server.
 *
 * Will fire important events (eg. `BALANCE_DATA_UPDATE`, `INSUFFICIENT_FUNDS`, etc.) when they occur via the
 * EventManager instance composed by this class. The class also support MobX observers and reactions.
 *
 * See the README.md file for full documentation.
 */
class WalletManager extends ManagerBase implements IWalletManager {
	/* #region ---- Properties --------------------------------------------------------------------------------------- */

	/**
	 * Currently assigned options.
	 */
	protected override _options: IWalletManagerOpts = WalletManager.defaultOptions();

	/**
	 * Player ID context for this wallet manager.
	 */
	protected _playerId: string = '';

	/**
	 * Active currency code. Used as a default in various methods when a valid currency code is not specified.
	 */
	protected _activeCurrencyCode: string = WalletManager.defaultCurrencyCode();

	/**
	 * Last received balance data from the server.
	 */
	protected _serverBalances!: IWalletServerBalances;

	/**
	 * Current local balance data.
	 */
	protected _localBalances!: IWalletLocalBalances;

	/**
	 * Dirty local balance history. Stores entries per currency of local balance amounts made when in the dirty state.
	 */
	protected _dirtyBalanceHistory!: IWalletDirtyBalanceHistory;

	/**
	 * Flag indicating if this class instance is currently bound to MobX as an observable.
	 */
	protected _isMobXBound: boolean = false;

	/* #endregion ---- Properties -------------------------------------------------------------------------------------*/

	/* #region ---- CONSTRUCTOR ---------------------------------------------------------------------------------------*/

	constructor(opts?: Maybe<IWalletManagerOpts>) {
		super();

		opts != null && this.setOptions(opts);

		this.init();
	}

	/* #endregion ---- CONSTRUCTOR ----------------------------------------------------------------------------------- */

	/* #region ---- Public ------------------------------------------------------------------------------------------- */

	/**
	 * ACTION
	 * Sets/initializes the class options.
	 *
	 * - Overrides the parent class method.
	 */
	public setOptions(opts?: IWalletManagerOpts) {
		const { newOpts } = this.resolveOptions(opts);

		if (this._isMobXBound) {
			set(this, '_options', newOpts);
		} else {
			this._options = newOpts;
		}

		this.onSetOptions(newOpts);
	}

	/**
	 * ACTION
	 * Resets this class instance back to the clear/initial state.
	 */
	public clear() {
		this.init();
	}

	/**
	 * The active currency code being used by this wallet manager.
	 */
	public get activeCurrencyCode(): string {
		return this._activeCurrencyCode;
	}
	public set activeCurrencyCode(val: string) {
		this.setActiveCurrencyCode(val);
	}
	// Actionable setter method for MobX.
	protected setActiveCurrencyCode(val: string) {
		const currencyCode = normalizeCurrencyCode(val) || WalletManager.defaultCurrencyCode();
		if (currencyCode === this._activeCurrencyCode) {
			return;
		}

		this._activeCurrencyCode = currencyCode;
	}

	/**
	 * Get/set the active wagering player ID. Setting this will also set the same player ID on the WalletManager instance.
	 */
	public get playerId(): string {
		return this._playerId;
	}
	public set playerId(val: string) {
		this.setPlayerId(val);
	}
	// Actionable setter method for MobX.
	protected setPlayerId(val: string) {
		if (val === this._playerId) {
			return;
		}

		const prevPlayerId = this._playerId;
		this._playerId = val;
		this.onPlayerIdChanged(this._playerId, prevPlayerId);
	}

	/**
	 * Gets/sets whether or not this class instance is currently bound to MobX as an observable.
	 */
	public get isMobXBound(): boolean {
		return this._isMobXBound;
	}
	public set isMobXBound(value: boolean) {
		this._isMobXBound = value;
	}

	/**
	 * Gets when the local balance data was last updated.
	 */
	public get lastUpdatedTs(): number {
		return this._localBalances.lastUpdatedTs;
	}

	/**
	 * Gets the current local balance data - for the active currency code.
	 */
	public get balanceData(): IWalletLocalBalanceDataEntry {
		const currencyCode = this._activeCurrencyCode;

		return this.getBalanceData(currencyCode) ?? WalletManager.getDefaultBalanceData({ currencyCode });
	}

	/**
	 * Gets the current local balance amount - for the active currency code.
	 */
	public get balanceAmount(): number {
		return this.balanceData.amount;
	}

	/**
	 * Gets the current local balance real amount - for the active currency code.
	 */
	public get balanceReal(): number {
		return this.balanceData.amountReal;
	}

	/**
	 * Gets the current local balance monetary value - for the active currency code.
	 */
	public get balanceMoney(): string {
		return this.balanceData.amountMoney;
	}

	/**
	 * Gets the current local balance currency code - for the active currency code.
	 */
	public get balanceCurrencyCode(): string {
		return this.balanceData.currencyCode;
	}

	/**
	 * Gets the current local balance currency symbol - for the active currency code.
	 */
	public get balanceCurrencySymbol(): string {
		return this.balanceData.currencySymbol;
	}

	/**
	 * Attempts to set the local balance amount for the specified currency code.
	 *
	 * - Currency code will default to the current active currency code.
	 * - Amount less than zero will be converted to zero.
	 *
	 * @returns The balance adjustment amounts if successfully adjusted, otherwise NULL.
	 */
	public setBalanceAmount(
		amount: number,
		currencyCode?: Maybe<string>
	): Nullable<IWalletManagerBalanceAmountAdjustmentDiff> {
		// Use specified currency, or the current active currency if not specified
		currencyCode = this.resolveCurrencyCode(currencyCode);

		const validate = this.canSetBalanceAmount(amount, currencyCode);
		if (!validate.canSet) {
			return null;
		}

		const newAmount = validate.amountDiff.updated;
		const success = this.updateLocalBalanceAmount(currencyCode, newAmount) != null;

		// Return the adjustment amounts - or NULL if not successful
		return success ? validate.amountDiff : null;
	}

	/**
	 * Increment or decrement the local balance for the specified currency code by the specified amount.
	 *
	 * - Currency code will default to the current active currency code.
	 * - Adjusted balance will not go below zero.
	 *
	 * eg. Current balance for USD is 50,000 ($50), adjusted by positive amount 25,000 ($25) is 75,000 ($75).
	 * eg. Current balance is 50,000 ($50), adjusted by amount -25,000 (-$25) is 25,000 ($25)
	 *
	 * @returns The balance adjustment amounts if successfully adjusted, otherwise NULL.
	 */
	public adjustBalanceAmount(
		byAmount: number,
		currencyCode?: Maybe<string>
	): Nullable<IWalletManagerBalanceAmountAdjustmentDiff> {
		// Use specified currency, or the current active currency if not specified
		currencyCode = this.resolveCurrencyCode(currencyCode);

		// Validate the adjustment
		const validate = this.canAdjustBalanceAmount(byAmount, currencyCode);
		if (validate.canAdjust === false) {
			if (validate.insufficientFunds) {
				this.issueInsufficientFundsEvent(byAmount, currencyCode);
			}
			return null;
		}

		// Skip zero adjustments
		if (byAmount === 0) {
			return validate.amountDiff;
		}

		const newAmount = validate.amountDiff.updated;
		const success = this.updateLocalBalanceAmount(currencyCode, newAmount) != null;

		// Return the adjustment amounts - or NULL if not successful
		return success ? validate.amountDiff : null;
	}

	/**
	 * Decreases the local balance for the specified currency code by the specified amount.
	 *
	 * - Currency code will default to the current active currency code.
	 * - Adjusted balance cannot
	 *
	 * eg. Current balance for USD is 50,000 ($50), decreased by amount 25,000 ($25) is 25,000 ($25).
	 *
	 * @returns The balance adjustment amounts if successfully adjusted, otherwise NULL.
	 */
	public spendAmount(
		amount: number,
		currencyCode?: Maybe<string>
	): Nullable<IWalletManagerBalanceAmountAdjustmentDiff> {
		// Use specified currency, or the current active currency if not specified
		currencyCode = this.resolveCurrencyCode(currencyCode);

		// Validate the spend
		const validate = this.canSpendAmount(amount, currencyCode);
		if (validate.canSpend === false) {
			if (validate.insufficientFunds) {
				this.issueInsufficientFundsEvent(amount, currencyCode);
			}
			return null;
		}

		const adjustBy = validate.amountDiff.difference;

		return this.adjustBalanceAmount(adjustBy, currencyCode);
	}

	/**
	 * Determines if we are able to set the specified currency to the specified amount.
	 *
	 * - The `amount` cannot be negative.
	 * - Currency code will default to the current active currency code.
	 * - A balance entry for the specified currency must exist.
	 */
	public canSetBalanceAmount(
		amount: number,
		currencyCode?: Maybe<string>
	): IWalletManagerMethodCanSetBalanceAmountResult {
		const debugMethod = 'canSetBalanceAmount';

		// Use specified currency, or the current active currency if not specified
		currencyCode = this.resolveCurrencyCode(currencyCode);

		// Default result
		const result: IWalletManagerMethodCanSetBalanceAmountResult = {
			currencyCode,
			amount,
			canSet: false,
			reason: '',
			hasEntry: false,
			amountDiff: { current: 0, updated: 0, difference: 0 },
		};

		// Make sure we have a balance entry for the specified currency
		const currentEntry = this._localBalances.get(currencyCode);
		if (currentEntry == null) {
			const message = `Currency '${currencyCode}' does not exist in current wallet.`;
			this.warn(message, debugMethod, {
				currencyCode,
				amount,
			});
			result.reason = message;

			return result;
		}

		result.hasEntry = true;

		if (amount < 0) {
			const message = `Amount to set cannot negative.`;
			this.warn(message, debugMethod, {
				currencyCode,
				amount,
			});
			result.reason = message;

			return result;
		}

		// Determine the new adjusted amount
		const currentAmount = currentEntry.amount;
		const newAmount = amount;
		const diffAmount = newAmount - currentAmount; // Difference
		result.amountDiff = { current: currentAmount, updated: newAmount, difference: diffAmount };

		// We can set the balance amount
		result.canSet = true;

		return result;
	}

	/**
	 * Determines if we are able to spend the specified currency amount.
	 *
	 * - The `amount` cannot be negative.
	 * - Currency code will default to the current active currency code.
	 * - A balance entry for the specified currency must exist.
	 * - Adjusted balance cannot be negative.
	 */
	public canSpendAmount(amount: number, currencyCode?: Maybe<string>): IWalletManagerMethodCanSpendAmountResult {
		const debugMethod = 'canSpendAmount';

		// Use specified currency, or the current active currency if not specified
		currencyCode = this.resolveCurrencyCode(currencyCode);

		// Default result
		const result: IWalletManagerMethodCanSpendAmountResult = {
			currencyCode,
			amount,
			canSpend: false,
			reason: '',
			hasEntry: this._localBalances?.has(currencyCode) ?? false,
			insufficientFunds: false,
			amountDiff: { current: 0, updated: 0, difference: 0 },
		};

		if (amount === 0) {
			result.canSpend = true;

			return result;
		}

		if (amount < 0) {
			const message = `Amount to spend cannot negative.`;
			this.warn(message, debugMethod, {
				currencyCode,
				amount,
			});
			result.reason = message;

			return result;
		}

		const adjustAmount = amount > 0 ? -amount : 0;
		const validateAdjust = this.canAdjustBalanceAmount(adjustAmount, currencyCode);
		result.hasEntry = validateAdjust.hasEntry;
		result.reason = validateAdjust.reason;
		result.insufficientFunds = validateAdjust.insufficientFunds;
		result.amountDiff = validateAdjust.amountDiff;
		result.canSpend = validateAdjust.canAdjust;

		return result;
	}

	/**
	 * Determines if we are able to adjust the specified currency by the specified amount.
	 *
	 * - The `byAmount` can be positive or negative.
	 * - Currency code will default to the current active currency code.
	 * - A balance entry for the specified currency must exist.
	 * - Adjusted balance cannot be negative.
	 */
	public canAdjustBalanceAmount(
		byAmount: number,
		currencyCode?: Maybe<string>
	): IWalletManagerMethodCanAdjustAmountResult {
		const debugMethod = 'canAdjustBalanceAmount';

		// Use specified currency, or the current active currency if not specified
		currencyCode = this.resolveCurrencyCode(currencyCode);

		// Default result
		const result: IWalletManagerMethodCanAdjustAmountResult = {
			currencyCode,
			byAmount,
			canAdjust: false,
			reason: '',
			hasEntry: false,
			insufficientFunds: false,
			amountDiff: { current: 0, updated: 0, difference: 0 },
		};

		// Make sure we have a balance entry for the specified currency
		const currentEntry = this._localBalances.get(currencyCode);
		if (currentEntry == null) {
			const message = `Currency '${currencyCode}' does not exist in current wallet.`;
			this.warn(message, debugMethod, {
				currencyCode,
				byAmount,
			});
			result.reason = message;

			return result;
		}

		result.hasEntry = true;

		// Determine the new adjusted amount
		const currentAmount = currentEntry.amount;
		const newAmount = currentAmount + byAmount; // New adjusted amount
		const diffAmount = newAmount - currentAmount; // Difference
		result.amountDiff = { current: currentAmount, updated: newAmount, difference: diffAmount };

		// Allow zero adjustments
		if (byAmount === 0) {
			result.canAdjust = true;
			return result;
		}

		// Don't allow us to adjust to a negative amount
		if (newAmount < 0) {
			const message = `Adjusted balance amount ${newAmount} cannot be less than zero.`;
			this.warn(message, debugMethod, {
				currencyCode,
				byAmount,
			});
			result.reason = message;
			result.insufficientFunds = true;

			return result;
		}

		result.canAdjust = true;

		return result;
	}

	/**
	 * @returns The local balance data for the specified (or active) currency.
	 *          Will return NULL if balance data for the currency is not present in the local data.
	 */
	public getBalanceData(currencyCode?: Maybe<string>): Nullable<IWalletLocalBalanceDataEntry> {
		// Use specified currency, or the current active currency if not specified
		currencyCode = this.resolveCurrencyCode(currencyCode);

		return this._localBalances.get(currencyCode);
	}

	/**
	 * @returns The current raw amount for the specified (or active) currency. eg. 5000.
	 *          Will return NULL if balance data for the currency is not present in the local data.
	 */
	public getBalanceAmount(currencyCode?: Maybe<string>): Nullable<number> {
		return this.getBalanceData(currencyCode)?.amount ?? null;
	}

	/**
	 * @returns The current real amount for the specified (or active) currency. eg. raw:5000 --> real:50.
	 *          Will return NULL if balance data for the currency is not present in the local data.
	 */
	public getBalanceAmountReal(currencyCode?: Maybe<string>): Nullable<number> {
		return this.getBalanceData(currencyCode)?.amountReal ?? null;
	}

	/**
	 * @returns The formatted actual amount for the specified (or active) currency. eg. "$50.00"
	 *          Will return NULL if balance data for the currency is not present in the local data.
	 */
	public getBalanceMoney(currencyCode?: Maybe<string>): Nullable<string> {
		return this.getBalanceData(currencyCode)?.amountMoney ?? null;
	}

	/**
	 * @returns The currency symbol used for the specified currency. eg. Code "USD" --> Symbol "$"
	 *          Will return NULL if balance data for the currency is not present in the local data.
	 */
	public getBalanceCurrencySymbol(currencyCode?: Maybe<string>): Nullable<string> {
		return this.getBalanceData(currencyCode)?.currencySymbol ?? null;
	}

	/**
	 * @returns A list of all the current local balance data entries.
	 */
	public getBalancesList() {
		return this._localBalances.getBalancesList();
	}

	/**
	 * @returns A lookup of all the current local balance data entries.
	 */
	public getBalancesLookup() {
		return this._localBalances.getBalancesLookup();
	}

	/**
	 * @returns TRUE if a balance for the specified currency exists in the wallet manager data.
	 */
	public hasCurrency(currencyCode: string): boolean {
		return this._localBalances.has(currencyCode);
	}

	/**
	 * @returns A JSON export of the current pertinent data.
	 */
	public override toJson(extended?: Maybe<boolean>): PlainObject {
		extended = extended ?? false;

		const toJs = (val: unknown) => DebugBase.toJs(val, { extended, useMobXToJs: this.isMobXBound });

		const result: PlainObject = {
			playerId: this.playerId,
			activeCurrencyCode: this.activeCurrencyCode,
			balanceData: toJs(this.balanceData),
		};

		if (extended) {
			result.extended = {
				isMobXBound: this.isMobXBound,
				options: toJs({ ...this._options }),
				localBalances: toJs(this._localBalances),
				serverBalances: toJs(this._serverBalances),
				dirtyBalanceHistory: toJs(this._dirtyBalanceHistory),

				// Debug info from base class
				_Debug: { ...super.toJson(extended) },
			};
		}

		return result;
	}

	/* #endregion ---- Public ---------------------------------------------------------------------------------------- */

	/* #region ---- Protected ---------------------------------------------------------------------------------------- */

	/* #region ::Local Balances:: */

	/**
	 * ACTION
	 * Updates the local balance data entry for the specified currency code to the specified amount.
	 *
	 * @returns The updated amount if successful, otherwise NULL.
	 */
	protected updateLocalBalanceAmount(currencyCode: string, newAmount: number): Nullable<number> {
		const updated = this.updateLocalBalanceData(currencyCode, { amount: newAmount, isDirty: true });

		return updated != null ? newAmount : null;
	}

	/**
	 * ACTION
	 * Updates the local balance data for the specified currency code.
	 *
	 * Data update is immutable and operates on a copy of the local balances data.
	 *
	 * @returns A diff summary of the balances data if successful, otherwise NULL.
	 */
	protected updateLocalBalanceData(
		currencyCode: string,
		newProps: IUpdateLocalBalanceDataEntry,
		opts?: Maybe<IMethodUpdateLocalBalanceDataOpts>
	): Nullable<IUpdateLocalBalanceDiff> {
		// ++++ TODO: Enhancement -- Only update if new props values did change. ++++

		currencyCode = normalizeCurrencyCode(currencyCode);
		const willIssueEvent = opts?.willIssueBalanceUpdateEvent ?? true;

		const diff = WalletLocalBalances.newFrom(currencyCode, newProps, this._localBalances);
		if (diff === null) {
			return null;
		}

		const { balances: balancesDiff, entry: entryDiff } = diff;
		const { updated, original } = balancesDiff;
		const updatedTs = updated.lastUpdatedTs;

		const { original: oe, updated: ue } = entryDiff;
		const wasEntryDirty = oe.isDirty;

		// Immutable update of the current class local balances
		this.setLocalBalancesInstance(updated);

		// If the original entry was dirty and the amount did change then add the original amount to the dirty balance history
		if (wasEntryDirty && ue.amount !== oe.amount) {
			this._dirtyBalanceHistory.addAmount(currencyCode, oe.amount, updatedTs);
		}

		// Issue the balance update event if specified
		willIssueEvent && this.issueLocalBalanceDataUpdateEvent(this._localBalances, original);

		return diff;
	}

	/**
	 * ACTION
	 * Resets the main local balances back to the initial/clear values.
	 */
	protected clearLocalBalances() {
		this._localBalances.clear();
	}

	/* #endregion ::Local Balances:: */

	/* #region ::Main Server Balances:: */

	/**
	 * ACTION
	 * Resets the server balances back to the initial/clear values.
	 */
	protected clearServerBalances() {
		this._serverBalances.clear();
	}

	/* #endregion ::Main Server Balances:: */

	/* #region ::Balance Sync:: */

	/**
	 * ACTION
	 * Update the server balance data using the raw balance list from the server.
	 */
	protected updateFromServerBalanceList = (rawList: RawServerBalanceList): boolean => {
		const debugMethod = 'updateFromServerBalanceList';

		// If the incoming server wagers data is the same as the current stored server wagers data then do nothing
		if (this._serverBalances.isRawDataSame(rawList)) {
			this.warn(
				'Server balance data is the same as the current stored server balance data. Will not update.',
				debugMethod
			);
			return false;
		}

		const lastUpdatedTs = Date.now();

		// Update the server balances data
		const newServerBalances = WalletServerBalances.newFromRawList(rawList, {
			playerId: this.playerId,
			populateOpts: { updatedTs: lastUpdatedTs },
		});

		let attempts = 0;
		let canUpdate = false;
		let merge: Nullable<IMergeServerBalancesResult> = null;

		let localLastUpdatedTs = this._localBalances.lastUpdatedTs;

		while (!canUpdate && attempts < 5) {
			attempts++;
			merge = this.mergeServerBalancesWithLocal(newServerBalances);
			canUpdate = this._localBalances.lastUpdatedTs === localLastUpdatedTs;

			if (!canUpdate) {
				localLastUpdatedTs = this._localBalances.lastUpdatedTs;
			}
		}

		if (!canUpdate || merge == null) {
			return false;
		}

		this.setServerBalancesInstance(newServerBalances);
		this.setLocalBalancesData(merge.balances.updated.data);
		this.setDirtyBalanceHistoryInstance(merge.history.updated);

		this.issueLocalBalanceDataUpdateEvent(merge.balances.updated, merge.balances.original);

		return true;
	};

	/**
	 * Merges the specified server balances data with the specified local balances data to create a new local
	 * balances state. Also determines the new dirty history state.
	 *
	 * @returns A diff summary of the local balances data and dirty history data.
	 */
	protected mergeServerBalancesWithLocal(
		serverBalances: IWalletServerBalances,
		localBalances?: Maybe<IWalletLocalBalances>,
		dirtyBalanceHistory?: Maybe<IWalletDirtyBalanceHistory>
	): IMergeServerBalancesResult {
		const debugMethod = 'mergeServerBalancesWithLocal';

		localBalances = localBalances ?? this._localBalances;
		dirtyBalanceHistory = dirtyBalanceHistory ?? this._dirtyBalanceHistory;

		const updatedTs = serverBalances.lastUpdatedTs;

		// Create a shallow clone of the current local balances data
		const originalBalances = localBalances.clone();

		// Note: We start with an empty structure for our updated local balances to handle the case where a currency
		// could get removed in the server balance data
		const updatedBalances: IWalletLocalBalances = new WalletLocalBalances({ updatedTs });

		// Dirty balance history state resulting from our merge
		const originalDbHistory = dirtyBalanceHistory.clone();
		const updatedDbHistory = originalDbHistory.clone();

		const makeResult = () => {
			return {
				balances: { original: originalBalances, updated: updatedBalances },
				history: { original: originalDbHistory, updated: updatedDbHistory },
			};
		};

		// Return default empty local balance data if the server balances are empty
		if (serverBalances.isEmpty) {
			this.warn('Empty server balance data', debugMethod);

			return makeResult();
		}

		const updateLocalBalanceEntry = (currencyCode: string, props: IUpdateLocalBalanceDataEntry) => {
			const diff = WalletLocalBalances.newEntryDataFrom(currencyCode, props, originalBalances);
			diff != null && updatedBalances.set(currencyCode, diff.updated);
		};

		const clearDbHistoryItems = (currencyCode: string) => {
			const diff = WalletDirtyBalanceHistory.clearItemsFor(currencyCode, updatedDbHistory, { updatedTs });
			updatedDbHistory.lookup.set(currencyCode, diff.items.updated);
			updatedDbHistory.lastUpdatedTs = updatedTs;
		};

		const ltrimDbHistoryItems = (currencyCode: string, trimIndex: number) => {
			const diff = WalletDirtyBalanceHistory.sliceItemsFor(currencyCode, updatedDbHistory, trimIndex + 1);
			updatedDbHistory.lookup.set(currencyCode, diff.items.updated);
			updatedDbHistory.lastUpdatedTs = updatedTs;
		};

		// Process only the balance entries being sent by the server. Local balances that do not match this will get removed.
		serverBalances.list.forEach((svrBal: IWalletServerBalanceDataEntry) => {
			const currencyCode = svrBal.currencyCode;

			// The current local balance entry
			const bal = originalBalances.lookup.get(currencyCode) ?? null;

			//----------------------------------------------------------------------
			// New server balance (currency not in original local balances)
			//----------------------------------------------------------------------
			if (bal == null) {
				const insert: IWalletLocalBalanceDataEntry = {
					...svrBal,
					isDirty: false,
					lastUpdatedTs: updatedTs,
				};

				// Insert new server balance as a new local balance
				updatedBalances.lookup.set(currencyCode, insert);

				// this.info(`${currencyCode}: Inserted new server balance ${svrBal.amount}.`, debugMethod, insert);

				return; // CONTINUE to next server balance
			}

			//----------------------------------------------------------------------
			// Server balance amount is the same as the local balance.
			// Treat this as a confirmation of the local balance entry.
			//----------------------------------------------------------------------
			if (bal.amount === svrBal.amount) {
				updateLocalBalanceEntry(currencyCode, { lastUpdatedTs: updatedTs, isDirty: false });
				clearDbHistoryItems(currencyCode);

				return; // CONTINUE to next server balance
			}

			//-----------------------------------------------------------
			// Balance amount is different than the local balance
			//-----------------------------------------------------------
			// >> Local balance is NON-DIRTY - accept the new server provided value
			if (!bal.isDirty) {
				// this.info(
				// 	`${currencyCode}: Local balance ${bal.amount} updated to match server balance ${svrBal.amount}.`,
				// 	debugMethod
				// );

				updateLocalBalanceEntry(currencyCode, { amount: svrBal.amount, lastUpdatedTs: updatedTs, isDirty: false });
				clearDbHistoryItems(currencyCode);

				return; // CONTINUE to next server balance
			}

			// >> Dirty local balance is STALE (based on last updated timestamp) - accept the new server provided value
			const isLocalStale = updatedTs - bal.lastUpdatedTs >= DIRTY_BALANCE_STALE_THRESHOLD_MS;

			if (isLocalStale) {
				this.warn(
					`${currencyCode}: Server balance ${svrBal.amount} will override STALE dirty local balance ${bal.amount}`,
					debugMethod
				);

				updateLocalBalanceEntry(currencyCode, { amount: svrBal.amount, lastUpdatedTs: updatedTs, isDirty: false });
				clearDbHistoryItems(currencyCode);

				return; // CONTINUE to next server balance
			}

			// Attempt to locate the balance amount in dirty balance history prior to this update
			const historyIndex = originalDbHistory.findHistoryItemIndex(currencyCode, svrBal.amount, updatedTs);

			// >> Server balance is an amount that we do not have a record of placing - accept the new server provided value
			if (historyIndex === -1) {
				this.warn(
					`` +
						`${currencyCode}: Incoming server balance ${svrBal.amount} is not detected in dirty balance history. ` +
						`Server balance will override dirty local balance ${bal.amount}`,
					debugMethod
				);

				updateLocalBalanceEntry(currencyCode, { amount: svrBal.amount, lastUpdatedTs: updatedTs, isDirty: false });
				clearDbHistoryItems(currencyCode);
			}
			// >> Server balance matches an amount we have in history - the dirty local balance is authorative
			else {
				updateLocalBalanceEntry(currencyCode, { lastUpdatedTs: bal.amount });
				ltrimDbHistoryItems(currencyCode, historyIndex);
			}
		});

		return makeResult();
	}

	/* #endregion ::Balance Sync:: */

	/* #region ::Other:: */

	/**
	 * ACTION
	 * Initializes the class properties.
	 */
	protected init() {
		const playerId = this.playerId;
		const isDebugEnabled = this.isDebugEnabled;

		const localBalances = new WalletLocalBalances({ isDebugEnabled });
		this.setLocalBalancesInstance(localBalances);

		const serverBalances = new WalletServerBalances({ playerId, isDebugEnabled });
		this.setServerBalancesInstance(serverBalances);

		const dirtyBalanceHistory = new WalletDirtyBalanceHistory();
		this.setDirtyBalanceHistoryInstance(dirtyBalanceHistory);
	}

	/**
	 * Resolves the options being passed in and returns the original and new options.
	 *
	 * - Overrides the base class method.
	 */
	protected override resolveOptions(opts?: Maybe<IWalletManagerOpts>) {
		const origOpts: IWalletManagerOpts = {
			...WalletManager.defaultOptions(),
			...this._options,
		};

		const newOpts: IWalletManagerOpts = {
			...origOpts,
			...(opts ?? {}),
		};

		return { origOpts, newOpts };
	}

	/**
	 * Called after new options are set.
	 *
	 * - Extends the base class method.
	 */
	protected onSetOptions(newOpts: IWalletManagerOpts) {
		super.onSetOptions(newOpts);

		if (newOpts.playerId != null && newOpts.playerId !== this._playerId) {
			const prevPlayerId = this._playerId;
			this._playerId = newOpts.playerId;
			this.onPlayerIdChanged(this._playerId, prevPlayerId);
		}

		if (newOpts.activeCurrencyCode != null && newOpts.activeCurrencyCode !== this._activeCurrencyCode) {
			const currencyCode = normalizeCurrencyCode(newOpts.activeCurrencyCode);
			this._activeCurrencyCode = currencyCode || this._activeCurrencyCode;
		}
	}

	/**
	 * Called when the player ID changes.
	 */
	protected onPlayerIdChanged(newPlayerId: string, _prevPlayerId: string) {
		if (this._serverBalances) {
			this._serverBalances.playerId = newPlayerId;
		}

		// TODO: If the player ID changes we should clear all balances and reset the dirty local balance history.
	}

	/**
	 * @returns The currency code, or, the active currency if currency code is empty.
	 */
	protected resolveCurrencyCode(currencyCode?: Maybe<string>): string {
		return normalizeCurrencyCode(currencyCode) || this._activeCurrencyCode;
	}

	/**
	 * Issues a `BALANCE_DATA_UPDATE` event.
	 */
	protected issueLocalBalanceDataUpdateEvent(
		updated?: Maybe<IWalletLocalBalances>,
		original?: Maybe<IWalletLocalBalances>
	) {
		const debugMethod = 'issueLocalBalanceDataUpdateEvent';

		const toJs = (val: unknown): PlainObject =>
			DebugBase.toJs(val, { useMobXToJs: this.isMobXBound, forceObject: true, useToJson: false }) as PlainObject;

		updated = updated ?? this._localBalances;
		original = original ?? null;

		const newData: PlainObject = { ...updated.data, lookup: toJs(updated.data.lookup) };
		const origData: Nullable<PlainObject> = original ? { ...original.data, lookup: toJs(original.data.lookup) } : null;

		const eventData: PlainObject = {
			event: Events.BALANCE_DATA_UPDATE,
			balanceData: { original: origData, updated: newData },
		};

		this.trigger(Events.BALANCE_DATA_UPDATE, eventData);

		// Log and trigger an info debug event
		this.info(Events.BALANCE_DATA_UPDATE, debugMethod, eventData);
	}

	/**
	 * Issue an `INSUFFICIENT_FUNDS` event.
	 */
	protected issueInsufficientFundsEvent(attemptAmount: number, currencyCode: string) {
		const debugMethod = 'issueInsufficientFundsEvent';

		const { amountReal: attemptAmountReal } = resolveAmount(attemptAmount);
		const haveAmountReal = this.getBalanceAmountReal(currencyCode);

		const eventData: PlainObject = {
			event: Events.BALANCE_DATA_UPDATE,
			tried: attemptAmountReal,
			have: haveAmountReal,
		};

		this.trigger(Events.INSUFFICIENT_FUNDS, eventData);

		// Also log & trigger an error event
		this.error(Events.INSUFFICIENT_FUNDS, debugMethod, eventData);
	}

	/**
	 * ACTION
	 * Sets the `_localBalances` data instance.
	 */
	protected setLocalBalancesInstance(instance: IWalletLocalBalances): void {
		if (this.isMobXBound) {
			set(this, '_localBalances', instance);
		} else {
			this._localBalances = instance;
		}
	}

	/**
	 * ACTION
	 * Sets the `_localBalances` embedded data.
	 */
	protected setLocalBalancesData(data: IWalletLocalBalancesData): void {
		this._localBalances.data = data;
	}

	/**
	 * ACTION
	 * Sets the `_serverBalances` data instance.
	 */
	protected setServerBalancesInstance(instance: IWalletServerBalances): void {
		if (this.isMobXBound) {
			set(this, '_serverBalances', instance);
		} else {
			this._serverBalances = instance;
		}
	}

	/**
	 * ACTION
	 * Sets the `_dirtyBalanceHistory` data instance.
	 */
	protected setDirtyBalanceHistoryInstance(instance: IWalletDirtyBalanceHistory): void {
		if (this.isMobXBound) {
			set(this, '_dirtyBalanceHistory', instance);
		} else {
			this._dirtyBalanceHistory = instance;
		}
	}

	/* #endregion ---- Protected ------------------------------------------------------------------------------------- */

	/* #region ---- Derived / Memoized ------------------------------------------------------------------------------- */

	/**
	 * ACTION
	 * Sets the server balance data using the raw balance list from the server and sync the local balances.
	 * Debounces to only run {X}ms after the last time it was called - prevents multiple back-to-back updates.
	 */
	public setServerBalances = debounce(this.updateFromServerBalanceList, 500, { leading: false, trailing: true });

	/* #endregion ---- Derived / Memoized ---------------------------------------------------------------------------- */

	/* #region ---- Static ------------------------------------------------------------------------------------------- */

	/**
	 * STATIC
	 * @returns The default options data used by this class.
	 */
	public static defaultOptions(): IWalletManagerOpts {
		return {
			...ManagerBase.defaultOptions(),
			activeCurrencyCode: null,
			playerId: null,
		};
	}

	/**
	 * @returns The default currency code to use when none is active or specified.
	 */
	public static getCurrencySymbol(currencyCode: string): string {
		return getCurrencySymbolForCode(currencyCode || WalletManager.defaultCurrencyCode());
	}

	/**
	 * @returns The default currency code to use when none is active or specified.
	 */
	public static defaultCurrencyCode(): string {
		return DEFAULT_CURRENCY_CODE;
	}

	/**
	 * @returns The default currency exponent to use when none is active or specified.
	 */
	public static defaultCurrencyExponent(): number {
		return DEFAULT_CURRENCY_EXPONENT;
	}

	/**
	 *
	 * @returns The default currency symbol for the default currency code.
	 */
	public static defaultCurrencySymbol(): string {
		return WalletManager.getCurrencySymbol(WalletManager.defaultCurrencyCode());
	}

	/**
	 * @returns The default balance data to use when none is available.
	 */
	public static getDefaultBalanceData(
		opts?: Maybe<{ currencyCode?: Maybe<string>; currencyExponent?: Maybe<number> }>
	): IWalletLocalBalanceDataEntry {
		const emptyData = newEmptyBalanceDataExt({
			currencyCode: opts?.currencyCode,
			currencyExponent: opts?.currencyExponent,
		});

		return {
			...emptyData,
			lastUpdatedTs: 0,
			isDirty: false,
		};
	}

	/* #endregion ---- Static ---------------------------------------------------------------------------------------- */

	/* #region ---- Debug -------------------------------------------------------------------------------------------- */

	/**
	 * Overrides the base class property.
	 * @returns The label to use when debugging.
	 */
	protected override get debugClassLabel(): string {
		return WalletManager.debugClassLabel();
	}

	/**
	 * STATIC
	 * @returns Label assigned to this class namespace.
	 */
	protected static debugClassLabel(): string {
		return `RpcLib.Manager.WalletManager`;
	}

	/**
	 * Called when the debug enabled state is changed.
	 *
	 * - Extends the base class method.
	 */
	protected override onSetDebugEnabled(isEnabled: boolean) {
		super.onSetDebugEnabled(isEnabled);

		if (this._localBalances) {
			this._localBalances.isDebugEnabled = isEnabled;
		}
		if (this._serverBalances) {
			this._serverBalances.isDebugEnabled = isEnabled;
		}
	}

	/* #endregion ---- Debug ----------------------------------------------------------------------------------------- */
}

// ---- Exports -------------------------------------------------------------------------------------------------------

export { WalletManager as default };
export { WalletManager };
