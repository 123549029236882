import delay from 'lodash/delay';
import { BufMessage, BufServiceType, ConnectCallOptions, ConnectPromiseClient } from '../../../bufbuild';
import { DebugBase } from '../../../common';
import { isObjectEqual } from '../../../helpers';
import { getSessionProviderAuthInstance } from '../AuthSession/utility';
import {
	IStreamClientListeners,
	IStreamController,
	IStreamEndStatusData,
	IStreamMethodOptions,
	StreamCancelCode,
	StreamClient,
} from '../Clients/StreamClient';
import { IStreamClientOpts, IStreamError } from '../Clients/types';
import { authenticateRequest, IAuthSessionProvider, SessionProvider } from '../helpers/authenticate';
import { RestartOnErrorType, StreamStatus } from './constants';
import {
	AutoRestartProps,
	IStream,
	IStreamMethodOptRequirements,
	IStreamOpts,
	IStreamRequestProps,
	IStreamState,
	IStreamSubscriber,
	IValidateStartResult,
	StreamSubscribers,
} from './types';

abstract class Stream<
		RpcServiceType extends BufServiceType,
		StreamReplyType extends BufMessage<StreamReplyType>,
		StreamRequestProps extends IStreamRequestProps
	>
	extends DebugBase
	implements IStream<RpcServiceType>
{
	/* #region ---- Properties --------------------------------------------------------------------------------------- */

	/**
	 * Currently assigned options.
	 */
	protected override _options: IStreamOpts = Stream.defaultOptions();

	/**
	 * The stream's service URL.
	 */
	protected _url: string = '';

	/**
	 * Stream client instance used to connect to the server via RPC.
	 */
	protected _streamClient!: StreamClient<RpcServiceType>;

	/**
	 * Session provider used for authenticating requests.
	 */
	protected _session: Nullable<SessionProvider>;

	/**
	 * The current data stream controller object.
	 */
	protected _streamController: Nullable<IStreamController> = null;

	/**
	 * Stream subscribers. These are things that are listening to activity on this stream.
	 */
	protected _subscribers: StreamSubscribers<StreamReplyType> = [];

	/**
	 * Current stream state.
	 */
	protected _currentState: IStreamState = {
		// TRUE when this stream is enabled. Disabled streams cannot start/restart or auto-restart.
		isEnabled: true,
		// TRUE when this stream is currently live and connected.
		isActive: false,
		// Stream status
		status: StreamStatus.NOT_STARTED,
		// Message associated with the status
		message: 'Stream not started',
	};

	/**
	 * Auto restart properties
	 */
	protected _autoRestart: AutoRestartProps = Stream.autoRestartDefaults();

	/**
	 * Listener for the stream client callbacks. This allows this stream to listen to RPC stream activity.
	 */
	protected _streamClientListener!: IStreamClientListeners<StreamReplyType>;

	/**
	 * Holds the last request properties that were used when starting/restarting the stream.
	 */
	protected _lastRequestProps: Nullable<StreamRequestProps> = null;

	/* #endregion ---- Properties ------------------------------------------------------------------------------------ */

	/* #region ---- CONSTRUCTOR -------------------------------------------------------------------------------------- */

	constructor(url: string, opts?: Maybe<IStreamOpts>) {
		super();

		this._url = url;

		if (this._url === '') {
			this.throwError('Stream url must be specified');
		}

		opts && this.setOptions(opts);

		this.initStreamClientListener();
		this.initStreamClient();
	}

	/* #endregion ---- CONSTRUCTOR ----------------------------------------------------------------------------------- */

	/* #region ---- Abstract Methods --------------------------------------------------------------------------------- */

	/**
	 * Must override. Will attempt to start the stream.
	 *
	 * @returns TRUE if the attempt to start the stream succeeded. Note that this does NOT mean the stream actually
	 *          started and received data - you must subscribe to the stream to know that.
	 */
	public abstract start(requestProps?: Maybe<StreamRequestProps>): boolean;

	/**
	 * Must override. Implements the minimum logic needed to create and start a new stream.
	 *
	 * @returns TRUE if successfully able to create and start the stream.
	 */
	protected abstract runStream(props?: Maybe<StreamRequestProps>): boolean;

	/**
	 * Must override. Implements logic used to determine if we are allowed to start/restart the stream.
	 *
	 * @returns The result of the validation. This also includes the processed request props to apply.
	 */
	protected abstract validateStart(requestProps?: Maybe<StreamRequestProps>): IValidateStartResult<StreamRequestProps>;

	/* #endregion ---- Abstract Methods ------------------------------------------------------------------------------ */

	/* #region ---- Public ------------------------------------------------------------------------------------------- */

	/**
	 * Sets/initializes the class options.
	 *
	 * - Overrides the parent class method.
	 */
	public override setOptions(opts: IStreamOpts) {
		const { newOpts, origOpts } = this.resolveOptions(opts);
		this._options = newOpts;
		this.onSetOptions(newOpts, origOpts);
	}

	/**
	 * Gets/sets the session instance.
	 */
	public get session(): Nullable<SessionProvider> {
		return this._session;
	}
	public set session(session: Nullable<SessionProvider>) {
		this._session = session;
	}

	/**
	 * Gets/sets the session token.
	 */
	public get token(): string {
		const session = this.getSessionInstance();
		return session?.token ?? '';
	}
	public set token(value: string) {
		const session = this.getSessionInstance();
		session != null && (session.token = value);
	}

	/**
	 * Gets/sets the service url.
	 */
	public get url(): string {
		return this._url;
	}
	public set url(val: string) {
		this.setUrl(val);
	}
	protected setUrl(val: string) {
		if (val === '') {
			this.throwError('Stream url must not be empty');
		}
		if (val == this._url) {
			return;
		}

		const prev = this._url;
		this._url = val;

		this.onUrlChanged(this._url, prev);
	}

	/**
	 * Gets the RPC client instance.
	 */
	public get rpcClient(): ConnectPromiseClient<RpcServiceType> {
		if (this._streamClient == null) {
			this.warn('Stream client is not assigned');
		}

		return this._streamClient.rpcClient;
	}

	/**
	 * @returns The number of subscribers to this stream.
	 */
	public get subscriberCount(): number {
		return this._subscribers.length;
	}

	/**
	 * @returns TRUE when there are subscribers to this stream.
	 */
	public get hasSubscribers(): boolean {
		return this.subscriberCount > 0;
	}

	/**
	 * @returns The current state of the stream
	 */
	public get currentState(): IStreamState {
		return this._currentState;
	}

	/**
	 * @returns TRUE when the stream currently active and running.
	 */
	public get isActive() {
		return this._currentState.isActive ?? false;
	}

	/**
	 * Gets the method keys supported by the RPC client.
	 */
	public get rpcClientMethods() {
		return this._streamClient?.rpcStreamMethods;
	}

	/**
	 * TRUE when this stream is enabled. Disabled streams cannot start/restart or auto-restart.
	 */
	public get isEnabled(): boolean {
		return this._currentState.isEnabled ?? false;
	}
	public set isEnabled(val: boolean) {
		this._currentState.isEnabled = val;
	}

	/**
	 * Attempts to manually restart this stream using the specified props.
	 *
	 * @returns TRUE if the attempt to restart the stream succeeded. Note that this does NOT mean the stream
	 *          actually re-connected and received data.
	 */
	public restart(props?: Maybe<StreamRequestProps>): boolean {
		// Disable restarts if the stream is disabled
		if (!this.isEnabled) {
			return false;
		}

		// Validate the restart request
		const { isValid, requestProps } = this.validateStart(props);
		if (!isValid) {
			return false;
		}

		// Manual restarts will clear any auto-restart cycle that might be active
		const ar = this._autoRestart;
		ar.isRestarting && this.clearAutoRestarts();

		// Stop the stream if it is currently running
		this.stopStream({ cancelCode: StreamCancelCode.RESTART, status: StreamStatus.RESTARTING });

		// Attempt to run the stream (sub-class specific logic)
		if (!this.runStream(requestProps)) {
			return false;
		}

		this.afterStart(requestProps);

		return true;
	}

	/**
	 * Attempts to manually stop this stream.
	 *
	 * @returns TRUE if the attempt to stop succeeded.
	 */
	public stop(cancelCode?: StreamCancelCode): boolean {
		// Manual stops will clear any auto-restart cycle that might be active
		const ar = this._autoRestart;
		ar.isRestarting && this.clearAutoRestarts();

		// Stop the stream if it is currently running
		return this.stopStream({ cancelCode });
	}

	/**
	 * Subscribes to this stream. By default will throw an error if the specified subscriber already exists.
	 *
	 * @param  subscriber  Set of callbacks to execute when the relevant stream client actions occur (eg. onData, onError, etc)
	 */
	public subscribe(subscriber: IStreamSubscriber<StreamReplyType>, opts?: { throwError?: boolean }) {
		const { throwError = true } = opts || {};

		const index = this.findSubscriptionIndex(subscriber);
		if (index > -1) {
			if (throwError) {
				this.throwError('Specified listener already subscribed', 'subscribe');
			}

			return;
		}

		this._subscribers.push(subscriber);
	}

	/**
	 * Unsubscribes (removes) the specified subscriber from this stream.
	 *
	 * @param  subscriber  The subscriber we want to remove.
	 */
	public unsubscribe(subscriber: IStreamSubscriber<StreamReplyType>): boolean {
		const index = this.findSubscriptionIndex(subscriber);
		if (index === -1) {
			return false;
		}

		this._subscribers.splice(index, 1);

		return true;
	}

	/**
	 * Unsubscribes (removes) all the subscribers from this stream.
	 */
	public unsubscribeAll(): void {
		this._subscribers = [];
	}

	/* #endregion ---- Public ---------------------------------------------------------------------------------------- */

	/* #region ---- Protected ---------------------------------------------------------------------------------------- */

	/**
	 * Make an stream call via the client attached to this service. Returns the raw RPC data in the `data` prop.
	 *
	 * @returns See `StreamClient.stream` method
	 */
	protected stream = <ReqT extends BufMessage<ReqT>>(
		rpcMethod: typeof this.rpcClientMethods,
		request: ReqT,
		opts?: IStreamMethodOptions
	): IStreamController => {
		if (this._streamClient == null) {
			this.throwError('Stream client is not assigned');
		}

		if (this.isActive && this._streamController != null) {
			this.warn('Stream is already running. Ignoring this attempt.', 'stream');
			return this._streamController;
		}

		const resolvedOpts = this.resolveStreamOptRequirements(opts);

		return this._streamClient.stream<ReqT, StreamReplyType>(
			rpcMethod,
			request,
			this._streamClientListener,
			resolvedOpts
		);
	};

	/**
	 * Called when a stream successfully starts (ie. after it first connects and receives data).
	 */
	protected onStreamStart(data?: Maybe<StreamReplyType>) {
		this.setState({
			isActive: true,
			status: StreamStatus.RUNNING,
			message: 'Stream started',
		});

		this._subscribers.forEach((subscriber) => {
			subscriber.onStart && subscriber.onStart(data);
		});

		// Clear any active auto-restart cycle
		const ar = this._autoRestart;
		ar.isRestarting && this.clearAutoRestarts();
	}

	/**
	 * Called when the stream is terminated (by either client or server).
	 */
	protected onStreamEnd(endStatusData: IStreamEndStatusData<StreamReplyType>) {
		const { isError, isCancelled } = endStatusData;

		let statusCode: StreamStatus = StreamStatus.ENDED;
		if (isError) {
			statusCode = StreamStatus.ENDED_ERROR;
		} else if (isCancelled) {
			statusCode = StreamStatus.ENDED_CANCELLED;
		}

		const reason = endStatusData.summary || 'Unknown reason';

		// this.warn('Stream ended', 'onStreamEnd', { reason, statusCode, endStatusData, isError, isCancelled });

		// Clear the stream controller if it is still active
		this._streamController = null;

		this.setState({
			isActive: false,
			status: statusCode,
			message: reason,
		});

		this._subscribers.forEach((subscriber) => {
			subscriber.onEnd && subscriber.onEnd(reason, endStatusData);
		});

		const ar = this._autoRestart;
		if (isCancelled) {
			ar.isRestarting && this.clearAutoRestarts();
			return;
		}

		this.tryAutoRestart();
	}

	/**
	 * Called when the stream successfully received valid data from the server.
	 */
	protected onStreamData(data: StreamReplyType) {
		this._subscribers.forEach((subscriber) => {
			subscriber.onData && subscriber.onData(data);
		});
	}

	/**
	 * Called when an error has occured with the stream.
	 */
	protected onStreamError(err: IStreamError) {
		this._subscribers.forEach((subscriber) => {
			subscriber.onError && subscriber.onError(err);
		});
	}

	/**
	 * Intended to be called after each start/restart.
	 *
	 * @param requestProps
	 */
	protected afterStart(requestProps?: Maybe<StreamRequestProps>) {
		if (requestProps) {
			this._lastRequestProps = requestProps ?? null;
		}
	}

	/**
	 * Stop the stream if it is currently running.
	 *
	 * @returns TRUE if the stop attempt was successfully issued.
	 */
	protected stopStream(
		opts?: Maybe<{ cancelCode?: Maybe<StreamCancelCode>; status?: Maybe<StreamStatus>; force?: Maybe<boolean> }>
	): boolean {
		const force = opts?.force ?? false;
		if (!this.isActive && !force) {
			return false;
		}

		const cancelCode = opts?.cancelCode ?? (force ? StreamCancelCode.FORCE_STOP : StreamCancelCode.STOP);

		// Cancel the stream and then clear the controller (prior to the `onStreamEnd` call)
		this._streamController?.cancel(cancelCode);
		this._streamController = null;

		this.setState({
			isActive: false,
			status: opts?.status ?? StreamStatus.STOPPED,
			message: 'Stream stopped',
		});

		return true;
	}

	/**
	 * @returns The set of listeners/handlers to use for stream client activity.
	 */
	protected newStreamClientListeners(): IStreamClientListeners<StreamReplyType> {
		return {
			// Called when a stream successfully starts (ie. after it first received valid data).
			onStart: (data?: Maybe<StreamReplyType>) => {
				this.onStreamStart(data);
			},

			// Called when the server sends data to the client.
			onData: (data: StreamReplyType) => {
				this.onStreamData(data);
			},

			// Called when the server sends response headers to the client.
			onHeaders: (_headers: Headers) => {
				// this.info('Headers received:', 'onHeaders', headers);
			},

			// Called when the server sends response trailers to the client.
			onTrailers: (_trailers: Headers) => {
				// this.info('Trailers received:', 'onTrailers', trailers);
			},

			// Called once the stream has ended (ie. was cancelled, errored, or just ended normally)
			onEnd: (endStatusData: IStreamEndStatusData<StreamReplyType>) => {
				this.onStreamEnd(endStatusData);
			},

			// Called when an error has occured with the stream.
			onError: (err: IStreamError) => {
				this.onStreamError(err);
			},
		};
	}

	/**
	 * The actual type of the class extending this.
	 */
	protected get className(): string {
		return this.constructor.name ?? this.debugClassLabel;
	}

	/**
	 * @param  subscriber  Set of callbacks to execute when the relevant stream client actions occur (eg. onData, onError, etc)
	 * @returns TRUE if this subscription exists.
	 */
	protected hasSubscription(subscriber: IStreamSubscriber<StreamReplyType>): boolean {
		return this.findSubscriptionIndex(subscriber) > -1;
	}

	/**
	 * @param subscriber
	 * @returns The index of the specified subscriber in the the current subscriptions. Returns -1 if not found.
	 */
	protected findSubscriptionIndex(subscriber: IStreamSubscriber<StreamReplyType>): number {
		if (this._subscribers.length === 0) {
			return -1;
		}

		return this._subscribers.findIndex((s: IStreamSubscriber<StreamReplyType>) => Object.is(s, subscriber));
	}

	/**
	 * Stops and fully clears any active auto-restart cycle.
	 */
	protected clearAutoRestarts() {
		const ar = this._autoRestart;
		if (!ar.enabled) {
			return;
		}

		this.stopAutoRestarts();
		ar.applyDelayMs = ar.delayMs;
		ar.attemptsThisCycle = 0;
	}

	/**
	 * Stop any existing auto-restart cycle timer.
	 */
	protected stopAutoRestarts() {
		const ar = this._autoRestart;
		if (!ar.enabled) {
			return;
		}

		if (ar.restartTimerId > 0) {
			globalThis.clearTimeout(ar.restartTimerId);
			ar.restartTimerId = 0;
		}

		ar.isRestarting = false;
	}

	/**
	 * @returns TRUE if the specified stream error is a recoverable error.
	 */
	protected isRecoverableStreamError(err: Maybe<IStreamError>): boolean {
		const rawError = err?.rawError?.js ?? { code: -1 };
		const code: number = rawError.code;
		const recoverableErrorCodes: number[] = [10050012];

		return recoverableErrorCodes.includes(code);
	}

	/**
	 * Attempt an auto-restart cycle.
	 *
	 * TODO: Review this and fix the `ar.isRestarting` issue that cropped up
	 *
	 * @param    isError  If this is TRUE we are attempting due to an error.
	 * @returns
	 */
	protected tryAutoRestart(err?: Maybe<IStreamError>) {
		const debugMethod = 'tryAutoRestart';

		const ar = this._autoRestart;
		const isError = err != null && (err?.code != null || err?.message != null);
		const isRecoverableError = isError && this.isRecoverableStreamError(err);

		// this.info('1', debugMethod, {
		// 	isError,
		// 	isRecoverableError,
		// 	isEnabled: this.isEnabled,
		// 	autoRestart: { ...ar },
		// });

		//=============================================================
		// Do not attempt to auto-restart if:
		//   1) The stream is disabled
		//   2) If the auto-restart flag is disabled
		//   3) We are already attempting to restart
		//=============================================================
		if (!this.isEnabled || !ar.enabled || ar.isRestarting /* || !ar.allowAttempts */) {
			return;
		}

		// this.info('2', debugMethod, { ar: { ...ar } });

		// Do not attempt to auto-restart if the attempt is due to an error and we are NOT allowed to restart on ANY errors
		if (isError && ar.restartOnError === RestartOnErrorType.OFF) {
			this.info('Will not attempt restart after error due to `restartOnError` set to OFF', debugMethod, {
				ar: { ...ar },
			});
			return;
		}
		// Do not attempt to auto-restart if the attempt is due to a non-recoverable error and we are only allowed to
		// restart on recoverable errors
		else if (isError && !isRecoverableError && ar.restartOnError === RestartOnErrorType.RECOVERABLE) {
			this.info(
				'Will not attempt restart after non-recoverable error due to `restartOnError` set to RECOVERABLE only',
				debugMethod,
				{
					ar: { ...ar },
				}
			);
			return;
		}

		// this.info('3 - Going to attempt!');

		const stopAttempts = () => {
			this.clearAutoRestarts();
		};

		const attemptRestart = () => {
			const ar = this._autoRestart;

			// this.info('1', 'attemptRestart', { ar: { ...ar } });

			const noMoreAttempts = ar.attemptsThisCycle >= ar.maxAttempts;

			if (!ar.enabled || this.isActive || noMoreAttempts /* || !ar.allowAttempts */) {
				stopAttempts();
				return;
			}

			// Attempt the restart
			ar.attemptsThisCycle++;
			const isValid = this.doAutoRestart();

			// this.info('2', 'attemptRestart', { ar: { ...ar }, isValid, isActive: this.isActive });

			if (!isValid || this.isActive) {
				stopAttempts();
				return;
			}

			// Check active status again after a short delay
			delay(() => {
				this.isActive && stopAttempts();
			}, 200);

			if (ar.applyExponentialBackoff) {
				ar.applyDelayMs = (1 + ar.attemptsThisCycle) * ar.delayMs;
			}

			delay(attemptRestart, ar.applyDelayMs);
		};

		// ar.allowAttempts = true;
		ar.isRestarting = true;
		ar.restartTimerId = delay(attemptRestart, ar.applyDelayMs);

		// this.info('4', debugMethod, { ar: { ...ar } });
	}

	/**
	 * Runs an auto-restart. A auto-restart is distinct from a manual restart.
	 *
	 * @returns TRUE if the auto-restart attempt was allowed.
	 */
	protected doAutoRestart(): boolean {
		// Disable auto-restarts if the stream is disabled
		if (!this.isEnabled) {
			return false;
		}

		const ar = this._autoRestart;
		if (!ar.enabled /* || !ar.allowAttempts */) {
			return false;
		}

		const { isValid, requestProps } = this.validateStart();

		// this.info('1', 'doAutoRestart:', { ar: { ...ar }, isValid, requestProps, lastRequestProps: this.lastRequestProps });

		if (!isValid) {
			return false;
		}

		this.stopStream({ cancelCode: StreamCancelCode.RESTART, status: StreamStatus.RESTARTING, force: true });
		this.runStream(requestProps);

		ar.totalTimesRestarted++;

		return true;
	}

	/**
	 * Creates a new `StreamClient` instance.
	 */
	protected newStreamClient(
		rpcService: RpcServiceType,
		baseUrl: string,
		clientOpts?: Maybe<IStreamClientOpts>
	): StreamClient<RpcServiceType> {
		const streamClientOpts: IStreamClientOpts = {
			isDebugEnabled: this._options.isDebugEnabled,
			debugLabel: this._options.debugLabel,
			...clientOpts,
		};

		return new StreamClient<RpcServiceType>(rpcService, baseUrl, streamClientOpts);
	}

	/**
	 * Creates a request options object with authentication headers.
	 */
	protected resolveStreamOptRequirements(opts?: Maybe<IStreamMethodOptions>): IStreamMethodOptRequirements {
		opts = opts ?? {};

		const headers: Headers = authenticateRequest(this.token, { headers: opts.callOpts?.headers });

		const callOpts: ConnectCallOptions = {
			...opts,
			headers: headers,
		};

		const abortController = opts?.abortController ?? new AbortController();
		callOpts.signal = abortController.signal;

		return {
			callOpts,
			abortController,
			expectInitialPayload: opts.expectInitialPayload ?? undefined,
		};
	}

	/**
	 * Sets the current stream state.
	 */
	protected setState(props: IStreamState) {
		this._currentState = { ...this._currentState, ...props };
	}

	/**
	 * Resolves the options being passed in and returns the original and new options.
	 *
	 * - Overrides the parent class method.
	 */
	protected override resolveOptions(opts?: Maybe<IStreamOpts>) {
		const origOpts: IStreamOpts = {
			...Stream.defaultOptions(),
			...this._options,
		};

		const newOpts: IStreamOpts = {
			...origOpts,
			...(opts ?? {}),
		};

		return { origOpts, newOpts };
	}

	/**
	 * Called after new options are set.
	 *
	 * - Extends the base class method.
	 */
	protected override onSetOptions(newOpts: IStreamOpts, origOpts: IStreamOpts) {
		super.onSetOptions(newOpts, origOpts);

		if (newOpts.session !== this.session) {
			this._session = newOpts.session ?? null;
		}

		if (newOpts.isEnabled != null && newOpts.isEnabled !== this.isEnabled) {
			this.isEnabled = newOpts.isEnabled;
		}

		if (
			newOpts.clientOpts != null &&
			(origOpts == null || !isObjectEqual(newOpts.clientOpts, origOpts.clientOpts ?? {}))
		) {
			this.onClientOptsChanged(newOpts.clientOpts);
		}

		const optAr = newOpts?.autoRestart ?? null;
		const ar = this._autoRestart;

		if (optAr != null && optAr !== ar) {
			ar.enabled = optAr?.enabled ?? ar.enabled;
			ar.delayMs = optAr?.delayMs ?? ar.delayMs;
			ar.maxAttempts = optAr?.maxAttempts ?? ar.maxAttempts;
			ar.restartOnError = optAr?.restartOnError ?? ar.restartOnError;
			ar.applyExponentialBackoff = optAr?.applyExponentialBackoff ?? ar.applyExponentialBackoff;
		}
	}

	/**
	 * Called after new client opts are set.
	 */
	protected onClientOptsChanged(_newVal: IStreamClientOpts, _prevVal?: IStreamClientOpts) {
		this.initStreamClient();
	}

	/**
	 * Called after the service url is changed.
	 */
	protected onUrlChanged(_newVal: string, _prevVal?: string) {
		this.initStreamClient();
	}

	/**
	 * Initializes the stream client instance.
	 */
	protected initStreamClient() {
		if (this.isEnabled && this.isActive) {
			this.stop();
		}

		this._streamClient = this.createStreamClient(this._url, this._options.clientOpts);
	}

	/**
	 * Initializes the stream client listener instance.
	 */
	protected initStreamClientListener() {
		this._streamClientListener = this.newStreamClientListeners();
	}

	/**
	 * Creates the correct stream client instance. Must be implemented by the derived class.
	 */
	protected abstract createStreamClient(
		url: string,
		clientOpts?: Maybe<IStreamClientOpts>
	): StreamClient<RpcServiceType>;

	/**
	 * @returns The session instance associated with this stream - if currently set, otherwise NULL.
	 */
	protected getSessionInstance(): Nullable<IAuthSessionProvider> {
		return this._session == null ? null : getSessionProviderAuthInstance(this._session);
	}

	/* #region ---- Protected ---------------------------------------------------------------------------------------- */

	/* #region ---- Static ------------------------------------------------------------------------------------------- */

	/**
	 * @returns Defaults used for Stream class options.
	 */
	public static defaultOptions = (): IStreamOpts => ({
		...DebugBase.defaultOptions(),
		session: null,
		clientOpts: null,
		autoRestart: null,
		isEnabled: true,
	});

	/**
	 * @returns Defaults used for auto-restart props.
	 */
	public static autoRestartDefaults = (): AutoRestartProps => ({
		// Input options
		enabled: true,
		delayMs: 1500,
		maxAttempts: 10,
		restartOnError: RestartOnErrorType.ALL,
		applyExponentialBackoff: true,

		// State props
		isRestarting: false,
		restartTimerId: 0,
		totalTimesRestarted: 0,
		attemptsThisCycle: 0,
		applyDelayMs: 1000,
		// allowAttempts: true, // <-- TODO: Review & fix this
	});

	/* #endregion ---- Static ---------------------------------------------------------------------------------------- */

	/* #region ---- Debug -------------------------------------------------------------------------------------------- */

	/**
	 * Overrides the parent class property.
	 *
	 * @returns The label to use when debugging.
	 */
	protected override get debugClassLabel(): string {
		return Stream.debugClassLabel();
	}

	/**
	 * STATIC
	 * @returns Label assigned to this class namespace.
	 */
	protected static debugClassLabel(): string {
		return `RpcLib.Core.Stream`;
	}

	/* #endregion ---- Debug ----------------------------------------------------------------------------------------- */
}

// ---- Exports -------------------------------------------------------------------------------------------------------

export { Stream as default };
export { Stream };
