/**********************************************************************************************************************
 * Initialization of the various RPC streams via factory methods.
 *********************************************************************************************************************/
import { IServiceOpts } from '../../../client/core';
import { DealerService, DeviceService, GameDevService, GameService, UserService } from '../../../client/service';
import { filterNullUndefined } from '../../../helpers';
import { IServiceFactoryNewProps, ServiceConstructor } from './types';
import { newService } from './utility';

/**
 * Factory class for creating RPC services.
 */
class ServiceFactory {
	/**
	 * @returns A new instance of the specified service type.
	 */
	public static newService<T>(Service: ServiceConstructor<T>, props: IServiceFactoryNewProps) {
		const { url, session } = props;

		// Note that `isDebugEnabled` and `debugLabel` are not currently implemented by the Service classes
		const isDebugEnabled = props?.isDebugEnabled ?? null;
		const debugLabel = props?.debugLabel || null;
		const serviceOpts: IServiceOpts = { ...props?.serviceOpts, ...filterNullUndefined({ isDebugEnabled, debugLabel }) };

		return newService<T>(Service, url, session, serviceOpts);
	}

	/**
	 * @returns A new instance of the `GameDevService`.
	 */
	public static newGameDevService(opts: IServiceFactoryNewProps): GameDevService {
		return ServiceFactory.newService<GameDevService>(GameDevService, opts);
	}

	/**
	 * @returns A new instance of the `GameService`.
	 */
	public static newGameService(opts: IServiceFactoryNewProps): GameService {
		return ServiceFactory.newService<GameService>(GameService, opts);
	}

	/**
	 * @returns A new instance of the `DealerService`.
	 */
	public static newDealerService(opts: IServiceFactoryNewProps): DealerService {
		return ServiceFactory.newService<DealerService>(DealerService, opts);
	}

	/**
	 * @returns A new instance of the `StudioDealingDeviceService`.
	 */
	public static newDeviceService(opts: IServiceFactoryNewProps): DeviceService {
		return ServiceFactory.newService<DeviceService>(DeviceService, opts);
	}

	/**
	 * @returns A new instance of the `UserService`.
	 */
	public static newUserService(opts: IServiceFactoryNewProps): UserService {
		return ServiceFactory.newService<UserService>(UserService, opts);
	}
}

// ---- Exports -------------------------------------------------------------------------------------------------------

export { ServiceFactory as default };
export { ServiceFactory };
