import { IStreamClientOpts, IStreamController } from '../../core';
import { IStreamOpts, IValidateStartResult, Stream, StreamStatus } from '../../core/Stream';
import { GetSelfReply, GetSelfRequest } from '../../rpc';
import { GameClient } from '../../rpc/clients/game';
import { makeStreamPropHashId } from '../utility';
import { IUserDataStream, UserDataStreamRequestProps } from './types';

type RequestProps = UserDataStreamRequestProps;
type ValidateStartResult = IValidateStartResult<RequestProps>;

class UserDataStream extends Stream<typeof GameClient, GetSelfReply, RequestProps> implements IUserDataStream {
	/* #region ---- CONSTRUCTOR -------------------------------------------------------------------------------------- */

	constructor(url: string, opts?: Maybe<IStreamOpts>) {
		super(url, opts);
	}

	/* #endregion ---- CONSTRUCTOR ----------------------------------------------------------------------------------- */

	/* #region ---- Public ------------------------------------------------------------------------------------------- */

	/**
	 * Attempts to start this stream.
	 *
	 * @returns TRUE if the attempt to start the stream succeeded. Note that this does NOT mean the stream actually
	 *          connected and received data - you must subscribe to the stream to know that.
	 */
	public start(props?: Maybe<RequestProps>): boolean {
		const { isValid, requestProps } = this.validateStart(props);

		if (!isValid) {
			return false;
		}

		const propHashId = makeStreamPropHashId(
			requestProps?.includeBalances ?? false,
			requestProps?.includeArtifacts ?? false,
			requestProps?.includeRewards ?? false,
			requestProps?.includeRecentPlays ?? false
		);

		const lastPropHashId = makeStreamPropHashId(
			this._lastRequestProps?.includeBalances ?? false,
			this._lastRequestProps?.includeArtifacts ?? false,
			this._lastRequestProps?.includeRewards ?? false,
			this._lastRequestProps?.includeRecentPlays ?? false
		);

		// Stream is already running
		if (this.isActive) {
			// Different props specified? Treat it as a restart
			if (propHashId !== lastPropHashId) {
				return this.restart(requestProps);
			}

			this.warn(
				'Attempted to start an already running stream using the same props. Use `restart` if this is intended',
				'start',
				{ ...requestProps }
			);

			return false;
		}

		// Manual starts will clear any auto-restart cycle that might be active
		this.clearAutoRestarts();
		this.currentState.status = StreamStatus.STARTING;

		const didRun = this.runStream(requestProps);
		if (didRun) {
			this.afterStart(requestProps);
		}

		return didRun;
	}

	/* #endregion ---- Public ---------------------------------------------------------------------------------------- */

	/* #region ---- Protected ---------------------------------------------------------------------------------------- */

	/**
	 * Determines if we are allowed to start/restart this stream.
	 *
	 * @returns The result of the validation. This also includes the processed request props to apply.
	 */
	protected validateStart(requestProps?: Maybe<RequestProps>): ValidateStartResult {
		requestProps = requestProps ?? this._lastRequestProps ?? null;

		return { isValid: true, requestProps };
	}

	/**
	 * Creates and starts a new table data stream.
	 *
	 * @returns TRUE if successfully able to create and start the stream.
	 */
	protected runStream(requestProps?: Maybe<RequestProps>): boolean {
		this._streamController = this.newStream(requestProps);

		return true;
	}

	/**
	 * Starts a new user data stream with the specified request props.
	 */
	protected newStream(requestProps?: Maybe<RequestProps>): IStreamController {
		const request = new GetSelfRequest({
			includeArtifacts: requestProps?.includeArtifacts ?? true,
			includeBalances: requestProps?.includeBalances ?? true,
			includeRewards: requestProps?.includeRewards ?? true,
			includeRecentPlays: requestProps?.includeRecentPlays ?? true,
		});

		return this.stream<typeof request>('streamSelf', request);
	}

	/**
	 * @returns The stream client instance used by this stream.
	 */
	protected createStreamClient(url: string, clientOpts?: Maybe<IStreamClientOpts>) {
		return this.newStreamClient(GameClient, url, clientOpts);
	}

	/* #endregion ---- Protected ------------------------------------------------------------------------------------- */

	/* #region ---- Debug -------------------------------------------------------------------------------------------- */

	/**
	 * Overrides the parent class property.
	 *
	 * @returns The label to use when debugging.
	 */
	protected override get debugClassLabel(): string {
		return UserDataStream.debugClassLabel();
	}

	/**
	 * STATIC
	 * @returns Label assigned to this class namespace.
	 */
	protected static debugClassLabel(): string {
		return `RpcLib.Stream.UserDataStream`;
	}

	/* #endregion ---- Debug ----------------------------------------------------------------------------------------- */
}

// ---- Exports -------------------------------------------------------------------------------------------------------

export { UserDataStream as default };
export { UserDataStream };
