import delay from 'lodash/delay';
import { getNowTs } from '../dateTime';
import { IPreciseIntervalCallbackProps, IPreciseIntervalOpts, PreciseInterval } from './types';

const setPreciseInterval = (
	intervalMs: number,
	onInterval: (opts: IPreciseIntervalCallbackProps) => void,
	opts?: IPreciseIntervalOpts
): PreciseInterval => {
	const { autoStart = true, aligned = false, immediate = false } = opts ?? {};

	let isRunning = false;
	let nextAtTs: number = 0;
	let startedTs: number = 0;
	let lastTickTs: number = 0;
	let tickNum: number = 0;
	let timerId: number = 0;

	const clear = (): void => {
		isRunning = false;
		timerId = 0;
		nextAtTs = 0;
		startedTs = 0;
		tickNum = 0;
	};

	const onTick = () => {
		if (!isRunning) {
			return;
		}

		const nowTs = getNowTs();
		if (startedTs === 0) {
			startedTs = nowTs;
		}

		const scheduledForTs = nextAtTs;

		const elapsedSinceStartMs = Math.floor(nowTs - startedTs);
		const elapsedSinceLastTickMs = lastTickTs === 0 ? 0 : Math.floor(nowTs - lastTickTs);

		nextAtTs += intervalMs;
		lastTickTs = nowTs;

		timerId = delay(onTick, nextAtTs - nowTs);

		tickNum++;
		onInterval &&
			onInterval({
				num: tickNum,
				elapsedStartMs: elapsedSinceStartMs,
				elapsedMs: elapsedSinceLastTickMs,
				nowTs,
				scheduledForTs,
				nextAtTs,
			});
	};

	const start = (): boolean => {
		if (isRunning) {
			return false;
		}

		nextAtTs = getNowTs();

		if (aligned) {
			nextAtTs += intervalMs - (getNowTs() % intervalMs);
		}

		if (!immediate) {
			nextAtTs += intervalMs;
		}

		isRunning = true;

		timerId = delay(onTick, nextAtTs - getNowTs());

		return true;
	};

	const stop = (): void => {
		if (!isRunning) {
			return;
		}

		if (timerId !== 0) {
			globalThis.clearTimeout(timerId);
			timerId = 0;
		}

		isRunning = false;
		nextAtTs = 0;
	};

	const reset = (): void => {
		stop();
		clear();
	};

	autoStart && start();

	return { start, stop, reset, opts: { autoStart, aligned, immediate } };
};

// ---- Exports -------------------------------------------------------------------------------------------------------

export { setPreciseInterval };
