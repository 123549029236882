import { set } from 'mobx';
import { DebugBase } from '../../../../common';
import { bindWalletServerBalancesMobX } from './mobx';
import {
	IMethodWalletServerBalancesMapRawDataOpts,
	IWalletServerBalanceDataEntry,
	IWalletServerBalances,
	IWalletServerBalancesData,
	IWalletServerBalancesOpts,
	RawServerBalanceList,
} from './types';
import { copyData, defaultData, generateRawListHashId, newDataFromRawList } from './utility';

/**
 * Stores and manages a collection of server balance data used for wallet manager balance syncing.
 */
class WalletServerBalances extends DebugBase implements IWalletServerBalances {
	/* #region ---- Properties --------------------------------------------------------------------------------------- */

	/**
	 * Currently assigned options.
	 */
	protected override _options: IWalletServerBalancesOpts = WalletServerBalances.defaultOptions();

	/**
	 * Encapsulated data.
	 */
	protected _data: IWalletServerBalancesData;

	/**
	 * Flag indicating if this class instance is currently bound to MobX as an observable.
	 */
	protected _isMobXBound: boolean = false;

	/* #endregion ---- Properties -------------------------------------------------------------------------------------*/

	/* #region ---- CONSTRUCTOR ---------------------------------------------------------------------------------------*/

	constructor(opts?: Maybe<IWalletServerBalancesOpts>) {
		super();

		this._data = opts?.data ?? defaultData();
		opts != null && this.setOptions(opts);

		// Bind to MobX as an observable.
		if (this._options.useMobX) {
			bindWalletServerBalancesMobX(this);
		}
	}

	/* #endregion ---- CONSTRUCTOR ----------------------------------------------------------------------------------- */

	/* #region ---- Public ------------------------------------------------------------------------------------------- */

	/**
	 * Sets/initializes the class options.
	 *
	 * - Overrides the parent class method.
	 */
	public setOptions(opts: IWalletServerBalancesOpts) {
		const { newOpts } = this.resolveOptions(opts);

		if (this._isMobXBound) {
			set(this, '_options', newOpts);
		} else {
			this._options = newOpts;
		}

		this.onSetOptions(newOpts);
	}

	/**
	 * Whether or not the balance data collection is empty.
	 */
	public get isEmpty(): boolean {
		return this.size === 0;
	}

	/**
	 * Size of the data collection.
	 */
	public get size(): number {
		return this.lookup.size;
	}

	/**
	 * Lookup of server balance data keyed by currency code.
	 */
	public get lookup(): Map<string, IWalletServerBalanceDataEntry> {
		return this._data.lookup;
	}

	/**
	 * Flat array of server balance data.
	 */
	public get list(): IWalletServerBalanceDataEntry[] {
		return Array.from(this.lookup.values());
	}

	/**
	 * The last time (unix timestamp) that the data was updated.
	 */
	public get lastUpdatedTs(): number {
		return this._data.lastUpdatedTs;
	}
	public set lastUpdatedTs(val: number) {
		this.setLastUpdatedTs(val);
	}
	// Actionable setter method for MobX.
	protected setLastUpdatedTs(val: number) {
		if (val === this._data.lastUpdatedTs) {
			return;
		}

		this._data.lastUpdatedTs = val;
	}

	/**
	 * The full data encapsulated by this class instance.
	 */
	public get data(): IWalletServerBalancesData {
		return this._data;
	}
	public set data(val: IWalletServerBalancesData) {
		this.setData(val);
	}
	// Actionable setter method for MobX.
	protected setData(val: IWalletServerBalancesData) {
		if (val === this._data) {
			return;
		}
		if (this.isMobXBound) {
			set(this, '_data', val);
		} else {
			this._data = val;
		}
	}

	/**
	 * The player ID context for this server balance data.
	 */
	public get playerId(): string {
		return this._data.playerId;
	}
	public set playerId(val: string) {
		this._data.playerId = val;
	}
	// Actionable setter method for MobX.
	protected setPlayerId(val: string) {
		if (val === this._data.playerId) {
			return;
		}

		this._data.playerId = val;
	}

	/**
	 * Gets/sets whether or not this class instance is currently bound to MobX as an observable.
	 */
	public get isMobXBound(): boolean {
		return this._isMobXBound;
	}
	public set isMobXBound(value: boolean) {
		this._isMobXBound = value;
	}

	/**
	 * ACTION
	 * Populates this instance using the raw active wagers list.
	 */
	public populate(
		rawList: RawServerBalanceList,
		opts?: Maybe<IMethodWalletServerBalancesMapRawDataOpts>
	): IWalletServerBalancesData {
		const data = newDataFromRawList(rawList, opts);
		this.setData(data);

		return this._data;
	}

	/**
	 * Resets the server balances back to the initial/clear values.
	 */
	public clear() {
		const data = defaultData({ updatedTs: 0 });
		this.setData(data);
	}

	/**
	 * @returns A new clone of this class instance with copied data.
	 */
	public clone(opts?: Maybe<{ updatedTs?: Maybe<number> }>): WalletServerBalances {
		const newData = copyData(this._data, { updatedTs: opts?.updatedTs });

		return new WalletServerBalances({ ...this._options, data: newData });
	}

	/**
	 * Extends the parent class method.
	 *
	 * @returns A JSON export of the current data.
	 */
	public override toJson(extended?: Maybe<boolean>): PlainObject {
		extended = extended ?? false;

		const data = copyData(this._data);

		const toJs = (val: unknown) => DebugBase.toJs(val, { extended, useMobXToJs: this.isMobXBound });

		const result: PlainObject = {
			lastUpdatedTs: data.lastUpdatedTs,
			size: data.lookup.size,
			isEmpty: data.lookup.size === 0,
			lookup: toJs(data.lookup),
			list: toJs(Array.from(data.lookup.values())),
		};

		if (extended) {
			result.extended = {
				isMobXBound: this.isMobXBound,
				options: toJs({ ...this._options }),
				rawData: toJs(data),

				// Debug info from base class
				_Debug: { ...super.toJson(extended) },
			};
		}

		return result;
	}

	/* #endregion ---- Public ---------------------------------------------------------------------------------------- */

	/* #region ---- Protected ---------------------------------------------------------------------------------------- */

	/**
	 * Overrides the parent class method.
	 * Resolves the options being passed in and returns the original and new options.
	 */
	protected override resolveOptions(opts?: Maybe<IWalletServerBalancesOpts>) {
		const origOpts: IWalletServerBalancesOpts = {
			...WalletServerBalances.defaultOptions(),
			...this._options,
		};

		const newOpts: IWalletServerBalancesOpts = {
			...origOpts,
			...(opts ?? {}),
		};

		return { origOpts, newOpts };
	}

	/**
	 * Called after new options are set.
	 *
	 * - Extends the parent class method
	 */
	protected override onSetOptions(newOpts: IWalletServerBalancesOpts) {
		super.onSetOptions(newOpts);

		if (newOpts.data != null && (!this._data || newOpts.data.uniqId !== this._data.uniqId)) {
			this.setData(newOpts.data);
		}

		if (!this._data) {
			return;
		}
		if (newOpts.updatedTs != null && newOpts.updatedTs !== this._data.lastUpdatedTs) {
			this.setLastUpdatedTs(newOpts.updatedTs);
		}
		if (newOpts.playerId != null && newOpts.playerId !== this._data.playerId) {
			this.setPlayerId(newOpts.playerId);
		}
	}

	/**
	 * @returns TRUE if the specified raw list is the same as the current one - in terms of the meaningful data.
	 */
	public isRawDataSame(wagerList: RawServerBalanceList): boolean {
		const origHashId = this._data.raw?.hashId ?? '';
		const newHashId = generateRawListHashId(wagerList);

		return newHashId === origHashId;
	}

	/* #endregion ---- Protected ------------------------------------------------------------------------------------- */

	/* #region ---- Static ------------------------------------------------------------------------------------------- */

	/**
	 * STATIC
	 * @returns A clone of the specified class instance.
	 */
	public static cloneInstance(
		from: WalletServerBalances,
		opts?: Maybe<{ updatedTs?: Maybe<number> }>
	): WalletServerBalances {
		return from.clone(opts);
	}

	/**
	 * STATIC
	 * @returns A new instance of this class populated with the specified raw list.
	 */
	public static newFromRawList(
		rawList: RawServerBalanceList,
		opts?: Maybe<{
			playerId?: Maybe<string>;
			newInstanceOpts?: Maybe<Omit<IWalletServerBalancesOpts, 'data' | 'playerId' | 'updatedTs'>>;
			populateOpts?: Maybe<Omit<IMethodWalletServerBalancesMapRawDataOpts, 'playerId'>>;
		}>
	): WalletServerBalances {
		const populateOpts: IMethodWalletServerBalancesMapRawDataOpts = {
			...opts?.populateOpts,
			playerId: opts?.playerId,
		};

		const data = newDataFromRawList(rawList, populateOpts);

		return new WalletServerBalances({
			...opts?.newInstanceOpts,
			data,
			updatedTs: data.lastUpdatedTs,
			playerId: data.playerId,
		});
	}

	/**
	 * STATIC
	 * Maps the raw data list sent by the server and resolves to the relevant encapsulated data.
	 */
	public static newDataFromRawList(
		rawList: RawServerBalanceList,
		opts?: Maybe<IMethodWalletServerBalancesMapRawDataOpts>
	): IWalletServerBalancesData {
		return newDataFromRawList(rawList, opts);
	}

	/**
	 * STATIC
	 * @returns The default options data used by this class.
	 */
	public static defaultOptions(): IWalletServerBalancesOpts {
		return {
			...DebugBase.defaultOptions(),
			data: null,
			updatedTs: null,
			playerId: null,
			useMobX: true,
		};
	}

	/* #endregion ---- Static ---------------------------------------------------------------------------------------- */

	/* #region ---- Debug -------------------------------------------------------------------------------------------- */

	/**
	 * @returns The label to use when debugging.
	 */
	protected get debugClassLabel(): string {
		return WalletServerBalances.debugClassLabel();
	}

	/**
	 * STATIC
	 * @returns Label assigned to this class namespace.
	 */
	protected static debugClassLabel(): string {
		return `RpcLib.DataObject.WalletServerBalances`;
	}

	/* #endregion ---- Debug ----------------------------------------------------------------------------------------- */
}

// ---- Exports -------------------------------------------------------------------------------------------------------

export { WalletServerBalances as default };
export { WalletServerBalances };
