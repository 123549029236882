import { set } from 'mobx';
import { DebugBase } from '../../../../common';
import { filterNullUndefined } from '../../../../helpers';
import { bindPlayWagerDefinitionsMobX } from './mobx';
import {
	IMethodPlayWagerDefinitionsMapRawDataOpts,
	IMethodPlayWagerDefinitionsRemapDataOpts,
	IPlayWagerDefinitionsData,
	IPlayWagerDefinitionsDataEntry,
	IPlayWagerDefinitionsFilterToOpts,
	IPlayWagerDefinitionsOpts,
	PlayWagerDefinitionsDataList,
	PlayWagerDefinitionsDataLookup,
	RawPlayWagerDefinitionList,
} from './types';
import { copyData, defaultData, generateRawListHashId, getWagerNames, newDataFromRawList, remapData } from './utility';

/**
 * Represents a collection of available wagers with extended data.
 */
class PlayWagerDefinitions extends DebugBase {
	/* #region ---- Properties --------------------------------------------------------------------------------------- */

	/**
	 * Currently assigned options.
	 */
	protected override _options: IPlayWagerDefinitionsOpts = PlayWagerDefinitions.defaultOptions();

	/**
	 * Encapsulated data.
	 */
	protected _data: IPlayWagerDefinitionsData;

	/**
	 * Flag indicating if this class instance is currently bound to MobX as an observable.
	 */
	protected _isMobXBound: boolean = false;

	/* #endregion ---- Properties -------------------------------------------------------------------------------------*/

	/* #region ---- CONSTRUCTOR ---------------------------------------------------------------------------------------*/

	constructor(opts?: Maybe<IPlayWagerDefinitionsOpts>) {
		super();

		this._data = opts?.data ?? defaultData();
		opts != null && this.setOptions(opts);

		// Bind to MobX as an observable.
		if (this._options.useMobX) {
			bindPlayWagerDefinitionsMobX(this);
		}
	}

	/* #endregion ---- CONSTRUCTOR ----------------------------------------------------------------------------------- */

	/* #region ---- Public ------------------------------------------------------------------------------------------- */

	/**
	 * Sets/initializes the class options.
	 *
	 * - Overrides the parent class method.
	 */
	public override setOptions(opts: IPlayWagerDefinitionsOpts) {
		const { newOpts, origOpts } = this.resolveOptions(opts);

		if (this._isMobXBound) {
			set(this, '_options', newOpts);
		} else {
			this._options = newOpts;
		}

		this.onSetOptions(newOpts, origOpts);
	}

	/**
	 * Whether or not the data collection is empty.
	 */
	public get isEmpty(): boolean {
		return this.size === 0;
	}

	/**
	 * Size of the data collection.
	 */
	public get size(): number {
		return this.lookup.size;
	}

	/**
	 * Lookup of wager definitions data keyed by wager ID.
	 */
	public get lookup(): PlayWagerDefinitionsDataLookup {
		return this._data.lookup;
	}

	/**
	 * Array of wager definitions data.
	 */
	public get list(): PlayWagerDefinitionsDataList {
		return Array.from(this.lookup.values());
	}

	/**
	 * @returns All defined unique keys (ie. `wagerId`) in the data lookup.
	 */
	public get keys(): string[] {
		return Array.from(this.lookup.keys());
	}

	/**
	 * The unique hash ID for the data collection.
	 */
	public get dataHashId(): string {
		return this._data?.hashId ?? '';
	}

	/**
	 * The last time (unix timestamp) that the data was updated.
	 */
	public get lastUpdatedTs(): number {
		return this._data.lastUpdatedTs;
	}
	public set lastUpdatedTs(val: number) {
		this.setLastUpdatedTs(val);
	}
	// Actionable setter method for MobX.
	protected setLastUpdatedTs(val: number) {
		if (val === this._data.lastUpdatedTs) {
			return;
		}
		this._data.lastUpdatedTs = val;
	}

	/**
	 * The full data encapsulated by this class instance.
	 */
	public get data(): IPlayWagerDefinitionsData {
		return this._data;
	}
	public set data(val: IPlayWagerDefinitionsData) {
		this.setData(val);
	}
	// Sets the encapsulated class data. Actionable setter method for MobX.
	protected setData(val: IPlayWagerDefinitionsData) {
		if (val === this._data) {
			return;
		}
		if (this.isMobXBound) {
			set(this, '_data', val);
		} else {
			this._data = val;
		}
	}

	/**
	 * Get/set the play ID the instance is associated with.
	 */
	public get playId(): string {
		return this._data.playId;
	}
	public set playId(val: string) {
		this.setPlayId(val);
	}
	// Actionable setter method for MobX.
	protected setPlayId(val: string) {
		if (val === this._data.playId) {
			return;
		}

		const prev = this._data.playId;
		this._data.playId = val;
		this.onPlayIdChanged(this._data.playId, prev);
	}

	/**
	 * Gets/sets whether or not this class instance is currently bound to MobX as an observable.
	 */
	public get isMobXBound(): boolean {
		return this._isMobXBound;
	}
	public set isMobXBound(value: boolean) {
		this._isMobXBound = value;
	}

	/**
	 * @returns All wager IDs in the data collection.
	 */
	public get wagerIds(): string[] {
		return this.keys;
	}

	/**
	 * @returns All wager names in the data collection.
	 */
	public get wagerNames(): string[] {
		return getWagerNames(this.lookup);
	}

	/**
	 * @returns The data entry for the specified wager ID, or, NULL if not present
	 */
	public get(wagerId: string, opts?: Maybe<{ copy?: Maybe<boolean> }>): Nullable<IPlayWagerDefinitionsDataEntry> {
		const entry = this.lookup.get(wagerId) ?? null;

		if (opts?.copy === true && entry != null) {
			return { ...entry };
		}

		return entry;
	}

	/**
	 * @returns The data entry for the specified wager name, or, NULL if not present
	 */
	public getByName(
		wagerName: string,
		opts?: Maybe<{ copy?: Maybe<boolean> }>
	): Nullable<IPlayWagerDefinitionsDataEntry> {
		const wagerNameLc = wagerName.toLocaleLowerCase();

		const wagerId = (wagerName != '' ? this._data.nameToIdLookup.get(wagerNameLc) : null) ?? null;
		const entry = (wagerId != null ? this.lookup.get(wagerId) : null) ?? null;

		if (opts?.copy === true && entry != null) {
			return { ...entry };
		}

		return entry;
	}

	/**
	 * @returns TRUE if a data entry for specified wager ID exists.
	 */
	public has(wagerId: string): boolean {
		return this.lookup.has(wagerId);
	}

	/**
	 * @returns TRUE if a data entry for specified wager name exists.
	 */
	public hasName(wagerId: string): boolean {
		return this._data.nameToIdLookup.has(wagerId);
	}

	/**
	 * Resets the class instance data back to the initial/clear values.
	 */
	public clear() {
		const data = defaultData({ updatedTs: Date.now(), playId: this.playId });
		this.setData(data);
	}

	/**
	 * @returns A new clone of this class instance with copied data.
	 */
	public clone(
		opts?: Maybe<{ updatedTs?: Maybe<number>; instanceOpts?: Maybe<IPlayWagerDefinitionsOpts> }>
	): PlayWagerDefinitions {
		const newData = copyData(this._data, { updatedTs: opts?.updatedTs });

		const instanceOpts: IPlayWagerDefinitionsOpts = {
			...this._options,
			...filterNullUndefined(opts?.instanceOpts ?? {}),
			data: newData,
			updatedTs: newData.lastUpdatedTs,
		};

		return new PlayWagerDefinitions(instanceOpts);
	}

	/**
	 * Overrides the parent class method.
	 *
	 * @returns A JSON export of the current data.
	 */
	public override toJson(extended?: Maybe<boolean>): PlainObject {
		extended = extended ?? false;

		const toJs = (val: unknown) => DebugBase.toJs(val, { extended, useMobXToJs: this.isMobXBound });

		const data = copyData(this._data);
		const list = Array.from(data.lookup.values());
		const keys = Array.from(data.lookup.keys());

		const result: PlainObject = {
			lastUpdatedTs: data.lastUpdatedTs,
			size: data.lookup.size,
			isEmpty: data.lookup.size === 0,
			playId: data.playId,
			list: toJs(list),
			lookup: toJs(data.lookup),
			wagerIds: toJs(keys),
			wagerNames: toJs(getWagerNames(data.lookup)),
		};

		if (extended) {
			result.extended = {
				isMobXBound: this.isMobXBound,
				options: toJs({ ...this._options }),
				rawData: toJs(data),
				dataHashId: this.dataHashId,

				// Debug info from base class
				_Debug: { ...super.toJson(extended) },
			};
		}

		return result;
	}

	/**
	 * Populates this instance using the raw available wagers list.
	 */
	public populate(
		rawList: RawPlayWagerDefinitionList,
		opts?: Maybe<IMethodPlayWagerDefinitionsMapRawDataOpts>
	): IPlayWagerDefinitionsData {
		const data = newDataFromRawList(rawList, this.playId, opts);
		this.setData(data);

		return this._data;
	}

	/**
	 * Similar to `populate` but remaps the existing raw data using the specified filter props.
	 */
	public filterTo(
		filterProps: IPlayWagerDefinitionsFilterToOpts,
		opts?: IMethodPlayWagerDefinitionsRemapDataOpts
	): IPlayWagerDefinitionsData {
		const data = remapData(this._data, this.playId, filterProps, opts);
		this.setData(data);

		return this._data;
	}

	/**
	 * @returns TRUE if the specified raw list is the same as the current one - in terms of the meaningful data.
	 */
	public isRawDataSame(rawList: RawPlayWagerDefinitionList): boolean {
		rawList = rawList ?? [];

		const origRawList = this._data?.raw?.list ?? [];
		if (0 === rawList.length + origRawList.length) {
			return true;
		}
		if (rawList.length != origRawList.length) {
			return false;
		}

		const origHashId = this._data.raw?.hashId ?? '';
		const newHashId = generateRawListHashId(rawList);

		return newHashId === origHashId;
	}

	/* #endregion ---- Public ---------------------------------------------------------------------------------------- */

	/* #region ---- Protected ---------------------------------------------------------------------------------------- */

	/**
	 * Resolves the options being passed in and returns the original and new options.
	 *
	 * - Overrides the parent class method
	 */
	protected override resolveOptions(opts?: Maybe<IPlayWagerDefinitionsOpts>) {
		const origOpts: IPlayWagerDefinitionsOpts = {
			...PlayWagerDefinitions.defaultOptions(),
			...this._options,
		};

		const newOpts: IPlayWagerDefinitionsOpts = {
			...origOpts,
			...(opts ?? {}),
		};

		return { origOpts, newOpts };
	}

	/**
	 * Called after new options are set.
	 *
	 * - Extends the parent class method
	 */
	protected override onSetOptions(newOpts: IPlayWagerDefinitionsOpts, origOpts?: Maybe<IPlayWagerDefinitionsOpts>) {
		super.onSetOptions(newOpts, origOpts);

		if (newOpts.data != null && (!this._data || newOpts.data.uniqId !== this._data.uniqId)) {
			this.setData(newOpts.data);
		}

		if (!this._data) {
			return;
		}
		if (newOpts.updatedTs != null && newOpts.updatedTs !== this._data.lastUpdatedTs) {
			this.setLastUpdatedTs(newOpts.updatedTs);
		}
		if (newOpts.playId != null && newOpts.playId !== this._data.playId) {
			this.setPlayId(newOpts.playId);
		}
	}

	/**
	 * Called after the play ID changes.
	 */
	protected onPlayIdChanged(_newPlayId: string, prevPlayId: string) {
		if (prevPlayId === '') {
			return;
		}

		// Since the play has changed, we need to clear the current data.
		this.clear();
	}

	/* #endregion ---- Protected ------------------------------------------------------------------------------------- */

	/* #region ---- Static ------------------------------------------------------------------------------------------- */

	/**
	 * STATIC
	 * @returns A clone of the specified class instance.
	 */
	public static cloneInstance(
		from: PlayWagerDefinitions,
		opts?: Maybe<{ updatedTs?: Maybe<number> }>
	): PlayWagerDefinitions {
		return from.clone(opts);
	}

	/**
	 * STATIC
	 * @returns The default options data used by this class.
	 */
	public static defaultOptions(): IPlayWagerDefinitionsOpts {
		return {
			...DebugBase.defaultOptions(),
			data: null,
			updatedTs: null,
			useMobX: true,
			playId: null,
		};
	}

	/**
	 * STATIC
	 * @returns A new instance of this class populated with the specified raw list.
	 */
	public static newFromRawList(
		rawList: RawPlayWagerDefinitionList,
		playId: string,
		opts?: Maybe<{
			newInstanceOpts?: Maybe<Omit<IPlayWagerDefinitionsOpts, 'data' | 'playId'>>;
			populateOpts?: Maybe<IMethodPlayWagerDefinitionsMapRawDataOpts>;
		}>
	): PlayWagerDefinitions {
		const data = newDataFromRawList(rawList, playId, opts?.populateOpts);

		return new PlayWagerDefinitions({ ...opts?.newInstanceOpts, data, updatedTs: data.lastUpdatedTs });
	}

	/* #endregion ---- Static ---------------------------------------------------------------------------------------- */

	/* #region ---- Debug -------------------------------------------------------------------------------------------- */

	/**
	 * Overrides the parent class property.
	 *
	 * @returns The label to use when debugging.
	 */
	protected override get debugClassLabel(): string {
		return PlayWagerDefinitions.debugClassLabel();
	}

	/**
	 * STATIC
	 * @returns Label assigned to this class namespace.
	 */
	protected static debugClassLabel(): string {
		return `RpcLib.DataObject.PlayWagerDefinitions`;
	}

	/* #endregion ---- Debug ----------------------------------------------------------------------------------------- */
}

// ---- Exports -------------------------------------------------------------------------------------------------------

export { PlayWagerDefinitions as default };
export { PlayWagerDefinitions };
