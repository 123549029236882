import omit from 'lodash/omit';
import { set } from 'mobx';
import { EventDispatcherBase } from '../../../../common';
import {
	filterNullUndefined,
	formatCurrency,
	IFormatCurrencyOpts,
	IMethodResolveAmountsOpts,
	IResolvedAmountData,
	isObjectEmpty,
	makeUniqId,
	resolveAmount,
} from '../../../../helpers';
import {
	ActiveWagers,
	IActiveWagerData,
	IActiveWagerDataEntry,
	IActiveWagers,
	IActiveWagersOpts,
	IActiveWagerDataEntry as IPendingWagerDataEntry,
	IActiveWagers as IPendingWagers,
	IActiveWagersOpts as IPendingWagersOpts,
	IMethodActiveWagersMapRawDataOpts as IPopulateWagersOpts,
	IActiveWagerDataEntry as IRejectedWagerDataEntry,
	IActiveWagers as IRejectedWagers,
	IActiveWagersOpts as IRejectedWagersOpts,
	ActiveWagers as PendingWagers,
	ActiveWagers as RejectedWagers,
} from '../../../../helpers/data';
import {
	IMethodGetWagersOpts,
	IMethodGetWagerTotalsOpts,
	IMultiSeatWagers,
	IMultiSeatWagerSet,
	IMultiSeatWagerTotalsSet,
	ISeatWagerSet,
	IWagers,
	IWagersNoNull,
	IWagerTotalData,
} from '../../types';
import {
	fillNewKeyLookup,
	fillNewMultiSeatWagerSet,
	fillNewMultiSeatWagerTotalsSet,
	fillNewSeatLookup,
	getMultiSeatWagersNums,
	newMultiSeatWagers,
	newSeatWagerSet,
	newWagers,
	newWagerTotalData,
	resolveWageringSeats,
} from '../../utility';
import { ServerWageringState } from './constants';
import { bindServerPlayerWagerStateMobX } from './mobx';
import {
	IServerPlayerWagerStateData,
	IServerPlayerWagerStateDataEntry,
	IServerPlayerWagerStateDataRetrievers,
	IServerPlayerWagerStateOpts,
	RawServerPlayerWagerStateData,
	ServerPlayerWagerStateDataList,
	ServerPlayerWagerStateDataLookup,
} from './types.main';
import {
	copyData,
	copyLookup,
	defaultData,
	defaultDataRetrievers,
	extractSeatNumbers,
	extractWagerIds,
	extractWagerNames,
	generateDataLookupHashId,
	generateRawDataHashId,
	mapEntryToWagerData,
} from './utility';

class ServerPlayerWagerState extends EventDispatcherBase {
	/* #region ---- Properties --------------------------------------------------------------------------------------- */

	/**
	 * Currently assigned options.
	 */
	protected override _options: IServerPlayerWagerStateOpts = ServerPlayerWagerState.defaultOptions();

	/**
	 * Encapsulated data.
	 */
	protected _data: IServerPlayerWagerStateData;

	/**
	 * Flag indicating if this class instance is currently bound to MobX as an observable.
	 */
	protected _isMobXBound: boolean = false;

	/**
	 * Collection of active wagers data the server has accepted.
	 */
	protected _activeWagers: IActiveWagers;

	/**
	 * Collection of pending wagers the server is waiting to process.
	 */
	protected _pendingWagers: IPendingWagers;

	/**
	 * Collection of wagers the server has rejected - usually due to an error.
	 */
	protected _rejectedWagers: IRejectedWagers;

	/**
	 * Data retriever functions used by this class.
	 */
	protected _dataRetrievers: IServerPlayerWagerStateDataRetrievers = defaultDataRetrievers();

	/* #endregion ---- Properties -------------------------------------------------------------------------------------*/

	/* #region ---- CONSTRUCTOR ---------------------------------------------------------------------------------------*/

	constructor(dataRetrievers: IServerPlayerWagerStateDataRetrievers, opts?: Maybe<IServerPlayerWagerStateOpts>) {
		super();

		// Set data retrievers used by this class
		this._dataRetrievers = dataRetrievers;

		this._data = opts?.data ?? ServerPlayerWagerState.defaultData();
		opts != null && this.setOptions(opts);

		this.init();

		// Bind to MobX as an observable.
		if (this._options.useMobX) {
			bindServerPlayerWagerStateMobX(this);
		}
	}

	/* #endregion ---- CONSTRUCTOR ----------------------------------------------------------------------------------- */

	/* #region ---- Public ------------------------------------------------------------------------------------------- */

	/**
	 * Sets/initializes the class options.
	 *
	 * - Overrides the parent class method.
	 */
	public override setOptions(opts: IServerPlayerWagerStateOpts) {
		const { newOpts, origOpts } = this.resolveOptions(opts);

		if (this._isMobXBound) {
			set(this, '_options', newOpts);
		} else {
			this._options = newOpts;
		}

		this.onSetOptions(newOpts, origOpts);
	}

	/**
	 * @returns All seat numbers in the data collection.
	 */
	public get seatNumbers(): number[] {
		return extractSeatNumbers(this.lookup);
	}

	/**
	 * @returns All defined wager IDs in the data collection.
	 */
	public get wagerIds(): string[] {
		return extractWagerIds(this.lookup).all;
	}

	/**
	 * @returns All wager names in the data collection.
	 */
	public get wagerNames(): string[] {
		return extractWagerNames(this.lookup).all;
	}

	/**
	 * @returns All wagers in the data collection as a multi-seat wagers object.
	 */
	public get wagers(): IMultiSeatWagerSet {
		return this.getWagers();
	}

	/**
	 * @returns Totals data for all wagers in the data collection as a multi-seat wagers totals object.
	 */
	public get wagerTotals(): IMultiSeatWagerTotalsSet {
		return this.getWagerTotals();
	}

	/**
	 * Whether or not the data collection is empty.
	 */
	public get isEmpty(): boolean {
		return this.size === 0;
	}

	/**
	 * Size of the data collection.
	 */
	public get size(): number {
		return this.lookup.size;
	}

	/**
	 * Lookup of current resolved wagers data keyed by active wager key.
	 */
	public get lookup(): ServerPlayerWagerStateDataLookup {
		return this._data.lookup;
	}

	/**
	 * Array of active wagers data.
	 */
	public get list(): ServerPlayerWagerStateDataList {
		return Array.from(this.lookup.values());
	}

	/**
	 * @returns All defined unique keys (ie. activeWagerKey) in the data collection.
	 */
	public get keys(): string[] {
		return Array.from(this.lookup.keys());
	}

	/**
	 * The unique hash ID for the data collection.
	 */
	public get dataHashId(): string {
		return this._data?.hashId ?? '';
	}

	/**
	 * The full data encapsulated by this class instance.
	 */
	public get data(): IServerPlayerWagerStateData {
		return this._data;
	}
	public set data(val: IServerPlayerWagerStateData) {
		this.setData(val);
	}
	// Actionable setter method for MobX.
	protected setData(val: IServerPlayerWagerStateData, opts?: Maybe<{ regenerateHashId?: Maybe<boolean> }>) {
		if (val === this._data) {
			return;
		}

		const regenerateHashId = opts?.regenerateHashId ?? true;

		if (regenerateHashId) {
			// const origHashId = val.hashId;
			val.hashId = generateDataLookupHashId(val.lookup);
		}

		if (this.isMobXBound) {
			set(this, '_data', val);
		} else {
			this._data = val;
		}
	}

	/**
	 * The last time (unix timestamp) that the data was updated.
	 */
	public get lastUpdatedTs(): number {
		return this._data.lastUpdatedTs;
	}
	public set lastUpdatedTs(val: number) {
		this.setLastUpdatedTs(val);
	}
	// Actionable setter method for MobX.
	protected setLastUpdatedTs(val: number) {
		if (val === this._data.lastUpdatedTs) {
			return;
		}

		this._data.lastUpdatedTs = val;
	}

	/**
	 * Get/set the active play ID.
	 */
	public get playId(): string {
		return this._dataRetrievers.playId();
	}

	/**
	 * Gets/sets whether or not this class instance is currently bound to MobX as an observable.
	 */
	public get isMobXBound(): boolean {
		return this._isMobXBound;
	}
	public set isMobXBound(value: boolean) {
		this._isMobXBound = value;
	}

	/**
	 * @returns The data entry for the specified active wager key, or NULL if not present
	 */
	public get(activeWagerKey: string, opts?: Maybe<{ copy?: Maybe<boolean> }>): Nullable<IActiveWagerDataEntry> {
		const entry = this.lookup.get(activeWagerKey) ?? null;

		if (opts?.copy === true && entry != null) {
			return { ...entry };
		}

		return entry;
	}

	/**
	 * @returns TRUE if a data entry for specified active wager key exists.
	 */
	public has(activeWagerKey: string): boolean {
		return this.lookup.has(activeWagerKey);
	}

	/**
	 * Data retrievers used by this class.
	 */
	public get dataRetrievers(): IServerPlayerWagerStateDataRetrievers {
		return Object.freeze(this._dataRetrievers);
	}

	/**
	 * Active wagers data collection instance.
	 */
	public get activeWagersDataObj(): IActiveWagers {
		return this._activeWagers;
	}

	/**
	 * Pending wagers data collection instance.
	 */
	public get pendingWagersDataObj(): IPendingWagers {
		return this._pendingWagers;
	}

	/**
	 * Pending wagers data collection instance.
	 */
	public get rejectedWagersDataObj(): IRejectedWagers {
		return this._rejectedWagers;
	}

	/**
	 * ACTION
	 * Resets the class data back to the initial/clear values.
	 */
	public clear() {
		const data = defaultData({ updatedTs: 0 });
		this.setData(data);

		this._activeWagers.clear();
		this._activeWagers.playId = this.playId;
		this._pendingWagers.clear();
		this._pendingWagers.playId = this.playId;
		this._rejectedWagers.clear();
		this._rejectedWagers.playId = this.playId;
	}

	/**
	 * ACTION
	 * Populates this instance using the raw server data.
	 */
	public populate(
		rawData: RawServerPlayerWagerStateData,
		opts?: Maybe<{ updatedTs?: Maybe<number> }>
	): IServerPlayerWagerStateData {
		const updatedTs: number = opts?.updatedTs || Date.now();

		// Create and populate new instances of the wager collection classes using the raw data
		const activeWagers = this.newActiveWagersInstance({ updatedTs });
		const pendingWagers = this.newPendingWagersInstance({ updatedTs });
		const rejectedWagers = this.newRejectedWagersInstance({ updatedTs });

		this.populateWagerCollectionInstancesFromRawData(rawData, {
			updatedTs,
			useInstances: { activeWagers, pendingWagers, rejectedWagers },
		});

		const lookup = this.newLookupFromCollections(activeWagers, pendingWagers, rejectedWagers, { updatedTs });

		const rawHashId = generateRawDataHashId(rawData);

		const data = {
			...defaultData({ updatedTs }),
			lookup,
			uniqId: makeUniqId(),
			hashId: generateDataLookupHashId(lookup),
			raw: { data: { ...rawData }, hashId: rawHashId },
		};

		this.setData(data, { regenerateHashId: false });

		this.setActiveWagersInstance(activeWagers);
		this.setPendingWagersInstance(pendingWagers);
		this.setRejectedWagersInstance(rejectedWagers);

		return this._data;
	}

	/**
	 * @returns TRUE if the specified raw data is the same as the current raw data - in terms of the meaningful data.
	 */
	public isRawDataSame(rawData: RawServerPlayerWagerStateData): boolean {
		const origRawData = this._data.raw?.data ?? null;

		if ((rawData != null && origRawData == null) || (rawData == null && origRawData != null)) {
			return false;
		}

		const origLen =
			(origRawData?.activeWagers?.length ?? 0) +
			(origRawData?.pendingWagers?.length ?? 0) +
			(origRawData?.rejectedWagers?.length ?? 0);

		const newLen =
			(rawData?.activeWagers?.length ?? 0) +
			(rawData?.pendingWagers?.length ?? 0) +
			(rawData?.rejectedWagers?.length ?? 0);

		if (origLen !== newLen) {
			return false;
		}

		const origHashId = this._data.raw?.hashId ?? '';
		const newHashId = generateRawDataHashId(rawData);

		return newHashId === origHashId;
	}

	/**
	 * ACTION
	 * Sets the collection instances for active, pending and rejected wagers.
	 */
	public injectWagerCollections(opts: {
		activeWagers?: Maybe<IActiveWagers>;
		pendingWagers?: Maybe<IPendingWagers>;
		rejectedWagers?: Maybe<IRejectedWagers>;
		doNotUpdateData?: Maybe<boolean>;
	}) {
		const doNotUpdateData = opts?.doNotUpdateData ?? false;

		// If no data is specified, then we can safely ignore this call
		if (opts.activeWagers == null && opts.pendingWagers == null && opts.rejectedWagers == null) {
			return;
		}

		if (opts.activeWagers != null) {
			this.setActiveWagersInstance(opts.activeWagers);
		}
		if (opts.pendingWagers != null) {
			this.setPendingWagersInstance(opts.pendingWagers);
		}
		if (opts.rejectedWagers != null) {
			this.setRejectedWagersInstance(opts.rejectedWagers);
		}

		if (doNotUpdateData) {
			return;
		}

		const updatedTs = Date.now();

		// Generate a new lookup from the new data collections
		const lookup = this.newLookupFromCollections(this._activeWagers, this._pendingWagers, this._rejectedWagers, {
			updatedTs,
		});

		// If the new lookup is the same as the current lookup, then do not update the data
		const hashId = generateDataLookupHashId(lookup);
		if (hashId === this._data.hashId) {
			return;
		}

		const data = {
			...this._data,
			lookup,
			hashId,
			uniqId: makeUniqId(),
			lastUpdatedTs: updatedTs,
		};

		this.setData(data, { regenerateHashId: false });

		return this._data;
	}

	/**
	 * @returns A new clone of this class instance with copied data.
	 */
	public clone(
		opts?: Maybe<{ updatedTs?: Maybe<number>; instanceOpts?: Maybe<Omit<IServerPlayerWagerStateOpts, 'data'>> }>
	): ServerPlayerWagerState {
		const newData = ServerPlayerWagerState.copyData(this._data, { updatedTs: opts?.updatedTs });

		const instanceOpts: IServerPlayerWagerStateOpts = {
			...{
				...this._options,

				// These will be set via data or instanceOpts
				data: null,
				updatedTs: null,
			},

			data: newData,
			updatedTs: newData.lastUpdatedTs,
			...filterNullUndefined(omit(opts?.instanceOpts ?? {}, ['data', 'updatedTs'])),
		};

		const instance = new ServerPlayerWagerState(this._dataRetrievers, instanceOpts);
		instance.injectWagerCollections({
			activeWagers: this._activeWagers,
			pendingWagers: this._pendingWagers,
			rejectedWagers: this._rejectedWagers,
			doNotUpdateData: true,
		});

		return instance;
	}

	/**
	 * Extends the parent class method.
	 *
	 * @returns A JSON export of the current data.
	 */
	public override toJson(extended?: Maybe<boolean>): PlainObject {
		extended = extended ?? false;

		const toJs = (val: unknown) => EventDispatcherBase.toJs(val, { extended, useMobXToJs: this.isMobXBound });

		const base = super.toJson(extended);
		const data = copyData(this._data);
		const list = Array.from(data.lookup.values());
		const keys = Array.from(data.lookup.keys());

		const result: PlainObject = {
			...base,
			lastUpdatedTs: data.lastUpdatedTs,
			size: data.lookup.size,
			isEmpty: data.lookup.size === 0,
			list: toJs(list),
			lookup: toJs(data.lookup),
			activeWagerKeys: toJs(keys),
			seatNumbers: toJs(this.seatNumbers),
			wagerNames: toJs(this.wagerNames),
			wagerIds: toJs(this.wagerIds),
			wagers: toJs(this.wagers),
			wagerTotals: toJs(this.wagerTotals),
		};

		if (extended) {
			result.extended = {
				...((base.extended ?? {}) as PlainObject),
				isMobXBound: this.isMobXBound,
				options: toJs({ ...this._options }),
				activeWagers: toJs(this._activeWagers),
				pendingWagers: toJs(this._pendingWagers),
				rejectedWagers: toJs(this._rejectedWagers),
			};
		}

		return result;
	}

	/**
	 * Gets all/filtered wager data - across all or some seats in the data.
	 *
	 * - Will only process available seats - and then filter than further by various filter values.
	 * - When `filterSeatNumbers` is specified an entry for every seat number specified will be returned. Seats that are
	 *   invalid or have no matching wagers will return as value NULL.
	 * - When `filterSeatNumbers` is NOT specified an entry for every available seat number will be returned. Seats that
	 *   have no matching wagers will return as value NULL.
	 */
	// TODO: Review this for correct amount resolution
	public getWagers(opts?: Maybe<IMethodGetWagersOpts>): IMultiSeatWagerSet {
		const filterSeatNumbers = opts?.filterSeatNumbers ?? [];
		const filterWagerNames = opts?.filterWagerNames ?? [];
		const filterWagerIds = opts?.filterWagerIds ?? [];
		const filterContextIds = opts?.filterContextIds ?? [];

		let result: IMultiSeatWagerSet;

		// Get all seat numbers available in the current data collection
		const availableSeatNumbers = this.seatNumbers;

		// Determine what seat numbers to process and what the result should be
		let processSeatNumbers: number[];

		if (filterSeatNumbers.length > 0) {
			result = fillNewMultiSeatWagerSet(filterSeatNumbers, null);

			const { matchedSeats } = resolveWageringSeats(availableSeatNumbers, filterSeatNumbers);
			processSeatNumbers = matchedSeats;
		} else {
			result = fillNewMultiSeatWagerSet(availableSeatNumbers, null);
			processSeatNumbers = availableSeatNumbers;
		}

		// Early exit if there are no seat numbers to process
		const list = this.list.slice();
		if (list.length === 0) {
			return result;
		}

		const processSeats: Map<number, boolean> = fillNewSeatLookup<boolean>(processSeatNumbers, true);
		const processWagerNames: Map<string, boolean> = fillNewKeyLookup<boolean>(filterWagerNames, true);
		const processWagerIds: Map<string, boolean> = fillNewKeyLookup<boolean>(filterWagerIds, true);
		const processContextIds: Map<string, boolean> = fillNewKeyLookup<boolean>(filterContextIds, true);

		const multipleSeatWagers: IMultiSeatWagers = result.seatWagers ?? newMultiSeatWagers();

		let wagerCount = 0;

		const currencyCode = opts?.formatCurrencyOpts?.currencyCode ?? null;
		const formatCurrencyOpts = filterNullUndefined({ ...opts?.formatCurrencyOpts, currencyCode: null });
		const resolveAmountsOpts: IMethodResolveAmountsOpts = {
			...this.getResolveAmountsOpts(),
			...filterNullUndefined({ ...formatCurrencyOpts, currencyCode }),
		};

		list.forEach((entry: IActiveWagerDataEntry) => {
			const seatNumber = entry.seatNumber;
			const wagerName = entry.name;
			const wagerId = entry.wagerId;
			const contextId = entry.contextId;

			if (seatNumber < 1 || wagerName.length === 0) {
				return; // Next entry
			}
			if (processSeats.size > 0 && !processSeats.has(seatNumber)) {
				return; // Next entry
			}
			if (processWagerNames.size > 0 && !processWagerNames.has(wagerName)) {
				return; // Next entry
			}
			if (processWagerIds.size > 0 && !processWagerIds.has(wagerId)) {
				return; // Next entry
			}
			if (processWagerIds.size > 0 && !processWagerIds.has(wagerId)) {
				return; // Next entry
			}
			if (processContextIds.size > 0 && !processContextIds.has(contextId)) {
				return; // Next entry
			}

			const seatWagerSet: ISeatWagerSet = multipleSeatWagers[seatNumber] ?? newSeatWagerSet({ seatNumber });
			const seatWagers: IWagers = seatWagerSet.wagers ?? newWagers();

			const wager = mapEntryToWagerData(entry, { resolveAmountsOpts });
			seatWagers[wagerId] = wager;

			seatWagerSet.wagers = seatWagers;
			seatWagerSet.wagerCount++;

			multipleSeatWagers[seatNumber] = seatWagerSet;
			wagerCount++;
		});

		result.seatWagers = multipleSeatWagers;
		result.wagerCount = wagerCount;
		result.seatNumbers = getMultiSeatWagersNums(multipleSeatWagers);

		return result;
	}

	/**
	 * @returns A set of multiple-seat wager data - optionally filtered by seat number(s), wager name(s) or wager ID(s).
	 */
	// TODO: Review this for correct amount resolution
	public getWagerTotals(opts?: Maybe<IMethodGetWagerTotalsOpts>): IMultiSeatWagerTotalsSet {
		const formatCurrencyOpts: IFormatCurrencyOpts = { ...opts?.formatCurrencyOpts };

		// Get all wagers matching the specified filters
		const wagerSet: IMultiSeatWagerSet = this.getWagers(opts);
		const seatNumbers = wagerSet.seatNumbers;

		const result: IMultiSeatWagerTotalsSet = fillNewMultiSeatWagerTotalsSet(seatNumbers, null);
		result.seatNumbers = seatNumbers;

		// If we have no wagers, return an empty totals set
		if (wagerSet.wagerCount === 0 || isObjectEmpty(wagerSet.seatWagers)) {
			// this.warn('No wager data extracted matching specified filters.', 'getWagerTotals', { ...opts });
			return result;
		}

		const multiSeatWagers: IMultiSeatWagers = wagerSet.seatWagers ?? {};
		const sumTotals: IWagerTotalData = newWagerTotalData(null, formatCurrencyOpts);

		for (const seatNumber of seatNumbers) {
			const seatWagerSet = multiSeatWagers[seatNumber] ?? null;
			const seatWagers = filterNullUndefined(seatWagerSet?.wagers ?? {}) as IWagersNoNull;

			// If there are no wagers for this seat, skip it - thus `result.seatTotals` will contain NULL for this seat
			if (isObjectEmpty(seatWagers)) {
				continue; // Next seat
			}

			const seatTotals: IWagerTotalData = result.seatTotals[seatNumber] ?? newWagerTotalData(null, formatCurrencyOpts);

			for (const wagerKey in seatWagers) {
				const seatWager = seatWagers[wagerKey];
				seatTotals.wagerCount += 1;
				seatTotals.amount += seatWager.amount;
				seatTotals.amountReal += seatWager.amountReal;
			}

			seatTotals.amountMoney = formatCurrency(seatTotals.amountReal, formatCurrencyOpts);
			result.seatTotals[seatNumber] = seatTotals;

			if (seatTotals.wagerCount > 0) {
				sumTotals.wagerCount += seatTotals.wagerCount;
				sumTotals.amount += seatTotals.amount;
				sumTotals.amountReal += seatTotals.amountReal;
			}
		}

		sumTotals.amountMoney = formatCurrency(sumTotals.amountReal, formatCurrencyOpts);
		result.sumTotals = sumTotals;

		return result;
	}

	/* #endregion ---- Public ---------------------------------------------------------------------------------------- */

	/* #region ---- Protected ---------------------------------------------------------------------------------------- */

	/**
	 * Resolves the options being passed in and returns the original and new options.
	 *
	 * - Overrides the parent class method
	 */
	protected override resolveOptions(opts?: Maybe<IServerPlayerWagerStateOpts>) {
		const origOpts: IServerPlayerWagerStateOpts = {
			...ServerPlayerWagerState.defaultOptions(),
			...this._options,
		};

		const newOpts: IServerPlayerWagerStateOpts = {
			...origOpts,
			...(opts ?? {}),
		};

		return { origOpts, newOpts };
	}

	/**
	 * Called after new options are set.
	 *
	 * - Extends the parent class method
	 */
	protected override onSetOptions(newOpts: IServerPlayerWagerStateOpts, origOpts?: Maybe<IServerPlayerWagerStateOpts>) {
		super.onSetOptions(newOpts, origOpts);
	}

	/**
	 * ACTION
	 * Initializes the class properties.
	 */
	protected init() {
		this.initWagerCollectionInstances();
	}

	/**
	 * ACTION
	 * Initializes the wager collection instances.
	 */
	protected initWagerCollectionInstances() {
		const isDebugEnabled = this.isDebugEnabled;
		const playId = this.playId;

		// Active wagers
		const activeWagers = this.newActiveWagersInstance({ isDebugEnabled, playId });
		this.setActiveWagersInstance(activeWagers);

		// Pending wagers
		const pendingWagers = this.newPendingWagersInstance({ isDebugEnabled, playId });
		this.setPendingWagersInstance(pendingWagers);

		// Rejected wagers
		const rejectedWagers = this.newRejectedWagersInstance({ isDebugEnabled, playId });
		this.setRejectedWagersInstance(rejectedWagers);
	}

	/**
	 * @returns A new instance of the active wagers data collection.
	 */
	protected newActiveWagersInstance(opts?: Maybe<IActiveWagersOpts>): IActiveWagers {
		const isDebugEnabled = this.isDebugEnabled;
		const playId = this.playId;

		return new ActiveWagers({ isDebugEnabled, playId, ...filterNullUndefined(opts ?? {}) });
	}

	/**
	 * @returns A new instance of the pending wagers data collection.
	 */
	protected newPendingWagersInstance(opts?: Maybe<IPendingWagersOpts>): IPendingWagers {
		const isDebugEnabled = this.isDebugEnabled;
		const playId = this.playId;

		return new PendingWagers({ isDebugEnabled, playId, ...filterNullUndefined(opts ?? {}) });
	}

	/**
	 * @returns A new instance of the rejected wagers data collection.
	 */
	protected newRejectedWagersInstance(opts?: Maybe<IRejectedWagersOpts>): IRejectedWagers {
		const isDebugEnabled = this.isDebugEnabled;
		const playId = this.playId;

		return new RejectedWagers({ isDebugEnabled, playId, ...filterNullUndefined(opts ?? {}) });
	}

	/**
	 * @returns The opts used when populating the active/pending/rejected wagers data.
	 */
	protected getPopulateOpts(opts?: Maybe<{ updatedTs?: Maybe<number> }>): IPopulateWagersOpts {
		const resolveAmountsOpts = this._dataRetrievers.resolveAmountsOpts ?? null;

		return {
			updatedTs: opts?.updatedTs ?? Date.now(),
			availableWagersList: this._dataRetrievers.availableWagersList(),
			wagerDefinitionsList: this._dataRetrievers.wagerDefinitionsList(),
			playSeatAssignmentsList: this._dataRetrievers.playSeatAssignmentsList(),
			extendDataOpts: resolveAmountsOpts != null ? resolveAmountsOpts() : null,
		};
	}

	/**
	 * ACTION
	 * Populates the active wagers data using the specified raw data.
	 */
	protected populateActiveWagersFromRawData(
		rawData: RawServerPlayerWagerStateData,
		populateOpts?: Maybe<IPopulateWagersOpts>,
		useInstance?: Maybe<IActiveWagers>
	) {
		const rawList = (rawData.activeWagers ?? []) as IActiveWagerData[];
		populateOpts = populateOpts ?? this.getPopulateOpts();

		const instance = useInstance ?? this._activeWagers;
		instance.playId = this.playId;
		instance.populate(rawList, populateOpts);
	}

	/**
	 * ACTION
	 * Populates the pending wagers data using the specified raw data.
	 */
	protected populatePendingWagersFromRawData(
		rawData: RawServerPlayerWagerStateData,
		populateOpts?: Maybe<IPopulateWagersOpts>,
		useInstance?: Maybe<IPendingWagers>
	) {
		const rawList = (rawData.pendingWagers ?? []) as IActiveWagerData[];
		populateOpts = populateOpts ?? this.getPopulateOpts();

		const instance = useInstance ?? this._pendingWagers;
		instance.playId = this.playId;
		instance.populate(rawList, populateOpts);
	}

	/**
	 * ACTION
	 * Populates the rejected wagers data using the specified raw data.
	 */
	protected populateRejectedWagersFromRawData(
		rawData: RawServerPlayerWagerStateData,
		populateOpts?: Maybe<IPopulateWagersOpts>,
		useInstance?: Maybe<IRejectedWagers>
	) {
		const rawList = (rawData.rejectedWagers ?? []) as IActiveWagerData[];
		populateOpts = populateOpts ?? this.getPopulateOpts();

		const instance = useInstance ?? this._pendingWagers;
		instance.playId = this.playId;
		instance.populate(rawList, populateOpts);
	}

	/**
	 * ACTION
	 * Populates all the wager collection instances data using the specified raw data.
	 */
	protected populateWagerCollectionInstancesFromRawData(
		rawData: RawServerPlayerWagerStateData,
		opts?: Maybe<{
			updatedTs?: Maybe<number>;
			useInstances?: Maybe<{
				activeWagers?: Maybe<IActiveWagers>;
				pendingWagers?: Maybe<IPendingWagers>;
				rejectedWagers?: Maybe<IRejectedWagers>;
			}>;
		}>
	) {
		const populateOpts = this.getPopulateOpts({ updatedTs: opts?.updatedTs });

		this.populateActiveWagersFromRawData(rawData, populateOpts, opts?.useInstances?.activeWagers);
		this.populatePendingWagersFromRawData(rawData, populateOpts, opts?.useInstances?.pendingWagers);
		this.populateRejectedWagersFromRawData(rawData, populateOpts, opts?.useInstances?.rejectedWagers);
	}

	/**
	 * @returns The options to use when calling `resolveAmount` or `resolveAmounts`.
	 */
	protected getResolveAmountsOpts(): IMethodResolveAmountsOpts {
		const resolveAmountsFn = this._dataRetrievers.resolveAmountsOpts ?? null;
		return resolveAmountsFn != null ? resolveAmountsFn() : {};
	}

	/**
	 * @returns The encapsulated lookup data generated from the specified active, pending and rejected data collections.
	 */
	protected newLookupFromCollections = (
		activeWagers: IActiveWagers,
		pendingWagers: IPendingWagers,
		rejectedWagers: IRejectedWagers,
		opts?: Maybe<{ updatedTs?: Maybe<number> }>
	): ServerPlayerWagerStateDataLookup => {
		const resolveAmountsOpts = this.getResolveAmountsOpts();

		const updatedTs = opts?.updatedTs ?? Date.now();
		const currentLookup = copyLookup(this.lookup);
		const result = new Map<string, IServerPlayerWagerStateDataEntry>();

		/*=================================================================================================================
		 * @returns An entry with REMOVED state that matches the specified current wager data.
		 *================================================================================================================*/
		const makeRemovedEntryForCurrent = (
			currentEntry: IServerPlayerWagerStateDataEntry
		): IServerPlayerWagerStateDataEntry => {
			const currentData = { ...currentEntry };
			const currencyCode: Nullable<string> = currentData.currencyCode || resolveAmountsOpts.currencyCode || null;
			const zeroAmounts: IResolvedAmountData = resolveAmount(0, { ...resolveAmountsOpts, currencyCode });

			return {
				...currentData,
				...zeroAmounts,
				lastUpdatedTs: updatedTs,
				state: ServerWageringState.REMOVED,
				removed: { originalData: currentData },
			};
		};

		/*=================================================================================================================
		 * @returns A wager with ACTIVE state that extends the specified incoming active wager data.
		 *================================================================================================================*/
		const makeActiveEntry = (entry: IActiveWagerDataEntry): IServerPlayerWagerStateDataEntry => {
			return {
				...entry,
				lastUpdatedTs: updatedTs,
				state: ServerWageringState.ACTIVE,
			};
		};

		/*=================================================================================================================
		 * @returns A wager with the correct PENDING state that utilizes the specified incoming pending (and active) wager data.
		 *================================================================================================================*/
		const makePendingEntry = (
			entry: IPendingWagerDataEntry,
			opts?: Maybe<{ activeWagerData?: Maybe<IActiveWagerDataEntry> }>
		): IServerPlayerWagerStateDataEntry => {
			const activeWagerData: Nullable<IActiveWagerDataEntry> =
				opts?.activeWagerData != null ? { ...opts?.activeWagerData } : null;

			const pendingState =
				activeWagerData == null ? ServerWageringState.PENDING_NEW : ServerWageringState.PENDING_UPDATE;

			const currencyCode: Nullable<string> = entry.currencyCode || resolveAmountsOpts.currencyCode || null;
			const zeroAmounts: IResolvedAmountData = resolveAmount(0, { ...resolveAmountsOpts, currencyCode });

			let result = {
				...entry,
				...zeroAmounts,
				lastUpdatedTs: updatedTs,
				state: pendingState,
				sequence: entry.sequence, // TODO: Review this
				pending: {
					pendingAmount: entry.amount,
					pendingSequence: entry.sequence,
					activeWagerData,
				},
			};

			// Updating pending wagers have the same amount as the active wager (until they are accepted by the server)
			if (pendingState === ServerWageringState.PENDING_UPDATE) {
				const activeAmounts: IResolvedAmountData = resolveAmount(activeWagerData?.amount ?? 0, {
					...resolveAmountsOpts,
					currencyCode,
				});

				result = { ...result, ...activeAmounts };
			}

			return result;
		};

		/*=================================================================================================================
		 * @returns A wager with REJECTED state that utilizes the specified incoming rejected (and active) wager data.
		 *================================================================================================================*/
		const makeRejectedEntry = (
			entry: IRejectedWagerDataEntry,
			opts?: Maybe<{ activeWagerData?: Maybe<IActiveWagerDataEntry> }>
		): IServerPlayerWagerStateDataEntry => {
			const activeWagerData: Nullable<IActiveWagerDataEntry> =
				opts?.activeWagerData != null ? { ...opts?.activeWagerData } : null;

			const rejectedState =
				activeWagerData == null ? ServerWageringState.REJECTED_NEW : ServerWageringState.REJECTED_UPDATE;

			const currencyCode: Nullable<string> = entry.currencyCode || resolveAmountsOpts.currencyCode || null;
			const zeroAmounts: IResolvedAmountData = resolveAmount(0, { ...resolveAmountsOpts, currencyCode });

			let result: IServerPlayerWagerStateDataEntry = {
				...entry,
				...zeroAmounts,
				lastUpdatedTs: updatedTs,
				state: rejectedState,
				sequence: entry.sequence, // TODO: Review this
				rejected: {
					rejectedAmount: entry.amount,
					rejectedSequence: entry.sequence,
					activeWagerData,
				},
			};

			// Updating rejected wagers have the same amount as the active wager (until they are accepted by the server)
			if (rejectedState === ServerWageringState.REJECTED_UPDATE) {
				const activeAmounts: IResolvedAmountData = resolveAmount(activeWagerData?.amount ?? 0, {
					...resolveAmountsOpts,
					currencyCode,
				});

				result = { ...result, ...activeAmounts };
			}

			return result;
		};

		/*=================================================================================================================
		 * Adds a REMOVED state entry to result for every current entry that is not in the incoming data.
		 *================================================================================================================*/
		const addMissingCurrentEntriesAsRemovedToResult = () => {
			currentLookup.forEach((entry, key) => {
				if (result.has(key)) {
					return;
				}

				// Do not include previously removed entries
				if (entry.state === ServerWageringState.REMOVED) {
					return;
				}

				const removedEntry = makeRemovedEntryForCurrent(entry);
				result.set(key, removedEntry);
			});
		};

		// If there are no active, pending or rejected wagers in the incoming data, then we can return the current lookup
		// will all the entries marked as removed.
		if (activeWagers.size + pendingWagers.size + rejectedWagers.size === 0) {
			addMissingCurrentEntriesAsRemovedToResult();
			return result;
		}

		// Overlay all the incoming active wagers
		if (activeWagers.size > 0) {
			activeWagers.lookup.forEach((activeEntry: IActiveWagerDataEntry, key) => {
				const newActiveEntry = makeActiveEntry(activeEntry);
				result.set(key, newActiveEntry);
			});
		}

		// Overlay all the incoming pending wagers
		if (pendingWagers.size > 0) {
			pendingWagers.lookup.forEach((pendingEntry: IPendingWagerDataEntry, key) => {
				// If our incoming pending entry is older than the active entry for the same wager key entry, then we safely ignore it.
				const activeWagerData = activeWagers.get(key) ?? null;
				if (activeWagerData != null && pendingEntry.sequence < activeWagerData.sequence) {
					return;
				}

				// Replace the current result entry with a pending wager entry
				const newPendingEntry = makePendingEntry(pendingEntry, { activeWagerData });
				result.set(key, newPendingEntry);
			});
		}

		// Overlay all the incoming rejected wagers
		if (rejectedWagers.size > 0) {
			rejectedWagers.lookup.forEach((rejectedEntry: IRejectedWagerDataEntry, key) => {
				// Do not include stale rejections - sequence must match or be greater than the existing result entry
				const existingResultEntry = result.get(key) ?? null;
				if (existingResultEntry != null && rejectedEntry.sequence < existingResultEntry.sequence) {
					return;
				}

				// Replace the current result entry with a rejected wager entry
				const activeWagerData = activeWagers.get(key) ?? null;
				const newRejectedEntry = makeRejectedEntry(rejectedEntry, { activeWagerData });
				result.set(key, newRejectedEntry);
			});
		}

		// Any current entries that were not added/updated by the incoming data are considered removed.
		addMissingCurrentEntriesAsRemovedToResult();

		return result;
	};

	/**
	 * ACTION
	 * Sets the `_activeWagers` data instance.
	 */
	protected setActiveWagersInstance(instance: IActiveWagers): void {
		if (this.isMobXBound) {
			set(this, '_activeWagers', instance);
		} else {
			this._activeWagers = instance;
		}
	}

	/**
	 * ACTION
	 * Sets the `_pendingWagers` data instance.
	 */
	protected setPendingWagersInstance(instance: IPendingWagers): void {
		if (this.isMobXBound) {
			set(this, '_pendingWagers', instance);
		} else {
			this._pendingWagers = instance;
		}
	}

	/**
	 * ACTION
	 * Sets the `_rejectedWagers` data instance.
	 */
	protected setRejectedWagersInstance(instance: IRejectedWagers): void {
		if (this.isMobXBound) {
			set(this, '_rejectedWagers', instance);
		} else {
			this._rejectedWagers = instance;
		}
	}

	/* #endregion ---- Protected ------------------------------------------------------------------------------------- */

	/* #region ---- Static ------------------------------------------------------------------------------------------- */

	/**
	 * STATIC
	 * @returns The default options data used by this class.
	 */
	public static defaultOptions(): IServerPlayerWagerStateOpts {
		return {
			...EventDispatcherBase.defaultOptions(),
			data: null,
			updatedTs: null,
			useMobX: true,
		};
	}

	/**
	 * STATIC
	 * @returns The default data encapsulated this class.
	 */
	public static defaultData(
		opts?: Maybe<{ updatedTs?: Maybe<number>; playId?: Maybe<string> }>
	): IServerPlayerWagerStateData {
		return defaultData(opts);
	}

	/**
	 * STATIC
	 * @returns The default data encapsulated this class.
	 */
	public static copyData(
		data: IServerPlayerWagerStateData,
		opts?: Maybe<{ updatedTs?: Maybe<number> }>
	): IServerPlayerWagerStateData {
		return copyData(data, opts);
	}

	/**
	 * STATIC
	 * @returns A new instance of this class populated with the specified raw active data.
	 */
	// public static newFromRawData(
	// 	rawList: RawActiveWagerList,
	// 	playId: string,

	// 	opts?: Maybe<{
	// 		availableWagersList?: Maybe<IAvailableWagerDataExt[]>;
	// 		wagerDefinitionsList?: Maybe<IPlayWagerDefinitionDataExt[]>;
	// 		playSeatAssignmentsList?: Maybe<IPlaySeatDataExt[]>;
	// 		newInstanceOpts?: Maybe<Omit<IActiveWagersOpts, 'data' | 'playId' | 'updatedTs'>>;
	// 		populateOpts?: Maybe<
	// 			Omit<
	// 				IMethodActiveWagersMapRawDataOpts,
	// 				'availableWagersList' | 'wagerDefinitionsList' | 'playSeatAssignmentsList'
	// 			>
	// 		>;
	// 	}>
	// ): ServerPlayerWagerState {
	// 	const populateOpts: IMethodActiveWagersMapRawDataOpts = {
	// 		...opts?.populateOpts,
	// 		availableWagersList: opts?.availableWagersList,
	// 		wagerDefinitionsList: opts?.wagerDefinitionsList,
	// 		playSeatAssignmentsList: opts?.playSeatAssignmentsList,
	// 	};

	// 	const data = ActiveWagers.newDataFromRawList(rawList, playId, populateOpts);

	// 	return new ServerPlayerWagerState({ ...opts?.newInstanceOpts, data, updatedTs: data.lastUpdatedTs });
	// }

	/* #endregion ---- Static ---------------------------------------------------------------------------------------- */

	/* #region ---- Debug -------------------------------------------------------------------------------------------- */

	/**
	 * Overrides the parent class property.
	 *
	 * @returns The label to use when debugging.
	 */
	protected override get debugClassLabel(): string {
		return ServerPlayerWagerState.debugClassLabel();
	}

	/**
	 * STATIC
	 * @returns Label assigned to this class namespace.
	 */
	protected static debugClassLabel(): string {
		return `RpcLib.DataObject.ServerPlayerWagerState`;
	}

	/* #endregion ---- Debug ----------------------------------------------------------------------------------------- */
}

// ---- Exports -------------------------------------------------------------------------------------------------------

export { ServerPlayerWagerState as default };
export { ServerPlayerWagerState };
