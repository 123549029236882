import { action, AnnotationsMap, computed, makeObservable, observable } from 'mobx';
import SettingsStore from './SettingsStore';
import { SettingsStoreMobXPrivateProps } from './types.mobx';

/**
 * MobX annotations for `SettingsStore`.
 */
const SettingsStoreMobXAnnotations: AnnotationsMap<SettingsStore, SettingsStoreMobXPrivateProps> = {
	// ---- Observed -----------------------------------------------------

	// >> Properties
	_data: observable,
	_lastUpdatedTs: observable,

	// ---- Computed -----------------------------------------------------

	// >> Public
	data: computed,
	lastUpdatedTs: computed,
	isMuted: computed,
	videoSetting: computed,
	dealerVolume: computed,
	gameVolume: computed,
	ambientVolume: computed,
	isChatEnabled: computed,
	isChatFilterEnabled: computed,

	// ---- Actions ------------------------------------------------------

	// >> Public
	clear: action,
	toggleChatEnabled: action,
	toggleChatFilterEnabled: action,

	// >> Protected
	setUpdatedTs: action,

	// ---- Excluded -----------------------------------------------------

	_isMobXBound: false,
	isMobXBound: false,
};

/**
 * Makes the specified `SettingsStore` instance an observable MobX object.
 */
const bindSettingsStoreMobX = (instance: SettingsStore): boolean => {
	if (instance.isMobXBound) {
		return false;
	}

	makeObservable<SettingsStore, SettingsStoreMobXPrivateProps>(instance, {
		...SettingsStoreMobXAnnotations,
	});

	instance.isMobXBound = true;

	return true;
};

// ---- Exports -------------------------------------------------------------------------------------------------------

export { SettingsStoreMobXAnnotations, bindSettingsStoreMobX };
