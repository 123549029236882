import { useMediaQuery } from 'react-responsive';

enum WindowLayout {
	MOBILE = 'mobile',
	TABLET = 'tablet',
	TABLET_LANDSCAPE = 'tablet_landscape',
	DESKTOP = 'destop',
}

const useLayout = (): WindowLayout => {
	const isMobile = useMediaQuery({ maxAspectRatio: '1/1', maxWidth: 767 });
	const isTabletPortrait = useMediaQuery({ maxAspectRatio: '1/1', minWidth: 768 });
	const isTabletLandscape = useMediaQuery({ minAspectRatio: '1/1', maxWidth: 1200 });

	if (isMobile) {
		return WindowLayout.MOBILE;
	} else if (isTabletPortrait) {
		return WindowLayout.TABLET;
	} else if (isTabletLandscape) {
		return WindowLayout.TABLET_LANDSCAPE;
	}

	return WindowLayout.DESKTOP;
};

// ---- Export --------------------------------------------------------------------------------------------------------

export { WindowLayout };
export { useLayout };
