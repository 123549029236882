import findIndex from 'lodash/findIndex';
import { normalizeCurrencyCode } from '../../../../helpers';
import {
	IAmountHistoryItem,
	IMethodUpdateWalletDirtyBalanceHistoryOpts,
	IUpdateWalletDirtyBalanceHistoryItemsDiff,
	IWalletDirtyBalanceHistory,
	IWalletDirtyBalanceHistoryData,
	IWalletDirtyBalanceHistoryOpts,
	WalletDirtyBalanceHistoryItems,
	WalletDirtyBalanceHistoryLookup,
} from './types';

class WalletDirtyBalanceHistory implements IWalletDirtyBalanceHistory {
	/* #region ---- Properties --------------------------------------------------------------------------------------- */

	/**
	 * Currently assigned options.
	 */
	protected _options: IWalletDirtyBalanceHistoryOpts = WalletDirtyBalanceHistory.defaultOptions();

	/**
	 * Encapsulated data.
	 */
	protected _data: IWalletDirtyBalanceHistoryData;

	/* #endregion ---- Properties -------------------------------------------------------------------------------------*/

	/* #region ---- CONSTRUCTOR ---------------------------------------------------------------------------------------*/

	constructor(opts?: Maybe<IWalletDirtyBalanceHistoryOpts>) {
		this._data = WalletDirtyBalanceHistory.defaultData();
		this.setOptions(opts);
	}

	/* #endregion ---- CONSTRUCTOR ----------------------------------------------------------------------------------- */

	/* #region ---- Public ------------------------------------------------------------------------------------------- */

	/**
	 * Sets/initializes the class options.
	 */
	public setOptions(opts?: Maybe<IWalletDirtyBalanceHistoryOpts>) {
		if (opts == null) {
			return;
		}

		const origOpts: IWalletDirtyBalanceHistoryOpts = {
			...this._options,
		};

		const newOpts: IWalletDirtyBalanceHistoryOpts = {
			...origOpts,
			...(opts ?? {}),
		};

		if (newOpts.data != null) {
			this._data = newOpts.data;
		}

		if (newOpts.updatedTs != null && newOpts.updatedTs !== origOpts.updatedTs) {
			this._data.lastUpdatedTs = newOpts.updatedTs;
		}

		this._options = newOpts;
	}

	/**
	 * Whether or not the data collection is empty.
	 */
	public get isEmpty(): boolean {
		return this.size === 0;
	}

	/**
	 * Size of the data collection.
	 */
	public get size(): number {
		return this.lookup.size;
	}

	/**
	 * Lookup of dirty balance history data keyed by currency code.
	 */
	public get lookup(): WalletDirtyBalanceHistoryLookup {
		return this._data.lookup;
	}

	/**
	 * The last time (unix timestamp) that the data was updated.
	 */
	public get lastUpdatedTs(): number {
		return this._data.lastUpdatedTs;
	}
	public set lastUpdatedTs(val: number) {
		this._data.lastUpdatedTs = val;
	}

	/**
	 * The full data encapsulated by this class instance.
	 */
	public get data(): IWalletDirtyBalanceHistoryData {
		return this._data;
	}
	public set data(val: IWalletDirtyBalanceHistoryData) {
		this._data = val;
	}

	/**
	 * Resets the server balances back to the initial/clear values.
	 */
	public clear() {
		this._data = WalletDirtyBalanceHistory.defaultData(Date.now());
	}

	/**
	 * @returns A new clone of this class instance with copied data.
	 */
	public clone(updatedTs?: Maybe<number>): WalletDirtyBalanceHistory {
		const newData = WalletDirtyBalanceHistory.copyData(this._data, updatedTs);

		return new WalletDirtyBalanceHistory({ data: newData, updatedTs });
	}

	/**
	 * @returns The dirty balance history items for the specified currency code.
	 *          Will return NULL if data for the currency is not present.
	 */
	public get(currencyCode: string, opts?: Maybe<{ copy?: Maybe<boolean> }>): Nullable<WalletDirtyBalanceHistoryItems> {
		const items = this.lookup.get(currencyCode) ?? null;

		if (opts?.copy === true && items != null) {
			return items.slice();
		}

		return items;
	}

	/**
	 * Sets the dirty balance history items for the specified currency code.
	 */
	public set(currencyCode: string, items: WalletDirtyBalanceHistoryItems) {
		this.lookup.set(currencyCode, items);
		this.lastUpdatedTs = Date.now();
	}

	/**
	 * @returns TRUE if an entry for the specified currency exists.
	 */
	public has(currencyCode: string): boolean {
		return this.lookup.has(currencyCode);
	}

	/**
	 * @returns A JSON export of the current pertinent data.
	 */
	public toJson(extended?: Maybe<boolean>): PlainObject {
		extended = extended ?? false;

		const result: PlainObject = {
			...this._data,
		};

		if (extended) {
			result.options = { ...this._options };
		}

		return result;
	}

	/**
	 * Add a new dirty balance history item for the specified amount and currency code.
	 *
	 * Data merge is immutable and operates on a copy of the history data.
	 *
	 * @returns An diff summary of the dirty balance history data - before and after item addition.
	 */
	public addAmount(
		currencyCode: string,
		amount: number,
		createdTs?: Maybe<number>
	): IUpdateWalletDirtyBalanceHistoryItemsDiff {
		currencyCode = normalizeCurrencyCode(currencyCode);

		const newItem = WalletDirtyBalanceHistory.newHistoryItem(amount, createdTs);
		const diff = WalletDirtyBalanceHistory.addItemFor(currencyCode, newItem, this);

		this._data = diff.history.updated;

		return diff;
	}

	/**
	 * @returns The index of the the first entry in dirty balance history items for the specified currency and amount.
	 */
	public findHistoryItemIndex(currencyCode: string, amount: number, createdByTs?: Maybe<number>): number {
		return WalletDirtyBalanceHistory.findHistoryItemIndexFor(currencyCode, amount, this, createdByTs);
	}

	/* #endregion ---- Public ---------------------------------------------------------------------------------------- */

	/* #region ---- Static ------------------------------------------------------------------------------------------- */

	/**
	 * STATIC
	 * @returns A clone of the specified class instance.
	 */
	public static cloneInstance(from: WalletDirtyBalanceHistory, updatedTs?: Maybe<number>): WalletDirtyBalanceHistory {
		return from.clone(updatedTs);
	}

	/**
	 * STATIC
	 * @returns A copy of the specified dirty balance history data.
	 */
	protected static copyData(
		data: IWalletDirtyBalanceHistoryData,
		updatedTs?: Maybe<number>
	): IWalletDirtyBalanceHistoryData {
		const newData: IWalletDirtyBalanceHistoryData = WalletDirtyBalanceHistory.defaultData();
		newData.lookup = new Map<string, WalletDirtyBalanceHistoryItems>(data.lookup);
		newData.lastUpdatedTs = updatedTs ?? data.lastUpdatedTs;

		return newData;
	}

	/**
	 * STATIC
	 * Simulate clearing the dirty balance history items for the data contained in the specified class instance.
	 *
	 * This is immutable and operates on a copy of the history data. All returned objects are copies.
	 *
	 * If the `apply` option is TRUE then the resulting updated data will be applied to the specified instance.
	 *
	 * @returns A diff summary of the dirty balance history data - before and after the clear.
	 */
	public static clearItemsFor(
		currencyCode: string,
		forInstance: IWalletDirtyBalanceHistory,
		opts?: Maybe<IMethodUpdateWalletDirtyBalanceHistoryOpts>
	): IUpdateWalletDirtyBalanceHistoryItemsDiff {
		currencyCode = normalizeCurrencyCode(currencyCode);

		return WalletDirtyBalanceHistory.replaceItemsFor(currencyCode, [], forInstance, opts);
	}

	/**
	 * STATIC
	 * Simulate adding a new dirty balance history item for the data contained in the specified class instance.
	 *
	 * This is immutable and operates on a copy of the history data. All returned objects are copies.
	 *
	 * If the `apply` option is TRUE then the resulting updated data will be applied to the specified instance.
	 *
	 * @returns A diff summary of the dirty balance history data - before and after item addition.
	 */
	public static addItemFor(
		currencyCode: string,
		item: IAmountHistoryItem,
		forInstance: IWalletDirtyBalanceHistory,
		opts?: Maybe<IMethodUpdateWalletDirtyBalanceHistoryOpts>
	): IUpdateWalletDirtyBalanceHistoryItemsDiff {
		currencyCode = normalizeCurrencyCode(currencyCode);

		const newItems = (forInstance.get(currencyCode) ?? []).slice();
		newItems.push({ ...item });

		return WalletDirtyBalanceHistory.replaceItemsFor(currencyCode, newItems, forInstance, opts);
	}

	/**
	 * STATIC
	 * Simulate slicing the dirty balance history items for the data contained in the specified class instance.
	 *
	 * This is immutable and operates on a copy of the history data. All returned objects are copies.
	 *
	 * If the `apply` option is TRUE then the resulting updated data will be applied to the specified instance.
	 *
	 * @returns A diff summary of the dirty balance history data - before and after the slice operation.
	 */
	public static sliceItemsFor(
		currencyCode: string,
		forInstance: IWalletDirtyBalanceHistory,
		begin?: Maybe<number>,
		end?: Maybe<number>,
		opts?: Maybe<IMethodUpdateWalletDirtyBalanceHistoryOpts>
	): IUpdateWalletDirtyBalanceHistoryItemsDiff {
		currencyCode = normalizeCurrencyCode(currencyCode);

		const items = forInstance.get(currencyCode) ?? [];
		if (items.length === 0 || (begin != null && begin > items.length && (end == null || end >= 0))) {
			return WalletDirtyBalanceHistory.clearItemsFor(currencyCode, forInstance, opts);
		}

		const newItems = items.slice(begin ?? undefined, end ?? undefined);

		return WalletDirtyBalanceHistory.replaceItemsFor(currencyCode, newItems, forInstance, opts);
	}

	/**
	 * STATIC
	 * Simulate updating the dirty balance history items for the data contained in the specified class instance.
	 *
	 * This is immutable and operates on a copy of the history data. All returned objects are copies.
	 *
	 * If the `apply` option is TRUE then the resulting updated data will be applied to the specified instance.
	 *
	 * @returns A diff summary of the dirty balance history data - before and after the update.
	 */
	public static replaceItemsFor(
		currencyCode: string,
		newItems: WalletDirtyBalanceHistoryItems,
		forInstance: IWalletDirtyBalanceHistory,
		opts?: Maybe<IMethodUpdateWalletDirtyBalanceHistoryOpts>
	): IUpdateWalletDirtyBalanceHistoryItemsDiff {
		currencyCode = normalizeCurrencyCode(currencyCode);

		const updatedTs = opts?.updatedTs ?? 0;

		const original = WalletDirtyBalanceHistory.copyData(forInstance.data);
		const updated = WalletDirtyBalanceHistory.copyData(original);

		const originalItems = (original.lookup.get(currencyCode) ?? []).slice();
		const updatedItems = newItems.slice();

		updated.lookup.set(currencyCode, newItems);
		updated.lastUpdatedTs = updatedTs > 0 ? updatedTs : Date.now();

		const diff = {
			currencyCode,
			history: { original, updated },
			items: { original: originalItems, updated: updatedItems },
		};

		if (opts?.apply) {
			forInstance.data = diff.history.updated;
		}

		return diff;
	}

	/**
	 * STATIC
	 * @returns The index of the the first entry in dirty balance history items for the specified currency and amount.
	 */
	public static findHistoryItemIndexFor = (
		currencyCode: string,
		amount: number,
		forInstance: IWalletDirtyBalanceHistory,
		createdByTs?: Maybe<number>
	): number => {
		const maxCreatedTs: number = createdByTs ?? 0;

		const items = forInstance.get(currencyCode) ?? [];

		if (amount < 0 || items.length === 0) {
			return -1;
		}

		return findIndex(items, (h) => {
			const isTimeValid = maxCreatedTs > 0 && h.createdTs <= maxCreatedTs;
			return h.amount === amount && isTimeValid;
		});
	};

	/**
	 * STATIC
	 * @returns A new dirty balance history item with the specified values.
	 */
	public static newHistoryItem(amount: number, createdTs?: Maybe<number>): IAmountHistoryItem {
		createdTs = createdTs ?? Date.now();

		return { amount, createdTs };
	}

	/**
	 * STATIC
	 * @returns The default data used by this class.
	 */
	public static defaultData(updatedTs?: Maybe<number>): IWalletDirtyBalanceHistoryData {
		return {
			lookup: new Map<string, WalletDirtyBalanceHistoryItems>(),
			lastUpdatedTs: updatedTs ?? 0,
		};
	}

	/**
	 * STATIC
	 * @returns The default options data used by this class.
	 */
	public static defaultOptions(): IWalletDirtyBalanceHistoryOpts {
		return {
			data: null,
			updatedTs: null,
		};
	}

	/* #endregion ---- Static ---------------------------------------------------------------------------------------- */
}

// ---- Exports -------------------------------------------------------------------------------------------------------

export { WalletDirtyBalanceHistory as default };
export { WalletDirtyBalanceHistory };
