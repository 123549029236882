import { ManagerBaseEvents } from '../lib/ManagerBase/constants';

/**
 * Events that the `WagerManager` will trigger.
 */
const Events: Record<string, string> = {
	...ManagerBaseEvents,
	PLAYER_ID_CHANGED: 'PLAYER_ID_CHANGED',
	TABLE_ID_CHANGED: 'TABLE_ID_CHANGED',
	PLAY_ID_CHANGED: 'PLAY_ID_CHANGED',
};

// Error codes specific to the wager manager
// const WagerManagerErrorCodes: Dict<number> = {};

/**
 * Dirty local wager data is considered to be stale after this number of milliseconds.
 */
const DIRTY_WAGER_STALE_THRESHOLD_MS = 5000; // 5 SECS

/**
 * Sequence number used for locally queued wagers.
 */
const LOCAL_QUEUED_SEQUENCE = Number.MAX_SAFE_INTEGER;

// ---- Exports -------------------------------------------------------------------------------------------------------

export { Events };
export { DIRTY_WAGER_STALE_THRESHOLD_MS, LOCAL_QUEUED_SEQUENCE };
