import { action, AnnotationsMap, computed, makeObservable, observable } from 'mobx';
import { DebugBaseMobXAnnotations } from '../../../../common';
import ActiveWagers from './ActiveWagers';
import { ActiveWagersMobXPrivateProps } from './types.mobx';

const ActiveWagersMobXAnnotations: AnnotationsMap<ActiveWagers, ActiveWagersMobXPrivateProps> = {
	// Inherit from DebugBase
	...DebugBaseMobXAnnotations,

	// ---- Observed -----------------------------------------------------

	// >> Properties
	_data: observable.struct,

	// ---- Computed -----------------------------------------------------

	// >> Public
	isEmpty: computed,
	size: computed,
	lookup: computed,
	list: computed,
	keys: computed,
	dataHashId: computed,
	data: computed,
	lastUpdatedTs: computed,
	playId: computed,

	// ---- Actions ------------------------------------------------------

	// >> Public
	clear: action,
	populate: action,

	// >> Protected
	setLastUpdatedTs: action,
	setData: action,
	setPlayId: action,

	// ---- Excluded -----------------------------------------------------

	// >> Properties
	_isMobXBound: false,

	// >> Public
	isMobXBound: false,
};

/**
 * Makes the specified `ActiveWagers` instance an observable MobX object.
 */
const bindActiveWagersMobX = (instance: ActiveWagers): boolean => {
	if (instance.isMobXBound) {
		return false;
	}

	makeObservable<ActiveWagers, ActiveWagersMobXPrivateProps>(instance, {
		...ActiveWagersMobXAnnotations,
	});

	instance.isMobXBound = true;

	return true;
};

// ---- Exports -------------------------------------------------------------------------------------------------------

export { ActiveWagersMobXAnnotations, bindActiveWagersMobX };
