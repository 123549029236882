import { action, AnnotationsMap, computed, observable } from 'mobx';
import DebugBase from './DebugBase';
import { DebugBaseMobXPrivateProps } from './types.mobx';

/**
 * MobX annotations for `DebugBase`.
 */
const DebugBaseMobXAnnotations: AnnotationsMap<DebugBase, DebugBaseMobXPrivateProps> = {
	// ---- Observed -----------------------------------------------------

	// >>> Properties
	_options: observable.struct,
	_isDebugEnabled: observable,
	_debugLabel: observable,

	// ---- Computed -----------------------------------------------------

	// >>> Public
	isDebugEnabled: computed,

	// >>> Protected
	debugLabel: computed,

	// ---- Actions ------------------------------------------------------

	// >>> Public
	setOptions: action,

	// >>> Protected
	setIsDebugEnabled: action,

	// ---- Excluded -----------------------------------------------------

	// >>> Properties
	_debug: false,
	_instanceId: false,
	toJson: false,

	// >>> Public
	instanceId: false,

	// >>> Protected
	debug: false,
	info: false,
	warn: false,
	error: false,
	newDebugLogger: false,
	getDebugLogPrefix: false,
	debugClassLabel: false,
	resolveOptions: false,
	onSetOptions: false,
	onSetDebugLabel: false,
	onSetDebugEnabled: false,
};

// ---- Exports -------------------------------------------------------------------------------------------------------

export { DebugBaseMobXAnnotations };
