import { IDebugMethodOpts, ILogger } from './types';

class Logger implements ILogger {
	/* #region ---- Properties --------------------------------------------------------------------------------------- */

	/**
	 * Log prefix to use when logging.
	 */
	protected _logPrefix: string = 'Logger';

	/**
	 * If TRUE then no logging will occur.
	 */
	protected _silent: boolean = false;

	/* #endregion ---- Properties -------------------------------------------------------------------------------------*/

	/* #region ---- CONSTRUCTOR ---------------------------------------------------------------------------------------*/

	constructor(logPrefix: string) {
		this._logPrefix = logPrefix;
	}

	/* #endregion ---- CONSTRUCTOR ------------------------------------------------------------------------------------*/

	/* #region ---- Public ------------------------------------------------------------------------------------------- */

	/**
	 * Gets/sets the log prefix to use when logging.
	 */
	public get prefix(): string {
		return this._logPrefix;
	}
	public set prefix(val: string) {
		this._logPrefix = val;
	}

	/**
	 * Gets/sets if logging is enabled.
	 */
	public get silent(): boolean {
		return this._silent;
	}
	public set silent(val: boolean) {
		this._silent = val;
	}

	/**
	 * Logs the specified error message for the given method and arguments.
	 */
	public error(msg: string, method?: Maybe<string>, ...args: unknown[]) {
		!this.silent && Logger.logError(msg, { method, prefix: this._logPrefix }, ...args);
	}

	/**
	 * Logs the specified warning message for the given method and arguments.
	 */
	public warn(msg: string, method?: Maybe<string>, ...args: unknown[]) {
		!this.silent && Logger.logWarn(msg, { method, prefix: this._logPrefix }, ...args);
	}

	/**
	 * Logs the specified informational message for the given method and arguments.
	 */
	public info(msg: string, method?: Maybe<string>, ...args: unknown[]) {
		!this.silent && Logger.logInfo(msg, { method, prefix: this._logPrefix }, ...args);
	}

	/**
	 * Creates a debug message for the given message and method.
	 */
	public makeMessage(msg: string, method?: Maybe<string>): string {
		return Logger.makeMessage(msg, method, this._logPrefix);
	}

	/* #endregion ---- Public ---------------------------------------------------------------------------------------- */

	/* #region ---- Static ------------------------------------------------------------------------------------------- */

	/**
	 * @returns A debug message for the given message and options.
	 */
	public static debugMsg(msg: string, opts?: Maybe<IDebugMethodOpts>): string {
		const logPrefix = opts?.prefix || '';
		const logMethod = opts?.method || '';

		const prefix: string[] = [];
		prefix.push(logPrefix !== '' ? `[${logPrefix}]: ` : '');
		prefix.push(logMethod !== '' ? `${logMethod} - ` : '');

		return `${prefix.join('')}${msg}`;
	}

	/**
	 * @returns A debug message for the given message, method and prefix.
	 */
	public static makeMessage(msg: string, method?: Maybe<string>, prefix?: Maybe<string>): string {
		return Logger.debugMsg(msg, { method, prefix });
	}

	/**
	 * Logs the specified error message using the given options and additional arguments.
	 */
	public static logError(msg: string, opts?: Maybe<IDebugMethodOpts>, ...args: unknown[]): void {
		console.error(Logger.debugMsg(msg, opts), ...args);
	}

	/**
	 * Logs the specified warning message using the given options and additional arguments.
	 */
	public static logWarn(msg: string, opts?: Maybe<IDebugMethodOpts>, ...args: unknown[]): void {
		console.warn(Logger.debugMsg(msg, opts), ...args);
	}

	/**
	 * Logs the specified informational message using the given options and additional arguments.
	 */
	public static logInfo(msg: string, opts?: Maybe<IDebugMethodOpts>, ...args: unknown[]): void {
		console.log(Logger.debugMsg(msg, opts), ...args);
	}

	/* #endregion ---- Static ---------------------------------------------------------------------------------------- */
}

// ---- Exports -------------------------------------------------------------------------------------------------------

export { Logger as default };
export { Logger };
