// @generated by protoc-gen-connect-es v0.11.0 with parameter "target=js+dts"
// @generated from file clientserver.proto (package com.sands.ldx.clientserver, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { AuxiliaryGameDataReply, AuxiliaryGameDataRequest, ClearWagerRequest, ClearWagersRequest, GameHistoryReply, GameHistoryRequest, GetPlayerGameSettingReply, GetPlayerGameSettingRequest, GetPlayerTableSettingReply, GetPlayerTableSettingRequest, GetPlayReply, GetPlayRequest, GetSelfReply, GetSelfRequest, GetTableReply, GetTableRequest, GetTablesReply, GetTablesRequest, LeaderboardReply, LeaderboardRequest, ListPlayerGameSettingsReply, ListPlayerGameSettingsRequest, ListPlayerSettingsReply, ListPlayerSettingsRequest, ListPlayerTableSettingsReply, ListPlayerTableSettingsRequest, MakeChoiceReply, MakeChoiceRequest, MakeWagersReply, MakeWagersRequest, ReadyToPlayRequest, SitTableReply, SitTableRequest, StandTableRequest, UpdatePlayerGameSettingRequest, UpdatePlayerTableSettingRequest, UpdateSeatSettingsReply, UpdateSeatSettingsRequest } from "./clientserver_pb.js";
import { Empty, MethodKind } from "@bufbuild/protobuf";

/**
 * @generated from service com.sands.ldx.clientserver.ClientServer
 */
export const ClientServer = {
  typeName: "com.sands.ldx.clientserver.ClientServer",
  methods: {
    /**
     * GetSelf returns details about the requesting user 
     *
     * @generated from rpc com.sands.ldx.clientserver.ClientServer.GetSelf
     */
    getSelf: {
      name: "GetSelf",
      I: GetSelfRequest,
      O: GetSelfReply,
      kind: MethodKind.Unary,
    },
    /**
     * StreamSelf streams details about the requesting user 
     *
     * @generated from rpc com.sands.ldx.clientserver.ClientServer.StreamSelf
     */
    streamSelf: {
      name: "StreamSelf",
      I: GetSelfRequest,
      O: GetSelfReply,
      kind: MethodKind.ServerStreaming,
    },
    /**
     * GetTables returns a list of game tables 
     *
     * @generated from rpc com.sands.ldx.clientserver.ClientServer.GetTables
     */
    getTables: {
      name: "GetTables",
      I: GetTablesRequest,
      O: GetTablesReply,
      kind: MethodKind.Unary,
    },
    /**
     * GetTable returns table state (non-streaming).  
     *
     * @generated from rpc com.sands.ldx.clientserver.ClientServer.GetTable
     */
    getTable: {
      name: "GetTable",
      I: GetTableRequest,
      O: GetTableReply,
      kind: MethodKind.Unary,
    },
    /**
     * StreamTable streams state of the table. 
     *
     * @generated from rpc com.sands.ldx.clientserver.ClientServer.StreamTable
     */
    streamTable: {
      name: "StreamTable",
      I: GetTableRequest,
      O: GetTableReply,
      kind: MethodKind.ServerStreaming,
    },
    /**
     * GetPlay returns the current play state.  
     *
     * @generated from rpc com.sands.ldx.clientserver.ClientServer.GetPlay
     */
    getPlay: {
      name: "GetPlay",
      I: GetPlayRequest,
      O: GetPlayReply,
      kind: MethodKind.Unary,
    },
    /**
     * StreamPlay streams the current play statey. 
     *
     * @generated from rpc com.sands.ldx.clientserver.ClientServer.StreamPlay
     */
    streamPlay: {
      name: "StreamPlay",
      I: GetPlayRequest,
      O: GetPlayReply,
      kind: MethodKind.ServerStreaming,
    },
    /**
     * @generated from rpc com.sands.ldx.clientserver.ClientServer.ReadyToPlay
     */
    readyToPlay: {
      name: "ReadyToPlay",
      I: ReadyToPlayRequest,
      O: Empty,
      kind: MethodKind.Unary,
    },
    /**
     * MakeWagers allows, when betting is open, a player making or altering wagers on a play. 
     *
     * @generated from rpc com.sands.ldx.clientserver.ClientServer.MakeWagers
     */
    makeWagers: {
      name: "MakeWagers",
      I: MakeWagersRequest,
      O: MakeWagersReply,
      kind: MethodKind.Unary,
    },
    /**
     * MakeChoice allows a player during the play to make decisions. 
     *
     * @generated from rpc com.sands.ldx.clientserver.ClientServer.MakeChoice
     */
    makeChoice: {
      name: "MakeChoice",
      I: MakeChoiceRequest,
      O: MakeChoiceReply,
      kind: MethodKind.Unary,
    },
    /**
     * SitTable allows a player to sit down at the game table. 
     *
     * @generated from rpc com.sands.ldx.clientserver.ClientServer.SitTable
     */
    sitTable: {
      name: "SitTable",
      I: SitTableRequest,
      O: SitTableReply,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc com.sands.ldx.clientserver.ClientServer.StandTable
     */
    standTable: {
      name: "StandTable",
      I: StandTableRequest,
      O: Empty,
      kind: MethodKind.Unary,
    },
    /**
     * ClearWager allows a player to clear a wager type for a context 
     *
     * @generated from rpc com.sands.ldx.clientserver.ClientServer.ClearWager
     */
    clearWager: {
      name: "ClearWager",
      I: ClearWagerRequest,
      O: Empty,
      kind: MethodKind.Unary,
    },
    /**
     * ClearWagers allows a player to clear wagers 
     *
     * @generated from rpc com.sands.ldx.clientserver.ClientServer.ClearWagers
     */
    clearWagers: {
      name: "ClearWagers",
      I: ClearWagersRequest,
      O: Empty,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc com.sands.ldx.clientserver.ClientServer.UpdatePlayerGameSetting
     */
    updatePlayerGameSetting: {
      name: "UpdatePlayerGameSetting",
      I: UpdatePlayerGameSettingRequest,
      O: Empty,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc com.sands.ldx.clientserver.ClientServer.UpdatePlayerTableSetting
     */
    updatePlayerTableSetting: {
      name: "UpdatePlayerTableSetting",
      I: UpdatePlayerTableSettingRequest,
      O: Empty,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc com.sands.ldx.clientserver.ClientServer.GetPlayerGameSetting
     */
    getPlayerGameSetting: {
      name: "GetPlayerGameSetting",
      I: GetPlayerGameSettingRequest,
      O: GetPlayerGameSettingReply,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc com.sands.ldx.clientserver.ClientServer.GetPlayerTableSetting
     */
    getPlayerTableSetting: {
      name: "GetPlayerTableSetting",
      I: GetPlayerTableSettingRequest,
      O: GetPlayerTableSettingReply,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc com.sands.ldx.clientserver.ClientServer.ListPlayerSettings
     */
    listPlayerSettings: {
      name: "ListPlayerSettings",
      I: ListPlayerSettingsRequest,
      O: ListPlayerSettingsReply,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc com.sands.ldx.clientserver.ClientServer.ListPlayerGameSettings
     */
    listPlayerGameSettings: {
      name: "ListPlayerGameSettings",
      I: ListPlayerGameSettingsRequest,
      O: ListPlayerGameSettingsReply,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc com.sands.ldx.clientserver.ClientServer.ListPlayerTableSettings
     */
    listPlayerTableSettings: {
      name: "ListPlayerTableSettings",
      I: ListPlayerTableSettingsRequest,
      O: ListPlayerTableSettingsReply,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc com.sands.ldx.clientserver.ClientServer.UpdateSeatSettings
     */
    updateSeatSettings: {
      name: "UpdateSeatSettings",
      I: UpdateSeatSettingsRequest,
      O: UpdateSeatSettingsReply,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc com.sands.ldx.clientserver.ClientServer.GetLeaderboard
     */
    getLeaderboard: {
      name: "GetLeaderboard",
      I: LeaderboardRequest,
      O: LeaderboardReply,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc com.sands.ldx.clientserver.ClientServer.GetGameHistory
     */
    getGameHistory: {
      name: "GetGameHistory",
      I: GameHistoryRequest,
      O: GameHistoryReply,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc com.sands.ldx.clientserver.ClientServer.GetAuxiliaryGameData
     */
    getAuxiliaryGameData: {
      name: "GetAuxiliaryGameData",
      I: AuxiliaryGameDataRequest,
      O: AuxiliaryGameDataReply,
      kind: MethodKind.Unary,
    },
  }
};

