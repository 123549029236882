import omit from 'lodash/omit';
import { resolveAmounts } from '../amounts';
import { filterNullUndefined } from '../shared';
import {
	IExtendPlayWagerDefinitionDataOpts,
	IPlayWagerDefinitionData,
	IPlayWagerDefinitionDataExt,
} from './types/playWagerDefinitionExt';

/**
 * @returns An empty `IPlayWagerDefinitionData` object with default values.
 */
const newEmptyPlayWagerDefinitionData = (): IPlayWagerDefinitionData => ({
	wagerTypeId: '',
	name: '',
	description: '',
	isCategory: false,
	availableKeys: [],
	required: false,
	minAmount: BigInt(0),
	maxAmount: BigInt(0),
	currency: '',
	wagerPeriodId: '',
	requiresSeat: false,
	requiresSpectator: false,
});

/**
 * @returns An empty `IPlayWagerDefinitionDataExt` object with default values.
 */
const newEmptyPlayWagerDefinitionDataExt = (): IPlayWagerDefinitionDataExt => ({
	raw: newEmptyPlayWagerDefinitionData(),
	wagerId: '',
	name: '',
	description: '',
	currencyCode: '',
	currencySymbol: '',
	minAmount: 0,
	minAmountReal: 0,
	minAmountMoney: '',
	maxAmount: 0,
	maxAmountReal: 0,
	maxAmountMoney: '',
	isRequired: false,
	isCategory: false,
	categoryMemberKeys: [],
	wagerPeriodId: '',
	isSeatRequired: false,
	isSpectatorRequired: false,
	playId: '',
});

/**
 * @returns Extended version of `IPlayWagerDefinitionData` with re-mapped types, props and added fields.
 */
const extendPlayWagerDefinitionData = (
	src: IPlayWagerDefinitionData,
	opts?: Maybe<IExtendPlayWagerDefinitionDataOpts>
): IPlayWagerDefinitionDataExt => {
	const base = omit(src, [
		'wagerTypeId',
		'minAmount',
		'maxAmount',
		'currency',
		'required',
		'availableKeys',
		'requiresSeat',
		'requiresSpectator',
	]);

	const applyCurrencyCode = opts?.currencyCode || src.currency || '';
	const formatCurrencyOpts = opts?.formatCurrencyOpts ?? null;

	const {
		currencyCode, // Currency actually used for amounts.
		currencySymbol, // Currency symbol actually used for amounts.
	} = resolveAmounts({ amount: 0 }, { formatCurrencyOpts, currencyCode: applyCurrencyCode });

	const {
		amount: minAmount,
		amountReal: minAmountReal,
		amountMoney: minAmountMoney,
	} = resolveAmounts({ amount: Number(src.minAmount ?? 0) }, { formatCurrencyOpts, currencyCode });

	const {
		amount: maxAmount,
		amountReal: maxAmountReal,
		amountMoney: maxAmountMoney,
	} = resolveAmounts({ amount: Number(src.maxAmount ?? 0) }, { formatCurrencyOpts, currencyCode });

	return {
		...newEmptyPlayWagerDefinitionDataExt(),
		...filterNullUndefined(base),
		raw: { ...src },
		wagerId: src.wagerTypeId,
		description: src.description ?? '',
		currencyCode,
		currencySymbol,
		minAmount,
		minAmountReal,
		minAmountMoney,
		maxAmount,
		maxAmountReal,
		maxAmountMoney,
		isRequired: src.required,
		isCategory: src.isCategory ?? false,
		isSeatRequired: src.requiresSeat ?? false,
		isSpectatorRequired: src.requiresSpectator ?? false,
		categoryMemberKeys: src.availableKeys ?? [],
		wagerPeriodId: src.wagerPeriodId ?? '',
		playId: opts?.playId ?? '',
	};
};

/**
 * @returns Extended version of `IPlayWagerDefinitionData[]` with re-mapped types, props and added fields.
 */
const extendPlayWagerDefinitionDataList = (
	srcList: IPlayWagerDefinitionData[],
	opts?: Maybe<IExtendPlayWagerDefinitionDataOpts>
): IPlayWagerDefinitionDataExt[] => {
	const result: IPlayWagerDefinitionDataExt[] = [];

	for (const src of srcList) {
		result.push(extendPlayWagerDefinitionData(src, opts));
	}

	return result;
};

/**
 * @returns A lookup of `wagerId` => `IPlayWagerDefinitionDataExt` objects mapped from the specified list.
 */
const makeWagerIdToDefinitionLookupFromList = (
	list: IPlayWagerDefinitionDataExt[]
): Map<string, IPlayWagerDefinitionDataExt> => {
	const result = new Map<string, IPlayWagerDefinitionDataExt>();

	if (list.length === 0) {
		return result;
	}

	list.forEach((item) => {
		result.set(item.wagerId, item);
	});

	return result;
};

// ---- Exports -------------------------------------------------------------------------------------------------------

export { extendPlayWagerDefinitionData, extendPlayWagerDefinitionDataList };
export { newEmptyPlayWagerDefinitionData, newEmptyPlayWagerDefinitionDataExt };
export { makeWagerIdToDefinitionLookupFromList };

// Other
export * from './types/playWagerDefinitionExt';
