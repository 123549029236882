// @generated by protoc-gen-connect-es v0.11.0 with parameter "target=js+dts"
// @generated from file device.proto (package com.sands.ldx.deviceserver, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { DeviceInputRequest, GetDeviceMessagesReply, GetDeviceMessagesRequest } from "./device_pb.js";
import { Empty, MethodKind } from "@bufbuild/protobuf";

/**
 * @generated from service com.sands.ldx.deviceserver.DealingDevice
 */
export const DealingDevice = {
  typeName: "com.sands.ldx.deviceserver.DealingDevice",
  methods: {
    /**
     * A dealing device asks what requests are waiting for it. 
     *
     * @generated from rpc com.sands.ldx.deviceserver.DealingDevice.GetDeviceMessages
     */
    getDeviceMessages: {
      name: "GetDeviceMessages",
      I: GetDeviceMessagesRequest,
      O: GetDeviceMessagesReply,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc com.sands.ldx.deviceserver.DealingDevice.StreamDeviceMessages
     */
    streamDeviceMessages: {
      name: "StreamDeviceMessages",
      I: GetDeviceMessagesRequest,
      O: GetDeviceMessagesReply,
      kind: MethodKind.ServerStreaming,
    },
    /**
     * A dealing device sends an input to a play.  
     *
     * @generated from rpc com.sands.ldx.deviceserver.DealingDevice.DeviceInput
     */
    deviceInput: {
      name: "DeviceInput",
      I: DeviceInputRequest,
      O: Empty,
      kind: MethodKind.Unary,
    },
  }
};

