import { action, AnnotationsMap, computed, makeObservable, observable } from 'mobx';
import { DataStoreMobXAnnotations } from '../DataStore/mobx';
import PlayStore from './PlayStore';
import { PlayStoreMobXPrivateProps } from './types.mobx';

/**
 * MobX annotations for `PlayStore`.
 */
const PlayStoreMobXAnnotations: AnnotationsMap<PlayStore, PlayStoreMobXPrivateProps> = {
	// Inherit from DataStore
	...DataStoreMobXAnnotations,

	// ---- Observed -----------------------------------------------------

	_externalDataDeps: observable.struct,

	// ---- Computed -----------------------------------------------------

	// >> Public
	activeChoices: computed,
	activeWagers: computed,
	activeWagersTotal: computed,
	availableChoiceIdsByContext: computed,
	availableWagerIdsByContext: computed,
	choiceDefinitions: computed,
	choicePeriods: computed,
	externalDataDeps: computed,
	genericGameState: computed,
	hasActiveWagers: computed,
	hasAvailableChoices: computed,
	hasAvailableWagers: computed,
	hasPendingWagers: computed,
	hasRejectedWagers: computed,
	hasResolvedWagers: computed,
	id: computed,
	pendingWagers: computed,
	playerDecisionState: computed,
	playerPlayState: computed,
	playerState: computed,
	playId: computed,
	playState: computed,
	rejectedWagers: computed,
	resolvedWagers: computed,
	resolvedWagersPayoutsTotal: computed,
	seatCount: computed,
	seats: computed,
	seatSettings: computed,
	state: computed,
	tableId: computed,
	tableState: computed,
	wagerDefinitions: computed,
	wagerPeriods: computed,

	// ---- Actions ------------------------------------------------------

	// >> Public
	populateFromTableReply: action,

	// >> Protected
	fetchPopulateData: action,
	updateExternalDataDeps: action,

	// ---- Excluded -----------------------------------------------------
	// NONE YET
};

/**
 * Makes the specified `PlayStore` instance an observable MobX object.
 */
const bindPlayStoreMobX = (instance: PlayStore): boolean => {
	if (instance.isMobXBound) {
		return false;
	}

	makeObservable<PlayStore, PlayStoreMobXPrivateProps>(instance, {
		...PlayStoreMobXAnnotations,
	});

	instance.isMobXBound = true;

	return true;
};

// ---- Exports -------------------------------------------------------------------------------------------------------

export { PlayStoreMobXAnnotations, bindPlayStoreMobX };
