import isEqual from 'lodash/isEqual';
import { IReactionDisposer, reaction } from 'mobx';
import { SessionProvider } from '../../client/core/AuthSession';
import { StreamManager } from '../../client/core/StreamManager';
import { IBalanceData, IPlayData } from '../../client/rpc';
import { GameDevService } from '../../client/service';
import { PlayDataStreamRequestProps, TableDataStreamRequestProps } from '../../client/stream/types';
import { EventDispatcherBase } from '../../common';
import { ConfigAdapterLdx, filterNullUndefined } from '../../helpers';
import { IWagerManagerOpts } from '../../managers/types';
import { IPlayStore, IPlayStoreOpts, PlayStore } from '../../store';
import { IClientRpcSdkActions, newActions } from '../actions';
import { ClientRpcSdkSessionAuth, IClientRpcSdkSessionAuth } from '../lib/ClientRpcSdkSessionAuth';
import { IManagers, IManagersOpts, Managers } from '../managers';
import { IClientRpcSdkSelectors, newSelectors } from '../selectors';
import { IServices, IServicesConfig, IServicesOpts, Services } from '../services';
import { IStoreManager, IStoreManagerOpts, StoreManager } from '../stores';
import { StoreKey } from '../stores';
import {
	IStreams,
	IStreamsConfig,
	IStreamsOpts,
	IStreamSubscriptions,
	IStreamSubscriptionsOpts,
	StreamKey,
	Streams,
	StreamSubscriptions,
} from '../streams';
import { SdkEvents, SdkGameKey } from './constants';
import {
	IClientRpcSdk,
	IClientRpcSdkConfig,
	IClientRpcSdkManagersNs,
	IClientRpcSdkOpts,
	IClientRpcSdkServicesNs,
	IClientRpcSdkSessionsNs,
	IClientRpcSdkState,
	IClientRpcSdkStoresNs,
	IClientRpcSdkStreamsNs,
	IMethodSetActivePlayerOpts,
	IMethodSetActivePlayOpts,
	IMethodSetActiveTableOpts,
} from './types';

/**
 * Provides a high level interface for working with the RPC library and SDK features. Intended to be used either
 * directly or as a base for game specific SDKs.
 */
class ClientRpcSdk<
		PlayStoreType extends IPlayStore = PlayStore,
		PlayStoreOptsType extends IPlayStoreOpts = IPlayStoreOpts
	>
	extends EventDispatcherBase
	implements IClientRpcSdk<PlayStoreType, PlayStoreOptsType>
{
	/* #region ---- Properties --------------------------------------------------------------------------------------- */

	/**
	 * Configuration values for the SDK.
	 */
	protected _config!: IClientRpcSdkConfig;

	/**
	 * SDK `Session Auth` instance.
	 */
	protected _sessionAuth!: IClientRpcSdkSessionAuth;

	/**
	 * SDK `Services` instance (aka "SDK Services Container").
	 */
	protected _services!: IServices;

	/**
	 * SDK `Streams` instance (aka "SDK Streams Container")
	 */
	protected _streams!: IStreams;

	/**
	 * SDK `StoreManager` instance
	 */
	protected _storeManager!: IStoreManager<PlayStoreType, PlayStoreOptsType>;

	/**
	 * SDK `Managers` instance (aka "SDK Manager Container")
	 */
	protected _managers!: IManagers;

	/**
	 * SDK `StreamSubscriptions` instance (ie. listens to stream events and does various things)
	 */
	protected _streamSubscriptions!: IStreamSubscriptions;

	/**
	 * SDK state data.
	 */
	protected _state: IClientRpcSdkState = ClientRpcSdk.defaultState();

	/**
	 * Available SDK 'Actions' instance.
	 */
	protected _actions!: IClientRpcSdkActions;

	/**
	 * Available SDK 'Actions' instance.
	 */
	protected _selectors!: IClientRpcSdkSelectors;

	/**
	 * Keeps track of mobx reaction disposers by target.
	 */
	protected _mobxReactionDisposersByTarget: { [key: string]: IReactionDisposer[] } = {
		[StoreKey.TableStore]: [],
		[StoreKey.PlayStore]: [],
		[StoreKey.UserStore]: [],
	};

	/* #endregion ---- Properties ------------------------------------------------------------------------------------ */

	/* #region ---- CONSTRUCTOR ---------------------------------------------------------------------------------------*/

	constructor(config: IClientRpcSdkConfig, noInit?: Maybe<boolean>) {
		super();

		this.setConfig(config);

		this._sessionAuth = this.newSessionAuth();

		noInit = noInit ?? false;
		!noInit && this.init();
	}

	/* #endregion ---- CONSTRUCTOR ------------------------------------------------------------------------------------*/

	/* #region ---- Public ------------------------------------------------------------------------------------------- */

	/* #region ::PUBLIC::Initialize:: */

	public init() {
		if (this._state.isInitialized) {
			return;
		}

		this.info(`Initializing SDK`, 'init', { config: this._config, appConfig: this._config.appConfig.resolvedProps });

		// Initialize SDK `Session Auth` - using any configured values
		this.initSessionAuth();

		// Initialize SDK `Services` instance (aka "SDK Services Container")
		this.initServices();

		// Initialize SDK `Streams` instance (aka "SDK Streams Container")
		this.initStreams();

		// Initialize SDK `StoreManager` instance
		this.initStoreManager();

		// Initialize SDK `Managers` instance (aka "SDK Manager Container")
		this.initManagers();

		// Initialize SDK `StreamSubscriptions` instance.
		this.initStreamSubscriptions();

		// Initialize data workflow events & reactions
		this.initDataWorkflowEvents();

		// Initialize SDK `Actions` instance.
		this.initActions();

		// Initialize SDK `Selectors` instance.
		this.initSelectors();

		// Update state
		this.updateState({ isInitialized: true });

		// Run the post-login process which will set the active player, populate user store, etc.
		this.postLogin();
	}

	/* #endregion ::PUBLIC::Initialize:: */

	/**
	 * Gets whether or not the SDK has been initialized.
	 */
	public get isInitialized(): boolean {
		return this._state.isInitialized;
	}

	/**
	 * Gets a the config used for this SDK instance.
	 */
	public get sdkConfig(): IClientRpcSdkConfig {
		return this._config;
	}

	/**
	 * Gets the app config adapter used for this SDK instance.
	 */
	public get appConfig(): ConfigAdapterLdx {
		return this._config.appConfig;
	}

	/**
	 * Gets/sets the game key used by the SDK
	 */
	public get gameKey(): string {
		return this._config.gameKey;
	}
	public set gameKey(val: string) {
		this.setGameKey(val);
	}
	// Setter method (we may override this when sub-classing)
	protected setGameKey(val: string) {
		if (val === this._config.gameKey) {
			return;
		}

		const prev = this._config.gameKey;
		this._config.gameKey = val;

		this.onGameKeyChanged(val, prev);
	}

	/**
	 * Get the active logged-in player ID.
	 */
	public get playerId(): string {
		return this._state.playerId;
	}

	/**
	 * Get/sets the active table ID.
	 */
	public get tableId(): string {
		return this._state.tableId;
	}
	public set tableId(val: string) {
		this.setTableId(val);
	}
	// Setter method
	protected setTableId(val: string) {
		if (val === this._state.tableId) {
			return;
		}

		if (this.isInitialized) {
			this.setActiveTable(val);
		} else {
			this.warn(`Setting table ID before SDK is initialized is not supported`, 'setTableId');
		}
	}

	/**
	 * Get/sets the active play ID.
	 */
	public get playId(): string {
		return this._state.playId;
	}
	public set playId(val: string) {
		this.setPlayId(val);
	}
	// Setter method
	protected setPlayId(val: string) {
		if (val === this._state.playId) {
			return;
		}

		if (this.isInitialized) {
			this.setActivePlay(val);
		} else {
			this.warn(`Setting play ID before SDK is initialized is not supported`, 'setPlayId');
		}
	}

	/**
	 * Gets whether or not the SDK is logged in.
	 */
	public get isLoggedIn(): boolean {
		return this._sessionAuth.isLoggedIn;
	}

	/**
	 * Groups functionality related to sessions.
	 */
	public get sessions(): IClientRpcSdkSessionsNs {
		return Object.freeze({
			// User session instance
			user: this._sessionAuth.userSession,
			// Omnibus session instance
			omnibus: this._sessionAuth.omnibusSession,
			// SDK `SessionAuth` instance
			getControllerObj: () => this._sessionAuth,
		});
	}

	/**
	 * Groups functionality related to services.
	 */
	public get services(): IClientRpcSdkServicesNs {
		return Object.freeze({
			// Method returns a key-value pair of all current service instances.
			getServices: () => this._services.getServices(),
			// SDK `Services` instance (aka "SDK Services Container").
			getControllerObj: () => this._services,
			// All service instances as named props
			...this._services.getServices(),
		});
	}

	/**
	 * Groups functionality related to streams.
	 */
	public get streams(): IClientRpcSdkStreamsNs {
		return Object.freeze({
			// The SDK `StreamManager` instance
			getStreamManager: () => this._streams.streamManager,
			// Method returns a key-value pair of all current stream instances.
			getStreams: () => this._streams.getStreams(),
			// SDK `Streams` instance (aka "SDK Streams Container")
			getControllerObj: () => this._streams,
		});
	}

	/**
	 * Groups functionality related to stores.
	 */
	public get stores(): IClientRpcSdkStoresNs<PlayStoreType> {
		return Object.freeze({
			// Method returns a key-value pair of all current store instances.
			getStores: () => this._storeManager.getStores(),
			// SDK `StoreManager` instance
			getControllerObj: () => this._storeManager,
			// Replace the current PlayStore instance with the specified one.
			setPlayStore: (playStore: PlayStoreType, copyData?: Maybe<boolean>) => this.setPlayStore(playStore, copyData),
			// All store instances as named props
			...this._storeManager.getStores(),
		});
	}

	/**
	 * Groups functionality related to managers.
	 */
	public get managers(): IClientRpcSdkManagersNs {
		return Object.freeze({
			// Method returns a key-value pair of all current manager instances.
			getManagers: () => this._managers.getManagers(),
			// SDK `Managers` instance (aka "SDK Manager Container")
			getControllerObj: () => this._managers,
			// All manager instances as named props
			...this._managers.getManagers(),
		});
	}

	/**
	 * Actions exposed by the SDK.
	 */
	public get actions(): IClientRpcSdkActions {
		return this._actions;
	}

	/**
	 * Selectors exposed by the SDK.
	 */
	public get selectors(): IClientRpcSdkSelectors {
		return this._selectors;
	}

	/**
	 * Logs the specified user in - and sets the active player.
	 */
	public async login(
		email: string,
		opts?: Maybe<{ displayName?: Maybe<string>; operatorLabel?: Maybe<string> }>
	): Promise<boolean> {
		await this._sessionAuth.login(email, opts);

		return this.postLogin();
	}

	protected async postLogin(): Promise<boolean> {
		const userAuthData = this._sessionAuth.userLoginData;

		// Not logged-in
		if (!userAuthData.isLoggedIn) {
			// Unset any active player
			await this.setActivePlayer('');
			return false;
		}

		let authPlayerId = userAuthData.login.playerId;

		// Not having a playerId can happen if the session is authenticated via just a token
		if (authPlayerId === '') {
			// This will call `GetSelf` to fetch the current user data associated with the token
			await this.populateUserStore();

			const { userStore } = this.stores.getStores();
			authPlayerId = userStore.playerId;
			userAuthData.login.playerId = authPlayerId;
		}

		// Set the active player
		authPlayerId != '' && (await this.setActivePlayer(authPlayerId));

		// Only return true if an authenticated player ID is set
		return authPlayerId != '';
	}

	/**
	 * Logout the current active user.
	 */
	public logout(cleanUp?: Maybe<boolean>) {
		cleanUp = cleanUp ?? true;

		if (this._sessionAuth.isLoggedIn) {
			this._sessionAuth.logout();
		}

		if (cleanUp) {
			this.setActivePlayer('');
			this._streams.streamManager.stopAll();
		}
	}

	/**
	 * @returns TRUE if the specified game key is a multi-seat game.
	 */
	public isMultiSeatGame(opts?: Maybe<{ gameKey?: Maybe<string> }>): boolean {
		const gameKey = opts?.gameKey ?? this.gameKey;

		return [SdkGameKey.MSBJ].includes(gameKey as SdkGameKey);
	}

	/* #endregion ---- Public ---------------------------------------------------------------------------------------- */

	/* #region ---- Protected ---------------------------------------------------------------------------------------- */

	/**
	 * Sets/initializes the class config.
	 */
	protected setConfig(config: IClientRpcSdkConfig) {
		const { newConfig, origConfig } = this.resolveConfig(config);
		this._config = newConfig;

		super.setOptions(newConfig); // Set the options for the parent event dispatcher class
		this.onSetConfig(newConfig, origConfig);
	}

	/**
	 * @returns Config object with overrides.
	 */
	protected resolveConfig(config: IClientRpcSdkConfig) {
		const origConfig: IClientRpcSdkConfig = {
			...ClientRpcSdk.defaultOptions(),
			...{ gameKey: '' },
			...this._config,
		};

		const newConfig: IClientRpcSdkConfig = {
			...origConfig,
			...config,
		};

		return { origConfig, newConfig };
	}

	/**
	 * Called AFTER new config is set.
	 *
	 * - Extends the base class method.
	 */
	protected onSetConfig(newConfig: IClientRpcSdkConfig, origConfig: IClientRpcSdkConfig) {
		if (newConfig.gameKey !== origConfig.gameKey) {
			this.onGameKeyChanged(newConfig.gameKey, origConfig.gameKey);
		}
	}

	/**
	 * Called after the game key is changed.
	 */
	protected onGameKeyChanged(gameKey: string, _prevGameKey: string) {
		if (!this.isInitialized) {
			return;
		}
		if (gameKey === '') {
			return;
		}

		const { wagerManager } = this.managers;
		this.isMultiSeatGame({ gameKey }) && (wagerManager.isSingleSeatTable = false);
	}

	/**
	 * @returns Gets a functional user session provider to pass down to other SDK layers.
	 */
	protected appliedUserSession(): SessionProvider {
		return () => this._sessionAuth.userSession;
	}

	/**
	 * @returns Gets a functional omnibus session provider to pass to other SDK layers.
	 */
	protected appliedOmnibusSession(): SessionProvider {
		return () => this._sessionAuth.omnibusSession;
	}

	/**
	 * Initialize SDK state data.
	 */
	protected initState() {
		this._state = ClientRpcSdk.defaultState();
	}

	/**
	 * Initialize the `ClientRpcSdkSessionAuth` auth instance used for session management.
	 */
	protected initSessionAuth() {
		const sessionAuth = this._sessionAuth;

		if (sessionAuth.isLoggedIn) {
			return;
		}

		const appConfig = this.appConfig;
		const appConfigAuth = { email: appConfig.userName ?? '' };
		const userAuth = this._config.userAuth ?? null;
		const userAuthEmail = userAuth?.email ?? '';

		if (userAuthEmail !== '') {
			sessionAuth.login(userAuthEmail, {
				displayName: userAuth?.displayName,
				operatorLabel: userAuth?.operatorLabel,
			});
		} else if (appConfigAuth.email !== '') {
			sessionAuth.login(appConfigAuth.email);
		}
	}

	/**
	 * Initialize the SDK `Services` instance (aka "SDK Services Container").
	 */
	protected initServices() {
		const debugMethod = 'initServices';

		if (this._services != null) {
			this.warn(`Services have already been initialized`, debugMethod);
			return;
		}

		let instance: IServices;

		const config = this._config.services ?? {};
		if (config.instance != null) {
			const userSession = this.appliedUserSession();
			const omnibusSession = this.appliedOmnibusSession();

			instance = config.instance;
			instance.isDebugEnabled = this.isDebugEnabled;
			instance.userSession = userSession;
			instance.omnibusSession = omnibusSession;
		} else {
			const opts: Nullable<IServicesOpts> = config.opts ?? null;
			instance = this.newServices(opts);
		}

		this._services = instance;
	}

	/**
	 * Initialize the SDK `Streams` instance (aka "SDK Streams Container").
	 */
	protected initStreams() {
		const debugMethod = 'initStreams';

		if (this._streams != null) {
			this.warn(`Streams have already been initialized`, debugMethod);
			return;
		}

		let instance: IStreams;

		const config = this._config.streams ?? {};
		if (config.instance != null) {
			const userSession = this.appliedUserSession();
			const omnibusSession = this.appliedOmnibusSession();

			instance = config.instance;
			instance.isDebugEnabled = this.isDebugEnabled;
			instance.userSession = userSession;
			instance.omnibusSession = omnibusSession;
		} else {
			const opts: Nullable<IStreamsOpts> = config.opts ?? null;
			instance = this.newStreams(opts);
		}

		this._streams = instance;
	}

	/**
	 * Initialize the SDK `StoreManager` instance.
	 *
	 * TODO: Finish this method so it correctly handles the different play store type.
	 */
	protected initStoreManager() {
		const debugMethod = 'initStoreManager';

		if (this._storeManager != null) {
			this.warn(`Store manager has already been initialized`, debugMethod);
			return;
		}

		const config = this._config.stores ?? {};
		const instance = this.newStoreManager((config.opts ?? {}) as PlayStoreOptsType);

		this._storeManager = instance;
	}

	/**
	 * Initialize the SDK `Managers` instance (aka "SDK Manager Container")
	 */
	protected initManagers() {
		const debugMethod = 'initManagers';

		if (this._managers != null) {
			this.warn(`Managers have already been initialized`, debugMethod);
			return;
		}

		let instance: IManagers;

		const config = this._config.managers ?? {};
		if (config.instance != null) {
			instance = config.instance;
			instance.isDebugEnabled = this.isDebugEnabled;
			instance.wagerManager.isSingleSeatTable = !this.isMultiSeatGame();
		} else {
			const opts: Nullable<IManagersOpts> = config.opts ?? null;
			instance = this.newManagers(opts);
		}

		this._managers = instance;
	}

	/**
	 * Initialize the SDK `StreamSubscriptions` instance.
	 */
	protected initStreamSubscriptions() {
		const debugMethod = 'initStreamSubscriptions';

		if (this._streamSubscriptions != null) {
			this.warn(`Stream subscriptions have already been initialized`, debugMethod);
			return;
		}

		let instance: IStreamSubscriptions;

		const config = this._config.subscriptions ?? {};
		if (config.instance != null) {
			instance = config.instance;
			instance.isDebugEnabled = this.isDebugEnabled;
		} else {
			const opts: Nullable<IStreamSubscriptionsOpts> = config.opts ?? null;
			instance = this.newStreamSubscriptions(opts);
		}

		instance.subscribe(this._storeManager, config.extenders);

		this._streamSubscriptions = instance;
	}

	/**
	 * Initialize the SDK `Actions` instance.
	 */
	protected initActions() {
		const debugMethod = 'initActions';

		if (this._actions != null) {
			this.warn(`Actions have already been initialized`, debugMethod);
			return;
		}

		const instance: IClientRpcSdkActions = this.newActions();

		this._actions = instance;
	}

	/**
	 * Initialize the SDK `Selectors` instance.
	 */
	protected initSelectors() {
		const debugMethod = 'initSelectors';

		if (this._selectors != null) {
			this.warn(`Selectors have already been initialized`, debugMethod);
			return;
		}

		const instance: IClientRpcSdkSelectors = this.newSelectors();

		this._selectors = instance;
	}

	/**
	 * Initialize data workflow events and reactions
	 */
	protected initDataWorkflowEvents() {
		this.initTableStoreWorkflowEvents();
		this.initPlayStoreWorkflowEvents();
		this.initUserStoreWorkflowEvents();
		this.initTriggeredWorkflowEvents();
	}

	/**
	 * Initialize table store workflow events.
	 * TODO: Make this better once we have time.
	 */
	protected initTableStoreWorkflowEvents() {
		const { tableStore } = this.stores.getStores();

		const disposers = this._mobxReactionDisposersByTarget[StoreKey.TableStore];

		// Table play ID change
		disposers.push(
			reaction(
				() => tableStore.playId,
				(playId: string, prevPlayId: string) => {
					const { tableStore } = this.stores.getStores();

					this.info(`Play ID change: '${prevPlayId}' --> '${playId}'`, 'TableStore.Reaction');

					const tableId = tableStore.tableId;
					const activePlayId = playId;

					// const previousPlayId = prevPlayId || tableStore.previousPlayId;
					// const activePlayId = playId || previousPlayId;
					// if (activePlayId === '') {
					// 	return;
					// }

					this.setActivePlay(activePlayId, { tableId });
				}
			)
		);

		// Table data changed
		disposers.push(
			reaction(
				() => tableStore.lastUpdatedTs,
				(newTs: number, prevTs: number) => {
					const { tableStore } = this.stores.getStores();

					this.info(`Table data update: TS '${prevTs}' --> '${newTs}'`, 'TableStore.Reaction');

					if (tableStore.data == null) {
						return;
					}

					const { wagerManager } = this.managers.getManagers();

					// Send the table data to the wager manager
					wagerManager.setTableData(tableStore.data);
				}
			)
		);
	}

	/**
	 * Initialize play store workflow events.
	 */
	protected initPlayStoreWorkflowEvents(playStoreInstance?: Maybe<IPlayStore>) {
		const playStore = playStoreInstance ?? this.stores.getStores().playStore;

		const disposers = this._mobxReactionDisposersByTarget[StoreKey.PlayStore];

		// Play data changed
		// TODO: I'd like to declare and use a playStore.on("DATA_UPDATED") type of event at some point rather than
		// use MobX in this manner
		disposers.push(
			reaction(
				() => playStore.lastUpdatedTs,
				(newTs: number, prevTs: number) => {
					const { playStore } = this.stores.getStores();

					this.info(`Play data update: TS '${prevTs}' --> '${newTs}'`, 'PlayStore.Reaction', {
						instanceId: playStore.instanceId,
					});

					if (playStore.data == null) {
						return;
					}

					const { wagerManager, resolutionManager, choiceManager } = this.managers.getManagers();

					// Set the resolutions for the resolution manager
					resolutionManager.setResolvedWagers(playStore.resolvedWagers);

					// Send the play data to the wager manager
					wagerManager.setPlayData(playStore.data);

					// Send the play data to the choice manager
					choiceManager.setPlayData(playStore.data);
				}
			)
		);
	}

	/**
	 * Initialize user store reactions.
	 * TODO: Make this better once we have time.
	 */
	protected initUserStoreWorkflowEvents() {
		const { userStore } = this.stores.getStores();

		const disposers = this._mobxReactionDisposersByTarget[StoreKey.UserStore];

		// TODO: I'd like to declare and use a userStore.on("BALANCE_DATA_CHANGED") type of event at some point
		// rather than use MobX in this manner.
		disposers.push(
			reaction(
				() => userStore.balancesList,
				(value: IBalanceData[], prev: IBalanceData[]) => {
					this.info(`Player balances changed`, 'UserStore.Reaction', { value, prev });

					const { walletManager } = this.managers.getManagers();
					walletManager.setServerBalances(value);
				}
			)
		);
	}

	/**
	 * Initialize event listeners for SDK triggered events.
	 */
	protected initTriggeredWorkflowEvents() {
		// Set active player
		this.on(SdkEvents.SET_ACTIVE_PLAYER, (props: PlainObject) => {
			const playerId = props.newPlayerId as string;
			const prevPlayerId = props.origPlayerId as string;

			this.info(`SET_ACTIVE_PLAYER: '${prevPlayerId}' --> '${playerId}'`, 'SdkEvents');

			const { wagerManager, walletManager } = this.managers;
			walletManager.playerId = playerId;
			wagerManager.playerId = playerId;
		});

		// Set active table
		this.on(SdkEvents.SET_ACTIVE_TABLE, (props: PlainObject) => {
			const tableId = props.newTableId as string;
			const prevTableId = props.origTableId as string;

			this.info(`SET_ACTIVE_TABLE: '${prevTableId}' --> '${tableId}'`, 'SdkEvents');

			const { tableStore } = this.stores;
			const { wagerManager, choiceManager } = this.managers;

			// Send the table data to the wager manager
			const tableData = tableStore.data ?? null;

			if (tableId === '') {
				// Clean-up the table-related data in the wager manager
				wagerManager.tableId = '';
				choiceManager.tableId = '';
			} else {
				wagerManager.setTableData(tableData);
			}
		});

		// Set active play
		this.on(SdkEvents.SET_ACTIVE_PLAY, (props: PlainObject) => {
			const playId = props.newPlayId as string;
			const prevPlayId = props.origPlayId as string;

			this.info(`SET_ACTIVE_PLAY: '${prevPlayId}' --> '${playId}'`, 'SdkEvents');

			const { playStore } = this.stores;
			const { wagerManager, choiceManager } = this.managers;

			// Send the table data to the wager manager
			const playData = playStore.data ?? null;
			if (playId === '') {
				// Clean-up the play-related data in the wager manager
				wagerManager.playId = '';
				choiceManager.playId = '';
			} else {
				wagerManager.setPlayData(playData);
				choiceManager.setPlayData(playData);
			}
		});
	}

	/**
	 * Runs all registered mobx reaction disposers for the specified target.
	 */
	protected mobxReactionsCleanup(target: string) {
		const validTargets = Object.keys(this._mobxReactionDisposersByTarget);
		if (!validTargets.includes(target)) {
			return;
		}
		const disposers = this._mobxReactionDisposersByTarget[target] ?? null;
		if (disposers == null) {
			return;
		}

		disposers.forEach((disposer) => disposer());
		disposers.length = 0;
		this._mobxReactionDisposersByTarget[target] = [];
	}

	/**
	 * @returns A new SDK `ClientRpcSdkSessionAuth` instance that is correctly configured for use with this SDK instance.
	 */
	protected newSessionAuth(): IClientRpcSdkSessionAuth {
		const getOmnibusTokenFn = (): string => this._config.appConfig.omnibusAuthToken;
		const userLoginDevService = new GameDevService(this._config.appConfig.devServerURI);

		const sessionAuth = new ClientRpcSdkSessionAuth(userLoginDevService, {
			userSession: this._config.userSession ?? null,
			omnibusTokenAccessorFn: getOmnibusTokenFn,
		});

		return sessionAuth;
	}

	/**
	 * @returns A new SDK `Services` instance that is correctly configured for use with this SDK instance.
	 */
	protected newServices(opts?: Maybe<IServicesOpts>): IServices {
		const userSession = this.appliedUserSession();
		const omnibusSession = this.appliedOmnibusSession();
		const isDebugEnabled = opts?.isDebugEnabled ?? this.isDebugEnabled;

		const servicesOpts: IServicesOpts = {
			...opts,
			init: true,
			isDebugEnabled,
			omnibusSession,
		};

		const servicesConfig: IServicesConfig = this.newServicesConfig();

		return new Services(userSession, servicesConfig, servicesOpts);
	}

	/**
	 * @returns A new SDK `Streams` instance that is correctly configured for use with this SDK instance.
	 */
	protected newStreams(opts?: Maybe<IStreamsOpts>): IStreams {
		const userSession = this.appliedUserSession();
		const omnibusSession = this.appliedOmnibusSession();
		const isDebugEnabled = opts?.isDebugEnabled ?? this.isDebugEnabled;

		const streamsOpts: IStreamsOpts = {
			...opts,
			init: true,
			isDebugEnabled,
			omnibusSession,
		};

		const streamsConfig: IStreamsConfig = this.newStreamsConfig();
		const streamManager = new StreamManager();

		return new Streams(streamManager, userSession, streamsConfig, streamsOpts);
	}

	/**
	 * @returns A new SDK `StoreManager` instance that is correctly configured for use with this SDK instance.
	 */
	protected newStoreManager(
		opts?: Maybe<IStoreManagerOpts<PlayStoreType, PlayStoreOptsType>>
	): IStoreManager<PlayStoreType, PlayStoreOptsType> {
		const isDebugEnabled = opts?.isDebugEnabled ?? this.isDebugEnabled;

		const storeManagerOpts: IStoreManagerOpts<PlayStoreType, PlayStoreOptsType> = {
			...opts,
			isDebugEnabled,
			init: true,
		};

		return new StoreManager<PlayStoreType, PlayStoreOptsType>(this._services, storeManagerOpts);
	}

	/**
	 * @returns A new SDK `Managers` instance that is correctly configured for use with this SDK instance.
	 */
	protected newManagers(opts?: Maybe<IManagersOpts>): IManagers {
		const wagerManagerOpts: IWagerManagerOpts = {
			useLocalWagersOnly: false,
			isSingleSeatTable: !this.isMultiSeatGame(),
		};

		const isDebugEnabled = opts?.isDebugEnabled ?? this.isDebugEnabled;

		const managersOpts: IManagersOpts = {
			...opts,
			isDebugEnabled,
			wagerManager: { opts: { managerOpts: wagerManagerOpts } },

			// TODO: Remove when done testing wager manager
			choiceManager: { opts: { managerOpts: { isDebugEnabled: false } } },
		};

		return new Managers(this._services, managersOpts);
	}

	/**
	 * @returns A new SDK `StreamSubscriptions` instance that is correctly configured for use with this SDK instance.
	 */
	protected newStreamSubscriptions(opts?: Maybe<IStreamSubscriptionsOpts>): IStreamSubscriptions {
		const isDebugEnabled = opts?.isDebugEnabled ?? this.isDebugEnabled;

		const streamSubscriptionsOpts: IStreamSubscriptionsOpts = {
			...opts,
			isDebugEnabled,
		};

		return new StreamSubscriptions(this._streams.streamManager, streamSubscriptionsOpts);
	}

	/**
	 * @returns A new SDK `Actions` instance that is correctly configured for use with this SDK instance.
	 */
	protected newActions(): IClientRpcSdkActions {
		return newActions(this);
	}

	/**
	 * @returns A new SDK `Selectors` instance that is correctly configured for use with this SDK instance.
	 */
	protected newSelectors(): IClientRpcSdkSelectors {
		return newSelectors(this);
	}

	/**
	 * Updates the SDK state object.
	 */
	protected updateState(props: Partial<IClientRpcSdkState>) {
		const currentState = this._state;
		const newState = { ...currentState, ...filterNullUndefined(props) };
		this._state = newState;
	}

	/**
	 * Sets the SDK active player - which populates the user store and optionally starts the user stream.
	 */
	protected setActivePlayer = async (playerId: string, opts?: Maybe<IMethodSetActivePlayerOpts>): Promise<boolean> => {
		const debugMethod = 'setActivePlayer';

		const { userStore } = this.stores.getStores();
		const streamManager = this._streams.streamManager;

		opts = opts ?? {};
		const currentPlayerId = this.playerId;
		if (playerId === currentPlayerId) {
			return false;
		}

		// What to do when we unset the active player
		const onUnsetPlayer = () => {
			streamManager.stop(StreamKey.UserStream);
			userStore.clear();
			this.updateState({ playerId: '' });
			this.trigger(SdkEvents.SET_ACTIVE_PLAYER, { origPlayerId: currentPlayerId, newPlayerId: '' });

			// Also unset the active table
			this.setActiveTable('');
		};

		// Unsetting the active SDK player ID - note this is NOT the same as logging out
		if (playerId === '') {
			onUnsetPlayer();
			return true;
		}

		const restartStream = true;

		// Re-populate the user store (if necessary)
		if (userStore.playerId !== playerId) {
			// debug.info(`Attempting to populate user store for player ID '${playerId}'`, debugMethod);
			await userStore.populate();

			// Check to ensure the user store was populated correctly..
			if (!userStore.isPopulated || userStore.playerId !== playerId) {
				this.warn(`Unable to populate user store for player ID '${playerId}'`, debugMethod);
				onUnsetPlayer();
				return false;
			}
		}

		this.updateState({ playerId });
		this.trigger(SdkEvents.SET_ACTIVE_PLAYER, { origPlayerId: currentPlayerId, newPlayerId: playerId });

		// Start the user stream (if necessary)
		const streamKey = StreamKey.UserStream;
		const startStream = opts.startUserStream ?? true;

		if (startStream && streamManager.isEnabled(streamKey)) {
			const isStarted = streamManager.isActive(streamKey);

			if (!isStarted) {
				this.info(`Attempting to start the user stream for player ID '${playerId}'`, debugMethod);
				streamManager.start(streamKey);
			} else if (restartStream) {
				this.info(`Attempting to re-start the user stream for player ID '${playerId}'`, debugMethod);
				streamManager.restart(streamKey);
			}
		}

		return true;
	};

	/**
	 * Sets the SDK active table - which populates the table store and optionally starts the table stream.
	 */
	protected setActiveTable = async (tableId: string, opts?: Maybe<IMethodSetActiveTableOpts>): Promise<boolean> => {
		const debugMethod = 'setActiveTable';

		const streamManager = this._streams.streamManager;
		const { tableStore } = this.stores.getStores();

		opts = opts ?? {};
		const currentTableId = this.tableId;
		if (tableId === currentTableId) {
			return false;
		}

		// What to do when we unset the active table
		const onUnsetTable = () => {
			streamManager.stop(StreamKey.TableStream);
			tableStore.clear();
			this.updateState({ tableId: '' });
			this.trigger(SdkEvents.SET_ACTIVE_TABLE, { origTableId: currentTableId, newTableId: '' });

			// Also unset the active play
			this.setActivePlay('');
		};

		// Unsetting the SDK active table
		if (tableId === '') {
			onUnsetTable();
			return true;
		}

		const restartStream = true;

		// Populate the table store (if necessary)
		if (tableStore.tableId !== tableId) {
			// debug.info(`Attempting to populate table store for table ID '${tableId}'`, debugMethod);
			await tableStore.populate(tableId);

			if (!tableStore.isPopulated || tableStore.tableId !== tableId) {
				this.warn(`Unable to populate table store using table ID '${tableId}'. Is the table ID valid?`, debugMethod);
				onUnsetTable();
				return false;
			}
		}

		this.updateState({ tableId });
		this.trigger(SdkEvents.SET_ACTIVE_TABLE, { origTableId: currentTableId, newTableId: tableId });

		// Start the play stream (if necessary)
		const streamKey = StreamKey.TableStream;
		const startStream = opts.startTableStream ?? true;

		if (startStream && streamManager.isEnabled(streamKey)) {
			const isStarted = streamManager.isActive(streamKey);

			const streamProps: TableDataStreamRequestProps = {
				includePlayConfig: true,
				includePlay: true,
				...opts?.streamProps,
				tableId,
			};

			if (!isStarted) {
				this.info(`Attempting to start the table stream for table ID '${tableId}'`, debugMethod, { streamProps });
				streamManager.start(streamKey, streamProps);
			} else if (restartStream) {
				this.info(`Attempting to re-start the table stream for table ID '${tableId}'`, debugMethod, { streamProps });
				streamManager.restart(streamKey, streamProps);
			}
		}

		return true;
	};

	/**
	 * Sets the SDK active play - which populates the play store and optionally starts the play stream.
	 */
	protected setActivePlay = async (playId: string, opts?: Maybe<IMethodSetActivePlayOpts>): Promise<boolean> => {
		const debugMethod = 'setActivePlay';

		const streamManager = this._streams.streamManager;
		const { playStore } = this.stores.getStores();

		opts = opts ?? {};
		const currentPlayId = this.playId;
		if (playId === currentPlayId) {
			return false;
		}

		const tableId = opts?.tableId || this.tableId;

		// What to do when we unset the active play
		const onUnsetPlay = () => {
			streamManager.stop(StreamKey.PlayStream);
			playStore.clear();
			this.updateState({ playId: '' });
			this.trigger(SdkEvents.SET_ACTIVE_PLAY, { origPlayId: currentPlayId, newPlayId: '' });
		};

		// Unsetting the SDK active play
		if (playId === '') {
			onUnsetPlay();
			return true;
		}

		const restartStream = true;

		// Populate the play store (if necessary)
		if (playStore.playId !== playId) {
			// debug.info(`Attempting to populate play store for play ID '${playId}' and table ID '${tableId}'`, debugMethod);
			await playStore.populate(playId, tableId);

			if (!playStore.isPopulated || playStore.playId !== playId) {
				this.warn(`Unable to populate play store using play ID '${playId}'`, debugMethod);
				onUnsetPlay();
				return false;
			}
		}

		this.updateState({ playId });
		this.trigger(SdkEvents.SET_ACTIVE_PLAY, { origPlayId: currentPlayId, newPlayId: playId });

		// Start the play stream (if necessary)
		const streamKey = StreamKey.PlayStream;
		const startStream = opts.startPlayStream ?? false;

		if (startStream && streamManager.isEnabled(streamKey)) {
			const isStarted = streamManager.isActive(streamKey);

			const streamProps: PlayDataStreamRequestProps = {
				...opts?.streamProps,
				playId,
				tableId,
			};

			if (!isStarted) {
				this.info(`Attempting to start the play stream for play ID '${playId}'`, debugMethod, { streamProps });
				streamManager.start(streamKey, streamProps);
			} else if (restartStream) {
				this.info(`Attempting to re-start the play stream for play ID '${playId}'`, debugMethod, { streamProps });
				streamManager.restart(streamKey, streamProps);
			}
		}

		return true;
	};

	protected newServicesConfig(): IServicesConfig {
		const cfg = this.appConfig;

		return {
			devServerURI: cfg.devServerURI,
			gameServerURI: cfg.gameServerURI,
			deviceServerURI: cfg.deviceServerURI,
			dealerServerURI: cfg.dealerServerURI,
		};
	}

	protected newStreamsConfig(): IStreamsConfig {
		return this.newServicesConfig() as IStreamsConfig;
	}

	protected async populateUserStore(): Promise<boolean> {
		const { userStore } = this.stores.getStores();

		return await userStore.populate();
	}

	/**
	 * Replace the current PlayStore instance with the specified one.
	 */
	protected setPlayStore(playStore: PlayStoreType, copyData?: Maybe<boolean>): void {
		copyData = copyData ?? false;

		if (playStore == null || this._storeManager == null || this._storeManager.playStore == null) {
			return;
		}

		// Don't replace the store if it's the same instance
		const oldStore = this._storeManager.playStore;
		if (playStore.instanceId === oldStore.instanceId) {
			return;
		}

		// Copy the data from the old store to the new store
		if (copyData) {
			const data: IPlayData = { ...(oldStore.data ?? {}) } as IPlayData;
			playStore.setData(data);
		}

		// Clean-up the old store
		this.mobxReactionsCleanup(StoreKey.PlayStore);
		oldStore.clear();

		// Set the new store
		playStore.service = this._services.gameService;
		this.initPlayStoreWorkflowEvents(playStore);
		this._storeManager.playStore = playStore;
	}

	/* #endregion ---- Protected ------------------------------------------------------------------------------------- */

	/* #region ---- Static ------------------------------------------------------------------------------------------- */

	/**
	 * @returns TRUE if the specified config is the same as the current config.
	 */
	public static isConfigSame(config: IClientRpcSdkConfig, config2: IClientRpcSdkConfig): boolean {
		if (config === config2) {
			return true;
		}

		return isEqual(config, config2);
	}

	/**
	 * @returns Default class options.
	 */
	public static defaultOptions(): IClientRpcSdkOpts {
		return {
			...EventDispatcherBase.defaultOptions(),
			userSession: null,
			userAuth: null,
			services: null,
			streams: null,
			managers: null,
			stores: null,
		};
	}

	/**
	 * @returns Default state.
	 */
	public static defaultState() {
		return {
			isInitialized: false,
			playerId: '',
			playId: '',
			tableId: '',
		};
	}

	/* #endregion ---- Static ---------------------------------------------------------------------------------------- */

	/* #region ---- Debug ------------------------------------------------------------------------------------------- */

	/**
	 * Overrides the parent class method.
	 *
	 * @returns A JSON export of the current data.
	 */
	public override toJson(extended?: Maybe<boolean>): PlainObject {
		extended = extended ?? false;

		const toJs = (val: unknown) => EventDispatcherBase.toJs(val, { extended });

		const result: PlainObject = {
			isInitialized: this._state.isInitialized,
			playerId: this._state.playerId,
			tableId: this._state.tableId,
			playId: this._state.playId,
			gameKey: this._config.gameKey,
			isLoggedIn: this._sessionAuth.isLoggedIn,
			userLoginData: toJs(this._sessionAuth.userLoginData),
		};

		if (extended) {
			result.extended = {
				config: toJs({ ...this._config }),
				state: toJs({ ...this._state }),
				sessionAuth: this._sessionAuth.toJson(extended),
				services: this._services.toJson(extended),
				streams: this._services.toJson(extended),
				stores: this._storeManager.toJson(extended),
				managers: this._managers.toJson(extended),
				streamSubscriptions: this._streamSubscriptions.toJson(extended),
				actions: toJs(this._actions),
				selectors: toJs(this._selectors),

				// Info from base class
				_Base: { ...super.toJson(extended) },
			};
		}

		return result;
	}

	/**
	 * Overrides the parent class property.
	 *
	 * @returns The label to use when debugging.
	 */
	protected override get debugClassLabel(): string {
		return ClientRpcSdk.debugClassLabel();
	}

	/**
	 * STATIC
	 * @returns Label assigned to this class namespace.
	 */
	protected static debugClassLabel(): string {
		return `RpcLib.Sdk.ClientRpcSdk`;
	}

	/* #endregion ---- Debug ---------------------------------------------------------------------------------------- */
}

// ---- Export --------------------------------------------------------------------------------------------------------

export { ClientRpcSdk };
