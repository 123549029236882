import { throttle } from 'lodash';
import debounce from 'lodash/debounce';
import intersect from 'lodash/intersection';
import { set } from 'mobx';
import {
	IGameService,
	IPlayData,
	IPlayerDecisionStateData,
	IPlayerStateData,
	IPlayStateData,
	ITableData,
	MakeWagersReply,
} from '../../client';
import { ClearWagersRequest, MakeWagersRequest } from '../../client/rpc/requests';
import { DebugBase } from '../../common';
import { CancelablePromiseError, entries, filterNullUndefined, generateRandomString } from '../../helpers';
import { IMethodResolveAmountsOpts, resolveAmounts } from '../../helpers/amounts';
import {
	ActiveWagers,
	extendTableConfigData,
	IActiveWagers,
	IAvailableWagerDataEntry,
	IContextAvailableWagersData,
	IPlaySeatAssignments,
	IPlaySeatData,
	ITableConfigData,
	ITableConfigDataExt,
	ITableConfigWagerDefinitions,
	ITableSeatAssignments,
	ITableSeatData,
	newEmptyContextAvailableWagersData,
	newEmptyTableConfigDataExt,
	PlaySeatAssignments,
	PlayWagerDefinitions,
	RawPlaySeatAssignmentList,
	RawPlayWagerDefinitionList,
	RawTableSeatAssignmentList,
	TableConfigWagerDefinitions,
	TableSeatAssignments,
} from '../../helpers/data';
import { AvailableWagers, IAvailableWagers } from '../../helpers/data';
import { IPlayWagerDefinitions } from '../../helpers/data';
import { generateObjectListHashId, makeActiveWagerKeyFromAvailableKey } from '../../helpers/data/utility';
import { normalizeContextId } from '../../helpers/data/utility';
import { ManagerBase } from '../lib/ManagerBase';
import { IWalletLocalBalanceDataEntry, IWalletManager, WalletManager } from '../WalletManager';
import { Events, LOCAL_QUEUED_SEQUENCE } from './constants';
import {
	BatchUpsertLocalActiveWagersList,
	ILocalActiveWagerDataEntry,
	ILocalActiveWagers,
	IMethodWagerUndoStackPushOpts,
	IServerPlayerWagerState,
	IUpdateLocalActiveWagerProps,
	IWagerUndoStack,
	LocalActiveWagers,
	ServerPlayerWagerState,
	WagerUndoStack,
} from './lib';
import { ServerWageringState } from './lib/ServerPlayerWagerState';
import {
	ILocalActiveWagersData,
	ILocalActiveWagersDataDiff,
	ILocalActiveWagersOpts,
	IMethodGetWagersOpts,
	IMethodGetWagerTotalsOpts,
	IMultiSeatPlacedWagerSet,
	IMultiSeatWagerSet,
	IMultiSeatWagerTotalsSet,
	IServerPlayerWagerStateDataEntry,
	IServerPlayerWagerStateDataRetrievers,
	IServerPlayerWagerStateOpts,
	IWagerData,
	IWagerLimits,
	IWagerManager,
	IWagerManagerMergeServerWagersResult,
	IWagerManagerMethodAdjustWagerAmountsByNameWagerSet,
	IWagerManagerMethodAdjustWagerAmountsWagerSet,
	IWagerManagerMethodCanAdjustWagerAmountResult,
	IWagerManagerMethodCanSetWagerAmountResult,
	IWagerManagerMethodNewLocalWagerFromServerWagerOpts,
	IWagerManagerMethodSetWagerAmountsWagerSet,
	IWagerManagerMethodUpdateLocalWagerAmountOpts,
	IWagerManagerMethodUpdateLocalWagerDataWagerSet,
	IWagerManagerMethodUpdateLocalWagersDataResult,
	IWagerManagerOpts,
	IWagerManifest,
	IWagerTotalData,
	RawServerPlayerWagerStateData,
} from './types';
import {
	createMultiSeatWagersCombinedRequest,
	defaultMultiSeatWagerTotalsSet,
	extractMswSeatNums,
	fillNewMultiSeatWagerSet,
	fillNewMultiSeatWagerTotalsSet,
	flattenMultiSeatWagerSet,
	getMultiSeatWagersCount,
	getMultiSeatWagersNums,
	getWagersManifest,
	mapWagerSetToPlacedWagerSet,
	newClearWagersRequest,
	resolveWageringSeats,
	sendClearWagersRequestThrottled,
	sendMakeWagersRequestThrottled,
	sendReplaceWagersRequestThrottled,
} from './utility';

/**
 * Manages all things wager related. Stores, tracks, synchronizes and sends wager events / requests.
 */
class WagerManager extends ManagerBase implements IWagerManager {
	/* #region ---- Properties --------------------------------------------------------------------------------------- */

	/**
	 * Currently assigned options.
	 */
	protected override _options: IWagerManagerOpts = WagerManager.defaultOptions();

	/**
	 * Active wagering player ID.
	 */
	protected _playerId: string = '';

	/**
	 * Active table ID.
	 */
	protected _tableId: string = '';

	/**
	 * Active play ID.
	 */
	protected _playId: string = '';

	/**
	 * Default seat number. Used when seat number is not specified. This will default to 1 but get auto-assigned to
	 * the first claimed seat number for the playerId when claimed seats are known (ie. multi-seat games).
	 */
	protected _defaultSeatNumber: number = 1;

	/**
	 * Flag indicating if the table is a single seat table. This is used to determine if claimed seats are mutated
	 * when table seat assignments change.
	 */
	protected _isSingleSeatTable: boolean = true;

	/**
	 * If this is TRUE, then the wager manager will not attempt to sync active wagers from the server - unless it
	 * currently has no local wagers at all.
	 */
	protected _useLocalWagersOnly: boolean = false;

	/**
	 * Last received wager data from the server for the active wagering player - encapsulated in a data object.
	 * See `play.playerDecisionState`.
	 */
	protected _serverPlayerWagerState!: IServerPlayerWagerState;

	/**
	 * Current local wager data ONLY for the active wagering player. This may contain multiple seats worth of data.
	 */
	protected _activePlayerWagersLocal!: ILocalActiveWagers;

	/**
	 * Current play wager definitions data from the server. See `play.playState.wagerDefinitions`.
	 */
	protected _wagerDefinitions!: IPlayWagerDefinitions;

	/**
	 * Current available wagers data from the server (by context). See `play.playerState.availableWagers`.
	 *
	 * Example:
	 * "availableWagers": [{
	 *		"contextId": "00000000-0000-0000-0000-000000000000",
	 *		"wagerTypeIds": [
	 *			"52902fb9-abbf-4970-850d-3953e21cfc53",
	 *			"1be3f336-6bfc-481f-b467-07f1948838c5",
	 *		]
	 * }]
	 */
	protected _playAvailableWagersByContext: IContextAvailableWagersData[] = [];

	/**
	 * Current full available wagers data from the server - flattened by a single data object. This is the authoritative
	 * source of available wagers data for the active wagering player.
	 */
	protected _availableWagers!: IAvailableWagers;

	/**
	 * Table config data for the current table.
	 */
	protected _tableConfig!: ITableConfigDataExt;

	/**
	 * Table config wager definitions data for the current table, encapsulated in a data object.
	 */
	protected _tableConfigWagerDefinitions!: ITableConfigWagerDefinitions;

	/**
	 * Current table seat assignment data from the server. Holds seat assignments on the table for ALL players
	 * that are at actually sat at the table (see `table.seats`).
	 */
	protected _tableSeatAssignments!: ITableSeatAssignments;

	/**
	 * Current play seat data from the server. Holds seat assignments (and other seat-related data) on the play
	 * for ALL players that are sat at the table and participating in the play (see `play.seats`).
	 */
	protected _playSeatAssignments!: IPlaySeatAssignments;

	/**
	 * Flat array of seat numbers currently claimed by the active wagering player. This restricts what seats the
	 * `WagerManager` instance can place/clear wagers for.
	 */
	protected _myClaimedSeats: number[] = [];

	/**
	 * Data set of saved wagers to use when calling the rebet method. Contains a single set of wagers across multiple
	 * claimed seats. When rebet occurs these wagers will replace the current local wagers and will get sent to the server.
	 */
	protected _rebetWagers: Nullable<ILocalActiveWagersData> = null;

	/**
	 * Class that manages a stack of local wager data used for undo purposes.
	 */
	protected _wagerUndoStack!: IWagerUndoStack;

	/**
	 * Wallet manager instance used by this instance.
	 */
	protected _walletManager: Nullable<IWalletManager> = null;

	/**
	 * Wager service used to send wagers to the server.
	 */
	protected _wagerService: Nullable<IGameService> = null;

	/**
	 * Flag indicating if this class instance is currently bound to MobX as an observable.
	 */
	protected _isMobXBound: boolean = false;

	/* #endregion ---- Properties -------------------------------------------------------------------------------------*/

	/* #region ---- CONSTRUCTOR ---------------------------------------------------------------------------------------*/

	constructor(wagerService: IGameService, opts?: Maybe<IWagerManagerOpts>) {
		super();

		this._wagerService = wagerService;
		opts != null && this.setOptions(opts);

		this.init();
	}

	/* #endregion ---- CONSTRUCTOR ----------------------------------------------------------------------------------- */

	/* #region ---- Public ------------------------------------------------------------------------------------------- */

	/**
	 * ACTION
	 * Sets/initializes the class options.
	 *
	 * - Overrides the parent class method.
	 */
	public override setOptions(opts: IWagerManagerOpts) {
		const { newOpts, origOpts } = this.resolveOptions(opts);

		if (this._isMobXBound) {
			set(this, '_options', newOpts);
		} else {
			this._options = newOpts;
		}

		this.onSetOptions(newOpts, origOpts);
	}

	/**
	 * ACTION
	 * Resets this class instance back to the clear/initial state.
	 */
	public clear() {
		this._playerId = '';
		this._tableId = '';
		this._playId = '';
		this.init();
	}

	/**
	 * ACTION
	 * Clears all local wagering data. Note that this action is purely local and does not affect the server.
	 */
	public clearLocalData(): void {
		this._activePlayerWagersLocal.clear();
	}

	/**
	 * ACTION
	 * Resets all local wagering data to what is currently on the server. Note that this action is purely local
	 * and does not affect the server.
	 */
	public resetLocalData(): void {
		this.clearLocalData();
		this.updateLocalFromCurrentServerWagers();
	}

	/**
	 * Gets/sets whether or not this class instance is currently bound to MobX as an observable.
	 */
	public get isMobXBound(): boolean {
		return this._isMobXBound;
	}
	public set isMobXBound(value: boolean) {
		this._isMobXBound = value;
	}

	/**
	 * The timestamp (in milliseconds) when the local wager data was last updated.
	 */
	public get lastUpdatedTs(): number {
		return this._activePlayerWagersLocal.lastUpdatedTs;
	}

	/**
	 * Get/set the active wagering player ID. Setting this will also set the same player ID on the WalletManager instance.
	 */
	public get playerId(): string {
		return this._playerId;
	}
	public set playerId(val: string) {
		this.setPlayerId(val);
	}
	// Actionable setter method for MobX.
	protected setPlayerId(val: string, opts?: Maybe<{ resetRelatedState?: Maybe<boolean> }>) {
		if (val === this._playerId) {
			return;
		}

		const prev = this._playerId;
		this._playerId = val;
		this.onPlayerIdChanged(this._playerId, prev, { resetRelatedState: opts?.resetRelatedState });
	}

	/**
	 * Get/set the active table ID.
	 */
	public get tableId(): string {
		return this._tableId;
	}
	public set tableId(val: string) {
		this.setTableId(val);
	}
	// Actionable setter method for MobX.
	protected setTableId(val: string, opts?: Maybe<{ resetRelatedState?: Maybe<boolean> }>) {
		if (val === this._tableId) {
			return;
		}

		const prev = this._tableId;
		this._tableId = val;
		this.onTableIdChanged(this._tableId, prev, { resetRelatedState: opts?.resetRelatedState });
	}

	/**
	 * Get/set the active play ID.
	 */
	public get playId(): string {
		return this._playId;
	}
	public set playId(val: string) {
		this.setPlayId(val);
	}
	// Actionable setter method for MobX.
	protected setPlayId(val: string, opts?: Maybe<{ resetRelatedState?: Maybe<boolean> }>) {
		if (val === this._playId) {
			return;
		}

		const prevPlayId = this._playId;
		this._playId = val;
		this.onPlayIdChanged(this._playId, prevPlayId, { resetRelatedState: opts?.resetRelatedState });
	}

	/**
	 * Gets the table config data.
	 */
	public get tableConfig(): ITableConfigDataExt {
		return this._tableConfig;
	}

	/**
	 * Gets the current table config wager definitions (aka `wagerRules`).
	 */
	public get wagerRules(): ITableConfigWagerDefinitions {
		return this._tableConfigWagerDefinitions;
	}

	/**
	 * Get/set the default seat number. Used when seat number is not specified.
	 */
	public get defaultSeatNumber(): number {
		return this._defaultSeatNumber;
	}
	public set defaultSeatNumber(val: number) {
		this.setDefaultSeatNumber(val);
	}
	// Actionable setter method for MobX.
	protected setDefaultSeatNumber(val: number) {
		val = Math.max(val, 1);

		if (val === this._defaultSeatNumber) {
			return;
		}

		// If the table is a single seat table then we can't set the default seat number to anything other than 1
		if (this._isSingleSeatTable) {
			this.warn('Cannot set default seat number when table is a single seat table.');
			return;
		}

		// Note we never disallow setting the default seat number to 1 - even if that is not a claimed seat number.
		if (val > 1 && !this.isMySeat(val)) {
			this.warn('Cannot set default seat number to a seat that is not claimed by the active wagering player.');
			return;
		}

		this._defaultSeatNumber = val;
	}

	/**
	 * Get/sets whether or not the table is a single seat table.
	 */
	public get isSingleSeatTable(): boolean {
		return this._isSingleSeatTable;
	}
	public set isSingleSeatTable(val: boolean) {
		this.setIsSingleSeatTable(val);
	}
	// Actionable setter method for MobX.
	protected setIsSingleSeatTable(val: boolean) {
		this.info('INFO', 'setIsSingleSeatTable', { val });
		if (val === this._isSingleSeatTable) {
			return;
		}

		const prevVal = this._isSingleSeatTable;
		this._isSingleSeatTable = val;
		this.onIsSingleSeatTableChanged(this._isSingleSeatTable, prevVal);
	}

	/**
	 * Get whether or not the local wagers only feature is enabled.
	 */
	public get useLocalWagersOnly(): boolean {
		return this._useLocalWagersOnly;
	}
	/**
	 * @returns Wager total data object for the active wagering player.
	 */
	public get totalWagered(): IWagerTotalData {
		return this.getClaimedSeatTotals().sumTotals;
	}

	/**
	 * @returns Total wager amount (raw) across all seats claimed by the active wagering player. eg. 50,000
	 */
	public get totalWageredAmount(): number {
		return this.totalWagered.amount;
	}

	/**
	 * @returns Total wager amount (real) across all seats claimed by the active wagering player.
	 *          eg. 50,000 (raw) = 50 (real)
	 */
	public get totalWageredAmountReal(): number {
		return this.totalWagered.amountReal;
	}

	/**
	 * @returns Total wager amount (with currency symbol) across all seats claimed by the active wagering player.
	 *          eg. 50,000 (raw) = $50.00
	 */
	public get totalWageredAmountMoney(): string {
		return this.totalWagered.amountMoney;
	}

	/**
	 * @returns TRUE if we currently have ANY non-zero local wagers amounts set for the active wagering player (any claimed seat).
	 */
	public get hasWagers(): boolean {
		return this.totalWageredAmount > 0;
	}

	/**
	 * @returns Array of seat numbers currently claimed by the active wagering player.
	 */
	public get myClaimedSeats(): number[] {
		return this._myClaimedSeats;
	}

	/**
	 * @returns TRUE if the active wagering player has claimed any seats.
	 */
	public get hasClaimedSeats(): boolean {
		return this._myClaimedSeats.length > 0;
	}

	/**
	 * The current wagers as an `IMultiSeatWagerSet` object - ie. data is grouped by seat number.
	 */
	public get wagers(): IMultiSeatWagerSet {
		return this.getWagers();
	}

	/**
	 * The current wagers as a flattened array of `IWagerData` objects.
	 */
	public get wagersList(): IWagerData[] {
		return this.getWagersList();
	}

	/**
	 * The current min & max wager amount limits - based on wager rules.
	 */
	public get wagerLimits(): IWagerLimits {
		// const minAmount = this._tableConfig?.minAmount ?? 0;
		const minAmount = this.wagerRules?.minAmount ?? 0;
		const min = resolveAmounts({ amount: minAmount }, this.getResolveAmountsOpts());

		// const maxAmount = this._tableConfig?.maxAmount ?? 0;
		const maxAmount = this.wagerRules?.maxAmount ?? 0;
		const max = resolveAmounts({ amount: maxAmount }, this.getResolveAmountsOpts());
		const hasLimits = minAmount + maxAmount > 0;

		return { min, max, hasLimits };
	}

	/**
	 * @returns Wagers manifest object containing all the potential wagers for the current table plus a flag
	 * 				  indicating if the wager is available or not.
	 */
	public get wagersManifest(): IWagerManifest {
		return getWagersManifest(this._wagerDefinitions, this._availableWagers);
	}

	/**
	 * Gets/sets the default currency code used for currency related processing in this wager manager.
	 */
	public get currencyCode(): string {
		return this._walletManager?.activeCurrencyCode || WalletManager.defaultCurrencyCode();
	}
	public set currencyCode(val: string) {
		if (!this._walletManager) {
			return;
		}

		this._walletManager.activeCurrencyCode = val;
	}

	/**
	 * @returns TRUE if there are any wagers available - according to `_availableWagers`.
	 */
	public get hasAvailableWagers(): boolean {
		return !(this._availableWagers?.isEmpty ?? true);
	}

	/**
	 * @returns Available wagers class instance containing all the currently available wagers.
	 */
	public get availableWagers(): IAvailableWagers {
		return this._availableWagers;
	}

	/**
	 * @returns Wager definitions class instance containing all the potential wagers for the current play.
	 */
	public get wagerDefinitions(): IPlayWagerDefinitions {
		return this._wagerDefinitions;
	}

	/**
	 * @returns The current server active wagers.
	 */
	public get serverActiveWagers(): IActiveWagers {
		return this._serverPlayerWagerState.activeWagersDataObj;
	}

	/**
	 * @returns The current server player wager state class instance.
	 */
	public get serverPlayerWagerState(): IServerPlayerWagerState {
		return this._serverPlayerWagerState;
	}

	/**
	 * @returns TRUE if the specified wager ID is an available wager.
	 */
	public isWagerAvailable(availableWagerKey: string): boolean {
		return this._availableWagers?.has(availableWagerKey) ?? false;
	}

	/**
	 * @returns TRUE if the specified wager name is an available wager.
	 */
	public isWagerNameAvailable(wagerName: string): boolean {
		return this._availableWagers?.hasName(wagerName) ?? false;
	}

	/* #region ::PUBLIC::Wager Data:: */

	/**
	 * Gets LOCAL wager data that applies to the active wagering player - across all/specified claimed seats.
	 *
	 * - Will only process claimed seats - and then filter than further by `filterSeatNumbers`.
	 * - Wagers returned will be filtered to those that match `filterSeatNumbers` and `filterWagerNames` (if either
	 *   are specified)
	 * - Will only use local wager data - will not check server data.
	 * - An entry for every seat number will be returned. Seats that are invalid, or, have no relevant wagers, will
	 *   return with a value of NULL.
	 *
	 * TODO: Add currency formatting option support here
	 */
	public getWagers(opts?: Maybe<IMethodGetWagersOpts>): IMultiSeatWagerSet {
		const debugMethod = 'getWagers';

		const myClaimedSeats = this._myClaimedSeats.slice();
		const filterSeatNumbers = opts?.filterSeatNumbers ?? [];

		// Determine what seat numbers we are allowed to process
		let processSeatNumbers: number[] = [];
		let result: IMultiSeatWagerSet;

		// Additional seat filtering has been specified
		if (filterSeatNumbers.length > 0) {
			// Determine which of the specified seats are valid - ie. they are claimed by the active wagering player
			const { matchedSeats } = resolveWageringSeats(myClaimedSeats, filterSeatNumbers);
			if (matchedSeats.length === 0) {
				this.error('No claimed seats matching the seat filter specified.', debugMethod, {
					filterSeatNumbers,
					myClaimedSeats,
				});

				// Return all the specified filter seats with NULL values
				return fillNewMultiSeatWagerSet(filterSeatNumbers, null);
			}

			// We'll still use the full list of filter seats to fill the result, but we will populate only with valid seats
			processSeatNumbers = matchedSeats;
			result = fillNewMultiSeatWagerSet(filterSeatNumbers, null);
		}
		// No seat filter was specified so just use the current claimed seats
		else {
			processSeatNumbers = myClaimedSeats;
			result = fillNewMultiSeatWagerSet(myClaimedSeats, null);
		}

		const localWagersInstance = opts?.localWagersInstance ?? this._activePlayerWagersLocal;

		// Query the local wagers for wagers matching the relevant seat numbers
		// TODO: Future optimization, we can add memoization to this local wagers query to avoid re-calculating the same data
		const localWagers = localWagersInstance.getWagers({
			filterSeatNumbers: processSeatNumbers,
			filterWagerNames: opts?.filterWagerNames,
			filterWagerIds: opts?.filterWagerIds,
			filterContextIds: opts?.filterContextIds,
		});

		// Merge the queried wagers into the result
		result.seatWagers = { ...result.seatWagers, ...localWagers.seatWagers };
		result.wagerCount = getMultiSeatWagersCount(result.seatWagers);
		result.seatNumbers = result.wagerCount === 0 ? [] : getMultiSeatWagersNums(result.seatWagers);

		return result;
	}

	/**
	 * Same as `getWagers` but returns a flattened array of wager data.
	 */
	public getWagersList(opts?: Maybe<IMethodGetWagersOpts>): IWagerData[] {
		const wagers = this.getWagers(opts);

		return flattenMultiSeatWagerSet(wagers);
	}

	/**
	 * Gets singular LOCAL wager data for the specified available wager key.
	 *
	 * - Will resolve to use the `_defaultSeatNumber` if seat is not specified.
	 * - Returns NULL if the specified seat is not claimed by the active wagering player.
	 * - Returns NULL if unable to find the relevant wager in the data.
	 * - Will only use local wager data - will not check server data.
	 *
	 * 	TODO: Add currency formatting option support here
	 */
	public getWager(availableWagerKey: string): Nullable<IWagerData> {
		const debugMethod = 'getWager';

		// Resolve the seat number
		const seatNumber = this.finagleSeatNumber({ availableWagerKey });

		if (availableWagerKey === '') {
			this.error('Available wager key is required.', debugMethod, {
				availableWagerKey,
			});
			return null;
		}

		if (!this.isMySeat(seatNumber)) {
			this.error('Unable to get wager data for seat that is not claimed by the active wagering player.', debugMethod, {
				availableWagerKey,
				seatNumber,
			});
			return null;
		}

		// Attempt to get the wager from the local wager data
		return this._activePlayerWagersLocal.getAvailableWager(availableWagerKey, { seatNumber });
	}

	/**
	 * Get LOCAL wager totals data that applies to the active wagering player - across all/specified claimed seats.
	 *
	 * - Will only process claimed seats - and then filter than further by `filterSeatNumbers`.
	 * - Wagers being totaled will be filtered to those that match `filterSeatNumbers` and `filterWagerNames` (if either
	 *   are specified)
	 * - An entry for every seat number will be returned. Seats that are invalid, or, have no relevant wagers, will
	 *   return with a value of NULL.
	 * - Will only use local wager data - will not check server data.
	 *
	 * TODO: Add currency formatting option support here
	 */
	public getWagerTotals(opts?: Maybe<IMethodGetWagerTotalsOpts>): IMultiSeatWagerTotalsSet {
		const debugMethod = 'getWagerTotals';

		const myClaimedSeats = this._myClaimedSeats.slice();
		const filterSeatNumbers = opts?.filterSeatNumbers ?? [];

		// Determine what seat numbers we are allowed to process
		let validFilterSeatNumbers: number[] = [];
		let resultTemplate: IMultiSeatWagerTotalsSet;

		if (filterSeatNumbers.length > 0) {
			const { matchedSeats } = resolveWageringSeats(myClaimedSeats, filterSeatNumbers);

			if (matchedSeats.length === 0) {
				this.error('No claimed seats matching the seat filter specified.', debugMethod, {
					filterSeatNumbers,
					myClaimedSeats,
				});

				return fillNewMultiSeatWagerTotalsSet(filterSeatNumbers, null);
			}

			validFilterSeatNumbers = matchedSeats;
			resultTemplate = fillNewMultiSeatWagerTotalsSet(filterSeatNumbers, null);
		} else {
			validFilterSeatNumbers = myClaimedSeats;
			resultTemplate = fillNewMultiSeatWagerTotalsSet(myClaimedSeats, null);
		}

		// Proxy the call to the local wagers class
		const totalsSet = this._activePlayerWagersLocal.getWagerTotals({
			...(opts ?? {}),
			filterSeatNumbers: validFilterSeatNumbers,
		});

		return { ...resultTemplate, ...totalsSet };
	}
	/* #endregion ::PUBLIC::Wager Data:: */

	/* #region ::PUBLIC::Placing/Clearing Wagers:: */

	/**
	 * ACTION
	 * Attempts to set the current wager amount for the specified available wager key - in the context
	 * of the active wagering player.
	 *
	 * - Will resolve to use the `_defaultSeatNumber` if seat is not specified.
	 * - Will fail if the seat number is not claimed by the active wagering player.
	 * - Will fail if the wager ID is not available (see `_availableWagers`).
	 * - Will fail if the amount < 0
	 * - Will fail if the amount being set is not within the min/max thresholds for the wager (see `_wagerRules`).
	 * - Calling this will adjust the balance via the WalletManager instance - if wager amount change results in
	 *   spend/refund activity.
	 * - Calling this will send a `MakeWagers` RPC call.
	 *
	 * @returns TRUE if the wager amount was successfully set.
	 */
	public setWagerAmount(
		availableWagerKey: string,
		amount: number,
		opts?: Maybe<{
			isDirty?: Maybe<boolean>;
		}>
	): boolean {
		const debugMethod = 'setWagerAmount';

		// Determine if the wager amount can be set as specified
		const validate = this.canSetWagerAmount(availableWagerKey, amount);
		if (!validate.canSet) {
			this.warn('Unable to set wager amount', debugMethod, { ...validate });
			return false;
		}

		// Attempt to update the local wager amount
		const updateResult = this.updateLocalWagerAmount(availableWagerKey, amount, { ...opts });
		this.onLocalWagersUpdate(updateResult, { undoCacheKeys: [availableWagerKey] });

		return updateResult.success;
	}

	/**
	 * ACTION
	 * Attempts to set the current wager amount for the specified wager name to the specified `amount` value - in the
	 * context of the specified seat.
	 *
	 * See `setWagerAmount` for more details.
	 *
	 * @returns TRUE if the wager amount was successfully set.
	 */
	public setWagerAmountByName(
		wagerName: string,
		byAmount: number,
		opts?: Maybe<{
			// Seat number to set the wager amount for. Defaults to the default seat number.
			seatNumber?: Maybe<number>;
			// Flag indicating if the wager amount is dirty (ie. not confirmed). Defaults to the default value.
			isDirty?: Maybe<boolean>;
		}>
	): boolean {
		const debugMethod = 'setWagerAmountByName';

		// Resolve the specified seat number - this will default to the default seat number if not specified
		const seatNumber = this.resolveSeatNumber(opts?.seatNumber);

		// Make sure this wager name is an available wager for the specified seat number
		const availWager = this.getFirstAvailableWagerByNameAndSeatNumber(wagerName, seatNumber);

		// Fail the whole operation if the wager is not a valid available wager
		if (availWager == null) {
			this.warn(`${wagerName}: Wager is not an available wager for the specified seat.`, debugMethod, {
				availableWagers: this._availableWagers.list.slice(),
				seatNumber,
			});

			return false;
		}

		return this.setWagerAmount(availWager.availableWagerKey, byAmount, { ...opts });
	}

	/**
	 * ACTION
	 * Attempts to set multiple wager amounts at the same time - in the context of the active wagering player.
	 *
	 * - See `canSetWagerAmount` for details on how the validation works.
	 *
	 * @returns TRUE if the wagers were successfully set.
	 */
	public setWagerAmounts(wagerSet: IWagerManagerMethodSetWagerAmountsWagerSet): boolean {
		const debugMethod = 'setWagerAmounts';

		// Create a set of wagers to update via `updateLocalWagersData`
		const updateWagersData: IWagerManagerMethodUpdateLocalWagerDataWagerSet = {};
		for (const availableWagerKey in wagerSet) {
			const props = wagerSet[availableWagerKey];

			// Determine if the wager amount can be set as specified
			const validate = this.canSetWagerAmount(availableWagerKey, props.amount);

			// Fail the whole operation if ANY wager cannot be set
			if (!validate.canSet) {
				this.error('Unable to set wager amount.', debugMethod, { ...validate });
				return false;
			}

			updateWagersData[availableWagerKey] = {
				isDirty: props.isDirty ?? this.isNewLocalDataDirtyDefaultVal,
				amount: props.amount,
			};
		}

		// Attempt to update the local wager data
		const updateResult = this.updateLocalWagersData(updateWagersData);
		this.onLocalWagersUpdate(updateResult, {
			undoCacheKeys: [...Object.keys(updateWagersData)],
		});

		return updateResult.success;
	}

	/**
	 * ACTION
	 * Attempts to adjust the current wager amount for the specified (or default) seat and available wager key by the
	 * specified `byAmount` value - in the context of the active wagering player.
	 *
	 * - The `byAmount` value can be positive or negative.
	 * - Will resolve to use the `_defaultSeatNumber` if seat is not specified.
	 * - Will fail if the seat number is not claimed by the active wagering player.
	 * - Will fail if the available wager key is not available (see `_availableWagers`).
	 * - Will fail if `byAmount` is zero
	 * - Will fail if the adjustment will cause the wager amount to go below zero.
	 * - Will fail if the adjustment will cause the wager amount to go outside the min/max thresholds for the
	 *   wager (see `_wagerRules`).
	 * - Calling this will adjust the balance via the WalletManager instance - if wager amount change results in
	 *   spend/refund activity.
	 *
	 * @returns TRUE if the wager amount was successfully set.
	 */
	public adjustWagerAmount(
		availableWagerKey: string,
		byAmount: number,
		opts?: Maybe<{
			isDirty?: Maybe<boolean>;
		}>
	): boolean {
		// Determine if the wager amount can be set as specified
		const validate = this.canAdjustWagerAmount(availableWagerKey, byAmount);
		if (!validate.canAdjust) {
			return false;
		}

		// Attempt to update the local wager data
		const newAmount = validate.amountDiff.updated;
		const updateOpts = this.addQueuedPropsToLocalUpdateOpts({
			...opts,
		});

		const updateResult = this.updateLocalWagerAmount(availableWagerKey, newAmount, updateOpts);
		this.onLocalWagersUpdate(updateResult, { undoCacheKeys: [availableWagerKey] });

		return updateResult.success;
	}

	protected addQueuedPropsToLocalUpdateOpts(
		opts: IWagerManagerMethodUpdateLocalWagerAmountOpts
	): IWagerManagerMethodUpdateLocalWagerAmountOpts {
		return {
			...opts,
			extra: { ...opts.extra, sequence: LOCAL_QUEUED_SEQUENCE, isQueued: true, lastQueuedTs: Date.now() },
		};
	}

	/**
	 * ACTION
	 * Attempts to adjust the current wager amount for the specified (or default) seat and wager name by the
	 * specified `byAmount` value - in the context of the active wagering player.
	 *
	 * See `adjustWagerAmount` for more details.
	 *
	 * @returns TRUE if the wager amount was successfully set.
	 */
	public adjustWagerAmountByName(
		wagerName: string,
		byAmount: number,
		opts?: Maybe<{
			// Seat number to set the wager amount for. Defaults to the default seat number.
			seatNumber?: Maybe<number>;
			// Flag indicating if the wager amount is dirty (ie. not confirmed). Defaults to the default value.
			isDirty?: Maybe<boolean>;
		}>
	): boolean {
		const debugMethod = 'adjustWagerAmountByName';

		// Resolve the seat number - this will default to the default seat number if not specified
		const seatNumber = this.resolveSeatNumber(opts?.seatNumber);

		// Make sure this wager name is an available wager for the specified seat number
		const availWager = this.getFirstAvailableWagerByNameAndSeatNumber(wagerName, seatNumber);

		// Fail the whole operation if the wager is not a valid available wager
		if (availWager == null) {
			this.warn(`${wagerName}: Wager is not an available wager for the specified seat.`, debugMethod, {
				availableWagers: this._availableWagers.list.slice(),
				seatNumber,
			});

			return false;
		}

		return this.adjustWagerAmount(availWager.availableWagerKey, byAmount, { ...opts });
	}

	/**
	 * ACTION
	 * Attempts to adjust multiple wager amounts at the same time for the specified (or default) seat - in the context of
	 * the active wagering player.
	 *
	 * - The `byAmount` value on each entry can be positive or negative.
	 * - Will resolve to use the `_defaultSeatNumber` if seat is not specified.
	 * - Will fail if the seat number is not claimed by the active wagering player.
	 * - Will fail if ANY available wager key is not an available wager (see `_availableWagers`).
	 * - Will fail if ANY `byAmount` is zero
	 * - Will fail if ANY adjustment will cause the wager amount to go below zero.
	 * - Will fail if ANY adjustment will cause the wager amount to go outside the min/max thresholds for the
	 *   wager (see `_wagerRules`).
	 * - Calling this will adjust the balance via the WalletManager instance - if wager amount changes results in
	 *   spend/refund activity.
	 *
	 * @returns TRUE if the wagers were successfully adjusted.
	 */
	public adjustWagerAmounts(wagerSet: IWagerManagerMethodAdjustWagerAmountsWagerSet): boolean {
		const debugMethod = 'adjustWagerAmounts';

		// Create a set of wagers to update via `updateLocalWagersData`
		const updateWagersData: IWagerManagerMethodUpdateLocalWagerDataWagerSet = {};
		for (const availableWagerKey in wagerSet) {
			const props = wagerSet[availableWagerKey];

			// Determine if the wager amount can be adjusted as specified
			const validate = this.canAdjustWagerAmount(availableWagerKey, props.byAmount);

			// Fail the whole operation if ANY wager cannot be adjusted
			if (!validate.canAdjust) {
				this.error('Unable to adjust wager amount.', debugMethod, { ...validate });
				return false;
			}

			const newAmount = validate.amountDiff.updated;
			updateWagersData[availableWagerKey] = {
				isDirty: props.isDirty ?? this.isNewLocalDataDirtyDefaultVal,
				amount: newAmount,
			};
		}

		// Attempt to update the local wager data
		const updateResult = this.updateLocalWagersData(updateWagersData);
		this.onLocalWagersUpdate(updateResult, {
			undoCacheKeys: [...Object.keys(updateWagersData)],
		});

		return updateResult.success;
	}

	/**
	 * ACTION
	 * Attempts to adjust multiple wager amounts by wager name at the same time for the specified (or default) seat - in
	 * the context of the active wagering player.
	 *
	 * See `adjustWagerAmounts` for more details.
	 *
	 * @returns TRUE if the wagers were successfully adjusted.
	 */
	public adjustWagerAmountsByName(
		wagerSet: IWagerManagerMethodAdjustWagerAmountsByNameWagerSet,
		opts?: Maybe<{ seatNumber?: number }>
	): boolean {
		const debugMethod = 'adjustWagerAmountsByName';

		// Resolve the seat number - this will default to the default seat number if not specified
		const seatNumber = this.resolveSeatNumber(opts?.seatNumber);

		const adjustWagersData: IWagerManagerMethodAdjustWagerAmountsWagerSet = {};
		for (const wagerName in wagerSet) {
			const props = wagerSet[wagerName];

			// Make sure this wager name is an available wager for the specified seat number
			const availWager = this.getFirstAvailableWagerByNameAndSeatNumber(wagerName, seatNumber);

			// Fail the whole operation if ANY wager is not a valid available wager
			if (availWager == null) {
				this.error(`${wagerName}: Wager is not an available wager for the specified seat.`, debugMethod, {
					availableWagers: this._availableWagers.list.slice(),
					seatNumber,
				});

				return false;
			}

			adjustWagersData[availWager.availableWagerKey] = props;
		}

		return this.adjustWagerAmounts(adjustWagersData);
	}

	/**
	 * ACTION
	 * Clears ALL local wagers for the active wagering player.
	 */
	public clearAllWagers() {
		// Do nothing if we have no wagers
		if (this._activePlayerWagersLocal.isEmpty) {
			return;
		}

		const original = this._activePlayerWagersLocal.copyData();
		this._activePlayerWagersLocal.clear();
		const updated = this._activePlayerWagersLocal.copyData();

		this.onLocalWagersClear({ original, updated, updatedTs: updated.lastUpdatedTs });
	}

	/**
	 * @returns TRUE if we believe this wager can be successfully set to the specified amount - in the context of
	 *          the active wagering player.
	 *
	 * - Will return FALSE if the available wager key does not exist as an available wager (see `_availableWagers`).
	 * - Will return FALSE if the seat number is not claimed by the active wagering player.
	 * - Will return FALSE if the amount < 0
	 * - Will return FALSE if the amount being set is not within the min/max thresholds for the wager (see _wagerRules).
	 * - Will return FALSE if we have insufficient funds to set the wager to the new amount.
	 */
	public canSetWagerAmount(
		availableWagerKey: string,
		amount: number,
		opts?: Maybe<{
			// Flag indicating if we should bypass the funds check. Defaults to FALSE.
			doNotCheckFunds?: Maybe<boolean>;
			// Amount to use for the provisional balance check. When not specified will use the current WalletManager
			// balances. Defaults to NULL.
			useProvisionalBalanceAmount?: Maybe<number>;
		}>
	): IWagerManagerMethodCanSetWagerAmountResult {
		const debugMethod = 'canSetWagerAmount';

		// Default result
		const result: IWagerManagerMethodCanSetWagerAmountResult = {
			canSet: false,
			availableWagerKey,
			seatNumber: -1,
			amount,
			reason: '',
			hasEntry: false,
			amountDiff: { current: 0, updated: 0, difference: 0 },
			currencyCode: this.currencyCode,
			spendAmount: 0,
			refundAmount: 0,
		};

		// Make sure the wager ID is not empty
		if (availableWagerKey === '') {
			this.warn('Available wager key is required.', debugMethod, { availableWagerKey });
			result.reason = 'Available wager key is required.';

			return result;
		}

		// Make sure this wager is an available wager
		const availWager = this._availableWagers.get(availableWagerKey);
		if (availWager == null) {
			this.warn(`${availableWagerKey}: Wager is not an available wager.`, debugMethod, {
				availableWagers: this._availableWagers.list.slice(),
			});

			result.reason = `Wager '${availableWagerKey}' is not an available wager.`;
			return result;
		}

		// Set the seat number
		const seatNumber = availWager.seatNumber;
		result.seatNumber = seatNumber;

		// Make sure the available wager seat number is claimed by the active player
		if (!this.isMySeat(seatNumber)) {
			this.warn(`${availableWagerKey}: Seat '${seatNumber}' is not claimed by the active player.`, debugMethod, {
				myClaimedSeats: this.myClaimedSeats.slice(),
			});
			result.reason = `Seat '${seatNumber}' is not claimed by the active player.`;

			return result;
		}

		// Make sure the amount is not negative
		if (amount < 0) {
			this.warn(`${availableWagerKey}: Amount cannot be negative.`, debugMethod, { amount });
			result.reason = 'Amount cannot be negative.';

			return result;
		}

		// Make sure the wager is available for the specified seat
		if (availWager.seatNumber !== seatNumber) {
			this.warn(`${availableWagerKey}: Wager is not available for the specified seat.`, debugMethod, {
				availableWagerKey,
				seatNumber,
				availWager,
			});

			result.reason = `Wager '${availableWagerKey}' is not available for the specified seat.`;
			return result;
		}

		// Get the current entry for the wager amount - if it exists
		const currentEntry: Nullable<ILocalActiveWagerDataEntry> = this._activePlayerWagersLocal.getAvailableWager(
			availableWagerKey,
			{
				seatNumber,
			}
		);

		const currentAmount = currentEntry?.amount ?? 0;
		const diffAmount = amount - currentAmount;
		const spendAmount = diffAmount > 0 ? diffAmount : 0;
		const refundAmount = diffAmount < 0 ? diffAmount : 0;
		const currencyCode = currentEntry?.currencyCode ?? result.currencyCode;

		result.hasEntry = currentEntry != null;
		result.amountDiff = { current: currentAmount, updated: amount, difference: diffAmount };
		result.spendAmount = spendAmount;
		result.refundAmount = refundAmount;
		result.currencyCode = currencyCode;

		// Make sure the amount is within the min/max range for the wager
		const wagerDef = availWager.def;
		const { minAmount = 0, maxAmount = 0 } = wagerDef ?? {};

		if (amount > 0 && (amount < minAmount || amount > maxAmount)) {
			this.warn(`${availableWagerKey}: Wager amount is outside of min/max range allowed.`, debugMethod, {
				availableWagerKey,
				amount,
				range: { min: minAmount, max: maxAmount },
				wagerDef,
			});
			result.reason = `Wager amount is outside of min/max range allowed (${minAmount} - ${maxAmount}).`;

			return result;
		}

		// Bypass funds check??
		const doNotCheckFunds = opts?.doNotCheckFunds ?? false;
		const provisionalBalanceAmount =
			opts?.useProvisionalBalanceAmount != null ? Math.max(opts.useProvisionalBalanceAmount, 0) : null;

		if (spendAmount === 0 || doNotCheckFunds || (provisionalBalanceAmount == null && this._walletManager == null)) {
			result.canSet = true;

			return result;
		}

		// Check if we have sufficient funds to set the wager amount
		let canSpend: boolean = false;
		if (provisionalBalanceAmount != null) {
			canSpend = spendAmount <= provisionalBalanceAmount;
		} else if (this._walletManager != null) {
			const validateSpend = this._walletManager.canSpendAmount(spendAmount, currencyCode);
			canSpend = validateSpend?.canSpend ?? false;
		}

		if (!canSpend) {
			this.warn(`Insufficient funds available to set wager amount.`, debugMethod, {
				availableWagerKey,
				amount,
				currencyCode,
				spendAmount,
			});
			result.reason = `Insufficient funds to set wager amount.`;
			return result;
		}

		result.canSet = true;

		return result;
	}

	/**
	 * @returns Details regarding whether or not we believe this wager can be successfully adjusted by the specified
	 *          amount - in the context of the active wagering player.
	 *
	 * - The `byAmount` value can be positive or negative.
	 * - Will resolve to use the `_defaultSeatNumber` if seat is not specified.
	 * - Will return FALSE if the specified available wager key is not an available wager (see `_availableWagers`).
	 * - Will return FALSE if the seat number is not claimed by the active wagering player.
	 * - Will return FALSE if the adjustment amount is 0.
	 * - Will return FALSE if the adjusted amount is less than zero.
	 * - Will return FALSE if the adjusted amount is not within the min/max thresholds for the wager (see `_wagerRules`).
	 * - Will return FALSE if we have insufficient funds to set the wager to the new amount.
	 */
	public canAdjustWagerAmount(
		availableWagerKey: string,
		byAmount: number,
		opts?: Maybe<{
			// Flag indicating if we should bypass the funds check. Defaults to FALSE.
			doNotCheckFunds?: Maybe<boolean>;
			// Amount to use for the provisional balance check. When not specified will use the current WalletManager
			// balances. Defaults to NULL.
			useProvisionalBalanceAmount?: Maybe<number>;
		}>
	): IWagerManagerMethodCanAdjustWagerAmountResult {
		const debugMethod = 'canAdjustWagerAmount';

		const result: IWagerManagerMethodCanAdjustWagerAmountResult = {
			canAdjust: false,
			availableWagerKey,
			seatNumber: -1,
			byAmount,
			reason: '',
			hasEntry: false,
			amountDiff: { current: 0, updated: 0, difference: 0 },
			currencyCode: this.currencyCode,
			spendAmount: 0,
			refundAmount: 0,
		};

		// Make sure the available wager key is not empty
		if (availableWagerKey === '') {
			this.warn('Available wager key is required.', debugMethod, { availableWagerKey });
			result.reason = 'Available wager key is required.';
			return result;
		}

		// Make sure this wager is an available wager
		const availWager = this._availableWagers.get(availableWagerKey);
		if (availWager == null) {
			this.warn(`${availableWagerKey}: Wager is not an available wager.`, debugMethod, {
				availableWagers: this._availableWagers.list.slice(),
			});

			result.reason = `Wager '${availableWagerKey}' is not an available wager.`;
			return result;
		}

		// Set the seat number
		const seatNumber = availWager.seatNumber;
		result.seatNumber = seatNumber;

		// Make sure the seat number is claimed by the active player
		if (!this.isMySeat(seatNumber)) {
			this.warn(`${availableWagerKey}: Seat '${seatNumber}' is not claimed by the active player.`, debugMethod, {
				myClaimedSeats: this.myClaimedSeats.slice(),
			});

			result.reason = `Seat '${seatNumber}' is not claimed by the active player.`;
			return result;
		}

		// Make sure the adjustment amount is not zero
		if (byAmount == 0) {
			this.warn(`${availableWagerKey}: Adjustment amount is zero.`, debugMethod, { byAmount });
			result.reason = 'Adjustment amount is zero.';
			return result;
		}

		// Make sure the wager is available for the specified seat
		if (availWager.seatNumber !== seatNumber) {
			this.warn(`${availableWagerKey}: Wager is not available for the specified seat.`, debugMethod, {
				availableWagerKey,
				seatNumber,
				availWager,
			});

			result.reason = `Wager '${availableWagerKey}' is not available for the specified seat.`;
			return result;
		}

		// Get the current entry for the wager amount - if it exists
		const currentEntry: Nullable<ILocalActiveWagerDataEntry> = this._activePlayerWagersLocal.getAvailableWager(
			availableWagerKey,
			{
				seatNumber,
			}
		);

		// Determine the new adjusted amount
		const currentAmount = currentEntry?.amount ?? 0;
		const newAmount = currentAmount + byAmount; // New adjusted amount
		const diffAmount = newAmount - currentAmount; // Difference
		const spendAmount = diffAmount > 0 ? diffAmount : 0;
		const refundAmount = diffAmount < 0 ? diffAmount : 0;
		const currencyCode = currentEntry?.currencyCode ?? result.currencyCode;

		result.hasEntry = currentEntry != null;
		result.amountDiff = { current: currentAmount, updated: newAmount, difference: diffAmount };
		result.spendAmount = spendAmount;
		result.refundAmount = refundAmount;
		result.currencyCode = currencyCode;

		// Make sure the adjusted amount is not less than zero
		if (newAmount < 0) {
			this.warn(`${availableWagerKey}: Adjusted wager amount cannot be less than zero.`, debugMethod, {
				availableWagerKey,
				newAmount,
			});

			result.reason = `Adjusted wager amount ${newAmount} cannot be less than zero.`;
			return result;
		}

		// Make sure the adjusted amount is within the min/max range for the wager
		const wagerDef = availWager.def;
		const { minAmount = 0, maxAmount = 0 } = wagerDef ?? {};

		if (newAmount > 0 && (newAmount < minAmount || newAmount > maxAmount)) {
			this.warn(`${availableWagerKey}: Adjusted wager amount is outside of min/max range allowed.`, debugMethod, {
				availableWagerKey,
				newAmount,
				range: { min: minAmount, max: maxAmount },
				wagerDef,
			});

			result.reason = `Adjusted wager amount is outside of min/max range allowed (${minAmount} - ${maxAmount}).`;
			return result;
		}

		// Bypass funds check??
		const doNotCheckFunds = opts?.doNotCheckFunds ?? false;
		const provisionalBalanceAmount =
			opts?.useProvisionalBalanceAmount != null ? Math.max(opts.useProvisionalBalanceAmount, 0) : null;

		if (spendAmount === 0 || doNotCheckFunds || (provisionalBalanceAmount == null && this._walletManager == null)) {
			result.canAdjust = true;

			return result;
		}

		// Check if we have sufficient funds to adjust the wager amount
		let canSpend: boolean = false;
		if (provisionalBalanceAmount != null) {
			canSpend = spendAmount <= provisionalBalanceAmount;
		} else if (this._walletManager != null) {
			const validateSpend = this._walletManager.canSpendAmount(spendAmount, currencyCode);
			canSpend = validateSpend?.canSpend ?? false;
		}

		if (!canSpend) {
			this.warn(`Insufficient funds available to adjust wager amount.`, debugMethod, {
				availableWagerKey,
				newAmount,
				currencyCode,
				spendAmount,
			});
			result.reason = `Insufficient funds to adjust wager amount.`;
			return result;
		}

		result.canAdjust = true;

		return result;
	}

	/* #endregion ::PUBLIC::Placing/Clearing Wagers:: */

	/* #region ::PUBLIC::Undo:: */

	/**
	 * @returns TRUE if we are able to attempt to undo the last wager placed for the specified seat.
	 */
	public get canUndoWagers(): boolean {
		return this.hasAvailableWagers && this._wagerUndoStack.size > 0;
	}

	/**
	 * ACTION
	 * Clears the current undo wagers stack.
	 */
	public clearUndoWagers(): void {
		if (this._wagerUndoStack.size === 0) {
			return;
		}

		this._wagerUndoStack.clear();
	}

	// > Also: See throttled method `undoWagers` in the section at the bottom.

	/* #endregion ::PUBLIC::Undo:: */

	/* #region ::PUBLIC::Double Wagers:: */

	/**
	 * @returns TRUE if we are able to attempt to double all wagers across all seats.
	 */
	public get canDoubleAllWagers(): boolean {
		return this.hasAvailableWagers && this.hasWagers;
	}

	/**
	 * ACTION
	 * Attempts to double the wager amounts for all wagers that can be doubled across all seats.
	 */
	public doubleAllWagers(): boolean {
		const debugMethod = 'doubleAllWagers';

		if (!this.canDoubleAllWagers) {
			return false;
		}

		const wagers = this.getWagersList();
		if (wagers.length === 0) {
			return false;
		}

		const playId = this.playId;
		const currencyCode = this.currencyCode;
		const walletManager = this._walletManager;
		const checkFunds = walletManager != null;
		let provisionalBalance: number = (checkFunds ? walletManager.getBalanceAmount(currencyCode) : null) ?? 0;

		if (checkFunds && provisionalBalance <= 0) {
			this.warn('Insufficient funds available to double any wagers', debugMethod, {
				currencyCode,
				balance: provisionalBalance,
			});

			return false;
		}

		// Loop through wagers in numerical seat order, determine which can be doubled and add them to a batch of local
		// wager data to be updated in a single call to `updateLocalWagersData`
		const wagerBatchesBySeat = new Map<string, IWagerManagerMethodUpdateLocalWagerDataWagerSet>();
		const seatNumKeys = new Set<string>();
		const availWagerKeys = new Set<string>();

		wagers.forEach((wager: IWagerData) => {
			const seatNumber = wager.seatNumber;
			const availableWagerKey = wager.availableWagerKey;
			const byAmount = wager.amount;

			if (byAmount <= 0) {
				return; // Next wager
			}

			const validate = this.canAdjustWagerAmount(availableWagerKey, byAmount, {
				useProvisionalBalanceAmount: checkFunds ? provisionalBalance : null,
				doNotCheckFunds: !checkFunds,
			});

			// Skip this wager if it cannot be doubled
			if (!validate.canAdjust) {
				this.warn('Unable to double wager amount.', debugMethod, { ...validate });
				return; // Next wager
			}

			const newAmount = validate.amountDiff.updated;
			const spendAmount = validate.spendAmount;

			checkFunds && (provisionalBalance = Math.max(provisionalBalance - spendAmount, 0));

			const seatNumStr = seatNumber.toString();
			const batch = wagerBatchesBySeat.get(seatNumStr) ?? {};
			batch[availableWagerKey] = { amount: newAmount, isDirty: true };
			wagerBatchesBySeat.set(seatNumStr, batch);

			seatNumKeys.add(seatNumStr);
			availWagerKeys.add(availableWagerKey);
		});

		const updatedWagers = this.newLocalActiveWagers({ playId, useMobX: false, isDebugEnabled: false });

		// TODO: Review. Maybe we don't need to do this by seats now...
		wagerBatchesBySeat.forEach((batch) => {
			this.updateLocalWagersData(batch, { localWagersInstance: updatedWagers });
		});

		const origData = this._activePlayerWagersLocal.copyData();
		const newData = updatedWagers.copyData({ updatedTs: Date.now() });

		const updateResult: IWagerManagerMethodUpdateLocalWagersDataResult = {
			success: true,
			diff: { original: origData, updated: newData, updatedTs: newData.lastUpdatedTs },
		};

		this._activePlayerWagersLocal.data = newData;

		// Note the use of a random string to ensure the update is not ignored by the debounced `addWagersToUndoStack` method
		this.onLocalWagersUpdate(updateResult, {
			undoCacheKeys: [`double_${generateRandomString(10)}`, ...seatNumKeys, ...availWagerKeys],
		});

		return true;
	}

	// > Also: See throttled method `undoWagers` in the section at the bottom.

	/* #endregion ::PUBLIC::Undo:: */

	/* #region ::PUBLIC::Rebet:: */

	/**
	 * ACTION
	 * Stores a copy of the current local wagers data for rebet.
	 */
	public saveCurrentWagersForRebet() {
		const debugMethod = 'saveCurrentWagersForRebet';

		if (this._activePlayerWagersLocal.isEmpty) {
			this.warn('No wagers to save for rebet.', debugMethod);
			return;
		}

		const data = this._activePlayerWagersLocal.copyData({ updatedTs: Date.now() });
		this.setRebetWagersData(data);
	}

	/**
	 * @returns TRUE if we have saved rebet wager data.
	 */
	public get hasRebetWagers(): boolean {
		return this._rebetWagers != null;
	}

	/**
	 * @returns TRUE if we are able to attempt to place rebet wagers.
	 */
	public get canRebetWagers(): boolean {
		return this.hasRebetWagers && this.hasAvailableWagers && !this.hasWagers;
	}

	/**
	 * Will attempt to rebet any currently stored wagers in `_rebetWagers`.
	 *
	 * - Can only be called if we have saved rebet wager data and no current wagers.
	 * - This will apply the rebet wagers as if the user had chosen them - via local wagers.
	 * - Rebet wagers will be heavily validated before being applied to make sure they are relevant for the current play.
	 */
	public rebetWagers(): boolean {
		const debugMethod = 'rebetWagers';

		if (!this.canRebetWagers) {
			return false;
		}

		const rebetWagers = this._rebetWagers;

		// TODO: Take a lot of this logic below and place it into a `replaceLocalWagers` method for better re-use.
		// TODO: We need a better version of `updateLocalWagersData` that can handle a batch across multiple seats.

		const lookup = rebetWagers?.lookup ?? null;
		const wagerList = lookup != null ? Array.from(lookup.values()) : [];
		if (wagerList.length === 0) {
			return false;
		}

		const playId = this.playId;
		const wagerBatchesBySeat = new Map<string, IWagerManagerMethodUpdateLocalWagerDataWagerSet>();
		const seatNumKeys = new Set<string>();
		const availWagerKeys = new Set<string>();

		wagerList.forEach((wager: ILocalActiveWagerDataEntry) => {
			const seatNumber = wager.seatNumber;
			const wagerName = wager.name;
			const amount = wager.amount;

			// Invalid seat number
			if (seatNumber < 1) {
				return;
			}
			// Empty amounts are ignored when re-placing wagers
			if (amount <= 0) {
				return;
			}

			// Cannot re-place wagers for seats that are not currently claimed by the active player
			if (!this.isMySeat(seatNumber)) {
				this.warn(`${wagerName}: Seat '${seatNumber}' is not claimed by the active player.`, debugMethod, {
					claimedSeats: this._myClaimedSeats.slice(),
				});
				return;
			}

			// The wager name must be an available wager for the specified seat
			const availWager = this.getFirstAvailableWagerByNameAndSeatNumber(wagerName, seatNumber);
			if (availWager == null) {
				this.warn(`${wagerName}: Wager is not an available wager for the specified seat.`, debugMethod, {
					seatNumber,
				});
				return;
			}

			const availableWagerKey = availWager.availableWagerKey;
			const seatNumStr = seatNumber.toString();
			const batch = wagerBatchesBySeat.get(seatNumStr) ?? {};
			batch[availableWagerKey] = { playId, amount, isDirty: true };
			wagerBatchesBySeat.set(seatNumStr, batch);

			seatNumKeys.add(seatNumStr);
			availWagerKeys.add(availableWagerKey);
		});

		const updatedWagers = this.newLocalActiveWagers({ useMobX: false, isDebugEnabled: false });

		// TODO: Review. Maybe we don't need to do this in seat batches anymore.
		wagerBatchesBySeat.forEach((batch) => {
			this.updateLocalWagersData(batch, { localWagersInstance: updatedWagers });
		});

		const origData = this._activePlayerWagersLocal.copyData();
		const newData = updatedWagers.copyData({ updatedTs: Date.now() });

		const updateResult: IWagerManagerMethodUpdateLocalWagersDataResult = {
			success: true,
			diff: { original: origData, updated: newData, updatedTs: newData.lastUpdatedTs },
		};

		this._activePlayerWagersLocal.data = newData;

		this.onLocalWagersUpdate(updateResult, { undoCacheKeys: [...seatNumKeys, ...availWagerKeys], isReplace: true });

		return true;
	}

	/* #endregion ::PUBLIC::Rebet:: */

	/* #region ::PUBLIC::Data Syncing:: */

	/**
	 * ACTION
	 * Set the table config data coming in from the server.
	 *
	 * - Populates `_tableConfig`.
	 * - Populates `_tableConfigWagerDefinitions` if the table config has wager definitions that are different from the
	 *   current list
	 */
	// TODO: Change me to protected once all clients use the SDK - I should be used via the `setTableData` method.
	public setTableConfig(rawData: Nullable<ITableConfigData>): void {
		if (rawData == null) {
			return;
		}

		const tableId = this.tableId;
		const dataExt = extendTableConfigData(rawData, { tableId, currencyCode: rawData.currency || this.currencyCode });
		const wagerDefinitionsList = dataExt.wagerDefinitions.slice();

		// We don't need this data - so don't keep it
		dataExt.raw = null;
		dataExt.wagerDefinitions = [];

		this.setTableConfigData(dataExt);

		this.info('Setting table config from server', 'setTableConfig', { tableId, dataExt, wagerDefinitionsList });

		if (dataExt.currencyCode !== this.currencyCode) {
			this.info(`Changing currency from '${this.currencyCode}' to '${dataExt.currencyCode}'`);
			this.currencyCode = dataExt.currencyCode;
		}

		if (wagerDefinitionsList.length === 0) {
			return;
		}

		// Don't update if the definitions list is the same
		const isSameDefinitionsData =
			this._tableConfigWagerDefinitions && this._tableConfigWagerDefinitions.isDataListSame(wagerDefinitionsList);

		if (isSameDefinitionsData) {
			return;
		}

		const tableConfigWagerDefinitions = TableConfigWagerDefinitions.newFromList(wagerDefinitionsList, tableId, {
			newInstanceOpts: { isDebugEnabled: this.isDebugEnabled },
		});

		this.setTableConfigWagerDefinitionsInstance(tableConfigWagerDefinitions);

		// TODO: In the future we might want to use the `_tableConfigWagerDefinitions` for other things...
	}

	/**
	 * ACTION
	 * Set the defined wagers for the play - using the raw server data.
	 *
	 * - Will do nothing if the new raw list is empty.
	 * - Will do nothing if the new raw list is the same as the current list.
	 * - Populates `_wagerDefinitions`.
	 * - Populates `_wagerRules`.
	 * - Updates `_availableWagers` from the new definitions - unless told not to
	 */
	// TODO: Change me to protected once all clients use the SDK  - I should be used via the `updateFromPlayData` method.
	public setWagerDefinitions(
		rawList: RawPlayWagerDefinitionList,
		opts?: Maybe<{ doNotSetAvailableWagersData?: Maybe<boolean> }>
	): boolean {
		const playId = this.playId;
		const doNotSetAvailableWagersData = opts?.doNotSetAvailableWagersData ?? false;

		// Don't update if the new raw list is empty (wager definitions should never be empty)
		if (rawList == null || rawList.length === 0) {
			return false;
		}

		// Don't update if the new raw list is the same
		const isSameData = this._wagerDefinitions && this._wagerDefinitions.isRawDataSame(rawList);
		if (isSameData) {
			return false;
		}

		// Create and store the new wager definitions data collection class instance
		const newWagerDefinitions = PlayWagerDefinitions.newFromRawList(rawList, playId, {
			newInstanceOpts: { updatedTs: Date.now(), isDebugEnabled: this.isDebugEnabled },
			populateOpts: { extendDataOpts: { currencyCode: this.currencyCode } },
		});
		this.setWagerDefinitionsInstance(newWagerDefinitions);

		// This will re-populate the available wagers data using the new definitions
		!doNotSetAvailableWagersData &&
			this.setAvailableWagersData(this._playAvailableWagersByContext, newWagerDefinitions);

		// 3. Update server active wagers with definition data - remove any wager that is not defined.
		// 4. Update local wagers with definition data - remove any wager that is not defined.

		// TODO: Defined wagers have changed - so we need to re-validate the current local wagers??

		return true;
	}

	/**
	 * ACTION
	 * Set the available wagers data - using the raw server data.
	 *
	 * - Populates `_availableWagers`.
	 * - Populates `_playAvailableWagersList`.
	 */
	// TODO: Change me to protected once all clients use the SDK  - I should be used via the `updateFromPlayData` method.
	public setAvailableWagersData(
		availsData: IContextAvailableWagersData[],
		wagerDefinitions?: Maybe<IPlayWagerDefinitions>
	): boolean {
		const debugMethod = 'setAvailableWagersData';
		const isDebugEnabled = this.isDebugEnabled;

		// TODO: Remove the `filterPlayAvailableWagersByContextToOnlyMySeatIds` when we are ready to handle back-betting
		const origAvailsData = this._playAvailableWagersByContext.slice();
		const newAvailsData = this.filterPlayAvailableWagersByContextToOnlyMySeatIds(availsData.slice());

		const origWagerDefinitions =
			this._wagerDefinitions?.clone({ instanceOpts: { isDebugEnabled: false, useMobX: false } }) ?? null;
		const newWagerDefinitions = wagerDefinitions ?? origWagerDefinitions;

		const isSameDefinitionsData = (newData: PlayWagerDefinitions, currentData: PlayWagerDefinitions) => {
			const newHashId = newData?.dataHashId ?? '';
			const currentHashId = currentData?.dataHashId ?? '';

			return newHashId === currentHashId;
		};

		const isSameAvailsData = (newData: IContextAvailableWagersData[], currentData: IContextAvailableWagersData[]) => {
			const genAvailWagersListHashId = (list: IContextAvailableWagersData[]) => {
				return generateObjectListHashId<IContextAvailableWagersData>(list, ['contextId', 'wagerTypeIds']);
			};

			const newHashId = genAvailWagersListHashId(newData);
			const currentHashId = genAvailWagersListHashId(currentData);

			return newHashId === currentHashId;
		};

		if (
			isSameAvailsData(newAvailsData, origAvailsData) &&
			isSameDefinitionsData(newWagerDefinitions, origWagerDefinitions)
		) {
			return false;
		}

		// Update available wagers with definition data
		let availableWagers: AvailableWagers;

		if (newWagerDefinitions.isEmpty || newAvailsData.length === 0) {
			const updatedTs = Date.now();
			availableWagers = new AvailableWagers({ isDebugEnabled, updatedTs, playId: this.playId });
		} else {
			this.info('Re-populating available wagers from new definitions.', 'setAvailableWagersData', {
				newAvailsData,
				newWagerDefinitions: newWagerDefinitions.list,
				playId: this.playId,
			});

			availableWagers = AvailableWagers.newFromAvailPlayWagersData(
				newAvailsData,
				newWagerDefinitions.list,
				this.playId,
				{
					playSeatAssignments: this._playSeatAssignments.list,
					newInstanceOpts: { isDebugEnabled },
				}
			);
		}

		this.setAvailableWagersInstance(availableWagers);
		this.setPlayAvailableWagersByContextList(newAvailsData);

		// TODO: Available wagers have changed - so we need to re-validate the current local & server wagers??
		/**
		 * We are going from having available wagers to not having any available wagers then
		 * replace all the local wagers data with the server wagers data.
		 */
		if (newAvailsData.length === 0 && origAvailsData.length > 0) {
			this.info('Local wagers will be replaced with server wagers due to no available wagers data', debugMethod);
			this.updateLocalFromCurrentServerWagers();
			return true;
		}

		return true;
	}

	/**
	 * TODO: When backbetting needs implementing we will need to NOT do this.
	 *
	 * @returns A filtered list of available wagers by context containing only contextIds that are seatIds claimed by
	 *          the active wagering player.
	 */
	protected filterPlayAvailableWagersByContextToOnlyMySeatIds(
		list: IContextAvailableWagersData[]
	): IContextAvailableWagersData[] {
		if (list.length === 0) {
			return [];
		}

		if (this._isSingleSeatTable || this._playSeatAssignments.isEmpty || this._myClaimedSeats.length === 0) {
			return list;
		}

		const seats = this._playSeatAssignments;

		return list.filter((entry: IContextAvailableWagersData) => {
			const contextId = normalizeContextId(entry.contextId);
			const seatData = seats.getBySeatId(contextId) ?? null;

			if (seatData == null || !this.isMySeat(seatData.seatNumber)) {
				return false;
			}

			return true;
		});
	}

	/**
	 * Sets the list of open & claimed table seats data (see `table.seats`). This is for ALL players sitting at the table.
	 *
	 * - Used to populate `_tableSeatAssignments`.
	 * - Will update `_myClaimedSeats` (ie. seats claimed by the active wagering player).
	 * - Once table seat assignments are set they cannot be cleared by this method - only updated.
	 */
	// TODO: Change me to protected once all clients use the SDK  - I should be used via the `updateFromTableData` method.
	public setTableSeatAssignments(rawList: RawTableSeatAssignmentList): boolean {
		// Don't update if the new raw list is empty
		if (rawList.length === 0) {
			return false;
		}

		// Don't update if the new raw list is the same
		if (
			this._tableSeatAssignments &&
			!this._tableSeatAssignments.isEmpty &&
			this._tableSeatAssignments.isRawDataSame(rawList)
		) {
			return false;
		}

		const updatedTs = Date.now();

		const tableSeatAssignments = TableSeatAssignments.newFromRawList(rawList, this._tableId, {
			newInstanceOpts: { isDebugEnabled: this.isDebugEnabled },
			populateOpts: { updatedTs },
		});

		this.setTableSeatAssignmentsInstance(tableSeatAssignments);

		!this.isSingleSeatTable && this.initClaimedSeatingForMultiSeatTable();

		return true;
	}

	/**
	 * Sets the list play seats data (see `play.seats`). This is for ALL players sitting at the table.
	 *
	 * - Used to populate `_playSeatAssignments`.
	 */
	// TODO: Change me to protected once all clients use the SDK  - I should be used via the `updateFromPlayData` method.
	public setPlaySeatAssignments(rawList: RawPlaySeatAssignmentList): boolean {
		// Don't update if the new raw list is empty
		if (rawList.length === 0) {
			return false;
		}

		// Don't update if the new raw list is the same
		if (this._playSeatAssignments && this._playSeatAssignments.isRawDataSame(rawList)) {
			return false;
		}

		const updatedTs = Date.now();

		const playSeatAssignments = PlaySeatAssignments.newFromRawList(rawList, this._tableId, this._playId, {
			newInstanceOpts: { isDebugEnabled: this.isDebugEnabled },
			populateOpts: { updatedTs },
		});

		this.setPlaySeatAssignmentsInstance(playSeatAssignments);

		return true;
	}

	/**
	 * Sets the WalletManager instance used internally by this wager manager instance.
	 */
	public setWalletManager(instance: IWalletManager): boolean {
		const debugMethod = 'setWalletManager';

		if (instance.instanceId === this._walletManager?.instanceId) {
			return false;
		}

		if (this.playerId != '' && instance.playerId != '' && this.playerId !== instance.playerId) {
			this.error('Cannot set wallet manager instance - player ID mismatch.', debugMethod, {
				wagerManagerPlayerId: this.playerId,
				walletManagerPlayerId: instance.playerId,
			});
			return false;
		}

		if (instance.playerId == '' && this.playerId != '') {
			this.info(
				`Syncing wallet manager instance player ID from '${instance.playerId}' --> '${this.playerId}'.`,
				debugMethod
			);

			instance.playerId = this.playerId;
		}

		this.setWalletManagerInstance(instance);

		return true;
	}

	// > Also: See debounced method `setActivePlayerWagers` in the section at the bottom.

	/* #endregion ::PUBLIC::Data Syncing:: */

	/* #region ::PUBLIC::Other:: */

	/**
	 * @returns A JSON export of the current pertinent data.
	 */
	public toJson(extended?: Maybe<boolean>): PlainObject {
		extended = extended ?? false;

		const toJs = (val: unknown) => DebugBase.toJs(val, { extended, useMobXToJs: this.isMobXBound });

		const result: PlainObject = {
			playerId: this.playerId,
			tableId: this.tableId,
			playId: this.playId,
			currencyCode: this.currencyCode,
			defaultSeatNumber: this.defaultSeatNumber,
			isSingleSeatTable: this.isSingleSeatTable,
			useLocalWagersOnly: this.useLocalWagersOnly,
			hasClaimedSeats: this.hasClaimedSeats,
			myClaimedSeats: toJs(this.myClaimedSeats.slice()),
			hasAvailableWagers: this.hasAvailableWagers,
			hasWagers: this.hasWagers,
			wagers: toJs(this.wagers),
			wagersManifest: toJs(this.wagersManifest),
			totalWageredAmount: this.totalWageredAmount,
			totalWageredAmountReal: this.totalWageredAmountReal,
			totalWageredAmountMoney: this.totalWageredAmountMoney,
			totalWagered: toJs({ ...this.totalWagered }),
		};

		if (extended) {
			result.extended = {
				isMobXBound: this.isMobXBound,
				options: toJs({ ...this._options }),
				wagersList: toJs(this.getWagersList()),
				totals: toJs(this.getWagerTotals()),
				walletManager: toJs(this._walletManager),
				serverWagers: toJs(this._serverPlayerWagerState),
				localWagers: toJs(this._activePlayerWagersLocal),
				wagerDefinitions: toJs(this._wagerDefinitions),
				playAvailableWagersByContext: toJs(this._playAvailableWagersByContext),
				availableWagers: toJs(this._availableWagers),
				tableConfig: toJs(this._tableConfig),
				tableConfigWagerDefinitions: toJs(this._tableConfigWagerDefinitions),
				tableSeatAssignments: this._tableSeatAssignments.toJson(extended),
				playSeatAssignments: this._playSeatAssignments.toJson(extended),
				rules: toJs(this.wagerRules),
				wagerUndoStack: this._wagerUndoStack.toJson(extended),
				rebetWagers: toJs(this._rebetWagers),

				// Debug info from base class
				_Debug: { ...super.toJson(extended) },
			};
		}

		return result;
	}

	/* #endregion ::PUBLIC::Other:: */

	/* #endregion ---- Public ---------------------------------------------------------------------------------------- */

	/* #region ---- Protected ---------------------------------------------------------------------------------------- */

	/* #region ::Sending Wagers:: */

	/**
	 * Send the specified set of multi-seat placed wagers to the server.
	 *
	 * - Will filter out any seat numbers that are not claimed by the active wagering player.
	 * - Will do nothing if no seat wager data is available - after filtering.
	 */
	protected async sendMultipleSeatWagers(
		multipleSeatWagers: IMultiSeatPlacedWagerSet,
		opts?: Maybe<{ isReplace?: Maybe<boolean> }>
	): Promise<Nullable<MakeWagersReply>> {
		const debugMethod = 'sendMultipleSeatWagers';

		const isReplace = opts?.isReplace ?? false;

		const seatNumbers = extractMswSeatNums(multipleSeatWagers);
		if (seatNumbers.length === 0) {
			this.error('No seats present in the wager set specified', debugMethod);
			return null;
		}

		const { availableSeats, matchedSeats: validSeatNumbers } = this.resolveMyWageringSeats(seatNumbers);
		if (validSeatNumbers.length === 0) {
			this.error('No valid seats present in the wager set specified.', debugMethod, {
				seatNumbers,
				availableSeats,
			});
			return null;
		}

		// TODO: The eventual version of this will need to send a batch of requests and handle failures

		const tableId = this.tableId;
		const playId = this.playId;
		const currencyCode = this.currencyCode;
		let result: Nullable<MakeWagersReply> = null;

		const request = createMultiSeatWagersCombinedRequest(multipleSeatWagers, playId, tableId, validSeatNumbers, {
			currencyCode,
		});

		const logParams = {
			isReplace,
			tableId,
			playId,
			currencyCode,
			placedWagerSet: multipleSeatWagers,
			validSeatNumbers,
			request,
		};

		if (request == null) {
			this.error('No request generated to send', debugMethod, logParams);
			return null;
		}

		this.info('Sending wagers request', debugMethod, {
			...logParams,
		});

		try {
			if (isReplace) {
				result = await this.sendReplaceWagersRequest(request);
			} else {
				result = await this.sendMakeWagersRequest(request);
			}

			this.info('Wagers sent successfully', debugMethod, {
				...logParams,
				result,
			});
		} catch (err: unknown) {
			const errData = err as CancelablePromiseError;
			const isCanceled = errData.isCanceled ?? false;

			if (!isCanceled) {
				this.error('Wagers failed to send', debugMethod, {
					...logParams,
					err,
				});
			} else {
				this.warn('Wagers send canceled', debugMethod, {
					...logParams,
					err,
				});
			}

			return null;
		}

		return result;
	}

	/**
	 * Sends the current set of local wagers to the server.
	 *
	 * - Gets the current local wager data for all seats claimed by the active wagering player and creates
	 *   a `IMultiSeatPlacedWagerSet` which it passes to `sendSeatWagers`.
	 * - Will do nothing if no seats numbers are claimed by the active wagering player.
	 */
	protected sendCurrentLocalWagers = async (
		opts?: Maybe<{ isReplace?: Maybe<boolean> }>
	): Promise<Nullable<MakeWagersReply>> => {
		const localWagersData = this._activePlayerWagersLocal.copyData();

		return this.sendWagersUpdate(localWagersData, { isReplace: opts?.isReplace });
	};

	/**
	 * Sends the specified set of local wagers to the server.
	 *
	 * - Gets the wager data for all seats claimed by the active wagering player and creates
	 *   a `IMultiSeatPlacedWagerSet` which it passes to `sendSeatWagers`.
	 * - Will do nothing if no seats numbers are claimed by the active wagering player.
	 */
	protected sendWagersUpdate = async (
		newWagers: ILocalActiveWagersData,
		opts?: Maybe<{
			origWagers?: Maybe<ILocalActiveWagersData>;
			isReplace?: Maybe<boolean>;
		}>
	): Promise<Nullable<MakeWagersReply>> => {
		const debugMethod = 'sendWagersUpdate';

		if (!this.hasClaimedSeats) {
			this.error('No seats claimed by the active wagering player', debugMethod);
			return null;
		}

		// This gets the wager data for all seats claimed by the active wagering player
		const wagerSet: IMultiSeatWagerSet = this.getWagersForLocalWagersData(newWagers);

		// TODO: Perhaps allow this to be called with an empty wager set - to clear wagers?
		if (wagerSet.wagerCount === 0) {
			this.error('No wagers to send', debugMethod);
			return null;
		}

		// Create the empty set of wagers to place/send
		const placeWagers = mapWagerSetToPlacedWagerSet(wagerSet);

		this.warn('Sending wagers', debugMethod, {
			wagerSet,
			wagerSetCount: wagerSet.wagerCount,
			placeWagers,
		});

		// Send the wagers
		const reply = await this.sendMultipleSeatWagers(placeWagers, { isReplace: opts?.isReplace });
		this.processMakeWagersReply(reply);

		return reply;
	};

	/**
	 * ACTION
	 * Process the replies from MakeWagers requests and updates local data accordingly.
	 */
	protected processMakeWagersReply(reply?: Maybe<Nullable<MakeWagersReply>>): boolean {
		if (reply == null) {
			return false;
		}

		const pendingWagersRaw = reply.pendingWagers ?? [];
		if (pendingWagersRaw.length === 0) {
			return false;
		}

		const pendingWagers = ActiveWagers.newFromRawList(pendingWagersRaw, this.playId, {
			availableWagersList: this._availableWagers.list,
			wagerDefinitionsList: this._wagerDefinitions.list,
			playSeatAssignmentsList: this._playSeatAssignments.list,
			populateOpts: { extendDataOpts: { ...this.getResolveAmountsOpts() } },
			newInstanceOpts: { isDebugEnabled: false, useMobX: false },
		});

		// Create a set of wagers to update via `updateLocalWagersData`
		const updateWagersData: IWagerManagerMethodUpdateLocalWagerDataWagerSet = {};

		pendingWagers.lookup.forEach((pw) => {
			const activeWagerKey = pw.activeWagerKey ?? '';
			const localWager = this._activePlayerWagersLocal.get(activeWagerKey, { copy: true }) ?? null;

			if (localWager == null) {
				return; // Next pending wager
			}

			if (localWager.sequence != LOCAL_QUEUED_SEQUENCE && pw.sequence <= localWager.sequence) {
				return; // Next pending wager
			}

			const availableWagerKey = pw.availableWagerKey ?? '';
			if (availableWagerKey === '') {
				return; // Next pending wager
			}

			updateWagersData[availableWagerKey] = {
				sequence: pw.sequence,
				isQueued: false,
				lastSubmittedTs: Date.now(),
			};
		});

		// this.info('Pending wager update batch:', 'processMakeWagersReply, updateWagersData);

		// Attempt to update the local wager data
		const updateResult = this.updateLocalWagersData(updateWagersData);

		return updateResult.success;
	}

	/**
	 * Sends a `ClearWagers` request to the server - which will clear all wagers for the current active player on
	 * the current table & play. Calling this will also cancel all in-flight `MakeWagers` requests.
	 */
	protected sendClearAllWagers = async (): Promise<boolean> => {
		const request = newClearWagersRequest(this._tableId, this._playId);
		await this.sendClearWagersRequest(request);

		return true;
	};

	/**
	 * Calls the underlying service `MakeWagers` method. Cancels any previous in-flight requests for the same wager
	 * configuration if called again before the previous transaction is finished.
	 */
	protected async sendMakeWagersRequest(request: MakeWagersRequest): Promise<Nullable<MakeWagersReply>> {
		const data = await sendMakeWagersRequestThrottled(request, this._wagerService);

		return data ?? null;
	}

	/**
	 * Calls `ClearWagers` followed by `MakeWagers`. Subsequent calls to this method will cancel any currently in-flight
	 * `MakeWagers` requests.
	 */
	protected async sendReplaceWagersRequest(request: MakeWagersRequest): Promise<Nullable<MakeWagersReply>> {
		const data = await sendReplaceWagersRequestThrottled(request, this._wagerService);

		return data ?? null;
	}

	/**
	 * Calls the underlying service `ClearWagers` method.
	 */
	protected async sendClearWagersRequest(request: ClearWagersRequest): Promise<boolean> {
		const data = await sendClearWagersRequestThrottled(request, this._wagerService);

		return data ?? false;
	}

	/* #endregion ::Sending Wagers:: */

	/* #region ::Data Syncing:: */

	/**
	 * ACTION
	 * Uses the entire `GetTable` data payload to sync various state data.
	 */
	protected updateFromTableData = (data: Nullable<ITableData>): boolean => {
		if (data == null) {
			return false;
		}

		const tableId = data.tableId ?? '';
		const playId = data.playId ?? '';
		const tableConfig = (data.playConfig ?? null) as Nullable<ITableConfigData>;
		const seatsList = (data.seats ?? []) as ITableSeatData[];

		this.info('Info', 'updateFromTableData', { data: { ...data }, tableId, playId, tableConfig, seatsList });

		this.tableId = tableId;
		playId !== '' && (this.playId = playId);
		this.setTableConfig(tableConfig);
		this.setTableSeatAssignments(seatsList);

		return true;
	};

	/**
	 * ACTION
	 * Uses the entire `GetPlay` data payload to sync various state data.
	 */
	protected updateFromPlayData = (data: Nullable<IPlayData>): boolean => {
		if (data == null) {
			return false;
		}

		const playId = data.playId ?? '';
		const playerState = (data.playerState ?? null) as Nullable<IPlayerStateData>;
		const playState = (data.playState ?? null) as Nullable<IPlayStateData>;
		const playerDecisionState = (data?.playerDecisionState ?? null) as Nullable<IPlayerDecisionStateData>;
		const wagerDefinitions = (playState?.wagerDefinitions ?? []) as RawPlayWagerDefinitionList;
		const availableWagerIdsByContext = (playerState?.availableWagers ?? []) as IContextAvailableWagersData[];
		const seatsList = (data.seats ?? []) as IPlaySeatData[];

		// this.info('Info', 'updateFromPlayData', {
		// 	playId,
		// 	playerState,
		// 	playState,
		// 	playerDecisionState,
		// 	availableWagerIdsByContext,
		// 	seatsList,
		// });

		this.playId = playId;
		this.setPlaySeatAssignments(seatsList);
		this.setWagerDefinitions(wagerDefinitions, { doNotSetAvailableWagersData: true });
		this.setAvailableWagersData(availableWagerIdsByContext);

		playerDecisionState != null && this.updateFromRawPlayerWagersData(playerDecisionState);

		return true;
	};

	/**
	 * ACTION
	 * Update the local wagers data using the raw player active wagers list from the server.
	 */
	protected updateFromRawPlayerWagersData = (rawData: RawServerPlayerWagerStateData): boolean => {
		const debugMethod = 'updateFromRawPlayerWagersData';

		this.info('New raw wagers data', debugMethod, rawData);

		if (rawData == null) {
			return false;
		}

		// If the incoming server wagers data is the same as the current stored server wagers data then do nothing
		if (this._serverPlayerWagerState.isRawDataSame(rawData)) {
			this.warn(
				'Server wagers data is the same as the current stored server wagers data. Will not update.',
				debugMethod
			);
			return false;
		}

		const updatedTs = Date.now();

		// Update the server wagers data
		const serverPlayerWagerState = this.newServerPlayerWagerState({ updatedTs });
		serverPlayerWagerState.populate(rawData, { updatedTs });
		this.setServerPlayerWagerStateInstance(serverPlayerWagerState);

		this.info('Server wagers data updated', debugMethod, serverPlayerWagerState.lookup);

		/**
		 * When we are set to ONLY use local wagers AND we have local data set then we don't want to sync the server wagers data.
		 * - If the local wagers data is empty then we will allow ONE initial sync of the server wagers data.
		 */
		if (this.useLocalWagersOnly && this._activePlayerWagersLocal.lastUpdatedTs > 0) {
			this.warn('Local wagers will not be synced due to `useLocalWagersOnly` being TRUE', debugMethod);
			return true;
		}

		/**
		 * When we are set to allow server-synced wagers AND we currently have no available wager data then we want to
		 * replace all the local wagers data with the server wagers data.
		 */
		if (!this.useLocalWagersOnly && this._availableWagers.isEmpty) {
			this.info('Local wagers will be replaced with server wagers due to no available wagers data', debugMethod);
			this.updateLocalFromCurrentServerWagers();
			return true;
		}

		let attempts = 0;
		let canUpdate = false;
		let merge: Nullable<IWagerManagerMergeServerWagersResult> = null;

		let localLastUpdatedTs = this._activePlayerWagersLocal.lastUpdatedTs;

		while (!canUpdate && attempts < 5) {
			attempts++;
			merge = this.mergeServerWagersWithLocal(serverPlayerWagerState);

			// If the local wagers data has been updated since we started then we need to try again
			// TODO: Maybe use the dataHashId instead?
			canUpdate = this._activePlayerWagersLocal.lastUpdatedTs === localLastUpdatedTs;

			if (!canUpdate) {
				localLastUpdatedTs = this._activePlayerWagersLocal.lastUpdatedTs;
			}
		}

		if (!canUpdate || merge == null) {
			return false;
		}

		// Update the local data
		this._activePlayerWagersLocal.data = merge.localWagers.updated.data;

		// TODO
		// this.issueLocalWagerDataUpdateEvent(merge.localWagers.updated, merge.localWagers.original);

		return true;
	};

	/**
	 * ACTION
	 * Update the local wagers data using the current server active wagers.
	 */
	protected updateLocalFromCurrentServerWagers(): boolean {
		const serverWagers = this._serverPlayerWagerState.clone();
		const updatedTs: number = serverWagers.lastUpdatedTs;

		const newLocalWagers: ILocalActiveWagers = this.newLocalActiveWagers({
			updatedTs,
		});

		serverWagers.list.forEach((svrWager: IServerPlayerWagerStateDataEntry) => {
			const insert = this.newLocalWagerFromServerWager(svrWager, { updatedTs });
			newLocalWagers.set(insert.activeWagerKey, insert);
		});

		this._activePlayerWagersLocal.data = newLocalWagers.data;

		// TODO
		// this.issueLocalWagerDataUpdateEvent(merge.localWagers.updated, merge.localWagers.original);

		return true;
	}

	protected newLocalWagerFromServerWager(
		svrWager: IServerPlayerWagerStateDataEntry,
		opts?: Maybe<IWagerManagerMethodNewLocalWagerFromServerWagerOpts>
	): ILocalActiveWagerDataEntry {
		const updatedTs: number = opts?.updatedTs ?? svrWager.lastUpdatedTs ?? Date.now();
		const overrides = filterNullUndefined(opts?.override ?? {});

		return {
			activeWagerId: svrWager.activeWagerId,
			activeWagerKey: svrWager.activeWagerKey,
			amount: svrWager.amount,
			amountMoney: svrWager.amountMoney,
			amountReal: svrWager.amountReal,
			avail: svrWager.avail,
			availableWagerKey: svrWager.availableWagerKey,
			categoryMemberKey: svrWager.categoryMemberKey,
			contextId: svrWager.contextId,
			createdTs: svrWager.createdTs,
			currencyCode: svrWager.currencyCode,
			currencySymbol: svrWager.currencySymbol,
			def: svrWager.def,
			isDirty: false,
			isLocal: true,
			lastUpdatedTs: updatedTs,
			isQueued: false,
			lastQueuedTs: 0,
			lastSubmittedTs: svrWager.createdTs,
			name: svrWager.name,
			playId: svrWager.playId,
			processedTs: svrWager.processedTs,
			raw: null,
			result: svrWager.result,
			seatId: svrWager.seatId,
			seatNumber: svrWager.seatNumber,
			sequence: svrWager.sequence,
			wagerId: svrWager.wagerId,
			...overrides,
		};
	}

	/**
	 * Merges the specified server wagers data with the specified local wagers data to create a new local
	 * wagers state. Also determines the new dirty wager history state.
	 *
	 * @returns A diff summary of the local wagers data and dirty wagers history data.
	 */
	protected mergeServerWagersWithLocal(
		serverWagers: IServerPlayerWagerState,
		localWagers?: Maybe<ILocalActiveWagers>
	): IWagerManagerMergeServerWagersResult {
		// const debugMethod = 'mergeServerWagersWithLocal';

		localWagers = localWagers ?? this._activePlayerWagersLocal;

		const updatedTs = serverWagers.lastUpdatedTs;

		// Create a shallow clone of the current local wagers data
		const existingLocalWagers = localWagers.clone({ instanceOpts: { useMobX: false, isDebugEnabled: false } });

		// Note: We start with an empty structure for our updated local wagers to handle the case where a wager
		// could get removed in the server provided wagers data
		const updatedLocalWagers: ILocalActiveWagers = this.newLocalActiveWagers({
			updatedTs,
		});

		const makeResult = () => {
			return {
				serverWagers: serverWagers,
				localWagers: { original: existingLocalWagers, updated: updatedLocalWagers, updatedTs },
			};
		};

		/*=================================================================================================================
		 * TODO
		 *================================================================================================================*/
		const addExistingLocalEntry = (activeWagerKey: string, override?: Maybe<Partial<ILocalActiveWagerDataEntry>>) => {
			const existing = existingLocalWagers.get(activeWagerKey, { copy: true });
			if (existing == null) {
				return;
			}

			updatedLocalWagers.set(activeWagerKey, { ...existing, ...override });
		};

		const newLocalWagerFromServerWager = (
			svrWager: IServerPlayerWagerStateDataEntry,
			srcLocalWager?: Maybe<ILocalActiveWagerDataEntry>,
			opts?: Maybe<IWagerManagerMethodNewLocalWagerFromServerWagerOpts>
		) => {
			const override = opts?.override ?? {};

			if (srcLocalWager != null) {
				override.lastQueuedTs = srcLocalWager.lastQueuedTs;
				override.lastSubmittedTs = srcLocalWager.lastSubmittedTs;
			}

			return this.newLocalWagerFromServerWager(svrWager, {
				isDirty: false,
				...filterNullUndefined(opts ?? {}),
				override,
			});
		};

		// /*=================================================================================================================
		//  * TODO
		//  *================================================================================================================*/
		// const updateLocalWagerEntry = (activeWagerKey: string, props: IUpdateLocalActiveWagerProps) => {
		// 	const diff = diffEntryDataFrom(activeWagerKey, props, existingLocalWagers.lookup);
		// 	diff != null && updatedLocalWagers.set(activeWagerKey, diff.updated);
		// };

		// /*=================================================================================================================
		//  * TODO
		//  *================================================================================================================*/
		// const clearDwHistoryItems = (wagerKey: string) => {
		// 	const diff = DirtyWagerHistory.clearItemsFor(wagerKey, updatedDwHistory, { updatedTs });
		// 	updatedDwHistory.lookup.set(wagerKey, diff.items.updated);
		// 	updatedDwHistory.lastUpdatedTs = updatedTs;
		// };

		// /*=================================================================================================================
		//  * TODO
		//  *================================================================================================================*/
		// const ltrimDbHistoryItems = (wagerKey: string, trimIndex: number) => {
		// 	const diff = DirtyWagerHistory.sliceItemsFor(wagerKey, updatedDwHistory, trimIndex + 1);
		// 	updatedDwHistory.lookup.set(wagerKey, diff.items.updated);
		// 	updatedDwHistory.lastUpdatedTs = updatedTs;
		// };

		// Process only the wager entries being sent by the server. Local wagers that do not match this will get removed.
		serverWagers.list.forEach((svrWager: IServerPlayerWagerStateDataEntry) => {
			const activeWagerKey = svrWager.activeWagerKey;

			// The current local wager entry
			const localWager: Nullable<ILocalActiveWagerDataEntry> = existingLocalWagers.get(activeWagerKey) ?? null;

			//----------------------------------------------------------------------
			// New server wager (wager key not in original local wagers)
			//----------------------------------------------------------------------
			if (localWager == null) {
				// Insert new server wager as a new local wager
				const insert: ILocalActiveWagerDataEntry = newLocalWagerFromServerWager(svrWager);
				updatedLocalWagers.set(activeWagerKey, insert);

				// this.info(
				// 	`Inserted new server wager '${activeWagerKey}' with amount '${svrWager.amountMoney}'.`,
				// 	debugMethod,
				// 	insert
				// );

				return; // CONTINUE to next server wager
			}

			const isRemoved = svrWager.state === ServerWageringState.REMOVED;
			const isActive = svrWager.state === ServerWageringState.ACTIVE;
			const isPending = [ServerWageringState.PENDING_NEW, ServerWageringState.PENDING_UPDATE].includes(svrWager.state);
			const isRejected = [ServerWageringState.REJECTED_NEW, ServerWageringState.REJECTED_UPDATE].includes(
				svrWager.state
			);

			//----------------------------------------------------------------------
			// Incoming server wager is PENDING
			//----------------------------------------------------------------------
			if (isPending) {
				addExistingLocalEntry(activeWagerKey, { activeWagerId: svrWager.activeWagerId });
				return; // CONTINUE to next server wager
			}

			//----------------------------------------------------------------------
			// Incoming server wager is ACTIVE
			//----------------------------------------------------------------------
			if (isActive) {
				// Stale data, just use what we have
				if (svrWager.sequence < localWager.sequence) {
					addExistingLocalEntry(activeWagerKey);
					return; // CONTINUE to next server wager
				}

				// Update the local wager entry with the new server wager data
				const replace: ILocalActiveWagerDataEntry = newLocalWagerFromServerWager(svrWager, localWager);
				updatedLocalWagers.set(activeWagerKey, replace);
				return; // CONTINUE to next server wager
			}

			//----------------------------------------------------------------------
			// Incoming server wager is REJECTED
			//----------------------------------------------------------------------
			if (isRejected) {
				// Stale data, just use what we have
				if (svrWager.sequence < localWager.sequence) {
					addExistingLocalEntry(activeWagerKey);
					return; // CONTINUE to next server wager
				}

				const replace: ILocalActiveWagerDataEntry = newLocalWagerFromServerWager(svrWager, localWager);
				updatedLocalWagers.set(activeWagerKey, replace);
				return; // CONTINUE to next server wager
			}

			//----------------------------------------------------------------------
			// Incoming Server wager is REMOVED
			//----------------------------------------------------------------------
			if (isRemoved) {
				const replace: ILocalActiveWagerDataEntry = this.newLocalWagerFromServerWager(svrWager, localWager);
				updatedLocalWagers.set(activeWagerKey, replace);
				return; // CONTINUE to next server wager
			}
		});

		existingLocalWagers.lookup.forEach((oldWager: ILocalActiveWagerDataEntry) => {
			if (updatedLocalWagers.has(oldWager.activeWagerKey)) {
				return; // CONTINUE to next local wager
			}

			const oldWagerLastSubmittedDiffMs = Date.now() - oldWager.lastSubmittedTs;
			if (oldWager.isQueued || oldWagerLastSubmittedDiffMs <= 2000) {
				addExistingLocalEntry(oldWager.activeWagerKey);
			}
		});

		return makeResult();
	}

	/* #endregion ::Data Syncing:: */

	/* #region ::Other:: */

	/**
	 * The default value to use when setting the `isDirty` flag on new & updated local wager entries.
	 */
	protected get isNewLocalDataDirtyDefaultVal(): boolean {
		return !this._useLocalWagersOnly;
	}

	/**
	 * @returns TRUE if the specified seat number is one of the seats claimed by the active wagering player.
	 */
	protected isMySeat(seatNumber: number): boolean {
		return seatNumber > 0 && this._myClaimedSeats.length > 0 && this._myClaimedSeats.includes(seatNumber);
	}

	/**
	 * @returns TRUE if the specified seat numbers are all seats claimed by the active wagering player.
	 */
	protected areMySeats(seatNumbers: number[]): boolean {
		const claimedSeats = this._myClaimedSeats.slice();
		if (seatNumbers.length === 0 || this._myClaimedSeats.length === 0) {
			return false;
		}

		const compare = intersect(seatNumbers, claimedSeats);

		return compare.length === seatNumbers.length;
	}

	/**
	 * @returns Eligible wagering seats for the active player.
	 */
	protected resolveMyWageringSeats(filterSeats?: Optional<number[]>) {
		return resolveWageringSeats(this._myClaimedSeats.slice(), filterSeats);
	}

	/**
	 * ACTION
	 * Initialized the claimed seats list & correct default seat for the active wagering player based on the current table seat
	 * assignments.
	 */
	protected initClaimedSeatingForMultiSeatTable(): Nullable<{
		myClaimedSeats: number[];
		defaultSeatNumber: number;
	}> {
		if (this.isSingleSeatTable) {
			return null;
		}

		// Update the list of claimed seats for the current player based on the new table seat assignments
		const myClaimedSeats: number[] = this.resolveMyClaimedSeatsForTableSeatAssignments();
		if (myClaimedSeats.length === 0) {
			myClaimedSeats.push(1);
		}

		this.setMyClaimedSeatsList(myClaimedSeats);

		// Update the default seat number if it is no longer claimed. Pick the first claimed seat number.
		if (!myClaimedSeats.includes(this._defaultSeatNumber)) {
			this.setDefaultSeatNumber(this._myClaimedSeats[0] ?? 1);
		}

		return { myClaimedSeats: this._myClaimedSeats, defaultSeatNumber: this._defaultSeatNumber };
	}

	/**
	 * @returns The seat numbers claimed by the active wagering player from the `_tableSeatAssignments` collection.
	 */
	protected resolveMyClaimedSeatsForTableSeatAssignments(
		tableSeatAssignments?: Maybe<ITableSeatAssignments>
	): number[] {
		tableSeatAssignments = tableSeatAssignments ?? this._tableSeatAssignments;

		return tableSeatAssignments.getClaimedSeatsForPlayerId(this.playerId);
	}

	/**
	 * @returns The total wagers for the active wagering player for the current active currency code.
	 */
	protected getClaimedSeatTotals(): IMultiSeatWagerTotalsSet {
		const currencyCode = this.currencyCode;
		const myClaimedSeats = this._myClaimedSeats.slice();

		if (myClaimedSeats.length === 0) {
			return defaultMultiSeatWagerTotalsSet();
		}

		return this.getWagerTotals({ filterSeatNumbers: myClaimedSeats, formatCurrencyOpts: { currencyCode } });
	}

	/**
	 * @returns Either the specified seat number or the default seat number if the specified seat number is invalid.
	 */
	protected resolveSeatNumber(seatNumber?: Maybe<number>): number {
		seatNumber = seatNumber || 0;

		return seatNumber > 0 ? seatNumber : this._defaultSeatNumber;
	}

	/**
	 * @returns The seat number for the specified available wager key or NULL if the available wager key is not found.
	 */
	protected getSeatNumberForAvailableWagerKey(availableWagerKey: string): Nullable<number> {
		const availableWager = this._availableWagers.get(availableWagerKey) ?? null;

		return availableWager?.seatNumber ?? null;
	}

	/**
	 * @returns A resolved seat number from either the specified seat number or the specified available wager key.
	 * 				  If neither are resolvable, the default seat number is returned.
	 */
	protected finagleSeatNumber(opts?: { seatNumber?: Maybe<number>; availableWagerKey?: Maybe<string> }) {
		const availableWagerKey = opts?.availableWagerKey ?? '';
		if (availableWagerKey !== '') {
			return this.getSeatNumberForAvailableWagerKey(availableWagerKey) ?? this._defaultSeatNumber;
		}

		if (opts?.seatNumber !== null) {
			return this.resolveSeatNumber(opts?.seatNumber);
		}

		return this._defaultSeatNumber;
	}

	/**
	 * @returns Same as `getWagers` but for the specified local wagers instance.
	 */
	protected getWagersForLocalWagersData(
		data: ILocalActiveWagersData,
		opts?: Omit<IMethodGetWagersOpts, 'localWagersInstance'>
	) {
		const instance = this.newLocalActiveWagers({ data, useMobX: false, isDebugEnabled: false });

		return this.getWagers({ ...opts, localWagersInstance: instance });
	}

	/**
	 * ACTION
	 * Updates the local wager entry for the specified available wager key (and optional seat number) to the specified amount.
	 *
	 * @returns The updated amount if successful, otherwise NULL.
	 */
	protected updateLocalWagerAmount(
		availableWagerKey: string,
		newAmount: number,
		opts?: Maybe<IWagerManagerMethodUpdateLocalWagerAmountOpts>
	): IWagerManagerMethodUpdateLocalWagersDataResult {
		const debugMethod = 'updateLocalWagerAmount';

		const isDirty = opts?.isDirty ?? this.isNewLocalDataDirtyDefaultVal;

		// Fail if the available wager key is not specified
		if (availableWagerKey === '') {
			this.error('Available wager key is required.', debugMethod);
			return { success: false, diff: null };
		}

		const updateBatch: IWagerManagerMethodUpdateLocalWagerDataWagerSet = {
			[availableWagerKey]: { ...filterNullUndefined(opts?.extra ?? {}), amount: newAmount, isDirty },
		};

		const updateResult = this.updateLocalWagersData(updateBatch, { sequenceOffset: opts?.sequenceOffset });

		return { ...updateResult };
	}

	/**
	 * ACTION
	 * Updates the local balance based on whether or not we're "refunding" or spending money.
	 */
	protected onAdjustLocalBalance(oldTotal: number, currentWagers: ILocalActiveWagers) {
		const currentTotal = currentWagers.getWagerTotals().sumTotals.amount;
		const diff = currentTotal - oldTotal;

		// Note: If we subtract the old from the new total, we could just pass diff directly to
		// adjustBalanceAmount, but doing it this way makes it a little more clear as to exactly
		// what's going on.
		const isRefund = diff < 0;
		const isSpend = diff >= 0;

		this.debug.info('Adjusting balance', 'onAdjustLocalBalance', { diff, isRefund, isSpend });

		if (isSpend) {
			this._walletManager?.adjustBalanceAmount(-diff);
		} else {
			this._walletManager?.adjustBalanceAmount(Math.abs(diff));
		}
	}

	/**
	 * ACTION
	 * Attempts to set multiple wager data entries at the same time for the specified (or default) seat - in the
	 * context of the active wagering player.
	 *
	 * - Will resolve to use the `_defaultSeatNumber` if seat is not specified.
	 * - Will fail if the seat number is not claimed by the active wagering player.
	 * - Will fail if ANY available wager key is not available (see `_availableWagers`).
	 * - Will fail if ANY amount < 0
	 * - Will fail if ANY amount being set is not within the min/max thresholds for the wager (see `_wagerRules`).
	 * - Calling this will adjust the balance via the WalletManager instance - if wager amount changes results in
	 *   spend/refund activity.
	 */
	protected updateLocalWagersData(
		wagerSet: IWagerManagerMethodUpdateLocalWagerDataWagerSet,
		opts?: Maybe<{ localWagersInstance?: Maybe<ILocalActiveWagers>; sequenceOffset?: Maybe<number> }>
	): IWagerManagerMethodUpdateLocalWagersDataResult {
		const debugMethod = 'updateLocalWagersData';

		// Fail if the wager set is invalid
		if (!this.validateUpdateWagersSet(wagerSet)) {
			return { success: false, diff: null };
		}

		const current = this._activePlayerWagersLocal.clone();
		const currentAmount = current.getWagerTotals().sumTotals.amount;

		// Build the batch update data.
		const updateBatch: BatchUpsertLocalActiveWagersList = [];

		entries(wagerSet).forEach(([availableWagerKey, updateProps]) => {
			const avail = this._availableWagers.get(availableWagerKey);

			if (avail == null) {
				this.error(`${availableWagerKey}: Available wager does not exist`, debugMethod);
				return; // Next wager
			}

			const seatNumber = avail.seatNumber;

			const activeWagerKey: string = makeActiveWagerKeyFromAvailableKey(availableWagerKey, { seatNumber });

			if (activeWagerKey === '') {
				this.error(`${availableWagerKey}: Unable to make a valid wager key.`, debugMethod, {
					keyProps: { availableWagerKey },
					updateProps,
				});
				return; // Next wager
			}

			updateBatch.push({
				...updateProps,
				availableWagerKey,
				seatNumber,
				avail,
				name: avail?.name ?? '',
				activeWagerKey,
				playId: this.playId,
			});
		});

		const localWagersInstance = opts?.localWagersInstance ?? this._activePlayerWagersLocal;

		const diff = localWagersInstance.batchUpsertWagerData(updateBatch, {
			resolveAmountsOpts: this.getResolveAmountsOpts(),
			sequenceOffset: opts?.sequenceOffset,
		});

		const success = diff != null;

		if (success) {
			this.onAdjustLocalBalance(currentAmount, localWagersInstance);
		}

		return { success, diff };
	}

	/**
	 * ACTION
	 * Called following an update to the local wagers that we want to officially register and sync with the server.
	 *
	 * @param undoCacheKeys  Optional list of cache keys that were updated. If specified, this will be used
	 *                       to determine if the local wagers should be added to the undo stack.
	 */
	protected onLocalWagersUpdate(
		updateResult: IWagerManagerMethodUpdateLocalWagersDataResult,
		opts?: Maybe<{
			undoCacheKeys?: Maybe<string[]>;
			isReplace?: Maybe<boolean>;
		}>
	) {
		this.info('info.1:', 'onLocalWagersUpdate', { updateResult });

		if (!updateResult.success) {
			return;
		}

		const diff: ILocalActiveWagersDataDiff = updateResult.diff as ILocalActiveWagersDataDiff;

		// TODO: Adjust the balance via the WalletManager instance - if wager amount changes results in spend/refund activity
		// To do this, calculate the original total amount of the local wagers, and the new total amount of the local
		// wagers then spend or refund the difference.

		// Add the original local wagers data to the undo stack
		const undoCacheKey = (opts?.undoCacheKeys ?? []).join('|');
		this.addWagersToUndoStack([diff.original], { debounceKey: undoCacheKey });

		// Send wagers to the server
		this.sendWagersUpdateDebounced(diff.updated, { origWagers: diff.original, isReplace: opts?.isReplace });
	}

	/**
	 * ACTION
	 * Called following a clear of the local wagers that we want to officially register and sync with the server.
	 */
	protected onLocalWagersClear(_diff: ILocalActiveWagersDataDiff) {
		// TODO: Adjust the balance via the WalletManager instance - if wager amount changes results in spend/refund activity
		// To do this, calculate the original total amount of the local wagers, and the new total amount of the local
		// wagers then spend or refund the difference.

		// Clear the wager undo stack
		this.clearUndoWagers();

		// Tell the server to clear all wagers
		return this.sendClearAllWagersDebounced();
	}

	/**
	 * ACTION
	 * Reverts the local wagers data back to the last undo point.
	 */
	protected undoToLastWagers = (): boolean => {
		if (!this.canUndoWagers) {
			return false;
		}

		const undoStack = this._wagerUndoStack;
		const [wagers] = undoStack.pop();

		// Replace the local wagers data with the undo data
		this._activePlayerWagersLocal.data = wagers as ILocalActiveWagersData;
		const isZeroWagers = this._activePlayerWagersLocal.wagerTotals.sumTotals.amount === 0;

		// No wagers means clear all wagers
		if (isZeroWagers) {
			this.sendClearAllWagersDebounced();
		} else {
			this.sendCurrentLocalWagersDebounced({ isReplace: true });
		}

		return true;
	};

	/**
	 * ACTION
	 * Adds the specified wagers to the wager undo stack.
	 */
	protected addWagersToUndoStack = (wagers: ILocalActiveWagersData[], opts?: Maybe<IMethodWagerUndoStackPushOpts>) => {
		this._wagerUndoStack.push(wagers, opts);
	};

	/**
	 * Validates the specified wager set used when updating wager data - ensuring that all wagers are available wagers,
	 * and, that all amounts are valid.
	 *
	 * @returns TRUE if the wager set is valid.
	 */
	protected validateUpdateWagersSet(wagerSet: IWagerManagerMethodUpdateLocalWagerDataWagerSet): boolean {
		const debugMethod = 'validateUpdateWagersSet';

		const availableWagerKeys = Object.keys(wagerSet);

		if (availableWagerKeys.length === 0) {
			this.error('No wagers specified in the wager set.', debugMethod, { wagerSet });
			return false;
		}

		for (const availableWagerKey of availableWagerKeys) {
			if (availableWagerKey === '') {
				this.error('Available wager key required.', debugMethod, { wagerSet });
				return false;
			}

			const wagerData: IUpdateLocalActiveWagerProps = wagerSet[availableWagerKey];
			const amount = wagerData.amount ?? null;
			const currencyCode = wagerData.currencyCode ?? null;

			const availWager = this._availableWagers.get(availableWagerKey);

			// Make sure this wager is an available wager
			if (availWager == null) {
				this.error(`${availableWagerKey}: Wager is not an available wager.`, debugMethod, {
					availableWagerKey,
					wagerData,
					availableWagers: this._availableWagers.list.slice(),
				});
				return false;
			}

			if (!this.isMySeat(availWager.seatNumber)) {
				this.error(
					`${availableWagerKey}: Available wager seat number is not claimed by the active wagering player.`,
					debugMethod,
					{
						availableWagerKey,
						wagerData,
						availWager,
						myClaimedSeats: this._myClaimedSeats,
					}
				);
				return false;
			}

			// Make sure the amount is valid
			if (amount != null) {
				if (amount < 0) {
					this.error(`${availableWagerKey}: Wager amount cannot be less than zero.`, debugMethod, {
						availableWagerKey,
						amount,
					});
					return false;
				}

				// Make sure the amount is within the min/max range for the wager
				const wagerDef = availWager.def;
				const { minAmount = 0, maxAmount = 0 } = wagerDef;

				if (amount > 0 && (amount < minAmount || amount > maxAmount)) {
					this.error(`${availableWagerKey}: Wager amount is outside of min/max range allowed.`, debugMethod, {
						availableWagerKey,
						wagerId: availWager.wagerId,
						amount,
						wagerDef,
						range: { min: minAmount, max: maxAmount },
					});
					return false;
				}
			}

			// Make sure the currency code is valid
			if (currencyCode != null && currencyCode === '') {
				this.error(`${availableWagerKey}: Wager currency code should not be empty string.`, debugMethod, {
					availableWagerKey,
					currencyCode,
				});
				return false;
			}
		}

		return true;
	}

	/**
	 * @returns The first available wager matching the specified wager name and seat number, or, NULL if no match.
	 */
	protected getFirstAvailableWagerByNameAndSeatNumber(
		wagerName: string,
		seatNumber?: Maybe<number>
	): Nullable<IAvailableWagerDataEntry> {
		seatNumber = Math.max(seatNumber ?? 0, 0);

		const availWagers = this._availableWagers.queryBy({ name: wagerName, seatNumber });

		return availWagers.length > 0 ? availWagers[0] ?? null : null;
	}

	/**
	 * ACTION
	 * Initializes the class properties.
	 */
	protected init() {
		this.initTableRelatedStateData();
		this.initPlayRelatedStateData();
	}

	/**
	 * ACTION
	 * Initializes the table-related state data.
	 */
	protected initTableRelatedStateData() {
		const isDebugEnabled = this.isDebugEnabled;
		const tableId = this.tableId;

		this.setDefaultSeatNumber(1);
		this.setMyClaimedSeatsList([this._defaultSeatNumber]);

		// Table seat assignments
		const tableSeatAssignments = new TableSeatAssignments({ isDebugEnabled, tableId });
		this.setTableSeatAssignmentsInstance(tableSeatAssignments);

		// Table Config
		const emptyTableConfig = newEmptyTableConfigDataExt({ tableId });
		this.setTableConfigData(emptyTableConfig);

		// Table Config: Wager Definitions
		const tableConfigWagerDefinitions = new TableConfigWagerDefinitions({ isDebugEnabled, tableId });
		this.setTableConfigWagerDefinitionsInstance(tableConfigWagerDefinitions);

		// Rebet Wagers
		this._rebetWagers = null;
	}

	/**
	 * ACTION
	 * Initializes the play-related state data.
	 */
	protected initPlayRelatedStateData() {
		const isDebugEnabled = this.isDebugEnabled;
		const playId = this.playId;
		const tableId = this.tableId;

		// Wager Definitions (for the play)
		const wagerDefinitions = new PlayWagerDefinitions({ isDebugEnabled, playId });
		this.setWagerDefinitionsInstance(wagerDefinitions);

		// Play seat assignments
		const playSeatAssignments = new PlaySeatAssignments({ isDebugEnabled, tableId, playId });
		this.setPlaySeatAssignmentsInstance(playSeatAssignments);

		// Available Wagers: WagerIds by ContextId
		const emptyPlayAvailableWagersByContext = [newEmptyContextAvailableWagersData()];
		this.setPlayAvailableWagersByContextList(emptyPlayAvailableWagersByContext);

		// Available Wagers: Master List
		const availableWagers = new AvailableWagers({ isDebugEnabled, playId });
		this.setAvailableWagersInstance(availableWagers);

		// Player Wagers: Server
		const serverPlayerWagerState = this.newServerPlayerWagerState();
		this.setServerPlayerWagerStateInstance(serverPlayerWagerState);

		// Player Wagers: Local
		const activePlayerWagersLocal = this.newLocalActiveWagers();
		this.setActivePlayerWagersLocalInstance(activePlayerWagersLocal);

		// Wager Undo Stack
		const wagerUndoStack = new WagerUndoStack({ isDebugEnabled });
		this.setWagerUndoStackInstance(wagerUndoStack);
	}

	/**
	 * @returns A new instance of the `LocalActiveWagers` class configured for this class.
	 */
	protected newLocalActiveWagers(opts?: Maybe<Partial<ILocalActiveWagersOpts>>) {
		const defaultOpts: ILocalActiveWagersOpts = {
			isDebugEnabled: this.isDebugEnabled,
			isNewDataDirtyDefaultVal: this.isNewLocalDataDirtyDefaultVal,
			playId: this.playId,
		};

		return new LocalActiveWagers({
			...defaultOpts,
			...filterNullUndefined(opts ?? {}),
		});
	}

	/**
	 * @returns A new instance of the `ServerPlayerWagerState` class configured for this class.
	 */
	protected newServerPlayerWagerState(opts?: Maybe<Partial<IServerPlayerWagerStateOpts>>) {
		const defaultOpts: IServerPlayerWagerStateOpts = {
			isDebugEnabled: this.isDebugEnabled,
		};

		const instanceOpts = {
			...defaultOpts,
			...filterNullUndefined(opts ?? {}),
		};

		const dataRetrievers = this.newServerPlayerWagerStateDataRetrievers();

		return new ServerPlayerWagerState(dataRetrievers, instanceOpts);
	}

	/**
	 * @returns Data retrievers for the `ServerPlayerWagerState` instance.
	 */
	protected newServerPlayerWagerStateDataRetrievers = (): IServerPlayerWagerStateDataRetrievers => {
		return {
			playId: () => this.playId,
			availableWagersList: () => this._availableWagers.list,
			wagerDefinitionsList: () => this._wagerDefinitions.list,
			playSeatAssignmentsList: () => this._playSeatAssignments.list,
			resolveAmountsOpts: () => this.getResolveAmountsOpts(),
		};
	};

	/**
	 * @returns Currency data for the active currency code.
	 */
	protected getActiveCurrencyData(): IWalletLocalBalanceDataEntry {
		const currencyCode = this.currencyCode;

		return this._walletManager?.getBalanceData(currencyCode) ?? WalletManager.getDefaultBalanceData({ currencyCode });
	}

	/**
	 * @returns The options to use when resolving amounts for the active currency code.
	 */
	protected getResolveAmountsOpts = (): IMethodResolveAmountsOpts => {
		const activeCurrencyData = this.getActiveCurrencyData();

		return {
			currencyCode: activeCurrencyData.currencyCode,
			currencyExponent: activeCurrencyData.currencyExponent,
		};
	};

	/**
	 * ACTION
	 * Resets all table-related state data back to default values.
	 */
	protected resetTableRelatedData(opts?: Maybe<{ tableId?: Maybe<string>; playId?: Maybe<string> }>) {
		opts?.tableId != null && this.setTableId(opts?.tableId, { resetRelatedState: false });
		opts?.playId != null && this.setPlayId(opts?.playId, { resetRelatedState: false });

		this.initTableRelatedStateData();
		this.initPlayRelatedStateData();
	}

	/**
	 * ACTION
	 * Resets all play-related state data back to default values.
	 */
	protected resetPlayRelatedData(opts?: Maybe<{ playId?: Maybe<string> }>) {
		opts?.playId != null && this.setPlayId(opts?.playId, { resetRelatedState: false });

		this.initPlayRelatedStateData();
	}

	/**
	 * Resolves the options being passed in and returns the original and new options.
	 *
	 * - Overrides the base class method.
	 */
	protected override resolveOptions(opts?: Maybe<IWagerManagerOpts>) {
		const origOpts: IWagerManagerOpts = {
			...WagerManager.defaultOptions(),
			...this._options,
		};

		const newOpts: IWagerManagerOpts = {
			...origOpts,
			...(opts ?? {}),
		};

		return { origOpts, newOpts };
	}

	/**
	 * ACTION
	 * Called after new options are set.
	 *
	 * - Extends the base class method.
	 */
	protected override onSetOptions(newOpts: IWagerManagerOpts, _origOpts: IWagerManagerOpts) {
		super.onSetOptions(newOpts);

		if (newOpts.isSingleSeatTable != null && newOpts.isSingleSeatTable !== this._isSingleSeatTable) {
			this.setIsSingleSeatTable(newOpts.isSingleSeatTable);
		}

		if (newOpts.useLocalWagersOnly != null && newOpts.useLocalWagersOnly !== this._useLocalWagersOnly) {
			this._useLocalWagersOnly = newOpts.useLocalWagersOnly;
		}

		if (newOpts.defaultSeatNumber != null && newOpts.defaultSeatNumber !== this._defaultSeatNumber) {
			if (this._isSingleSeatTable) {
				this.warn('Cannot set default seat number when table is a single seat table.');
			} else {
				this.setDefaultSeatNumber(newOpts.defaultSeatNumber);
			}
		}

		const origPlayerId = this._playerId;
		const origTableId = this._tableId;
		const origPlayId = this._playId;

		if (newOpts.playerId != null && newOpts.playerId !== this._playerId) {
			this._playerId = newOpts.playerId;
		}
		if (newOpts.tableId != null && newOpts.tableId !== this._tableId) {
			this._tableId = newOpts.tableId;
		}
		if (newOpts.playId != null && newOpts.playId !== this._playId) {
			this._playId = newOpts.playId;
		}

		if (this._playerId !== origPlayerId) {
			this.onPlayerIdChanged(this._playerId, origPlayerId);
			this._tableId !== origTableId && this.onTableIdChanged(this._tableId, origTableId, { resetRelatedState: false });
			this._playId !== origPlayId && this.onPlayIdChanged(this._playId, origPlayId, { resetRelatedState: false });
		} else if (this._tableId !== origTableId) {
			this.onTableIdChanged(this._tableId, origTableId);
			this._playId !== origPlayId && this.onPlayIdChanged(this._playId, origPlayId, { resetRelatedState: false });
		} else if (this._playId !== origPlayId) {
			this.onPlayIdChanged(this._playId, origPlayId);
		}

		if (newOpts.walletManager != null && newOpts.walletManager.instanceId !== this._walletManager?.instanceId) {
			this.setWalletManager(newOpts.walletManager);
		}
	}

	/**
	 * Called after the class instance `playerId` property changes.
	 */
	protected onPlayerIdChanged(
		newPlayerId: string,
		prevPlayerId: string,
		opts?: Maybe<{ resetRelatedState?: Maybe<boolean> }>
	) {
		this.issueOnPlayerIdChangedEvent(newPlayerId, prevPlayerId);

		// If the previous player ID is empty, then we are initializing the player for the first time, we don't need to
		// do anything further
		if (prevPlayerId === '') {
			return;
		}

		// When changing or clearing the player we will most likely reset table & play related state data.
		// This will also clear the current table & play ID and reset table & play related state data - since a lot of the
		// table and play data is player-specific.
		const resetRelatedState = opts?.resetRelatedState ?? true;
		resetRelatedState && this.resetTableRelatedData({ tableId: '', playId: '' });
	}

	/**
	 * Called after the class instance `tableId` property changes.
	 */
	protected onTableIdChanged(
		newTableId: string,
		prevTableId: string,
		opts?: Maybe<{ resetRelatedState?: Maybe<boolean> }>
	) {
		this.setTableIdForDeps(newTableId);
		this.issueOnTableIdChangedEvent(newTableId, prevTableId);

		// If the previous table ID is empty, then we are initializing the table for the first time, we don't need to
		// do anything further.
		if (prevTableId === '') {
			return;
		}

		// When changing or clearing the table we will most likely reset table related state data.
		// This will also clear the current play ID and reset play related state data - since the play is specific
		// to the table.
		const resetRelatedState = opts?.resetRelatedState ?? true;
		resetRelatedState && this.resetTableRelatedData({ playId: '' });
	}

	/**
	 * Called after the class instance `_playId` property changes.
	 */
	protected onPlayIdChanged(
		newPlayId: string,
		prevPlayId: string,
		opts?: Maybe<{ resetRelatedState?: Maybe<boolean> }>
	) {
		this.setPlayIdForDeps(newPlayId);
		this.issueOnPlayIdChangedEvent(newPlayId, prevPlayId);

		// If the previous play ID is empty, then we are initializing the play for the first time, we don't need to
		// do anything further.
		if (prevPlayId === '') {
			return;
		}

		// When changing or clearing the play we will most likely reset play related state data.
		const resetRelatedState = opts?.resetRelatedState ?? true;
		resetRelatedState && this.resetPlayRelatedData();
	}

	/**
	 * ACTION
	 * Called after the class instance `_isSingleSeatTable` property changes.
	 */
	protected onIsSingleSeatTableChanged(newVal: boolean, prevVal: boolean) {
		// Single-seat table ---> Multi-seat table
		if (prevVal === false && newVal === true) {
			this.initClaimedSeatingForMultiSeatTable();
		}
		// Multi-seat table ---> Single-seat table
		else if (prevVal === true && newVal === false) {
			this.setDefaultSeatNumber(1);
			this.setMyClaimedSeatsList([this._defaultSeatNumber]);
		}
	}

	/**
	 * ACTION
	 * Sets the table ID for the various dependencies.
	 */
	protected setTableIdForDeps(tableId: string) {
		this._tableConfig.tableId = tableId;
		this._tableSeatAssignments.tableId = tableId;
		this._tableConfigWagerDefinitions.tableId = tableId;
		this._playSeatAssignments.tableId = tableId;
	}

	/**
	 * ACTION
	 * Sets the play ID for the various dependencies.
	 */
	protected setPlayIdForDeps(playId: string) {
		this._wagerDefinitions.playId = playId;
		this._playSeatAssignments.playId = playId;
		this._availableWagers.playId = playId;
		this._activePlayerWagersLocal.playId = playId;
	}

	/**
	 * ACTION
	 * Sets the `_playAvailableWagersByContext` list instance.
	 */
	protected setPlayAvailableWagersByContextList(list: IContextAvailableWagersData[]): void {
		if (this.isMobXBound) {
			set(this, '_playAvailableWagersByContext', list);
		} else {
			this._playAvailableWagersByContext = list;
		}
	}

	/**
	 * ACTION
	 * Sets the `_tableConfig` data instance.
	 */
	protected setTableConfigData(data: ITableConfigDataExt): void {
		if (this.isMobXBound) {
			set(this, '_tableConfig', data);
		} else {
			this._tableConfig = data;
		}
	}

	/**
	 * ACTION
	 * Sets the `_tableConfigWagerDefinitions` data instance.
	 */
	protected setTableConfigWagerDefinitionsInstance(instance: ITableConfigWagerDefinitions): void {
		if (this.isMobXBound) {
			set(this, '_tableConfigWagerDefinitions', instance);
		} else {
			this._tableConfigWagerDefinitions = instance;
		}
	}

	/**
	 * ACTION
	 * Sets the `_wagerDefinitions` data instance.
	 */
	protected setWagerDefinitionsInstance(instance: IPlayWagerDefinitions): void {
		if (this.isMobXBound) {
			set(this, '_wagerDefinitions', instance);
		} else {
			this._wagerDefinitions = instance;
		}
	}

	/**
	 * ACTION
	 * Sets the `_availableWagers` data instance.
	 */
	protected setAvailableWagersInstance(instance: IAvailableWagers): void {
		if (this.isMobXBound) {
			set(this, '_availableWagers', instance);
		} else {
			this._availableWagers = instance;
		}
	}

	/**
	 * ACTION
	 * Sets the `_walletManager` data instance.
	 */
	protected setWalletManagerInstance(instance: IWalletManager): void {
		if (this.isMobXBound) {
			set(this, '_walletManager', instance);
		} else {
			this._walletManager = instance;
		}
	}

	/**
	 * ACTION
	 * Sets the `_serverPlayerWagerState` data instance.
	 */
	protected setServerPlayerWagerStateInstance(instance: IServerPlayerWagerState): void {
		if (this.isMobXBound) {
			set(this, '_serverPlayerWagerState', instance);
		} else {
			this._serverPlayerWagerState = instance;
		}
	}

	/**
	 * ACTION
	 * Sets the `_activePlayerWagersLocal` data instance.
	 */
	protected setActivePlayerWagersLocalInstance(instance: ILocalActiveWagers): void {
		if (this.isMobXBound) {
			set(this, '_activePlayerWagersLocal', instance);
		} else {
			this._activePlayerWagersLocal = instance;
		}
	}

	/**
	 * ACTION
	 * Sets the `_tableSeatAssignments` data instance.
	 */
	protected setTableSeatAssignmentsInstance(instance: ITableSeatAssignments): void {
		if (this.isMobXBound) {
			set(this, '_tableSeatAssignments', instance);
		} else {
			this._tableSeatAssignments = instance;
		}
	}

	/**
	 * ACTION
	 * Sets the `_playSeatAssignments` data instance.
	 */
	protected setPlaySeatAssignmentsInstance(instance: IPlaySeatAssignments): void {
		if (this.isMobXBound) {
			set(this, '_playSeatAssignments', instance);
		} else {
			this._playSeatAssignments = instance;
		}
	}

	/**
	 * ACTION
	 * Sets the `_wagerUndoStack` data instance.
	 */
	protected setWagerUndoStackInstance(instance: IWagerUndoStack): void {
		if (this.isMobXBound) {
			set(this, '_wagerUndoStack', instance);
		} else {
			this._wagerUndoStack = instance;
		}
	}

	/**
	 * ACTION
	 * Sets the `_myClaimedSeats` data list instance.
	 */
	protected setMyClaimedSeatsList(list: number[]): void {
		if (this.isMobXBound) {
			set(this, '_myClaimedSeats', list);
		} else {
			this._myClaimedSeats = list;
		}
	}

	/**
	 * ACTION
	 * Sets the `_rebetWagers` data list instance.
	 */
	protected setRebetWagersData(data: Nullable<ILocalActiveWagersData>): void {
		if (this.isMobXBound) {
			set(this, '_rebetWagers', data);
		} else {
			this._rebetWagers = data;
		}
	}

	/* #endregion ::Other:: */

	/* #endregion ---- Protected ------------------------------------------------------------------------------------- */

	/* #region ---- Derived / Memoized ------------------------------------------------------------------------------- */

	/**
	 * ACTION
	 * Will undo the last wager change made by the active player.
	 */
	public undoWagers = throttle(this.undoToLastWagers, 500, { leading: true, trailing: false });

	/**
	 * ACTION
	 * Sets the server wagers data using the raw wagers list from the server and sync the local wagers.
	 * Debounces to only run {X}ms after the last time it was called - prevents multiple back-to-back updates.
	 */
	// TODO: Refactor me out once all clients use the SDK - I should be used via the `updateFromPlayData` method.
	public setActivePlayerWagers = debounce(this.updateFromRawPlayerWagersData, 500, {
		leading: false,
		trailing: true,
	});

	/**
	 * ACTION
	 * Uses the entire `GetTable` data payload to sync various state data.
	 * Debounces to only run {X}ms after the last time it was called - prevents multiple back-to-back updates.
	 */
	public setTableData = debounce(this.updateFromTableData, 200, {
		leading: false,
		trailing: true,
	});

	/**
	 * ACTION
	 * Uses the entire `GetPlay` data payload to sync various state data.
	 * Debounces to only run {X}ms after the last time it was called - prevents multiple back-to-back updates.
	 */
	public setPlayData = debounce(this.updateFromPlayData, 100, {
		leading: false,
		trailing: true,
	});

	/**
	 * Sends the current local wagers to the server.
	 * Debounces to only run {X}ms after the last time it was called - prevents multiple back-to-back updates.
	 */
	protected sendCurrentLocalWagersDebounced = debounce(this.sendCurrentLocalWagers, 500, {
		leading: true,
		trailing: true,
	});

	/**
	 * Sends the result of a local wager update to the server. This uses the original local wager data and the new wager data.
	 * Debounces to only run {X}ms after the last time it was called - prevents multiple back-to-back updates.
	 */
	protected sendWagersUpdateDebounced = debounce(this.sendWagersUpdate, 500, {
		leading: true,
		trailing: true,
	});

	/**
	 * Sends the the clear all wagers command to the server.
	 * Debounces to only run {X}ms after the last time it was called - prevents multiple back-to-back updates.
	 */
	protected sendClearAllWagersDebounced = debounce(this.sendClearAllWagers, 500, {
		leading: true,
		trailing: true,
	});

	/* #endregion ---- Derived / Memoized ---------------------------------------------------------------------------- */

	/* #region ---- Static ------------------------------------------------------------------------------------------- */

	/**
	 * STATIC
	 * @returns The default options data used by this class.
	 */
	public static defaultOptions(): IWagerManagerOpts {
		return {
			...ManagerBase.defaultOptions(),
			walletManager: null,
			defaultSeatNumber: 1,
			playerId: null,
			tableId: null,
			playId: null,
			isSingleSeatTable: true,
			useLocalWagersOnly: false,
		};
	}

	/* #endregion ---- Static ---------------------------------------------------------------------------------------- */

	/* #region ---- Events ------------------------------------------------------------------------------------------- */

	/**
	 * Issue a `PLAYER_ID_CHANGED` event.
	 */
	protected issueOnPlayerIdChangedEvent(newValue: string, prevValue: string) {
		const eventData = { newValue, prevValue };
		this.trigger(Events.PLAYER_ID_CHANGED, eventData);

		// TODO: Remove me eventually
		this.info(Events.PLAYER_ID_CHANGED, 'Event', eventData);
	}

	/**
	 * Issue a `TABLE_ID_CHANGED` event.
	 */
	protected issueOnTableIdChangedEvent(newValue: string, prevValue: string) {
		const eventData = { newValue, prevValue };
		this.trigger(Events.TABLE_ID_CHANGED, eventData);

		// TODO: Remove me eventually
		this.info(Events.TABLE_ID_CHANGED, 'Event', eventData);
	}

	/**
	 * Issue a `PLAY_ID_CHANGED` event.
	 */
	protected issueOnPlayIdChangedEvent(newValue: string, prevValue: string) {
		const eventData = { newValue, prevValue };
		this.trigger(Events.PLAY_ID_CHANGED, eventData);

		// TODO: Remove me eventually
		this.info(Events.PLAY_ID_CHANGED, 'Event', eventData);
	}

	/* #endregion ---- Events ---------------------------------------------------------------------------------------- */

	/* #region ---- Debug -------------------------------------------------------------------------------------------- */

	/**
	 * Overrides the base class property.
	 * @returns The label to use when debugging.
	 */
	protected override get debugClassLabel(): string {
		return WagerManager.debugClassLabel();
	}

	/**
	 * STATIC
	 * @returns Label assigned to this class namespace.
	 */
	protected static debugClassLabel(): string {
		return `RpcLib.Manager.WagerManager`;
	}

	/**
	 * Called when the debug enabled state is changed.
	 *
	 * - Extends the base class method.
	 */
	protected override onSetDebugEnabled(isEnabled: boolean) {
		super.onSetDebugEnabled(isEnabled);

		if (this._tableConfigWagerDefinitions) {
			this._tableConfigWagerDefinitions.isDebugEnabled = isEnabled;
		}
		if (this._wagerDefinitions) {
			this._wagerDefinitions.isDebugEnabled = isEnabled;
		}
		if (this._availableWagers) {
			this._availableWagers.isDebugEnabled = isEnabled;
		}
		if (this._activePlayerWagersLocal) {
			this._activePlayerWagersLocal.isDebugEnabled = isEnabled;
		}
		if (this._serverPlayerWagerState) {
			this._serverPlayerWagerState.isDebugEnabled = isEnabled;
		}
	}

	/* #endregion ---- Debug ----------------------------------------------------------------------------------------- */
}

// ---- Exports -------------------------------------------------------------------------------------------------------

export { WagerManager as default };
export { WagerManager };
