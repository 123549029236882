import { copyAnyObjectList, makeUniqId } from '../../../../helpers';
import {
	extendActiveWagerData,
	extendActiveWagerDataFullDef,
	IExtendActiveWagerDataFullDefOpts,
} from '../../activeWagersExt';
import { makeAvailableWagerKeyToWagerDataLookupFromList } from '../../availableWagersExt';
import { makeSeatIdToPlaySeatDataLookupFromList } from '../../playSeatExt';
import { makeWagerIdToDefinitionLookupFromList } from '../../playWagerDefinitionExt';
import { generateObjectListHashId } from '../../utility';
import {
	ActiveWagerDataList,
	ActiveWagerDataLookup,
	IActiveWagerDataEntry,
	IActiveWagersData,
	IMethodActiveWagersMapRawDataOpts,
	RawActiveWagerEntry,
	RawActiveWagerList,
} from './types';

/**
 * @returns The default data used by the `ActiveWagers` class.
 */
const defaultData = (opts?: Maybe<{ updatedTs?: Maybe<number>; playId?: Maybe<string> }>): IActiveWagersData => {
	return {
		raw: null,
		lookup: new Map<string, IActiveWagerDataEntry>(),
		lastUpdatedTs: opts?.updatedTs ?? 0,
		playId: opts?.playId ?? '',
		uniqId: makeUniqId(),
		hashId: '',
	};
};

/**
 * @returns A unique hash ID for the specified list of raw data entries.
 */
const generateRawListHashId = (list: RawActiveWagerList): string => {
	return generateObjectListHashId<RawActiveWagerEntry>(list, [
		'wagerKey',
		'wagerTypeId',
		'currency',
		'contextId',
		'amount',
		'sequence',
	]);
};

/**
 * @returns A unique hash ID for the specified list of data entries.
 */
const generateDataListHashId = (list: ActiveWagerDataList): string => {
	return generateObjectListHashId<IActiveWagerDataEntry>(list, [
		'wagerId',
		'activeWagerId',
		'contextId',
		'availableWagerKey',
		'name',
		'currencyCode',
		'seatNumber',
		'amount',
		'sequence',
	]);
};

/**
 * @returns TRUE if the specified raw data lists are the same - in terms of the meaningful data.
 */
const isRawListSameData = (list1: RawActiveWagerList, list2: RawActiveWagerList): boolean => {
	if (list1 === list2) {
		return true;
	}
	if (list1.length !== list2.length) {
		return false;
	}

	const hashId1 = generateRawListHashId(list1);
	const hashId2 = generateRawListHashId(list2);

	return hashId1 === hashId2;
};

/**
 * Maps the raw data list sent by the server and resolves to the relevant encapsulated data.
 */
const newDataFromRawList = (
	srcList: RawActiveWagerList,
	playId: string,
	opts?: Maybe<IMethodActiveWagersMapRawDataOpts>
): IActiveWagersData => {
	const { updatedTs } = opts ?? {};
	const lastUpdatedTs: number = updatedTs || Date.now();

	if (srcList.length === 0) {
		return defaultData({ updatedTs: lastUpdatedTs, playId });
	}

	const lookup = new Map<string, IActiveWagerDataEntry>();

	const availableWagersLookup = makeAvailableWagerKeyToWagerDataLookupFromList(opts?.availableWagersList ?? []);
	const wagerDefinitionsLookup = makeWagerIdToDefinitionLookupFromList(opts?.wagerDefinitionsList ?? []);
	const playSeatBySeatIdLookup = makeSeatIdToPlaySeatDataLookupFromList(opts?.playSeatAssignmentsList ?? []);

	const extendDataOpts = { ...opts?.extendDataOpts, playId };

	const mappedList: IActiveWagerDataEntry[] = [];

	srcList.forEach((entry: RawActiveWagerEntry) => {
		const entryExt = extendActiveWagerData(entry, extendDataOpts);
		const wagerId = entryExt.wagerId;
		const availableWagerKey = entryExt.availableWagerKey;
		const availWager = availableWagersLookup.get(availableWagerKey) ?? null;
		const wagerDef = wagerDefinitionsLookup.get(wagerId) ?? availWager?.def ?? null;

		const extendFullDefOpts: IExtendActiveWagerDataFullDefOpts = {
			...extendDataOpts,
			avail: availWager,
			def: wagerDef,
			playSeatAssignments: playSeatBySeatIdLookup,
		};

		const entryFull = extendActiveWagerDataFullDef(entryExt, extendFullDefOpts);

		const item: IActiveWagerDataEntry = {
			...entryFull,
			lastUpdatedTs,
		};

		mappedList.push(item);
	});

	mappedList.sort((a: IActiveWagerDataEntry, b: IActiveWagerDataEntry): number => {
		// Sort by wager key (ASC)
		const wagerKeyCompare = a.activeWagerKey.localeCompare(b.activeWagerKey);
		if (wagerKeyCompare !== 0) {
			return wagerKeyCompare;
		}

		// Sort by sequence (DESC)
		return b.sequence - a.sequence;
	});

	mappedList.forEach((item: IActiveWagerDataEntry) => {
		const activeWagerKey = item.activeWagerKey;

		// Only add the first entry for each wager key - this has the highest sequence number.
		!lookup.has(activeWagerKey) && lookup.set(activeWagerKey, item);
	});

	const list = Array.from(lookup.values());
	const hashId = generateDataListHashId(list);
	const uniqId = makeUniqId();

	const rawList = srcList.slice();
	const rawHashId = generateRawListHashId(rawList);

	return {
		raw: { list: rawList, hashId: rawHashId },
		lookup,
		lastUpdatedTs,
		uniqId,
		hashId,
		playId,
	};
};

/**
 * @returns A copy of the specified raw data list.
 */
const copyRawList = (list: RawActiveWagerList): RawActiveWagerList => {
	return copyAnyObjectList<RawActiveWagerEntry>(list);
};

/**
 * @returns A copy of the specified data list.
 */
const copyList = (list: ActiveWagerDataList): ActiveWagerDataList => {
	return copyAnyObjectList<IActiveWagerDataEntry>(list);
};

/**
 * @returns A copy of the specified data lookup.
 */
const copyLookup = (lookup: ActiveWagerDataLookup): ActiveWagerDataLookup => {
	const copy = new Map<string, IActiveWagerDataEntry>();
	for (const [key, value] of lookup) {
		copy.set(key, { ...value });
	}

	return copy;
};

/**
 * @returns A copy of the specified encapsulated data.
 */
const copyData = (data: IActiveWagersData, opts?: Maybe<{ updatedTs?: Maybe<number> }>): IActiveWagersData => {
	const newData: IActiveWagersData = defaultData();
	newData.lastUpdatedTs = opts?.updatedTs ?? data.lastUpdatedTs;
	newData.lookup = copyLookup(data.lookup);
	newData.hashId = data.hashId;
	newData.playId = data.playId;
	newData.raw = data.raw != null ? { list: copyRawList(data.raw.list), hashId: data.raw.hashId } : null;

	return newData;
};

// ---- Exports -------------------------------------------------------------------------------------------------------

export { copyData, copyList, copyLookup, copyRawList, defaultData };
export { generateRawListHashId, generateDataListHashId, isRawListSameData };
export { newDataFromRawList };
