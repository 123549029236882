import isFunction from 'lodash/isFunction';
import { reaction } from 'mobx';
import { DebugBase } from '../../../common';
import { filterNullUndefined } from '../../../helpers';
import {
	DeviceStore,
	IPlayStore,
	IPlayStoreOpts,
	IStoreBase,
	PlayStore,
	SettingsStore,
	TableStore,
	UserStore,
} from '../../../store';
import { IServices } from '../../services';
import { DefaultStoreFactory } from '../StoreFactory';
import { IStoreManager, IStoreManagerOpts, IStores } from './types';

/**
 * Manages the creation and initialization of the default player stores.
 */
class StoreManager<
		PlayStoreType extends IPlayStore = PlayStore,
		PlayStoreOptsType extends IPlayStoreOpts = IPlayStoreOpts
	>
	extends DebugBase
	implements IStoreManager<PlayStoreType, PlayStoreOptsType>
{
	/* #region ---- Properties --------------------------------------------------------------------------------------- */

	/**
	 * Currently assigned options.
	 */
	protected override _options: IStoreManagerOpts<PlayStoreType, PlayStoreOptsType> = StoreManager.defaultOptions();

	/**
	 * Instance of the Services SDK class.
	 */
	protected _services: IServices;

	/**
	 * Instance of the `PlayStore` class.
	 */
	protected _playStore!: PlayStoreType;

	/**
	 * Instance of the `SettingsStore` class.
	 */
	protected _settingsStore!: SettingsStore;

	/**
	 * Instance of the `TableStore` class.
	 */
	protected _tableStore!: TableStore;

	/**
	 * Instance of the `UserStore` class.
	 */
	protected _userStore!: UserStore;

	/**
	 * Instance of the `DeviceStore` class.
	 */
	protected _deviceStore!: DeviceStore;

	/**
	 * TRUE if the class has been initialized.
	 */
	protected _isInitialized: boolean = false;

	/**
	 * TRUE if debug reactions have been added.
	 */
	protected _hasDebugReactions: boolean = false;

	/* #endregion ---- Properties ------------------------------------------------------------------------------------ */

	/* #region ---- CONSTRUCTOR ---------------------------------------------------------------------------------------*/

	constructor(services: IServices, opts?: Maybe<IStoreManagerOpts<PlayStoreType, PlayStoreOptsType>>) {
		super();
		this._services = services;

		opts != null && this.setOptions(opts);

		// Initialize immediately if specified via options
		const init = this._options?.init ?? true;
		init && this.init();
	}

	/* #endregion ---- CONSTRUCTOR ------------------------------------------------------------------------------------*/

	/* #region ---- Public ------------------------------------------------------------------------------------------- */

	/**
	 * Sets/initializes the class options.
	 *
	 * - Overrides the parent class method.
	 */
	public override setOptions(opts: IStoreManagerOpts<PlayStoreType, PlayStoreOptsType>) {
		const { newOpts } = this.resolveOptions(opts);
		this._options = newOpts;
		this.onSetOptions(newOpts);
	}

	/**
	 * Initializes the class instance.
	 */
	public init(opts?: Maybe<IStoreManagerOpts<PlayStoreType, PlayStoreOptsType>>): boolean {
		opts = opts != null ? this.resolveOptions(opts).newOpts : this._options;

		if (this._isInitialized) {
			return false;
		}

		// TODO: Refactor this to be like the `Managers` class is done and pass isDebugEnabled and useMobX to each store
		const isDebugEnabled = opts?.isDebugEnabled ?? null;
		const storeDebugLabel = opts?.storeDebugLabel ?? null;
		const useMobX = opts?.useMobX ?? null;

		const factoryOverrides = filterNullUndefined({ isDebugEnabled, useMobX, debugLabel: storeDebugLabel });

		// ---- PlayStore -------------------------------------------------

		const psOpts = opts?.playStore;
		if (psOpts?.instance) {
			this._playStore = psOpts.instance;
		} else {
			const storeOpts = psOpts?.opts;
			const props = { storeOpts, ...factoryOverrides };
			this._playStore = DefaultStoreFactory.newPlayStore<PlayStoreType>(this._services, props);
		}

		// ---- SettingsStore ----------------------------------------------

		const ssOpts = opts?.settingsStore;
		if (ssOpts?.instance) {
			this._settingsStore = ssOpts.instance;
		} else {
			const storeOpts = ssOpts?.opts;
			const props = { storeOpts, ...factoryOverrides };
			this._settingsStore = DefaultStoreFactory.newSettingsStore(props);
		}

		// ---- TableStore -----------------------------------------------

		const tsOpts = opts?.tableStore;
		if (tsOpts?.instance) {
			this._tableStore = tsOpts.instance;
		} else {
			const storeOpts = tsOpts?.opts;
			const props = { storeOpts, ...factoryOverrides };
			this._tableStore = DefaultStoreFactory.newTableStore(this._services, props);
		}

		// ---- UserStore ------------------------------------------------

		const usOpts = opts?.userStore;
		if (usOpts?.instance) {
			this._userStore = usOpts.instance;
		} else {
			const storeOpts = usOpts?.opts;
			const props = { storeOpts, ...factoryOverrides };
			this._userStore = DefaultStoreFactory.newUserStore(this._services, props);
		}

		// ---- DeviceStore -----------------------------------------------

		const dsOpts = opts?.deviceStore;
		if (dsOpts?.instance) {
			this._deviceStore = dsOpts.instance;
		} else {
			const storeOpts = dsOpts?.opts;
			const props = { storeOpts, ...factoryOverrides };
			this._deviceStore = DefaultStoreFactory.newDeviceStore(this._services, props);
		}

		// Add debugging reactions if enabled
		if (isDebugEnabled) {
			this.addDebugReactions();
		}

		this._isInitialized = true;

		return true;
	}

	/**
	 * Gets/sets the play store instance.
	 */
	public get playStore(): PlayStoreType {
		return this._playStore;
	}
	public set playStore(store: PlayStoreType) {
		this._playStore = store;
	}

	/**
	 * Gets/sets the settings store instance.
	 */
	public get settingsStore(): SettingsStore {
		return this._settingsStore;
	}
	public set settingsStore(store: SettingsStore) {
		this._settingsStore = store;
	}

	/**
	 * Gets/sets the table store instance.
	 */
	public get tableStore(): TableStore {
		return this._tableStore;
	}
	public set tableStore(store: TableStore) {
		this._tableStore = store;
	}

	/**
	 * Gets/sets the user store instance.
	 */
	public get userStore(): UserStore {
		return this._userStore;
	}
	public set userStore(store: UserStore) {
		this._userStore = store;
	}

	/**
	 * Gets/sets the device store instance.
	 */
	public get deviceStore(): DeviceStore {
		return this._deviceStore;
	}
	public set deviceStore(store: DeviceStore) {
		this._deviceStore = store;
	}

	/**
	 * @returns Key/value pair of all store instances.
	 */
	public getStores(): IStores<PlayStoreType> {
		return {
			playStore: this.playStore,
			settingsStore: this.settingsStore,
			tableStore: this.tableStore,
			userStore: this.userStore,
			deviceStore: this.deviceStore,
		};
	}

	/**
	 * Clears all stores managed by this class.
	 */
	public clearStores(): void {
		Object.values(this.getStores()).forEach((val) => {
			const store = val as IStoreBase;
			isFunction(store.clear) && store.clear();
		});
	}

	/**
	 * @returns A JSON export of the pertinent data.
	 */
	public toJson(extended?: Maybe<boolean>): PlainObject {
		extended = extended ?? false;

		const toJs = (val: unknown, useMobXToJs?: Maybe<boolean>) => DebugBase.toJs(val, { extended, useMobXToJs });

		const result: PlainObject = {
			stores: toJs(this.getStores(), true),
		};

		if (extended) {
			result.isInitialized = this._isInitialized;
			result.options = { ...this._options };
			result.services = this._services.toJson(extended);
			result._Debug = { ...super.toJson(extended), hasDebugReactions: this._hasDebugReactions };
		}

		return result;
	}

	/* #endregion ---- Public ---------------------------------------------------------------------------------------- */

	/* #region ---- Protected ---------------------------------------------------------------------------------------- */

	/**
	 * Resolves the options being passed in and returns the original and new options.
	 *
	 * - Overrides the parent class method.
	 */
	protected override resolveOptions(opts?: Maybe<IStoreManagerOpts<PlayStoreType, PlayStoreOptsType>>) {
		const origOpts: IStoreManagerOpts<PlayStoreType, PlayStoreOptsType> = {
			...StoreManager.defaultOptions(),
			...this._options,
		};

		const newOpts: IStoreManagerOpts<PlayStoreType, PlayStoreOptsType> = {
			...origOpts,
			...(opts ?? {}),
		};

		return { origOpts, newOpts };
	}

	/**
	 * Called when the debug enabled state is changed.
	 *
	 * - Extends the parent class method.
	 */
	protected override onSetDebugEnabled(isEnabled: boolean) {
		super.onSetDebugEnabled(isEnabled);

		if (isEnabled) {
			this.addDebugReactions();
		}
	}

	/**
	 * Adds debugging reactions to the store instances.
	 */
	protected addDebugReactions(): boolean {
		if (this._hasDebugReactions || !this._isInitialized) {
			return false;
		}

		const { playStore, settingsStore, tableStore, userStore, deviceStore } = this.getStores();
		const toJs = (val: unknown) => DebugBase.toJs(val, { useMobXToJs: true });

		// ---- Table Store ----------------------------------

		if (tableStore != null) {
			reaction(
				() => tableStore.tableId,
				(value: string, prev: string) => {
					this.info(`Table ID change: '${prev}' --> '${value}'`, 'TableStore');
				}
			);
			reaction(
				() => tableStore.lastUpdatedTs,
				() => {
					this.info(`Table data updated:`, 'TableStore', { data: toJs(tableStore) });
				}
			);
		}

		// ---- Play Store -----------------------------------

		if (playStore != null) {
			reaction(
				() => playStore.lastUpdatedTs,
				() => {
					this.info(`Play data updated:`, playStore.className, { data: toJs(playStore) });
				}
			);
		}

		// ---- Settings Store -------------------------------

		if (settingsStore != null) {
			reaction(
				() => settingsStore.lastUpdatedTs,
				() => {
					this.info(`Settings data updated:`, 'SettingsStore', { data: toJs(settingsStore) });
				}
			);
		}

		// ---- User Store -----------------------------------

		if (userStore != null) {
			reaction(
				() => userStore.lastUpdatedTs,
				() => {
					this.info(`User data updated:`, 'UserStore', { data: toJs(userStore) });
				}
			);
			reaction(
				() => userStore.playerId,
				(value: string, prev: string) => {
					this.info(`Player ID change: ${prev} --> ${value}`, 'UserStore');
				}
			);
		}

		// ---- Device Store -----------------------------------

		if (deviceStore != null) {
			reaction(
				() => deviceStore.lastUpdatedTs,
				() => {
					this.info(`Device data updated:`, 'DeviceStore', { data: toJs(deviceStore) });
				}
			);
			reaction(
				() => deviceStore.deviceId,
				(value: string, prev: string) => {
					this.info(`Device ID change: ${prev} --> ${value}`, 'DeviceStore');
				}
			);
		}

		this._hasDebugReactions = true;

		return true;
	}

	/* #endregion ---- Protected ------------------------------------------------------------------------------------- */

	/* #region ---- Static ------------------------------------------------------------------------------------------- */

	/**
	 * STATIC
	 * @returns The default options data used by this class.
	 */
	public static defaultOptions<
		PlayStoreType extends IPlayStore = PlayStore,
		IPlayStoreOptsType extends IPlayStoreOpts = IPlayStoreOpts
	>(): IStoreManagerOpts<PlayStoreType, IPlayStoreOptsType> {
		return {
			...DebugBase.defaultOptions(),
			init: true,
			useMobX: true,
			storeDebugLabel: null,
			userStore: null,
			tableStore: null,
			playStore: null,
			settingsStore: null,
			deviceStore: null,
		};
	}

	/* #endregion ---- Static ---------------------------------------------------------------------------------------- */

	/* #region ---- Debug -------------------------------------------------------------------------------------------- */

	/**
	 * Overrides the parent class property.
	 *
	 * @returns The label to use when debugging.
	 */
	protected override get debugClassLabel(): string {
		return StoreManager.debugClassLabel();
	}

	/**
	 * STATIC
	 * @returns Label assigned to this class namespace.
	 */
	protected static debugClassLabel(): string {
		return `RpcLib.Sdk.StoreManager`;
	}

	/* #endregion ---- Debug ----------------------------------------------------------------------------------------- */
}

// ---- Exports -------------------------------------------------------------------------------------------------------

export { StoreManager as default };
export { StoreManager };
