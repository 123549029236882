import { action, AnnotationsMap, computed, makeObservable, observable } from 'mobx';
import { DebugBaseMobXAnnotations } from '../../../../common';
import { TableSeatAssignments } from './TableSeatAssignments';
import { TableSeatAssignmentsMobXPrivateProps } from './types.mobx';

const TableSeatAssignmentsMobXAnnotations: AnnotationsMap<TableSeatAssignments, TableSeatAssignmentsMobXPrivateProps> =
	{
		// Inherit from DebugBase
		...DebugBaseMobXAnnotations,

		// ---- Observed -----------------------------------------------------

		// >> Properties
		_data: observable.struct,

		// ---- Computed -----------------------------------------------------

		// >> Public
		data: computed,
		isEmpty: computed,
		size: computed,
		lookup: computed,
		list: computed,
		lastUpdatedTs: computed,
		dataHashId: computed,

		// ---- Actions ------------------------------------------------------

		// >> Public
		clear: action,
		populate: action,

		// >> Protected
		setLastUpdatedTs: action,
		setData: action,

		// ---- Excluded -----------------------------------------------------

		// >> Properties
		_isMobXBound: false,

		// >> Public
		isMobXBound: false,
		get: false,
		has: false,
		clone: false,
		toJson: false,
		isRawDataSame: false,
	};

/**
 * Makes the specified `TableSeatAssignments` instance an observable MobX object.
 */
const bindTableSeatAssignmentsMobX = (instance: TableSeatAssignments): boolean => {
	if (instance.isMobXBound) {
		return false;
	}

	makeObservable<TableSeatAssignments, TableSeatAssignmentsMobXPrivateProps>(instance, {
		...TableSeatAssignmentsMobXAnnotations,
	});

	instance.isMobXBound = true;

	return true;
};

// ---- Exports -------------------------------------------------------------------------------------------------------

export { TableSeatAssignmentsMobXAnnotations, bindTableSeatAssignmentsMobX };
