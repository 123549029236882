import isArray from 'lodash/isArray';
import isString from 'lodash/isString';
import { renderTemplate } from '../string';
import ConfigAdapterBase from './ConfigAdapterBase';
import { DEFAULT_DEPLOYMENT, DEFAULT_SERVER_DOMAIN } from './constants';
import {
	ConfigVarsLdx as ConfigVars,
	DEFAULT_DOMAIN_NAME,
	DEFAULT_LDX_DEALER_SERVER_SUBDOMAIN,
	DEFAULT_LDX_DEALER_SERVER_TEMPLATE,
	DEFAULT_LDX_DEV_SERVER_SUBDOMAIN,
	DEFAULT_LDX_DEV_SERVER_TEMPLATE,
	DEFAULT_LDX_DEVICE_SERVER_SUBDOMAIN,
	DEFAULT_LDX_DEVICE_SERVER_TEMPLATE,
	DEFAULT_LDX_GAME_SERVER_SUBDOMAIN,
	DEFAULT_LDX_GAME_SERVER_TEMPLATE,
	DEFAULT_LDX_STUDIO_BACKOFFICE_SERVER_SUBDOMAIN,
	DEFAULT_LDX_STUDIO_BACKOFFICE_SERVER_TEMPLATE,
} from './constants.ldx';
import {
	DEFAULT_LDX_DEALER_SERVER_DOMAIN_NAME_TEMPLATE,
	DEFAULT_LDX_DEV_SERVER_DOMAIN_NAME_TEMPLATE,
	DEFAULT_LDX_DEVICE_SERVER_DOMAIN_NAME_TEMPLATE,
	DEFAULT_LDX_GAME_SERVER_DOMAIN_NAME_TEMPLATE,
	DEFAULT_LDX_STUDIO_BACKOFFICE_SERVER_DOMAIN_NAME_TEMPLATE,
} from './constants.ldx';

class ConfigAdapterLdx extends ConfigAdapterBase {
	public get tableId(): string {
		return this.getStringVar(ConfigVars.TABLE_ID);
	}

	public get userName(): string {
		return this.getStringVar(ConfigVars.APP_USERNAME);
	}

	public get password(): string {
		return this.getStringVar(ConfigVars.APP_PASSWORD);
	}

	public get enableDealerOverlay(): boolean {
		return this.getBoolVar(ConfigVars.ENABLE_DEALER_OVERLAY);
	}

	public get omnibusAuthToken(): string {
		return this.getStringVar(ConfigVars.OMNIBUS_AUTH_TOKEN);
	}

	public get defaultGameServerTemplate(): string {
		if (this.domainName) {
			return DEFAULT_LDX_GAME_SERVER_DOMAIN_NAME_TEMPLATE;
		}

		return DEFAULT_LDX_GAME_SERVER_TEMPLATE;
	}

	public get defaultDevServerTemplate(): string {
		if (this.domainName) {
			return DEFAULT_LDX_DEV_SERVER_DOMAIN_NAME_TEMPLATE;
		}

		return DEFAULT_LDX_DEV_SERVER_TEMPLATE;
	}

	public get defaultDeviceServerTemplate(): string {
		if (this.domainName) {
			return DEFAULT_LDX_DEVICE_SERVER_DOMAIN_NAME_TEMPLATE;
		}

		return DEFAULT_LDX_DEVICE_SERVER_TEMPLATE;
	}

	public get defaultDealerServerTemplate(): string {
		if (this.domainName) {
			return DEFAULT_LDX_DEALER_SERVER_DOMAIN_NAME_TEMPLATE;
		}

		return DEFAULT_LDX_DEALER_SERVER_TEMPLATE;
	}

	public get defaultStudioBackOfficeServerTemplate(): string {
		if (this.domainName) {
			return DEFAULT_LDX_STUDIO_BACKOFFICE_SERVER_DOMAIN_NAME_TEMPLATE;
		}

		return DEFAULT_LDX_STUDIO_BACKOFFICE_SERVER_TEMPLATE;
	}

	public get gameServerSubdomain(): string {
		const val = this.getStringVar(ConfigVars.GAME_SERVER_SUBDOMAIN);

		return val || DEFAULT_LDX_GAME_SERVER_SUBDOMAIN;
	}

	public get gameServerURI(): string {
		const val = this.getStringVar(ConfigVars.GAME_SERVER_URI);

		return val || this.gameServerURIFromTemplate();
	}

	public gameServerURIFromTemplate(template?: string): string {
		template = template || this.gameServerTemplate;

		const conf: StringDict = {
			[ConfigVars.GAME_SERVER_SUBDOMAIN]: this.gameServerSubdomain || DEFAULT_LDX_GAME_SERVER_SUBDOMAIN,
			[ConfigVars.DEPLOYMENT]: this.deployment || DEFAULT_DEPLOYMENT,
			[ConfigVars.SERVER_DOMAIN]: this.serverDomain || DEFAULT_SERVER_DOMAIN,
			[ConfigVars.DOMAIN_NAME]: this.domainName || DEFAULT_DOMAIN_NAME,
		};

		return this.fromTemplate(template, conf);
	}

	public get devServerSubdomain(): string {
		const val = this.getStringVar(ConfigVars.DEV_SERVER_SUBDOMAIN);

		return val || DEFAULT_LDX_DEV_SERVER_SUBDOMAIN;
	}

	public get devServerURI(): string {
		const val = this.getStringVar(ConfigVars.DEV_SERVER_URI);

		return val || this.devServerURIFromTemplate();
	}

	public devServerURIFromTemplate(template?: string): string {
		template = template || this.devServerTemplate;

		const conf: StringDict = {
			[ConfigVars.DEV_SERVER_SUBDOMAIN]: this.devServerSubdomain,
			[ConfigVars.DEPLOYMENT]: this.deployment || DEFAULT_DEPLOYMENT,
			[ConfigVars.SERVER_DOMAIN]: this.serverDomain || DEFAULT_SERVER_DOMAIN,
			[ConfigVars.DOMAIN_NAME]: this.domainName || DEFAULT_DOMAIN_NAME,
		};

		return this.fromTemplate(template, conf);
	}

	public get deviceServerSubdomain(): string {
		const val = this.getStringVar(ConfigVars.DEVICE_SERVER_SUBDOMAIN);

		return val || DEFAULT_LDX_DEVICE_SERVER_SUBDOMAIN;
	}

	public get deviceServerURI(): string {
		const val = this.getStringVar(ConfigVars.DEVICE_SERVER_URI);

		return val || this.deviceServerURIFromTemplate();
	}

	public deviceServerURIFromTemplate(template?: string): string {
		template = template || this.deviceServerTemplate;

		const conf: StringDict = {
			[ConfigVars.DEVICE_SERVER_SUBDOMAIN]: this.deviceServerSubdomain,
			[ConfigVars.DEPLOYMENT]: this.deployment || DEFAULT_DEPLOYMENT,
			[ConfigVars.SERVER_DOMAIN]: this.serverDomain || DEFAULT_SERVER_DOMAIN,
			[ConfigVars.DOMAIN_NAME]: this.domainName || DEFAULT_DOMAIN_NAME,
		};

		return this.fromTemplate(template, conf);
	}

	public get dealerServerSubdomain(): string {
		const val = this.getStringVar(ConfigVars.DEALER_SERVER_SUBDOMAIN);

		return val || DEFAULT_LDX_DEALER_SERVER_SUBDOMAIN;
	}

	public get dealerServerURI(): string {
		const val = this.getStringVar(ConfigVars.DEALER_SERVER_URI);

		return val || this.dealerServerURIFromTemplate();
	}

	public dealerServerURIFromTemplate(template?: string): string {
		template = template || this.dealerServerTemplate;

		const conf: StringDict = {
			[ConfigVars.DEALER_SERVER_SUBDOMAIN]: this.dealerServerSubdomain,
			[ConfigVars.DEPLOYMENT]: this.deployment || DEFAULT_DEPLOYMENT,
			[ConfigVars.SERVER_DOMAIN]: this.serverDomain || DEFAULT_SERVER_DOMAIN,
			[ConfigVars.DOMAIN_NAME]: this.domainName || DEFAULT_DOMAIN_NAME,
		};

		return this.fromTemplate(template, conf);
	}

	public get studioBackOfficeServerSubdomain(): string {
		const val = this.getStringVar(ConfigVars.STUDIO_BACKOFFICE_SERVER_SUBDOMAIN);

		return val || DEFAULT_LDX_STUDIO_BACKOFFICE_SERVER_SUBDOMAIN;
	}

	public get studioBackOfficeServerURI(): string {
		const val = this.getStringVar(ConfigVars.STUDIO_BACKOFFICE_SERVER_URI);

		return val || this.studioBackOfficeServerURIFromTemplate();
	}

	public studioBackOfficeServerURIFromTemplate(template?: string): string {
		template = template || this.studioBackOfficeServerTemplate;

		const conf: StringDict = {
			[ConfigVars.STUDIO_BACKOFFICE_SERVER_SUBDOMAIN]: this.studioBackOfficeServerSubdomain,
			[ConfigVars.DEPLOYMENT]: this.deployment || DEFAULT_DEPLOYMENT,
			[ConfigVars.SERVER_DOMAIN]: this.serverDomain || DEFAULT_SERVER_DOMAIN,
			[ConfigVars.DOMAIN_NAME]: this.domainName || DEFAULT_DOMAIN_NAME,
		};

		return this.fromTemplate(template, conf);
	}

	public get gameServerTemplate(): string {
		return this.getStringVar(ConfigVars.GAME_SERVER_TEMPLATE) || this.defaultGameServerTemplate;
	}

	public get devServerTemplate(): string {
		return this.getStringVar(ConfigVars.DEV_SERVER_TEMPLATE) || this.defaultDevServerTemplate;
	}

	public get studioBackOfficeServerTemplate(): string {
		return (
			this.getStringVar(ConfigVars.STUDIO_BACKOFFICE_SERVER_TEMPLATE) || this.defaultStudioBackOfficeServerTemplate
		);
	}

	public get deviceServerTemplate(): string {
		return this.getStringVar(ConfigVars.DEVICE_SERVER_TEMPLATE) || this.defaultDeviceServerTemplate;
	}

	public get dealerServerTemplate(): string {
		return this.getStringVar(ConfigVars.DEALER_SERVER_TEMPLATE) || this.defaultDealerServerTemplate;
	}

	public get themes(): Array<string> {
		const themes = this.getGlobalVar(ConfigVars.THEMES);
		if (themes == null || !isArray(themes)) {
			return [];
		}

		const filtered: string[] = [];

		(themes as Array<unknown>).forEach((t: unknown) => {
			t = t ?? '';
			if (isString(t) && t !== '') {
				filtered.push(t);
			}
		});

		return filtered;
	}

	public get gameTitle(): string {
		return this.getStringVar(ConfigVars.GAME_TITLE);
	}

	public get deployment(): string {
		return this.getStringVar(ConfigVars.DEPLOYMENT);
	}

	public get serverDomain(): string {
		return this.getStringVar(ConfigVars.SERVER_DOMAIN);
	}

	public get domainName(): string {
		return this.getStringVar(ConfigVars.DOMAIN_NAME);
	}

	/**
	 * Phenix
	 */
	public get enablePhenixTelemetry(): boolean {
		return this.getBoolVar(ConfigVars.ENABLE_PHENIX_TELEMETRY);
	}
	public get phenixAuthApiURI(): string {
		return this.getStringVar(ConfigVars.PHENIX_AUTH_API_URI);
	}
	public get phenixChannelId(): string {
		return this.getStringVar(ConfigVars.PHENIX_CHANNEL_ID);
	}
	public get phenixChannelAlias(): string {
		return this.getStringVar(ConfigVars.PHENIX_CHANNEL_ALIAS);
	}
	public get phenixStreamToken(): string {
		return this.getStringVar(ConfigVars.PHENIX_STREAM_TOKEN);
	}

	public get resolvedProps(): PlainObject {
		const allProps: PlainObject = {
			[ConfigVars.TABLE_ID]: this.tableId,
			[ConfigVars.APP_USERNAME]: this.userName,
			[ConfigVars.APP_PASSWORD]: this.password,
			[ConfigVars.ENABLE_DEALER_OVERLAY]: this.enableDealerOverlay,
			[ConfigVars.DEV_SERVER_SUBDOMAIN]: this.devServerSubdomain,
			[ConfigVars.DEV_SERVER_URI]: this.devServerURI,
			[ConfigVars.DEV_SERVER_TEMPLATE]: this.devServerTemplate,
			[ConfigVars.GAME_SERVER_SUBDOMAIN]: this.gameServerSubdomain,
			[ConfigVars.GAME_SERVER_URI]: this.gameServerURI,
			[ConfigVars.GAME_SERVER_TEMPLATE]: this.gameServerTemplate,
			[ConfigVars.DEVICE_SERVER_SUBDOMAIN]: this.deviceServerSubdomain,
			[ConfigVars.DEVICE_SERVER_URI]: this.deviceServerURI,
			[ConfigVars.DEVICE_SERVER_TEMPLATE]: this.deviceServerTemplate,
			[ConfigVars.DEALER_SERVER_SUBDOMAIN]: this.dealerServerSubdomain,
			[ConfigVars.DEALER_SERVER_URI]: this.dealerServerURI,
			[ConfigVars.DEALER_SERVER_TEMPLATE]: this.dealerServerTemplate,
			[ConfigVars.STUDIO_BACKOFFICE_SERVER_SUBDOMAIN]: this.studioBackOfficeServerSubdomain,
			[ConfigVars.STUDIO_BACKOFFICE_SERVER_URI]: this.studioBackOfficeServerURI,
			[ConfigVars.STUDIO_BACKOFFICE_SERVER_TEMPLATE]: this.studioBackOfficeServerTemplate,
			[ConfigVars.OMNIBUS_AUTH_TOKEN]: this.omnibusAuthToken,
			[ConfigVars.GAME_TITLE]: this.gameTitle,
			[ConfigVars.THEMES]: this.themes,
			[ConfigVars.DEPLOYMENT]: this.deployment,
			[ConfigVars.SERVER_DOMAIN]: this.serverDomain,
			[ConfigVars.DOMAIN_NAME]: this.domainName,

			// Phenix
			[ConfigVars.ENABLE_PHENIX_TELEMETRY]: this.enablePhenixTelemetry,
			[ConfigVars.PHENIX_AUTH_API_URI]: this.phenixAuthApiURI,
			[ConfigVars.PHENIX_CHANNEL_ID]: this.phenixChannelId,
			[ConfigVars.PHENIX_CHANNEL_ALIAS]: this.phenixChannelAlias,
			[ConfigVars.PHENIX_STREAM_TOKEN]: this.phenixStreamToken,
		};

		Object.keys(allProps).forEach((k) => {
			const val = allProps[k] ?? null;
			if (val === '' || val == null) {
				delete allProps[k];
			}
		});

		return allProps;
	}

	protected fromTemplate(template: string, conf: StringDict): string {
		const value = renderTemplate(template, conf);

		return !value.includes('{{') ? value : '';
	}
}

// ---- Export --------------------------------------------------------------------------------------------------------

export { ConfigAdapterLdx as default };
export { ConfigAdapterLdx };
