import { action, AnnotationsMap, computed, makeObservable } from 'mobx';
import { DataStoreMobXAnnotations } from '../DataStore/mobx';
import TableStore from './TableStore';
import { TableStoreMobXPrivateProps } from './types.mobx';

/**
 * MobX annotations for `TableStore`.
 */
const TableStoreMobXAnnotations: AnnotationsMap<TableStore, TableStoreMobXPrivateProps> = {
	// Inherit from DataStore
	...DataStoreMobXAnnotations,

	// ---- Computed -----------------------------------------------------

	// >>> Public
	tableId: computed,
	id: computed,
	playId: computed,
	previousPlayId: computed,
	state: computed,
	game: computed,
	gameVariant: computed,
	tableName: computed,
	seatCount: computed,
	openSeatsCount: computed,
	seatsList: computed,
	tableConfig: computed,

	// ---- Actions ------------------------------------------------------

	// >>> Protected
	fetchPopulateData: action,

	// ---- Excluded ----------------------------------------------------

	// >>> Public
	toJson: false,
};

/**
 * Makes the specified `TableStore` instance an observable MobX object.
 */
const bindTableStoreMobX = (instance: TableStore): boolean => {
	if (instance.isMobXBound) {
		return false;
	}

	makeObservable<TableStore, TableStoreMobXPrivateProps>(instance, {
		...TableStoreMobXAnnotations,
	});

	instance.isMobXBound = true;

	return true;
};

// ---- Exports -------------------------------------------------------------------------------------------------------

export { TableStoreMobXAnnotations, bindTableStoreMobX };
