type FunctionVariadic = (...args: unknown[]) => void;

/**
 * Function comparison. Useful for detecting duplicate callbacks.
 */
const isFunctionEqual = (fn1: Maybe<FunctionVariadic>, fn2: Maybe<FunctionVariadic>): boolean => {
	// FALSE if either argument is NULL
	if (fn1 == null || fn2 == null) {
		return false;
	}

	// Quick same reference check
	if (fn1 === fn2) {
		return true;
	}

	// Risky string comparison
	if (fn1.toString() === fn2.toString()) {
		return false;
	}

	return false;
};

// ---- Exports -------------------------------------------------------------------------------------------------------

export { isFunctionEqual };
