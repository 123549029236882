import {
	DEFAULT_CURRENCY_CODE,
	DEFAULT_CURRENCY_EXPONENT,
	formatCurrency,
	getCurrencySymbolForCode,
	IFormatCurrencyOpts,
	normalizeCurrencyCode,
} from '../shared';
import {
	IMethodResolveAmountsOpts,
	IMethodResolveAmountsVals,
	IResolvedAmountData,
	IResolvedAmountDataSimple,
} from './types';

/**
 * @returns The currency symbol for the currency code. Uses the default currency if not specified.
 */
const getCurrencySymbol = (currency?: Maybe<string>): string => {
	const currencyCode = resolveCurrencyCode(currency);

	return getCurrencySymbolForCode(currencyCode);
};

/**
 * @param amountVals The values to resolve.
 * @param opts      Options for resolving the values.
 * @returns The given amount values resolved to the correct amounts and currency.
 */
const resolveAmounts = (
	amountVals: IMethodResolveAmountsVals,
	opts?: Maybe<IMethodResolveAmountsOpts>
): IResolvedAmountData => {
	const currencyCode = resolveCurrencyCode(opts?.currencyCode);
	const currencyExponent = resolveCurrencyExponent(opts?.currencyExponent);
	const currencySymbol = getCurrencySymbolForCode(currencyCode);

	let amount: Nullable<number> = amountVals.amount != null ? amountVals.amount : null;
	amount = amount != null && amount < 0 ? 0 : amount;

	let amountReal: Nullable<number> = amountVals.amountReal != null ? amountVals.amountReal : null;
	amountReal = amountReal != null && amountReal < 0 ? 0 : amountReal;

	let result: IResolvedAmountData = {
		amount: 0,
		amountReal: 0,
		amountMoney: '',
		currencyCode,
		currencySymbol,
		currencyExponent,
	};

	if (amount != null && amountReal != null) {
		const amountMoney = getAmountMoney(amountReal, currencyCode, opts?.formatCurrencyOpts);
		result = { ...result, amount, amountReal, amountMoney };
	}

	if (amount != null) {
		const amountReal = getAmountRealFromRawAmount(amount, currencyExponent);
		const amountMoney = getAmountMoney(amountReal, currencyCode, opts?.formatCurrencyOpts);
		result = { ...result, amount, amountReal, amountMoney };
	}

	if (amountReal != null) {
		const amount = getAmountRawFromRealAmount(amountReal, currencyExponent);
		const amountMoney = getAmountMoney(amountReal, currencyCode, opts?.formatCurrencyOpts);
		result = { ...result, amount, amountReal, amountMoney };
	}

	return result;
};

/**
 * Simplified version of `resolveAmounts` that only returns the `amount`, `amountReal` and `amountMoney` values.
 *
 * @param amountVals The values to resolve.
 * @param opts      Options for resolving the values.
 * @returns The given amount values resolved to the correct amounts.
 */
const resolveAmountsSimple = (
	amountVals: IMethodResolveAmountsVals,
	opts?: Maybe<IMethodResolveAmountsOpts>
): IResolvedAmountDataSimple => {
	const result = resolveAmounts(amountVals, opts);

	return { amount: result.amount, amountReal: result.amountReal, amountMoney: result.amountMoney };
};

/**
 * @returns The given amount resolved to the correct amounts and currency.
 */
const resolveAmount = (amount: number, opts?: Maybe<IMethodResolveAmountsOpts>): IResolvedAmountData => {
	return resolveAmounts({ amount }, opts);
};

/**
 * @returns The given amount resolved to the `amount`, `amountReal` and `amountMoney` values.
 */
const resolveAmountSimple = (amount: number, opts?: Maybe<IMethodResolveAmountsOpts>): IResolvedAmountDataSimple => {
	return resolveAmountsSimple({ amount }, opts);
};

/**
 * @returns The monetary value for the specified real amount formatted with the specified currency code.
 */
const getAmountMoney = (
	amountReal: number,
	currencyCode: string,
	formatCurrencyOpts?: Maybe<IFormatCurrencyOpts>
): string => {
	currencyCode = resolveCurrencyCode(currencyCode);

	return formatCurrency(amountReal, { ...(formatCurrencyOpts ?? {}), currencyCode });
};

const resolveCurrencyCode = (currencyCode?: Maybe<string>): string => {
	return normalizeCurrencyCode(currencyCode || DEFAULT_CURRENCY_CODE);
};

const resolveCurrencyExponent = (currencyExponent?: Maybe<number>): number => {
	return Math.max(currencyExponent ?? DEFAULT_CURRENCY_EXPONENT, 0);
};

const getAmountRealFromRawAmount = (amount: number, currencyExponent?: Maybe<number>): number => {
	const exponent = resolveCurrencyExponent(currencyExponent);

	return exponent > 0 ? amount / Math.pow(10, exponent) : amount;
};

const getAmountRawFromRealAmount = (amountReal: number, currencyExponent?: Maybe<number>): number => {
	const exponent = resolveCurrencyExponent(currencyExponent);

	return exponent > 0 ? amountReal * Math.pow(10, exponent) : amountReal;
};

// ---- Exports -------------------------------------------------------------------------------------------------------

export {
	getAmountRawFromRealAmount,
	getAmountRealFromRawAmount,
	getCurrencySymbol,
	normalizeCurrencyCode,
	resolveAmount,
	resolveAmounts,
	resolveAmountSimple,
	resolveAmountsSimple,
	resolveCurrencyCode,
	resolveCurrencyExponent,
};
