import { copyAnyObjectList, extendPlaySeatData, IExtendPlaySeatDataOpts, makeUniqId } from '../../../';
import { generateObjectListHashId } from '../../utility';
import {
	IMethodPlaySeatAssignmentsMapDataOpts,
	IMethodPlaySeatAssignmentsMapRawDataOpts,
	IPlaySeatAssignmentDataEntry,
	IPlaySeatAssignmentsData,
	PlaySeatAssignmentsDataList,
	PlaySeatAssignmentsDataLookup,
	RawPlaySeatAssignmentEntry,
	RawPlaySeatAssignmentList,
} from './types';

/**
 * @returns The default data used by the `PlaySeatAssignments` class.
 */
const defaultData = (
	opts?: Maybe<{ tableId?: Maybe<string>; playId?: Maybe<string>; updatedTs?: Maybe<number> }>
): IPlaySeatAssignmentsData => {
	return {
		raw: null,
		lookup: new Map<string, IPlaySeatAssignmentDataEntry>(),
		seatId2NumLookup: null,
		lastUpdatedTs: opts?.updatedTs ?? 0,
		tableId: opts?.tableId ?? '',
		playId: opts?.playId ?? '',
		uniqId: makeUniqId(),
		hashId: '',
	};
};

/**
 * @returns A unique hash id for the given list of raw data entries.
 */
const generateRawListHashId = (list: RawPlaySeatAssignmentList): string => {
	return generateObjectListHashId<RawPlaySeatAssignmentEntry>(list, [
		'seatNum',
		'playerId',
		'seatId',
		'spectatorId',
		'displayName',
		'imageUrl',
		'decisionState',
		'seatSettings',
		'focused',
	]);
};

/**
 * @returns A unique hash id for the given list of data entries.
 */
const generateListHashId = (list: PlaySeatAssignmentsDataList): string => {
	return generateObjectListHashId<IPlaySeatAssignmentDataEntry>(list, [
		'isFocused',
		'isOpen',
		'playerDisplayName',
		'playerGameState',
		'playerId',
		'playerImageUrl',
		'playId',
		'seatId',
		'seatNumber',
		'seatSettings',
		'spectatorId',
		'tableId',
	]);
};

/**
 * @returns TRUE if the specified raw data lists are the same - in terms of the meaningful data.
 */
const isRawListSameData = (list1: RawPlaySeatAssignmentList, list2: RawPlaySeatAssignmentList): boolean => {
	if (list1 === list2) {
		return true;
	}
	if (list1.length !== list2.length) {
		return false;
	}

	const hashId1 = generateRawListHashId(list1);
	const hashId2 = generateRawListHashId(list2);

	return hashId1 === hashId2;
};

/**
 * @returns TRUE if the specified data lists are the same - in terms of the meaningful data.
 */
const isListSameData = (list1: PlaySeatAssignmentsDataList, list2: PlaySeatAssignmentsDataList): boolean => {
	if (list1 === list2) {
		return true;
	}
	if (list1.length !== list2.length) {
		return false;
	}

	const hashId1 = generateListHashId(list1);
	const hashId2 = generateListHashId(list2);

	return hashId1 === hashId2;
};

/**
 * Maps the raw data list sent by the server and resolves to the relevant encapsulated data.
 */
const newDataFromRawList = (
	srcList: RawPlaySeatAssignmentList,
	tableId: string,
	playId: string,
	opts?: Maybe<IMethodPlaySeatAssignmentsMapRawDataOpts>
): IPlaySeatAssignmentsData => {
	const lastUpdatedTs: number = opts?.updatedTs || Date.now();

	if (srcList.length === 0) {
		return defaultData({ updatedTs: lastUpdatedTs, tableId, playId });
	}

	const lookup = new Map<string, IPlaySeatAssignmentDataEntry>();
	const seatId2NumLookup = new Map<string, string>();

	const extendDataOpts: IExtendPlaySeatDataOpts = { ...opts?.extendDataOpts, tableId, playId };

	srcList.forEach((entry: RawPlaySeatAssignmentEntry, i) => {
		const entryExt = extendPlaySeatData(entry, extendDataOpts);

		if (entryExt.seatNumber < 1) {
			entryExt.seatNumber = i + 1;
		}

		const seatNum = entryExt.seatNumber;

		const item: IPlaySeatAssignmentDataEntry = {
			...entryExt,
			lastUpdatedTs,
		};

		lookup.set(`${seatNum}`, item);

		if (item.seatId !== '') {
			seatId2NumLookup.set(item.seatId, `${seatNum}`);
		}
	});

	const list = Array.from(lookup.values());
	const hashId = generateListHashId(list);
	const uniqId = makeUniqId();

	const rawList = srcList.slice();
	const rawHashId = generateRawListHashId(rawList);

	return {
		raw: { list: rawList, hashId: rawHashId },
		lookup,
		lastUpdatedTs,
		uniqId,
		hashId,
		tableId,
		playId,
		seatId2NumLookup,
	};
};

/**
 * Maps the specified data list and resolves to the relevant encapsulated data.
 */
const newDataFromList = (
	srcList: PlaySeatAssignmentsDataList,
	opts?: Maybe<IMethodPlaySeatAssignmentsMapDataOpts>
): IPlaySeatAssignmentsData => {
	const lastUpdatedTs: number = opts?.updatedTs || Date.now();

	const tableId = opts?.tableId ?? null;
	const playId = opts?.playId ?? null;
	const seatNumber = opts?.extendDataOpts?.seatNumber ?? null;

	if (srcList.length === 0) {
		return defaultData({ updatedTs: lastUpdatedTs, tableId, playId });
	}

	const lookup = new Map<string, IPlaySeatAssignmentDataEntry>();
	const seatId2NumLookup = new Map<string, string>();

	srcList.forEach((entry: IPlaySeatAssignmentDataEntry, i) => {
		entry.tableId = tableId ?? entry.tableId;
		entry.playId = playId ?? entry.playId;
		entry.seatNumber = seatNumber ?? entry.seatNumber;

		if (entry.seatNumber < 1) {
			entry.seatNumber = i + 1;
		}

		const seatNum = entry.seatNumber;

		const item: IPlaySeatAssignmentDataEntry = {
			...entry,
			lastUpdatedTs,
		};

		lookup.set(`${seatNum}`, item);

		if (item.seatId !== '') {
			seatId2NumLookup.set(item.seatId, `${seatNum}`);
		}
	});

	const list = Array.from(lookup.values());
	const hashId = generateListHashId(list);
	const uniqId = makeUniqId();

	return {
		raw: null,
		lookup,
		lastUpdatedTs,
		uniqId,
		hashId,
		tableId: tableId ?? list[0]?.tableId ?? '',
		playId: playId ?? list[0]?.playId ?? '',
		seatId2NumLookup,
	};
};

/**
 * @returns A lookup of seat ID to seat number for the specified seatNumber --> data lookup.
 */
const createSeatIdToNumLookup = (lookup: PlaySeatAssignmentsDataLookup): Map<string, string> => {
	const result = new Map<string, string>();

	const entries = Array.from(lookup.entries());

	entries.forEach(([seatNumKey, entry]) => {
		const seatId = entry.seatId;

		if (seatId !== '') {
			result.set(entry.seatId, seatNumKey);
		}
	});

	return result;
};

/**
 * @returns A copy of the specified raw data list.
 */
const copyRawList = (list: RawPlaySeatAssignmentList): RawPlaySeatAssignmentList => {
	return copyAnyObjectList<RawPlaySeatAssignmentEntry>(list);
};

/**
 * @returns A copy of the specified data list.
 */
const copyList = (list: PlaySeatAssignmentsDataList): PlaySeatAssignmentsDataList => {
	return copyAnyObjectList<IPlaySeatAssignmentDataEntry>(list);
};

/**
 * @returns A copy of the specified data lookup.
 */
const copyLookup = (lookup: PlaySeatAssignmentsDataLookup): PlaySeatAssignmentsDataLookup => {
	const copy = new Map<string, IPlaySeatAssignmentDataEntry>();
	for (const [key, value] of lookup) {
		copy.set(key, { ...value });
	}

	return copy;
};

/**
 * @returns A copy of the specified data lookup.
 */
const copySeatId2NumLookup = (lookup: Map<string, string>): Map<string, string> => {
	const copy = new Map<string, string>();
	for (const [key, value] of lookup) {
		copy.set(key, value);
	}

	return copy;
};

/**
 * @returns A copy of the specified seat assignments data.
 */
const copyData = (
	data: IPlaySeatAssignmentsData,
	opts?: Maybe<{ updatedTs?: Maybe<number> }>
): IPlaySeatAssignmentsData => {
	const newData: IPlaySeatAssignmentsData = defaultData();
	newData.lastUpdatedTs = opts?.updatedTs ?? data.lastUpdatedTs;
	newData.lookup = copyLookup(data.lookup);
	newData.seatId2NumLookup = data.seatId2NumLookup != null ? copySeatId2NumLookup(data.seatId2NumLookup) : null;
	newData.hashId = data.hashId;
	newData.tableId = data.tableId;
	newData.playId = data.playId;
	newData.raw = data.raw != null ? { list: copyRawList(data.raw.list), hashId: data.raw.hashId } : null;

	return newData;
};

// ---- Exports -------------------------------------------------------------------------------------------------------

export { copyData, copyList, copyLookup, copyRawList, defaultData };
export { generateListHashId, generateRawListHashId, isRawListSameData, isListSameData };
export { newDataFromRawList, newDataFromList };
export { createSeatIdToNumLookup };
