import { action, AnnotationsMap, computed, makeObservable, observable } from 'mobx';
import { DebugBaseMobXAnnotations } from '../../common';
import { ChoiceManager } from './ChoiceManager';
import { ChoiceManagerMobXPrivateProps } from './types.mobx';

const ChoiceManagerMobXAnnotations: AnnotationsMap<ChoiceManager, ChoiceManagerMobXPrivateProps> = {
	// Inherit from DebugBase
	...DebugBaseMobXAnnotations,

	// ---- Observed -----------------------------------------------------

	// >> Properties
	_playId: observable,
	_tableId: observable,
	_choiceDefinitions: observable,
	_availableChoices: observable,
	_choicePeriodList: observable,
	_choiceContexts: observable,
	_activeChoices: observable,
	_choicePeriodLookup: observable,
	_lastUpdatedTs: observable,

	// ---- Computed -----------------------------------------------------

	// >> Public
	activeChoices: computed,
	availableChoiceIds: computed,
	availableChoices: computed,
	choiceIds: computed,
	choiceIdLookup: computed,
	choiceDefinitions: computed,
	choiceNames: computed,
	choicePeriodCount: computed,
	choicePeriodIds: computed,
	choicePeriodsList: computed,
	choicesByNameLookup: computed,
	closedChoicePeriods: computed,
	contextAvailableChoices: computed,
	isChoicePeriodsSet: computed,
	openChoicePeriods: computed,

	// ---- Actions ------------------------------------------------------

	// >> Public
	setChoiceDefinitions: action,
	setAvailableChoices: action,
	setContextChoices: action,
	setChoicePeriods: action,
	setPlayerActiveChoices: action,
	clear: action,
	clearChoiceData: action,
	clearChoicePeriodData: action,

	// >> Protected
	updateFromPlayData: action,
	setLastUpdatedTs: action,
	setDefaults: action,

	// ---- Excluded -----------------------------------------------------

	// >> Properties
	_options: false,
	_isMobXBound: false,

	// >> Public
	setPlayData: false,
	isMobXBound: false,
	hasChoice: false,
	findChoiceById: false,
	findChoiceByName: false,
	hasChoicePeriod: false,
	findChoicePeriodById: false,
	isChoicePeriodClosed: false,
	filterOpenChoices: false,
	toJson: false,
};

/**
 * Makes the specified `ChoiceManager` instance an observable MobX object.
 */
const bindChoiceManagerMobX = (instance: ChoiceManager): boolean => {
	if (instance.isMobXBound) {
		return false;
	}

	makeObservable<ChoiceManager, ChoiceManagerMobXPrivateProps>(instance, {
		...ChoiceManagerMobXAnnotations,
	});

	instance.isMobXBound = true;

	return true;
};

// ---- Exports -------------------------------------------------------------------------------------------------------

export { bindChoiceManagerMobX, ChoiceManagerMobXAnnotations };
