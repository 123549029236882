import throttle from 'lodash/throttle';
import { IGameService } from '../../client';
import { ClearWagersRequest, MakeWagersReply, MakeWagersRequest } from '../../client/rpc';
import { IKumquatWagerData as ISeatWagerData, KumquatWager as SeatWager } from '../../client/rpc';
import { CancelablePromiseError, entries, makeUniqId } from '../../helpers';
import { generateObjectListHashId } from '../../helpers/data/utility';
import {
	IMultiSeatPlacedWagerSet,
	InflightWagersTransaction,
	InflightWagersTransactionLookup,
	ISetInflightWagersTransactionProps,
	SendWagersSender,
} from './types';
import { logError, logInfo, logWarn, throwFatalError } from './utility.error';
import { isValidSeat } from './utility.main';

const extractMswSeatNums = (multipleSeatWagers: IMultiSeatPlacedWagerSet): number[] => {
	const seatNumKeys: string[] = Object.keys(multipleSeatWagers);

	return seatNumKeys.map(Number);
};

const newInflightWagersTransaction = (
	requestKey: string,
	request?: Maybe<MakeWagersRequest>,
	sender?: Maybe<SendWagersSender>,
	transactionId?: Maybe<string>
): InflightWagersTransaction => {
	transactionId = transactionId || makeUniqId();

	return {
		requestKey,
		transactionId,
		inFlight: false,
		isResolved: false,
		isCancelled: false,
		request: request ?? null,
		sender: sender ?? null,
		result: null,
	};
};

const setInflightWagersTransactionProps = (
	requestKey: string,
	props: ISetInflightWagersTransactionProps
): Nullable<InflightWagersTransaction> => {
	const ct = inFlightWagerTransactions.get(requestKey);
	if (ct == null) {
		return null;
	}

	const nt = { ...newInflightWagersTransaction(requestKey), ...ct };
	nt.sender = ct.sender;
	nt.request = ct.request;

	nt.transactionId = props.transactionId ?? nt.transactionId;
	nt.inFlight = props.inFlight ?? nt.inFlight;
	nt.isResolved = props.isResolved ?? nt.isResolved;
	nt.isCancelled = props.isCancelled ?? nt.isCancelled;
	nt.sender = props.sender != undefined ? props.sender : nt.sender;
	nt.request = props.request != undefined ? props.request : nt.request;

	inFlightWagerTransactions.set(requestKey, nt);

	return nt;
};

const newInFlightWagersTransactionLookup = () => {
	return new Map<string, InflightWagersTransaction>();
};

const inFlightWagerTransactions: InflightWagersTransactionLookup = newInFlightWagersTransactionLookup();

const cancelInflightWagersTransaction = (requestKey: string) => {
	const tr = inFlightWagerTransactions.get(requestKey) ?? null;
	if (tr == null) {
		return;
	}

	if (tr.isResolved || tr.isCancelled) {
		return;
	}

	tr.sender && tr.sender.cancel();
	tr.isCancelled = true;
	tr.inFlight = false;
	tr.request = null;
	tr.sender = null;

	inFlightWagerTransactions.set(requestKey, tr);
};

const cancelAllInflightWagersTransactions = (opts?: Maybe<{ filterRequestKey?: Maybe<string> }>) => {
	const filterRequestKey: string = opts?.filterRequestKey ?? '';
	const list = Array.from(inFlightWagerTransactions.values());

	list.forEach((tr) => {
		if (filterRequestKey != '' && !tr.requestKey.startsWith(filterRequestKey)) {
			return;
		}

		cancelInflightWagersTransaction(tr.requestKey);
	});
};

const clearAllInflightWagersTransactions = () => {
	cancelAllInflightWagersTransactions();
	inFlightWagerTransactions.clear();
};

/**
 * TODO: TEMPORARY UNTIL WE FINISH THE `_sendWagersTransactionQueue` AT WHICH POINT WE WILL MOVE THIS.
 *
 * Creates a single combined `MakeWagers` request for the specified multiple seat wagers.
 */
const createMultiSeatWagersCombinedRequest = (
	multipleSeatWagers: IMultiSeatPlacedWagerSet,
	playId: string,
	tableId: string,
	validSeatNumbers: number[],
	opts?: Maybe<{ currencyCode?: Maybe<string> }>
): Nullable<MakeWagersRequest> => {
	const debugMethod = 'createMultiSeatWagersCombinedRequest';

	if (playId === '') {
		logError('A valid play Id must be specified.', debugMethod);
		return null;
	}

	if (tableId === '') {
		logError('A valid table Id must be specified.', debugMethod);
		return null;
	}

	if (validSeatNumbers.length === 0) {
		logError('A non-empty set of valid seat numbers must be specified.', debugMethod);
		return null;
	}

	const multipleSeatWagerEntries = entries(multipleSeatWagers);
	if (multipleSeatWagerEntries.length === 0) {
		logError('No seat wagers specified in multi-seat wager set.', debugMethod);
		return null;
	}

	let wagerCount: number = 0;
	const request = newMakeWagersRequest(playId, tableId);

	multipleSeatWagerEntries.forEach(([_, sws]) => {
		const seatNumber = sws.seatNumber;

		if (!isValidSeat(seatNumber, validSeatNumbers)) {
			logWarn(`Specified seat number '${seatNumber} is not valid for placing wagers.'`, debugMethod);
			return; // Next seat
		}

		const seatWagers = sws.wagers;
		const seatWagerEntries = entries(seatWagers);
		if (seatWagerEntries.length === 0) {
			logWarn(`Specified seat wagers for '${seatNumber} has not valid wagers.'`, debugMethod);
			return; // Next seat
		}

		seatWagerEntries.forEach(([_, w]) => {
			if (w.seatNumber !== seatNumber) {
				logWarn(
					`Individual wager seat number '${w.seatNumber}' for wager '${w.wagerId}' is not valid for seat '${seatNumber}' on the wager set`,
					debugMethod
				);
				return; // Next seat wager
			}

			const wagerId = w.wagerId;
			const currencyCode = opts?.currencyCode || w.currencyCode;
			const contextId = w.contextId;
			const amount = w.amount;
			const categoryMemberKey = w.categoryMemberKey;

			const sw = newSeatWager({
				seatNumber,
				wagerId,
				contextId,
				amount,
				currencyCode,
				categoryMemberKey,
			});

			if (sw == null) {
				return; // Next seat wager
			}

			request.wagers.push(sw);
			wagerCount++;
		});
	});

	if (wagerCount === 0) {
		logError('No valid wager items added to request', debugMethod);
		return null;
	}

	return request;
};

const makeRequestDataKey = (wagers: ISeatWagerData[], salt?: Maybe<string[]>) => {
	return generateObjectListHashId<ISeatWagerData>(wagers, ['wagerTypeId', 'contextId', 'key', 'currency'], salt);
};

/**
 * TODO: TEMPORARY UNTIL WE FINISH THE `_sendWagersTransactionQueue` AT WHICH POINT WE WILL MOVE THIS.
 *
 * Calls the underlying service `MakeWagers` method for a single request. Cancels any previous in-flight requests
 * if called again with the same wagers configuration.
 */
const sendMakeWagersRequest = async (
	request: MakeWagersRequest,
	svc?: Nullable<IGameService>
): Promise<MakeWagersReply> => {
	const debugMethod = 'sendMakeWagersRequest';

	logInfo(':::SENDING:::', debugMethod, { request });

	const returnWithErrorPromise = (errMessage: string) => {
		logError(errMessage, debugMethod);

		return Promise.reject(`[${debugMethod}]: ${errMessage}`);
	};

	if (svc == null) {
		return returnWithErrorPromise('Invalid wager service instance specified');
	}

	if (request.tableId === '') {
		return returnWithErrorPromise('A valid table ID must be specified on the request');
	}

	if (request.playId === '') {
		return returnWithErrorPromise('A valid play ID must be specified on the request');
	}

	if (request.wagers.length === 0) {
		return returnWithErrorPromise('No wagers are present in the specified request');
	}

	// Create a unique hash ID representing the request data
	const requestDataKey = `MakeWagers.${makeRequestDataKey(request.wagers)}`;

	// Cancel any current transaction for the same MakeWagers configuration
	cancelInflightWagersTransaction(requestDataKey);

	// Create a new transaction with a new sender
	const nt = newInflightWagersTransaction(requestDataKey, request);
	nt.sender = svc.sendWagers(request);
	nt.inFlight = true;

	nt.sender.promise
		.then((reply) => {
			logInfo('MakeWagers.reply', debugMethod, reply);
			setInflightWagersTransactionProps(requestDataKey, { isResolved: true });
		})
		.catch((err: CancelablePromiseError) => {
			if (err.isCanceled) {
				setInflightWagersTransactionProps(requestDataKey, { isCancelled: true });
				return;
			}

			logError('Error:', debugMethod, err);

			setInflightWagersTransactionProps(requestDataKey, { isResolved: true });
		})
		.finally(() => {
			setInflightWagersTransactionProps(requestDataKey, { inFlight: false, sender: null });
		});

	inFlightWagerTransactions.set(requestDataKey, nt);

	return nt.sender.promise;
};

/**
 * TODO: TEMPORARY UNTIL WE FINISH THE `_sendWagersTransactionQueue` AT WHICH POINT WE WILL MOVE THIS.
 *
 * Calls `ClearWagers` followed by `MakeWagers` for a single request. Cancels any previous in-flight requests
 * if called again with the same wagers configuration.
 */
const sendReplaceWagersRequest = async (
	request: MakeWagersRequest,
	svc?: Nullable<IGameService>
): Promise<Nullable<MakeWagersReply>> => {
	const debugMethod = 'sendReplaceWagersRequest';

	logInfo(':::SENDING:::', debugMethod, { request });

	const clearWagersRequest = newClearWagersRequest(request.tableId, request.playId);
	const cleared = await sendClearWagersRequest(clearWagersRequest, svc);

	return cleared ? sendMakeWagersRequest(request, svc) : null;
};

/**
 * TODO: TEMPORARY UNTIL WE FINISH THE `_sendWagersTransactionQueue` AT WHICH POINT WE WILL MOVE THIS.
 *
 * Calls the underlying service `ClearWagers` method for a single request. Cancels any previous in-flight requests if called again.
 */
const sendClearWagersRequest = async (request: ClearWagersRequest, svc?: Nullable<IGameService>): Promise<boolean> => {
	const debugMethod = 'sendClearWagersRequest';

	logInfo(':::SENDING:::', debugMethod, { request });

	const returnWithErrorPromise = (errMessage: string) => {
		logError(errMessage, debugMethod);

		return Promise.reject(`[${debugMethod}]: ${errMessage}`);
	};

	if (svc == null) {
		return returnWithErrorPromise('Invalid wager service instance specified');
	}

	if (request.tableId === '') {
		return returnWithErrorPromise('A valid table ID must be specified on the request');
	}

	if (request.playId === '') {
		return returnWithErrorPromise('A valid play ID must be specified on the request');
	}

	// Cancel and clear all current wager transactions
	clearAllInflightWagersTransactions();

	// Note we do not track the transaction for ClearWagers requests
	const sender = svc.sendClearWagers(request);

	const handlePromiseError = (e: unknown) => {
		const err = e as CancelablePromiseError;
		if (err.isCanceled === true) {
			return false;
		}

		const error = err.error as Error;
		const errMessage = error.message ?? '';
		const errLc = errMessage.toLocaleLowerCase();

		// Clear wagers will error if there are no wagers to clear, so we ignore the error
		const willIgnoreError: boolean = errLc.includes('wagers up to date');
		if (willIgnoreError) {
			return true;
		}

		logError('Error:', debugMethod, err);
		return false;
	};

	try {
		await sender.promise;
		return true;
	} catch (e: unknown) {
		return handlePromiseError(e);
	}
};

/**
 * TODO: TEMPORARY UNTIL WE FINISH THE `_sendWagersTransactionQueue` AT WHICH POINT WE WILL MOVE THIS.
 *
 * Throttled version of `sendSeatMakeWagersRequest`.
 */
const sendMakeWagersRequestThrottled = throttle(sendMakeWagersRequest, 250, {
	leading: true,
	trailing: true,
});

/**
 * TODO: TEMPORARY UNTIL WE FINISH THE `_sendWagersTransactionQueue` AT WHICH POINT WE WILL MOVE THIS.
 *
 * Throttled version of `sendClearWagersRequest`.
 */
const sendClearWagersRequestThrottled = throttle(sendClearWagersRequest, 250, {
	leading: true,
	trailing: true,
});

/**
 * TODO: TEMPORARY UNTIL WE FINISH THE `_sendWagersTransactionQueue` AT WHICH POINT WE WILL MOVE THIS.
 *
 * Throttled version of `sendReplaceWagersRequest`.
 */
const sendReplaceWagersRequestThrottled = throttle(sendReplaceWagersRequest, 250, {
	leading: true,
	trailing: true,
});

/**
 * @returns A new SeatWager request data object with the specified data.
 */
const newSeatWager = (props: {
	seatNumber: number;
	wagerId: string;
	contextId: string;
	amount: number;
	currencyCode: string;
	categoryMemberKey?: Maybe<string>;
}): SeatWager => {
	const throwError = (msg: string, ...args: unknown[]) => {
		throwFatalError(msg, 'newSeatWager', null, ...args);
	};

	const { wagerId, contextId, amount, currencyCode, categoryMemberKey } = props;
	const seatNumber = props.seatNumber || 1;

	if (seatNumber < 1) {
		throwError(`Invalid seat number specified: ${seatNumber}.`);
	}
	if (wagerId === '') {
		throwError(`Invalid empty wager ID specified.`);
	}
	if (amount < 0) {
		throwError(`Invalid negative amount was specified.`);
	}
	if (currencyCode === '') {
		throwError(`Invalid empty currency code specified.`);
	}

	const sw = new SeatWager();
	// sw.seatPosition = seatNumber; // TODO: Server will add this eventually
	sw.wagerTypeId = wagerId;
	sw.contextId = contextId;
	sw.amount = BigInt(amount);
	sw.currency = currencyCode.toLowerCase();
	sw.key = categoryMemberKey || undefined;

	return sw;
};

/**
 * @returns A new `MakeWagers` request data object with the specified play ID.
 */
const newMakeWagersRequest = (playId: string, tableId: string): MakeWagersRequest => {
	const throwError = (msg: string, ...args: unknown[]) => {
		throwFatalError(msg, 'newMakeWagersRequest', null, ...args);
	};

	if (playId === '') {
		throwError(`Empty play ID specified.`);
	}
	if (tableId === '') {
		throwError(`Empty table ID specified.`);
	}

	const request = new MakeWagersRequest({ playId, tableId });
	request.wagers = [];

	return request;
};

/**
 * @returns A new `ClearWagers` request data object with the specified play ID.
 */
const newClearWagersRequest = (tableId: string, playId: string): ClearWagersRequest => {
	const throwError = (msg: string, ...args: unknown[]) => {
		throwFatalError(msg, 'newClearWagersRequest', null, ...args);
	};

	if (tableId === '') {
		throwError(`Empty table ID specified.`);
	}
	if (playId === '') {
		throwError(`Empty play ID specified.`);
	}

	const request = new ClearWagersRequest({ playId, tableId });

	return request;
};

// /**
//  * TODO: TEMPORARY UNTIL WE FINISH THE `_sendWagersTransactionQueue` AT WHICH POINT WE WILL MOVE THIS.
//  *
//  * Calls the underlying service send method. Cancels any previous in-flight requests if called again.
//  */
// const sendSeatMakeWagersBatch = async (
// 	requests: ISeatMakeWagersBatchRequest[],
// 	svc?: Nullable<IWagerService>
// ): Promise<unknown> => {
// 	const debugMethod = 'sendSeatMakeWagersBatch';

// 	// TODO: Code this, eventually. When I absolutely need it.

// 	return null;
// };

// /**
//  * TODO: TEMPORARY UNTIL WE FINISH THE `_sendWagersTransactionQueue` AT WHICH POINT WE WILL MOVE THIS.
//  *
//  * Creates a `MakeWagers` request populated with a single seat's worth of wagers.
//  */
// const createSingleSeatWagersRequest = (
// 	singleSeatWagers: ISeatPlacedWagerSet,
// 	playId: string,
// 	tableId: string,
// 	validSeatNumbers: number[],
// 	opts?: Maybe<{ currencyCode?: Maybe<string> }>
// ): Nullable<MakeWagersRequest> => {
// 	const debugMethod = 'createSingleSeatWagersRequest';

// 	if (playId === '') {
// 		logError('A valid play ID must be specified', debugMethod);
// 		return null;
// 	}

// 	if (tableId === '') {
// 		logError('A valid table ID must be specified', debugMethod);
// 		return null;
// 	}

// 	const seatNumber = singleSeatWagers.seatNumber;
// 	if (!isValidSeat(seatNumber, validSeatNumbers)) {
// 		logError(`Specified seat number '${seatNumber} is not valid for placing wagers'`, debugMethod);
// 		return null;
// 	}

// 	let wagerCount: number = 0;
// 	const request = newMakeWagersRequest(playId, tableId);

// 	entries(singleSeatWagers.wagers).forEach(([_, w]) => {
// 		const wagerId = w.wagerId;
// 		const currencyCode = opts?.currencyCode || w.currencyCode;
// 		const contextId = w.contextId || playId;
// 		const amount = w.amount;
// 		const categoryMemberKey = w.categoryMemberKey;

// 		const sw = newSeatWager({
// 			seatNumber,
// 			wagerId,
// 			contextId,
// 			amount,
// 			currencyCode,
// 			categoryMemberKey,
// 		});

// 		if (sw == null) {
// 			return;
// 		}

// 		request.wagers.push(sw);
// 		wagerCount++;
// 	});

// 	if (wagerCount === 0) {
// 		logError('No valid wager items added to request', debugMethod);
// 		return null;
// 	}

// 	return request;
// };

// /**
//  * TODO: TEMPORARY UNTIL WE FINISH THE `_sendWagersTransactionQueue` AT WHICH POINT WE WILL MOVE THIS.
//  *
//  * Creates a batch of requests for the specified multiple seat wagers.
//  */
// const createMultiSeatWagersRequestsBatch = (
// 	multipleSeatWagers: IMultiSeatPlacedWagerSet,
// 	playId: string,
// 	tableId: string,
// 	validSeatNumbers: number[],
// 	currencyCode?: Maybe<string>
// ): SeatMakeWagersBatch => {
// 	const debugMethod = 'createMultiSeatWagersRequestsBatch';

// 	if (playId === '') {
// 		logError('A valid play ID must be specified.', debugMethod);
// 		return [];
// 	}

// 	if (tableId === '') {
// 		logError('A valid table ID must be specified.', debugMethod);
// 		return [];
// 	}

// 	if (validSeatNumbers.length === 0) {
// 		logError('A non-empty set of valid seat numbers must be specified.', debugMethod);
// 		return [];
// 	}

// 	const seatWagerEntries = entries(multipleSeatWagers);
// 	if (seatWagerEntries.length === 0) {
// 		logError('No seat wagers specified in multi-seat wager set.', debugMethod);
// 		return [];
// 	}

// 	const batch: SeatMakeWagersBatch = [];

// 	seatWagerEntries.forEach(([_, sw]) => {
// 		const seatNumber = sw.seatNumber;

// 		if (!isValidSeat(seatNumber, validSeatNumbers)) {
// 			logWarn(`Specified seat number '${seatNumber} is not valid for placing wagers.'`, debugMethod);
// 			return; // Continue
// 		}

// 		const request = createSingleSeatWagersRequest(sw, playId, tableId, validSeatNumbers, { currencyCode });
// 		if (request == null) {
// 			return; // Continue
// 		}

// 		batch.push({ seatNumber, request });
// 	});

// 	if (batch.length === 0) {
// 		logError('No valid seat wager requests were created in batch.', debugMethod);
// 		return [];
// 	}

// 	return batch;
// };

// ---- Exports -------------------------------------------------------------------------------------------------------

export {
	createMultiSeatWagersCombinedRequest,
	extractMswSeatNums,
	newSeatWager,
	newMakeWagersRequest,
	sendMakeWagersRequest,
	sendMakeWagersRequestThrottled,
};

export { newClearWagersRequest, sendClearWagersRequest, sendClearWagersRequestThrottled };
export { sendReplaceWagersRequest, sendReplaceWagersRequestThrottled };
