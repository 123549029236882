enum StreamCancelCode {
	CANCEL = 'CANCEL',
	STOP = 'STOP',
	FORCE_STOP = 'FORCE_STOP',
	RESTART = 'RESTART',
	TIMEOUT = 'TIMEOUT',
}

// ---- Exports -------------------------------------------------------------------------------------------------------

export { StreamCancelCode };
