const getNowTs = (): number => new Date().getTime();
const getNowUnix = (): number => Date.now();

/**
 * Converts the given milliseconds (ms) to the component parts: seconds, minutes and hours. If the provided milliseconds
 * is greater or equal to 24 hours (8.64e+7) the result will always be [0,0,24] since modulus 24 will cause a roll over.
 *
 * Note: This was taken from the client-library remainingTime function which was broke and contained
 * logic for padding the numbers and returning the string equivelent. We should replace the time portion
 * there with this method.
 *
 * @returns A tuple containing [seconds, minutes, hours].
 */
const convertMilliseconds = (ms?: number): [number, number, number] => {
	// Protect against null or undefined values.
	ms = ms ?? 0;

	// Nothing to do.
	if (ms <= 0) {
		return [0, 0, 0];
	}

	// Don't allow hour modulus rollover.
	if (ms >= 8.64e7) {
		return [0, 0, 24];
	}

	const seconds = Math.floor((ms / 1000) % 60);
	const minutes = Math.floor((ms / (1000 * 60)) % 60);
	const hours = Math.floor((ms / (1000 * 60 * 60)) % 24);

	return [seconds, minutes, hours];
};

// ---- Exports -------------------------------------------------------------------------------------------------------

export { getNowTs, getNowUnix, convertMilliseconds };
