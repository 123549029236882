import { generateRandomString } from '../../../helpers';
import { CACHE_DEFAULT_EXPIRY_SECS, StorageType } from './constants';
import { IJsonOpts, ILocalStorageCache, ILocalStorageCacheData, ILocalStorageCacheOpts } from './types';

/**
 * This will be used as the base for any storage provider that needs to use any type of local storage.
 */
abstract class LocalStorageCache<T extends ILocalStorageCacheData> implements ILocalStorageCache<T> {
	/* #region ---- Properties --------------------------------------------------------------------------------------- */

	/**
	 * Instance ID used to identify this specific instance.
	 */
	protected _instanceId: string = '';

	/**
	 * Storage key to use when placing the data in local storage.
	 */
	protected _storageKey: string = '';

	/**
	 * Seconds after which the session storage is considered to be expired. Expired data will automatically get thrown
	 * away and defaults returned.
	 */
	protected _expirySecs: number = CACHE_DEFAULT_EXPIRY_SECS;

	/**
	 * Memory data cache. Local storage is updated shortly after this is changed.
	 */
	protected _cachedData: Nullable<T> = null;

	/**
	 * If this is FALSE then only the memory cache storage is used. Useful for unit tests.
	 */
	protected _useStorage: boolean = false;

	/**
	 * What type of storage to use (ie. Local or Session)
	 */
	protected _storageType: StorageType = StorageType.SESSION;

	/* #endregion ---- Properties ------------------------------------------------------------------------------------ */

	/* #region ---- CONSTRUCTOR -------------------------------------------------------------------------------------- */

	constructor(storageKey: string, opts?: Maybe<ILocalStorageCacheOpts>) {
		if (storageKey === '') {
			throw new Error('A storage key must be specified');
		}

		const expirySecs = opts?.expirySecs ?? 0;

		this._storageKey = storageKey || this._storageKey;
		this._expirySecs = expirySecs > 0 ? expirySecs : this._expirySecs;
		this._useStorage = opts?.useStorage ?? this._useStorage;
		this._storageType = opts?.storageType || this._storageType;
		this._instanceId = opts?.instanceId || generateRandomString();

		this.loadCache();
	}

	/* #endregion ---- CONSTRUCTOR ----------------------------------------------------------------------------------- */

	/* #region ---- Public ------------------------------------------------------------------------------------------- */

	/**
	 * Returns the class instance id.
	 */
	public get instanceId(): string {
		return this._instanceId;
	}

	/**
	 * Get all of the data currently stored for the local storage key.
	 */
	public get data(): Nullable<T> {
		if (this._cachedData == null) {
			return null;
		}

		// If the data has expired then reset it before returning
		if (this.hasDataExpired(this._cachedData)) {
			this.reset();
		}

		return this._cachedData;
	}

	/**
	 * Returns the UTC unix timestamp for the time at which when the cached data is considered to be expired.
	 */
	public get expirationTs(): number {
		return this._cachedData?.expirationTs || 0;
	}

	/**
	 * Returns the stored data as a plain object or null if not set.
	 */
	public get storedData(): Nullable<T> {
		const value = (this._useStorage && this.storage.getItem(this._storageKey)) || '';
		return (value ? (JSON.parse(value) as T) : null) || null;
	}

	protected set storedData(value: Nullable<T>) {
		if (!this._useStorage) {
			return;
		}

		if (value == null) {
			this.storage.removeItem(this._storageKey);
		}

		this.storage.setItem(this._storageKey, JSON.stringify(value));
	}

	/**
	 * @returns TRUE if the data is considered to be expired.
	 */
	public hasExpired(): boolean {
		return this.hasDataExpired();
	}

	/**
	 * Keep the cache alive.
	 */
	public refreshExpiry() {
		this.update(this._cachedData, true);
	}

	/**
	 * Resets the cached data and relevant local storage back to default values.
	 */
	public reset = () => {
		this.updateCache(this.makeDefaults());
	};

	/**
	 * Clears (deletes) the cached data and relevant local storage.
	 */
	public clear = () => {
		this.updateCache(null);
	};

	/**
	 * @returns A JSON export of the current pertinent data.
	 */
	public toJS(verbose?: Maybe<boolean>): PlainObject {
		const result: PlainObject = {
			id: this.instanceId,
			data: this.data,
			storedData: this.storedData,
		};

		if (verbose) {
			const extra: PlainObject = {
				expirationTs: this.expirationTs,
				storageKey: this._storageKey,
				storageType: this._storageType,
				useStorage: this._useStorage,
			};

			return { ...result, ...extra };
		}

		return result;
	}

	/**
	 * @returns A JSON string version of the current pertinent data.
	 */
	public toJSON(opts?: Maybe<IJsonOpts>): string {
		const data = this.toJS(opts?.verbose);

		if (opts?.pretty) {
			return JSON.stringify(data, null, 2);
		}

		return JSON.stringify(data);
	}

	/* #endregion ---- Public ---------------------------------------------------------------------------------------- */

	/* #region ---- Protected ---------------------------------------------------------------------------------------- */

	protected update(data: Nullable<T>, refreshExpirationTs: boolean = true) {
		if (data != null && refreshExpirationTs) {
			data = { ...data, expirationTs: this.newExpirationTs() };
		}

		this.updateCache(data);
	}

	protected newExpirationTs = (expirySecs?: Maybe<number>) => {
		expirySecs = expirySecs ?? 0;
		return Date.now() + (expirySecs > 0 ? expirySecs : this._expirySecs) * 1000;
	};

	protected hasDataExpired(data?: Nullable<T>): boolean {
		data = data ?? this._cachedData;
		return (data?.expirationTs || 0) <= Date.now();
	}

	protected get storage(): Storage {
		return this._storageType === StorageType.LOCAL ? this.localStorage : this.sessionStorage;
	}

	protected get sessionStorage(): Storage {
		return globalThis.sessionStorage;
	}

	protected get localStorage(): Storage {
		return globalThis.localStorage;
	}

	protected updateCache = (data: Nullable<T>, saveToStorage: boolean = true): Nullable<T> => {
		this._cachedData = data;
		saveToStorage && this.syncToStorage();

		return this._cachedData;
	};

	protected makeDefaults(): T {
		return { expirationTs: this.newExpirationTs() } as T;
	}

	protected loadCache = () => {
		const data = { ...this.makeDefaults(), ...(this.storedData || {}) };

		if (this.hasDataExpired(data)) {
			this.reset();
			return;
		}

		this.updateCache(data, false);
	};

	protected expiryCheck = (): void => {
		if (this.hasDataExpired(this._cachedData)) {
			this.reset();
		}
	};

	protected getDataProp = <V = unknown>(key: string): Nullable<V> => {
		const data = this.data;
		const value = (data ? data[key] : null) ?? null;

		return value as Nullable<V>;
	};

	protected setDataProp = <V = unknown>(key: string, value: V): void => {
		const newData = { ...(this.data || {}), [key]: value } as T;
		this.update(newData);
	};

	protected getStoredDataProp = <V = unknown>(key: string): Nullable<V> => {
		const data = this.storedData;
		const value = (data ? data[key] : null) ?? null;

		return value as Nullable<V>;
	};

	protected syncToStorage = () => {
		this.storedData = this._cachedData;
	};

	/* #endregion ---- Protected ------------------------------------------------------------------------------------- */
}

// ---- Exports -------------------------------------------------------------------------------------------------------

export { LocalStorageCache as default };
export { LocalStorageCache };
