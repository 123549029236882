import { KumquatWager as ChoiceWager, KumquatChoice } from '../../../client/rpc/data';
import { IChoicePeriodDataExt, IPlayChoiceDefinitionData, IPlayChoiceDefinitionDataExt } from '../../../helpers/data';
import { IAvailableChoiceConfig } from './types';

/**
 * Combined choice definition, choice period and choice request.
 */
class AvailableChoice {
	/* #region Properties ------------------------------------------------------------------------------------------- */

	protected _definition!: IPlayChoiceDefinitionDataExt;
	protected _period!: Nullable<IChoicePeriodDataExt>;
	protected _contextId: string = '';
	protected _wagers: ChoiceWager[] = [];
	protected _values: string[] = [];

	/* #endregion --------------------------------------------------------------------------------------------------- */

	/* #region Getters / Setters ------------------------------------------------------------------------------------ */

	/**
	 * @returns The choice definition associated with this choice.
	 */
	public get definition(): IPlayChoiceDefinitionDataExt {
		return this._definition;
	}
	public set definition(val: IPlayChoiceDefinitionDataExt) {
		this._definition = val;
	}

	/**
	 * @returns The choice period associated with this choice.
	 */
	public get period(): Nullable<IChoicePeriodDataExt> {
		return this._period;
	}
	public set period(val: Nullable<IChoicePeriodDataExt>) {
		this._period = val;
	}

	/**
	 * @returns The context id set for this choice.
	 */
	public get contextId(): string {
		return this._contextId;
	}
	public set contextId(val: string) {
		this._contextId = val;
	}

	/**
	 * @returns The raw proto choice definition.
	 */
	public get raw(): Nullable<IPlayChoiceDefinitionData> {
		return this._definition.raw;
	}

	/**
	 * @returns Unique ID for the choice
	 */
	public get choiceId(): string {
		return this._definition.choiceId;
	}

	/**
	 * @returns Unique name for the choice
	 */
	public get choiceName(): string {
		return this._definition.name;
	}

	/**
	 * @returns TRUE, if all wagers must be placed before this choice can be taken.
	 */
	public get isAllWagersRequired(): boolean {
		return this._definition.isAllWagersRequired;
	}

	/**
	 * @returns TRUE, if context/hand must be present for this choice to be taken.
	 */
	public get isContextRequired(): boolean {
		return this._definition.isContextRequired;
	}

	/**
	 * @returns The original available wager ids from the raw data.
	 */
	public get availableWagerIds(): string[] {
		return this._definition.availableWagerIds;
	}

	/**
	 * @returns Play ID the choice is associated with (if known).
	 */
	public get playId(): string {
		return this._definition.playId;
	}

	/**
	 * @returns The choice values that have been set.
	 */
	public get values(): string[] {
		return this._values || [];
	}
	public set values(vals: string[]) {
		this._values = vals;
	}

	/**
	 * @returns The wagers that have been added on this choice.
	 */
	public get wagers(): ChoiceWager[] {
		return this._wagers;
	}

	/**
	 * @returns TRUE if valid. Error if not.
	 */
	public get isValid(): Error | boolean {
		const choice = this._definition;

		if (choice.isAllWagersRequired && this.wagers.length !== choice.availableWagerIds.length) {
			return new Error(
				`All wagers required for choice ${choice.name}, but got ${this.wagers.length} out of ${choice.availableWagerIds.length}`
			);
		}

		if (this._period && this._period.isClosed) {
			return new Error(`Choice not longer valid since the choice period ${this._period.id} is closed`);
		}

		return true;
	}

	/* #endregion --------------------------------------------------------------------------------------------------- */

	/* #region Constructor ------------------------------------------------------------------------------------------ */

	/**
	 * constructor
	 */
	public constructor(config: IAvailableChoiceConfig) {
		this._contextId = config.contextId || config.definition.contextId || '';
		this._definition = config.definition;
		this._period = config.period || null;
	}

	/* #endregion --------------------------------------------------------------------------------------------------- */

	/* #region Public methods --------------------------------------------------------------------------------------- */

	/**
	 * Adds a new ChoiceWager to the choice's wagers list.
	 *
	 * @returns NULL if validation passes.
	 */
	public addWager(choiceWager: ChoiceWager): Nullable<Error> {
		const choiceName = this._definition.name;
		const wagerId = choiceWager.wagerTypeId;

		const isAvailable = this._definition.availableWagerIds.find((id: string) => id == wagerId) != null;
		if (!isAvailable) {
			return new Error(`Wager ${wagerId} not available for choice ${choiceName}`);
		}

		const isMatch = this.wagers.find((value: ChoiceWager) => value.wagerTypeId === wagerId) != null;
		if (isMatch) {
			return new Error(`Wager ${wagerId} already set on choice ${choiceName}`);
		}

		this._wagers.push(choiceWager);

		return null;
	}

	/**
	 * Creates the choice request object based on the class properties.
	 *
	 * @returns KumquatChoice
	 */
	public createRequestData = (): KumquatChoice => {
		const request: KumquatChoice = new KumquatChoice({
			choiceTypeId: this._definition.choiceId,
			contextId: this.contextId,
			values: this.values,
			wagers: this.wagers,
		});

		return request;
	};

	/**
	 * Creates an AvailableChoice instance.
	 *
	 * @returns The AvailableChoice instance.
	 */
	public static newAvailableChoice(config: IAvailableChoiceConfig): AvailableChoice {
		return new AvailableChoice(config);
	}

	/**
	 * @returns A JSON export of the current pertinent data.
	 */
	public toJson(extended?: Maybe<boolean>): PlainObject {
		const result: PlainObject = {
			name: this._definition.name,
			choiceId: this._definition.choiceId,
			choicePeriodId: this._definition.choicePeriodId,
			contextId: this.contextId,
			values: this.values,
			wagers: this.wagers,
			isValid: this.isValid,
		};

		if (extended) {
			result.definition = this._definition;
			result.period = this._period;
		}

		return result;
	}

	/* #endregion --------------------------------------------------------------------------------------------------- */
}

// ---- Export --------------------------------------------------------------------------------------------------------

export { AvailableChoice };
