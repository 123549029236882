import omit from 'lodash/omit';
import { WageringMode } from '../../client/rpc/enums';
import { filterNullUndefined } from '../shared';
import { IWagerPeriodData, IWagerPeriodDataExt } from './types/wagerPeriodExt';

/**
 * @returns An empty `IWagerPeriodData` object with default values.
 */
const newEmptyWagerPeriodData = (): IWagerPeriodData => ({
	wagerPeriodId: '',
	name: '',
	closing: false,
	closed: false,
	availabilityWindow: BigInt(0),
	remainingTime: BigInt(0),
	wageringMode: WageringMode.WAGER_MODE_REQUIRED_ALL,
	meta: {},
});

/**
 * @returns An empty `IWagerPeriodDataExt` object with default values.
 */
const newEmptyWagerPeriodDataExt = (): IWagerPeriodDataExt => ({
	raw: newEmptyWagerPeriodData(),
	id: '',
	name: '',
	isClosing: false,
	isClosed: false,
	totalSeconds: 0,
	remainingSeconds: 0,
	wageringMode: WageringMode.WAGER_MODE_REQUIRED_ALL,
	meta: {},
});

/**
 * @returns {IWagerPeriodDataExt} Extended version of `IWagerPeriodData` with re-mapped types, props.
 */
const extendWagerPeriodData = (src: IWagerPeriodData): IWagerPeriodDataExt => {
	const base = omit(src, ['wagerPeriodId', 'closed', 'closing', 'availabilityWindow', 'remainingTime', 'meta']);

	return {
		...newEmptyWagerPeriodDataExt(),
		...filterNullUndefined(base),
		raw: { ...src },
		id: src.wagerPeriodId,
		isClosed: src.closed,
		isClosing: src.closing,
		totalSeconds: Number(src.availabilityWindow),
		remainingSeconds: Number(src.remainingTime) / 1000,
		meta: src.meta as Dict<string>,
	};
};

/**
 * @returns {IWagerPeriodDataExt[]} Extended version of `IWagerPeriodData[]` with re-mapped types, props.
 */
const extendWagerPeriodDataList = (srcList: IWagerPeriodData[]): IWagerPeriodDataExt[] => {
	const result: IWagerPeriodDataExt[] = [];

	for (const src of srcList) {
		result.push(extendWagerPeriodData(src));
	}

	return result;
};

// ---- Export --------------------------------------------------------------------------------------------------------

export { extendWagerPeriodData, extendWagerPeriodDataList };
export { newEmptyWagerPeriodData, newEmptyWagerPeriodDataExt };

// Other
export * from './types/wagerPeriodExt';
