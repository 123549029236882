import { IStreamClientOpts, IStreamController } from '../../core';
import { IStreamOpts, IValidateStartResult, Stream, StreamStatus } from '../../core/Stream';
import { DeviceGetDeviceMessagesReply, GetDeviceMessagesRequest } from '../../rpc';
import { DeviceServerClient } from '../../rpc/clients/deviceserver';
import { makeStreamPropHashId } from '../utility';
import { DeviceDataStreamRequestProps, IDeviceDataStream } from './types';

type RequestProps = DeviceDataStreamRequestProps;
type ValidateStartResult = IValidateStartResult<RequestProps> & {
	deviceId: string;
};

class DeviceDataStream
	extends Stream<typeof DeviceServerClient, DeviceGetDeviceMessagesReply, RequestProps>
	implements IDeviceDataStream
{
	/* #region ---- CONSTRUCTOR -------------------------------------------------------------------------------------- */

	constructor(url: string, opts?: IStreamOpts) {
		super(url, opts);
	}

	/* #endregion ---- CONSTRUCTOR ----------------------------------------------------------------------------------- */

	/* #region ---- Public ------------------------------------------------------------------------------------------- */

	/**
	 * Attempts to start this stream.
	 *
	 * @returns TRUE if the attempt to start the stream succeeded. Note that this does NOT mean the stream actually
	 *          connected and received data - you must subscribe to the stream to know that.
	 */
	public start(props?: Maybe<RequestProps>): boolean {
		const { deviceId, isValid, requestProps } = this.validateStart(props);

		if (!isValid) {
			return false;
		}

		const propHashId = makeStreamPropHashId(deviceId);
		const lastPropHashId = makeStreamPropHashId(this._lastRequestProps?.deviceId || '');

		// Stream is already running
		if (this.isActive) {
			// Different props specified? Treat it as a restart
			if (propHashId !== lastPropHashId) {
				return this.restart(requestProps);
			}

			this.warn(
				'Attempted to start an already running stream using the same device ID. Use `restart` if this is intended',
				'start',
				{ ...requestProps }
			);

			return false;
		}

		// Manual starts will clear any auto-restart cycle that might be active
		this.clearAutoRestarts();
		this.currentState.status = StreamStatus.STARTING;

		const didRun = this.runStream(requestProps);
		if (didRun) {
			this.afterStart(requestProps);
		}

		return didRun;
	}

	/* #endregion ---- Public ---------------------------------------------------------------------------------------- */

	/* #region ---- Protected ---------------------------------------------------------------------------------------- */

	/**
	 * Determines if we are allowed to start/restart this stream.
	 *
	 * @returns The result of the validation. This also includes the processed request props to apply.
	 */
	protected validateStart(requestProps?: Maybe<RequestProps>): ValidateStartResult {
		const debugMethod = 'validateStart';
		requestProps = requestProps ?? this._lastRequestProps ?? null;

		if (requestProps == null) {
			this.error('Request props must be specified', debugMethod);
			return { isValid: false, requestProps: null, deviceId: '' };
		}

		const { deviceId = '' } = requestProps;

		if (deviceId === '') {
			this.error('Device ID must be specified', debugMethod);
			return { isValid: false, requestProps, deviceId: '' };
		}

		if (!this.isEnabled) {
			this.error('Stream is disabled', debugMethod, { deviceId });
			return { isValid: false, requestProps, deviceId };
		}

		return { isValid: true, requestProps, deviceId };
	}

	/**
	 * Creates and starts a new device requests stream.
	 *
	 * @returns TRUE if successfully able to create and start the stream.
	 */
	protected runStream(requestProps?: Maybe<RequestProps>): boolean {
		const { deviceId = '' } = requestProps ?? {};

		if (deviceId === '') {
			this.error('Device ID must be specified', 'runStream');
			return false;
		}

		this._streamController = this.newStream(deviceId);

		return true;
	}

	/**
	 * Starts a new device request stream for the specified device ID.
	 *
	 * @param deviceId Device ID to use when starting the stream.
	 */
	protected newStream(deviceId: string): IStreamController {
		// TODO: Add support for the device type param
		const request = new GetDeviceMessagesRequest({ deviceId });

		return this.stream<typeof request>('streamDeviceMessages', request);
	}

	/**
	 * @returns The stream client instance used by this stream.
	 */
	protected createStreamClient(url: string, clientOpts?: Maybe<IStreamClientOpts>) {
		return this.newStreamClient(DeviceServerClient, url, clientOpts);
	}

	/* #endregion ---- Protected ------------------------------------------------------------------------------------- */

	/* #region ---- Debug -------------------------------------------------------------------------------------------- */

	/**
	 * Overrides the parent class property.
	 *
	 * @returns The label to use when debugging.
	 */
	protected override get debugClassLabel(): string {
		return DeviceDataStream.debugClassLabel();
	}

	/**
	 * STATIC
	 * @returns Label assigned to this class namespace.
	 */
	protected static debugClassLabel(): string {
		return `RpcLib.Stream.DeviceRequestStream`;
	}

	/* #endregion ---- Debug ----------------------------------------------------------------------------------------- */
}

// ---- Exports -------------------------------------------------------------------------------------------------------

export { DeviceDataStream as default };
export { DeviceDataStream };
