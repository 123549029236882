enum CurrencyCode {
	USD = 'USD',
	EUR = 'EUR',
	GBP = 'GBP',
}

const DEFAULT_CURRENCY_CODE = CurrencyCode.USD.toString();
const DEFAULT_CURRENCY_EXPONENT = 2;
const DEFAULT_CURRENCY_SYMBOL = '$';

// ---- Exports -------------------------------------------------------------------------------------------------------

export { DEFAULT_CURRENCY_CODE, DEFAULT_CURRENCY_SYMBOL, DEFAULT_CURRENCY_EXPONENT };
export { CurrencyCode };
