// @generated by protoc-gen-es v1.3.0 with parameter "target=js+dts"
// @generated from file games_common.proto (package com.sands.ldx.games, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { proto3 } from "@bufbuild/protobuf";

/**
 * Representation of a physical card from a standard deck. Sharing prototypes between games can be
 * problematic because changing shared code makes all of the affected games have to go through GLI
 * all over again but representations of physical objects should be safe to share among games. If a
 * game needs additional data about the physical object (card, die, etc) then create a new message
 * for it. Don't add optional game-specific fields to shared type definitions.
 *
 * @generated from message com.sands.ldx.games.Card
 */
export const Card = proto3.makeMessageType(
  "com.sands.ldx.games.Card",
  () => [
    { no: 1, name: "suit", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "rank", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "dealt_order", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ],
);

