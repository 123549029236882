import trimEnd from 'lodash/trimEnd';
import { combineHashCodes, strToHashCode } from '../shared';
import { EMPTY_GUID } from './constants';

/**
 * @returns A unique key used to identify an available wager.
 */
const makeAvailableWagerKey = (
	wagerId: string,
	opts?: Maybe<{ contextId?: Maybe<string>; categoryMemberKey?: Maybe<string> }>
): string => {
	const debugMethod = 'makeAvailableWagerKey';

	const contextId = opts?.contextId ?? '';
	const categoryMemberKey = opts?.categoryMemberKey ?? '';

	if (wagerId === '') {
		console.warn(`Specified available wager key params are not valid.'`, debugMethod, { wagerId });
		return '';
	}

	const delim = '|';
	const parts = [wagerId, contextId, categoryMemberKey];

	return trimEnd(parts.join(delim), delim);
};

/**
 * @returns The extracted available wager key params.
 */
const extractAvailableWagerKey = (
	availableWagerKey: string
): Nullable<{ wagerId: string; contextId: string; categoryMemberKey: string }> => {
	const debugMethod = 'extractAvailableWagerKey';

	if (availableWagerKey === '') {
		console.warn(`Specified available wager key is empty.`, debugMethod);
		return null;
	}

	const delim = '|';
	const parts = availableWagerKey.split(delim);

	if (parts.length < 1) {
		console.warn(`Specified available wager key is not valid format.`, debugMethod, { availableWagerKey });
		return null;
	}

	const wagerId = parts[0];
	const contextId = parts[1] ?? '';
	const categoryMemberKey = parts[2] ?? '';

	return { wagerId, contextId, categoryMemberKey };
};

/**
 * @returns A unique key used to identify an active wager.
 */
const makeActiveWagerKey = (
	wagerId: string,
	opts?: Maybe<{ seatNumber?: Maybe<number>; contextId?: Maybe<string>; categoryMemberKey?: Maybe<string> }>
): string => {
	const debugMethod = 'makeActiveWagerKey';

	const seatNumber = opts?.seatNumber ?? 1;
	const contextId = opts?.contextId ?? '';
	const categoryMemberKey = opts?.categoryMemberKey ?? '';

	if (seatNumber < 0 || wagerId === '') {
		console.warn(`Specified wager key params are not valid.'`, debugMethod, { seatNumber, wagerId });
		return '';
	}

	const delim = '|';
	const parts = [seatNumber, wagerId, contextId, categoryMemberKey];

	return trimEnd(parts.join(delim), delim);
};

/**
 * Makes an active wager key from an available wager key plus a seat number.
 *
 * @returns A unique key used to identify an active wager.
 */
const makeActiveWagerKeyFromAvailableKey = (
	availableWagerKey: string,
	opts?: Maybe<{ seatNumber?: Maybe<number> }>
): string => {
	const debugMethod = 'makeActiveWagerKeyFromAvailableKey';

	const seatNumber = opts?.seatNumber ?? 1;

	if (seatNumber < 0 || availableWagerKey === '') {
		console.warn(`Specified wager key params are not valid.'`, debugMethod, { seatNumber, availableWagerKey });
		return '';
	}

	const delim = '|';
	const parts = [seatNumber, availableWagerKey];

	return parts.join(delim);
};

/**
 * @returns The extracted active wager key params.
 */
const extractActiveWagerKey = (
	wagerKey: string
): Nullable<{ seatNumber: number; wagerId: string; contextId: string; categoryMemberKey: string }> => {
	const debugMethod = 'extractActiveWagerKey';

	if (wagerKey === '') {
		console.warn(`Specified active wager key is empty.`, debugMethod);
		return null;
	}

	const delim = '|';
	const parts = wagerKey.split(delim);

	if (parts.length < 2) {
		console.warn(`Specified wager key is not valid format.`, debugMethod, { wagerKey });
		return null;
	}

	const seatNumber = parseInt(parts[0]);
	const wagerId = parts[1];
	const contextId = parts[2] ?? '';
	const categoryMemberKey = parts[3] ?? '';

	return { seatNumber, wagerId, contextId, categoryMemberKey };
};

const normalizeContextId = (contextId?: Maybe<string>): string => {
	contextId = (contextId ?? '').trim();

	if (contextId === EMPTY_GUID) {
		return '';
	}

	return contextId;
};

/**
 * @returns A hash value (number) for any single value.
 */
const generatePropHashVal = (prop: unknown): number => {
	if (prop == null || prop === '') {
		return 0;
	}

	const typeName = (typeof prop).toLowerCase();

	if (typeName === 'boolean') {
		return Number(prop ? '1' : '0');
	}
	if (typeName === 'bigint') {
		return Number(prop);
	}
	if (typeName === 'number') {
		return Number(prop);
	}
	if (typeName === 'string') {
		return strToHashCode(String(prop));
	}

	return strToHashCode(JSON.stringify(prop));
};

/**
 * @returns A hash ID (string) for any single value.
 */
const generatePropHashId = (prop: unknown): string => generatePropHashVal(prop).toString();

/**
 * @returns A hash value (number) for a single object of the specified type.
 */
const generateObjectHashVal = <ObjectType>(
	obj: ObjectType,
	propKeys: (keyof ObjectType)[],
	addSalt?: Maybe<string[]>
): number => {
	let hash = 5381;

	if (obj == null || Object.keys(obj).length === 0 || propKeys.length === 0) {
		return 0;
	}

	addSalt = addSalt ?? [];
	if (addSalt.length > 0) {
		const combine: number[] = [];

		addSalt.forEach((saltVal) => {
			combine.push(strToHashCode(saltVal));
		});

		const saltHash = combineHashCodes(...combine);
		hash = (hash << 5) + hash + saltHash;
	}

	const combine: number[] = [];
	propKeys.forEach((propKey) => {
		const propValue = obj[propKey] ?? null;

		if (propValue == null) {
			return;
		}

		const propHash = generatePropHashVal(propValue);
		combine.push(propHash);
	});

	const objectHash = combineHashCodes(...combine);

	// Use a simple bitwise operation to combine the hash values
	hash = (hash << 5) + hash + objectHash;

	return hash;
};

/**
 * @returns A hash ID (string) for a single object of the specified type.
 */
const generateObjectHashId = <ObjectType>(
	obj: ObjectType,
	propKeys: (keyof ObjectType)[],
	addSalt?: Maybe<string[]>
): string => {
	return generateObjectHashVal(obj, propKeys, addSalt).toString();
};

/**
 * @returns A hash value (number) for a list of objects of the specified type.
 */
const generateObjectListHashVal = <ListItemObjectType>(
	list: ListItemObjectType[],
	propKeys: (keyof ListItemObjectType)[],
	addSalt?: Maybe<string[]>
): number => {
	let hash = 5381;

	if (list.length === 0 || propKeys.length === 0) {
		return 0;
	}

	addSalt = addSalt ?? [];
	if (addSalt.length > 0) {
		const combine: number[] = [];

		addSalt.forEach((saltVal) => {
			combine.push(strToHashCode(saltVal));
		});

		const saltHash = combineHashCodes(...combine);
		hash = (hash << 5) + hash + saltHash;
	}

	const combine: number[] = [];

	list.forEach((item) => {
		if (item == null) {
			return;
		}

		const itemHash = generateObjectHashVal(item, propKeys);
		combine.push(itemHash);
	});

	// Use a simple bitwise operation to combine the hash values
	const listHashVal = combineHashCodes(...combine);
	hash = (hash << 5) + hash + listHashVal;

	return hash;
};

/**
 * @returns A hash value (string) for a list of objects of the specified type.
 */
const generateObjectListHashId = <ListItemType>(
	list: ListItemType[],
	propKeys: (keyof ListItemType)[],
	addSalt?: Maybe<string[]>
): string => {
	return generateObjectListHashVal(list, propKeys, addSalt).toString();
};

// ---- Exports -------------------------------------------------------------------------------------------------------

export { makeAvailableWagerKey, extractAvailableWagerKey };
export { makeActiveWagerKey, extractActiveWagerKey };
export { makeActiveWagerKeyFromAvailableKey };
export { generatePropHashVal, generatePropHashId };
export { generateObjectHashVal, generateObjectHashId };
export { generateObjectListHashVal, generateObjectListHashId };
export { normalizeContextId };
