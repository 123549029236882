import { IAuthSessionProvider } from '../AuthSession';

/**
 * Options for authenticating a request.
 */
interface IAuthenticateRequestOpts {
	authSession?: Maybe<IAuthSessionProvider>;
	headers?: Maybe<HeadersInit>;
	emptyTokenThrowsError?: Maybe<boolean>;
}

const authenticateRequest = (token?: Maybe<string>, opts?: Maybe<IAuthenticateRequestOpts>): Headers => {
	const { authSession = null, headers = null, emptyTokenThrowsError = true } = opts || {};

	token = token ?? '';
	token = token === '' && authSession != null ? authSession.token : token;

	// If we should set the token but we do not have it, throw an error, because any service request that accepts the
	// token will actually NEED the token. This behavior can be disabled via options.
	if (token === '') {
		const msg = 'Request requires valid authentication token';
		if (emptyTokenThrowsError) {
			throw new Error(msg);
		}

		console.error(msg);
	}

	let reqHeaders: Headers;

	if (headers instanceof Headers) {
		reqHeaders = headers;
	} else {
		reqHeaders = new Headers(headers ?? undefined);
	}

	reqHeaders.set('Authorization', `Bearer ${token}`);

	return reqHeaders;
};

// ---- Exports -------------------------------------------------------------------------------------------------------

export { authenticateRequest };
export type { IAuthenticateRequestOpts };

// Other
export type { IAuthSessionProvider, SessionProvider, SessionProviderAccessorFn } from '../AuthSession';
