import { ICancelablePromiseExt, NewCancelablePromiseExt } from '../../../helpers';
import { Service } from '../../core/Service';
import { IClientOpts, IServiceOpts, IServiceRequestOptions } from '../../core/types';
import { DeviceServerClient } from '../../rpc/clients/deviceserver';
import { DeviceGetDeviceMessagesReply, DeviceInputReply } from '../../rpc/replies/deviceserver';
import { DeviceInputRequest, GetDeviceMessagesRequest } from '../../rpc/requests/deviceserver';
import { IDeviceService } from './types';

/**
 * Studio device service.
 */
class DeviceService extends Service<typeof DeviceServerClient> implements IDeviceService {
	/* #region ---- CONSTRUCTOR -------------------------------------------------------------------------------------- */

	constructor(url: string, opts?: IServiceOpts) {
		super(url, opts);
	}

	/* #endregion ---- CONSTRUCTOR ----------------------------------------------------------------------------------- */

	/* #region ---- Public ------------------------------------------------------------------------------------------- */

	/**
	 * @returns An array of requests that have been issued to the specified device.
	 */
	public getDeviceRequests(
		deviceId: string,
		opts?: Maybe<IServiceRequestOptions>
	): ICancelablePromiseExt<DeviceGetDeviceMessagesReply> {
		console.warn('Obsolete, please use getDeviceMessages instead');
		return this.getDeviceMessages(deviceId, opts);
	}

	/**
	 * @returns An array of requests that have been issued to the specified device.
	 */
	public getDeviceMessages(
		deviceId: string,
		opts?: Maybe<IServiceRequestOptions>
	): ICancelablePromiseExt<DeviceGetDeviceMessagesReply> {
		const options = this.requestOptions(opts);
		const req: GetDeviceMessagesRequest = new GetDeviceMessagesRequest({ deviceId });
		const promise = this.rpcClient.getDeviceMessages(req, options.callOpts);

		return NewCancelablePromiseExt<DeviceGetDeviceMessagesReply>(promise, null, options.controller);
	}

	/**
	 * Issues a request to the specified device to control various functions/behaviors.
	 *
	 * @returns An empty reply.
	 */
	public deviceInput(
		deviceId: string,
		requestId: string,
		values: string[],
		opts?: Maybe<IServiceRequestOptions>
	): ICancelablePromiseExt<DeviceInputReply> {
		const options = this.requestOptions(opts);
		const req: DeviceInputRequest = new DeviceInputRequest({ deviceId, requestId, values });
		const promise = this.rpcClient.deviceInput(req, options.callOpts);

		return NewCancelablePromiseExt<DeviceInputReply>(promise, null, options.controller);
	}

	/**
	 * Alias for `deviceInput`.
	 */
	public dealInput(
		deviceId: string,
		requestId: string,
		values: string[],
		opts?: Maybe<IServiceRequestOptions>
	): ICancelablePromiseExt<DeviceInputReply> {
		return this.deviceInput(deviceId, requestId, values, opts);
	}

	/* #endregion ---- Public ---------------------------------------------------------------------------------------- */

	/* #region ---- Protected ---------------------------------------------------------------------------------------- */

	/**
	 * @returns The promise client instance used by this service.
	 */
	protected createPromiseClient(url: string, clientOpts?: Maybe<IClientOpts>) {
		return this.newPromiseClient(DeviceServerClient, url, clientOpts);
	}

	/* #endregion ---- Protected ------------------------------------------------------------------------------------- */
}

// ---- Exports -------------------------------------------------------------------------------------------------------

export { DeviceService as default };
export { DeviceService };
