// @generated by protoc-gen-es v1.3.0 with parameter "target=js+dts"
// @generated from file device.proto (package com.sands.ldx.deviceserver, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { Any, proto3 } from "@bufbuild/protobuf";

/**
 * @generated from message com.sands.ldx.deviceserver.DeviceInputRequest
 */
export const DeviceInputRequest = proto3.makeMessageType(
  "com.sands.ldx.deviceserver.DeviceInputRequest",
  () => [
    { no: 1, name: "device_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "request_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "values", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 5, name: "meta", kind: "map", K: 9 /* ScalarType.STRING */, V: {kind: "scalar", T: 9 /* ScalarType.STRING */} },
  ],
);

/**
 * @generated from message com.sands.ldx.deviceserver.GetDeviceMessagesRequest
 */
export const GetDeviceMessagesRequest = proto3.makeMessageType(
  "com.sands.ldx.deviceserver.GetDeviceMessagesRequest",
  () => [
    { no: 1, name: "device_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "type", kind: "enum", T: proto3.getEnumType(GetDeviceMessagesRequest_MessageType) },
  ],
);

/**
 * @generated from enum com.sands.ldx.deviceserver.GetDeviceMessagesRequest.MessageType
 */
export const GetDeviceMessagesRequest_MessageType = proto3.makeEnum(
  "com.sands.ldx.deviceserver.GetDeviceMessagesRequest.MessageType",
  [
    {no: 0, name: "BOTH"},
    {no: 1, name: "INPUT_REQUEST"},
    {no: 2, name: "HUD"},
  ],
);

/**
 * @generated from message com.sands.ldx.deviceserver.GetDeviceMessagesReply
 */
export const GetDeviceMessagesReply = proto3.makeMessageType(
  "com.sands.ldx.deviceserver.GetDeviceMessagesReply",
  () => [
    { no: 1, name: "device_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "type", kind: "enum", T: proto3.getEnumType(GetDeviceMessagesReply_MessageType) },
    { no: 3, name: "requests", kind: "message", T: InputRequest, repeated: true },
    { no: 4, name: "hud", kind: "message", T: HUD, opt: true },
  ],
);

/**
 * @generated from enum com.sands.ldx.deviceserver.GetDeviceMessagesReply.MessageType
 */
export const GetDeviceMessagesReply_MessageType = proto3.makeEnum(
  "com.sands.ldx.deviceserver.GetDeviceMessagesReply.MessageType",
  [
    {no: 0, name: "INPUT_REQUEST"},
    {no: 1, name: "HUD"},
  ],
);

/**
 * @generated from message com.sands.ldx.deviceserver.InputRequest
 */
export const InputRequest = proto3.makeMessageType(
  "com.sands.ldx.deviceserver.InputRequest",
  () => [
    { no: 1, name: "request_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "request_type", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "request_data", kind: "scalar", T: 12 /* ScalarType.BYTES */ },
  ],
);

/**
 * @generated from message com.sands.ldx.deviceserver.HUD
 */
export const HUD = proto3.makeMessageType(
  "com.sands.ldx.deviceserver.HUD",
  () => [
    { no: 1, name: "table_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "dealer_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "dealer_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "game_type", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "game_variant", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "auto_start", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 7, name: "play_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 8, name: "previous_play_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 9, name: "play", kind: "message", T: HUDPlay },
    { no: 10, name: "previous_play", kind: "message", T: HUDPlay },
    { no: 11, name: "estimated_streamers", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
  ],
);

/**
 * @generated from message com.sands.ldx.deviceserver.HUDPlay
 */
export const HUDPlay = proto3.makeMessageType(
  "com.sands.ldx.deviceserver.HUDPlay",
  () => [
    { no: 1, name: "game_type", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "game_variant", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "seats", kind: "message", T: HUDSeat, repeated: true },
    { no: 4, name: "open_device_requests", kind: "message", T: HistoricDeviceRequest, repeated: true },
    { no: 5, name: "complete_device_requests", kind: "message", T: HistoricDeviceRequest, repeated: true },
    { no: 6, name: "game_state", kind: "message", T: Any },
    { no: 7, name: "wager_periods", kind: "message", T: WagerPeriod, repeated: true },
    { no: 8, name: "choice_periods", kind: "message", T: ChoicePeriod, repeated: true },
    { no: 9, name: "celebrations", kind: "message", T: Celebration, repeated: true },
    { no: 10, name: "estimated_players", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 11, name: "current_time", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 12, name: "estimated_streamers", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
  ],
);

/**
 * @generated from message com.sands.ldx.deviceserver.HUDSeat
 */
export const HUDSeat = proto3.makeMessageType(
  "com.sands.ldx.deviceserver.HUDSeat",
  () => [
    { no: 1, name: "seat_position", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 2, name: "player_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "display_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "image_url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "player_game_state", kind: "message", T: Any, repeated: true },
  ],
);

/**
 * @generated from message com.sands.ldx.deviceserver.HistoricDeviceRequest
 */
export const HistoricDeviceRequest = proto3.makeMessageType(
  "com.sands.ldx.deviceserver.HistoricDeviceRequest",
  () => [
    { no: 1, name: "request_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "request_type", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "request_data", kind: "scalar", T: 12 /* ScalarType.BYTES */ },
    { no: 4, name: "input_values", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ],
);

/**
 * @generated from message com.sands.ldx.deviceserver.WagerPeriod
 */
export const WagerPeriod = proto3.makeMessageType(
  "com.sands.ldx.deviceserver.WagerPeriod",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "closing", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 4, name: "closed", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 5, name: "availability_window", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 6, name: "remaining_time", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 8, name: "meta", kind: "map", K: 9 /* ScalarType.STRING */, V: {kind: "scalar", T: 9 /* ScalarType.STRING */} },
    { no: 9, name: "expires_at", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
  ],
);

/**
 * @generated from message com.sands.ldx.deviceserver.ChoicePeriod
 */
export const ChoicePeriod = proto3.makeMessageType(
  "com.sands.ldx.deviceserver.ChoicePeriod",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "closed", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 4, name: "availability_window", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 5, name: "remaining_time", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 7, name: "meta", kind: "map", K: 9 /* ScalarType.STRING */, V: {kind: "scalar", T: 9 /* ScalarType.STRING */} },
    { no: 8, name: "expires_at", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
  ],
);

/**
 * @generated from message com.sands.ldx.deviceserver.Celebration
 */
export const Celebration = proto3.makeMessageType(
  "com.sands.ldx.deviceserver.Celebration",
  () => [
    { no: 1, name: "type", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "reason", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "seat_position", kind: "scalar", T: 4 /* ScalarType.UINT64 */, opt: true },
    { no: 4, name: "display_name", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
  ],
);

