import { action, AnnotationsMap, computed, makeObservable, observable } from 'mobx';
import { DebugBaseMobXAnnotations } from '../../../../common';
import { PlaySeatAssignments } from './PlaySeatAssignments';
import { PlaySeatAssignmentsMobXPrivateProps } from './types.mobx';

const PlaySeatAssignmentsMobXAnnotations: AnnotationsMap<PlaySeatAssignments, PlaySeatAssignmentsMobXPrivateProps> = {
	// Inherit from DebugBase
	...DebugBaseMobXAnnotations,

	// ---- Observed -----------------------------------------------------

	// >> Properties
	_data: observable.struct,

	// ---- Computed -----------------------------------------------------

	// >> Public
	data: computed,
	isEmpty: computed,
	size: computed,
	lookup: computed,
	list: computed,
	lastUpdatedTs: computed,
	dataHashId: computed,

	// ---- Actions ------------------------------------------------------

	// >> Public
	clear: action,
	populate: action,

	// >> Protected
	setLastUpdatedTs: action,
	setData: action,

	// ---- Excluded -----------------------------------------------------

	// >> Properties
	_isMobXBound: false,

	// >> Public
	isMobXBound: false,
	get: false,
	has: false,
	clone: false,
	toJson: false,
	isRawDataSame: false,
};

/**
 * Makes the specified `PlaySeatAssignments` instance an observable MobX object.
 */
const bindPlaySeatAssignmentsMobX = (instance: PlaySeatAssignments): boolean => {
	if (instance.isMobXBound) {
		return false;
	}

	makeObservable<PlaySeatAssignments, PlaySeatAssignmentsMobXPrivateProps>(instance, {
		...PlaySeatAssignmentsMobXAnnotations,
	});

	instance.isMobXBound = true;

	return true;
};

// ---- Exports -------------------------------------------------------------------------------------------------------

export { PlaySeatAssignmentsMobXAnnotations, bindPlaySeatAssignmentsMobX };
