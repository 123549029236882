import { IClientRpcSdk } from '../ClientRpcSdk';
import { debug } from './debug';
import { IClientRpcSdkDealerActions } from './types/dealer';

/**
 * Starts a new play.
 */
const newPlay = async (
	sdk: IClientRpcSdk,
	opts?: Maybe<{ isForced?: Maybe<boolean>; tableId?: Maybe<string> }>
): Promise<boolean> => {
	const debugMethod = 'dealer.newPlay';

	const tableId = opts?.tableId || sdk.tableId;
	const isForced = opts?.isForced ?? false;

	if (tableId === '') {
		debug.error('Unable to resolve a valid Table ID', debugMethod);
		return false;
	}

	const { devService } = sdk.services.getServices();
	await devService.newPlay(tableId, isForced).promise;

	return true;
};

/**
 * Runs the current play - this will start the dealing phase.
 */
const runPlay = async (
	sdk: IClientRpcSdk,
	opts?: Maybe<{ isForced?: Maybe<boolean>; tableId?: Maybe<string> }>
): Promise<boolean> => {
	const debugMethod = 'dealer.runPlay';

	const tableId = opts?.tableId || sdk.tableId;
	const isForced = opts?.isForced ?? false;

	if (tableId === '') {
		debug.error('Unable to resolve a valid Table ID', debugMethod);
		return false;
	}

	const { devService } = sdk.services.getServices();
	await devService.runPlay(tableId, isForced).promise;

	return true;
};

/**
 * Factory for creating dealer actions.
 */
const newDealerActions = (sdk: IClientRpcSdk): IClientRpcSdkDealerActions => {
	return Object.freeze({
		newPlay: async (tableId?: Maybe<string>, isForced?: Maybe<boolean>): Promise<boolean> =>
			newPlay(sdk, { tableId, isForced }),
		runPlay: async (tableId?: Maybe<string>, isForced?: Maybe<boolean>): Promise<boolean> =>
			runPlay(sdk, { tableId, isForced }),
	});
};

// ---- Export --------------------------------------------------------------------------------------------------------

export { newDealerActions };
