import { set } from 'mobx';
import { DebugBase } from '../../../../common';
import { filterNullUndefined } from '../../../shared';
import { bindPlaySeatAssignmentsMobX } from './mobx';
import {
	IMethodPlaySeatAssignmentsFilterByOpts,
	IMethodPlaySeatAssignmentsMapDataOpts,
	IMethodPlaySeatAssignmentsMapRawDataOpts,
	IPlaySeatAssignmentDataEntry,
	IPlaySeatAssignmentsData,
	IPlaySeatAssignmentsOpts,
	PlaySeatAssignmentsDataList,
	PlaySeatAssignmentsDataLookup,
	RawPlaySeatAssignmentList,
} from './types';
import {
	copyData,
	createSeatIdToNumLookup,
	defaultData,
	generateListHashId,
	generateRawListHashId,
	newDataFromList,
	newDataFromRawList,
} from './utility';

class PlaySeatAssignments extends DebugBase {
	/* #region ---- Properties --------------------------------------------------------------------------------------- */

	/**
	 * Currently assigned options.
	 */
	protected _options: IPlaySeatAssignmentsOpts = PlaySeatAssignments.defaultOptions();

	/**
	 * Encapsulated data.
	 */
	protected _data: IPlaySeatAssignmentsData;

	/**
	 * Flag indicating if this class instance is currently bound to MobX as an observable.
	 */
	protected _isMobXBound: boolean = false;

	/* #endregion ---- Properties ------------------------------------------------------------------------------------ */

	/* #region ---- CONSTRUCTOR -------------------------------------------------------------------------------------- */

	public constructor(opts?: Maybe<IPlaySeatAssignmentsOpts>) {
		super();

		this._data = opts?.data ?? defaultData();
		opts != null && this.setOptions(opts);

		// Bind to MobX as an observable.
		if (this._options.useMobX) {
			bindPlaySeatAssignmentsMobX(this);
		}
	}

	/* #endregion ---- CONSTRUCTOR ----------------------------------------------------------------------------------- */

	/* #region ---- Public ------------------------------------------------------------------------------------------- */

	/**
	 * Sets/initializes the class options.
	 *
	 * - Overrides the parent class method.
	 */
	public override setOptions(opts: IPlaySeatAssignmentsOpts) {
		const { newOpts, origOpts } = this.resolveOptions(opts);

		if (this._isMobXBound) {
			set(this, '_options', newOpts);
		} else {
			this._options = newOpts;
		}

		this.onSetOptions(newOpts, origOpts);
	}

	/**
	 * Whether or not the data collection is empty.
	 */
	public get isEmpty(): boolean {
		return this.size === 0;
	}

	/**
	 * Size of the data collection.
	 */
	public get size(): number {
		return this.lookup.size;
	}

	/**
	 * Lookup of seat assignment entries keyed by seat number.
	 */
	public get lookup(): PlaySeatAssignmentsDataLookup {
		return this._data.lookup;
	}

	/**
	 * Array of seat assignment entries.
	 */
	public get list(): PlaySeatAssignmentsDataList {
		return Array.from(this.lookup.values());
	}

	/**
	 * @returns All defined lookup keys in the data collection.
	 */
	public get keys(): string[] {
		return Array.from(this.lookup.keys());
	}

	/**
	 * The unique hash ID for the data collection.
	 */
	public get dataHashId(): string {
		return this.data.hashId ?? '';
	}

	/**
	 * The full data encapsulated by this class instance.
	 */
	public get data(): IPlaySeatAssignmentsData {
		return this._data;
	}
	// Sets the encapsulated class data. Actionable setter method for MobX.
	protected setData(val: IPlaySeatAssignmentsData) {
		if (val.uniqId === this._data.uniqId) {
			return;
		}

		const list = Array.from(val.lookup.values());

		const origHashId = val.hashId;
		val.hashId = generateListHashId(list);

		if (val.seatId2NumLookup == null || origHashId !== val.hashId) {
			val.seatId2NumLookup = createSeatIdToNumLookup(val.lookup);
		}

		if (this.isMobXBound) {
			set(this, '_data', val);
		} else {
			this._data = val;
		}
	}

	/**
	 * The last time (unix timestamp) that the data was updated.
	 */
	public get lastUpdatedTs(): number {
		return this._data.lastUpdatedTs;
	}
	public set lastUpdatedTs(val: number) {
		this.setLastUpdatedTs(val);
	}
	// Actionable setter method for MobX.
	protected setLastUpdatedTs(val: number) {
		if (val === this._data.lastUpdatedTs) {
			return;
		}
		this._data.lastUpdatedTs = val;
	}

	/**
	 * Get/set the active table ID.
	 */
	public get tableId(): string {
		return this._data.tableId;
	}
	public set tableId(val: string) {
		this.setTableId(val);
	}
	// Actionable setter method for MobX.
	protected setTableId(val: string) {
		if (val === this._data.tableId) {
			return;
		}

		const prev = this._data.tableId;
		this._data.tableId = val;
		this.onTableIdChanged(this._data.tableId, prev);
	}

	/**
	 * Get/set the active play ID.
	 */
	public get playId(): string {
		return this._data.playId;
	}
	public set playId(val: string) {
		this.setPlayId(val);
	}
	// Actionable setter method for MobX.
	protected setPlayId(val: string) {
		if (val === this._data.playId) {
			return;
		}

		const prev = this._data.playId;
		this._data.playId = val;
		this.onPlayIdChanged(this._data.playId, prev);
	}

	/**
	 * Gets/sets whether or not this class instance is currently bound to MobX as an observable.
	 */
	public get isMobXBound(): boolean {
		return this._isMobXBound;
	}
	public set isMobXBound(value: boolean) {
		this._isMobXBound = value;
	}

	/**
	 * @returns The data entry for the specified seat number, or, NULL if not present
	 */
	public get(seatNumber: number, opts?: Maybe<{ copy?: Maybe<boolean> }>): Nullable<IPlaySeatAssignmentDataEntry> {
		const entry = this.lookup.get(`${seatNumber}`) ?? null;

		if (opts?.copy === true && entry != null) {
			return { ...entry };
		}

		return entry;
	}

	/**
	 * @returns The data entry for the specified seat ID, or, NULL if not present
	 */
	public getBySeatId(seatId: string, opts?: Maybe<{ copy?: Maybe<boolean> }>): Nullable<IPlaySeatAssignmentDataEntry> {
		if (seatId === '') {
			return null;
		}

		let entry: Nullable<IPlaySeatAssignmentDataEntry> = null;

		const seatId2NumLookup = this._data.seatId2NumLookup ?? null;

		if (seatId2NumLookup != null) {
			const seatNumKey = seatId2NumLookup.get(seatId) ?? '';
			if (seatNumKey !== '') {
				entry = this.lookup.get(seatNumKey) ?? null;
			}
		} else {
			entry = this.list.find((a) => a.seatId === seatId) ?? null;
		}

		if (opts?.copy === true && entry != null) {
			return { ...entry };
		}

		return entry;
	}

	/**
	 * @returns TRUE if a data entry for specified seat number exists.
	 */
	public has(seatNumber: number): boolean {
		return this.lookup.has(`${seatNumber}`);
	}

	/**
	 * Resets the class data back to the initial/clear values.
	 */
	public clear() {
		const data = defaultData({ updatedTs: Date.now(), tableId: this.tableId, playId: this.playId });
		this.setData(data);
	}

	/**
	 * @returns A new clone of this class instance with copied data.
	 */
	public clone(
		opts?: Maybe<{ updatedTs?: Maybe<number>; instanceOpts?: Maybe<IPlaySeatAssignmentsOpts> }>
	): PlaySeatAssignments {
		const newData = copyData(this._data, { updatedTs: opts?.updatedTs });

		const instanceOpts: IPlaySeatAssignmentsOpts = {
			...{
				...this._options,
				// These will be set via data or instanceOpts
				updatedTs: null,
				playId: null,
				tableId: null,
			},

			data: newData,
			...filterNullUndefined(opts?.instanceOpts ?? {}),
		};

		return new PlaySeatAssignments(instanceOpts);
	}

	/**
	 * Overrides the parent class method.
	 *
	 * @returns A JSON export of the current data.
	 */
	public override toJson(extended?: Maybe<boolean>): PlainObject {
		extended = extended ?? false;

		const toJs = (val: unknown) => DebugBase.toJs(val, { extended, useMobXToJs: this.isMobXBound });

		const data = copyData(this._data);
		const list = Array.from(data.lookup.values());
		const keys = Array.from(data.lookup.keys());

		const result: PlainObject = {
			lastUpdatedTs: data.lastUpdatedTs,
			size: data.lookup.size,
			isEmpty: data.lookup.size === 0,
			tableId: data.tableId,
			playId: data.playId,
			list: toJs(list),
			lookup: toJs(data.lookup),
			seatNumbers: toJs(keys),
		};

		if (extended) {
			result.extended = {
				isMobXBound: this.isMobXBound,
				options: toJs({ ...this._options }),
				rawData: toJs(data),
				seatId2NumLookup: toJs(data.seatId2NumLookup),

				// Debug info from base class
				_Debug: { ...super.toJson(extended) },
			};
		}

		return result;
	}

	/**
	 * ACTION
	 * Populates this instance using the raw server seats list.
	 */
	public populate(
		rawList: RawPlaySeatAssignmentList,
		opts?: Maybe<{ updatedTs?: Maybe<number> }>
	): IPlaySeatAssignmentsData {
		const data = newDataFromRawList(rawList, this.tableId, this.playId, { updatedTs: opts?.updatedTs });
		this.setData(data);

		return this._data;
	}

	/**
	 * @returns Filtered seats based on the provided options. If no options are provided, all seats are returned.
	 */
	public filterBy(opts: IMethodPlaySeatAssignmentsFilterByOpts): IPlaySeatAssignmentDataEntry[] {
		let matches = this.list;

		if (opts.playerId != null && opts.isOpen != null) {
			matches = matches.filter((value) => value.playerId === opts.playerId && value.isOpen === opts.isOpen);
		} else if (opts.playerId != null) {
			matches = matches.filter((value) => value.playerId === opts.playerId);
		} else if (opts.isOpen != null) {
			matches = matches.filter((value) => value.isOpen === opts.isOpen);
		}

		return matches;
	}

	/**
	 * @returns The seat numbers claimed by the specified player ID.
	 */
	public getClaimedSeatsForPlayerId(playerId: string): number[] {
		if (playerId === '') {
			return [];
		}

		const result = this.filterBy({ playerId }).map((a) => a.seatNumber);
		result.sort((a, b) => a - b);

		return result;
	}

	/**
	 * @returns TRUE if the specified raw list is the same as the current one - in terms of the meaningful data.
	 */
	public isRawDataSame(rawList: RawPlaySeatAssignmentList): boolean {
		rawList = rawList ?? [];

		const origRawList = this._data?.raw?.list ?? [];
		if (0 === rawList.length + origRawList.length) {
			return true;
		}
		if (rawList.length != origRawList.length) {
			return false;
		}

		const origHashId = this._data.raw?.hashId ?? '';
		const newHashId = generateRawListHashId(rawList);

		return newHashId === origHashId;
	}

	/**
	 * @returns TRUE if the specified data list is the same as the current one - in terms of the meaningful data.
	 */
	public isDataSame(list: PlaySeatAssignmentsDataList): boolean {
		const origHashId = this._data?.hashId ?? '';
		const newHashId = generateListHashId(list);

		return newHashId === origHashId;
	}

	/* #region ---- Protected ---------------------------------------------------------------------------------------- */

	/**
	 * Resolves the options being passed in and returns the original and new options.
	 *
	 * - Overrides the parent class method
	 */
	protected override resolveOptions(opts?: Maybe<IPlaySeatAssignmentsOpts>) {
		const origOpts: IPlaySeatAssignmentsOpts = {
			...PlaySeatAssignments.defaultOptions(),
			...this._options,
		};

		const newOpts: IPlaySeatAssignmentsOpts = {
			...origOpts,
			...(opts ?? {}),
		};

		return { origOpts, newOpts };
	}

	/**
	 * Called after new options are set.
	 *
	 * - Extends the parent class method
	 */
	protected override onSetOptions(newOpts: IPlaySeatAssignmentsOpts, origOpts: Maybe<IPlaySeatAssignmentsOpts>) {
		super.onSetOptions(newOpts, origOpts);

		if (newOpts.data != null && (!this._data || newOpts.data.uniqId !== this._data.uniqId)) {
			this.setData(newOpts.data);
		}

		if (!this._data) {
			return;
		}
		if (newOpts.updatedTs != null && newOpts.updatedTs !== this._data.lastUpdatedTs) {
			this.setLastUpdatedTs(newOpts.updatedTs);
		}
		if (newOpts.tableId != null && newOpts.tableId !== this._data.tableId) {
			this.setTableId(newOpts.tableId);
		}
		if (newOpts.playId != null && newOpts.playId !== this._data.playId) {
			this.setPlayId(newOpts.playId);
		}
	}

	/**
	 * Called when the table ID changes.
	 */
	protected onTableIdChanged(_newTableId: string, _prevTableId: string) {
		// NOTHING YET
	}

	/**
	 * Called when the play ID changes.
	 */
	protected onPlayIdChanged(_newPlayId: string, _prevPlayId: string) {
		// NOTHING YET
	}

	/* #endregion ---- Protected ------------------------------------------------------------------------------------- */

	/* #region ---- Static ------------------------------------------------------------------------------------------- */

	/**
	 * STATIC
	 * @returns A clone of the specified class instance.
	 */
	public static cloneInstance(
		from: PlaySeatAssignments,
		opts?: Maybe<{ updatedTs?: Maybe<number> }>
	): PlaySeatAssignments {
		return from.clone(opts);
	}

	/**
	 * STATIC
	 * @returns The default options data used by this class.
	 */
	public static defaultOptions(): IPlaySeatAssignmentsOpts {
		return {
			...DebugBase.defaultOptions(),
			data: null,
			tableId: null,
			playId: null,
			updatedTs: null,
			useMobX: true,
		};
	}

	/**
	 * STATIC
	 * @returns A new instance of this class populated with the specified raw list.
	 */
	public static newFromRawList(
		rawList: RawPlaySeatAssignmentList,
		tableId: string,
		playId: string,
		opts?: Maybe<{
			newInstanceOpts?: Maybe<Omit<IPlaySeatAssignmentsOpts, 'data' | 'tableId' | 'playId' | 'updatedTs'>>;
			populateOpts?: Maybe<IMethodPlaySeatAssignmentsMapRawDataOpts>;
		}>
	): PlaySeatAssignments {
		const data = newDataFromRawList(rawList, tableId, playId, opts?.populateOpts);

		const instanceOpts: IPlaySeatAssignmentsOpts = {
			...filterNullUndefined(opts?.newInstanceOpts ?? {}),
			updatedTs: data.lastUpdatedTs,
			data: data,
		};

		return new PlaySeatAssignments(instanceOpts);
	}

	/**
	 * STATIC
	 * @returns A new instance of this class populated with the specified extended data list.
	 */
	public static newFromList(
		list: PlaySeatAssignmentsDataList,
		opts?: Maybe<{
			tableId?: Maybe<string>;
			playId?: Maybe<string>;
			newInstanceOpts?: Maybe<Omit<IPlaySeatAssignmentsOpts, 'data' | 'tableId' | 'playId' | 'updatedTs'>>;
			populateOpts?: Maybe<Omit<IMethodPlaySeatAssignmentsMapDataOpts, 'tableId' | 'playId'>>;
		}>
	): PlaySeatAssignments {
		const populateOpts: IMethodPlaySeatAssignmentsMapDataOpts = {
			...opts?.populateOpts,
			tableId: opts?.tableId,
			playId: opts?.playId,
		};

		const data = newDataFromList(list, populateOpts);

		const instanceOpts: IPlaySeatAssignmentsOpts = {
			...filterNullUndefined(opts?.newInstanceOpts ?? {}),
			updatedTs: data.lastUpdatedTs,
			data,
		};

		return new PlaySeatAssignments(instanceOpts);
	}

	/* #endregion ---- Static ---------------------------------------------------------------------------------------- */

	/* #region ---- Debug -------------------------------------------------------------------------------------------- */

	/**
	 * Overrides the parent class property.
	 *
	 * @returns The label to use when debugging.
	 */
	protected override get debugClassLabel(): string {
		return PlaySeatAssignments.debugClassLabel();
	}

	/**
	 * STATIC
	 * @returns Label assigned to this class namespace.
	 */
	protected static debugClassLabel(): string {
		return `RpcLib.DataObject.PlaySeatAssignments`;
	}

	/* #endregion ---- Debug ----------------------------------------------------------------------------------------- */
}

// ---- Exports -------------------------------------------------------------------------------------------------------

export { PlaySeatAssignments as default };
export { PlaySeatAssignments };
