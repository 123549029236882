// @generated by protoc-gen-es v1.3.0 with parameter "target=js+dts"
// @generated from file clientserver_testing.proto (package com.sands.ldx.clientserver.testing, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { proto3 } from "@bufbuild/protobuf";

/**
 * @generated from message com.sands.ldx.clientserver.testing.SetBalanceResponse
 */
export const SetBalanceResponse = proto3.makeMessageType(
  "com.sands.ldx.clientserver.testing.SetBalanceResponse",
  () => [
    { no: 1, name: "balances", kind: "message", T: Balance, repeated: true },
  ],
);

/**
 * @generated from message com.sands.ldx.clientserver.testing.GetSelfReply
 */
export const GetSelfReply = proto3.makeMessageType(
  "com.sands.ldx.clientserver.testing.GetSelfReply",
  () => [
    { no: 1, name: "player_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "balances", kind: "message", T: Balance, repeated: true },
    { no: 3, name: "profile", kind: "message", T: Profile },
  ],
);

/**
 * @generated from message com.sands.ldx.clientserver.testing.SetBalanceRequest
 */
export const SetBalanceRequest = proto3.makeMessageType(
  "com.sands.ldx.clientserver.testing.SetBalanceRequest",
  () => [
    { no: 1, name: "amount", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "currency", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "currency_exponent", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ],
);

/**
 * @generated from message com.sands.ldx.clientserver.testing.Profile
 */
export const Profile = proto3.makeMessageType(
  "com.sands.ldx.clientserver.testing.Profile",
  () => [
    { no: 1, name: "display_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "preferences", kind: "map", K: 9 /* ScalarType.STRING */, V: {kind: "scalar", T: 9 /* ScalarType.STRING */} },
  ],
);

/**
 * @generated from message com.sands.ldx.clientserver.testing.Balance
 */
export const Balance = proto3.makeMessageType(
  "com.sands.ldx.clientserver.testing.Balance",
  () => [
    { no: 1, name: "player_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "account_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "currency", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "amount", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 5, name: "currency_exponent", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ],
);

/**
 * @generated from message com.sands.ldx.clientserver.testing.NewPlayRequest
 */
export const NewPlayRequest = proto3.makeMessageType(
  "com.sands.ldx.clientserver.testing.NewPlayRequest",
  () => [
    { no: 1, name: "table_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "safe", kind: "scalar", T: 8 /* ScalarType.BOOL */, opt: true },
  ],
);

/**
 * @generated from message com.sands.ldx.clientserver.testing.NewPlayReply
 */
export const NewPlayReply = proto3.makeMessageType(
  "com.sands.ldx.clientserver.testing.NewPlayReply",
  () => [
    { no: 1, name: "table_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "play_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message com.sands.ldx.clientserver.testing.RunPlayRequest
 */
export const RunPlayRequest = proto3.makeMessageType(
  "com.sands.ldx.clientserver.testing.RunPlayRequest",
  () => [
    { no: 1, name: "table_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "forced", kind: "scalar", T: 8 /* ScalarType.BOOL */, opt: true },
  ],
);

/**
 * @generated from message com.sands.ldx.clientserver.testing.AddOperatorRequest
 */
export const AddOperatorRequest = proto3.makeMessageType(
  "com.sands.ldx.clientserver.testing.AddOperatorRequest",
  () => [
    { no: 1, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "short_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "authProvider", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "createdAt", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 5, name: "updatedAt", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
  ],
);

/**
 * @generated from message com.sands.ldx.clientserver.testing.Success
 */
export const Success = proto3.makeMessageType(
  "com.sands.ldx.clientserver.testing.Success",
  () => [
    { no: 1, name: "success", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ],
);

/**
 * @generated from message com.sands.ldx.clientserver.testing.DeleteOperatorRequest
 */
export const DeleteOperatorRequest = proto3.makeMessageType(
  "com.sands.ldx.clientserver.testing.DeleteOperatorRequest",
  () => [
    { no: 1, name: "short_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message com.sands.ldx.clientserver.testing.LoginRequest
 */
export const LoginRequest = proto3.makeMessageType(
  "com.sands.ldx.clientserver.testing.LoginRequest",
  () => [
    { no: 1, name: "operatorUserId", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "display_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "operator", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message com.sands.ldx.clientserver.testing.LoginReply
 */
export const LoginReply = proto3.makeMessageType(
  "com.sands.ldx.clientserver.testing.LoginReply",
  () => [
    { no: 1, name: "user_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message com.sands.ldx.clientserver.testing.DealerInfo
 */
export const DealerInfo = proto3.makeMessageType(
  "com.sands.ldx.clientserver.testing.DealerInfo",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message com.sands.ldx.clientserver.testing.PlayConfig
 */
export const PlayConfig = proto3.makeMessageType(
  "com.sands.ldx.clientserver.testing.PlayConfig",
  () => [
    { no: 1, name: "game_type", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "game_variant", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "game_version", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "auto_start", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 5, name: "plugin_config", kind: "scalar", T: 12 /* ScalarType.BYTES */ },
  ],
);

/**
 * @generated from message com.sands.ldx.clientserver.testing.Play
 */
export const Play = proto3.makeMessageType(
  "com.sands.ldx.clientserver.testing.Play",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "game_type", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "game_variant", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "game_version", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message com.sands.ldx.clientserver.testing.TableStateRequest
 */
export const TableStateRequest = proto3.makeMessageType(
  "com.sands.ldx.clientserver.testing.TableStateRequest",
  () => [
    { no: 1, name: "table_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message com.sands.ldx.clientserver.testing.TableStateResponse
 */
export const TableStateResponse = proto3.makeMessageType(
  "com.sands.ldx.clientserver.testing.TableStateResponse",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "label", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "active", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 4, name: "total_seats", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 5, name: "dealers", kind: "message", T: DealerInfo, repeated: true },
    { no: 6, name: "play", kind: "message", T: Play, opt: true },
    { no: 7, name: "play_config", kind: "message", T: PlayConfig, opt: true },
    { no: 8, name: "attached_devices", kind: "message", T: DeviceInfo, repeated: true },
  ],
);

/**
 * @generated from message com.sands.ldx.clientserver.testing.DeviceInfo
 */
export const DeviceInfo = proto3.makeMessageType(
  "com.sands.ldx.clientserver.testing.DeviceInfo",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "type", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message com.sands.ldx.clientserver.testing.DeviceInputRequest
 */
export const DeviceInputRequest = proto3.makeMessageType(
  "com.sands.ldx.clientserver.testing.DeviceInputRequest",
  () => [
    { no: 1, name: "device_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "request_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "values", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 5, name: "meta", kind: "map", K: 9 /* ScalarType.STRING */, V: {kind: "scalar", T: 9 /* ScalarType.STRING */} },
  ],
);

/**
 * @generated from message com.sands.ldx.clientserver.testing.GetDeviceRequestsRequest
 */
export const GetDeviceRequestsRequest = proto3.makeMessageType(
  "com.sands.ldx.clientserver.testing.GetDeviceRequestsRequest",
  () => [
    { no: 1, name: "device_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message com.sands.ldx.clientserver.testing.DeviceRequest
 */
export const DeviceRequest = proto3.makeMessageType(
  "com.sands.ldx.clientserver.testing.DeviceRequest",
  () => [
    { no: 1, name: "request_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "request_type", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "request_data", kind: "scalar", T: 12 /* ScalarType.BYTES */ },
  ],
);

/**
 * @generated from message com.sands.ldx.clientserver.testing.GetDeviceRequestsReply
 */
export const GetDeviceRequestsReply = proto3.makeMessageType(
  "com.sands.ldx.clientserver.testing.GetDeviceRequestsReply",
  () => [
    { no: 1, name: "device_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "requests", kind: "message", T: DeviceRequest, repeated: true },
  ],
);

