import { action, computed, observable } from 'mobx';
import { AnnotationsMap } from 'mobx';
import { DebugBaseMobXAnnotations } from '../../common/mobx';
import DataStore from './DataStore';
import { DataStoreMobXPrivateProps } from './types.mobx';

/**
 * MobX annotations for `DataStore`.
 */
const DataStoreMobXAnnotations: AnnotationsMap<DataStore<any, any>, DataStoreMobXPrivateProps> = {
	// Inherit from DebugBase
	...DebugBaseMobXAnnotations,

	// ---- Observed -----------------------------------------------------

	// >>> Properties
	_data: observable.struct,
	_lastUpdatedTs: observable,

	// ---- Computed -----------------------------------------------------

	// >>> Public
	data: computed,
	isPopulated: computed,
	lastUpdatedTs: computed,

	// ---- Actions ------------------------------------------------------

	// >>> Public
	setData: action,
	clear: action,
	populate: action,

	// >>> Protected
	setLastUpdatedTs: action,
	setService: action,

	// ---- Excluded ----------------------------------------------------

	// >>> Properties
	_service: false,
	_isMobXBound: false,

	// >>> Protected
	className: false,
	isMobXBound: false,
};

// ---- Exports -------------------------------------------------------------------------------------------------------

export { DataStoreMobXAnnotations };
