import { entries, filterNullUndefined, makeUniqId } from '../../../../helpers';
import { IMethodResolveAmountsOpts, IResolvedAmountData, resolveAmounts } from '../../../../helpers/amounts';
import { extractActiveWagerKey } from '../../../../helpers/data/utility';
import { generateObjectListHashId } from '../../../../helpers/data/utility';
import { IWagerData } from '../../../types';
import { defaultWagerData, logError, logWarn, newWagerData } from '../../utility';
import {
	BatchUpsertLocalActiveWagersList,
	ILocalActiveWagerDataEntry,
	ILocalActiveWagerDataEntryDiff,
	ILocalActiveWagers,
	ILocalActiveWagersData,
	ILocalActiveWagersDataDiff,
	ILocalActiveWagersLookupDiff,
	IMethodGetWagerIdsResult,
	IMethodGetWagerNamesResult,
	IUpsertLocalActiveWagerProps,
	LocalActiveWagersList,
	LocalActiveWagersLookup,
} from './types';

/**
 * @returns The default data used by the `LocalWagers` class.
 */
const defaultData = (opts?: Maybe<{ updatedTs?: Maybe<number>; playId?: Maybe<string> }>): ILocalActiveWagersData => {
	return {
		playId: opts?.playId ?? '',
		lookup: new Map<string, ILocalActiveWagerDataEntry>(),
		lastUpdatedTs: opts?.updatedTs ?? 0,
		uniqId: makeUniqId(),
		hashId: '',
	};
};

/**
 * @returns A unique hash ID for the specified list of data entries.
 */
const generateDataListHashId = (list: LocalActiveWagersList): string => {
	return generateObjectListHashId<ILocalActiveWagerDataEntry>(list, [
		'playId',
		'seatNumber',
		'wagerId',
		'contextId',
		'name',
		'categoryMemberKey',
		'amount',
		'currencyCode',
		'isDirty',
	]);
};

/**
 * Creates an updated copy of the specified class data after merging in a batch of new & updated wagers.
 *
 * Data merge is immutable and operates on a copy. All returned objects are copies.
 *
 * @returns An diff summary of the class data if successful, otherwise NULL.
 */
const diffLocalDataFromBatchUpsert = (
	batch: BatchUpsertLocalActiveWagersList,
	fromData: ILocalActiveWagersData,
	opts?: Maybe<{
		isDirtyDefaultVal?: Maybe<boolean>;
		resolveAmountsOpts?: Maybe<IMethodResolveAmountsOpts>;
		sequenceOffset?: Maybe<number>;
	}>
): Nullable<ILocalActiveWagersDataDiff> => {
	const debugMethod = 'diffLocalDataFromBatchUpsert';

	if (batch.length === 0) {
		logWarn('No batch data specified for operation', debugMethod);
		return null;
	}

	const original = copyData(fromData);
	const updated = copyData(original);
	const diffLookup = diffLookupDataFromBatchUpsert(batch, original.lookup, opts);

	if (diffLookup === null) {
		return null;
	}

	const updatedTs = diffLookup.updatedTs;

	updated.lookup = diffLookup.updated;
	updated.lastUpdatedTs = updatedTs;

	return {
		original,
		updated,
		updatedTs: updatedTs,
	};
};

/**
 * Creates an updated version of the specified wager lookup after merging in a batch of new & updated wagers.
 *
 * Data merge is immutable and operates on a copy. All returned objects are copies.
 *
 * @returns A diff summary of the wager lookup if successful, otherwise NULL.
 */
const diffLookupDataFromBatchUpsert = (
	batch: BatchUpsertLocalActiveWagersList,
	fromLookup: LocalActiveWagersLookup,
	opts?: Maybe<{
		isDirtyDefaultVal?: Maybe<boolean>;
		resolveAmountsOpts?: Maybe<IMethodResolveAmountsOpts>;
		sequenceOffset?: Maybe<number>;
	}>
): Nullable<ILocalActiveWagersLookupDiff> => {
	const debugMethod = 'diffLookupDataFromBatchUpsert';

	if (batch.length === 0) {
		logWarn('No batch data specified for operation', debugMethod);
		return null;
	}

	const original = copyLookup(fromLookup);
	const updated = copyLookup(original);
	const updatedTs: number = Date.now();

	batch.forEach((props: IUpsertLocalActiveWagerProps) => {
		const activeWagerKey = props.activeWagerKey ?? '';
		if (activeWagerKey === '') {
			return; // Next batch item
		}

		// Existing entry
		if (updated.has(activeWagerKey)) {
			const updatedEntry = diffEntryDataFrom(activeWagerKey, props, updated);
			if (updatedEntry == null) {
				return; // Next batch item
			}

			const replace = updatedEntry.updated;

			// const sequenceOffset = opts?.sequenceOffset ?? 0;
			// if (sequenceOffset != 0) {
			// 	const origSequence = replace.sequence;
			// 	const newSequence = origSequence + sequenceOffset;
			// 	console.warn(`SEQUENCE CHANGE: ${activeWagerKey} - ${origSequence} -> ${newSequence}`);
			// 	replace.sequence = replace.sequence + sequenceOffset;
			// }

			updated.set(activeWagerKey, replace);
			return; // Next batch item
		}

		// New entry
		const newEntry = mapEntryDataFromUpsertProps(props, opts);
		if (newEntry == null) {
			return; // Next batch item
		}

		updated.set(activeWagerKey, newEntry);
	});

	// Return the original and updated lookup
	return { original, updated, updatedTs };
};

/**
 * Creates an updated version of the wager entry in the specified local wagers lookup for the specified active wager key
 * by merging in new values.
 *
 * Data merge is immutable and operates on a copy. All returned objects are copies.
 *
 * @returns A diff summary of the balance entry data if successful, otherwise NULL.
 */
const diffEntryDataFrom = (
	activeWagerKey: string,
	newProps: IUpsertLocalActiveWagerProps,
	fromLookup: LocalActiveWagersLookup
): Nullable<ILocalActiveWagerDataEntryDiff> => {
	// Early return if a entry for the active wager key does not exist
	const entry: Nullable<ILocalActiveWagerDataEntry> =
		(activeWagerKey.length > 0 ? fromLookup.get(activeWagerKey) : null) ?? null;

	if (entry == null) {
		return null;
	}

	// Return the original and updated entry data
	return diffUpdateEntryData(entry, newProps);
};

/**
 * Creates an updated version of the specified local wager entry by merging in the specified new values.
 *
 * Data merge is immutable and operates on a copy. All returned objects are copies.
 *
 * @returns A diff summary of the local wager entry data if successful, otherwise NULL.
 */
const diffUpdateEntryData = (
	entry: ILocalActiveWagerDataEntry,
	newProps: IUpsertLocalActiveWagerProps
): ILocalActiveWagerDataEntryDiff => {
	const original: ILocalActiveWagerDataEntry = { ...entry };

	let resolve: Nullable<IResolvedAmountData> = null;

	const isAmountChanging = newProps.amount != null && newProps.amount !== original.amount;
	const isAmountRealChanging = newProps.amountReal != null && newProps.amountReal !== original.amountReal;
	const isCurrencyChanging = newProps.currencyCode != null && newProps.currencyCode !== original.currencyCode;

	if (isAmountChanging || isAmountRealChanging || isCurrencyChanging) {
		const currencyCode = newProps.currencyCode ?? original.currencyCode;
		resolve = resolveAmounts({ amount: newProps.amount, amountReal: newProps.amountReal }, { currencyCode });
	}

	const lastUpdatedTs = newProps.lastUpdatedTs ?? Date.now();

	const updated: ILocalActiveWagerDataEntry = {
		...defaultWagerData(),
		...original,
		...filterNullUndefined(newProps),
		...resolve,
		...{
			lastUpdatedTs,
		},
	};

	// Return the original and updated entry data
	return { original, updated, updatedTs: lastUpdatedTs };
};

/**
 * @returns A new wager entry data object created using the specified properties.
 */
const mapEntryDataFromUpsertProps = (
	props: IUpsertLocalActiveWagerProps,
	opts?: Maybe<{ isDirtyDefaultVal?: Maybe<boolean>; resolveAmountsOpts?: Maybe<IMethodResolveAmountsOpts> }>
): Nullable<ILocalActiveWagerDataEntry> => {
	const debugMethod = 'mapEntryDataFromUpsertProps';

	props = filterNullUndefined(props);

	const { activeWagerKey = '' } = props;

	if (activeWagerKey === '') {
		logError(`Empty active wager key specified`, debugMethod);
		return null;
	}

	const keyProps = extractActiveWagerKey(activeWagerKey);
	if (keyProps == null) {
		logError(`Invalid active wager key specified: ${activeWagerKey}`, debugMethod);
		return null;
	}

	const { seatNumber, wagerId, contextId, categoryMemberKey } = keyProps;

	if (seatNumber < 1) {
		logError(`Invalid seat number: ${seatNumber}`, debugMethod);
		return null;
	}

	if (wagerId === '') {
		logError(`Empty wager ID`, debugMethod);
		return null;
	}

	const playId = props.playId ?? '';
	const createdTs = props.createdTs ?? Date.now();
	const lastUpdatedTs = props.lastUpdatedTs ?? createdTs;
	const isDirty = props.isDirty ?? opts?.isDirtyDefaultVal ?? true;

	const resolveAmountsOpts: IMethodResolveAmountsOpts = {
		...opts?.resolveAmountsOpts,
		currencyCode: props.currencyCode || opts?.resolveAmountsOpts?.currencyCode || null,
	};

	const { amount, amountReal, amountMoney, currencyCode, currencySymbol } = resolveAmounts(
		{ amount: props.amount, amountReal: props.amountReal },
		resolveAmountsOpts
	);

	if (amount < 0) {
		logError(`Wager amount cannot be negative: ${amount}`, debugMethod);
		return null;
	}

	return {
		...defaultWagerData(),
		...props,
		playId,
		seatNumber,
		wagerId,
		contextId,
		categoryMemberKey,
		amount,
		amountReal,
		amountMoney,
		currencyCode,
		currencySymbol,
		createdTs,
		lastUpdatedTs,
		isDirty,
	};
};

/**
 * @returns The specified local wager data entry mapped to a standard wager data object.
 */
const mapEntryToWagerData = (
	entry: ILocalActiveWagerDataEntry,
	opts?: Maybe<{
		resolveAmountsOpts?: Maybe<IMethodResolveAmountsOpts>;
	}>
): IWagerData => {
	return newWagerData(
		{
			...entry,
			isLocal: true,
		},
		opts
	);
};

/**
 * Creates an updated copy of the specified `LocalWagers` instance after merging in a batch of new & updated wagers.
 *
 * Data merge is immutable and operates on a copy. All returned objects are copies.
 *
 * @returns An diff summary of the class and entry data if successful, otherwise NULL.
 */
const newFromBatchUpsert = (
	batch: BatchUpsertLocalActiveWagersList,
	fromInstance: ILocalActiveWagers
): Nullable<ILocalActiveWagers> => {
	const debugMethod = 'newFromBatchUpsert';

	if (batch.length === 0) {
		logWarn('No batch data specified for operation', debugMethod);
		return null;
	}

	const original = fromInstance.clone();
	const updated = original.clone();
	updated.batchUpsertWagerData(batch);

	return updated;
};

/**
 * @returns Unique seat numbers in the specified local wagers lookup.
 */
const extractSeatNumbers = (lookup: LocalActiveWagersLookup): number[] => {
	const list = Array.from(lookup.values());
	const seatMap = new Map<number, boolean>();

	list.forEach((entry) => {
		const seatNumber = entry.seatNumber;
		seatNumber > 0 && seatMap.set(seatNumber, true);
	});

	return Array.from(seatMap.keys());
};

/**
 * @returns Unique wager names in the specified lookup.
 */
const extractWagerNames = (lookup: LocalActiveWagersLookup): IMethodGetWagerNamesResult => {
	const list = Array.from(lookup.values());
	const allWagerNameMap = new Map<string, boolean>();
	const bySeat: Record<string, Map<string, boolean>> = {};

	const result: IMethodGetWagerNamesResult = { all: [], bySeat: new Map<string, string[]>() };
	if (list.length === 0) {
		return result;
	}

	list.forEach((entry) => {
		const seatNumber = entry.seatNumber;
		const wagerName = entry.name;

		if (seatNumber < 1 || wagerName.length === 0) {
			return; // Next entry
		}

		allWagerNameMap.set(wagerName, true);

		bySeat[seatNumber] = bySeat[seatNumber] ?? new Map<string, boolean>();
		bySeat[seatNumber].set(wagerName, true);
	});

	entries(bySeat).forEach(([seatNumber, wagerNameMap]) => {
		result.bySeat.set(seatNumber, Array.from(wagerNameMap.keys()));
	});

	result.all = Array.from(allWagerNameMap.keys());

	return result;
};

/**
 * @returns Unique wager Ids in the specified lookup.
 */
const extractWagerIds = (lookup: LocalActiveWagersLookup): IMethodGetWagerIdsResult => {
	const list = Array.from(lookup.values());
	const allWagerIdMap = new Map<string, boolean>();
	const bySeat: Record<string, Map<string, boolean>> = {};

	const result: IMethodGetWagerIdsResult = { all: [], bySeat: new Map<string, string[]>() };

	list.forEach((entry) => {
		const seatNumber = entry.seatNumber;
		const wagerId = entry.wagerId;

		if (seatNumber < 1 || wagerId.length === 0) {
			return; // Next entry
		}

		allWagerIdMap.set(wagerId, true);

		bySeat[seatNumber] = bySeat[seatNumber] ?? new Map<string, boolean>();
		bySeat[seatNumber].set(wagerId, true);
	});

	entries(bySeat).forEach(([seatNumber, wagerIdMap]) => {
		result.bySeat.set(seatNumber, Array.from(wagerIdMap.keys()));
	});

	result.all = Array.from(allWagerIdMap.keys());

	return result;
};

/**
 * @returns A copy of the specified local wagers lookup.
 */
const copyLookup = (lookup: LocalActiveWagersLookup): LocalActiveWagersLookup => {
	const copy = new Map<string, ILocalActiveWagerDataEntry>();
	for (const [key, value] of lookup) {
		copy.set(key, { ...value });
	}

	return copy;
};

/**
 * @returns A copy of the specified local wagers data.
 */
const copyData = (
	data: ILocalActiveWagersData,
	opts?: Maybe<{ updatedTs?: Maybe<number> }>
): ILocalActiveWagersData => {
	const newData: ILocalActiveWagersData = defaultData();
	newData.lastUpdatedTs = opts?.updatedTs ?? data.lastUpdatedTs;
	newData.playId = data.playId;
	newData.lookup = copyLookup(data.lookup);
	newData.hashId = data.hashId;

	return newData;
};

// ---- Exports -------------------------------------------------------------------------------------------------------

// Upsert
export { diffLocalDataFromBatchUpsert, diffLookupDataFromBatchUpsert, newFromBatchUpsert, mapEntryDataFromUpsertProps };

export { diffEntryDataFrom, diffUpdateEntryData };
export { mapEntryToWagerData };
export { defaultData, copyData, copyLookup };
export { extractSeatNumbers, extractWagerNames, extractWagerIds };
export { generateDataListHashId };
