enum RestartOnErrorType {
	OFF = '',
	RECOVERABLE = 'RECOVERABLE',
	ALL = 'ALL',
}

enum StreamEvents {
	DATA = 'data',
	STATUS = 'status',
	END = 'end',
}

enum StreamStatus {
	// Initial state.
	NOT_STARTED = '',
	// Stream is starting.
	STARTING = 'STARTING',
	// Stream is restarting.
	RESTARTING = 'RESTARTING',
	// Stream is started and running.
	RUNNING = 'RUNNING',
	// Stream has ended normally.
	ENDED = 'ENDED',
	// Stream has ended due to cancellation.
	ENDED_CANCELLED = 'ENDED_CANCELLED',
	// Stream has ended due to an error.
	ENDED_ERROR = 'ENDED_ERROR',
	// Stream was manually stopped due calling the `stop` method.
	STOPPED = 'STOPPED',
}

// ---- Exports -------------------------------------------------------------------------------------------------------

export { RestartOnErrorType, StreamEvents, StreamStatus };
