import { AuthSessionProvider, IAuthSessionProvider } from '../AuthSession';

const getDefaultOmnibusToken = (): string => {
	const parts: string[] = [];
	parts.push('eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJuYmYiOjE2O');
	parts.push('DI2Mjk2MTIsInRva2VuIjoiNWZaaDh4TkdEZ1hDQjl6WGlrT0RIdUVGbmFLU');
	parts.push('2lRU2kiLCJ1c2VyX2lkIjoiNWIyNGY0OGYtNzk5Yi00Y2FhLWJlNTEtNTI1YTA');
	parts.push('3YjM0ZThmIiwic2VydmVyX25hbWUiOiJ0ZXN0Iiwic2VydmljZV9uYW1lIjoidGVz');
	parts.push('dCJ9.Y57z4Hu2IPnGffd-P4pIUMgsjEAb_8uedkyolu96A54');

	return parts.join('');
};

const newOmnibusSession = (token: string): IAuthSessionProvider => {
	if (token === '') {
		throw new Error('Cannot create omnibus session with empty token.');
	}

	const session = new AuthSessionProvider('areax.omnibusSession', { useStorage: false });
	session.token = token;

	return session;
};

const newDefaultOmnibusSession = (): IAuthSessionProvider => {
	return newOmnibusSession(getDefaultOmnibusToken());
};

// ---- Exports -------------------------------------------------------------------------------------------------------

export { getDefaultOmnibusToken, newOmnibusSession, newDefaultOmnibusSession };
