const Events: Record<string, string> = {
	DEBUG: 'DEBUG',
	ERROR: 'ERROR',
};

enum DebugEventType {
	INFO = 'INFO',
	WARNING = 'WARNING',
	ERROR = 'ERROR',
}

// ---- Exports -------------------------------------------------------------------------------------------------------

export { Events as EventDispatcherBaseEvents, DebugEventType };
